import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
  fill?: string;
}

const IdeaIcon = (props: Props) => {
  return (
    // <svg width={props.width || '40px'} height={props.height || '40px'} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <circle cx="20" cy="20" r="20" fill={props.fill || '#E9E9E7'}/>
    //   <path d="M12 23C12 23 13 22 16 22C19 22 21 24 24 24C27 24 28 23 28 23V11C28 11 27 12 24 12C21 12 19 10 16 10C13 10 12 11 12 11V23Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M12 30V23" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>
    <svg width={props.width || '16px'} height={props.height || '16px'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z" stroke="#FF6C4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IdeaIcon;
