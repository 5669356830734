import { createStyles, makeStyles } from '@material-ui/core';
import Sidebar from '../UI/Sidebar';
import ProfileHeader from '../UI/Header';
import { useQuery } from 'react-query';
import React, { useEffect } from 'react';
import { HistoryPopup } from '../UI/History';
import TipsModal from '../LoadData/TipsModal';

const useStyles: any = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
    },
    page: {
      position: 'relative',
      flexGrow: 1,
      overflowY: 'auto',
      // paddingTop: 63,
    },
    pageHeader: {
      position: 'sticky',
      top: 0, right: 0,
      background: '#fff',
      width: '100%',
      zIndex: 9,
    }
  })
);

interface Props {
  historyPage?: string;
  showHistory?: boolean;
  children: any;
  staticName?: string;
  historyComponent?: any;
};

const Main = (props: Props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [openTips, setOpenTips] = React.useState(false);
  const [history, setHistory] = React.useState([]);

  // window.addEventListener('storage', (e: any) => {
  //   // let profileWb = localStorage.getItem('profileWb')
  //   return console.log('profileWb', JSON.parse(localStorage?.getItem('profileWb')));
  //   // let profileWb = localStorage.getItem('profileWb')
  //
  //   // console.log('profileWb', profileWb)
  // })

  // console.log('PROPS', props, props.showHistory)
  return (
    <div className={classes.root}>
      {history && (
        <HistoryPopup
          openModal={open}
          history={history}
          closeEvent={() => setOpen(false)}
        />
      )}
      <TipsModal
        show={openTips}
        list={[]}
        closeEvent={() => setOpenTips(false)}
      />
      <Sidebar />
      <div className={classes.page}>
        <div className={classes.pageHeader}>
          <ProfileHeader
            staticName={props.staticName}
            showHistory={props.showHistory || false}
            historyPage={props.historyPage}
            setOpenHistory={() => setOpen(true)}
            setHistoryList={(history) => setHistory(history)}
            // historyComponent={props.historyComponent}
          />
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default Main;
