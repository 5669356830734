import React from 'react';
import moment from 'moment';
import { ClockIcon, InfoIcon } from '../../components/UI/Icons';
import { makeStyles, createStyles } from '@material-ui/core';
import {useQuery} from "react-query";
import cookie from "cookie";
import {primaryDataSearchSale} from "../../api/primary_data_search";

const useStyles: any = makeStyles(() =>
  createStyles({
    needUpdate: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FF6C4B',
      display: 'inline-flex',
      alignItems: 'center',
    },
  }),
);

interface Props {
  typeCheck: 'custom' | 'standard';
  isActual?: boolean;
  lastDateUpload: string;
  allGoodUploadDate?: string;
  uid?: string;
};

const StockRemainsActualInfo = (props: Props) => {
  const classes = useStyles();
  const differenceDates = (lastDateUpload: string) => {
    const regex = /([0-9]+):([0-9]+)/;
    const a: any = moment(
      props.allGoodUploadDate
        ? props.allGoodUploadDate
        : null
    ).startOf('minute');
    const b: any = moment(lastDateUpload);

    return Math.round(moment.duration(a - b).asMinutes()) * -1 >= 0;
  };


  const checkActual = (lastDateUpload: string) => {
    switch (props.typeCheck) {
      case 'custom':

        if (lastDateUpload) {
          const days = differenceDates(props.lastDateUpload);
          if (props.isActual) return true;
        }
        break;
      case 'standard':
        if (lastDateUpload) {
          const days = differenceDates(props.lastDateUpload);
          if (days) return true;
        }
        break;
      default: return false;
    }
  };

  return (
    <>
      {checkActual(props.lastDateUpload)
        ? <>
            <ClockIcon /> Актуален {props.lastDateUpload ? `, загружен ${moment(props.lastDateUpload).format('LLL')}` : null}
          </>
        : <span className={classes.needUpdate}><InfoIcon /> Рекомендуем обновить</span>}
    </>
  );
};

export default StockRemainsActualInfo;
