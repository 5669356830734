import { SnackbarOrigin, Snackbar, createStyles, makeStyles, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import './SnackBars.css';
const useStyles = makeStyles(() =>
  createStyles({
    customSnackbar: {
      '& .MuiSnackbarContent-root': {
        minWidth: 298,
        display: 'flex',
        justifyContent: 'space-between',
        background: '#37352F',
        borderRadius: 8,
        padding: '16px 19px',
        zIndex: '999999!important'
      },
      '& .MuiSnackbarContent-message': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '16px',
        color: '#FFFFFF',
        padding: 0,
        maxWidth: 370,
      },
      '& .MuiSnackbarContent-action': {
        margin: 0,
      },
    },
    customSnackbarDividir: {},
    customSnackbarBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      marginLeft: 12,
      paddingBottom: 2,
      borderBottom: '1px solid #fff',
      cursor: 'pointer',
    },
  }),
);

export interface State extends SnackbarOrigin {
  open: boolean;
};
interface Props {
  open: boolean;
  message?: string;
  btnText?: string | undefined;
  showAction?: string | undefined;
  handleClose?: () => void;
};
export default function CustomSnackbar(props: Props){
  const classes = useStyles();

  React.useEffect(() => {
    if (!props.open) return;
    setTimeout(() => {
      if (props.handleClose) props.handleClose();
    }, 15000)
  }, [props.open])

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    if (props.handleClose) props.handleClose();
  };


  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={15000}
      open={props.open}
      onClose={handleClose}
      message={props.message}
      className={classes.customSnackbar}
      action={
        props.showAction ? <div className={classes.customSnackbarDividir}>
          |
          <span className={classes.customSnackbarBtn} onClick={handleClose}>
            {props.btnText || 'Отменить'}
          </span>
        </div> : action
      }
    />
  );
}
