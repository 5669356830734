const routes: any = {
  'admin': 'Администрирование',
  'profile': 'Профиль',
  'organization': 'Организация',
  'load-data': 'Загрузка первичных данных',
  'manage-prices': 'Управление ценами',
  'support': 'Техническая поддержка',
  'tariffs': 'Тарифы',
  'tariff': 'Тариф',
  'users': 'Пользователи',
  'wb-data': 'Общие данные из WB',
  'role': 'Роли',
  'jur-documents': 'Загрузка юридических документов',
  // 'load-params': 'Параметры бренд/категория/товар',
  'current-remainder': 'Расчет цены по спросу и остатку',
  'price-drop': 'Расчет цены для акций',
  'logistics-cost': 'Стоимости логистики и процента комиссии',
  'tariff-pay': 'Оплата тарифа',
  'stocks': 'Склады',
  'analytics': 'Аналитика'
};

export default routes;
