import API, { TOKEN_KEY, ROLE_KEY } from './utils';
import { IUser } from '../types';
import { removeLocalStorageValue, setLocalStorage } from '../utils';
import { setToken } from './utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

type User = {
  user: IUser & { token: string };
};

// const authBaseURL = 'http://85.175.216.246:8092';
// const registrationBaseURL = 'http://85.175.216.246:8084';

function handleUserResponse(token: string, role?: string) {
  setLocalStorage(TOKEN_KEY, token);
  setLocalStorage(ROLE_KEY, role || '');
  setToken(token);
  return token;
}

export function getCurrentUser() {
  return ;
}

export async function login(phone: string) {
  const urlAdres = await url()
  // localStorage.setItem('ORDERED_STATE', '1');
  return API.post(`${urlAdres.data.authorizationWb}/authorization/login`, {
    phone
  }, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
}

export async function answer(phone: string, auth: boolean) {
  const urlAdres = await url()
  return API.post(`${urlAdres.data.authorizationWb}/authorization/webhooks/response`, {
    phone: phone,
    auth: auth
  }, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
}

export async function registration(phone: string) {
  const urlAdres = await url()
  return API.post(`${urlAdres.data.registration}/registration/registration_request`, {
    phone
  }, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
}

export async function registrationVerefication(phone: string, code: string) {
  const urlAdres = await url()
  try {
    const result: any = await API.post(`${urlAdres.data.registration}/registration/registration`, {
      phone,
      code,
    }, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
    if (result.data.success)
      handleUserResponse(result.data.data || '');
    return result;
  } catch (error) {
    return error;
  }
}

export async function verefication(phone: string, code: string) {
  const urlAdres = await url()
  try {
    const result: any = await API.post(`${urlAdres.data.authorizationWb}/authorization/authentificate`, {
      phone,
      code
    }, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
    if (result.data.success)
      handleUserResponse(result.data.data.token, result.data.data.roles);
    return result;
  } catch (error) {
    return error;
  }
}

// export function register(user: {
//   phone: string;
//
// }) {
//   return API.post<User>('/users', { user }).then((user) =>
//     handleUserResponse(user.data),
//   );
// }

export function updateUser(user: IUser & Partial<{ password: string }>) {
  return API.put<User>('/user', { user });
}

export async function getUsers(page?: string) {
  const urlAdres = await url()
  return API.post(`${urlAdres.data.users}/users/users`, { page });
}

export async function logout() {
  const urlAdres = await url()
  const result = await API.post(`${urlAdres.data.authorizationWb}/authorization/logout`, {
    headers: { 'Access-Control-Allow-Origin': '*' }
  });
  removeLocalStorageValue(TOKEN_KEY);

  removeLocalStorageValue('org_uid');
  setToken(null);
  window.location.href = '/login';
  // localStorage.setItem('ORDERED_STATE', '0');
  return result;
}
