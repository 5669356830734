const Hightlight = (props) => {
    const { filter, str } = props

    if (!filter || str === null) return str
    const regexp = new RegExp(filter, 'ig')

      const matchValue = str.match(regexp)
      if (matchValue) {  
        return str.split(regexp).map((s, index, array) => {
          if (index < array.length - 1) {
            const c = matchValue.shift()
            return <>{s}<span style={{background: 'yellow'}}>{c}</span></>
          }
          return s
        })
      }
      return str

}

export default Hightlight;