import React from 'react';
import moment from 'moment';
import { ClockIcon, InfoIcon } from '../../components/UI/Icons';
import { makeStyles, createStyles } from '@material-ui/core';
import {useQuery} from "react-query";
import cookie from "cookie";
import {primaryDataSearchSale} from "../../api/primary_data_search";

const useStyles: any = makeStyles(() =>
  createStyles({
    needUpdate: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FF6C4B',
      display: 'inline-flex',
      alignItems: 'center',
    },
  }),
);

interface Props {
  typeCheck: 'custom' | 'standard';
  isActual?: boolean;
  lastDateUpload: string;
  uid?: string;
};

const StocksActualInfo = (props: Props) => {
  const classes = useStyles();

  const differenceDates = (lastDateUpload: string) => {
    const regex = /([0-9]+):([0-9]+)/;
    const a: any = moment().startOf('day');
    const b: any = moment(lastDateUpload.replace(regex, '00:00:00'));

    return Math.round(moment.duration(a - b).asDays()) * -1 >= 0;
  };

  const dataSearchSale = useQuery('dataSearchSale', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await primaryDataSearchSale(uid);

    // const check = result.data.data.some((item) => differenceDates(item.date_upload))
    // console.log(result.data.data)

    return result?.data?.data as any[];
  });
  const checkActual = (lastDateUpload: string) => {
    switch (props.typeCheck) {
      case 'custom':
        if((dataSearchSale?.data as any[])?.length > 0) return true
        if (lastDateUpload) {
          const days = differenceDates(props.lastDateUpload);
          if (props.isActual) return true;
        }
        break;
      case 'standard':
        if (lastDateUpload) {
          const days = differenceDates(props.lastDateUpload);
          if (days) return true;
        }
        break;
      default: return false;
    }
  };

  return (
    <>
      {checkActual(props.lastDateUpload)
        ? <>
            <ClockIcon /> Текущие акции {props.lastDateUpload ? `${moment(props.lastDateUpload).format('LLL')}` : null}
          </>
        : <span className={classes.needUpdate}><InfoIcon /> Нет текущей акции</span>}
    </>
  );
};

export default StocksActualInfo;
