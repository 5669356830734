import React, { useEffect, useState } from 'react';
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
  makeStyles,
  createStyles
} from '@material-ui/core';
import {
  matchPath,
  NavLink,
  useLocation
} from 'react-router-dom';
import routes from './routesList';

const useStyles: any = makeStyles(() =>
  createStyles({
    breadcrumb1: {
      '& .MuiBreadcrumbs-separator': {
        fontSize: '20px !important',
        fontWeight: 600,
        color: '#37352F',
      }
    },
    breadcrumbCurrent: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
    },
    breadcrumbLink: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      // fontSize: 16,
      textDecoration: 'none',
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#827F75',
    },
  })
);

const Breadcrumbs = (props: any) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((x: any) => x);

  return (
    <MUIBreadcrumbs
      className={classes.breadcrumb1}
      separator="›"
      aria-label="breadcrumb"
    >
      {/* {pathnames.length > 0 ? (
        <NavLink to="/">Home</NavLink>
      ) : (
        <Typography> Home </Typography>
      )} */}
      {pathnames.map((name: string, index: number) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        
        return isLast ? (
          routes[name] &&
            <Typography className={classes.breadcrumbCurrent} key={name}>
              {routes[name]}
            </Typography>
        ) : (
          <NavLink className={classes.breadcrumbLink} key={name} to={routeTo}>
            {routes[name]}
          </NavLink>
        );
      })}
      {props.staticName && <Typography className={classes.breadcrumbCurrent}>{props.staticName}</Typography>}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
