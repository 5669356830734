import React from 'react';
import classNames from 'classnames';
import { TableRow, TableCell, createStyles, makeStyles } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import LoadDataCellByType from './LoadDataTableCellByType';
import '../../../index.css'

const useStyles = makeStyles(() =>
  createStyles({
    tableCell: {
      verticalAlign: 'middle',
      textAlign: 'left',
      fontSize: '0.775rem',
      position: 'relative',
      minHeight: 30,
      padding: 10,

      '&:hover': {
        background: 'rgba(225, 225, 228, 0.5) !important'
      },
    },
    tableRow: {
      '&:hover': {
        background: '#f0f0ef',
      },
    },
    tableNoData: {
      backgroundColor: 'rgba(255, 108, 75, 0.08)',
    },
    cellBlock: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
    },
  })
);

interface Props {
  cols: any;
  data: any;
  param: string;
  tableWidth: number;
  item: any;
  setNewData: (data: any, rowIndex: number, colName: string) => void;
  search: string;
  setDoubles?: any
  changeCheckboxHandler: (item: any) => void
};


const getAlign = (colType: string) => {
  switch (colType) {
    case 'int': return 'flex-end';
    case 'bool': return 'center';
  };
};

const LoadDataTableRow = (props: Props) => {
  const classes = useStyles();

  const type = localStorage.getItem('typeName')


  const [searchParams, setSearchParams] = useSearchParams();
  const [loaded, setLoaded] = React.useState(false);
  const [noData, setNoData] = React.useState('');
  const [editCellPos, setEditCellPos] = React.useState<any>({ x: null, y: null });
  const [item, setItem] = React.useState<any>();

  React.useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  const cellRenderCondition = (name, showed) => {

    const linkId = searchParams.get("id");
    const idList = {
      all: '0a5956f9-a9f6-49c0-8080-bb1fda1251a7',
      currWeek: '94e066eb-3b01-4916-993c-73f06978145b',
      fbs: '974b957b-6df3-4811-9eaf-54c12898227f',
      stockBalance: '147bda8c-d997-4050-b497-d7a0aed59265',
      // currPromotion: '960d6482-dc35-4834-871a-1ee8a26f1d02',
      detail: '7e8d9639-2419-47cd-aca1-0c8829528622',
      purchase: '005fea2d-b561-4c81-a1e6-85554bfbd7ba',
      sizeWeightVolume: 'ceee8d45-32fb-4710-aa62-d26d2a75bb87',
      paramBrandProduct: '5fe9a4b9-423d-4bad-bbc9-e15660568081'
    };

    if (name.indexOf('week_') !== -1 && name.indexOf(`_${props.param}`) === -1) return false;
    // if (linkId === idList.all && name === 'brand') return false;
    // if (linkId === idList.currPromotion && name === 'name_shares') return false;
    if (!showed) return false;
    if (showed) return true;
    return true;
  };

  const getWidth = (width: number) => {
    return {
      width: width,
      maxWidth: width,
      minWidth: width,
    }
  };

  const openEditField = (noEdit: boolean, cellPos: any, data: any) => {
    if (noEdit) return;
    setEditCellPos(cellPos);
    setNoData(data);
  };
  const setNewVolume = (val) => {
    console.log('val: ', val);

    if (Number(item.volume) !== Number(val)) {
      setTimeout(() => {
        setItem({ ...item, volume: val });
      }, 1000)
    }
  }

  const updateCellData = (data) => {
    setItem({ ...item, ...data });
    // setEditCellPos({ x: null, y: null });
  };

  return item
      ? <TableRow className={classes.tableRow} key={item.rowIndex}>
          {props.cols.map((col: any, idx: number) => (
            (cellRenderCondition(col.column_name, col.showed) &&
              <TableCell
                className={
                  classNames(
                    classes.tableCell,
                    !item[col.column_name] ||
                    item[col.column_name] === '0.0' ||
                    item[col.column_name] === '0'
                      ? classes.tableNoData
                      : ''
                  )
                }
                style={{
                  width: col.width,
                  maxWidth: col.width,
                  minWidth: col.width,
                }}
                onClick={() =>
                  openEditField(col.column_edit === 'False', {
                      x: item.rowIndex,
                      y: idx
                    }, item[col.column_name]
                  )
                }
                key={idx}
              >
                <div
                  className={classes.cellBlock && 'vid'}
                  style={{justifyContent: getAlign(col.column_type)}}
                >
                  <LoadDataCellByType
                    search={props.search}
                    uidRow={item.uid}
                    typeName={type}
                    editCellPos={editCellPos}
                    x={item.rowIndex}
                    y={idx}
                    setDoubles={props.setDoubles}
                    type={col.column_type}
                    data={item[col.column_name]}
                    test={props.data}
                    editable={col.column_edit === 'False'}
                    dataItem={item}
                    setNewData={props.setNewData}
                    rowIndex={item.rowIndex}
                    colName={col.column_name}
                    resetEditCellPos={() => setEditCellPos({x: null, y: null})}
                    setNewVolume={(val: any) => setNewVolume(val)}
                    updateCellData={updateCellData}
                    changeCheckboxHandler={props.changeCheckboxHandler}
                  />
                </div>
              </TableCell>
          )))}
        </TableRow>
      : null
}

export default LoadDataTableRow;
