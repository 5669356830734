import { makeStyles, createStyles, Typography, Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import classNames from 'classnames';
import { Close } from '@material-ui/icons';
import cookie from 'cookie';

import { InfoIcon } from '../../../components/UI/Icons';
import React, {useState, useEffect} from 'react';
import { useQuery } from 'react-query';
import { countNotifications, getBell, new1Notifications, newNotifications } from '../../../api/reports';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() =>
  createStyles({
    notificationModal: {
      // display: 'none',
      position: 'fixed',
      width: 329,
      height: '100vh',
      background: '#fff',
      top: 0, right: 0,
      transform: 'translateX(100%)',
      transition: 'all .2s ease-out',
      padding: 16,
      paddingRight: 12,
      boxSizing: 'border-box',
      borderLeft: '1px solid #D3D1CB',
      zIndex: 1299,
      overflowY: 'auto',
    },
    accardionTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontSize: 17,
      fontWeight: 600,
      color: '#FF6C4B'
    },
    accardionTitle1: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontSize: 17,
      fontWeight: 500
    },
    accardion: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontSize: 15,
      // boxShadow: 'none',
    },
    time: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontSize: 15,
      marginTop: 10
    },
    show: {
      // display: 'block',
      transform: 'translateX(0)',
    },
    notificationHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 32,
    },
    notificationTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
    },
    notificationClose: {
      fontSize: 24,
      cursor: 'pointer',
    },
    notificationContent: {},
    notificationMiniTitle: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginBottom: 8,

      '& svg': {
        marginRight: 4,
      },
    },
    notificationList: {
      padding: 0,
      listStyle: 'none',
    },
    notificationListItem: {
      maxWidth: 280,
      paddingTop: 12,
      paddingBottom: 12,
      borderBottom: '1px solid #E9E9E7',
    },
    notificationName: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 4,

      '& span': {
        color: '#827F75',
      },
    },
    notificationInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,

      '& span': {
        color: '#827F75',
      },
    },
    notificationPopover: {},
  }),
);

interface Props {
  show: boolean;
  list?: any;
  closeEvent: () => void;
  activeNotifyChip: (active) => void;
  setCount: (num: number) => void;
  toggleNotif: any;
};

const CurrentRemainderNotificationModal = (props: Props) => {
  const classes = useStyles();

  const notify = useQuery('notify', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let result: any = null;
    result = await getBell({
      primary: '71a95970-2b70-4586-8813-44320ffc5fc1',
      history: '71a95970-2b70-4586-8813-44320ffc5fc1',
      org: uid
    });

    props.activeNotifyChip(!!result?.data?.data.length);
    return result?.data?.data;
  });

   ///Новые оповещения
 const [data, setData] = useState<any>([])
 const [id, setId] = useState<any>([])
 const [expanded, setExpanded] = React.useState(false);

  const handleChange = (id: any) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

 const getCount = async() => {
  const uid = await cookie.parse(document.cookie)['user_uid'];
  await countNotifications({
    "primary_data_type_uid": "b112d499-d632-42d8-970f-c6747ee54de3",
    "user_uid": uid
  })
  .then(res => props.setCount(res.data.count_id.count_id))
  .catch(err => console.log(err))
 }

 const getDataNotif = async() => {
  const uid = await cookie.parse(document.cookie)['user_uid'];
  await newNotifications({
    "primary_data_type_uid": "b112d499-d632-42d8-970f-c6747ee54de3",
    "user_uid": uid
  })
  .then(res => {
    const result = res.data.new_bell.sort((a,b) => b.id - a.id)    
    setData(result)})
  .catch(err => console.log(err))
 }


 const getUpdateNotif = async(id) => {
  const uid = await cookie.parse(document.cookie)['user_uid'];
  await new1Notifications({
    "id": id,
    "primary_data_type_uid": "b112d499-d632-42d8-970f-c6747ee54de3",
    "user_uid": uid
  })
  .then(res => {
    getCount()
    getDataNotif()
  })
  .catch(err => console.log(err))
 }

 useEffect(() => {
  getCount()
  getDataNotif()
 }, [props.toggleNotif]);

 
 const getId = (ids) => {
  setId(prevState => [...prevState, ids])
  const res = data.map((el) => (
    el.id === ids
      ? { ...el, viewed: true }
      : el
  ));
  setData(res)
 }

 const viewed = () => {
    const uniq = [...new Set(id)]
    uniq.map(el => getUpdateNotif(el))
    getCount()
    getDataNotif()
 }


  return (
    <div onMouseLeave={() => props.closeEvent()} className={classNames(classes.notificationModal, props.show ? classes.show : '')}> 
      <div className={classes.notificationHeader}>
        <h1 className={classes.notificationTitle}>Уведомления</h1>
        <span className={classes.notificationClose} onClick={() => {
          setExpanded(false)
          viewed()
          props.closeEvent()}}>
          <Close />
        </span>
      </div>
      <div className={classes.notificationContent}>
      {data.length > 0 ?
      data.map(el => {
          return (
            <Accordion expanded={expanded  === el.id ? true : null} onClick={() => {
              if(el.viewed === false) {
                getUpdateNotif(el.id)
                getId(el.id)
              }
              }}
              onChange={handleChange(el.id)}
              className={classes.accardion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography className={el.viewed ? classes.accardionTitle1 : classes.accardionTitle}>{el.type}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Typography className={classes.accardion}>
                    {el.text}
                  </Typography>
                  <Typography className={classes.time}>
                    {moment(el.date_time).format('LLL')}
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
          )
        })
        :
        <div className={classes.accardionTitle1}>
          Данные отсутствуют
        </div>
      }
      </div>
    </div>
  )
}

export default CurrentRemainderNotificationModal;
