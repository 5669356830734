import cookie from 'cookie';

  export async function getLocalStorageValue(key: string) {
    const value = await cookie.parse(document.cookie)[key];
    // const value = localStorage.getItem(key);
    return value;
    // if (!value) return null;
    // try {
    //   return value;
    // } catch (error) {
    //   return null;
    // }
  }

export function removeLocalStorageValue(key: string) {
  document.cookie = `${key}= ;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function setLocalStorage(key: string, value: string) {
  // localStorage.setItem(key, JSON.stringify(value));
  document.cookie = `${key}=${JSON.stringify(value)}`;
}
