import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Input, Button } from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import { MenuItem, Select, TextField, FormControl} from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { getAnalist, getArticleFilter, getBrandFilter, getCategoryFilter, getFixPoint, setFixPoint, setImport } from "../../api/reports";
import cookie from 'cookie';
import Search from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import fileDownload from "../../common/file-download";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        background: '#F9F9F9',
        padding: '25px 15px',
        borderRadius: 5
    },
    leftBlock: {
        background: '#EDEDED',
        padding: 15,
        border: '1px solid #A6A6A6',
        borderRadius: '3px',
        marginRight: '60px'
    },
    rowSelectWhere: {
        height: 30,
        minWidth: 100,
        background: '#EDEDED',
        border: '1px solid #D3D1CB',
        boxSizing: 'border-box',
        borderRadius: 8,
        marginRight: 20,
        textOverflow: 'ellipsis',
        width: '120px',

        '&::before': {
          display: 'none',
        },
        '&::after': {
          display: 'none',
        },
        '& .MuiSelect-select': {
          paddingLeft: 10,
        },
      },
    dates: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    inputNone: {
        background: 'transparent',
        border: 'none',
        width: '87px',
        padding: '3px',
        '&::-webkit-input-placeholder': {
            color: 'black'
        },
        '&:-moz-placeholder': {
        color: 'black'
        },
        '&::-moz-placeholder': {
        color: 'black'
        },
        '&:-ms-input-placeholder': {
        color: 'black'
        }
    },
    input: {
        width: '87px',
        padding: 4,
        borderRadius: 7,
        border: '1px solid black',
        '&::-webkit-input-placeholder': {
            color: 'black'
        },
        '&:-moz-placeholder': {
        color: 'black'
        },
        '&::-moz-placeholder': {
        color: 'black'
        },
        '&:-ms-input-placeholder': {
        color: 'black'
        }
    },
    inputNew: {
        border: 'none',
        maxWidth: '100px',
        borderRadius: '5px'
    },
    btn: {
        background: '#272727',
        outline: 'none',
        border: 'none',
        color: 'white',
        textTransform: 'uppercase',
        padding: '5px 30px',
        borderRadius: '5px',
        width: '160px',
        cursor: 'pointer',
        fontSize: '18px',
    },
    pred: {
        margin: '0px 10px',
    },
    block: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
        flexWrap: 'wrap'
    },
    textArea: {
        width: '415px',
        height: '50px'
    },
    rightBlock: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    wrapFilter: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20
    },
    btnRight: {
        border: '2px solid #E4E4E4',
        borderRadius: '5px',
        outline: 'none',
        background: '#EDEDED',
        textTransform: 'uppercase',
        width: '150px',
        padding: '5px 0',
        marginTop: '15px',
        fontSize: '18px',
        cursor: 'pointer'
    },
    block2: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    wrapperBlock: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    blockBtn: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    inputAuto: {
        '& .MuiInput-underline:after': {
          borderBottom: '2px solid black',
        },
        '& .MuiAutocomplete-inputFocused': {
          width: '100%',
        },
        display: 'flex',
        padding: '0px 15px',
        background: '#E9E9E7',
        alignItems: 'center',
        borderRadius: 10
    },
  })
);

interface Props {
    tableData: (el: any) => void;
    update: boolean
  };

const BlockFilters = (props: Props) => {
    const classes = useStyles();
    const mask = "9999.99.99 г.";
    const [brands, setBrands] = useState<Array<string>>([''])
    const [cats, setCats] = useState<Array<string>>([''])
    const [articles, setArticles] = useState<Array<string>>([''])
    const [dateFix, setDateFix] = useState<string>('')
    const [dateStart, setDateStart] = useState<string>('')
    const [dateEnd, setDateEnd] = useState<string>('')
    const [points, setPoints] = useState<any>('')

    const [dateStartNew, setDateStartNew] = useState<string>('')
    const [dateEndNew, setDateEndNew] = useState<string>('')

    const [input1, setInput1] = useState('')
    const [input2, setInput2] = useState('')


    const [brand, setBrand] = useState<string>('')
    const [cat, setCat] = useState<string>('')
    const [article, setArticle] = useState<Array<string>>([''])
    const [point, setPoint] = useState<any>('')

    const getDataAnalist = async(toggle) => {
        const org_uid = cookie.parse(document.cookie)['org_uid'];
        const res = await getAnalist({
          brand: brand? [brand] : null,
          category: cat? [cat] : null,
          date_fix: dateFix ? dateFix.replaceAll(' г.', '').replaceAll('.', '-') : null,
          date_start: dateStart ? dateStart.replaceAll(' г.', '').replaceAll('.', '-') : null,
          date_stop: dateEnd ? dateEnd.replaceAll(' г.', '').replaceAll('.', '-') : null,
          organization_uid: org_uid,
          print: toggle,
          supplier_article: article.length > 0 ? article : null
        })
        setDateStart(res.data.date_start)
        setDateEnd(res.data.date_end)

        setDateStartNew(res.data.date_start)
        setDateEndNew(res.data.date_end)
        props.tableData(res.data);
        setInput1(res.data.analist.order_amount)
        setInput2(res.data.analist.ordered)
        if(res.data.xlsx) {
            fileDownload(res.data.xlsx, `data.xlsx`)
        }

    }

    const fileInput = React.useRef<any>(null);

    const uploadFile = () => {
        fileInput?.current?.click();
    };

    const onFileChange = async (e: any) => {
         const file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async function () {
          if(reader.result) {
            let fileData = reader.result.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', '');
                const res = await setImport({
                    xlsx: fileData
                })
                const val = res.data.values.filter((el: string) => el !== null)
                setArticle(val);
          }
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      };

    useEffect(() => {
        if(props.update === true) getDataAnalist(false)
    }, [props.update]);


    const getFixingPoint = async() => {
        const org_uid = cookie.parse(document.cookie)['org_uid'];
        const res = await getFixPoint({
            organization_uid: org_uid
        })
        setPoints(res.data.fixing_point);
    }


    const getBrand = async() => {
        const org_uid = cookie.parse(document.cookie)['org_uid'];
        const res = await getBrandFilter({
          organization_uid: org_uid
        })
        setBrands(res.data.brand);
    }

    const getCategory = async(brand) => {
        const org_uid = cookie.parse(document.cookie)['org_uid'];
        const res = await getCategoryFilter({
            organization_uid: org_uid,
            brand: brand
        })
        setCats(res.data.filter_category);
    }

    const getArticle = async(brand, cat) => {
        const org_uid = cookie.parse(document.cookie)['org_uid'];
        const res = await getArticleFilter({
            organization_uid: org_uid,
            brand: brand,
            category: cat,
        })
        setArticles(res.data.filter_article);
    }

    useEffect(() => {
        getCategory(brand)
    }, [brand, cat]);

    useEffect(() => {
        getArticle(brand, cat)
    }, [brand, cat]);

    useEffect(() => {
        getBrand()
        getFixingPoint()
    }, []);


    const changeBrand = (val) => {
        setBrand(val)
    }

    const changeCat = (val) => {
        setCat(val)
    }

    const changeArticle = (val) => {
        setArticle(val)
    }

    const handleChange = (e, newValue) => {
        setPoint(newValue);
    }

    const save = async(toggle) => {
        const org_uid = cookie.parse(document.cookie)['org_uid'];
        if(point) {
            const res = await setFixPoint({
                date: point.date_,
                del_: toggle,
                name: point.name_,
                organization_uid: org_uid
            })
            if(res.data.success) {
                if(toggle === false) {
                    const date = point.date_.replaceAll('-', '.')
                    setDateFix(date)
                }
                else {
                    setDateFix('')
                }
            }
        }
    }


    return (
        <div className={classes.wrapper}>
            <div className={classes.leftBlock}>
                <div style={{textAlign: 'center',marginBottom: 20}}>Задать ТОЧКУ ФИКСАЦИИ для расчета среднего значения</div>
                <div className={classes.block}>
                    <div className={classes.dates}>
                        <div style={{marginRight: 10}}>Дата:</div>
                        <ReactInputMask
                            className={classes.input}
                            mask={mask}
                            value={dateFix}
                            placeholder="____.__.__ г."
                            // onChange={(e: any) => setDateFix(e.target.value.replaceAll(' г.', '').replaceAll('.', '-'))}
                        />
                    </div>
                    <div className={classes.inputAuto}>
                        <Search color="disabled" style={{fontSize: 30, marginRight: 7}}/>

                        <Autocomplete
                          options={points}
                          getOptionLabel={(option: any) => `${option?.date_ ? option?.date_ : ''}`}
                          value={point}
                          style={{ width: 170 }}
                          onChange={handleChange}
                          renderInput={(params) => <TextField {...params} placeholder="Введите дату ..." />}
                        />
                      </div>
                      {!dateFix ?
                      <button className={classes.btn} onClick={() => save(false)}>Сохранить</button>
                      :
                      <button className={classes.btn} onClick={() => save(true)}>Удалить</button>
                      }

                </div>
                <div className={classes.block}>
                    <div className={classes.dates}>
                        <div>Диапозон дат:</div>
                        <span className={classes.pred}>с</span>
                        <ReactInputMask
                            className={classes.input}
                            mask={mask}
                            placeholder="____.__.__ г."
                            onChange={(e: any) => setDateStart(e.target.value.replaceAll(' г.', '').replaceAll('.', '-'))}
                        />
                        <span className={classes.pred}>по</span>
                        <ReactInputMask
                            className={classes.input}
                            mask={mask}
                            placeholder="____.__.__ г."
                            onChange={(e: any) => setDateEnd(e.target.value.replaceAll(' г.', '').replaceAll('.', '-'))}
                        />
                    </div>
                    <button className={classes.btn} onClick={() => getDataAnalist(false)}>Рассчитать</button>
                </div>
                <div className={classes.block}>
                    <div className={classes.dates}>
                        <div>Средняя цена заказа за период</div>
                        <span className={classes.pred}>с</span>
                        <ReactInputMask
                            className={classes.inputNone}
                            mask={mask}
                            value={dateStartNew}
                            placeholder="____.__.__ г."
                        />
                        <span className={classes.pred}>по</span>
                        <ReactInputMask
                            className={classes.inputNone}
                            mask={mask}
                            value={dateEndNew}
                            placeholder="____.__.__ г."
                        />(₽)
                    </div>
                    <input value={input1} className={classes.inputNew} type="text" />
                </div>
                <div className={classes.block}>
                    <div className={classes.dates}>
                        <div>Средний спрос за период</div>
                        <span className={classes.pred}>с</span>
                        <ReactInputMask
                            className={classes.inputNone}
                            mask={mask}
                            value={dateStartNew}
                            placeholder="____.__.__ г."
                        />
                        <span className={classes.pred}>по</span>
                        <ReactInputMask
                            className={classes.inputNone}
                            mask={mask}
                            value={dateEndNew}
                            placeholder="____.__.__ г."
                        />
                        (шт)
                    </div>
                    <input className={classes.inputNew} value={input2} type="text" />
                </div>
            </div>


            <div  className={classes.rightBlock}>
                <div className={classes.wrapFilter}>
                  <FormControl>
                    <Select
                        value={brand}
                        onChange={(e) => changeBrand(e.target.value)}
                        displayEmpty
                        className={classes.rowSelectWhere}
                        inputProps={{ 'aria-label': 'Without label' }}
                        >
                        <MenuItem value="" disabled>
                            Бренд
                        </MenuItem>
                        {brands && brands.map((el: string) => (
                            <MenuItem value={el}>{el}</MenuItem>
                        ))
                        }
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select
                    value={cat}
                    onChange={(e) => changeCat(e.target.value)}
                    displayEmpty
                    className={classes.rowSelectWhere}
                    inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="" disabled>
                            Категория
                        </MenuItem>
                        {cats && cats.map((el: string) => (
                            <MenuItem value={el}>{el}</MenuItem>
                        ))
                        }
                    </Select>
                  </FormControl>

                  <FormControl>
                        <Select
                        value={article}
                        onChange={(e) => changeArticle(e.target.value)}
                        displayEmpty
                        multiple
                        className={classes.rowSelectWhere}
                        inputProps={{ 'aria-label': 'Without label' }}
                        >
                        <MenuItem value="" disabled>
                            Артикул
                        </MenuItem>
                        {articles && articles.map((el: string) => (
                            <MenuItem value={el}>{el}</MenuItem>
                        ))
                        }
                        </Select>
                    </FormControl>
                    <div>Фильтр</div>
                </div>
                <div className={classes.block2}>
                    <div
                        style={{width: '500px', height: 'auto', background: '#F4F4F4', border: 'none', borderRadius: 5}}
                        aria-label="minimum height"
                        placeholder="Выбранные товары"
                    >
                        {article.map(el => (
                           <div style={{padding: '5px 5px'}}>{el}</div>
                        ))}
                    </div>
                    <input
                        ref={fileInput}
                        type="file"
                        name="file"
                        id="file"
                        style={{ display: 'none', }}
                        accept=".xlsx"
                        onChange={(e: any) => onFileChange(e)}
                    />

                    <Button
                        className={classes.btnRight}
                        onClick={() => uploadFile()}
                    >
                        Импорт
                    </Button>
                    <Button className={classes.btnRight} onClick={() => getDataAnalist(true)}>Экспорт</Button>
                </div>
            </div>
        </div>
     );
}

export default BlockFilters;
