import { makeStyles, createStyles, ButtonGroup, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect } from 'react'
import { Check } from '@material-ui/icons';
import { useQuery } from 'react-query';
import CustomModal from '../../UI/CustomModal';
import cookie from 'cookie';
import { getCalcOrg, setCalcOrg } from '../../../api/calc';
import {findTariff, refreshTariffs, TariffsType} from '../../../api/tariffs';
import FreeTariffSnackbar from '../../UI/FreeTariffSnackbar';
import moment from 'moment';
import {queries} from "@testing-library/react";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      '.MuiDialog-paperWidthSm': {
        maxWidth: '786px !important',
      },
    },
    content: {},
    params: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 32,
      paddingBottom: 0,
    },
    paramsBtns: {},
    paramsBtn: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: '#37352F',
      textTransform: 'none',
      background: '#FFFFFF',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
    },
    paramsFirstBtn: {
      boxSizing: 'border-box',
      borderRadius: '8px 0px 0px 8px',
    },
    paramsLastBtn: {
      borderRadius: '0px 8px 8px 0px',
    },
    paramsForCalculationBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      paddingBottom: 2,
      borderBottom: '1px solid #37352F',
      cursor: 'pointer',
    },
    paramsModal: {
      marginTop: 32,
    },
    paramsModalText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      maxWidth: 600,
      marginBottom: 16,
    },
    paramsModalContent: {},
    paramsModalLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    paramsModalBtns: {
      width: '100%',
      marginBottom: 32,
    },
    paramsModalBtn: {
      width: '50%',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: '#37352F',
      textTransform: 'none',
      background: '#FFFFFF',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',

      '&:last-child': {
        borderRadius: '0px 8px 8px 0px',
      },
    },
    paramsModalFirstBtn: {
      boxSizing: 'border-box',
      borderRadius: '8px 0px 0px 8px',
      padding: '12px 13.5px',
    },
    paramsModalLastBtn: {
      borderRadius: '0px 8px 8px 0px',
    },
    active: {
      background: '#E9E9E7',
      border: '1px solid #37352F',
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      // marginTop: 32,
      marginBottom: 16,

      '& span': {
        color: '#FF6C4B',
      },
    },
    miniField: {
      width: '100%',
      maxWidth: '50%',
      marginBottom: 40,
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: '100%',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '14px 16px',
      width: '100%',
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,
    },
    paramsModalFields: {
      display: 'flex',
    },
    paramsModalCheckboxes: {
      display: 'flex',
      flexDirection: 'column',
    },
    paramsModalCheckbox: {
      marginBottom: 0,
      '& svg': {
        fill: '#37352F',
      },
      '& .Mui-disabled': {
        '& svg': {
          fill: '#D3D1CB',
        },
        '& span': {
          color: '#37352F',
        },
      },
      '& span': {
        fontSize: 14,
      },
      '&:last-child': {
        marginBottom: 32,
      },
    },
  })
);

interface Props {
  rubles_procent: number;
  max_sale_value: number;
  max_sale_value_1: number;
  max_sale: number;
  updateTable?: () => void;
  reportHandler?: () => void;
  setRublesProcent: (val: number) => void;
  setNewMaxSaleValue: (val: number) => void;
  setNewMaxSaleValue1: (val: number) => void;
  setLoading: (val: boolean) => void;
};

export const PriceDropParams = (props: Props) => {
  const classes = useStyles();

  const [param, setParam] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [myTarif, setTarif] = React.useState<any>(null)
  const [loaded, setLoaded] = React.useState(false);
  const [selectedBtn, setSelectedBtn] = React.useState(8);
  const [maxSaleValue, setMaxSaleValue] = React.useState<number | string>();
  const [currentMaxSaleValue, setCurrentMaxSaleValue] = React.useState<number | string>();
  const [currentMaxSaleValue1, setCurrentMaxSaleValue1] = React.useState<number | string>();
  const [maxAllowableReduction, setMaxAllowableReduction] = React.useState<number>();
  const [minPrice, setMinPrice] = React.useState<boolean>(myTarif === 'Демо' ?false : true);
  const [minStock, setMinStock] = React.useState<boolean>(false);
  const [remainsTypeBtns, setRemainsTypeBtns] = React.useState([
    { id: 1, name: 'Только fbo' },
    { id: 2, name: 'Только fbs' },
    { id: 3, name: 'fbo + fbs' },
  ]);
  const btnsWeek = [
    { id: 0, name: '0 недель' },
    { id: 1, name: '1 неделя' },
    { id: 2, name: '2 недели' },
    { id: 4, name: '4 недели' },
    { id: 6, name: '6 недель' },
    { id: 8, name: '8 недель' },
    { id: 11, name: '11 недель' },
  ];

  const [loadOrg, setLoadOrg] = React.useState(false)

  const [demandAndBalance, setDemandAndBalance] = React.useState(false)

  const [openFreeTariffSnackbar, setOpenFreeTariffSnackbar] = React.useState(false);
  const [toggleDisabled, setToggleDisabled] = React.useState(false);
  const [toggleDemandAndBalance, setToggleDemandAndBalance] = React.useState(false)

  const btns = [
    { id: 0, name: 'Допустимое снижение по цене в %' },
    { id: 1, name: 'Допустимое снижение по цене ₽' },
  ];

  const currentTariff = useQuery<TariffsType>('currentTariff', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await findTariff(uid);

    return result.data.data;
  });

  useEffect(() => {
    // setParam(props.rubles_procent);
    setSelectedBtn(props.max_sale);
  }, [loaded, props.rubles_procent, props.max_sale]);

  useEffect(() => {
    localStorage.setItem('demandAndBalance', '0')

    const min_stock = cookie.parse(document.cookie)['min_stock'];
    const min_price = cookie.parse(document.cookie)['min_price'];

    setMinStock(min_stock === 'true' ? true : false);
    if(min_price) {
      setMinPrice(min_price === 'true' ? true : false);
    }

  }, []);

  const  [stockAndPromotion, setStockAndPromotion] = React.useState({
    weeks_count: 0,
    stocks_type: 0,
    current_week: 0
  })


  useQuery(['calcOrg'], async () => {
    setLoadOrg(true)
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await getCalcOrg({ org: uid });    
    setStockAndPromotion({
      weeks_count: result?.data.data[0]?.weeks_count,
      stocks_type: result?.data.data[0]?.stocks_type,
      current_week: result?.data.data[0]?.current_week,
      })
    setParam(result.data.data[0].rubles_procent);
    setCurrentMaxSaleValue(result.data.data[0].max_sale_value);
    setCurrentMaxSaleValue1(result.data.data[0].max_sale_value_1);
    props.setRublesProcent(result.data.data[0].rubles_procent);
     setLoadOrg(false)
  }, {refetchOnWindowFocus: true});

  useQuery(['params', { param }], async (queryParam: any) => {
    if (param === -1 || typeof param !== 'number') return;
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await setCalcOrg({
      org: uid,
      rubles_procent: param,
    });

    return result.data.data;
  });

  const getEndDayDate = () => {
    const a: any = moment().startOf('day');
    const b: any = moment(moment().add(1, 'days'));
    const endDay = (moment.duration(a - b).asHours() + 29) * -1;
    return moment(moment(), 'YYYY-MM-DD').add(endDay, 'hours');
  };

  useEffect(() => {
    const fetch = async () => {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const plan = await findTariff(uid);
      setTarif(plan.data.data.planName)
    }
    fetch()
  }, []);

  const saveParamsForCalculation = async () => {
    props.setLoading(true);
    document.cookie = `min_stock=${minStock};expires=${getEndDayDate()};`;
    document.cookie = `min_price=${minPrice};expires=${getEndDayDate()};`;
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const result = await setCalcOrg({
        org: uid,
        rubles_procent: param,
        max_sale: selectedBtn,
        max_sale_value: selectedBtn === 0
          ? !maxSaleValue || maxSaleValue === '-' ? -99999999 : maxSaleValue
          : undefined,
        max_sale_value_1: selectedBtn === 1
          ? !maxSaleValue || maxSaleValue === '-' ? -99999999 : maxSaleValue
          : undefined,
        min_sale: Number(minPrice),
        min_stock: Number(minStock),
        save: true
      });

      setTimeout(() => {
        selectedBtn === 0
          ? props.setNewMaxSaleValue(
              Number(String(maxSaleValue).indexOf('-') !== -1 ? maxSaleValue : '-' + maxSaleValue)
            )
          : props.setNewMaxSaleValue1(
              Number(String(maxSaleValue).indexOf('-') !== -1 ? maxSaleValue : '-' + maxSaleValue)
            );
        if (props.updateTable) props.updateTable();
        handleCloseModal();
      }, 1500);
      setToggleDisabled(true);
      props.reportHandler()
      return result.data.data;
    } catch (error) {
      console.error(error);
      props.setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    // setMaxSaleValue('');
    props.setLoading(false);
  };

  const handleChangeParam = (id: number) => {
    setParam(id);
    props.setRublesProcent(id);
    // props.setRublesProcent(id);
  };

  const newMaxSaleValue = (val: string) => {
    setToggleDisabled(false);
    if (val.indexOf('.') !== -1) {
      if (val?.split('.').pop().length > 1) return;
    }
    if (selectedBtn === 0) {
      setMaxSaleValue(val.indexOf('-') !== -1 ? val : '-' + val);
      return;
    }
    setMaxSaleValue(Number(val.indexOf('-') !== -1 ? val : '-' + val));
  };

  const handleSelectBtn = (id: number) => {
    setSelectedBtn(id);
    setToggleDisabled(false)
    setMaxSaleValue('');
  };


  const changeMinPrice = () => {
    setMinPrice(prev => !prev)
    setToggleDisabled(false)
  }
  const changeMinStock = () => {
    setMinStock(!minStock)
    setToggleDisabled(false)
  }

  const changeDemandAndBalance = () => {
    setDemandAndBalance(!demandAndBalance)
    let param = Number(!demandAndBalance)
    localStorage.setItem('demandAndBalance', `${param}`)
    setToggleDemandAndBalance(!toggleDemandAndBalance)
  }
  return (
    <div className={classes.params}>
      <FreeTariffSnackbar
        open={openFreeTariffSnackbar}
        closeEvent={() => setOpenFreeTariffSnackbar(false)}
      />
      <CustomModal
        title="Параметры для расчетов"
        show={open}
        successText="Сохранить"
        cancelText="Отмена"
        disabledSuccessBtn={toggleDisabled ? true : false}
        successEvent={() => saveParamsForCalculation()}
        closeEvent={() => handleCloseModal()}
      >
        <div className={classes.paramsModal}>
          <p className={classes.paramsModalText}>
            Выберите условие
          </p>
          <div className={classes.paramsModalContent}>
            <ButtonGroup className={classes.paramsModalBtns} variant="outlined">
              {btns.map((item: any) => (
                <Button
                  className={
                    classNames(
                      classes.paramsModalFirstBtn,
                      classes.paramsModalBtn,
                      selectedBtn === item.id ? classes.active : ''
                    )
                  }
                  onClick={() => handleSelectBtn(item.id)}
                  key={item.id}
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </div>
        <div className={classes.paramsModalFields}>
          {selectedBtn === 0 ? (
            <div className={classNames(classes.fieldBlock, classes.miniField)}>
              {/* <label htmlFor="name" className={classes.fieldLabel}>
                Размер скидки
              </label> */}
              <div className={classes.iconField}>
                <input
                  id="name"
                  type="number"
                  placeholder="Только цифры"
                  className={classes.field}
                  value={maxSaleValue ? maxSaleValue : ''}
                  onChange={(e: any) => newMaxSaleValue(e.target.value)}
                />
                <Check className={classNames(classes.checkIcon, maxSaleValue > 0 ? classes.checkIconActive : '')} />
              </div>
            </div>
          ) : (
            <div style={{width: '50%'}}>&nbsp;</div>
          )}
          {selectedBtn === 1 && (
            <div className={classNames(classes.fieldBlock, classes.miniField)}>
              {/* <label htmlFor="name" className={classes.fieldLabel}>
                Допустимое снижение
              </label> */}
              <div className={classes.iconField}>
                <input
                  id="name"
                  type="number"
                  placeholder="Только цифры"
                  className={classes.field}
                  value={maxSaleValue ? maxSaleValue : ''}
                  onChange={(e: any) => newMaxSaleValue(e.target.value)}
                />
                <Check className={classNames(classes.checkIcon, maxSaleValue > 0 ? classes.checkIconActive : '')} />
              </div>
            </div>
          )}
        </div>
        <div className={classes.paramsModalCheckboxes}>
          <div
            class="MuiFormControlLabel-root"
            className={classes.paramsModalCheckbox}
            onClick={
              () =>
                currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'
                  ? setOpenFreeTariffSnackbar(true)
                  : null
            }
          >
            <Checkbox
              color="default"
              checked={minPrice}
              disabled={currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'}
              value={minPrice}
              onClick={
                () =>
                  currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'
                    ? setOpenFreeTariffSnackbar(true)
                    : null
              }
              onChange={() => changeMinPrice()}
            />
            <span class="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
              Учитывать минимальный порог по цене
            </span>
          </div>

          <div
            class="MuiFormControlLabel-root"
            className={classes.paramsModalCheckbox}
            onClick={
              () =>
                currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'
                  ? setOpenFreeTariffSnackbar(true)
                  : null
            }
          >
            <Checkbox
              color="default"
              checked={minStock}
              disabled={currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'}
              value={minStock}
              onChange={() => changeMinStock()}
            />
            <span class="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
              Учитывать минимальный порог по остатку
            </span>
          </div>

          <div
            class="MuiFormControlLabel-root"
            className={classes.paramsModalCheckbox}
            onClick={
              () =>
                currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'
                  ? setOpenFreeTariffSnackbar(true)
                  : null
            }
          >
            <Checkbox
              color="default"
              checked={demandAndBalance}
              disabled={currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'}
              value={demandAndBalance}
              onChange={() => changeDemandAndBalance()}
            />
            <span class="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
                Учитывать соотношение спроса и остатка
            </span>
          </div>

          {loadOrg && <span>......</span>}
          {demandAndBalance && !loadOrg &&
            <div style={{display: 'flex', paddingLeft: 15, paddingBottom: 15}}>
              { stockAndPromotion.current_week === 1 && stockAndPromotion.weeks_count !== 0 ?
                <div style={{display: 'flex', paddingLeft: 6}}>
                  {
                    btnsWeek.map(week => {
                      return (
                          stockAndPromotion.weeks_count === week.id &&
                          <div>
                            <span style={{marginLeft: 5, fontSize: 14}}> {week.name},</span>
                          </div>
                      )
                    })
                  }

                  <div style={{paddingTop: 2, fontSize: 14, marginLeft: 4}}> {stockAndPromotion.current_week === 1 ? ' C текущей неделей продаж' : ' Без текущей недели продаж'}</div>,
                  {
                    remainsTypeBtns.map(remain => {
                      return (
                        stockAndPromotion.stocks_type === remain.id &&
                        <div>
                          <span style={{marginLeft: 5, fontSize: 14}}> {remain.name}</span>
                        </div>
                      )
                    })
                  }
                </div>
                 :
                  <div style={{display: 'flex', paddingLeft: 6}}>

                    { stockAndPromotion.current_week !== 1 &&
                      btnsWeek.map(week => {
                        return (
                            stockAndPromotion.weeks_count === week.id &&
                            <div>
                              <span style={{fontSize: 14}}> {week.name}, </span>
                            </div>
                        )
                      })
                    }

                    <span style={{fontSize: 14, marginRight: 5, paddingTop: 2}}> {stockAndPromotion.current_week === 0 ? 'Без текущей недели продаж' : ' С текущей неделей продаж'}, </span>

                    {
                      remainsTypeBtns.map(remain => {
                        return (
                          stockAndPromotion.stocks_type === remain.id &&
                          <div>
                            <span style={{fontSize: 14}}>{remain.name}</span>
                          </div>
                        )
                      })
                    }
                  </div>
              }


              {/*<span style={{marginLeft: 5, fontSize: 14}}>Тип склада: {stockAndPromotion.stocks_type}</span>*/}
            </div>
          }


          {/* <FormControlLabel
            className={classes.paramsModalCheckbox}
            control={
              <Checkbox
                color="default"
                checked={minPrice}
                disabled={currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'}
              />
            }
            label="Учитывать минимальный порог по цене"
            value={minPrice}
            onClick={
              () =>
                currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'
                  ? setOpenFreeTariffSnackbar(true)
                  : null
            }
            onChange={() => setMinPrice(!minPrice)}
          />
          <FormControlLabel
            className={classes.paramsModalCheckbox}
            control={
              <Checkbox
                color="default"
                checked={minStock}
                disabled={currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'}
              />
            }
            onClick={
              () =>
                currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'
                  ? setOpenFreeTariffSnackbar(true)
                  : null
            }
            label="Учитывать минимальный порог по остатку"
            value={minStock}
            onChange={() => setMinStock(!minStock)}
          /> */}
        </div>
      </CustomModal>
      <ButtonGroup className={classes.paramsBtns} variant="outlined">
        <Button
          className={
            classNames(
              classes.paramsFirstBtn,
              classes.paramsBtn,
              param === 0 ? classes.active : ''
            )
          }
          onClick={() => handleChangeParam(0)}
        >
          Показать в %
        </Button>
        <Button
          className={
            classNames(
              classes.paramsLastBtn,
              classes.paramsBtn,
              param === 1 ? classes.active : ''
            )
          }
          onClick={() => handleChangeParam(1)}
        >
          Показать в ₽
        </Button>
      </ButtonGroup>

      <span className={classes.paramsForCalculationBtn} onClick={() => setOpen(true)}>
        Параметры для расчетов
      </span>
    </div>
  )
}
