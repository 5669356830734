// import { ThemeProvider } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from "react";
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

// import MainLogo from './assets/icons/mainImage.svg';
// import VerificationForm from './pages/Verification';
import Profile from './pages/Profile';
import Registration from './pages/Registration';
import Verification from './pages/Verification';
import Login from './pages/Login';
import { getLocalStorageValue } from "./utils";
import ProfileOrganization from './pages/Profile/Organization';
import LoadData from "./pages/LoadData";
import TariffPay from "./pages/Profile/TariffPay";
import VerificationRegistration from "./pages/VerificationRegistration";
import AdminPage from "./pages/Admin";
import AdminTariffs from "./pages/Admin/Tariffs";
import SupportFAQ from "./pages/Support/FAQ";
import Support from "./pages/Support";
import { AdminUsers } from "./pages/Admin/Users";
import { ProfileUsers } from "./pages/Profile/Users";
import WBData from "./pages/Admin/WBData";
import { LoadDataParams } from "./pages/LoadData/Params";
import LoadDataParamsV2 from "./pages/LoadData/ParamsV2";
import ManagePrices from "./pages/ManagePrices";
import { PriceDrop } from "./pages/ManagePrices/PriceDrop";
import { LogisticsCost } from "./pages/ManagePrices/LogisticsCost";
import { CurrentRemainder } from "./pages/ManagePrices/CurrentRemainder";
import { NotFound } from "./pages/NotFound";
import Main from "./components/layout/main";
import { Stocks } from "./pages/Profile/Stocks";
import { CurrentRemainderDemo } from "./pages/ManagePrices/CurrentRemainderDemo";
import { getUsers } from "./api/auth";
import AdminDocuments from "./pages/Admin/Documets";
import License from "./pages/Admin/License";
import PersonalData from "./pages/Admin/PersonalData";
import PrivacyPolicy from "./pages/Admin/PrivacyPolicy";
import DataNew from './context/context';
import axios, {AxiosInstance, AxiosResponse} from "axios"
import cookie from "cookie";
import Role from "./pages/Admin/Role";
import Analitycs from "./pages/Analitycs";
import routes from "./components/UI/Breadcrumbs/routesList";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retryOnMount: true,
    },
  },
});

const App: React.FC = () => {

  const [data, setData] = React.useState<any | null>({
    token: null,
    loading: true,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [orange, setOrange] = useState<any>([])

  const getUserToken = async () => {
    setLoading(true);
    const value = await getLocalStorageValue('token');

    if (value) {
      let checkAdm = [];
      // if (window.location.pathname !== '/login'
      //     && window.location.pathname !== '/verification'
      //     && window.location.pathname !== '/registration'
      //     && window.location.pathname !== '/reg-verification') {
          const result: any = await getUsers(window.location.pathname);
          checkAdm = result?.data?.data?.[0]?.name_.filter(el => el.indexOf('Админ') > -1);
      // }
      setData({ admin: !!checkAdm?.length, token: value, loading: false });
    }
  };


  useEffect(() => {
    setInterval(() => {
      const date = new Date()
      const hours = date.getHours()
      const minutes = date.getMinutes()
      if(hours === 0 && minutes === 0) {
        document.cookie = `orange = ${''}`;
      }
    }, 1000)
  }, []);


  useEffect(() => {
    if (loading) getUserToken();
    setLoading(false);
  }, [loading]);
  const token = document.cookie.split('token="')
  const filterToken = token[1]?.split('\"')



  const logResult = useCallback(() => {
    return window.location.pathname
  }, [window.location.pathname])

  useEffect(() => {
    if(!filterToken &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/admin/jur-license_agreement' &&
      window.location.pathname !== '/admin/jur-personal_data_processing_policy' &&
      window.location.pathname !== '/admin/jur-privacy_policy'
    ) {
      window.location.replace('/login')
    }
  }, [logResult])

  return (
    <QueryClientProvider client={queryClient}>
      {!loading
        ?
          <Router>
            <DataNew.Provider value={{arrOrange: orange, funcOrange: setOrange}}>
            <Routes>
              <Route path="/" element={
                <EmptyRoute data={data}>
                  <></>
                </EmptyRoute>} />
              <Route
                path="/login"
                element={
                  <PublicRoute data={data}>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="/verification"
                element={
                  <PublicRoute data={data}>
                    <Verification />
                  </PublicRoute>
                }
              />
              <Route
                path="/registration"
                element={
                  <PublicRoute data={data}>
                    <Registration />
                  </PublicRoute>
                }
              />
              <Route
                path="/reg-verification"
                element={
                  <PublicRoute data={data}>
                    <VerificationRegistration />
                  </PublicRoute>
                }
              />

              <Route
                path="/profile"
                element={
                  <ProtectedRoute data={data}>
                    <Main showHistory={true} historyPage={'Profile'}>
                      <Profile />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile/organization"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <ProfileOrganization />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile/tariff-pay"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <TariffPay />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile/users"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <ProfileUsers />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile/stocks"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <Stocks />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/load-data"
                element={
                  <ProtectedRoute data={data}>
                    <Main showHistory={true} historyPage={'loadData'}>
                      <LoadData />
                    </Main>
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/load-data/load-params"
                element={
                  <ProtectedRoute data={data}>
                    <LoadDataParams />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/load-data/load-params"
                element={
                  <ProtectedRoute data={data}>
                    <LoadDataParamsV2 />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manage-prices"
                element={
                  <ProtectedRoute data={data}>
                    <Main showHistory={true} historyPage={'ManagePrice'}>
                      <ManagePrices />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manage-prices/current-remainder"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <CurrentRemainder />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manage-prices/current-remainder-demo"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <CurrentRemainderDemo />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manage-prices/logistics-cost"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <LogisticsCost />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manage-prices/price-drop"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <PriceDrop />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute data={data}>
                    <Main showHistory={true} historyPage={'Admin'}>
                      <AdminPage />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/tariffs"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <AdminTariffs />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/users"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <AdminUsers />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/role"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <Role />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/wb-data"
                element={
                  <ProtectedRoute data={data}>
                    <Main showHistory={true} historyPage={'AdminWBData'}>
                      <WBData />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/jur-documents"
                element={
                  <ProtectedRoute data={data}>
                    <Main showHistory={true}>
                      <AdminDocuments />
                    </Main>
                  </ProtectedRoute>
                }
              />

              <Route
                path='/admin/jur-license_agreement'
                element={
                  <OfertaRoute data={data}>
                    <License />
                  </OfertaRoute>
                }
              />

              <Route
                path='/admin/jur-personal_data_processing_policy'
                element={
                  <OfertaRoute data={data}>
                      <PersonalData />
                  </OfertaRoute>
                }
              />
              <Route
                path='/admin/jur-privacy_policy'
                element={
                  <OfertaRoute data={data}>
                      <PrivacyPolicy />
                  </OfertaRoute>
                }
              />
              <Route
                path="/support/faq"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <SupportFAQ />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/support"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <Support />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/analitycs"
                element={
                  <ProtectedRoute data={data}>
                    <Main>
                      <Analitycs />
                    </Main>
                  </ProtectedRoute>
                }
              />
              <Route
                path="*"
                element={
                  <PublicRoute data={data}>
                    <NotFound />
                  </PublicRoute>
                }
              />
            </Routes>
            </DataNew.Provider>
          </Router>
        : null}
    </QueryClientProvider>
  );
};


const EmptyRoute = ({ data, children }: { data: any, children: any }) => {
  if (data && data.token && !data.loading) {
    window.location.href = '/profile';
  }
  window.location.href = '/login';
  return <></>
};

const OfertaRoute = ({data, children}: { data: any, children: any }) => {
  // if(window.location.pathname === 'admin/jur-license_agreement') {
    // return <Navigate to="/admin"/>
  // }
  return children
}

const PublicRoute = ({ data, children }: { data: any, children: any }) => {
  if (data && data.token && !data.loading) {
    if ( window.location.pathname !== 'admin/jur-license_agreement') return <Navigate to="/admin"/>;
     return <Navigate to="/profile" replace />;
  }

  return children;
};
const ProtectedRoute = ({ data, children }: { data: any, children: any }) => {
  const location = window.location.pathname
  const routes = ['/profile', '/load-data', '/manage-prices', '/verification', '/login']
  if(data.admin && routes.includes(location)) return <Navigate to="/admin" replace />
  if (!data && !data.token && !data.loading) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
// const ProtectedAdminRoute = ({ data, children }: { data: any, children: any }) => {
//   if (!data && !data.token && !data.loading) {
//     return <Navigate to="/login" replace />;
//   }

//   return children;
// };

export default App;
