import { makeStyles, createStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import cookie from 'cookie';
import React from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { organizationsHistory, userHist } from '../../api/organizations';

const useStyles = makeStyles(() =>
  createStyles({
    historyPopupWrap: {
      position: 'absolute',
      top: 0, left: 0,
      width: '100%',
      height: '100vh',
      zIndex: 9999,
    },
    historyClose: {
      position: 'absolute',
      top: 0, left: 0,
      width: '100%', height: '100vh',
    },
    historyPopup: {
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: '#fff',
      maxWidth: 332,
      width: '100%',
      height: '100vh',
      padding: 16,
      borderLeft: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      overflowY: 'auto',
      zIndex: 9999,
    },
    historyPopupHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 32,
    },
    historyPopupTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
    },
    historyPopupClose: {
      fontSize: 24,
      cursor: 'pointer',
    },
    historyEmpty: {
      padding: '32px 60px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      border: '1px solid #E9E9E7',
      borderRadius: 8,
    },
    historyEmptyTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      textAlign: 'center',
      color: '#37352F',
      margin: 0,
      marginTop: 16,
      marginBottom: 4,
    },
    historyEmptyText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textAlign: 'center',
      color: '#37352F',
      margin: 0,
    },
    historyList: {},
    historyItem: {
      maxWidth: 260,
      marginBottom: 24,
    },
    historyItemHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 4,
    },
    historyAvatar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 20,
      height: 20,
      background: '#D3D1CB',
      borderRadius: 10,
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginRight: 4,
    },
    historyPhone: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
    },
    historyText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
      marginBottom: 4,
    },
    historyDate: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      margin: 0,
    },
    emptyHistory: {
      border: '1px solid #E9E9E7',
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '32px 60px',

      '& svg': {
        marginBottom: 16,
      },
      '& h2': {
        margin: 0,
        textAlign: 'center',
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#37352F',
        marginBottom: 4,
      },
      '& p': {
        margin: 0,
        textAlign: 'center',
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#37352F',
      },
    },
  })
)

interface Props {
  openModal?: boolean;
  history?: Array<any>;
  closeEvent?: () => void;
};

export const HistoryPopup = (props: Props) => {
  const classes = useStyles();

  const text = {
    'insert': 'Добавил в организацию',
    'update': 'Изменил',
    'delete': 'Отвязал от организации',
  };

  const closeHistory = () => {
    if (props.closeEvent) {
      props.closeEvent();
    }
  };

  return (
    <div>
      {props.openModal && (
        <div className={classes.historyPopupWrap}>
          <div className={classes.historyClose} onClick={closeHistory}></div>
          <div className={classes.historyPopup}>
            <div className={classes.historyPopupHeader}>
              <h2 className={classes.historyPopupTitle}>История действий</h2>
    
              <span className={classes.historyPopupClose} onClick={closeHistory}>
                <Close />
              </span>
            </div>
    
            <div className={classes.historyList}>
              {props?.history?.length
                ? props.history.map((item: any, index: number) => (
                  <div className={classes.historyItem} key={index}>
                    <div className={classes.historyItemHeader}>
                      <div className={classes.historyAvatar}>
                        A
                      </div>
                      <div className={classes.historyPhone}>
                        {item.user}
                      </div>
                    </div>
                    <p className={classes.historyText}>
                      {item.concat}
                    </p>
                    <p className={classes.historyDate}>
                      {item.date_action}
                    </p>
                  </div>
                ))
                : (
                  <div className={classes.emptyHistory}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z" stroke="#37352F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 2V6" stroke="#37352F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M8 2V6" stroke="#37352F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3 10H21" stroke="#37352F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <h2>Список пуст</h2>
                    <p>В истории пока еще нет ни одного события</p>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
