import { makeStyles, createStyles, Menu, MenuItem, Select, TextField, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import cookie from 'cookie';
import { Plus } from '../../UI/Icons';
import Trash from '../../UI/Icons/Trash';
import { useQuery } from 'react-query';
import { getAllFilter, getFilter, getOrganizationsAllUsers } from '../../../api/organizations';
import { Autocomplete } from '@material-ui/lab';
import { clearFilter, setFilter } from '../../../api/reports';
import classNames from 'classnames';
import * as _ from 'lodash';
import { useRows, useUsers } from '../../../pages/Admin/store';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';

const useStyles = makeStyles(() =>
  createStyles({
    toolbarFilterBtn: {
      position: 'relative',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '14px',
      color: '#37352F',
      textTransform: 'capitalize',
      borderBottom: '1px solid #000',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:hover': {
        borderBottom: '1px solid #827F75',
        color: '#827F75',
      },
    },
    toolbarFilterChip: {
      position: 'absolute',
      top: -10, right: -10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '12px',
      width: 14,
      height: 14,
      color: 'white',
      background: '#FF6C4B',
      borderRadius: '50%',
    },
    filterMenu: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 16,
        top: '126px !important',
        overflow: 'unset',
        minWidth: 500,
        maxWidth: 500,
        width: '100vh',
      },
      '& .MuiMenu-list': {
        minWidth: 500,
        maxWidth: 500,
        width: '100vh',
        padding: 0,
      },
    },
    filterContet: {
      padding: 16,
      minWidth: 500,
      maxWidth: 500,
      width: '100vh',
      boxSizing: 'border-box',
    },
    filterHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,

      '& svg': {
        width: 20,
        height: 20,
      }
    },
    filterTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    filterList: {},
    filterAddBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      textTransform: 'none',
      border: 'none',
      outline: 'none',
      background: 'none',
      padding: 0,
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        marginRight: 4,
        stroke: '#37352F',
      },
    },
    rowBtn: {
      '& svg': {
        stroke: '#FF6C4B ',
      },
    },
    rowList: {
      '& .MuiSelect-select.MuiSelect-select': {
        paddingLeft: 10
      }
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginBottom: 24,
    },
    rowLabel: {},
    rowSelectWhere: {
      height: 30,
      minWidth: 214,
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      borderRadius: 8,
      marginLeft: 16,

      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '& .MuiSelect-select': {
        paddingLeft: 10,
      },
    },
    rowEqualSymb: {
      minWidth: 33,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      borderRadius: 8,
      margin: '0 10px',

      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      }
    },
    rowSelectEqual: {
      position: 'relative',
      minWidth: 108,
      height: 30,
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      borderRadius: 8,
      marginRight: 16,

      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '& .MuiSelect-select': {
        paddingLeft: 10,
      },
      '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        opacity: 0,
      },
      '& input': {
        paddingLeft: '4px !important',
      },
      '& .MuiAutocomplete-clearIndicator': {
        display: 'none',
      },
    },
    autocompleteWrap: {
      position: 'relative',

      '& .MuiAutocomplete-popper': {
        right: 0,
        left: 'auto',
        minWidth: 300,
      }
    },
    filterCancelBtn: {
      marginLeft: 8,
    },
    filterFooter: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

interface Props {
  cols?: any;
  users?: any;
  sendFilters: (filter: any) => void;
  setFiltersUser: any
};

export const AdminUsersFilterMenu = (props: Props) => {
  const dataStore = useUsers();
  const dataRowsStore = useRows();

  const classes = useStyles();

  const cols = [
    { column_name: 'organization_name', column_caption: 'Организация' },
    { column_name: 'user_mobile', column_caption: 'Телефон' },
    { column_name: 'user_email', column_caption: 'E-mail' },
    { column_name: 'plan_name', column_caption: 'Тариф' },
    { column_name: 'date_end', column_caption: 'Дата окончания тарифа' },
    { column_name: 'date_last_update', column_caption: 'Дата и время последнего изменения' },
    { column_name: 'plan_is_confirmed', column_caption: 'Подтверждение оплаты' },
  ];
  const [symbols, setSymbols] = React.useState(['=', '!=']);
  const [symbols1, setSymbols1] = React.useState(['>', '<']);



  const [showFilter, setShowFilter] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowFilter(true);
  };


  const handleCloseFilter = () => {
    setShowFilter(false);
  };
  const addRow = () => {
    let copyRow: any = JSON.parse(JSON.stringify(dataRowsStore.rows));
    copyRow.push({ where: '', equal: '', equalList: [], symbol: '=' });
    dataRowsStore.getDataRows(copyRow)
  };
  const deleteRow = async (index: number) => {
    let copyRow: any = JSON.parse(JSON.stringify(dataRowsStore.rows));
    copyRow.splice(index, 1);
    dataRowsStore.getDataRows(copyRow)
    props.sendFilters({});
  };
  const sendDelRow = async (copyRow: any, index:number) => {
    if (copyRow[index].where && copyRow[index].equal) {
      const uid = await cookie.parse(document.cookie)['org_uid'];

      await setFilter({
        filter: copyRow[index].where,
        value_filter: String(copyRow[index].equal.val),
        type: 0,
        organization_uid: uid,
        primary_data: '9a5956f9-a9f6-49c0-8080-bb1fda1251a7',
        del: true
      });
    }
  };



  const filterRows = async() => {
    const result = dataRowsStore.rows.map(el => {
      if(el.equal instanceof Object && el.where) {
        return {row: el.where, value: el.equal.val, symbol: el.symbol}
      }
      if(el.equal && el.where) {
        return {row: el.where, value: el.equal, symbol: el.symbol}
      }
      else {
        return null
      }
    })

    const resultUser = await getOrganizationsAllUsers({})
    const rename = resultUser.data.data.map(el => {
      if(!el.organization_name && !el.user_email) {
        return {...el, organization_name: '', user_email: ''}
      }
      if(!el.organization_name) {
        return {...el, organization_name: ''}
      }
      if(!el.user_email) {
        return {...el, user_email: ''}
      }
      else return el
    })

    rename.sort(function(a, b) {
      if (a.organization_name > b.organization_name) {
        return -1;
      }
      if (a.organization_name < b.organization_name) {
        return 1;
      }
      return 0;
    });

    let sorted = _.sortBy(rename, ['plan_name', 'plan_is_confirmed']);

    const arr: any = []

    sorted.filter(el => {

      result.map(item => {
      if(item) {
        if(item.symbol === '=') {
          if(String(el[item.row]) === item.value) {
            arr.push(el)
          }
        }
        if(item.symbol === '!=') {
          if(String(el[item.row]) !== item.value) {
            arr.push(el)
          }
        }
        if(item.symbol === '>') {
          if(new Date(el[item.row]).getTime() > new Date(item.value).getTime()) {
            arr.push(el)
          }
        }
        if(item.symbol === '<') {
          if(new Date(el[item.row]).getTime() < new Date(item.value).getTime()) {
            arr.push(el)
          }
        }
      }
    })
    })

    if(arr.length) {
      const uniq =  _.uniqBy(arr, 'user_mobile')
      dataStore.getDataUsers(uniq)
      props.setFiltersUser(uniq)
    }

    if(result.length === 0) {
      dataStore.getDataUsers(sorted)
      props.setFiltersUser(sorted)
    }
  }

  useEffect(() => {
    filterRows()
  }, [dataRowsStore.rows]);


  const editWhere = async (e: any, index: number) => {
    let sendCol = e.target.value;

      const resultUser = await getOrganizationsAllUsers({})
      const rename = resultUser.data.data.map(el => {
        if(!el.organization_name && !el.user_email) {
          return {...el, organization_name: '', user_email: ''}
        }
        if(!el.organization_name) {
          return {...el, organization_name: ''}
        }
        if(!el.user_email) {
          return {...el, user_email: ''}
        }
        else return el
      })

      rename.sort(function(a, b) {
        if (a.organization_name > b.organization_name) {
          return -1;
        }
        if (a.organization_name < b.organization_name) {
          return 1;
        }
        return 0;
      });

      let sorted = _.sortBy(rename, ['plan_name', 'plan_is_confirmed']);

      const equalLists = sorted.map(el => {
        if(el[sendCol] === true) {
          return {val: String(el[sendCol]), name_: 'Подтверждено'}
        }
        if(el[sendCol] === false) {
          return {val: String(el[sendCol]), name_: 'Не подтверждено'}
        }
        else {
          return {val: el[sendCol], name_: el[sendCol]}
        }
      })

      const newEndRes = _.uniqBy(equalLists, 'val')

      let copyRow: any = JSON.parse(JSON.stringify(dataRowsStore.rows));

      copyRow[index].equalList = newEndRes.filter(el => {

        return (el.name_ && el.val)
      });

      copyRow[index].symbol = '=';
      copyRow[index].where = e.target.value;
      copyRow[index].equal = null;

      dataRowsStore.getDataRows(copyRow)
  };

  const editEqual = async (newValue: any, index: number) => {
    let copyFilteredUsers = JSON.parse(JSON.stringify(dataStore.dataUsers));

    const uid = await cookie.parse(document.cookie)['org_uid'];
    let filterResult: any = [];
    let copyRow: any = JSON.parse(JSON.stringify(dataRowsStore.rows));
    copyRow[index].equal = newValue;

    if (dataRowsStore.rows[index]?.equal?.val?.length) {
      await sendDelRow(dataRowsStore.rows, index);
    }

    copyRow.forEach((item: any) => {
      if (!filterResult.find((item2: any) => item2.column === item.where)) {
        filterResult.push({ column: item.where, value: [item.equal] });
      } else {
        const idx = filterResult.findIndex((item2: any) => item2.column === item.where);
        filterResult[idx].value.push({ column: item.where, value: [item.equal] });
      }
    });

    dataRowsStore.getDataRows(copyRow)
    props.sendFilters({});
  };

  const cancelFilter = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    dataRowsStore.getDataRows([
      { where: '', equal: '', equalList: [], symbol: '=' }
    ])

    const resultUser = await getOrganizationsAllUsers({})
    const rename = resultUser.data.data.map(el => {
      if(!el.organization_name && !el.user_email) {
        return {...el, organization_name: '', user_email: ''}
      }
      if(!el.organization_name) {
        return {...el, organization_name: ''}
      }
      if(!el.user_email) {
        return {...el, user_email: ''}
      }
      else return el
    })

    rename.sort(function(a, b) {
      if (a.organization_name > b.organization_name) {
        return -1;
      }
      if (a.organization_name < b.organization_name) {
        return 1;
      }
      return 0;
    });

    let sorted = _.sortBy(rename, ['plan_name', 'plan_is_confirmed']);

    dataStore.getDataUsers(sorted)
    props.setFiltersUser(sorted)
  };

  const getOptionLabel = (option: any) => {
    return option.name_;
  }

  const editSymbol = async (e: any, index: number) => {
    let copyRow: any = JSON.parse(JSON.stringify(dataRowsStore.rows));
    const newValue = e.target.value;

    copyRow[index].symbol = newValue;
    dataRowsStore.getDataRows(copyRow)
  };

  const [oldValue, setOldValue] = React.useState('');
  const mask = "9999-99-99";
  const setNewEditEqualValue = (e: any, index: number) => {
    setOldValue(e.target.value)
  };
  return (
    <>
      <span
        className={classes.toolbarFilterBtn}
        // onClick={() => setOpenFilters(true)}
        aria-controls={showFilter ? 'filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={showFilter ? 'true' : undefined}
        onClick={handleOpenFilter}
      >
        Фильтр
        {dataRowsStore.rows.length && dataRowsStore.rows[0].where
          ? <span className={classes.toolbarFilterChip}>
            {dataRowsStore.rows.length}
          </span>
          : null}
      </span>
      <Menu
        id="filter-menu"
        className={classes.filterMenu}
        anchorEl={anchorEl}
        open={showFilter}
        onClose={handleCloseFilter}
      >
        <div className={classes.filterContet}>
          <div className={classes.filterHeader}>
            <h2 className={classes.filterTitle}>Фильтры</h2>
            <span onClick={handleCloseFilter}>
              <CloseIcon />
            </span>
          </div>
          <div className={classes.filterList}>
            <div className={classes.rowList}>
              {dataRowsStore.rows.map((item: any, index: number) => (
                <div className={classes.row} key={index}>
                  <span className={classes.rowLabel}>Где</span>
                  <Select
                    className={classes.rowSelectWhere}
                    value={item.where}
                    onChange={(e:any) => editWhere(e, index)}
                  >
                    {
                      cols?.map((col: any, i: number) =>
                        <MenuItem value={col.column_name} key={i}>
                          {col.column_caption}
                        </MenuItem>)
                    }
                  </Select>
                  {
                    item.where === 'date_last_update' || item.where === 'date_end' ?
                    <Select
                    className={classes.rowEqualSymb}
                    value={item.symbol}
                    onChange={(e: any) => editSymbol(e, index)}
                  >
                    {symbols1.map((el: any, i: number) =>
                      <MenuItem
                        value={el}
                        key={i}
                      >
                        {el}
                      </MenuItem>
                    )}
                  </Select>
                  :
                  <Select
                    className={classes.rowEqualSymb}
                    value={item.symbol}
                    onChange={(e: any) => editSymbol(e, index)}
                  >
                    {symbols.map((el: any, i: number) =>
                      <MenuItem
                        value={el}
                        key={i}
                      >
                        {el}
                      </MenuItem>
                    )}
                  </Select>
                  }

                  {
                    item.where === 'date_last_update' || item.where === 'date_end' ?
                    <ReactInputMask
                      id="phone"
                      className={classes.rowSelectEqual}
                      mask={mask}
                      value={item.equal}
                      placeholder="Дата"
                      onChange={(e) => setNewEditEqualValue(e, index)}
                      onBlur={(e) => editEqual(e.target.value, index)}
                    />
                    :
                    <div className={classes.autocompleteWrap}>
                    <Autocomplete
                      disablePortal
                      noOptionsText="Список пуст"
                      id="combo-box-demo"
                      options={item.equalList}
                      value={item.equal}
                      onChange={(e, newValue: any) => editEqual(newValue, index)}
                      getOptionLabel={(option) => getOptionLabel(option)}
                      renderOption={(props: any, category) => (
                        item.where === 'date_last_update' || item.where === 'date_end' ?
                        <Box component="li" {...props}>
                          { `${moment(props.name_).locale('ru').format('L').replace(/\./g, "-").split('-').reverse().join('-')} ${moment(props.name_).locale('ru').format('LTS')}`}
                        </Box>
                        :
                        <Box component="li" {...props}>
                          {props.name_}
                        </Box>
                      )}
                      renderInput={(params) =>
                        <TextField {...params} className={classes.rowSelectEqual} label="" />
                      }
                    />
                  </div>
                  }

                  <span className={classes.rowBtn} onClick={() => deleteRow(index)}>
                    <Trash />
                  </span>
                </div>
              ))}
            </div>
            <div className={classes.filterFooter}>
              <button className={classes.filterAddBtn} onClick={addRow}>
                <Plus /> Добавить фильтр
              </button>
              <button className={classNames(classes.filterAddBtn, classes.filterCancelBtn)} onClick={cancelFilter}>
                Сбросить
              </button>
            </div>
          </div>
        </div>
      </Menu>
    </>
  )
}
