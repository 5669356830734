import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles, Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'

import faqPicture from '../../assets/icons/faqPicture.svg'
import { ArrowForwardIos } from '@material-ui/icons'
import { useQuery } from 'react-query'
import { getFAQ } from '../../api/faq'

const useStyles = makeStyles(() => 
  createStyles({
    closeIcon: {
      position: 'absolute',
      top: 32, right: 32,
      cursor: 'pointer',
    },
    modalWrap: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      transform: 'translate(-50%, -50%)',
      zIndex: 9999,
    },
    modalBg: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      transform: 'translate(-50%, -50%)',
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 2,
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      height: '100%',
      maxWidth: 1366,
      maxHeight: 768,
      background: '#fff',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0 0 10px -3px rgb(0 0 0 / 30%)',
      overflowY: 'auto',
      borderRadius: 8,
      zIndex: 99999,
    },
    content: {
      maxWidth: 600 + 64,
      width: '100%',
      margin: '0 auto',
      padding: 32,
    },
    logo: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '38px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    title: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '23px',
      color: '#37352F',
      margin: 0,
      marginBottom: 32,
    },
    picture: {
      marginBottom: 36,
    },
    accordion: {
      border: '1px solid #37352F',
      borderRadius: '8px !important',
      boxShadow: 'none',
      marginBottom: '16px !important',

      '&::before': {
        opacity: 0,
      },
      '& .MuiAccordionSummary-content': {
        // margin: '0 !important',
        marginTop: 18,
        marginBottom: 18,
        alignItems: 'center',

        '& svg': {
          width: '14.4px !important',
          transform: 'rotate(90deg)',
          transition: 'all .3s ease',
        },
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        marginBottom: 10,
        '& svg': {
          transform: 'rotate(0deg)',
        },
      },
      '&.Mui-expanded': {
        marginTop: 0,
      },
    },
    accordionSummary: {
      minHeight: 'fit-content',
    },
    accordionTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      marginLeft: 8,
    },
    accordionText: {
      marginLeft: 32,
    },
    accordionDetails: {
      paddingTop: 0,
    },
  })
)

interface Props {
  open: boolean;
  closeEvent: () => void;
};

export const SupportFAQModal = (props: Props) => {
  const classes = useStyles();

  const faqList = useQuery('faqList', async () => {
    const result = await getFAQ();
    return result?.data?.data;
  }).data;

  return (
    <>
      {props.open && (
        <div className={classes.modalWrap}>
          <div className={classes.modalBg} onClick={props.closeEvent}></div>
          <div className={classes.modal}>
            <span className={classes.closeIcon} onClick={props.closeEvent}>
              <CloseIcon />
            </span>
            <div className={classes.content}>
              <h2 className={classes.logo}>PriceMarket</h2>
              <h3 className={classes.title}>Часто задаваемые вопросы (FAQ)</h3>
              <img src={faqPicture} className={classes.picture} alt="" />

              {faqList && faqList.map((item: any, index: number) => (
                <Accordion className={classes.accordion} key={index}>
                  <AccordionSummary
                    // expandIcon={<ExpandMore />}
                    className={classes.accordionSummary}
                  >
                    <ArrowForwardIos />
                    <Typography className={classes.accordionTitle}>{item.questText}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Typography className={classes.accordionText}>
                      {item.questAnswer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
