import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
  fill?: string;
}

const PhoneCircle = (props: Props) => {
  return (
    <svg width={props.width || '40'} height={props.height || '40'} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill={props.fill || '#E9E9E7'} />
      <path d="M29.9999 24.9201V27.9201C30.0011 28.1986 29.944 28.4743 29.8324 28.7294C29.7209 28.9846 29.5572 29.2137 29.352 29.402C29.1468 29.5902 28.9045 29.7336 28.6407 29.8228C28.3769 29.912 28.0973 29.9452 27.8199 29.9201C24.7428 29.5857 21.7869 28.5342 19.1899 26.8501C16.7738 25.3148 14.7253 23.2663 13.1899 20.8501C11.4999 18.2413 10.4482 15.2711 10.1199 12.1801C10.0949 11.9036 10.1278 11.6249 10.2164 11.3617C10.3051 11.0986 10.4475 10.8568 10.6347 10.6517C10.8219 10.4466 11.0497 10.2828 11.3037 10.1706C11.5577 10.0584 11.8323 10.0004 12.1099 10.0001H15.1099C15.5952 9.99532 16.0657 10.1672 16.4337 10.4836C16.8017 10.8001 17.042 11.2395 17.1099 11.7201C17.2366 12.6802 17.4714 13.6228 17.8099 14.5301C17.9445 14.888 17.9736 15.277 17.8938 15.651C17.8141 16.0249 17.6288 16.3682 17.3599 16.6401L16.0899 17.9101C17.5135 20.4136 19.5864 22.4865 22.0899 23.9101L23.3599 22.6401C23.6318 22.3712 23.9751 22.1859 24.3491 22.1062C24.723 22.0264 25.112 22.0556 25.4699 22.1901C26.3772 22.5286 27.3199 22.7635 28.2799 22.8901C28.7657 22.9586 29.2093 23.2033 29.5265 23.5776C29.8436 23.9519 30.0121 24.4297 29.9999 24.9201Z" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default PhoneCircle;
