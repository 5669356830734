import { makeStyles, createStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import classNames from 'classnames';
import cookie from 'cookie';
import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { calcStocks, getCalc, getCalcOrg, setCalc } from '../../api/calc';
import { updateOrgStorageType } from '../../api/organizations';
import moment from "moment";
import 'moment/locale/ru'
import { ManagePricesParams } from '../../components/ManagePrices/CurrentRemainder/Params';
import CurrentRemainderToolbar from '../../components/ManagePrices/CurrentRemainder/Toolbar';
import CurrencyFormatter from '../../components/UI/CurrencyFormatter';
import Spinner from '../../components/UI/Spinner';
import { getCellWidth } from '../../common/row-sizes'
import { Pagination } from '@material-ui/lab';
import './Pagination.css';
import DataNew from '../../context/context';
import '../../index.css'
import Hightlight from '../../common/Hightlight';
import { setColum } from '../../api/reports';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      height: 'calc(100vh - 63px)',
    },
    tableWrap: {
      overflowX: 'scroll',
      overflowY: 'scroll',
      maxHeight: 'calc(100vh - 270.6px)',
      margin: '0 32px',

      '& .MuiPaper-root': {
        backgroundColor: 'transparent !important',
      },
    },
    tableHeader: {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 8,
    },
    tableContainer: {
      boxShadow: 'none',
      borderTop: '1px solid #E9E9E7',
      boxSizing: 'border-box',
      borderBottom: '1px solid #E9E9E7',
      width: 'fit-content !important'
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',

      '& .MuiTableRow-head': {
        background: '#fff',
      },
      '& .MuiTableCell-head': {
        padding: '8px 8px',
        borderRight: '1px solid #E9E9E7',
        verticalAlign: 'middle',
        textAlign: 'center',
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        padding: '0 8px !important',
        borderRight: '1px solid #E9E9E7',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 8px !important',
        borderRight: 'none',
      },

    },
    pagin: {
      '&.MuiPaginationItem-page.Mui-selected': {
        color: '#fff',
        backgroundColor: '#BF11A6',
      }
    },
    tableRow: {
      // background: '#F2F2F0',
      // background: 'rgba(0, 0, 0, 0.03)',
      height: 28,

      '&:hover': {
        background: 'rgba(242, 242, 240, 0.5) !important',
      },
    },
    errorRow: {
      background: 'rgba(255, 108, 75, 0.1)',
      '&:hover': {
        background: '#f2e3df !important',
      },
    },
    errorRow1: {
      background: '#ffa161',
      '&:hover': {
        background: '#f2e3df !important',
      },
    },
    successRow: {
      background: 'rgba(0, 0, 0, 0.03)',
      '&:hover': {
        background: '#e5eae8 !important',
      },
    },
    tableCell: {
      minHeight: 28,
      fontSize: '0.775rem',
      padding: 10,
      '&:hover': {
        background: 'rgba(225, 225, 228, 0.5) !important'
      },
    },
    tableCellTh: {
      verticalAlign: 'middle',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      padding: 10,
      position: 'relative',
      backgroundColor: '#fff',

      '& span': {
        fontWeight: 'bold',
        color: 'black',
      },
    },
    paramsAccordion: {
      width: 'fit-content',
      boxShadow: 'none',
      marginTop: '0 !important',
      borderBottom: '1px solid #E9E9E7',

      '&::before': {
        opacity: '0 !important',
      },
    },
    paramsAccordionsSummary: {
      padding: '0 !important',

      '& .MuiAccordionSummary-content': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& p': {
        display: 'flex !important',
        alignItems: 'center !important',
      },
      '& svg': {
        width: 16,
        transition: 'all .2s ease-out',
      },
      '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '24px !important',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
      },
      '& .Mui-expanded svg': {
        transform: 'rotate(90deg)',
      },
    },
    paramsAccordionTitle: {
      width: 'fit-content',
      marginLeft: 13,
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    paramsAccordionsTitleBlock: {
      display: 'flex !important',
      alignItems: 'center !important',
      position: 'sticky',
      top: 0,
      left: 0,
    },
    paramsAccordSuccessChip: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#177367',
      padding: '4px 10px',
      maxWidth: 29,
      maxHeight: 24,
      background: 'rgba(23, 115, 103, 0.1)',
      borderRadius: 8,
    },
    paramsAccordionsDetails: {},
    paramsAccordionsList: {
      padding: 0,
      paddingLeft: 12,
      listStyle: 'none',
      margin: 0,
    },
    paramsAccordionsListItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'justify',
      color: '#827F75',
      marginBottom: 4,
    },
    paramsAccordionDetails: {
      padding: '0 !important',
      width: 'fit-content'
    },
    cellCheckbox: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      padding: 0,

      '& svg': {
        fill: '#37352F',
      },
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
    cellInput: {
      width: '100%',
      background: 'transparent',
      border: 'none',
      outline: 'none',
    },
  })
);

export const CurrentRemainder = () => {
  const classes = useStyles();
  const fakeRows = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const { arrOrange } = useContext(DataNew)
  const arrOranges = arrOrange


  const [loading, setLoading] = React.useState(true);
  const [filterLoaded, setFilterLoaded] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [columns, setColumns] = React.useState<any>([]);
  const [columnsReq, setColumnsReq] = React.useState([]);
  const [groupParams, setGroupParams] = React.useState([]);
  const [filterList, setFilterList] = React.useState([]);
  const [filteredTable, setFilteredTable] = React.useState([]);
  const [fullTable, setFullTable] = React.useState([]);
  const [calcType, setCalcType] = React.useState(1);
  const [weeksCount, setWeeksCount] = React.useState(8);
  const [stockType, setStockType] = React.useState(1);
  const [editQuantityRow, setEditQuantityRow] = React.useState(-1);
  const [quantityValue, setQuantityValue] = React.useState<any>();
  const [search, setSearch] = React.useState<string>('');
  const [dataFetch, setDataFetch] = useState<any>(null)
  const [columnFetch, setColumnFetch] = useState<any>(null)

  React.useEffect(() => {
    window.addEventListener('click', (e: any) => {
      if (e.target.className !== 'cell-block')
        handleEditQuantity(null, -1);
    });
  }, []);

  const currentDop = localStorage.getItem('weeksDop')

  useQuery('orgParams', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await getCalcOrg({ org: uid });
    localStorage.setItem('weeksDop', String(result.data.data[0]?.current_week === 1 ? "true" : "false"))
    localStorage.setItem('weeks', String(result.data.data[0]?.weeks_count))

    setCalcType(result.data.data[0].calculate_type);
    setWeeksCount(result.data.data[0].weeks_count);
    setStockType(result.data.data[0].stocks_type);
    return result.data.data[0];
  });

  const cols = useQuery('cols', async () => {
    setFilterLoaded(false);
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const orgStorageType = await updateOrgStorageType({ org: uid, action: 'show' });
    const result: any = await getCalc({
      org: uid,
      type: '71a95970-2b70-4586-8813-44320ffc5fc1'
    });

    let colsRes = result?.data?.data?.map((item: any) => {
      return {
        colum: item.colum,
        column_caption: item.column_caption,
        column_name: item.column_name,
        column_type: item.column_type,
        showed: item.colum !== null ? false : true,
        width: item.size ? Number(item.size) : 200,
      };
    })


    colsRes = colsRes.filter((item: any) => {
      if (orgStorageType.data.data[0].type === 0) {
        if (item.column_name === 'remaining_wb_fbs')
          return false;
        if (item.column_name === 'rest_wb_fbo')
          return true;
      }
      return true;
    });

    const resultFilter = result?.data?.filter.map((f: any) => {
      return {
        ...f,
        column_type: result.data.data.filter((c: any) => f.filter === c.column_name)?.[0]?.column_type || 'text'
      }
    });
    const filter = colsRes.map(el => (
      el.column_name === 'ordered_1'
        ? { ...el, showed: currentDop === 'true' ? false : true }
        : el
    ))
    setColumns(filter);
    setColumnsReq(colsRes);
    setFilterList(resultFilter);

    let filterResult: any = [];

    resultFilter.forEach((item: any) => {
      const idx = filterResult.findIndex((item2: any) => item2.column === item.filter);
      if (idx === -1) {
        filterResult.push({
          column: item.filter,
          value: [item.value_filter],
          type: Number(item.type)
        });
      } else {
        filterResult[idx].column = item.filter;
        filterResult[idx].type = Number(item.type);
        filterResult[idx].value.push(item.value_filter);
      }
    });

    setFilters(filterResult)
    setFilterLoaded(true);
    return result?.data?.data;
  });

  const [page, setPage] = useState(1)
  const [total, setTotalPage] = useState<any>(null)
  const [results, setResults] = useState<any>(null)


  const table = useQuery(
    ['table', { page, search, filters, columnsReq, calcType, weeksCount, stockType, colsIsLoading: cols.isLoading }],
    async (queryParams: any) => {
      if (cols.isLoading) return;
      try {
        if (!columnsReq.length) return;
        if (table.isLoading) return;
        setLoading(true);
        const uid = await cookie.parse(document.cookie)['org_uid'];
        const save = await cookie.parse(document.cookie)['save'];
        const colReq: any = [...new Set(queryParams.queryKey[1].columnsReq
          .filter((item: any) => item.showed)
          .map((item: any) => item.column_name)
        )];

        let filterResult = JSON.parse(JSON.stringify(filters));

        filterResult = filterResult.filter((item: any) => item.value?.[0]?.length);

        const result: any = await calcStocks({
          search: search,
          org: uid,
          weeks_count: weeksCount,
          calculate_type: calcType || 1,
          stocks_type: stockType,
          colum: colReq,
          filter: filterResult,
          type: 0,
          save: save === '1' ? 1 : undefined,
        }, page - 1, 500);
        setColumnFetch(queryParams.queryKey[1].columnsReq)
        const dataFetch = {
          search: search,
          org: uid,
          weeks_count: weeksCount,
          calculate_type: calcType || 1,
          stocks_type: stockType,
          colum: colReq,
          filter: filterResult,
          type: 0,
          save: save === '1' ? 1 : undefined,
        }
        setDataFetch(dataFetch)

        setTotalPage(result.data.data.maxPages)
        setResults(result.data.data.total)

        let tableResult = await result.data.data.data.map((item: any) => {
          let error = false;

          const save = cookie.parse(document.cookie)['save'];
          if (save === '1')
            error = true;
          // if (Number(item.lack_of_goods) > 0)

          return { ...item, ...{ error, disabledStockStatus: false } };
        });
        // if (search.length > 2) {
        //   tableResult = tableResult.filter((item: any) => {
        //     let searched = false;

        //     for (const key in item) {
        //       const element = item[key];

        //       if (!searched)
        //         if (typeof element === 'string')
        //           if (element.toLowerCase().indexOf(search.toLowerCase()) !== -1)
        //             searched = true;
        //     }

        //     if (searched) return item;
        //     return false;
        //   });
        // }

        let unique = result.data.data.data
          .map((item: any) => item.brand)
        unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);

        setGroupParams(unique);
        setFilteredTable(JSON.parse(JSON.stringify(tableResult)));
        setFullTable(JSON.parse(JSON.stringify(tableResult)));
        setLoading(false);

        return tableResult || [];
      } catch (error) {
        setLoading(false);
      }
    },
    {
      keepPreviousData: true,
    }
  );



  const saveData = async (supplier_article: string, val: string | number, colName: string, index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyFilteredTable = JSON.parse(JSON.stringify(filteredTable));

    if (colName === 'stocks_staus')
      if (val === 0)
        copyFilteredTable[index].disabledStockStatus = true;

    copyFilteredTable[index][colName] = String(val);
    setFilteredTable(copyFilteredTable);

    await setCalc({
      org: uid,
      column: colName === 'quantity_' ? 'quantity' : colName,
      val: val,
      articl: [supplier_article]
    });
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };
  const saveCols = (columns: any) => {
    setColumnsReq(columns);
    setColumns(columns);
  };

  const getAlign = (colType: string) => {
    switch (colType) {
      case 'int': return 'flex-end';
      case 'bool': return 'center';
    };
  };

  const updateTable = (req: any) => {
    setCalcType(req.calculate_type);
    setWeeksCount(req.weeks_count);
    setStockType(req.stocks_type);
    // table.refetch()
  };

  const checkCalcType = (name) => {
    if (calcType === 1) return name.replace(' / продажи ', ' ');
    if (calcType === 2) return name.replace('заказы / ', ' ');
    return name;
  };

  const handleEditQuantity = (item: any, idx: number) => {
    setEditQuantityRow(idx);
    setQuantityValue(item?.quantity_);
  }
  const [oranges, setOranges] = useState<any>(null)
  const [toggleBum, setToggleBum] = useState(false)



  const [time, setTime] = useState(new Date());

  // useEffect(() => {
  //   setInterval(() => {
  //    setTime(new Date());
  //   }, 1500);
  // }, []);

  useEffect(() => {
    let times = moment(time).format('LTS')
    if (times === '00:00:10') {
      document.cookie = `orange = ${''}`;
      window.location.reload()
    }
  }, [time]);

  useEffect(() => {
    let org = cookie.parse(document.cookie)['orange'];
    const oranges = [org]
    setOranges(oranges)
  }, [toggleBum]);

  useEffect(() => {
    setToggleBum(false)
  }, []);


  const light = useCallback((str) => {
    return <Hightlight filter={search} str={str} />
  }, [search])


  useEffect(() => {
    const filter = columns.map(el => (
      el.column_name === 'ordered_1'
        ? { ...el, showed: currentDop === 'true' ? false : true }
        : el
    ))
    setColumns(filter);
  }, [currentDop]);


  return (
    <>
      <div className={classes.content}>
        <Spinner open={loading} />

        <ManagePricesParams
          updateTable={() => table.refetch()}
          updateParams={(req: any) => updateTable(req)}
          setCalcType={(val: number) => setCalcType(val)}
          calculate_type={calcType}
          weeksCount={weeksCount}
          stockType={stockType}
          setLoading={(val: boolean) => setLoading(val)}
        />
        <CurrentRemainderToolbar
          dataFetch={dataFetch}
          columnFetch={columnFetch}
          filterList={filterList}
          cols={columns || []}
          count={results || 0}
          updateTable={() => table.refetch()}
          sendFilters={(filter: any) => setFilters(filter)}
          sendColumn={(columns: any) => saveCols(columns)}
          newColumnPosition={(columns: any) => setColumns(columns)}
          search={(value) => handleSearch(value)}
          refetch={() => table.refetch()}
          setLoading={(val: boolean) => setLoading(val)}
          setToggleBum={setToggleBum}
          setOranges={setOranges}
          setPage={setPage}
        />

        <div className={classes.tableWrap}>
          <TableContainer component={Paper} className={classNames(classes.tableContainer, classes.tableHeader)}>
            <Table className={classes.table}>
              <TableHead>
                {columns && (
                  <TableRow>
                    {columns.map((item: any, index: number) => (
                      item.showed && (
                        <TableCell
                          style={{
                            minWidth: item.width,
                            maxWidth: item.width,
                          }}
                          className={classes.tableCellTh}
                          key={index}
                        >
                          <div dangerouslySetInnerHTML={{ __html: checkCalcType(item.column_caption) }} />
                          {item.column_name === 'ordered_1_2' ||
                            item.column_name === 'ordered_1_4' ||
                            item.column_name === 'ordered_1_8' ||
                            item.column_name === 'ordered_1_11' ?
                            (currentDop === 'true' &&
                              <div>+ текущая неделя</div>)
                            :
                            null
                          }
                        </TableCell>
                      )
                    ))}
                  </TableRow>
                )}

              </TableHead>
            </Table>
            {filteredTable.length > 0 ?
              null : <div style={{ fontSize: 19 }}>Данных на данной странице не найдено</div>}
          </TableContainer>
          {results > 500
            ?
            (groupParams && groupParams.length) ? (
              groupParams.map((group: string) => (
                // <Accordion
                //   className={classes.paramsAccordion}
                //   TransitionProps={{ timeout: 0 }}
                //   key={group}
                // >
                //   <AccordionSummary
                //     // expandIcon={<ExpandMore />}
                //     className={classes.paramsAccordionsSummary}
                //   >
                //     <div className={classes.paramsAccordionsTitleBlock}>
                //       <ArrowForwardIos />
                //       <span className={classes.paramsAccordionTitle}>{group}</span>
                //     </div>
                //   </AccordionSummary>
                //   <AccordionDetails className={classes.paramsAccordionDetails}>
                <>

                  <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table}>
                      {(columns && table.data) ?
                        (
                          <TableBody>
                            {filteredTable.length > 0
                              ? filteredTable?.map((row: any, index: number) => (
                                (group === row.brand) &&
                                <TableRow
                                  className={
                                    classNames(
                                      classes.tableRow,
                                      row.stocks_staus !== '1' && row.disabledStockStatus
                                        ? classes.successRow
                                        : null,
                                      row.stocks_staus === '1' && row.error
                                        ? classes.errorRow
                                        : null,

                                      oranges[0]?.indexOf(String(row.id_wb)) !== -1
                                        ? classes.errorRow1
                                        : null
                                      ,
                                    )
                                  }
                                  key={index}
                                >
                                  {columns?.map((item: any, idx: number) => (
                                    item.showed && (
                                      <TableCell
                                        style={{
                                          minWidth: item.width,
                                          maxWidth: item.width,
                                          textAlign: item.column_name === 'stocks_staus' ? 'center' : 'left',
                                        }}
                                        onClick={() =>
                                          item.column_name === 'quantity_'
                                            ? handleEditQuantity(row, index)
                                            : null
                                        }
                                        className={classes.tableCell}
                                        key={idx}
                                      >
                                        <div
                                          style={{
                                            wordWrap: 'break-word',
                                            justifyContent: getAlign(item.column_type),
                                          }}
                                          className='vid'
                                          class="cell-block"
                                        >
                                          {item.column_name !== 'stocks_staus'
                                            && item.column_name !== 'quantity_'
                                            ? (
                                              <>
                                                {item.column_type === 'int'
                                                  ? <CurrencyFormatter
                                                    light={light}
                                                    currency={
                                                      Number(row[item.column_name])
                                                    }
                                                  />
                                                  : row[item.column_name] !== null &&
                                                  light(row[item.column_name] + '')
                                                }
                                              </>
                                            ) : null}
                                          {item.column_name === 'quantity_'
                                            ? (
                                              <>
                                                {item.column_type === 'int'
                                                  ? editQuantityRow !== index
                                                    ? <CurrencyFormatter
                                                      light={light}
                                                      currency={
                                                        Number(row[item.column_name])
                                                      }
                                                    />
                                                    : <input
                                                      autoFocus
                                                      type="number"
                                                      className={classes.cellInput}
                                                      value={light(String(quantityValue))}
                                                      onChange={(e: any) => setQuantityValue(e.target.value)}
                                                      onBlur={() =>
                                                        saveData(
                                                          row.supplier_article,
                                                          quantityValue || '0',
                                                          item.column_name,
                                                          index
                                                        )
                                                      }
                                                    />
                                                  : null}
                                              </>
                                            ) : null}
                                          {item.column_name === 'stocks_staus'
                                            ? (
                                              <FormControlLabel
                                                className={classes.cellCheckbox}
                                                control={
                                                  <Checkbox
                                                    color="default"
                                                    checked={item.stocks_staus}
                                                  />
                                                }
                                                checked={row[item.column_name] === '1'}
                                                onChange={() =>
                                                  saveData(
                                                    row.supplier_article,
                                                    row[item.column_name] === '0'
                                                      || !row[item.column_name] ? 1 : 0,
                                                    item.column_name,
                                                    index
                                                  )
                                                }
                                                label={undefined}
                                              />
                                            ) : null}
                                        </div>
                                      </TableCell>
                                    )
                                  ))}
                                </TableRow>
                              ))
                              // : fakeRows?.map((row: any, index: number) => (
                              //   <TableRow
                              //     className={classes.tableRow}
                              //     style={{
                              //       height: 28,
                              //       background: row.error ? 'rgba(255, 108, 75, 0.1);' : 'transparent'
                              //     }}
                              //     key={index}
                              //   >
                              //     {columns?.map((item: any, idx: number) => (
                              //       <TableCell
                              //         style={{
                              //           minWidth: item.width,
                              //           maxWidth: item.width,
                              //           textAlign: item.column_name === 'stocks_staus'
                              //             ? 'center'
                              //             : 'left'
                              //         }}
                              //         className={classes.tableCell}
                              //         key={idx}
                              //       >
                              //         <div
                              //           style={{
                              //             display: 'flex',
                              //             justifyContent: getAlign(item.column_type),
                              //           }}
                              //         >
                              //           {item.column_name !== 'stocks_staus' ? (
                              //             <>
                              //               {item.column_type === 'int'
                              //                 ? <CurrencyFormatter currency={Number(row[item.column_name])} />
                              //                 : row[item.column_name]}
                              //             </>
                              //           ) : (
                              //             <FormControlLabel
                              //               className={classes.cellCheckbox}
                              //               control={<Checkbox color="default" />}
                              //               checked={row[item.column_name] === '1'}
                              //               onChange={() => 
                              //                 saveData(
                              //                   row.supplier_article,
                              //                   row[item.column_name] === '0'
                              //                     || !row[item.column_name] ? 1 : 0,
                              //                   item.column_name,
                              //                   index
                              //                 )
                              //               }
                              //               label={undefined}
                              //             />
                              //           )}
                              //         </div>
                              //       </TableCell>
                              //     ))}
                              //   </TableRow>
                              // ))}
                              : null}
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow style={{ height: 28 }}>
                              <TableCell style={{ minWidth: 260, maxWidth: 260 }}>&nbsp;</TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                    </Table>
                  </TableContainer>
                  <div style={{ position: 'fixed', bottom: 0, right: 30, marginBottom: 10 }}>
                    <Pagination count={total} page={page} onChange={(_, num) => {
                      setPage(num)
                    }} shape="rounded" className={classes.pagin} />
                  </div>
                </>
                //   </AccordionDetails>
                // </Accordion> 
              ))
            ) : null
            :
            (groupParams && groupParams.length) ? (
              groupParams.map((group: string) => (
                <>
                  <Accordion
                    className={classes.paramsAccordion}
                    TransitionProps={{ timeout: 0 }}
                    key={group}
                  >
                    <AccordionSummary
                      // expandIcon={<ExpandMore />}
                      className={classes.paramsAccordionsSummary}
                    >
                      <div className={classes.paramsAccordionsTitleBlock}>
                        <ArrowForwardIos />
                        <span className={classes.paramsAccordionTitle}>{group}</span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.paramsAccordionDetails}>
                      <>
                        <TableContainer component={Paper} className={classes.tableContainer}>
                          <Table className={classes.table}>
                            {(columns && table.data) ?
                              (
                                <TableBody>
                                  {filteredTable.length > 0
                                    ? filteredTable?.map((row: any, index: number) => (
                                      (group === row.brand) &&
                                      <TableRow
                                        className={
                                          classNames(
                                            classes.tableRow,
                                            row.stocks_staus !== '1' && row.disabledStockStatus
                                              ? classes.successRow
                                              : null,
                                            row.stocks_staus === '1' && row.error
                                              ? classes.errorRow
                                              : null,

                                            oranges[0]?.indexOf(String(row.id_wb)) !== -1
                                              ? classes.errorRow1
                                              : null
                                            ,
                                          )
                                        }
                                        key={index}
                                      >
                                        {columns?.map((item: any, idx: number) => (
                                          item.showed && (
                                            <TableCell
                                              style={{
                                                minWidth: item.width,
                                                maxWidth: item.width,
                                                textAlign: item.column_name === 'stocks_staus' ? 'center' : 'left',
                                              }}
                                              onClick={() =>
                                                item.column_name === 'quantity_'
                                                  ? handleEditQuantity(row, index)
                                                  : null
                                              }
                                              className={classes.tableCell}
                                              key={idx}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: getAlign(item.column_type),
                                                }}
                                                class="cell-block"
                                                className='vid'
                                              >
                                                {item.column_name !== 'stocks_staus'
                                                  && item.column_name !== 'quantity_'
                                                  ? (
                                                    <>
                                                      {item.column_type === 'int'
                                                        ? <CurrencyFormatter
                                                          light={light}
                                                          currency={
                                                            Number(row[item.column_name])
                                                          }
                                                        />
                                                        : row[item.column_name] !== null &&
                                                        light(row[item.column_name] + '')
                                                      }
                                                    </>
                                                  ) : null}
                                                {item.column_name === 'quantity_'
                                                  ? (
                                                    <>
                                                      {item.column_type === 'int'
                                                        ? editQuantityRow !== index
                                                          ? <CurrencyFormatter
                                                            light={light}
                                                            currency={
                                                              Number(row[item.column_name])
                                                            }
                                                          />
                                                          : <input
                                                            autoFocus
                                                            type="number"
                                                            className={classes.cellInput}
                                                            value={light(String(quantityValue))}
                                                            onChange={(e: any) => setQuantityValue(e.target.value)}
                                                            onBlur={() =>
                                                              saveData(
                                                                row.supplier_article,
                                                                quantityValue || '0',
                                                                item.column_name,
                                                                index
                                                              )
                                                            }
                                                          />
                                                        : null}
                                                    </>
                                                  ) : null}
                                                {item.column_name === 'stocks_staus'
                                                  ? (
                                                    <FormControlLabel
                                                      className={classes.cellCheckbox}
                                                      control={
                                                        <Checkbox
                                                          color="default"
                                                          checked={item.stocks_staus}
                                                        />
                                                      }
                                                      checked={row[item.column_name] === '1'}
                                                      onChange={() =>
                                                        saveData(
                                                          row.supplier_article,
                                                          row[item.column_name] === '0'
                                                            || !row[item.column_name] ? 1 : 0,
                                                          item.column_name,
                                                          index
                                                        )
                                                      }
                                                      label={undefined}
                                                    />
                                                  ) : null}
                                              </div>
                                            </TableCell>
                                          )
                                        ))}
                                      </TableRow>
                                    ))
                                    // : fakeRows?.map((row: any, index: number) => (
                                    //   <TableRow
                                    //     className={classes.tableRow}
                                    //     style={{
                                    //       height: 28,
                                    //       background: row.error ? 'rgba(255, 108, 75, 0.1);' : 'transparent'
                                    //     }}
                                    //     key={index}
                                    //   >
                                    //     {columns?.map((item: any, idx: number) => (
                                    //       <TableCell
                                    //         style={{
                                    //           minWidth: item.width,
                                    //           maxWidth: item.width,
                                    //           textAlign: item.column_name === 'stocks_staus'
                                    //             ? 'center'
                                    //             : 'left'
                                    //         }}
                                    //         className={classes.tableCell}
                                    //         key={idx}
                                    //       >
                                    //         <div
                                    //           style={{
                                    //             display: 'flex',
                                    //             justifyContent: getAlign(item.column_type),
                                    //           }}
                                    //         >
                                    //           {item.column_name !== 'stocks_staus' ? (
                                    //             <>
                                    //               {item.column_type === 'int'
                                    //                 ? <CurrencyFormatter currency={Number(row[item.column_name])} />
                                    //                 : row[item.column_name]}
                                    //             </>
                                    //           ) : (
                                    //             <FormControlLabel
                                    //               className={classes.cellCheckbox}
                                    //               control={<Checkbox color="default" />}
                                    //               checked={row[item.column_name] === '1'}
                                    //               onChange={() => 
                                    //                 saveData(
                                    //                   row.supplier_article,
                                    //                   row[item.column_name] === '0'
                                    //                     || !row[item.column_name] ? 1 : 0,
                                    //                   item.column_name,
                                    //                   index
                                    //                 )
                                    //               }
                                    //               label={undefined}
                                    //             />
                                    //           )}
                                    //         </div>
                                    //       </TableCell>
                                    //     ))}
                                    //   </TableRow>
                                    // ))}
                                    : <div style={{ fontSize: 19 }}>Данных на данной странице не найдено</div>}
                                </TableBody>
                              ) : (
                                <TableBody>
                                  <TableRow style={{ height: 28 }}>
                                    <TableCell style={{ minWidth: 260, maxWidth: 260 }}>&nbsp;</TableCell>
                                  </TableRow>
                                </TableBody>
                              )}
                          </Table>
                        </TableContainer>
                      </>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))
            ) : null}


        </div>
      </div>
    </>
  )
}
