import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// const baseURL = 'http://85.175.216.246:8084';

interface SetUserOrgReq {
  uid?: string;
  name?: string;
  phone?: string;
  email?: string;
  role?: string;
  org: string;
  del: boolean;
};
interface UpdateUserOrgReq {
  uid: string;
  user_mobile: string;
  user_surname: string;
  user_name: string;
  user_middlename: string;
  user_email: string;
};
interface SetAdminOrgReq {
  org: string;
  name: string;
  inn?: string;
  short_name?: string;
  type?: string;
  phone: string;
  email: string
  plan: string;
  plan_is_confirmed: boolean;
  month: number;
  role: string;
};

export const setUserOrg = async (req: SetUserOrgReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.registration}/registration/setUserOrg`, req);
};
export const updateUserOrg = async (req: UpdateUserOrgReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.registration}/registration/update_users`, req);
};
export const setAdminOrg = async (req: SetAdminOrgReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.registration}/registration/setAdminOrg`, req);
};
export const getLicenseAgreement = async (fn: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.registration}/registration/get_admin_file_registration`, {
    'type': 'license_agreement'
  })
  .then(res => fn(res.data.data[0]))
}
export const getPersonalDataProcessingPolicy = async (fn: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.registration}/registration/get_admin_file_registration`, {
    'type': 'personal_data_processing_policy'
  })
  .then(res => fn(res.data.data[0]))
}
export const getPrivacyPolicy = async (fn: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.registration}/registration/get_admin_file_registration`, {
    'type': 'privacy_policy'
  })
  .then(res => fn(res.data.data[0]))
}
