import { makeStyles, createStyles, Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import moment from 'moment';
import cookie from 'cookie';
import React from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import { updateOrgStorageType } from '../../api/organizations';
import { RubIcon } from '../UI/Icons';
import { getPrimaryDataSearchActual } from '../../api/history';

const useStyles = makeStyles(() =>
  createStyles({
    infoBlock: {
      maxWidth: 262,
      width: '100%',
    },

    statuses: {
      marginBottom: 48,
    },
    statusesTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
      marginBottom: 20,
    },
    statusesAccordion: {
      boxShadow: 'none',
      marginTop: '0 !important',

      '&::before': {
        opacity: '0 !important',
      },
    },
    statusesAccordionsSummary: {
      padding: '0 !important',

      '& .MuiAccordionSummary-content': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& p': {
        display: 'flex !important',
        alignItems: 'center !important',
      },
      '& svg': {
        width: 16,
        transition: 'all .2s ease-out',
      },
      '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '24px !important',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
      },
      '& .Mui-expanded svg': {
        transform: 'rotate(90deg)',
      },
    },
    statusesAccordionTitle: {
      marginLeft: 13,
    },
    statusesAccordionsTitleBlock: {
      display: 'flex !important',
      alignItems: 'center !important',
    },
    statusesAccordSuccessChip: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#177367',
      padding: '4px 10px',
      maxWidth: 29,
      maxHeight: 24,
      background: 'rgba(23, 115, 103, 0.1)',
      borderRadius: 8,
    },
    statusesAccordErrorChip: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FF6C4B',
      padding: '4px 10px',
      maxWidth: 29,
      maxHeight: 24,
      background: 'rgba(255, 108, 75, 0.1)',
      borderRadius: 8,
    },
    statusesAccordDarkChip: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      padding: '4px 10px',
      maxWidth: 29,
      maxHeight: 24,
      background: '#E9E9E7',
      borderRadius: 8,
    },
    statusesAccordionsDetails: {},
    statusesAccordionsList: {
      padding: 0,
      paddingLeft: 12,
      listStyle: 'none',
      margin: 0,
    },
    statusesAccordionsListItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      // textAlign: 'justify',
      color: '#827F75',
      marginBottom: 4,
    },
    statusesNextSteps: {},
    statusesNextStepsTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
      marginBottom: 20,
    },
    statusesNextStepBlock: {
      padding: 16,
      paddingBottom: 20,
      border: '1px solid #E9E9E7',
      borderRadius: 8,
    },
    statusesNextStepIconBlock: {
      marginBottom: 32,
    },
    statusesNextStepsControlTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    statusesNextStepsControlText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 32,
    },
    statusesNextStepBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      border: '1px solid #37352F',
      borderRadius: 8,
      textTransform: 'none',
      padding: '8px 16px 8px 10px',
      textDecoration: 'none',
    },
    loadDataModalInfoText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#000000',
      margin: 0,
      marginTop: 32,
      marginBottom: 32,
    },
  })
)

interface Props {
  actual: any;
  noActual: any;
  noData: any;
  list?: any;
};

const LoadDataInfoBlock = (props: Props) => {
  const classes = useStyles();
  const [noDataCount, setNoDataCount] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);
  const [actualList, setActualList] = React.useState([]);
  const [noActualList, setNoActualList] = React.useState([]);

  const orgType = useQuery('orgType', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await updateOrgStorageType({ org: uid, action: 'show' });
    return result.data.data;
  });

  useEffect(() => {
    if (props?.list) {
      let newActual = props.list?.filter(el => el.aCheck && el.primaryDataTypeName !== 'Закупка товара' && el.primaryDataTypeName !== 'Размеры, вес, объем' && el.primaryDataTypeName !== 'Свободные остатки на складах');
      if (orgType?.data?.[0]?.type === 0)
        newActual = newActual.filter(el => el.primaryDataTypeName !== 'Остатки FBS');
      let newNoActual = props.list?.filter(el => !el.aCheck && el.primaryDataTypeName !== 'Закупка товара' && el.primaryDataTypeName !== 'Размеры, вес, объем' && el.primaryDataTypeName !== 'Свободные остатки на складах');
      if (orgType?.data?.[0]?.type === 0)
        newNoActual = newNoActual.filter(el => el.primaryDataTypeName !== 'Остатки FBS');
      setActualList(newActual);
      setNoActualList(newNoActual);
    }
    // if (loaded) return;
    let count = 0;
    if (props.noData) {
      for (const key in props.noData) {
        const element = props.noData[key];
        count += element;
      }

      setNoDataCount(count);
      // setLoaded(true);
    }
  }, [props.noData?.brand_category_product, loaded, props.list, orgType?.data]);
  return (
    <div className={classes.infoBlock}>
      <div className={classes.statuses}>
        <h2 className={classes.statusesTitle}>Статусы</h2>

        <Accordion className={classes.statusesAccordion}>
          <AccordionSummary className={classes.statusesAccordionsSummary}>
            <div className={classes.statusesAccordionsTitleBlock}>
              <ArrowForwardIos />
              <span className={classes.statusesAccordionTitle}>Актуальны</span>
            </div>
            <span className={classes.statusesAccordSuccessChip}>{actualList.length}</span>
          </AccordionSummary>
          <AccordionDetails className={classes.statusesAccordionsDetails}>
            <ul className={classes.statusesAccordionsList}>
              {actualList?.map((item: any, index: number) => (
                // (item.primaryDataTypeName === 'Остатки FBS' &&
                //   orgType?.data?.[0]?.type === 0)
                //   ? null
                // :
                <li className={classes.statusesAccordionsListItem} key={index}>
                  {item.primaryDataTypeName === 'Текущая неделя продаж' ? 'Еженедельная динамика продаж' : item.primaryDataTypeName}
                </li>
              ))}
              {!actualList?.length && (
                <li className={classes.statusesAccordionsListItem}>Данных нет</li>
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.statusesAccordion}>
          <AccordionSummary className={classes.statusesAccordionsSummary}>
            <div className={classes.statusesAccordionsTitleBlock}>
              <ArrowForwardIos />
              <span className={classes.statusesAccordionTitle}>Необходимо обновить</span>
            </div>
            <span className={classes.statusesAccordErrorChip}>{noActualList.length}</span>
          </AccordionSummary>
          <AccordionDetails className={classes.statusesAccordionsDetails}>
            <ul className={classes.statusesAccordionsList}>
              {noActualList?.map((item: any, index: number) => (
                // (item.primaryDataTypeName === 'Остатки FBS' &&
                //   orgType?.data?.[0]?.type === 0)
                //   ? null
                //   :
                <li className={classes.statusesAccordionsListItem} key={index}>
                  {item.primaryDataTypeName === 'Текущая неделя продаж' ? 'Еженедельная динамика продаж' : item.primaryDataTypeName}
                </li>
              ))}
              {!noActualList?.length && (
                <li className={classes.statusesAccordionsListItem}>Данных нет</li>
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.statusesAccordion}>
          <AccordionSummary className={classes.statusesAccordionsSummary}>
            <div className={classes.statusesAccordionsTitleBlock}>
              <ArrowForwardIos />
              <span className={classes.statusesAccordionTitle}>Отсутствуют данные <br /> в отчете</span>
            </div>
            <span className={classes.statusesAccordDarkChip}>{noDataCount}</span>
          </AccordionSummary>
          <AccordionDetails className={classes.statusesAccordionsDetails}>
            <ul className={classes.statusesAccordionsList}>
              {props?.noData?.all_goods ? (
                <li className={classes.statusesAccordionsListItem}>
                  Весь товар отсутствует: {props?.noData?.all_goods} шт
                  {/* <span>{props?.noData?.all_goods}</span> */}
                </li>
              ) : null}
              {props?.noData?.brand_category_product ? (
                <li className={classes.statusesAccordionsListItem}>
                  Параметры бренд/категория/товар отсутствует: {props?.noData.brand_category_product} шт
                  {/* <span>{props?.noData.brand_category_product}</span> */}
                </li>
              ) : null}
              {props?.noData?.dimensions_volume ? (
                <li className={classes.statusesAccordionsListItem}>
                  Размеры, вес, объем отсутствует: {props?.noData?.dimensions_volume} шт
                  {/* <span>{props?.noData?.dimensions_volume}</span> */}
                </li>
              ) : null}
              {noDataCount === 0 && (
                <li className={classes.statusesAccordionsListItem}>Данных нет</li>
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className={classes.statusesNextSteps}>
        <h2 className={classes.statusesNextStepsTitle}>Дальнейшие шаги</h2>

        <div className={classes.statusesNextStepBlock}>
          <div className={classes.statusesNextStepIconBlock}>
            <RubIcon />
          </div>
          <h2 className={classes.statusesNextStepsControlTitle}>
            Управляйте ценами <br /> и остатками
          </h2>
          <p className={classes.statusesNextStepsControlText}>
            Здесь добавим описание что <br /> и для чего нужно сделать
          </p>

          <NavLink to="/manage-prices" className={classes.statusesNextStepBtn}>
            К ценам
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default LoadDataInfoBlock