import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// const baseURL = 'http://85.175.216.246:8085';

export const getFAQ = async () => {
  const urlAdres = await url()
  return await API.get(`${urlAdres.data.faq}/faq/faq`);
};
export const getInfo = async (type: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.faq}/faq/info`, { type });
};
