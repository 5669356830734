import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from '@material-ui/core';
import BlockFilters from "./BlockFilters";
import TableAnalitycs from "./TableAanalitycs";


const useStyles = makeStyles(() => 
  createStyles({
    wrapper: {
      padding: 32,
    },
  }),
);

const Analitycs = () => {

  const [dataTable, setDataTable] = useState<any>([])
  const [update, setUpdate] = useState<boolean>(false)


    const classes = useStyles();

    return ( 
        <div className={classes.wrapper}>
            <BlockFilters tableData={setDataTable} update={update}/>
            <TableAnalitycs table={dataTable.table_analist} setUpdate={setUpdate}/>
        </div>
     );
}
 
export default Analitycs;