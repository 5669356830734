import React from "react";
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { ruRU } from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ruRU);

const logKey = (e) => {
  if (e.code === 'ArrowUp' || e.code === 'ArrowDown')
    e.preventDefault();
}
const onwheel = (e) => {
  if (e.target.tagName === 'INPUT' && e.target.type === 'number')
    e.target.blur();
};
document.addEventListener('keydown', logKey);
document.addEventListener('mousewheel', onwheel);

// eslint-disable-next-line no-lone-blocks
{/* <React.StrictMode> */}
// eslint-disable-next-line no-lone-blocks
{/* <BrowserRouter> */}
// eslint-disable-next-line no-lone-blocks
{/* </BrowserRouter> */}
// eslint-disable-next-line no-lone-blocks
{/* </React.StrictMode>, */}
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
