import React from 'react'
import cookie from 'cookie'
import { makeStyles, createStyles, Popover, Typography } from '@material-ui/core';
import moment from 'moment';
import { useQuery } from 'react-query';
import { primaryDataSearchSale } from '../../../api/primary_data_search';

const useStyles: any = makeStyles(() =>  
  createStyles({
    popover: {
      pointerEvents: 'none',

      '& .MuiPopover-paper': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#FFFFFF',
        borderRadius: 8,
        background: '#37352F',
        boxShadow: '-2px 3.5px 13px -3px rgba(113,114,171,0.3)',
        padding: 16,
      },
    },
    cardLink: {
      position: 'relative',
      display: 'inline-block',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    cardLinkLine: {
      position: 'absolute',
      display: 'inline-block',
      bottom: -1,
      left: 0,
      width: '100%',
      height: 1,
      background: '#000',
    },
    needUpdate: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FF6C4B',
      display: 'inline-flex',
      alignItems: 'center',
    },
    details: {
      fontSize: 14,
    },
  })
);

interface Props {
  saleNoActual: (val: any) => void;
  update: number;
  actual: any;
};

const MorePopoverSale = (props: Props) => {
  const classes = useStyles();

  React.useEffect(() => {
    dataSearchSale.refetch();
  }, [props.update]);

  const dataSearchSale = useQuery('dataSearchSale', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await primaryDataSearchSale(uid);

    // const check = result.data.data.some((item) => differenceDates(item.date_upload))

    props.saleNoActual(result.data.data.length === 0);
    // console.log(result.data.data)

    return result?.data?.data;
  });

  const differenceDates = (lastDateUpload: string) => {
    const regex = /([0-9]+):([0-9]+)/;
    const a: any = moment().startOf('day');
    const b: any = moment(lastDateUpload.replace(regex, '00:00:00'));

    return Math.round(moment.duration(a - b).asDays()) * -1 >= 0;
  };

  const [anchorSaleEl, setAnchorSaleEl] = React.useState<HTMLElement | null>(null);


  const handlePopoverSaleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (dataSearchSale.data.length) {
      setAnchorSaleEl(event.currentTarget);
    } else {
      setAnchorSaleEl(null);
    }
  };

  const handlePopoverSaleClose = () => {
    setAnchorSaleEl(null);
  };

  const openPopopverSale = Boolean(anchorSaleEl);

  return (
    <>
    {dataSearchSale.data && dataSearchSale.data.length  ? 
      <>
        <span className={classes.needUpdate}></span>
        <span
          aria-owns={openPopopverSale ? 'mouse-over-popover-sale' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverSaleOpen}
          onMouseLeave={handlePopoverSaleClose}
          className={classes.cardLink}
          style={{marginLeft: 5}}
        >
          Подробнее
          <span className={classes.cardLinkLine}></span>
        </span>
        <Popover
          id="mouse-over-popover-sale"
          className={classes.popover}
          open={openPopopverSale}
          anchorEl={anchorSaleEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverSaleClose}
          disableRestoreFocus
        >
          {dataSearchSale.data.map((item: any, index: number) => (
            <Typography
              style={{
                maxWidth: 300,
                width: '100%'
              }}
              className={classes.details}
              key={index}
            >
              <span>до {moment(item.data_end).format('L')} {item.name_shares}</span>
            </Typography>
          ))}
        </Popover>
      </> : null}
    </>
  );
};

export default MorePopoverSale;
