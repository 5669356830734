import { makeStyles, createStyles, Button, ButtonGroup, Box, MenuItem, OutlinedInput, Select, Theme, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Check, Close } from '@material-ui/icons';
import classNames from 'classnames';
import cookie from 'cookie';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getTariffsSettings } from '../../api/tariffs/access';
import { getAvgValue, getFilter, setAlltVolume, setVolume } from '../../api/reports';
import { useSearchParams } from 'react-router-dom';
import FreeTariffSnackbar from '../UI/FreeTariffSnackbar';
import { findTariff, TariffsType } from '../../api/tariffs';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '.MuiPopover-paper': {
        // top: '147px !important',
      },
      '.MuiMenuItem-root': {
        fontSize: 14,
        lineHeight: '16px',
        whiteSpace: 'unset',
      },
    },
    setDataModal: {
      // display: 'none',
      position: 'fixed',
      width: 329,
      height: '100vh',
      background: '#fff',
      top: 0, right: 0,
      transform: 'translateX(100%)',
      transition: 'all .2s ease-out',
      overflowY: 'auto',
      padding: 16,
      paddingRight: 12,
      boxSizing: 'border-box',
      borderLeft: '1px solid #D3D1CB',
      zIndex: 1299,
    },
    show: {
      // display: 'block',
      transform: 'translateX(0)',
    },
    setDataModalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,
    },
    setDataModalTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    setDataModalClose: {
      fontSize: 24,
      cursor: 'pointer',
    },
    setDataModalContent: {},
    setDataModalLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    setDataModalBtns: {
      marginBottom: 32,
    },
    setDataModalBtn: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: '#37352F',
      textTransform: 'none',
      background: '#FFFFFF',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',

      '&:last-child': {
        borderRadius: '0px 8px 8px 0px',
      },
    },
    setDataModalFirstBtn: {
      boxSizing: 'border-box',
      borderRadius: '8px 0px 0px 8px',
    },
    setDataModalLastBtn: {
      borderRadius: '0px 8px 8px 0px',
    },
    active: {
      background: '#E9E9E7',
      border: '1px solid #37352F',
    },
    setDataModalBlock: {
      width: '100%',
      outline: 'none',
      height: 32,
      border: 'none !important',
      borderRadius: 8,
      marginBottom: 32,

      '&.error': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #FF2E00',
        },
      },
      '&.Mui-focused': {
        outline: 'none !important',
        border: 'none !important',
      },
      '& .MuiSelect-select': {
        padding: 4,
        background: 'transparent !important',
      }
    },
    setDataModalInput: {
      border: '1px solid #D3D1CB',
      outline: 'none',
    },
    setDataModalChip: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      marginRight: 3,
      padding: '4px 8px',
    },
    setDataModalChips: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    setDataModalChipClose: {
      width: '16px !important',
      height: '16px !important',
      marginLeft: '8px !important'
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 32,

      '&.error': {
        iconField: {
          border: '1px solid #FF2E00',
        },
      },
      '& span': {
        display: 'inline-block',
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        textDecorationLine: 'underline',
        color: '#177367',
        marginTop: 4,
        cursor: 'pointer',
      },
    },
    miniField: {
      maxWidth: 261,
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      overflow: 'hidden',
      boxSizing: 'border-box',
      width: 180,
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '6px 16px',
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,

      '&:disabled': {
        background: '#F2F2F0',
        color: '#D3D1CB',
      },
    },
    saveBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      textTransform: 'none',
      padding: '12px 16px',

      '&:hover': {
        background: '#827F75',
      },
    },
  })
);

interface Props {
  open: boolean;
  primaryDataUploadUid: string;
  closeEvent: () => void;
  setOpenFreeTariffSnackbar: (val) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

export const SetDataModal = (props: Props) => {
  const classes = useStyles();

  const [param, setParam] = React.useState('brand');
  const [brand, setBrands] = React.useState<Array<any>>([]);
  const [minPriceThreshold, setMinPriceThreshold] = React.useState<any>();
  const [maxPriceThreshold, setMaxPriceThreshold] = React.useState<any>();
  const [averagePrice, setAveragePrice] = React.useState<any>();
  const [deliveryTime, setDeliveryTime] = React.useState<any>();
  const [minStock, setMinStock] = React.useState<any>();
  const [paramsData, setParamsData] = React.useState<any>([]);
  const [avgValues, setAvgValues] = React.useState<any>([]);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [brandError, setBrandError] = React.useState(false);
  const [minPriceThresholdError, setMinPriceThresholdError] = React.useState(true);
  const [maxPriceThresholdError, setMaxPriceThresholdError] = React.useState(false);
  const [averagePriceError, setAveragePriceError] = React.useState(false);
  const [deliveryTimeError, setDeliveryTimeError] = React.useState(false);

  const [standartSpp, setStandartSpp] = React.useState<any>();
  const [upSpp, setUpSpp] = React.useState<any>();

  const btns = [
    { id: 'brand', name: 'Бренд' },
    { id: 'category', name: 'Категория' },
    { id: 'product', name: 'Товар' },
  ];


  const currentTariff = useQuery<TariffsType>('currentTariff', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await findTariff(uid);

    return result.data.data;
  });

  React.useEffect(() => {
    if (loaded) return;
    getParams('brand');
    setLoaded(true);
  });

  const handleChangeBrands = async (value: any) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    setBrands(value);

    const result = await getAvgValue({
      org: uid,
      column: param,
      value_column: value,
    });

    setAvgValues(
      result.data.data[0].length
        ? result.data.data[0]
        : { min_: 0, max_: 0, avg_: 0, delivery_time_: 0, standard_spp: 0, increased_spp: 0, colum_: param }
      );
    // setOpenSelect(false);
  };

  const getParams = async (id: string) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await getFilter({
      primary: props.primaryDataUploadUid,
      org: uid,
      column: id,
    });

    setBrands([]);
    setParam(id);
    setParamsData(result.data.data.result.filter(el => el));
  };

  const handleSetData = (field: string, value: string) => {
    if (field === 'standart_spp') {
      setStandartSpp(Math.abs(Number(value)));
    }
    if (field === 'up_spp') {
      setUpSpp(Math.abs(Number(value)));
    }
    if (field === 'min_price') {
      setMinPriceThreshold(Math.abs(Number(value)));
      // setData(field, value);
    }
    if (field === 'max_price') {
      setMaxPriceThreshold(Math.abs(Number(value)));
      // setData(field, value);
    }
    if (field === 'avg_price') {
      setAveragePrice(Math.abs(Number(value)));
      // setData(field, value);
    }
    if (field === 'delivery_time') {
      setDeliveryTime(Math.abs(Number(value)));
      // setData(field, value);
    }
    if (field === 'min_stok') {
      setMinStock(Math.abs(Number(value)));
    }
  };

  const saveEvent = async () => {
    if (!brand.length) {
      setBrandError(true);
      return;
    } else setBrandError(false);

    const uid = await cookie.parse(document.cookie)['org_uid'];
    await setAlltVolume({
      org: uid,
      avg_price: averagePrice || averagePrice === 0 ? String(averagePrice) : undefined,
      delivery_time: deliveryTime || deliveryTime === 0 ? String(deliveryTime) : undefined,
      max_price: maxPriceThreshold || maxPriceThreshold === 0 ? String(maxPriceThreshold) : undefined,
      min_price: minPriceThreshold || minPriceThreshold === 0 ? String(minPriceThreshold) : undefined,
      min_stock: minStock || minStock === 0 ? String(minStock) : undefined,
      standard_spp: standartSpp || standartSpp === 0 ? String(standartSpp) : undefined,
      increased_spp: upSpp || upSpp === 0 ? String(upSpp) : undefined,
      column_search: param,
      value_search: brand
    });
    setStandartSpp('');
    setUpSpp('');
    setMinPriceThreshold('');
    setMaxPriceThreshold('');
    setAveragePrice('');
    setDeliveryTime('');
    setMinStock('');
    setBrands([]);
    props.closeEvent();
  };

  const setData = async (field: string, value: string) => {
    // const uid = await cookie.parse(document.cookie)['org_uid'];
    // const result = await setVolume({
    //   org: uid,
    //   column: field,
    //   value: String(value),
    //   column_search: param,
    //   value_search: brand
    // });
  };

  const handleOpenSelect = (e: any) => {
    if (!e?.target?.className.length) {
      setOpenSelect(false);
      return;
    }
    if (e?.target?.tagName === 'svg' || e?.target?.tagName === 'path') {
      return;
    }
    setOpenSelect(true);
  };

  const removeChip = (chip: string) => {
    let selectedCopy: any = JSON.parse(JSON.stringify(brand));
    selectedCopy = selectedCopy.filter((item: any) => item !== chip);
    handleChangeBrands(selectedCopy);
    setBrands(selectedCopy);
  };

  const handleStandartSpp = (val: number) => {
    setStandartSpp(!val ? '' : Math.abs(Number(val)));
  };

  const handleUpSpp = (val: number) => {
    setUpSpp(!val ? '' : Math.abs(Number(val)));
  };

  const handleMinPriceThreshold = (val: string) => {
    setMinPriceThreshold(val.replace(/\D/g,''));
  };
  const handleMaxPriceThreshold = (val: string) => {
    setMaxPriceThreshold(val.replace(/\D/g,''));
  };
  const handleAveragePrice = (val: string) => {
    setAveragePrice(val.replace(/\D/g,''));
  };
  const handleDeliveryTime = (val: string) => {
    setDeliveryTime(val.replace(/\D/g,''));
  };
  const handleMinStock = (val: string) => {
    setMinStock(val.replace(/\D/g,''));
  };

  const clickMinStock = () => {
    if (currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202')
      props.setOpenFreeTariffSnackbar(true);
  }


  return (
    <div className={classNames(classes.setDataModal, props.open ? classes.show : '')}>
      <div className={classes.setDataModalHeader}>
        <h2 className={classes.setDataModalTitle}>Установка значений</h2>

        <span className={classes.setDataModalClose} onClick={() => props.closeEvent()}>
          <Close />
        </span>
      </div>
      <div className={classes.setDataModalContent}>
        <p className={classes.setDataModalLabel}>
          Установить значения для
        </p>
        <ButtonGroup className={classes.setDataModalBtns} variant="outlined">
          {btns.map((item: any) => (
            <Button
              className={
                classNames(
                  classes.setDataModalFirstBtn,
                  classes.setDataModalBtn,
                  param === item.id ? classes.active : ''
                )
              }
              onClick={() => getParams(item.id)}
              key={item.id}
            >
              {item.name}
            </Button>
          ))}
        </ButtonGroup>
        <div>
          {/* <p className={classes.setDataModalLabel}>Бренд</p> */}
          <Select
            className={
              classNames(
                classes.setDataModalBlock,
                brandError ? 'error' : null
              )
            }
            multiple
            value={brand}
            onChange={(e: any) => handleChangeBrands(e.target.value)}
            input={<OutlinedInput className={classes.setDataModalInput} />}
            renderValue={(selected: any) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', marginRight: 5 }}>
                {brand?.length && (
                  <div className={classes.setDataModalChips}>
                    <div className={classes.setDataModalChip} onClick={() => removeChip(brand[0])}>
                      {brand[0]} <CloseIcon className={classes.setDataModalChipClose} />
                    </div> {brand.length > 1 && `+ ${brand?.length - 1}`}
                  </div>
                )}
              </Box>
            )}
            open={openSelect}
            onClose={handleOpenSelect}
            onOpen={handleOpenSelect}
            MenuProps={MenuProps}
          >
            {paramsData.map((el: any, i: number) => <MenuItem value={el} key={i}>{el}</MenuItem>)}
          </Select>
        </div>

        <div
          className={
            classNames(
              classes.fieldBlock,
              classes.miniField,
              minPriceThresholdError ? 'error' : null
            )
          }
        >
          <label htmlFor="name" className={classes.fieldLabel}>Минимальный порог по цене, ₽</label>
          <div className={classes.iconField}>
            <input
              type="text"
              placeholder="Только цифры"
              className={classes.field}
              value={minPriceThreshold}
              onInput={(e) => handleMinPriceThreshold(e.target.value)}
              onChange={(e) => handleMinPriceThreshold(e.target.value)}
              onBlur={(e) => setData('min_price', e.target.value)}
            />
          </div>
          <span onClick={() => handleSetData('min_price', avgValues.min_)}>
            {/* Рекомендуем: {avgValues.min_} ₽ */}
          </span>
        </div>
        <div
          className={
            classNames(
              classes.fieldBlock,
              classes.miniField,
              averagePriceError ? 'error' : null
            )
          }
        >
          <label htmlFor="name" className={classes.fieldLabel}>Дефолтное значение цены</label>
          <div className={classes.iconField}>
            <input
              type="text"
              placeholder="Только цифры"
              className={classes.field}
              value={averagePrice}
              onChange={(e) => handleAveragePrice(e.target.value)}
              onBlur={(e) => setData('avg_price', e.target.value)}
            />
          </div>
          <span onClick={() => handleSetData('avg_price', avgValues.avg_)}>
            {/* Рекомендуем: {avgValues.avg_} ₽ */}
          </span>
        </div>
        <div
          className={
            classNames(
              classes.fieldBlock,
              classes.miniField,
              maxPriceThresholdError ? 'error' : null
            )
          }
        >
          <label htmlFor="name" className={classes.fieldLabel}>Максимальный порог по цене, ₽</label>
          <div className={classes.iconField}>
            <input
              type="text"
              placeholder="Только цифры"
              className={classes.field}
              value={maxPriceThreshold}
              onChange={(e) => handleMaxPriceThreshold(e.target.value)}
              onBlur={(e) => setData('max_price', e.target.value)}
            />
          </div>
          <span onClick={() => handleSetData('max_price', avgValues.max_)}>
            {/* Рекомендуем: {avgValues.max_} ₽ */}
          </span>
        </div>
        <div
          className={
            classNames(
              classes.fieldBlock,
              classes.miniField,
              deliveryTimeError ? 'error' : null
            )
          }
        >
          <label htmlFor="name" className={classes.fieldLabel}>Срок поставки товара от поставщика, дней</label>
          <div className={classes.iconField}>
            <input
              type="text"
              placeholder="Только цифры"
              className={classes.field}
              value={deliveryTime}
              onChange={(e) => handleDeliveryTime(e.target.value)}
              onBlur={(e) => setData('delivery_time', e.target.value)}
            />
          </div>
          <span onClick={() => handleSetData('delivery_time', avgValues.delivery_time_)}>
            {/* Рекомендуем: {avgValues.delivery_time_} дней */}
          </span>
        </div>
        <div
          className={
            classNames(
              classes.fieldBlock,
              classes.miniField,
              deliveryTimeError ? 'error' : null
            )
          }
          onClick={clickMinStock}
        >
          <label htmlFor="name" className={classes.fieldLabel}>Минимальный порог по остатку, шт.</label>
          <div className={classes.iconField}>
            <input
              type="text"
              placeholder="Только цифры"
              className={classes.field}
              value={minStock}
              onChange={(e) => handleMinStock(e.target.value)}
              onBlur={(e) => setData('min_stok', e.target.value)}
              disabled={currentTariff?.data?.uid === '45124e44-22d7-4714-9ad4-83129b787202'}
            />
          </div>
          <span onClick={() => handleSetData('min_stok', avgValues.min_stok)}>
            {/* Рекомендуем: {avgValues.delivery_time_} дней */}
          </span>
        </div>
      </div>

      <div
          className={
            classNames(
              classes.fieldBlock,
              classes.miniField,
              minPriceThresholdError ? 'error' : null
            )
          }
        >
          <label htmlFor="name" className={classes.fieldLabel}>Стандартный СПП, %</label>
          <div className={classes.iconField}>
            <input
              type="number"
              placeholder="Только цифры"
              className={classes.field}
              value={standartSpp}
              onInput={(e) => handleStandartSpp(Number(e.target.value))}
              onChange={(e) => handleStandartSpp(Number(e.target.value))}
              onBlur={(e) => setData('standart_spp', e.target.value)}
            />
          </div>
          <span onClick={() => handleSetData('standart_spp', avgValues.standard_spp)}>
            {/* Рекомендуем: {avgValues.min_} ₽ */}
          </span>
        </div>


        <div
          className={
            classNames(
              classes.fieldBlock,
              classes.miniField,
              minPriceThresholdError ? 'error' : null
            )
          }
        >
          <label htmlFor="name" className={classes.fieldLabel}>Повышенный СПП, %</label>
          <div className={classes.iconField}>
            <input
              type="number"
              placeholder="Только цифры"
              className={classes.field}
              value={upSpp}
              onInput={(e) => handleUpSpp(Number(e.target.value))}
              onChange={(e) => handleUpSpp(Number(e.target.value))}
              onBlur={(e) => setData('up_spp', e.target.value)}
            />
          </div>
          <span onClick={() => handleSetData('up_spp', avgValues.increased_spp)}>
            {/* Рекомендуем: {avgValues.min_} ₽ */}
          </span>
        </div>

      <Button className={classes.saveBtn} onClick={saveEvent}>
        Применить изменения
      </Button>
    </div>
  )
}
