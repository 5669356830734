import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
}

const Question = (props: Props) => {
  return (
    <svg width={props.width || '16px'} height={props.height || '17px'} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00065 15.1666C11.6825 15.1666 14.6673 12.1818 14.6673 8.49992C14.6673 4.81802 11.6825 1.83325 8.00065 1.83325C4.31875 1.83325 1.33398 4.81802 1.33398 8.49992C1.33398 12.1818 4.31875 15.1666 8.00065 15.1666Z" stroke="#37352F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.06055 6.49989C6.21728 6.05434 6.52665 5.67863 6.93385 5.43931C7.34105 5.2 7.81981 5.11252 8.28533 5.19237C8.75085 5.27222 9.17309 5.51424 9.47726 5.87558C9.78144 6.23691 9.94792 6.69424 9.94721 7.16656C9.94721 8.49989 7.94721 9.16656 7.94721 9.16656" stroke="#37352F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 11.8333H8.00667" stroke="#37352F" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    // <svg width={props.width || '24px'} height={props.height || '24px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M12 5V19" strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M5 12H19" strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>
  )
}

export default Question;
