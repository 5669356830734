import { makeStyles, createStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import classNames from 'classnames';
import cookie from 'cookie';
import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { calcSale, excelDownloadListPromotion, getCalc, getCalcOrg, setCalc } from '../../api/calc';

import { PriceDropParams } from '../../components/ManagePrices/PriceDrop/Params';
import PriceDropToolbar from '../../components/ManagePrices/PriceDrop/Toolbar'
import CurrencyFormatter from '../../components/UI/CurrencyFormatter';
import Spinner from '../../components/UI/Spinner';
import { getCellWidth } from '../../common/row-sizes'
import { Pagination } from '@material-ui/lab';
import './Pagination.css';
import '../../index.css'
import Hightlight from '../../common/Hightlight';
import { useNewWb } from '../Admin/store';
import { loadDataPromotion } from '../../api/wb-data';
import { findTariff } from '../../api/tariffs';

const useStyles = makeStyles(() =>
  createStyles({
    content: {},
    tableWrap: {
      overflowX: 'scroll',
      overflowY: 'scroll',
      maxHeight: 'calc(100vh - 290.6px)',
      margin: '0 32px',

      '& .MuiPaper-root': {
        backgroundColor: 'transparent !important',
      },
    },
    tableHeader: {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 8,
    },
    tableContainer: {
      boxShadow: 'none',
      borderTop: '1px solid #E9E9E7',
      boxSizing: 'border-box',
      borderBottom: '1px solid #E9E9E7',
      width: 'fit-content !important'
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',

      '& .MuiTableRow-head': {
        background: '#fff',
      },
      '& .MuiTableCell-head': {
        verticalAlign: 'middle',
        padding: '8px 6px',
        borderRight: '1px solid #E9E9E7',
        boxSizing: 'border-box',
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        padding: '0 6px !important',
        borderRight: '1px solid #E9E9E7',
        boxSizing: 'border-box',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 6px !important',
        borderRight: 'none',
      },
    },
    tableRow: {
      '&:hover': {
        background: 'rgba(242, 242, 240, 0.5)',
      },
    },
    errorRow: {
      background: 'rgba(255, 108, 75, 0.1) !important',
      '&:hover': {
        background: '#f2e3df !important',
      },
    },
    successRow: {
      background: 'rgba(23, 115, 103, 0.05)',
      '&:hover': {
        background: '#e5eae8 !important',
      },
    },
    tableCell: {
      verticalAlign: 'middle !important',
      fontSize: '0.775rem',
      padding: '0 6px',
      position: 'relative',

      '&:hover': {
        background: 'rgba(225, 225, 228, 0.5) !important'
      },
    },
    tableCellTh: {
      verticalAlign: 'middle',
      textAlign: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',

      '& span': {
        fontWeight: 'bold',
        color: 'black',
      },
    },
    hoveredCol: {
      background: 'rgba(242, 242, 240, 0.5)',
    },
    paramsAccordion: {
      width: 'fit-content',
      boxShadow: 'none',
      marginTop: '0 !important',
      borderBottom: '1px solid #E9E9E7',

      '&::before': {
        opacity: '0 !important',
      },
    },
    paramsAccordionsSummary: {
      padding: '0 !important',

      '& .MuiAccordionSummary-content': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& p': {
        display: 'flex !important',
        alignItems: 'center !important',
      },
      '& svg': {
        width: 16,
        transition: 'all .2s ease-out',
      },
      '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '24px !important',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
      },
      '& .Mui-expanded svg': {
        transform: 'rotate(90deg)',
      },
    },
    paramsAccordionTitle: {
      width: 'fit-content',
      marginLeft: 13,
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    paramsAccordionsTitleBlock: {
      display: 'flex !important',
      alignItems: 'center !important',
      position: 'sticky',
      top: 0,
      left: 0,
    },
    paramsAccordSuccessChip: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#177367',
      padding: '4px 10px',
      maxWidth: 29,
      maxHeight: 24,
      background: 'rgba(23, 115, 103, 0.1)',
      borderRadius: 8,
    },
    paramsAccordionsDetails: {},
    paramsAccordionsList: {
      padding: 0,
      paddingLeft: 12,
      listStyle: 'none',
      margin: 0,
    },
    paramsAccordionsListItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'justify',
      color: '#827F75',
      marginBottom: 4,
    },
    paramsAccordionDetails: {
      padding: '0 !important',
      width: 'fit-content'
    },
    cellCheckbox: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: '0 !important',
      padding: '0 !important',

      '& .MuiIconButton-root': {
        padding: '0 !important',
      },
      '& svg': {
        fill: '#37352F',
      },
    },
    checkboxWrap: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export const PriceDrop = () => {
  const classes = useStyles();
  const fakeRows = [0, 1, 2, 3, 4, 5, 6, 7, 8];

  const [loading, setLoading] = React.useState(true);
  const [filters, setFilters] = React.useState<any[]>([]);
  const [columnsReq, setColumnsReq] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [groupParams, setGroupParams] = React.useState([]);
  const [filterList, setFilterList] = React.useState([]);
  const [filteredTable, setFilteredTable] = React.useState([]);
  const [rublesProcent, setRublesProcent] = React.useState(-1);
  const [maxSale, setMaxSale] = React.useState(-1);
  const [maxSaleValue, setMaxSaleValue] = React.useState(-1);
  const [maxSaleValue1, setMaxSaleValue1] = React.useState(-1);
  const [search, setSearch] = React.useState('');
  const [dataFetch, setDataFetch] = useState<any>(null)
  const [columnFetch, setColumnFetch] = useState<any>(null)

  const calcParams = useQuery('orgParams', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await getCalcOrg({ org: uid });

    if (rublesProcent === -1) setRublesProcent(result.data?.data?.[0]?.rubles_procent);
    if (maxSale === -1) setMaxSale(result.data?.data?.[0]?.max_sale);
    if (maxSaleValue === -1) setMaxSaleValue(result.data?.data?.[0]?.max_sale_value);
    if (maxSaleValue1 === -1) setMaxSaleValue1(result.data?.data?.[0]?.max_sale_value_1);

    return result.data?.data?.[0];
  });

  const cols = useQuery(['cols', { rublesProcent }], async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await getCalc({
      org: uid,
      type: 'b112d499-d632-42d8-970f-c6747ee54de3'
    });

    let colsRes = result?.data?.data?.map((item: any) => {
      const res = {
        ...item,
        showed: !item.colum,
        width: item.size ? Number(item.size) : 200 ,
      };

      if (item.column_name.indexOf('stocks_') > -1) {
        const name = `status_${item.column_name.split('stocks_').pop()}`;
        const obj = result.data.data.filter(el => el.column_name === name)[0];
        res.column_status = obj.column_name;
      }
      if (item.column_name.indexOf('stocks_') !== -1
        || item.column_name.indexOf('current_price_discount') !== -1) {
          item.column_name_1 = item.column_name;
      }

      return res;
    });
    colsRes = colsRes.filter((item: any) => item.column_name.indexOf('status_') === -1);
    const stock1Pos = colsRes.findIndex((item: any) => item.column_name.indexOf('stocks_1') !== -1);
    if (stock1Pos !== -1) {
      let noStocksCols = colsRes.filter((item: any) => item.column_name.indexOf('stocks_') === -1);
      let stocksCols = colsRes
        .filter((item: any) => item.column_name.indexOf('stocks_') !== -1)
        .sort((a: any, b: any) => a.column_caption > b.column_caption ? 1 : -1);
      colsRes =  noStocksCols;
      colsRes.splice(stock1Pos, 0, ...stocksCols)
    }
    const resultFilter = result?.data?.filter.map((f: any) => {
      return {
        ...f,
        column_type: result.data.data.filter((c:  any) => f.filter === c.column_name)[0].column_type
      }
    });

    setColumns(colsRes);
    setColumnsReq(colsRes);
    setFilterList(resultFilter);
    return result?.data?.data;
  });

  const [page , setPage] = useState(1)
  const [total, setTotalPage] = useState<any>(null)
  const [results, setResults] = useState<any>(null)

  const table = useQuery(
    ['table', {page, search, cols, filters, columnsReq, rublesProcent, maxSale, maxSaleValue, maxSaleValue1 }],
    async (queryParams: any) => {
      if (!cols.isFetched) return;
      if (maxSale === -1 || !columnsReq.length) return;
      setLoading(true);

      try {
        setLoading(true);
        const uid = await cookie.parse(document.cookie)['org_uid'];
        const colReq: any = [...new Set(columnsReq
          .filter((item: any) => item.showed)
          .map((item: any) => item.column_name)
        )];
          let filterResults: any = [];
          let copyRow: any = JSON.parse(JSON.stringify(filterList));

          copyRow.forEach((item: any) => {
            const idx = filterResults.findIndex((item2: any) => item2.column === item.filter);
            
            // const checkType = filterResult.find((item2: any) => {
            //   return item2.type === symbols.findIndex((el) => el === item.symbol);
            // });
            if (idx === -1) {
              filterResults.push({
                column: item.filter,
                value: [item.value_filter],
                type: Number(item.type)
              });
            } else {
              filterResults[idx].column = item.filter;
              filterResults[idx].value.push(item.value_filter);
              filterResults[idx].type = Number(item.type);
            }
          });

          
        if (filters?.[0]?.value?.[0] !== null) {
          let filterResult = JSON.parse(JSON.stringify(filters));
          filterResult = filterResult.filter((item: any) => item.value?.[0]?.length);
          
          const result: any = await calcSale({
            search: search,
            org: uid,
            rubles_procent: rublesProcent === -1 ? 0 : rublesProcent,
            rubles: rublesProcent === -1 ? 0 : rublesProcent,
            max_sale: maxSale || 1,
            max_sale_value: maxSaleValue || 1,
            colum: colReq,
            filter: filterResult.length > 0 ? filterResult : filterResults,
            type: 0,
          },page - 1, 500);
          const dataFetch = {
            search: search,
            org: uid,
            rubles_procent: rublesProcent === -1 ? 0 : rublesProcent,
            rubles: rublesProcent === -1 ? 0 : rublesProcent,
            max_sale: maxSale || 1,
            max_sale_value: maxSaleValue || 1,
            colum: colReq,
            filter: filterResult.length > 0 ? filterResult : filterResults,
            type: 0,
          }
          setDataFetch(dataFetch)
          setColumnFetch(columnsReq)
          setTotalPage(result.data.data.maxPages)
          setResults(result.data.data.total)
          let tableResult = result.data.data.data.map((item: any) => {
          let error = false;

            return {...item, ...{ error }};
          });

          let unique = result?.data?.data?.data
            .map((item: any) => item.brand)
          unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);
      
          setGroupParams(unique);
          setFilteredTable(tableResult);
          setLoading(false);
 
          // if (search.length) handleSearch(search, tableResult);
          return tableResult
        }
        
        return [];
        
      } catch (e) {
        setLoading(false)
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  );

  React.useEffect(() => {
    setLoading(calcParams.isLoading || cols.isLoading || table.isLoading);
  }, [calcParams.isLoading, cols.isLoading, table.isLoading]);

  const saveData = async (supplier_article: string, val: string | number, colName: string, index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await setCalc({
      org: uid,
      column: colName,
      val: val,
      articl: [supplier_article]
    });

    let copyFilteredTable = JSON.parse(JSON.stringify(filteredTable));
    console.log(copyFilteredTable)
    copyFilteredTable[index][colName] = val === 1 ? 'True' : 'False';
    setFilteredTable(copyFilteredTable);
  };

  const handleSearch = (value: string, data?: any) => {
    setSearch(value);
  };

  const getAlign = (colType: string) => {
    switch (colType) {
      case 'int': return 'flex-end';
      case 'bool': return 'center';
    };
  };

  const getBackgroundColor = (row: any) => {
    let error = false;
    let status = false;

    for (let i = 0; i < 12; i++) {
      if (row?.[`stocks_${i}`]) {
        // if (row?.[`status_${i}`] === 'True')
        //   if (!status) status = true;
        if (row?.[`status_${i}_hide`] === 'True')
          if (!status) status = true;
      }
    }

    if (status) {
      const min_price = cookie.parse(document.cookie)['min_price'];
      const min_stock = cookie.parse(document.cookie)['min_stock'];
      if (min_price === 'true')
        //if (Number(row?.sale_for_hide) <= Number(row?.min_price))
          error = true;
      if (min_stock === 'true')
        //if (Number(row?.remaining_fbs_fbo) <= Number(row?.min_stock))
          error = true;
    }

    return error ? 'rgba(255, 108, 75, 0.1)' : 'transparent';
  };

  const formatColName = (col: any) => {
    if (col.column_name.indexOf('stocks_') > -1)
      return `${col.column_caption} <span>${rublesProcent ? '₽' : '%'}</span>`
    if (col.column_name.indexOf('penalty') > -1)
      return `${col.column_caption} <span>${rublesProcent ? '₽' : '%'}</span>`

    return col.column_caption;
  };

  const updateRublesProcent = (val) => {
    if (rublesProcent !== val || rublesProcent === -1)
      setRublesProcent(val);
  };

  const saveCols = (columns: any) => {
    setColumnsReq(columns);
    setColumns(columns);
  };

    const light = useCallback((str) => {
    return <Hightlight filter={search} str={str} />
  }, [search])

  const [toggleNew, setToggleNew] = React.useState(false)
  const [numberProduct, setNumberProduct] = useState([]);
  const [numberProduct1, setNumberProduct1] = useState();
  const dataStore = useNewWb()

  const getPromotion = async() => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const data = {
      org: uid,
    }
    try {
      const res = await loadDataPromotion(data)
      setNumberProduct(res.data.data[0])
    } catch (error) { 
    }
  }

  const reportHandler = async () => {
    setLoading(true);
    if(!toggleNew) {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const plan = await findTariff(uid);
      let getdemandAndBalance = localStorage.getItem('demandAndBalance')
      let demandAndBalance = Number(getdemandAndBalance)
      const excel = await excelDownloadListPromotion({
        org: uid,
        plan: plan.data.data.uid || '45124e44-22d7-4714-9ad4-83129b787202',
        info: true,
        rest_wb_fbo: !!demandAndBalance
      });
      setNumberProduct1(excel.data.data.product_count)
    }
    if(toggleNew) {
      getPromotion()
    }
    setLoading(false);
  }

  useEffect(() => {
    setToggleNew(dataStore?.toggleNew)
  }, [dataStore])

  useEffect(() => {
    reportHandler()
  }, [toggleNew]);


  return (
    <>

      <div className={classes.content}>
        <Spinner open={loading} />
        <PriceDropParams
          updateTable={() => table.refetch()}
          reportHandler={reportHandler}
          rubles_procent={rublesProcent}
          max_sale_value={maxSaleValue}
          max_sale_value_1={maxSaleValue1}
          max_sale={maxSale}
          setRublesProcent={updateRublesProcent}
          setNewMaxSaleValue={setMaxSaleValue}
          setNewMaxSaleValue1={setMaxSaleValue1}
          setLoading={(val: boolean) => setLoading(val)}
        />
        {/* {(filterList && columns && table.data) && */}
          <PriceDropToolbar
            dataFetch={dataFetch}
            toggleNew={toggleNew}
            reportHandler={reportHandler}
            numberProduct={numberProduct}
            numberProduct1={numberProduct1}
            columnFetch={columnFetch}
            filterList={filterList || []}
            cols={columns || []}
            count={results || 0}
            updateTable={() => table.refetch()}
            sendFilters={(filter: any) => setFilters(filter)}
            sendColumn={(columns: any) => saveCols(columns)}
            newColumnPosition={(columns: any) => setColumns(columns)}
            search={(value) => handleSearch(value)}
            refetch={() => table.refetch()}
            setLoading={setLoading}
            setPage={setPage}
          />
        {/* } */}
        <div className={classes.tableWrap}>
          <TableContainer component={Paper} className={classNames(classes.tableContainer, classes.tableHeader)}>
            <Table className={classes.table}>
              <TableHead>
                {columns && (
                  <TableRow>
                    {columns.map((col: any, index: number) => (
                      (col.column_name !== 'sale_for_hide' && col.showed) && (
                        <TableCell
                          style={{
                            minWidth: col.width,
                            maxWidth: col.width,
                          }}
                          className={classes.tableCellTh}
                          key={index}
                        >
                          <div dangerouslySetInnerHTML={{__html: formatColName(col)}} />
                        </TableCell>
                      )
                    ))}
                  </TableRow>
                )}
              </TableHead>
            </Table>
            {filteredTable.length > 0 ?
            null : <div style={{fontSize: 19}}>Данных на данной странице не найдено</div>}
          </TableContainer>
          
          {results > 500
          ?
          (groupParams && groupParams.length) ? (
            groupParams.map((group: string) => (
              // <Accordion
              //   className={classes.paramsAccordion}
              //   TransitionProps={{ timeout: 0 }}
              //   key={group}
              // >
              //   <AccordionSummary className={classes.paramsAccordionsSummary}>
              //     <div className={classes.paramsAccordionsTitleBlock}>
              //       <ArrowForwardIos />
              //       <span className={classes.paramsAccordionTitle}>{group}</span>
              //     </div>
              //   </AccordionSummary>
              //   <AccordionDetails className={classes.paramsAccordionDetails}>
                  <>
                  <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table}>
                      {(columns && table.data) ?
                        (
                          <TableBody>
                            {filteredTable.length > 0
                              ? filteredTable?.map((row: any, index: number) => (
                                (group === row.brand) &&
                                  <TableRow
                                    style={{
                                      height: 28,
                                      background: getBackgroundColor(row),
                                    }}
                                    className={
                                      classNames(
                                        classes.tableRow,
                                        row.error
                                          ? classes.errorRow
                                          : null
                                      )
                                    }
                                    key={index}
                                  >
                                    {columns?.map((item: any, idx: number) => (
                                      (item.column_name !== 'sale_for_hide' && item.showed) && (
                                        <TableCell
                                          style={{
                                            minWidth: item.width,
                                            maxWidth: item.width,
                                          }}
                                          className={classes.tableCell}
                                          key={idx}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: getAlign(item.column_type),
                                            }}
                                            className='vid'
                                          >
                                            {item.column_type !== 'bool' ? (
                                              <>
                                                {item.column_type === 'int'
                                                ? <CurrencyFormatter light={light} currency={Number(row[item.column_name])} />
                                                : (item.column_status && row[item.column_name] !== null && light(row[item.column_name] + ''))
                                                    ? <span className={classes.checkboxWrap}>
                                                        <FormControlLabel
                                                          className={classes.cellCheckbox}
                                                          control={
                                                            <Checkbox
                                                              color="default"
                                                              checked={row[item.column_status] === 'True'}
                                                            />
                                                          }
                                                          checked={
                                                            row[item.column_status] === 'True'
                                                          }
                                                          onChange={() => 
                                                            saveData(
                                                              row.supplier_article,
                                                              row[item.column_status] === 'True' ? 0 : 1,
                                                              item.column_status,
                                                              index
                                                            )
                                                          }
                                                          label={undefined}
                                                        /> &nbsp;
                                                        {!rublesProcent
                                                          ?
                                                          row[item.column_name] !== null && 
                                                          light(row[item.column_name] + '')
                                                          : <CurrencyFormatter
                                                              light={light}
                                                              currency={
                                                                Number(row[item.column_name])
                                                              }
                                                            />}
                                                        
                                                      </span> 
                                                              :
                                                            row[item.column_name] !== null && 
                                                              light(row[item.column_name] + '')
          
                                                }
                                              </>
                                            ) : (
                                              <>
                                                <FormControlLabel
                                                  className={classes.cellCheckbox}
                                                  control={
                                                    <Checkbox
                                                      color="default"
                                                      checked={row[item.column_name] === 'True'}
                                                    />
                                                  }
                                                  checked={
                                                    row[item.column_name] === 'True'
                                                  }
                                                  onChange={() => 
                                                    saveData(
                                                      row.supplier_article,
                                                      row[item.column_name] === 'True' ? 0 : 1,
                                                      item.column_name,
                                                      index
                                                    )
                                                  }
                                                  label={undefined}
                                                />
                                              </>
                                            )}
                                          </div>
                                        </TableCell>
                                      )
                                    ))}
                                  </TableRow>
                              ))
                            : null }
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow style={{ height: 28 }}>
                              <TableCell className='vid' style={{minWidth: 260, maxWidth: 260}}></TableCell>
                            </TableRow>
                          </TableBody>
                        )
                      }
                    </Table>
                  </TableContainer>
                  <div style={{position: 'fixed', bottom: 0,right: 30, marginBottom: 10}}>
                    <Pagination count={total} page={page} onChange={(_, num) => setPage(num)} shape="rounded"/>
                  </div>
                  </>
              //   </AccordionDetails>
              // </Accordion>
            ))
          ) : null
          :
          (groupParams && groupParams.length) ? (
            groupParams.map((group: string) => (
              <Accordion
                className={classes.paramsAccordion}
                TransitionProps={{ timeout: 0 }}
                key={group}
              >
                <AccordionSummary className={classes.paramsAccordionsSummary}>
                  <div className={classes.paramsAccordionsTitleBlock}>
                    <ArrowForwardIos />
                    <span className={classes.paramsAccordionTitle}>{group}</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.paramsAccordionDetails}>
                  <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table}>
                      {(columns && table.data) ?
                        (
                          <TableBody>
                            {filteredTable.length
                              ? filteredTable?.map((row: any, index: number) => (
                                (group === row.brand) &&
                                  <TableRow
                                    style={{
                                      height: 28,
                                      background: getBackgroundColor(row),
                                    }}
                                    className={
                                      classNames(
                                        classes.tableRow,
                                        row.error
                                          ? classes.errorRow
                                          : null
                                      )
                                    }
                                    key={index}
                                  >
                                    {columns?.map((item: any, idx: number) => (
                                      (item.column_name !== 'sale_for_hide' && item.showed) && (
                                        <TableCell
                                          style={{
                                            minWidth: item.width,
                                            maxWidth: item.width,
                                          }}
                                          className={classes.tableCell}
                                          key={idx}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: getAlign(item.column_type),
                                            }}
                                            className='vid'
                                          >
                                            {item.column_type !== 'bool' ? (
                                              <>
                                                {item.column_type === 'int'
                                                  ? <CurrencyFormatter light={light} currency={Number(row[item.column_name])} />
                                                  : (item.column_status && row[item.column_name] !== null && light(row[item.column_name] + '') )
                                                    ? <span className={classes.checkboxWrap}>
                                                        <FormControlLabel
                                                          className={classes.cellCheckbox}
                                                          control={
                                                            <Checkbox
                                                              color="default"
                                                              checked={row[item.column_status] === 'True'}
                                                            />
                                                          }
                                                          checked={
                                                            row[item.column_status] === 'True'
                                                          }
                                                          onChange={() => 
                                                            saveData(
                                                              row.supplier_article,
                                                              row[item.column_status] === 'True' ? 0 : 1,
                                                              item.column_status,
                                                              index
                                                            )
                                                          }
                                                          label={undefined}
                                                        /> &nbsp;
                                                        {!rublesProcent
                                                         ?
                                                         row[item.column_name] !== null && 
                                                         light(row[item.column_name] + '')
                                                          : <CurrencyFormatter
                                                              light={light}
                                                              currency={
                                                                Number(row[item.column_name])
                                                              }
                                                            />}
                                                        
                                                      </span> 
                                                    :
                                                    row[item.column_name] !== null && 
                                                    light(row[item.column_name] + '')
                                                }
                                              </>
                                            ) : (
                                              <>
                                                <FormControlLabel
                                                  className={classes.cellCheckbox}
                                                  control={
                                                    <Checkbox
                                                      color="default"
                                                      checked={row[item.column_name] === 'True'}
                                                    />
                                                  }
                                                  checked={
                                                    row[item.column_name] === 'True'
                                                  }
                                                  onChange={() => 
                                                    saveData(
                                                      row.supplier_article,
                                                      row[item.column_name] === 'True' ? 0 : 1,
                                                      item.column_name,
                                                      index
                                                    )
                                                  }
                                                  label={undefined}
                                                />
                                              </>
                                            )}
                                          </div>
                                        </TableCell>
                                      )
                                    ))}
                                  </TableRow>
                              ))
                            // : fakeRows?.map((row: any, index: number) => (
                            //   <TableRow
                            //     style={{
                            //       height: 28,
                            //       background: row.status === 'True' ? 'rgba(23, 115, 103, 0.05)' : 'transparent',
                            //     }}
                            //     className={classes.tableRow}
                            //     key={index}
                            //   >
                            //     {cols?.data?.map((item: any, idx: number) => (
                            //       <TableCell
                            //         style={{
                            //           minWidth: item.width,
                            //           maxWidth: item.width,
                            //         }}
                            //         className={classes.tableCell}
                            //         key={idx}
                            //       >
                            //         {item.column_name !== 'status' ? (
                            //           <>
                            //             {item.column_type === 'int'
                            //               ? <CurrencyFormatter currency={Number(row[item.column_name])} />
                            //               : row[item.column_name]
                            //             }
                            //           </>
                            //         ) : (
                            //           <FormControlLabel
                            //             className={classes.cellCheckbox}
                            //             control={
                            //               <Checkbox
                            //                 color="default"
                            //                 checked={row[item.column_name] === 'True'}
                            //               />
                            //             }
                            //             checked={row[item.column_name] === 'True'}
                            //             onChange={() => 
                            //               saveData(
                            //                 row.uid,
                            //                 row[item.column_name] === 'True' ? 0 : 1,
                            //                 item.column_name,
                            //                 index
                            //               )
                            //             }
                            //             label={undefined}
                            //           />
                            //         )}
                            //       </TableCell>
                            //     ))}
                            //   </TableRow>
                            // ))}
                            : null }
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow style={{ height: 28 }}>
                              <TableCell className='vid' style={{minWidth: 260, maxWidth: 260}}></TableCell>
                            </TableRow>
                          </TableBody>
                        )
                      }
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))
          ) : null}
          
          
        </div>
      </div>
    </>
  )
}
