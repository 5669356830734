import { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles: any = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
  })
);

const Empty: FC = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.children}
    </div>
  )
}

export default Empty;
