import React, { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import CustomModal from '../../components/UI/CustomModal';
import { getPrimaryDataSearchActual, getReportList } from '../../api/history';
import { useQuery } from 'react-query';
import moment from 'moment';
import 'moment/locale/ru'
import cookie from 'cookie';
import LoadDataInfoBlock from '../../components/LoadData/InfoBlock';
import { deleteColum, getNoData } from '../../api/reports';
import Spinner from '../../components/UI/Spinner';
import CustomSnackbar from '../../components/UI/CustomSnackbar';
import { updateOrgStorageType } from '../../api/organizations';
import LoadDataReportCard from '../../components/LoadData/ReportCard';
import { useNewWb } from '../Admin/store';

const useStyles: any = makeStyles(() =>
  createStyles({
    loadDataPage: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 32,
    },
    cardList: {
      maxWidth: 702,
      width: '100%',
      marginRight: 32,
    },
    stockModalContent: {
      paddingTop: 32,
      paddingBottom: 16,
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      // marginTop: 32,
      marginBottom: 16,

      '& span': {
        color: '#FF6C4B',
      },
    },
    miniField: {
      maxWidth: 300,
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: '100%',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '14px 16px',
      width: '100%',
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,
    },
    stockModalCheckbox: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      marginBottom: 16,

      '& svg': {
        fill: '#37352F',
      },
    },
    details: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      minWidth: 112,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& .MuiSvgIcon-root': {
        width: 16, height: 16
      },
    },
    needUpdate: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FF6C4B',
      display: 'inline-flex',
      alignItems: 'center',
    },
  }),
);

export default function LoadData() {
  const classes = useStyles();

  const [loader, setLoader] = React.useState(false);
  const [currentTypeUid, setCurrentTypeUid] = React.useState<string | null>(null);
  const [actualLeftover , setActualLeftover] = useState(null)
  const [actual, setActual] = React.useState<any>([]);
  const [noActual, setNoActual] = React.useState<any>([]);

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [confirmAction, setConfirmAction] = React.useState(false);

  const [updateTipsModal, setUpdateTipsModal] = React.useState(false);
  const [allGoodUploadDate, setAllGoodUploadDate] = React.useState('');
  const storageType = useQuery('storageType', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await updateOrgStorageType({ org: uid, action: 'show' });
    return result.data.data;
  });

  const primaryDataSearchActual = useQuery(['primaryDataSearchActual', updateTipsModal], async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await getPrimaryDataSearchActual(uid);
    return result.data.data;
  });

  const [newItem, setNewItem] = useState(null)


  const report = useQuery('report', async () => {
    setLoader(true);
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const response = await getReportList(uid || '');
    const dataLeftover = response.data.data.filter(el => el.uid === '749fb88d-40db-4c39-a483-1051bfc5bb51')
    setActualLeftover(dataLeftover[0])
    //&& el.uid !== '749fb88d-40db-4c39-a483-1051bfc5bb51' все остатки
    const data = response.data.data.filter(el => el.uid !== '147bda8c-d997-4050-b497-d7a0aed59265' 
    &&  el.uid !==  '005fea2d-b561-4c81-a1e6-85554bfbd7ba' 
    && el.uid !==  'ceee8d45-32fb-4710-aa62-d26d2a75bb87'
    && el.uid !== '749fb88d-40db-4c39-a483-1051bfc5bb51'
    )
    setNewItem(data)
    let actualArr: any = [];
    let noActualArr: any = [];
    response.data.data.forEach((item: any) => {
      const diffRes = differenceDates(item.lastDateUpload /* item.periodNumber */);
      if (item.uid === '0a5956f9-a9f6-49c0-8080-bb1fda1251a7')
      
        setAllGoodUploadDate(item.lastDateUpload);
      if (item.lastDateUpload && diffRes) {
        actualArr.push(item);
      } else {
        noActualArr.push(item);
      }
    });
    setActual(actualArr);
    setNoActual(noActualArr);

    setLoader(false);
    return response.data.data;
  });


  const noData = useQuery('noData', async () => {
    setLoader(true);
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await getNoData({
      org: uid,
    });

    setLoader(false);
    return result?.data?.data;
  });

  moment.locale('ru');

  const differenceDates = (lastDateUpload: string) => {
    const a: any = moment().startOf('day');
    const b: any = moment(lastDateUpload);
    return Math.round(moment.duration(a - b).asDays()) * -1 > -1;
  };

  const updateList = () => {
    report.refetch();
    noData.refetch();
    setUpdateTipsModal(!updateTipsModal);
  };

  const handleConfirmAction = (show: boolean, uid: string) => {
    setConfirmAction(show);
    setCurrentTypeUid(uid);
  };
  const resetReport = async () => {
    setLoader(true)
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await deleteColum({
      primary: currentTypeUid,
      org: uid
    })
    .then(res => {
      handleConfirmAction(false, null);
      updateList();
    })
    .finally(() => {
      updateList();
      setLoader(false)
    })
  };
  const dataStore = useNewWb()
  return (
    <div>
      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />

      <Spinner open={loader} />

      <div className={classes.loadDataPage}>
        <div className={classes.cardList}>
          {newItem?.map((item: any, index: number) => (
            <div key={index}>
              {(item.uid !== '974b957b-6df3-4811-9eaf-54c12898227f' )
                ? <LoadDataReportCard
                    setLoader={setLoader}
                    item={item}
                    actualLeftover={actualLeftover}
                    storageType={(storageType?.data?.length) ? storageType?.data?.[0].type : []}
                    updateList={updateList}
                    allGoodUploadDate={allGoodUploadDate}
                  />
                : (item.uid === '974b957b-6df3-4811-9eaf-54c12898227f'
                  && (storageType?.data?.length) ? storageType?.data?.[0].type !== 0 : []) && !dataStore.toggleNew
                    ? <LoadDataReportCard
                        setLoader={setLoader}
                        item={item}
                        storageType={(storageType?.data?.length) ? storageType?.data?.[0].type : []}
                        updateList={updateList}
                      /> : null}
            </div>
          ))}
        </div>
        <CustomModal
          title="Подтвердите действие"
          show={confirmAction}
          closeEvent={() => setConfirmAction(false)}
          successEvent={() => resetReport()}
          successText="Да"
          cancelText="Нет"
        >
          <br />
        </CustomModal>
        <LoadDataInfoBlock
          actual={actual}
          noActual={noActual}
          noData={noData.data}
          list={primaryDataSearchActual.data || []}
        />
      </div>
    </div>
  )
}
