import React, { useEffect, useRef, useState } from 'react';
import { Backdrop, Button, Modal, Typography, createStyles, makeStyles, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Breadcrumbs from './Breadcrumbs';
import { ClockIcon } from './Icons';
import { getUsers, logout } from "../../api/auth";
import logoutIcon from '../../assets/icons/logout.png';
import { useQuery } from 'react-query';
import { getReportList } from '../../api/history';
import { useLocation } from 'react-router-dom';
import cookie from 'cookie';
import CustomModal from './CustomModal';
import { organizationsHistory } from '../../api/organizations';
import qrCodeImage from '../../assets/icons/qr-code.PartnerProgramNew.svg';
import { useRoles } from '../../pages/Admin/store';

const useStyles = makeStyles((theme) =>
  createStyles({
    profileHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #d3d1cb',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      width: 'auto',
      padding: '16px 32px',
    },
    breadcrumb: {
      display: 'flex',
      flexGrow: 1,
    },
    breadcrumbItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
    },
    historyBtn: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        marginRight: 10,
      },
    },
    historyBtnText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      borderBottom: '1px solid #37352F'
    },
    divider: {
      width: 1,
      height: 24,
      background: '#D3D1CB',
      marginLeft: 24,
    },
    iconBtn: {
      marginLeft: 24,
      cursor: 'pointer',
    },
    headerPartnerGreen: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      justifyContent: 'center',
      fontWeight: 800,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      borderRadius: 8,
      padding: '12px 12px',
      minWidth: '90px',
      height: 10,
      // textTransform: 'capitalize',
      marginRight: 12,
      color: 'green',
      background: '#DAF5D5',
      border: '1px solid #5E863B',
      [theme.breakpoints.down(1090)]: {
        fontSize: 10,
        padding: '16px 14px',
      }
    },
    modalContainer: {
      position: 'relative',
    },
    modalOpenButton: {
      position: 'relative',
    },
    modalText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: "center",
    },
    modalLinkButton: {
      color: "white",
      background: 'black',
      borderRadius: 9,
      padding: "0 20 0 20",
      backgroundColor: '#37352F'
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    modalStyle: {
      width: 370,
      // height: 630,
      background: '#DAF5D5',
      padding: 25,
      borderRadius: 8,
      left: '-200px',
      top: '55px',
      position: 'absolute',
    }
  }),
);

interface Props {
  showHistory: boolean;
  historyPage?: string;
  staticName?: string;
  setHistoryList: (history: any[]) => void;
  setOpenHistory: () => void;
}

const ProfileHeader = (props: Props) => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const pathnames: any = pathname.split("/").filter((x: any) => x);

  const [open, setOpen] = React.useState(false);
  const [confirmLogoutModal, setConfirmLogoutModal] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);

  const history = useQuery(['history', { pathname, open }], async (req: any) => {
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const response = await organizationsHistory({ id: uid, path: pathname.slice(1) });
      let newData = response.data.data.map(el => {
        return {
          ...el,
          sec: new Date(el.date_action.split('.').reverse().join('-')).getTime(),
        }
      })
      const sortData = newData.sort((a, b) => b.sec - a.sec)
      props.setHistoryList(sortData || []);
      return response.data.data || [];
    } catch (error) {
      console.error(error);
      props.setHistoryList([]);
    }
  }, { keepPreviousData: true });

  const listOfReport = useQuery('listOfReport', async () => {
    if (pathnames[1] === 'current-remainder') {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const result = await getReportList(uid);
      return result.data.data;
    }
  });

  const openHistoryModal = () => {
    setOpen(!open);
    props.setOpenHistory();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isAdm, setIsAdm] = useState(true);
  const checkAdm = async() => {
    const result: any = await getUsers(window.location.pathname);
    const isAdmim = result?.data?.data?.[0]?.name_.filter(el => el.indexOf('Админ') > -1);
    if(isAdmim.length > 0) setIsAdm(true)
    if(isAdmim.length === 0) setIsAdm(false)
  }
  useEffect(() => {
    checkAdm()
  }, []);

  const rootEl = useRef(null);
  const rootBtn = useRef(null);

  // useEffect(() => {
  //     const onClick = e => rootEl.current.contains(e.target) || rootBtn.current.contains(e.target) || handleCloseModal() ;
  //     document.addEventListener('click', onClick);
  //     return () => document.removeEventListener('click', onClick);
  // }, []);

  const dataStoreRole = useRoles()

  const logoutNew = () => {
    dataStoreRole.logoutRole()
    logout()
  }


  return (
    <div className={classes.profileHeader}>
      <div className={classes.breadcrumb}>
        <Breadcrumbs staticName={props.staticName} />
      </div>
      <div className={classes.modalContainer}>
        <Box className={classes.modalOpenButton}>
        {!isAdm &&
          <Button
            ref={rootBtn}
            variant="contained"
            className={classes.headerPartnerGreen}
            onClick={handleOpenModal}>
            ПОЛУЧИ от 15000 &#8381;
          </Button>
        }
        {openModal &&
          <div ref={rootEl} className={classes.modalStyle}>
            <div>
                <IconButton className={classes.closeButton} onClick={handleCloseModal}>
                  <CloseIcon onClick={handleCloseModal}/>
                </IconButton>
                <Typography className={classes.modalText} id="modal-title">
                  <span>
                    Приводи друзей получай ДЕНЬГИ!
                  </span>
                  <br></br>
                  <span>
                    УСЛОВИЯ ПРОГРАММЫ
                  </span>
                  <br></br>
                  <span>
                    1. Запроси партнерскую ссылку и отправь
                    тому, кому хочется посоветовать Price Market.
                    Или опубликуй её в любой соцсети, чтобы увидели сразу все.
                  </span>
                  <br></br>
                  <span>
                    2. Через 14 дней после того как твой друг оплатил услугу сопровождения от команды PriceMarket, тебе придет сообщение с суммой вознаграждения.
                  </span>
                  <br></br>
                  <span>
                    3. Тебе останется только лишь получить свои
                    деньги удобным способом.
                  </span>
                  <br></br>
                  <span>Больше друзей - больше денег!</span>
                  <br></br>
                  <Button
                    className={classes.modalLinkButton}
                    variant="contained"
                    href="https://wa.me/79648965197?text=ХОЧУ ССЫЛКУ ДЛЯ ДРУЗЕЙ"
                    target="_blank"
                    draggable="false"
                  >
                    ХОЧУ ССЫЛКУ ДЛЯ ДРУЗЕЙ
                  </Button>
                  <img src={qrCodeImage} draggable="false" alt="qr код" style={{ width: '40%', height: 'auto', marginTop: 15 }} />
                </Typography>
              </div>
          </div>
          }
          {/* {openModal && (
            <Modal
              style={{inset: 'none'}}
              className={classes.modalStyle}
              open={openModal}
              onClose={handleCloseModal}
              closeAfterTransition={true}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              BackdropComponent={Backdrop}
              BackdropProps={{ open: true, className: classes.backdrop }}
            >
              <div>
                <IconButton className={classes.closeButton} onClick={handleCloseModal}>
                  <CloseIcon />
                </IconButton>
                <Typography className={classes.modalText} id="modal-title">
                  <span>
                    Приводи друзей получай ДЕНЬГИ!
                  </span>
                  <br></br>
                  <span>
                    УСЛОВИЯ ПРОГРАММЫ
                  </span>
                  <br></br>
                  <span>
                    1. Запроси партнерскую ссылку и отправь
                    тому, кому хочется посоветовать Price Market.
                    Или опубликуй её в любой соцсети, чтобы увидели сразу все.
                  </span>
                  <br></br>
                  <span>
                    2. Через 14 дней после того как твой друг
                    оплатил сервис, тебе придет сообщение с
                    суммой вознаграждения.
                  </span>
                  <br></br>
                  <span>
                    3. Тебе останется только лишь получить свои
                    деньги удобным способом.
                  </span>
                  <br></br>
                  <span>Больше друзей - больше денег!</span>
                  <br></br>
                  <Button
                    className={classes.modalLinkButton}
                    variant="contained"
                    href="https://wa.me/79648965197?text=ХОЧУ ССЫЛКУ ДЛЯ ДРУЗЕЙ"
                    target="_blank"
                    draggable="false"
                  >
                    ХОЧУ ССЫЛКУ ДЛЯ ДРУЗЕЙ
                  </Button>
                  <img src={qrCodeImage} draggable="false" alt="qr код" style={{ width: '40%', height: 'auto', marginTop: 15 }} />
                </Typography>
              </div>
            </Modal>
          )} */}
        </Box>
      </div>
      {
        history.data && (
          <span className={classes.historyBtn} onClick={() => openHistoryModal()}>
            <ClockIcon width="24" height="24" strokeWidth={1.3} />
            <span className={classes.historyBtnText}>
              История действий
            </span>
          </span>
        )
      }
      <div className={classes.divider}></div>
      <span onClick={() => setConfirmLogoutModal(true)} className={classes.iconBtn}>
        <img
          width="16"
          height="16"
          style={{ cursor: 'pointer', }}
          title="Выйти"
          src={logoutIcon}
          alt="logout"
        />
      </span>
      <CustomModal
        title="Вы точно хотите выйти из системы?"
        show={confirmLogoutModal}
        closeEvent={() => setConfirmLogoutModal(false)}
        successEvent={logoutNew}
        successText="Да"
        cancelText="Нет"
      >
        <br />
      </CustomModal>
    </div >

  )
}

export default ProfileHeader;
