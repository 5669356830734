import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// const baseURL = 'http://85.175.216.246:8083';

export const primaryDataSearchFBS = async (org: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.searchpPrimaryData}/primary_data_search/fbs`, { org });
};
export const primaryDataSearchSale = async (org: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.searchpPrimaryData}/primary_data_search/sale`, { org });
};
