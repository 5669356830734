import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createStyles, Hidden, makeStyles, Tooltip, Typography, Button } from '@material-ui/core';
import cookie from 'cookie';

import MainLogo from '../assets/icons/mainImage.svg';
import { answer, login, verefication } from '../api/auth';
import Empty from '../components/layout/empty';
import Spinner from '../components/UI/Spinner';
import CustomSnackbar from '../components/UI/CustomSnackbar';
import useAuth from '../context/auth';
import React from 'react';
import { getUserUid } from '../api/reports';
import moment from 'moment';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    auth: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
      marginLeft: '13%',
    },
    submit: {
      width: 400,
      height: 40,
      background: '#37352F',
      borderRadius: 8,
      outline: 'none',
      border: 'none',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#fff',
      textTransform: 'capitalize',

      '&:hover': {
        background: '#827F75',
        cursor: 'pointer',
      },
      '&[disabled]': {
        color: '#FFFFFF',
        background: '#D3D1CB',
      },
    },
    back: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: 14,
      '&:hover': {
        color: '#827f75',
      },
      '& svg': {
        stroke: '#37352F',
        marginRight: 13,
      },
      // '&:hover svg': {
      //   stroke: '#827f75',
      // },
    },
    title: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '38px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      marginBottom: 8,
    },
    info: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      color: '#37352F',
      marginBottom: 48,
    },
    updateLink: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      border: 'none',
      outline: 'none',
      background: 'transparent',
      textDecoration: 'underline',
      padding: 0,
      cursor: 'pointer',
      '&:disabled': {
        color: '#D3D1CB',
        cursor: 'default',
      },
    },
    repeatText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    codeInput: {
      width: 180,
      height: 44,
      border: '1px solid #D3D1CB',
      outline: 'none !important',
      boxSizing: 'border-box',
      borderRadius: 8,
      padding: '14px 16px',
      marginTop: 8,
      marginBottom: 56,
      letterSpacing: 7,
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
    },
    logoImage: {
      maxWidth: 592,
      maxHeight: 500,
      height: '100%',
    },
    '@global': {
      '.MuiTooltip-tooltip': {
        minWidth: 358,
        background: '#37352F',
        borderRadius: 8,

        '& ul': {
          paddingLeft: '16px !important',
        },
        '& li': {
          fontFamily: 'Work Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px',
          color: '#FFFFFF',
          marginBottom: 16,
        },
        '& li:last-child': {
          marginBottom: 0,
        }
      }
    }
  })
);

const VerificationForm: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [phone, setPhone] = useState('');
  const [phoneMask, setPhoneMask] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [error, setError] = useState<string | null>();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const {
    state: { user },
    dispatch,
  } = useAuth();

  useEffect(() => {
    let cookies = cookie.parse(document.cookie);
    const tel = cookies.n.replace(/\D+/g, '');
    setPhone(tel);
    setPhoneMask(cookies.n);
    startTimer();
  }, [phone]);


  const answerWot = async() => {
    let cookies = cookie.parse(document.cookie);
    let tel = cookies.n.replace(/\D+/g, '')
    const result = await answer(tel, true)
    if(result.data.description[0].message !== null) {
      setError(result.data.description[0].message)
      setShowSnackbar(true);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      answerWot()
    }
      , 3000);
  }, []);

  const startTimer = () => {
    let time = 30;
    let interval = setInterval(() => {
      time -= 1;
      setTimer(time);
      if (!time) {
        setDisabledBtn(false);
        clearInterval(interval);
      }
    }, 1000);
  }

  const onChange = (v: string) => {
    setCode(v);

    if (v.length === 4) {
      const newDay = moment(new Date).format('L').substr(0,2)
      const orangeDay = localStorage.getItem('orangeDay')
      if(newDay !== orangeDay) {
        document.cookie = `orange = ${''}`;
      }
      setLoading(true);
      setTimeout(() => {
        
        verify(v);
      }, 200);
    }
  };

  const moveBack = () => {
    navigate('/login', { replace: true });
  };

  const verify = async (code: string) => {
    try {
      const result: any = await verefication(phone, code);

      // document.cookie = 'n=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

      if (!result.data.success) {
        setError(result.data.description[0].message);
        setShowSnackbar(true);
      }
      if (result.data.success) {
        await getUserUid()
        .then(res => document.cookie = `user_uid = ${res.data.user_uid.uid}`)
        .catch(err => console.log(err))
        setTimeout(() => {
          if (result.data.data.roles[0] === 'Администратор системы') {
            window.location.href = '/admin';
            return;
          }
          window.location.href = '/profile';
        }, 300);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getVerifyCode = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (disabledBtn) return;
    setLoading(true);
    setCode('');
    setDisabledBtn(true);
    startTimer();
    try {
      const tel = phone.replace(/\D+/g, '');
      const result: any = await login(tel);
      const user = { phone: tel };

      if (result.data.success) {
        dispatch({ type: 'LOAD_USER', user });
        navigate("/verification", { replace: true });
        setTimeout(() => answerWot(), 3000)
      } else {
        setError(result.data.description[0].reason);
        setShowSnackbar(true);
      }
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      if (error.status === 422) {
        setError(error.data.errors);
        setShowSnackbar(true)
      }
    }
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <Empty>
      <CustomSnackbar
        open={showSnackbar}
        message={error || ''}
        handleClose={() => setShowSnackbar(false)}
      />
      <div className={classes.root}>
        <div className={classes.auth}>
          <Spinner open={loading} />
          <div className="logForm">
            <div className={classes.back} onClick={() => moveBack()}>
              <svg width="6" height="12" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 13L1 7L7 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <p>Назад</p>
            </div>
            <div className="enterInfo">
              <h1 className={classes.title}>Код подтверждения</h1>
              <p className={classes.info}>
                На номер {phoneMask} будет выслано сообщение с авторизационным кодом в приложение <span style={{color: 'red'}}>WhatsApp</span>.<br /> Вам необходимо ввести его в окно ниже.
              </p>
            </div>
            <div className="inputPhone">
              <input
                className={classes.codeInput}
                placeholder="____"
                type="text"
                maxLength={4}
                value={code}
                onChange={(e: any) => onChange(e.target.value)}
                autoFocus
              />
            </div>
            <div>
              {disabledBtn
                ? <p className={classes.repeatText}>
                    Запросить код повторно можно через {timer} сек
                  </p>
                : <Tooltip
                    title={
                      <React.Fragment>
                        <ul>
                          <li>Проверьте правильно ли введен номер телефона, 
                            <br />который зарегистрирован в WhatsApp, нажав на кнопку "Назад" и вбив его заново.</li>
                          <li> Если код не приходит после нескольких попыток, 
                            <br />напишите в поддержку, нажав на кнопку "Написать в поддержку".</li>
                        </ul>
                      </React.Fragment>
                    }
                    placement="bottom"
                  >
                    <button
                      className={classes.updateLink}
                      onClick={(e) => getVerifyCode(e)}
                    >
                      Отправить код повторно
                    </button>
                  </Tooltip>
              }
                <div style={{marginTop: 20}}>
                  <Button
                    className={classes.submit}
                    onClick={() => openInNewTab('http://wa.me/79648965197')}
                  >
                        <span style={{color: 'white', textDecoration: 'none',textTransform: 'none'}}>Написать в поддержку</span>
                  </Button>
                </div>
            </div>
          </div>
        </div>

        <Hidden smDown>
          <div className={classes.logo}>
            <img className={classes.logoImage} src={MainLogo} alt="Main Logo" />
          </div>
        </Hidden>
      </div>
    </Empty>
  );
};

export default VerificationForm;
