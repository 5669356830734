import React from 'react';
import classNames from 'classnames';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    tableContainerBlock: {
      overflowX: 'hidden',
      minWidth: 'fit-content',
      border: 'none',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      tableLayout: 'fixed',

      '& .MuiTableRow-head': {
        background: '#fff',
      },
      '& .MuiTableCell-head': {
        borderRight: '1px solid #E9E9E7',
        textAlign: 'center',
        lineHeight: 1,
        boxSizing: 'border-box',
        padding: '8px !important',
        verticalAlign: 'middle',
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        padding: '2px 8px !important',
        borderRight: '1px solid #E9E9E7',
        boxSizing: 'border-box',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 16px !important',
        borderRight: 'none',
      },
    },
    tableHeader: {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 8,
      boxShadow: 'none',
    },
    tableCell: {
      verticalAlign: 'initial',
      textAlign: 'left',
      fontSize: '0.775rem',
      position: 'relative',
      minHeight: 30,
      padding: 10,

      '&:hover': {
        background: 'rgba(225, 225, 228, 0.5) !important'
      },
    },
    tableHeadRow: {},
    tableCellTh: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      position: 'relative',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      padding: 8,
      margin: 0,
      verticalAlign: 'middle',
      textAlign: 'center',

      '& span': {
        fontWeight: 'bold',
        color: 'black',
      },
    },
  })
);

interface Props {
  cols: any;
  param?: string;
  tableWidth: number;
}

const LoadDataTableHeader = (props: Props) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const cellRenderCondition = (name, showed) => {
    const linkId = searchParams.get("id");
    const idList = {
      all: '0a5956f9-a9f6-49c0-8080-bb1fda1251a7',
      currWeek: '94e066eb-3b01-4916-993c-73f06978145b',
      fbs: '974b957b-6df3-4811-9eaf-54c12898227f',
      stockBalance: '147bda8c-d997-4050-b497-d7a0aed59265',
      currPromotion: '960d6482-dc35-4834-871a-1ee8a26f1d02',
      detail: '7e8d9639-2419-47cd-aca1-0c8829528622',
      purchase: '005fea2d-b561-4c81-a1e6-85554bfbd7ba',
      sizeWeightVolume: 'ceee8d45-32fb-4710-aa62-d26d2a75bb87',
      paramBrandProduct: '5fe9a4b9-423d-4bad-bbc9-e15660568081'
    };

    if (name.indexOf('week_') !== -1 && name.indexOf(`_${props.param}`) === -1) return false;
    // if (linkId === idList.all && name === 'brand') return false;
    // if (linkId === idList.currPromotion && name === 'name_sha res') return false;
    if (!showed) return false;
    if (showed) return true;
    return true;
  };

  return (
    <TableContainer
      component={Paper}
      className={
        classNames(classes.tableContainerBlock, classes.tableHeader)
      }
    >
      <Table className={classes.table} style={{width: props.tableWidth}}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            {props.cols.map((col: any, index: number) => (
              cellRenderCondition(col.column_name, col.showed) &&
                <TableCell
                  className={classNames(classes.tableCell, classes.tableCellTh)}
                  style={{
                    width: col.width,
                    maxWidth: col.width,
                    minWidth: col.width
                  }}
                  key={index}
                >
                  <div dangerouslySetInnerHTML={{__html: col.column_caption}} />
                </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  )
}

export default LoadDataTableHeader;
