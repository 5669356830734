import React from 'react'
import cookie from 'cookie'
import { makeStyles, createStyles, Popover, Typography } from '@material-ui/core';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getDetailData } from '../../../api/reports';
import { Check } from '@material-ui/icons';
import { InfoIcon, ErrorIcon } from '../../../components/UI/Icons';
import { dateilDate, dateTrunc } from '../../../api/organizations';
import Spinner from '../../UI/Spinner';

const useStyles: any = makeStyles(() =>  
  createStyles({
    popover: {
      pointerEvents: 'none',

      '& .MuiPopover-paper': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#FFFFFF',
        borderRadius: 8,
        background: '#37352F',
        boxShadow: '-2px 3.5px 13px -3px rgba(113,114,171,0.3)',
        padding: 16,
      },
    },
    cardLink: {
      position: 'relative',
      display: 'inline-block',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    cardLinkLine: {
      position: 'absolute',
      display: 'inline-block',
      bottom: -1,
      left: 0,
      width: '100%',
      height: 1,
      background: '#000',
    },
    needUpdate: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FF6C4B',
      display: 'inline-flex',
      alignItems: 'center',
    },
    detailsRange: {
      display: 'inline-block',
      minWidth: 150,
    },
    iconBlock: {
      '& .MuiSvgIcon-root': {
        width: '16px !important',
        height: '16px !important',
      },
    },
    details: {
      fontSize: 14,
    },
  })
);

interface Props {
  detailNoActual: (val: boolean) => void; 
  update: number;
};

const MorePopoverDetail = (props: Props) => {
  const classes = useStyles();

  const [weeksList, setWeekList] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [periodNotFound, setPeriodNotFound] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    details.refetch();
  }, [props.update]);

  const details = useQuery('details', async () => {
    // setLoader(true);
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      let copyWeeksList = JSON.parse(JSON.stringify([
        { weekNum: 1, name: '- 1 неделя', keyWeek: 'week_1', keyThisWeek: 'this_week_1' },
        { weekNum: 2, name: '- 2 неделя', keyWeek: 'week_2', keyThisWeek: 'this_week_2' },
        { weekNum: 3, name: '- 3 неделя', keyWeek: 'week_3', keyThisWeek: 'this_week_3' },
        { weekNum: 4, name: '- 4 неделя', keyWeek: 'week_4', keyThisWeek: 'this_week_4' },
        { weekNum: 5, name: '- 5 неделя', keyWeek: 'week_5', keyThisWeek: 'this_week_5' },
        { weekNum: 6, name: '- 6 неделя', keyWeek: 'week_6', keyThisWeek: 'this_week_6' },
        { weekNum: 7, name: '- 7 неделя', keyWeek: 'week_7', keyThisWeek: 'this_week_7' },
        { weekNum: 8, name: '- 8 неделя', keyWeek: 'week_8', keyThisWeek: 'this_week_8' },
        { weekNum: 9, name: '- 9 неделя', keyWeek: 'week_9', keyThisWeek: 'this_week_9' },
        { weekNum: 10, name: '- 10 неделя', keyWeek: 'week_10', keyThisWeek: 'this_week_10' },
        { weekNum: 11, name: '- 11 неделя', keyWeek: 'week_11', keyThisWeek: 'this_week_11' }
      ]));
      const dates = await dateTrunc(uid);

      let checkNotFound = false;

      if (dates?.data?.data) {
        copyWeeksList.forEach((item: any, i: number) => {
          item.dateRange = getDateRow(dates.data.data[i].date_1, dates.data.data[i].date_2);
          item.val = dates.data.data[i].val;
          if (!checkNotFound) {
            checkNotFound = !dates.data.data[i].val ? true : false;
            setPeriodNotFound(checkNotFound);
          }
          item.checkNotFound = checkNotFound;
        });
      }
      const check = copyWeeksList.some((item) => item.checkNotFound);

      props.detailNoActual(check);
      setWeekList(copyWeeksList);
      return dates.data.data;
    } catch (error) {
      console.error(error);
    } finally {
      // setLoader(false);
    }
  });

  const getLastWeek = (d) => {
    let weekOfDay = parseInt(moment().format('E'));
    let last_monday = moment().subtract(weekOfDay + 7 * d - 1, 'days').format('DD.MM');
    let last_sunday = moment().subtract(weekOfDay + 7 * (d - 1), 'days').format('DD.MM');
    return `${last_monday}-${last_sunday}`;
  }

  const getDateRow = (d1, d2) => {
    return `${moment(d1).format('DD.MM')}-${moment(d2).format('DD.MM')}`;
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopopver = Boolean(anchorEl);
  
  
  const filter = () => {
    const result = weeksList?.map((item: any, index: number) => {
      if(item.val || details?.data?.[11]?.[item.keyThisWeek]) {
        return {...item, toggle: true}
      }
      else {
        return {...item, toggle: false}
      }
    })
    let missed: any = []
    if(result) {
      const missedFilter = result.filter(el => {
        if(el.toggle === true) {
          return missed.push(el.weekNum)
        }
      })
    }

    if (missed.length === missed[missed.length - 1]) {
      return localStorage.setItem('missed', '0');
    } else {
      let resultLength = missed[missed.length - 1] + 1;
  
      for (let i = 1; i < resultLength; i++) {
        if (missed.indexOf(i) === -1) {
          return localStorage.setItem('missed', String(i));;
        }
      }
    }
  }

  React.useEffect(() => {    
    filter()
  }, [weeksList, details]);

  
  return (
    <>
      <Spinner open={loader} />

      <span className={classes.needUpdate}>&nbsp;</span>
      <span
        aria-owns={openPopopver ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.cardLink}
    
        onMouseOver={() => details.refetch()}
      >
        Подробнее
        <span className={classes.cardLinkLine}></span>
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        open={openPopopver}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {weeksList?.map((item: any, index: number) => (
          <Typography
            style={{
              maxWidth: 300,
              width: '100%',
              color: details?.data?.[11]?.[item.keyThisWeek] || !item.val ? '#FF6C4B' : '#fff'
            }}
            className={classes.details}
            key={index}
          >
            <span className={classes.detailsRange}>{item.weekNum} ({item.dateRange})</span>
            {(details && details.data) && (
              <span className={classes.iconBlock}>
                {(item.val || details?.data?.[11]?.[item.keyThisWeek]) ? (
                  <Check />
                ) : (
                  <ErrorIcon />
                )}
              </span>
            )}
          </Typography>
        ))}
      </Popover>
    </>
  );
};

export default MorePopoverDetail;
