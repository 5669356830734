import React, {useCallback, useEffect, useState} from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import flag from "../../assets/icons/flag.svg";
import rightArrow from "../../assets/icons/rightArrow.svg"
import user_profile from "../../assets/icons/user_profile.svg"
import circleStock from "../../assets/icons/circleStock.svg"
import StockCircleIcon from "../../assets/icons/StockCircleIcon.svg"
import Main from "../../components/layout/Main";
import { Activity, Bell, Card } from '../../components/UI/Icons'
import { makeStyles, createStyles, Button, Dialog, ButtonGroup, Popover, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from "classnames";
import Question from "../../components/UI/Icons/question";
import { findTariff, getAllActiveTariffs, showGreeting, TariffsType, updateByPurchaseOrg } from '../../api/tariffs';
import { useQuery } from "react-query";
import CurrencyFormatter from "../../components/UI/CurrencyFormatter";
import { Chart } from "../../components/UI/Icons";
import moment from "moment";
import { getOrganizations, setOrganizations, updateOrgStorageType } from "../../api/organizations";
import CustomModal from "../../components/UI/CustomModal";
import { Check } from "@material-ui/icons";
import cookie from 'cookie';
import CustomSnackbar from "../../components/UI/CustomSnackbar";
import launch_Flatline from '../../assets/icons/Campaign launch_Flatline 2.png';
import { useNewWb } from "../Admin/store";
import routes from "../../components/UI/Breadcrumbs/routesList";
const useStyles: any = makeStyles(() =>
  createStyles({
    profileWindow: {
      fontSize: 14,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 680,
      // padding: '32px 72px',
      padding: 32,
    },
    profileItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: 16,
      paddingBottom: 16,
      borderBottom: '1px solid #e9e9e7',
    },
    profileContent: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    profileContentInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 8,
    },
    profileContentTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      marginBottom: 8,
    },
    profileBtn: {
      position: 'relative',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      textDecoration: 'none',
      padding: '12px 16px',
      backgroundColor: '#ffffff !important',
      border: '1px solid #37352f',
      boxSizing: 'border-box',
      borderRadius: 8,
      transition: 'all 0.2s ease-in-out',
      cursor: 'pointer',
      textTransform: 'capitalize',
      '&:hover': {
        border: '1px solid #D3D1CB',
        color: '#D3D1CB',
      },
    },
    tariffModal: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'calc(786px - 64px)',
        width: '100%',
        padding: 32,
        borderRadius: 8,
      }
    },
    tariffModalHeader: {},
    tariffModalTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '23px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
    },
    tariffModalClose: {
      position: 'absolute',
      top: 32,
      right: 32,
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',

      '& svg': {
        width: 24,
        height: 24,
      }
    },
    tariffModalInfoText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#000000',
      margin: 0,
      marginTop: 16,
      marginBottom: 32,
    },
    tariffModalContent: {
      display: 'flex',
    },
    tariffModalMonths: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    tariffModalText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '15px',
      display: 'flex',
      alignItems: 'center',
      color: '#827F75',
      margin: 0,
      marginBottom: 8,
    },
    tariffModalName: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '38px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
      marginBottom: 32,
    },
    tariffModalInfoBlock: {
      maxWidth: 'calc(442px - 64px)',
      width: '100vw',
      padding: 32,
      borderLeft: '1px solid #E9E9E7',
      borderTop: '1px solid #E9E9E7',
      borderBottom: '1px solid #E9E9E7',
      borderRight: '1px solid #E9E9E7',
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    tariffModalPriceBlock: {
      maxWidth: 'calc(280px - 64px)',
      width: '100%',
      padding: 32,
      borderRight: '1px solid #E9E9E7',
      borderTop: '1px solid #E9E9E7',
      borderBottom: '1px solid #E9E9E7',
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
    tariffModalList: {
      paddingLeft: 0,
    },
    tariffModalListItem: {
      display: 'flex',
      marginBottom: 24,

      '& svg': {
        minWidth: 24,
        minHeight: 24,
      }
    },
    tariffModalListContent: {
      marginLeft: 8,
      maxWidth: 250,
    },
    tariffModalListItemTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    tariffModalListItemText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
    },
    tariffModalMonthBtnGroup: {
      marginBottom: 64,
    },
    tariffModalMonthBtn: {
      width: 41,
      height: 44,

      '&.active': {
        background: '#E9E9E7',
        border: '1px solid #37352F',
      },
    },
    borderTopRightRadius8: {
      borderTopRightRadius: '8px !important',
    },
    borderBottomRightRadius8: {
      borderBottomRightRadius: '8px !important',
    },
    borderTopLeftRadius8: {
      borderTopLeftRadius: '8px !important',
    },
    borderBottomLeftRadius8: {
      borderBottomLeftRadius: '8px !important',
    },
    tariffModalPrice: {
      margin: 0,
      marginBottom: 64,

      '& span': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 32,
        lineHeight: '38px',
        // color: '#827F75',
        color: '#000',
      },
    },
    tariffModalPayBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#FFFFFF',
      width: '100%',
      height: 40,
      background: '#37352F',
      borderRadius: 8,
      marginBottom: 8,
      textAlign: 'center',
      textDecoration: 'none',

      '&:hover': {
        background: '#827F75 !important',
      }
    },
    profileTariffInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    profileTariffName: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      marginRight: 4,

      '& svg: hover': {
        cursor: 'pointer !important',
      }
    },
    profileTariffNameRed:{
      color: '#FF3317'
    },
    profileTariffInfoBtn: {

    },
    profileTariffDivider: {
      color: '#D3D1CB',
      marginLeft: 8,
      marginRight: 8,
    },
    questionIcon: {},
    popover: {
      pointerEvents: 'none',

      '& .MuiPopover-paper': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#FFFFFF',
        borderRadius: 8,
        background: '#37352F',
        boxShadow: '-2px 3.5px 13px -3px rgba(113,114,171,0.3)',
        padding: 16,
      },
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: 32,
      marginBottom: 56,

      '& span': {
        color: '#FF6C4B',
      },
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: '100%',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      overflow: 'hidden',
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '14px 16px',
      width: '100%',
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,
    },
    stockCheckbox: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      margin: '0 !important',
      '& .MuiCheckbox-root': {
        padding: '0 !important',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
      },
      '& svg': {
        fill: '#37352F',
      },
    },
    successPayImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    successPayText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: '#37352F',
    },
    actionBlock:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    modalUpgradeBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'none',
      padding: '12px 16px',
      color: '#ffffff',
      background: '#187367',
      borderRadius: 8,
      '&:hover': {
        color: '#fff',
        background: '#209a8a',
      }
    }
  }),
);

const Profile: React.FC = () => {

  const navigate = useNavigate();
  const classes = useStyles();
  const loc = useLocation();
  const [open, setOpen] = useState(false);
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false);
  const [nameOrg, setNameOrg] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedTariff, setSelectedTariff] = useState<TariffsType | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [confirmedUnsubscribeModal, setConfirmedUnsubscribeModal] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [type, setType] = React.useState(-1);
  const [fbs, setFbs] = React.useState(false);
  const [fbo, setFbo] = React.useState(false);

  const [toggleModal, setToggleModal] = React.useState(false)

  const monthList = [

  ];

  const token = document.cookie.split('token="')
  const filterToken = token[1]?.split('\"')
  const logResult = useCallback(() => {
    return window.location.pathname
  }, [window.location.pathname])

  useEffect(() => {
    if(!filterToken && window.location.pathname !== '/login') {
      window.location.replace('/login')
    }

  }, [logResult])

  const organs = useQuery<Array<any>>('organs', async () => {
    const result = await getOrganizations();

    setNameOrg(result?.data?.data?.[0]?.org_name || '');
    document.cookie = `org_uid=${result?.data?.data?.[0]?.org_uid};Path=/;`;
    return result.data.data;
  });

  const currentTariff = useQuery<TariffsType>('currentTariff', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await findTariff(uid);

    return result.data.data;
  }, {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    retryOnMount: true
  });

  const storageType = useQuery('storageType', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await updateOrgStorageType({ org: uid, action: 'show' });

    setType(result.data.data[0].type);
    setFbo(result.data.data[0].type === 0 || result.data.data[0].type === 3);
    setFbs(result.data.data[0].type === 1 || result.data.data[0].type === 3);

    return result.data.data;
  });


  async function getTariff() {
    const res = await getAllActiveTariffs();
    setSelectedTariff(res?.data.data.filter((item: any) => item.uid === 'ff4d3434-ebcc-4320-add7-7c00da2c7e16')[0]);
  }

  useEffect(() => {
    if(loc) {
      getTariff()
      setTimeout(() => setOpen(loc.state.modal), 1000)

    }
  }, [loc])

  useQuery(['setStorageType', type], async () => {
    if (type !== -1) {
      if (!storageType.isLoading) {
        const uid = await cookie.parse(document.cookie)['org_uid'];
        const result = await updateOrgStorageType({
          org: uid,
          action: 'update',
          type: type,
        });

        return result.data.data;
      }
    }
  });

  const getPhone = async () => {
    const p: string = await cookie.parse(document.cookie)['n'];
    setPhone(p);
  };
  useEffect(() => {
    getPhone();
  });

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopopver = Boolean(anchorEl);

  const tariffs = useQuery<Array<any>>('tariffs', async () => {
    const result = await getAllActiveTariffs();

    return result.data.data;
  }).data;

  const handleClose = () => {
    setOpen(false);
  };

  const selectMonth = (month: number) => {
    setSelectedMonth(month);
  };

  const selectTariff = () => {
    setSelectedTariff(tariffs?.filter((item: any) => item.uid === 'ff4d3434-ebcc-4320-add7-7c00da2c7e16')[0]);
    setOpen(true);
  };

  const saveOrgInfo = async () => {
    let req: any = {};
    if (organs && organs.data && organs.data.length) {
      req.uid = organs?.data[0].org_uid;
    }
    req.name = nameOrg;
    req.del = false;
    const result = await setOrganizations(req);
    if (!result.data.success) {
      setSnackbarMessage(result.data.description[0].reason);
      setShowSnackbar(true);
    }
    organs.refetch();
    setOpenEditModal(false);
  };

  const unsubscribe = async () => {
    setToggleModal(true)
    if (currentTariff?.data) {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const result = await updateByPurchaseOrg(currentTariff?.data?.plans_organizations_payments_uid, {
        org: uid,
        planIsConfirmed: false,
      });
      if (!result.data.success) {
        setSnackbarMessage(result.data.description[0].reason);
        setShowSnackbar(true);
        return;
      }
      currentTariff.refetch();
      setConfirmedUnsubscribeModal(false);
    }
  };

  const setFbsFbo = (fbs: boolean, fbo: boolean) => {
    if (fbo) setType(0);
    if (fbs) setType(1);
    if (fbs && fbo) setType(3);
    if (fbs || fbo) setFbs(fbs);
    if (fbs || fbo) setFbo(fbo);
  };
  useEffect(()=>{
    const isDowngradeState = localStorage.getItem('isDowngradeStatus');
    if(isDowngradeState === "true"){
      setUpgradeModalIsOpen(true)
      // localStorage.setItem('isDowngradeStatus', "false");
    }
  })

  function closeModalUpgradeHandler(){
    setToggleModal(false)
    localStorage.setItem('isDowngradeStatus', "false")
    setUpgradeModalIsOpen(false)
  }

  const dataStore = useNewWb()


  return (
    <>
      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />
      <CustomModal
        title="Вы точно хотите отменить подписку?"
        show={confirmedUnsubscribeModal}
        closeEvent={() => setConfirmedUnsubscribeModal(false)}
        successEvent={() => unsubscribe()}
        successText="Да"
        cancelText="Нет"
      >
        <br />
      </CustomModal>
      <CustomModal
          title="Тариф Базовый закончился"
          show={upgradeModalIsOpen || toggleModal}
          closeEvent={() => {closeModalUpgradeHandler()}}
          successEvent={() => {}}
          successText="Сохранить"
          cancelText="Отмена"
          showSuccessBtn={false}
          showCancelBtn={false}
      >
        <div className={classes.successPayImage}>
          <img src={launch_Flatline} alt="" />
        </div>

        <p className={classes.successPayText}>
          Для продления тарифа нажмите кнопку
        </p>
        <div className={classes.actionBlock}>
          <Button className={classes.modalUpgradeBtn} onClick={()=>{
            closeModalUpgradeHandler();
            selectTariff()
            }}>Улучшить</Button>
        </div>
      </CustomModal>
      <CustomModal
        title="Данные об организации"
        show={openEditModal}
        closeEvent={() => setOpenEditModal(false)}
        successEvent={() => saveOrgInfo()}
        successText="Сохранить"
        disabledSuccessBtn={nameOrg.length <= 0}
        cancelText="Отмена"
      >
        <div className="modalContent">
          <div className={classes.fieldBlock}>
            <label htmlFor="name" className={classes.fieldLabel}>Название <span>*</span></label>
            <div className={classes.iconField}>
              <input
                id="name"
                className={classes.field}
                value={nameOrg}
                onChange={(e) => setNameOrg(e.target.value)}
              />
              <Check className={classNames(classes.checkIcon, nameOrg.length > 0 ? classes.checkIconActive : '')} />
            </div>
          </div>
        </div>
      </CustomModal>
      <div>
        <div className={classes.profileWindow}>
          <div className={classes.profileItem}>
            <div className={classes.profileContent}>
              <img alt="fdf" src={flag}></img>
              <div className={classes.profileContentInfo}>
                <span className={classes.profileContentTitle}>
                  Организация
                </span>
                {organs.data ? <span>{organs.data[0].org_name}</span> : <span style={{color: '#827F75'}}>Не указано</span>}
              </div>
            </div>
            <input
              type={"button"}
              className={classes.profileBtn}
              value="Изменить"
              onClick={() => setOpenEditModal(true)}
            />
          </div>
          <div className={classes.profileItem}>
            <div className={classes.profileContent}>
              <Card />
              <div className={classes.profileContentInfo}>
                <span className={classes.profileContentTitle}>Тариф</span>
                <div className={classes.profileTariffInfo}>
                  <div className={classNames(classes.profileTariffName,
                      currentTariff?.data?.planName?.toLowerCase() === "демо" ? classes.profileTariffNameRed : ""
                  )}>{currentTariff?.data?.planName}</div>
                  <span
                    aria-owns={openPopopver ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    className={classes.questionIcon}
                  >
                    <Question />
                  </span>
                  <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    open={openPopopver}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography style={{ maxWidth: 300, width: '100%', }}>
                      {currentTariff?.data?.planDescription1Text}
                    </Typography>
                  </Popover>

                  <div className={classes.profileTariffDivider}>|</div>
                  <div className={classes.profileTariffText}>
                    до {moment(currentTariff?.data?.date_end).format('DD MMMM YYYY')}
                  </div>
                  <div className={classes.profileTariffDivider}>|</div>
                  <div className={classes.profileTariffText}>
                    <CurrencyFormatter currency={currentTariff?.data?.planCost || 0} /> ₽
                  </div>
                </div>
              </div>
            </div>
            {currentTariff?.data?.uid !== 'ff4d3434-ebcc-4320-add7-7c00da2c7e16' ? (
              <Button className={classes.profileBtn} onClick={() => selectTariff()}>Улучшить</Button>
            ) : (
              <Button className={classes.profileBtn} onClick={() => setConfirmedUnsubscribeModal(true)}>
                Отменить подписку
              </Button>
            )}
            <Dialog
              className={classes.tariffModal}
              onClose={handleClose}
              open={open}
            >
              <div className={classes.tariffModalHeader}>
                <h2 className={classes.tariffModalTitle}>Улучшение тарифа</h2>
                <button className={classes.tariffModalClose} onClick={handleClose}>
                  <CloseIcon />
                </button>
                <p className={classes.tariffModalInfoText}>Действующий тариф имеет ограничения, разблокируйте функции, чтобы работать лучше </p>
              </div>

              <div className={classes.tariffModalContent}>
                <div className={classes.tariffModalInfoBlock}>
                  <p className={classes.tariffModalText}>Тариф</p>
                  <h2 className={classes.tariffModalName}>{selectedTariff?.planName}</h2>
                  <ul className={classes.tariffModalList}>
                    <li className={classes.tariffModalListItem}>
                      <Bell width="24" height="24" />
                      <div className={classes.tariffModalListContent}>
                        <p className={classes.tariffModalListItemTitle}>{selectedTariff?.planDescription1Title}</p>
                        <p className={classes.tariffModalListItemText}>{selectedTariff?.planDescription1Text}</p>
                      </div>
                    </li>
                    <li className={classes.tariffModalListItem}>
                      <Activity width="24" height="24" />
                      <div className={classes.tariffModalListContent}>
                        <p className={classes.tariffModalListItemTitle}>{selectedTariff?.planDescription2Title}</p>
                        <p className={classes.tariffModalListItemText}>{selectedTariff?.planDescription2Text}</p>
                      </div>
                    </li>
                    <li className={classes.tariffModalListItem}>
                      <Chart width="24" height="24" />
                      <div className={classes.tariffModalListContent}>
                        <p className={classes.tariffModalListItemTitle}>{selectedTariff?.planDescription3Title}</p>
                        <p className={classes.tariffModalListItemText}>{selectedTariff?.planDescription3Text}</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={classes.tariffModalPriceBlock}>
                  <p className={classes.tariffModalText}>Количество месяцев</p>
                  <ButtonGroup
                    className={classes.tariffModalMonthBtnGroup}
                    variant="outlined"
                    aria-label="outlined button group"
                  >
                    <Button
                      onClick={() => selectMonth(1)}
                      className={
                        classNames(
                          classes.tariffModalMonthBtn,
                          classes.borderTopLeftRadius8,
                          classes.borderBottomLeftRadius8,
                          selectedMonth === 1 ? 'active' : ''
                        )
                      }
                    >
                      1
                    </Button>
                    <Button
                      onClick={() => selectMonth(3)}
                      className={
                        classNames(
                          classes.tariffModalMonthBtn,
                          selectedMonth === 3 ? 'active' : ''
                        )
                      }
                    >
                      3
                    </Button>
                    <Button
                      onClick={() => selectMonth(6)}
                      className={
                        classNames(
                          classes.tariffModalMonthBtn,
                          selectedMonth === 6 ? 'active' : ''
                        )
                      }
                    >
                      6
                    </Button>
                    <Button
                      onClick={() => selectMonth(9)}
                      className={
                        classNames(
                          classes.tariffModalMonthBtn,
                          selectedMonth === 9 ? 'active' : ''
                        )
                      }
                    >
                      9
                    </Button>
                    <Button
                      onClick={() => selectMonth(12)}
                      className={
                        classNames(
                          classes.tariffModalMonthBtn,
                          classes.borderTopRightRadius8,
                          classes.borderBottomRightRadius8,
                          selectedMonth === 12 ? 'active' : ''
                        )
                      }
                    >
                      12
                    </Button>
                  </ButtonGroup>
                  {/* <p className={classes.tariffModalMonths}>
                    Цена за {selectedMonth} месяцев
                  </p> */}
                  <p className={classes.tariffModalText}>
                    Продлить до {moment().add((selectedTariff?.planPeriodDays || 0) * selectedMonth, 'days').format('DD.MM.YYYY')}
                  </p>
                  <h2 className={classes.tariffModalPrice}>
                    <CurrencyFormatter currency={(selectedTariff?.planCost || 0) * selectedMonth} /> ₽
                  </h2>
                  <NavLink to={`/profile/tariff-pay?id=${selectedTariff?.uid}&name=${selectedTariff?.planName}&month=${selectedMonth}&start=${moment().format('YYYY-MM-DD HH:mm:ss')}&end=${moment().add((selectedTariff?.planPeriodDays || 0) * selectedMonth, 'days').format('YYYY-MM-DD HH:mm:ss')}&end_date_text=${moment().add((selectedTariff?.planPeriodDays || 0) * selectedMonth, 'days').format('DD.MM.YYYY')}&cost=${(selectedTariff?.planCost || 0) * selectedMonth}&count=${selectedMonth}`} className={classes.tariffModalPayBtn}>
                    Оплатить
                  </NavLink>
                  <p className={classes.tariffModalText}>
                    После оплаты вы получите доступ к функционалу сервиса, в рамках выбранного тарифа
                  </p>
                </div>
              </div>
            </Dialog>
          </div>
          <div className={classes.profileItem}>
            <div className={classes.profileContent}>
              <img alt="fdf" src={user_profile}/>
              <div className={classes.profileContentInfo}>
                <span className={classes.profileContentTitle}>Пользователи</span>
                <span>{phone}</span>
              </div>
            </div>
            <input type="image" alt="arrow" src={rightArrow} onClick={() => navigate('/profile/users')} />
          </div>
          <div className={classes.profileItem}>
            <div className={classes.profileContent}>
              <img alt="fdf" src={circleStock}/>
              <div className={classes.profileContentInfo}>
                <span className={classes.profileContentTitle}>Склады</span>
                <span></span>
              </div>
            </div>
            <input type="image" alt="arrow" src={rightArrow} onClick={() => navigate('/profile/stocks')} />
          </div>
          <div className={classes.profileItem}>
            <div className={classes.profileContent}>
              <img alt="fdf" src={StockCircleIcon}/>
              <div className={classes.profileContentInfo}>
                <span className={classes.profileContentTitle}>Системы торговли</span>
                <span>
                  <FormControlLabel
                    className={classes.stockCheckbox}
                    control={
                      <Checkbox defaultChecked color="default" checked={fbo} />
                    }
                    label="FBO (склад маркетплейса)"
                    checked={fbo}
                    onChange={(e: any) => setFbsFbo(fbs, e.target.checked)}
                  />
                  {!dataStore.toggleNew &&
                  <FormControlLabel
                    className={classes.stockCheckbox}
                    control={
                      <Checkbox defaultChecked color="default" checked={fbs} />
                    }
                    label="FBS (склад продавца)"
                    checked={fbs}
                    onChange={(e: any) => setFbsFbo(e.target.checked, fbo)}
                  />
                  }
                </span>
              </div>
            </div>
            {/* <input type="image" alt="arrow" src={rightArrow} onClick={() => console.log('stocks-type')} /> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile;
