import { makeStyles, createStyles } from '@material-ui/core';
import React from 'react'
import { LoadingIcon } from './Icons';

interface Props {
  open?: boolean | true;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'fixed',
      top: 0, left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.8)',
      zIndex: 1302,
      '& svg': {
        animation: '1.8s linear 0s infinite spin',
      }
    },
  }),
);

const Spinner = (props: Props) => {
  const classes = useStyles();

  return (
    <>
      {props.open && (<div className={classes.root}>
        <LoadingIcon color="#000" />
      </div>)}
    </>
  )
}

export default Spinner