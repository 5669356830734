import { makeStyles, createStyles, ButtonGroup, Button } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react'
const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      '.makeStyles-modal-134 .MuiDialog-paperWidthSm': {
        maxWidth: '786px !important',
      },
    },
    content: {},
    params: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 32,
      paddingBottom: 0,
    },
    paramsBtns: {},
    paramsBtn: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: '#37352F',
      textTransform: 'none',
      background: '#FFFFFF',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
    },
    paramsFirstBtn: {
      boxSizing: 'border-box',
      borderRadius: '8px 0px 0px 8px',
    },
    paramsLastBtn: {
      borderRadius: '0px 8px 8px 0px',
    },
    paramsForCalculationBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      paddingBottom: 2,
      borderBottom: '1px solid #37352F',
      cursor: 'pointer',
    },
    paramsModal: {
      marginTop: 32,
    },
    paramsModalText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      maxWidth: 600,
      marginBottom: 32,
    },
    paramsModalContent: {},
    paramsModalLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    paramsModalBtns: {
      marginBottom: 32,
    },
    paramsModalBtn: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: '#37352F',
      textTransform: 'none',
      background: '#FFFFFF',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',

      '&:last-child': {
        borderRadius: '0px 8px 8px 0px',
      },
    },
    paramsModalFirstBtn: {
      boxSizing: 'border-box',
      borderRadius: '8px 0px 0px 8px',
    },
    paramsModalLastBtn: {
      borderRadius: '0px 8px 8px 0px',
    },
    active: {
      background: '#E9E9E7',
      border: '1px solid #37352F',
    },
  })
);


interface Props {
  setParams?: (val: string) => void;
};

const Params = (props: Props) => {
  const classes = useStyles();

  const [param, setParam] = React.useState<string>('buyer_order_date');

  const handleSetParam = (val: string) => {
    setParam(val);
    if (props.setParams) props.setParams(val);
  };

  return (
    <div className={classes.params}>
      <ButtonGroup className={classes.paramsBtns} variant="outlined">
        <Button
          className={
            classNames(
              classes.paramsFirstBtn,
              classes.paramsBtn,
              param === 'buyer_order_date' ? classes.active : ''
            )
          }
          onClick={() => handleSetParam('buyer_order_date')}
        >
          Заказ
        </Button>
        <Button
          className={
            classNames(
              classes.paramsLastBtn,
              classes.paramsBtn,
              param === 'sale_date' ? classes.active : ''
            )
          }
          onClick={() => handleSetParam('sale_date')}
        >
          Продажа
        </Button>
      </ButtonGroup>

      <span className={classes.paramsForCalculationBtn}></span>
    </div>
  )
};

export default Params