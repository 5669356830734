import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
}

const Plus = (props: Props) => {
  return (
    <svg width={props.width || '24px'} height={props.height || '24px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5V19" strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5 12H19" strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Plus;
