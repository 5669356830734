import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// const baseURL = 'http://85.175.216.246:8087';

interface WBDataRequest {
  name: string;
  content: string;
}
interface UploadExcelWbDataReq {
  typeUid: string | null | undefined;
  orgUid: string;
  swap: Array<{ to: string, from: string }>;
  content: string | undefined;
  nameFile: string;
  dateCreation: string;
  dateUpdate: string;
  size: number;
  list?: {
    name_storage?: string | null;
    name_shares?: string | null;
    data_end?: string | null;
    fbo_commission_reduction?: string | null;
    fbs_commission_reduction?: string | null;
    fine_fbo?: string | null;
    fine_fbs?: string | null;
    logistics_penalty?: string | null;
    reduced_logistics?: string | null;
    fbs?: string;
    rest_wb_fbo?: boolean | null;
  }
}
interface PrimaryCheckReq {
  typeUid: string;
  orgUid: string;
  content: string;
  nameFile: string;
  dateCreation: string;
  dateUpdate: string;
  size: number;
}
interface SwapType {
  to: string;
  from: string;
  default: boolean;
}
export interface SaleWeekDetailing {
  typeUid: string;
  orgUid: string;
  swap: Array<SwapType>,
  content: string;
}


export interface ThresholdReport {
  org: string;
  report_type: string,
}


export const loadNewExcel = async (data: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/table_takeout`, data);
};

export const loadDataPromotion = async (data: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/list_promotion_count`, data);
};

export const wbDataSaveExcel = async (req: WBDataRequest) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/save`, req);
};

export const wbDataReadExcel = async (page: number, perPage: number, req: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/read?page=${page}&perPage=${perPage}`, req);
};

export const wbExcelPrimaryCheck = async (req: PrimaryCheckReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/check`, req);
};

const excelPaths: any = {
  '0a5956f9-a9f6-49c0-8080-bb1fda1251a7':	'/excel/primary/all_goods',
  '7e8d9639-2419-47cd-aca1-0c8829528622':	'/excel/primary/detailing',
  'ceee8d45-32fb-4710-aa62-d26d2a75bb87':	'/excel/primary/dimensions_volume',
  '147bda8c-d997-4050-b497-d7a0aed59265':	'/excel/primary/free_stock_balances',
  '974b957b-6df3-4811-9eaf-54c12898227f':	'/excel/primary/fbs',
  '005fea2d-b561-4c81-a1e6-85554bfbd7ba':	'/excel/primary/purchase_goods',
  '94e066eb-3b01-4916-993c-73f06978145b':	'/excel/primary/current_sales_week',
  '960d6482-dc35-4834-871a-1ee8a26f1d02':	'/excel/primary/current_stock',
  '5fe9a4b9-423d-4bad-bbc9-e15660568081': '/excel/primary/loadBCP',
};


export const uploadExcelWbData = async (req: UploadExcelWbDataReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}${excelPaths[req.typeUid || '']}`, req);
};

export const uploadExcelWbLeftovers = async (req: UploadExcelWbDataReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/total_remains`, req);
};

export const wbExcelPrimaryFbs = async (req: UploadExcelWbDataReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/fbs`, req);
};
export const excelPrimarySaleWeekDetailing = async (req: SaleWeekDetailing) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/sale_week/detailing`, req);
};

export const excelThresholdReport = async (req: ThresholdReport) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/limits`, req);
};
