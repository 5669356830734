import { create } from "zustand";
import { persist } from 'zustand/middleware'


export const useUsers = create(persist((set) => ({
    dataUsers: null,
    getDataUsers: (item) => set((state) => (
      {
        dataUsers: item
      }
    ))
  }),
    {
      name: 'storageUsers',
      getStorage: () => localStorage,
    }
  ))

export const useNewWb = create(persist((set) => ({
    toggleNew: null,
    getToggleNew: (toggle) => set((state) => (
      {
        toggleNew: toggle
      }
    ))
  }),
    {
      name: 'newWb',
      getStorage: () => localStorage,
    }
))

export const useRoles = create(persist((set) => ({
  org: null,
  userOrg: null,
  getOrg: (item) => set((state) => (
    {
      org: item
    }
  )),
  getUserOrg: (item) => set((state) => (
    {
      userOrg: item
    }
  )),
  logoutRole: () => set(() => (
    {
      org: null,
      userOrg: null
    }
  ))
}),
  {
    name: 'storageRoles',
    getStorage: () => localStorage,
  }
))


export const useRows = create(persist((set) => ({
    rows: [
        { where: '', equal: '', equalList: [] }
      ],
    getDataRows: (item) => set((state) => (
      {
        rows: item
      }
    ))
  }),
    {
      name: 'storageRows',
      getStorage: () => localStorage,
    }
  ))