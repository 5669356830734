import React, { useRef } from 'react'
import classNames from 'classnames';
import { useQuery } from 'react-query';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles, Menu, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import cookie from 'cookie';
import moment from 'moment';

import Main from '../../components/layout/Main'
import { Bell, MoreVerticalIcon, Search, Upload } from '../../components/UI/Icons'
import { wbDataReadExcel, wbDataSaveExcel } from '../../api/wb-data';
import Spinner from '../../components/UI/Spinner';
import { ArrowForwardIos } from '@material-ui/icons';
import CustomSnackbar from '../../components/UI/CustomSnackbar';
import { clearFilter, getRegions, setColum, setColumLocation } from '../../api/reports';
import SettingsMenu from '../../components/UI/SettingsMenu';

const useStyles = makeStyles(() => 
  createStyles({
    content: {
      padding: 32,
      boxSizing: 'border-box',
      paddingBottom: 0,
      // height: 'calc(100vh - 63px)',
      // overflowY: 'auto',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    toolbarCount: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginRight: 10
    },
    toolbarBtns: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    toolbarSearchBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      // textDecoration: 'underline',
      cursor: 'pointer',
      marginRight: 16,

      '& svg': {
        stroke: '#37352F',
      },
      '&:hover svg': {
        stroke: '#827F75',
      },
      '&:hover': {
        color: '#827F75',
      },
    },
    toolbarSearchBtnText: {
      marginLeft: 4,
      borderBottom: '1px solid #000',
      paddingBottom: 1,

      '&:hover': {
        borderBottom: '1px solid #827F75',
      }
    },
    toolbarSearch: {
      border: 'none',
      outline: 'none',
      borderBottom: '1px solid #37352F',
      marginLeft: 8,
      marginBottom: 2,
      width: 40,
      transition: 'all .3s ease-out',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:focus': {
        width: 222,
        cursor: 'auto',
      },
      '&::placeholder': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#37352F',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        // textDecoration: 'underline',
        cursor: 'pointer',
        transition: 'all .2s ease-out',
      },
      '&.focus': {
        '&::placeholder': {
          fontFamily: '"Work Sans", sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px',
          color: '#827F75',
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none',
          // textDecoration: 'underline',
        },
      },
    },
    toolbarFilter: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 16,
    },
    toolbarFilterBtn: {
      position: 'relative',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '14px',
      color: '#37352F',
      textTransform: 'capitalize',
      borderBottom: '1px solid #000',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:hover': {
        borderBottom: '1px solid #827F75',
        color: '#827F75',
      },
    },
    toolbarAddBtn: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'capitalize',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      padding: '8px 16px 8px 10px',
      cursor: 'pointer',

      '& svg': {
        stroke: '#ffffff',
      },

      '&:hover': {
        background: '#827F75',
      },
    },
    toolbarIconBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 40,
      marginRight: 16,
      cursor: 'pointer',
    },
    emptyData: {
      width: '100%',
      boxSizing: 'border-box',
      padding: 42,
      border: '1px solid #E9E9E7',
      borderRadius: 8,
    },
    emptyText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      textAlign: 'center',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    tableWrap: {
      overflowX: 'auto',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 198.9px)',
      margin: '32px 0',
      marginBottom: 0,

      '& .MuiPaper-root': {
        backgroundColor: 'transparent !important',
      },
    },
    tableContainer: {
      boxShadow: 'none',
      borderTop: '1px solid #E9E9E7',
      borderBottom: '1px solid #E9E9E7',
      width: 'fit-content !important'
    },
    table: {
      width: 'fit-content',
      borderCollapse: 'collapse',
      position: 'relative',
      tableLayout: 'fixed',

      '& .MuiTableRow-head': {
        background: '#fff',
      },
      '& .MuiTableCell-head': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#827F75',
        padding: '8px 16px',
        borderRight: '1px solid #E9E9E7',
        boxSizing: 'border-box',
        minWidth: '120px',
        maxWidth: '120px',
        position: 'sticky',
        top: 0,
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        padding: '8px 16px !important',
        borderRight: '1px solid #E9E9E7',
        boxSizing: 'border-box',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 16px !important',
        borderRight: 'none',
      },
    },
    tableHead: {
      position: 'sticky',
      top: 0,
      zIndex: 99,
      background: '#fff',
    },
    tableRow: {
      '&:hover': {
        background: 'rgba(242, 242, 240, 0.5) !important',
      },
    },
    tableCell: {
      '&:hover': {
        background: 'rgba(225, 225, 228, 0.5) !important'
      },
    },
    infiniteScroll: {
      // height: 'calc(100vh - 63px)',
    },
    settingsMenu: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 16,
        top: '126px !important',
        maxHeight: '70vh',
      },
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    settingsContet: {
      padding: 16,
      maxWidth: 330,
      width: '100vh',
    },
    settingsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,

      '& svg': {
        width: 20,
        height: 20,
      }
    },
    settingsTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    settingsList: {

    },
    settingsItem: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      cursor: 'grab !important',
    },
    settingsItemContent: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      padding: '6px 0',
      borderTop: '1px solid #fff',
      transition: 'all 0.1s ease-in-out',

      '& svg': {
        marginRight: 4,
        minWidth: 16,
      },
    },
    settingsItemSwitch: {
      width: 35,
      height: 18,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#000',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: '#ccc',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 14,
        height: 14,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: 'all .5s ease-in-out',
      },
    },
    tableSelect: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      borderBottom: 'none !important',

      '& .MuiSelect-selectMenu': {
        backgroundColor: 'transparent',
      },
      '&::after': {
        display: 'none !important',
      },
      '&::before': {
        display: 'none !important',
      },
    },
    settingChip: {
      position: 'absolute',
      top: -10, right: -10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '12px',
      width: 14,
      height: 14,
      color: 'white',
      background: '#FF6C4B',
      borderRadius: '50%',
    },
    settingsCancelBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 8,
      paddingBottom: 8,
    },
    settingsCancel: {
      cursor: 'pointer',
      borderBottom: '1px solid #37352F',
    },
  }),
);

function useFocus(): [any, any] {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

  return [ htmlElRef, setFocus ] 
}

const WBData = () => {
  const classes = useStyles();

  const [count, setCount] = React.useState(0);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loader, setLoader] = React.useState(false);
  const [cities, setCities] = React.useState<any>([]);
  const [page, setPage] = React.useState(-1);
  const [excel, setExcel] = React.useState<any>([]);
  const [list, setList] = React.useState<any>([]);
  const [filteredList, setFilteredList] = React.useState<any>([]);
  const [dwbtUid, setDwbtUid] = React.useState<string>('bbbd04b9-f5de-41e5-8507-502286f27fcc');
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [hoveredCol, setHoveredCol] = React.useState(-1);
  const [searchRef, setSearchFocus] = useFocus()
  const [settingsCount, setSettingsCount] = React.useState(0);
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const perPage = 150000;
  const localPerPage = 100;

  const regions = useQuery('regions', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await getRegions(uid);
    let noActual = false;
    const citiesRes = result.data.data.map((item: any, i: number) => {
      if (result.data.data[i - 1] !== item.lastUpdate)
        noActual = true;
      return {
        uid: item.uid,
        regionName: item.regionName,
        lastUpdate: item.lastUpdate,
        sort: i,
        showed: !item.colum
      }
    });

    if (!showSnackbar) {
      setSnackbarMessage('Не все данные по складам обновлены');
      setShowSnackbar(noActual);
    } else {
      setTimeout(() => {
        setSnackbarMessage('Не все данные по складам обновлены');
        setShowSnackbar(noActual);
      }, 15000)
    }

    setCities(citiesRes);

    countTheNumberSettings(citiesRes);
    return result.data.data;
  });

  const resExcel = useQuery(['resExcel', { dwbtUid, regIsFetched: regions.isFetched }], async () => {
    setLoader(true);
    const result: any = await wbDataReadExcel(0, perPage, { dwbtUid: dwbtUid });
    const copyResult = JSON.parse(JSON.stringify(result.data.data.result));

    setCount(result.data.data.total);
    setExcel(result.data);
    setList(result.data.data.result);
    setFilteredList(result.data.data.result.splice(page + 1, localPerPage));
    setPage(page + 1);
    // list.length ? setCitiesFunc(list) : setCitiesFunc(result.data.data.result)

    setLoader(false);

    return copyResult;
  });

  const onFileChange = async (e: any) => {
    setLoader(true);
    setExcel([]);
    setList([]);
    setFilteredList([]);
    let file = e.target.files[0],
      reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = async () => {
      let result: any = reader.result;
      if (result) {
        try {
          const uploaded = await wbDataSaveExcel({
            name: file.name,
            content: result.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', '')
          });

          setSnackbarMessage(uploaded.data.description[0].message);
          setShowSnackbar(true);
          regions.refetch();
          resExcel.refetch();
        } catch (error) {
          console.error(error);
        } finally {
          // setLoader(false);
          e.target.value = ''
        }
      }
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  };

  const infiniteLoadData = async (e: any) => {
    // setScrollLeft(e.currentTarget.scrollLeft);
    // if (scrollLeft !== e.currentTarget.scrollLeft) return;

    const maxPages = Math.ceil(excel.data.result.length / localPerPage);
    const bottom = Math.ceil(e.target.scrollTop - (e.target.scrollHeight - e.target.clientHeight)) >= 0
      && Math.ceil(e.target.scrollTop - (e.target.scrollHeight - e.target.clientHeight)) <= 5;
    if (bottom) {
      if (page < maxPages) {
        if (!resExcel.isLoading) {
          setFilteredList([...filteredList, ...excel.data.result.splice(page + 1, localPerPage)]);
          setPage(page + 1);
          // await resExcel.refetch();
        }
      }
    }
  }

  const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSettings(true);
  };
  const handleCloseSettings = () => {
    setShowSettings(false);
  };
  const onChangeCity = async (index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyCities = JSON.parse(JSON.stringify(cities));
    copyCities[index].showed = !copyCities[index].showed;
    setCities(copyCities);

    await setColum({
      colum: copyCities[index].regionName,
      organization_uid: uid,
      calc: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      del: copyCities[index].showed,
    });

    countTheNumberSettings(copyCities);
  };

  const countTheNumberSettings = (cities: any) => {
    let count = 0;
    cities.forEach((item: any) => {
      if (!item.showed) {
        count += 1;
      }
    });

    setSettingsCount(count);
  };

  const [currentItem, setCurrentItem] = React.useState<any>();
  const [currentItemIdx, setCurrentItemIdx] = React.useState<number>(-1);

  const onDragStartEvent = (e: any, item: any, index: number) => {
    setCurrentItem(item);
    setCurrentItemIdx(index);
  };
  const onDragOverEvent = (e: any, item: any, index: number) => {
    e.preventDefault();
    if (e.target.className.includes('settingsItemContent')) {
      e.target.style.borderTop = '10px solid rgba(255, 108, 75, 0.2)';
    }
  };
  const onDragLeaveEvent = (e: any) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDragEndEvent = (e: any, item: any, index: number) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDropEvent = async (e: any, overedItemIdx: number) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let citiesCopy = JSON.parse(JSON.stringify(cities));
    citiesCopy.splice(currentItemIdx, 1);
    citiesCopy.splice(overedItemIdx, 0, currentItem);
    setCities(citiesCopy);

    await setColumLocation({
      organization_uid: uid,
      calc: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      location: citiesCopy.map((item: any) => item.regionName)
    });
  };

  const onChangeSearch = (value: string) => {
    setPage(0);
    let copyExcelData = JSON.parse(JSON.stringify(resExcel.data));
    if (value.length >= 4) {
      copyExcelData = copyExcelData.filter((item: any, i: number) => {
        return item.subjectName
          .toLowerCase()
          .includes(value.toLowerCase());
      });
    }

    setFilteredList(copyExcelData.splice(0, localPerPage));
  };

  const handleSelectDwbtUid = async (value: string) => {
    if (!resExcel.isLoading) {
      setList([]);
      setFilteredList([]);
      setPage(0);
      setDwbtUid(value);
      setCount(0);
      setExcel([]);
      setList([]);
      setFilteredList([]);
    }
  };

  const cancelSettings = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyCities = JSON.parse(JSON.stringify(cities));
    await clearFilter({
      organization_uid: uid,
      primary_data: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      type: 1
    });
    await clearFilter({
      organization_uid: uid,
      primary_data: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      type: 2
    });
    copyCities.forEach(el => el.showed = true);

    countTheNumberSettings(copyCities);

    setCities(copyCities);
    if (resExcel.refetch) resExcel.refetch();
  };

  return (
    <>
      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />
      <div className={classes.content}>
        <Spinner open={loader} />
        <div className={classes.toolbar}>
          <p className={classes.toolbarCount}>Всего {count}</p>

          <div className={classes.toolbarBtns}>
            {cities.length
              ? <SettingsMenu
                  cols={cities}
                  calc={'2689fead-ddea-4d86-8ead-db8d7c1d9d8b'}
                  // newColumnPosition={props.newColumnPosition}
                  sendColumn={setCities}
                  refetch={resExcel.refetch}
                />
              : null
            }
            <div className={classes.toolbarSearchBtn} onClick={setSearchFocus}>
              <Search />
              <input
                type="text"
                placeholder={!openSearch ? 'Поиск' : 'Введите параметр для поиска...'}
                className={classNames(classes.toolbarSearch, openSearch ? 'focus' : '')}
                // onFocus={() => setOpenSearch(true)}
                ref={searchRef}
                onBlur={() => setOpenSearch(false)}
                onChange={(e) => onChangeSearch(e.target.value)}
              />
            </div>
            <span className={classes.toolbarIconBtn}>
              <Bell />
            </span>
            <label  className={classes.toolbarAddBtn} htmlFor="upload">
              {/* <Button> */}
              <Upload /> Загрузить
              {/* </Button> */}
            </label>
            <input
              id="upload"
              type="file"
              accept=".xlsx"
              style={{display: 'none'}}
              onChange={(e: any) => onFileChange(e)}
            />
          </div>
        </div>

        {(excel && excel.data && cities.length)
          ? (
              <div className={classes.tableWrap} onScroll={(e) => infiniteLoadData(e)}>
                <Table className={classNames(classes.table, classes.tableHead)}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '250px', minWidth: '250px' }} rowSpan={2}>
                        Товар
                      </TableCell>
                      <TableCell style={{ width: `calc(7vw * ${cities.length})`, maxWidth: '39%' }} colSpan={cities.length}>
                        {/* Процент комиссии */}
                        <Select
                          className={classes.tableSelect}
                          // IconComponent={() => (
                          //   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          //     <path d="M4 6L8 10L12 6" stroke="#37352F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          //   </svg>
                          // )}
                          value={dwbtUid}
                          onChange={(e: any) => handleSelectDwbtUid(e.target.value)}
                        >
                          <MenuItem value={'bbbd04b9-f5de-41e5-8507-502286f27fcc'}>Процент комиссии</MenuItem>
                          <MenuItem value={'90201e14-ec6a-4252-bc86-6253a7d45172'}>Стоимость логистики Моно и Микс, ₽</MenuItem>
                          <MenuItem value={'3221af3c-f9bc-4d2d-ac79-61926b76a4d1'}>Стоимость логистики Монопаллеты, ₽</MenuItem>
                          <MenuItem value={'11735346-9959-4f38-8358-8028556c377f'}>Стоимость хранения Моно и Микс, ₽/день</MenuItem>
                          <MenuItem value={'0b25f800-81c9-42ed-8257-563a595917f0'}>Стоимость хранения Монопаллеты, ₽/день</MenuItem>
                          <MenuItem value={'aba41377-468d-4449-92af-41db0a6fc49b'}>Расчет по фактическим габаритам товара</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {/* <TableCell style={{ width: '31%' }} rowSpan={2}>Организация</TableCell> */}
                      {cities && cities.length && cities.map((item: any, index: number) => (
                        item.showed && (
                          <TableCell
                            className={classes.tableCell}
                            style={{
                              // width: `calc(78vw / ${cities.length})`,
                              width: '7vw',
                              maxWidth: '39%' }}
                            key={index}
                            align="center"
                          >
                            <span>{item.regionName}</span> <br />
                            <span>
                              {item.lastUpdate
                                ? moment(item.lastUpdate).format('DD.MM.YYYY')
                                : null}
                            </span>
                          </TableCell>
                        )
                      ))}
                    </TableRow>
                  </TableHead>
                </Table>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table className={classes.table}>
                    <TableBody>
                      {filteredList.map((item: any, index: number) => (
                        <TableRow className={classes.tableRow} key={index}>
                          <TableCell style={{ width: 250, minWidth: 250, maxWidth: 250 }}>
                            {item.subjectName}
                          </TableCell>
                          {cities.length && cities.map((city: any, idx: number) => (
                            city.showed && (
                              <TableCell
                                className={classes.tableCell}
                                style={{ width: '7vw', maxWidth: '39%' }}
                                align="right"
                                key={idx}
                              >
                                {item.regionsValues[city.regionName] !== '0'
                                  ? !isNaN(Number(item.regionsValues[city.regionName]))
                                      ? Number(item.regionsValues[city.regionName])
                                      : item.regionsValues[city.regionName]
                                  : '-'}
                              </TableCell>
                            )
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )
            : <div className={classes.emptyData}>
                <p className={classes.emptyText}>Список пуст</p>
              </div>}
        {/* {!resExcel.isLoading && !excel && !excel.data && !cities.length (
          <div className={classes.emptyData}>
            <p className={classes.emptyText}>Список пуст</p>
          </div>
        )} */}
      </div>
    </>
  )
}

export default WBData
