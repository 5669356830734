import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}
interface GetRoleUsersReq {
    organization_uid: string;
  };
interface GetRoleUsersReq1 {
    organization_uid: string;
    uid_user: string;
  };

interface SetRoleUsersReq {
    active: number;
    uid_organization: string;
    uid_roles: string;
    uid_user: string;
};

interface SetNewPar {
    organization_uid: string,
    parameter_new: number
}

interface GetNewPar {
    organization_uid: string,
}

export const adminRoleOrgs = async (req: object) => {
    const urlAdres = await url()
    return await API.post(`${urlAdres.data.notification}/organizations-2/getOrgOrgAdmin`, req);
};

export const adminRoleUsers = async (req: GetRoleUsersReq) => {
    const urlAdres = await url()
    return await API.post(`${urlAdres.data.notification}/organizations-2/getOrgUserAdmin`, req);
};

export const adminGetRoles = async (req: object) => {
    const urlAdres = await url()
    return await API.post(`${urlAdres.data.notification}/organizations-2/getRoles`, req);
};

export const adminGetRolesActive = async (req: GetRoleUsersReq1) => {
    const urlAdres = await url()
    return await API.post(`${urlAdres.data.notification}/organizations-2/getActiveRoles`, req);
};

export const adminSetRoles = async (req: SetRoleUsersReq) => {
    const urlAdres = await url()
    return await API.post(`${urlAdres.data.notification}/organizations-2/setRoles`, req);
};

//Это новый профиль wb

export const getNewParam = async (req: GetNewPar) => {
    const urlAdres = await url()
    return await API.post(`${urlAdres.data.notification}/organizations-2/getParameterNew`, req);
};

export const setNewParam = async (req: SetNewPar, ) => {
    const urlAdres = await url()
    return await API.post(`${urlAdres.data.notification}/organizations-2/setParameterNew`, req);
};