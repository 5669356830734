import React, {useCallback, useEffect} from 'react';
import cookie from 'cookie';
import { MoreVerticalIcon } from './Icons';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles, Menu, Switch } from '@material-ui/core';
import { clearFilter, getReport, setColum } from '../../api/reports';
import HttpsIcon from '@material-ui/icons/Https';
import { getCalc } from '../../api/calc';
import routes from "./Breadcrumbs/routesList";
import {useIsMutating} from "react-query";



const useStyles = makeStyles(() =>
  createStyles({
    toolbarFilter: {
      display: 'flex',
      position: "relative",
      alignItems: 'center',
      marginRight: 16,
      top: 0
    },
    toolbarFilterBtn: {
      position: 'relative',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '14px',
      color: '#37352F',
      borderBottom: '1px solid #000',
      paddingBottom: 1,
      cursor: 'pointer',
      whiteSpace: 'nowrap',

      '&:hover': {
        borderBottom: '1px solid #827F75',
        color: '#827F75',
      },
    },
    settingChip: {
      position: 'absolute',
      top: -10, right: -10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '12px',
      width: 14,
      height: 14,
      color: 'white',
      background: '#FF6C4B',
      borderRadius: '50%',
    },
    settingsMenu: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 8,
        // top: '126px !important',
        // top: '180px !important',
        position: 'absolute',
        // left: '1220px !important',
        // transform: 'translateY(60px) !important',
        //60px
        maxHeight: '70vh',
      },
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    settingsContet: {
      padding: 16,
      paddingTop: 0,
      maxWidth: 330,
      width: '100vh',
    },
    settingsHeaderWrap: {
      position: 'sticky',
      paddingTop: 16,
      top: 0, left: 0,
      background: '#fff',
      zIndex: 2,
    },
    settingsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,

      '& svg': {
        width: 20,
        height: 20,
      }
    },
    settingsTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    settingsList: {

    },
    settingsItem: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      cursor: 'grab !important',
    },
    settingsItemContent: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      padding: '6px 0',
      borderTop: '1px solid #fff',
      transition: 'all 0.1s ease-in-out',
      position: "relative",
      '& svg': {
        marginRight: 4,
        minWidth: 16,
      },
    },
    settingsItemSwitch: {
      width: 35,
      height: 18,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#000',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: '#ccc',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 14,
        height: 14,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: 'all .5s ease-in-out',
      },
    },
    settingsReportContent: {
      padding: 8,
    },
    settingsReportItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: 10,
      cursor: 'pointer',
    },
    settingsCancelBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 8,
      paddingBottom: 8,
    },
    settingsCancel: {
      cursor: 'pointer',
      borderBottom: '1px solid #37352F',
    },
  })
);

interface Props {
  cols: any;
  calc: string;
  newColumnPosition?: (column: any) => void;
  sendColumn?: (column: any) => void;
  refetch?: (column?: any) => void;
};

const SettingsMenu = (props: Props) => {
  const classes = useStyles();
  const [loaded, setLoaded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showSettings, setShowSettings] = React.useState(false);
  const [settingsCount, setSettingsCount] = React.useState(0);
  const [cols, setCols] = React.useState<any>([]);
  const [space, setSpace] = React.useState(0)
  const currentDop = localStorage.getItem('weeksDop')

  React.useEffect(() => {
    if (props.cols.length) {
      setLoaded(true);

      if(currentDop === 'true') {
        const filter = props?.cols.filter(el => el.column_name !== 'ordered_1')
        setCols(filter);
        let count = 0;

        filter.forEach((item: any) => {
        if (!item.showed) count += 1;
        });
        setSettingsCount(count);
      }

      else {
        const filter = props?.cols.map(el =>  (
          el.column_name === 'ordered_1'
            ? { ...el, showed: true }
            : el
          ))
        setCols(filter);
        let count = 0;

        props.cols.forEach((item: any) => {
          if (!item.showed) count += 1;
        });
        setSettingsCount(count);
      }
    }
  }, [currentDop]);
  let profileWb = localStorage.getItem('profileWb')
  let path = window.location.pathname
  useEffect(() => {

    if(path.includes('/manage-prices/price-drop')) {
      if(profileWb === 'true' || profileWb === '1') {
        setSpace(195)

        // localStorage.setItem('profileWb', String(true))
        console.log('111', profileWb)
      } else {
        setSpace(170)
        // localStorage.setItem('profileWb', String(false))
        console.log('222', profileWb)
      }
    }
    if(path.includes('/manage-prices/current-remainder')) {
      if(profileWb === 'true') {
        setSpace(160)
        // console.log('333', localStorage.getItem('profileWb'))
      } else {
        setSpace(160)
        // console.log('444', localStorage.getItem('profileWb'))
      }
    }
  }, [profileWb])



  const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSettings(true);
  };
  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const acceptSettings = () => {

    if (props.sendColumn) props.sendColumn(cols);
  };

  const cancelSettings = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
await clearFilter({
      organization_uid: uid,
      primary_data: props.calc,
      type: 1
    });
    await clearFilter({
      organization_uid: uid,
      primary_data: props.calc,
      type: 2
    })
    .then(async(res) => {
      if(props.calc === 'b112d499-d632-42d8-970f-c6747ee54de3' || props.calc === '71a95970-2b70-4586-8813-44320ffc5fc1') {
      await getCalc({
        org: uid,
        type: props.calc
      })
      .then(result => {

        const rename = result.data.data.map(el => {
          return {...el, colum: null}
        })
        let colsRes = rename.map((item: any) => {
          const res = {
            ...item,
            showed: !item.colum,
            width: item.size ? Number(item.size) : 200 ,
          };
          return res;
        });
        colsRes = colsRes.filter((item: any) => item.column_name.indexOf('status_') === -1);
        const stock1Pos = colsRes.findIndex((item: any) => item.column_name.indexOf('stocks_1') !== -1);
        if (stock1Pos !== -1) {
          let noStocksCols = colsRes.filter((item: any) => item.column_name.indexOf('stocks_') === -1);
          let stocksCols = colsRes
            .filter((item: any) => item.column_name.indexOf('stocks_') !== -1)
            .sort((a: any, b: any) => a.column_caption > b.column_caption ? 1 : -1);
          colsRes =  noStocksCols;
          colsRes.splice(stock1Pos, 0, ...stocksCols)
        }

        setCols(colsRes)

        let count = 0;
        colsRes.forEach((item: any) => {
          if (!item.showed) {
            count += 1;
          }
        });
        setSettingsCount(count);

      })
      }
      else {
        await getReport({
          id_org: uid,
          primary_data: props.calc
        })
        .then(result => {
          const rename = result.data.column.map(el => {
            return {...el, colum: null}
          })
          let colsRes = rename.map((item: any) => {
            const res = {
              ...item,
              showed: !item.colum,
              width: item.size ? Number(item.size) : 200 ,
            };

            if (item.column_name.indexOf('stocks_') > -1) {
              const name = `status_${item.column_name.split('stocks_').pop()}`;
              const obj = result.data.data.filter(el => el.column_name === name)[0];
              res.column_status = obj.column_name;
            }
            if (item.column_name.indexOf('stocks_') !== -1
              || item.column_name.indexOf('current_price_discount') !== -1) {
                item.column_name_1 = item.column_name;
            }

            return res;
          });
          colsRes = colsRes.filter((item: any) => item.column_name.indexOf('status_') === -1);
          const stock1Pos = colsRes.findIndex((item: any) => item.column_name.indexOf('stocks_1') !== -1);
          if (stock1Pos !== -1) {
            let noStocksCols = colsRes.filter((item: any) => item.column_name.indexOf('stocks_') === -1);
            let stocksCols = colsRes
              .filter((item: any) => item.column_name.indexOf('stocks_') !== -1)
              .sort((a: any, b: any) => a.column_caption > b.column_caption ? 1 : -1);
            colsRes =  noStocksCols;
            colsRes.splice(stock1Pos, 0, ...stocksCols)
          }
          setCols(colsRes)
          let count = 0;
          colsRes.forEach((item: any) => {
            if (!item.showed) {
              count += 1;
            }
          });
          setSettingsCount(count);
        })
      }
    })

    // let copyCols = JSON.parse(JSON.stringify(cols));

    // copyCols.forEach((item: any) => item.showed = true);

    // setCols(copyCols);

    // let count = 0;
    // copyCols.forEach((item: any) => {
    //   if (!item.showed) {
    //     count += 1;
    //   }
    // });
    // console.log(copyCols)
    // setSettingsCount(count);

    if (props.refetch) props.refetch();
  };

  const [currentItem, setCurrentItem] = React.useState<any>();
  const [currentItemIdx, setCurrentItemIdx] = React.useState<number>(-1);

  const onDragStartEvent = (e: any, item: any, index: number) => {
    setCurrentItem(item);
    setCurrentItemIdx(index);
  };
  const onDragOverEvent = (e: any, item: any, index: number) => {
    e.preventDefault();
    if (e.target.className.includes('settingsItemContent')) {
      e.target.style.borderTop = '10px solid rgba(255, 108, 75, 0.2)';
    }
  };
  const onDragLeaveEvent = (e: any) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDragEndEvent = (e: any) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDropEvent = (e: any, overedItemIdx: number) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
    let colsCopy = JSON.parse(JSON.stringify(cols));
    colsCopy.splice(currentItemIdx, 1);
    colsCopy.splice(overedItemIdx, 0, currentItem);
    setCols(colsCopy);
    if (props.newColumnPosition) props.newColumnPosition(colsCopy);
  };
  const onChangeCity = async (index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyCols = JSON.parse(JSON.stringify(cols));
    let curEl: any = null;

    copyCols[index].showed = !copyCols[index].showed;
    curEl = copyCols[index];

    let count = 0;
    copyCols.forEach((item: any) => {
      if (!item.showed) {
        count += 1;
      }
    });
    setCols(copyCols);
    setSettingsCount(count);

    if (curEl) {
      await setColum({
        colum: curEl.column_name || curEl.regionName,
        organization_uid: uid,
        calc: props.calc,
        del: curEl.showed,
      });
    }
  };

  const styleIcon = {
    fontSize: 14,
    width: 14,
    height: 14,
    color: '#fff',
    borderRadius: 10,
  }

  const styleIcon1 = {
    fontSize: 14,
    width: 14,
    height: 14,
    color: '#fff',
    background: '#fff',
    borderRadius: '50%'
  }
  return (
    <div style={{position: "relative"}} className={classes.toolbarFilter}>
      <span
        className={classes.toolbarFilterBtn}
        aria-controls={showSettings ? 'settings-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={showSettings ? 'true' : undefined}
        onClick={handleOpenSettings}
      >
        Настройка столбцов
        {settingsCount ? <span className={classes.settingChip}>
          {settingsCount}
        </span> : null}
      </span>
      {(cols && cols.length) && (
        <Menu
          id="settings-menu"
          style={{top: `${space}px`}}
          className={classes.settingsMenu}
          anchorEl={anchorEl}
          open={showSettings}
          onClose={handleCloseSettings}
        >
          <div className={classes.settingsContet}>
            <div className={classes.settingsHeaderWrap}>
              <div className={classes.settingsHeader}>
                <h2 className={classes.settingsTitle}>Настройки</h2>
                <span onClick={handleCloseSettings}>
                  <CloseIcon />
                </span>
              </div>
              <div className={classes.settingsCancelBlock}>
                <span
                  className={classes.settingsCancel}
                  onClick={() => acceptSettings()}
                >
                  Применить
                </span>
                &nbsp;
                <span
                  className={classes.settingsCancel}
                  onClick={() => cancelSettings()}
                >
                  Сбросить
                </span>
              </div>
            </div>
            <div className={classes.settingsList}>
              {cols?.map((item: any, index: number) => (
                item.column_name !== 'sale_for_hide' &&
                item.column_name !== 'week_year'
                // item.column_name !== 'min_price' &&
                // item.column_name !== 'remaining_fbs_fbo' &&
                // item.column_name !== 'min_stock' &&
                // item.column_name !== 'ordered_1_4' &&
                // item.column_name !== 'ordered_1_8' &&
                // item.column_name !== 'ordered_1_11' &&
                // item.column_name !== 'ordered_1_2' &&
                // item.column_name !== 'ordered' &&
                // item.column_name !== 'lack_of_goods' &&
                // item.column_name !== 'stocks_staus' &&
                // item.column_name !== 'quantity_' &&
                // item.column_name !== 'rest_wb_fbo'
                ? <div
                    draggable={true}
                    onDragStart={(e) => onDragStartEvent(e, item, index)}
                    onDragOver={(e) => onDragOverEvent(e, item, index)}
                    onDragLeave={(e) => onDragLeaveEvent(e)}
                    onDragEnd={(e) => onDragEndEvent(e)}
                    onDrop={(e) => onDropEvent(e, index)}
                    className={classes.settingsItem}
                    key={index}
                  >
                    <div className={classes.settingsItemContent}>
                      <MoreVerticalIcon />
                      <div dangerouslySetInnerHTML={{__html: item.column_caption || item.regionName}} />
                    </div>
                    <Switch
                      disabled={item.column_name === 'min_price' ||
                      item.column_name === 'remaining_fbs_fbo' ||
                      item.column_name === 'min_stock' ||
                      item.column_name === 'ordered_1_4' ||
                      item.column_name === 'ordered_1_8' ||
                      item.column_name === 'ordered_1_11' ||
                      item.column_name === 'ordered_1_2' ||
                      item.column_name === 'ordered' ||
                      item.column_name === 'lack_of_goods' ||
                      item.column_name === 'stocks_staus' ||
                      item.column_name === 'quantity_' ||
                      item.column_name === 'supplier_article' ||
                      item.column_name === 'rest_wb_fbo' ? true : false}
                      className={classes.settingsItemSwitch}
                      checked={item.showed}
                      checkedIcon= {item.column_name === 'min_price' ||
                      item.column_name === 'remaining_fbs_fbo' ||
                      item.column_name === 'min_stock' ||
                      item.column_name === 'ordered_1_4' ||
                      item.column_name === 'ordered_1_8' ||
                      item.column_name === 'ordered_1_11' ||
                      item.column_name === 'ordered_1_2' ||
                      item.column_name === 'ordered' ||
                      item.column_name === 'lack_of_goods' ||
                      item.column_name === 'stocks_staus' ||
                      item.column_name === 'quantity_' ||
                      item.column_name === 'supplier_article' ||
                      item.column_name === 'rest_wb_fbo' ?<HttpsIcon style={styleIcon}/> : <div style={styleIcon1}></div>}
                      onChange={() => onChangeCity(index)}
                    />
                  </div>
                : null
              ))}
            </div>
          </div>
        </Menu>
      )}
    </div>
  )
};

export default SettingsMenu;
