import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// const baseURL = 'http://85.175.216.246:8084';

interface DocCheckReq {
  type: string;
  name: string;
  file: any;
}

export const sendDocuments = async (req: DocCheckReq) => {
  const urlAdres = await url()
  return await API.put(`${urlAdres.data.registration}/registration/set_admin_file_registration`, req);
};
