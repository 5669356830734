import React from 'react'
import cookie from 'cookie'
import { makeStyles, createStyles, Popover, Typography } from '@material-ui/core';
import moment from 'moment';
import { useQuery } from 'react-query';
import { primaryDataSearchFBS } from '../../../api/primary_data_search';

const useStyles: any = makeStyles(() =>  
  createStyles({
    popover: {
      pointerEvents: 'none',

      '& .MuiPopover-paper': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#FFFFFF',
        borderRadius: 8,
        background: '#37352F',
        boxShadow: '-2px 3.5px 13px -3px rgba(113,114,171,0.3)',
        padding: 16,
      },
    },
    cardLink: {
      position: 'relative',
      display: 'inline-block',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    cardLinkLine: {
      position: 'absolute',
      display: 'inline-block',
      bottom: -1,
      left: 0,
      width: '100%',
      height: 1,
      background: '#000',
    },
    needUpdate: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FF6C4B',
      display: 'inline-flex',
      alignItems: 'center',
    },
    details: {
      fontSize: 14,
    },
  })
);

interface Props {
  fbsNoActual: (val: any) => void;
  update: number;
  actual: any;
};

const MorePopoverFBS = (props: Props) => {
  const classes = useStyles();

  React.useEffect(() => {
    dataSearchFBS.refetch();
  }, [props.update]);

  const [isActual, setIsActual] = React.useState(false);


  const dataSearchFBS = useQuery('dataSearchFBS', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await primaryDataSearchFBS(uid);
    const check = result.data.data.some((item) => !item.date_upload);

    props.fbsNoActual(check);

    return result?.data?.data;
  });

  const [anchorFbsEl, setAnchorFbsEl] = React.useState<HTMLElement | null>(null);


  const handlePopoverFbsOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (dataSearchFBS.data.length) {
      setAnchorFbsEl(event.currentTarget);
    } else {
      setAnchorFbsEl(null);
    }
  };

  const handlePopoverFbsClose = () => {
    setAnchorFbsEl(null);
  };

  const openPopopverFbs = Boolean(anchorFbsEl);


  return (
    <>
    {props.actual ? 
      <>
        <span className={classes.needUpdate}></span>
        <span
          aria-owns={openPopopverFbs ? 'mouse-over-popover-fbs' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverFbsOpen}
          onMouseLeave={handlePopoverFbsClose}
          className={classes.cardLink}
          style={{marginLeft: 5}}
        >
          Подробнее
          <span className={classes.cardLinkLine}></span>
        </span>
        <Popover
          id="mouse-over-popover-fbs"
          className={classes.popover}
          open={openPopopverFbs}
          anchorEl={anchorFbsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverFbsClose}
          disableRestoreFocus
        >
          {dataSearchFBS.data &&
            dataSearchFBS.data.map((item: any, index: number) => (
            <Typography
              style={{
                maxWidth: 300,
                width: '100%',
                color: !item.date_upload ? '#FF6C4B' : null
              }}
              className={classes.details}
              key={index}
            >
              <span>{item.storage} ({item.date_upload ? moment(item.date_upload).format('L') : 'Необходимо обновить'})</span>
            </Typography>
          ))
          }
        </Popover>
      </> : null}
    </>
  );
};

export default MorePopoverFBS;
