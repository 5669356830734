import { makeStyles, createStyles, Popover, Typography, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { Close } from '@material-ui/icons';
import cookie from 'cookie';

import { InfoIcon } from '../../../components/UI/Icons';
import React from 'react';
import { getPrimaryDataSearchActual, getReportList } from '../../../api/history';
import { useQuery } from 'react-query';
import moment from 'moment';
import useOutsideClick from "../../../hooks/useOutsideClick";

const useStyles = makeStyles(() =>
  createStyles({
    TipsModal: {
      // display: 'none',
      position: 'fixed',
      width: 329,
      height: '100vh',
      background: '#fff',
      top: 0, right: 0,
      transform: 'translateX(100%)',
      transition: 'all .2s ease-out',
      padding: 16,
      paddingRight: 12,
      boxSizing: 'border-box',
      borderLeft: '1px solid #D3D1CB',
      zIndex: 1299,
      overflowY: 'auto',
    },
    show: {
      // display: 'block',
      transform: 'translateX(0)',
    },
    TipsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 32,
    },
    TipsTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
    },
    TipsClose: {
      fontSize: 24,
      cursor: 'pointer',
    },
    TipsContent: {},
    TipsMiniTitle: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginBottom: 8,

      '& svg': {
        marginRight: 4,
      },
    },
    TipsList: {
      padding: 0,
      listStyle: 'none',
    },
    TipsListItem: {
      maxWidth: 280,
      paddingBottom: 12,
      borderBottom: '1px solid #E9E9E7',
    },
    TipsName: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 4,

      '& span': {
        color: '#827F75',
      },
    },
    TipsInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,

      '& span': {
        color: '#827F75',
      },
    },
    TipsPopover: {},
    TipsListText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      '&.actual': {
        color: '#177367 !important',
      }
    },
    TipsListLink: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      '&.actual': {
        color: '#177367 !important',
      }
    },
  }),
);

interface Props {
  show: boolean;
  list?: any;
  closeEvent: () => void;
  activeTipsChip: (active) => void;
};

export const PriceDropTipsModal = (props: Props) => {
  const classes = useStyles();
  const reports: any = {
    '0a5956f9-a9f6-49c0-8080-bb1fda1251a7':	{
      name: 'Весь товар',
      text: 'Чтобы увидеть текущие остатки fbo, недостаточный запас товаров, необходимость изменения цены'
    },
    '7e8d9639-2419-47cd-aca1-0c8829528622':	{
      name: 'Детализация',
      text: 'Чтобы увидеть спрос на товар за последние 11 недель'
    },
    'ceee8d45-32fb-4710-aa62-d26d2a75bb87':	{
      name: 'Размеры, вес, объем',
      text: ''
    },
    '147bda8c-d997-4050-b497-d7a0aed59265':	{
      name: 'Свободные остатки на складах',
      // text: 'Чтобы увидеть свободные остатки на складе / остатки в пути'
    },
    '974b957b-6df3-4811-9eaf-54c12898227f':	{
      name: 'Остатки FBS',
      text: 'Чтобы увидеть текущие остатки fbs'
    },
    '005fea2d-b561-4c81-a1e6-85554bfbd7ba':	{
      name: 'Закупка товара',
      text: ''
    },
    '94e066eb-3b01-4916-993c-73f06978145b':	{
      name: 'Текущая неделя продаж',
      text: 'Чтобы увидеть текущий спрос на товар, прогноз на необходимые остатки, недостаточный запас товаров, необходимость изменения цены'
    },
    '960d6482-dc35-4834-871a-1ee8a26f1d02':	{
      name: 'Текущие акции',
      text: ''
    },
    '5fe9a4b9-423d-4bad-bbc9-e15660568081': {
      name: 'Параметры бренд/категория/товар',
      text: ''
    },
  };
  const [tipsList, setTipsList] = React.useState([]);
  const [allActual, setAllActual] = React.useState<any>();

  const primaryDataSearchActual = useQuery('primaryDataSearchActual', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await getPrimaryDataSearchActual(uid);
    let res = {};
    result.data.data.forEach(el => res[el.primaryDataTypeName] = el.aCheck)
    return res;
  });

  const tips = useQuery('tips', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await getReportList(uid);

    const res = result.data.data.map((item: any) => {
      return {
        actual: differenceDates(item.lastDateUpload, item.periodNumber),
        checkUploadHistory: item.checkUploadHistory,
        isItActual: item.isItActual,
        lastDateUpload: item.lastDateUpload,
        periodNumber: item.periodNumber,
        primaryDataTypeAccumulative: item.primaryDataTypeAccumulative,
        primaryDataTypeName: item.primaryDataTypeName,
        primaryDataTypeOwner: item.primaryDataTypeOwner,
        primaryDataTypePeriod: item.primaryDataTypePeriod,
        primaryDataTypeRequire: item.primaryDataTypeRequire,
        primaryDataTypeSort: item.primaryDataTypeSort,
        uid: item.uid,
      }
    });
    let isAllActual: any = true;
    res.forEach((item: any) => {
      if (!isAllActual) {
        if (!item.actual) isAllActual = false;
      }
    });

    props.activeTipsChip(!!result.data.data.length);
    return res;
  });

  const differenceDates = (date: string, periodNumber: string) => {
    if (!date) return false;
    const regex = /([0-9]+):([0-9]+)/;
    const a: any = moment().startOf('day');
    const b: any = moment(date.replace(regex, '00:00:00'));

    return Math.round(moment.duration(a - b).asDays()) * -1 >= 0;
  };
  // Тут хук который следит за нажатием вне компонента
  // const clickOutsideRef = useOutsideClick(()=>{
  //   if(props.show) props.closeEvent()
  // })
  //            ||
  // Тут ref    \/, который указывает за каким компонентом следит хук outsideClick
  return (
    <div onMouseLeave={() => props.closeEvent()} className={classNames(classes.TipsModal, props.show ? classes.show : '')}>
      <div className={classes.TipsHeader}>
        <h1 className={classes.TipsTitle}>Советы</h1>
        <span className={classes.TipsClose} onClick={() => props.closeEvent()}>
          <Close />
        </span>
      </div>
      <div className={classes.TipsContent}>
        <h5 className={classes.TipsMiniTitle}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L8 10L12 6" stroke="#37352F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Список шагов
        </h5>

        <ul className={classes.TipsList}>
          {tips.data?.map((item: any, index: number) => (
            reports[item.uid] &&
            <li
              className={
                classNames(
                  classes.TipsListItem,
                  primaryDataSearchActual?.data?.[item?.primaryDataTypeName] ? 'actual' : null
                )
              }
              key={index}>
              <p
                className={
                  classNames(
                    classes.TipsListText,
                    primaryDataSearchActual?.data?.[item?.primaryDataTypeName] ? 'actual' : null
                  )
                }
                >
                  {reports[item.uid] ? reports[item.uid].text : ''}
                </p>
              <a
                href={`/load-data/load-params?id=${item.uid}&name=${reports[item.uid].name}`}
                className={
                  classNames(
                    classes.TipsListLink,
                    primaryDataSearchActual?.data?.[item?.primaryDataTypeName] ? 'actual' : null
                  )
                }
              >
                Загрузить отчет «{reports[item.uid].name === 'Текущая неделя продаж'? "Еженедельная динамика продаж" : reports[item.uid].name}»
              </a>
            </li>
          ))}
          <li className={classNames(classes.TipsListItem, allActual ? 'actual' : null)}>
            <a
              href="/load-data"
              className={
                classNames(
                  classes.TipsListLink,
                  allActual ? 'actual' : null
                )
              }
            >
              Готовый отчет с измененными ценами для загрузки в ЛК WB 
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
