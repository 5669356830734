import { Box, Button, ButtonGroup, createStyles, makeStyles, Menu, MenuItem, OutlinedInput, Select, Switch, Theme, useTheme } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import cookie from 'cookie';

import CustomModal from '../../UI/CustomModal';
import {Bell, MoreVerticalIcon, RightArrow, Search} from '../../UI/Icons'
import { PriceDropFilterMenu } from './FilterMenu'
import { downloadListProducts, excelDownloadAllGoods, excelDownloadListPromotion, getFilterSale, getFilterStocks, setCalc, setCalcOrg } from '../../../api/calc';
import { PriceDropTipsModal } from './TipsModal';
import fileDownload from '../../../common/file-download'
import CustomSnackbar from '../../UI/CustomSnackbar';
import { clearFilter, setColum } from '../../../api/reports';
import PriceDropNotificationModal from './NotificationModal';
import { findTariff } from '../../../api/tariffs';
import * as XLSX from 'xlsx';
import moment from 'moment';
import SettingsMenu from '../../UI/SettingsMenu';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import {ExcelDialog} from "../ExcelDialogComponent/ExcelDialog";
import Spinner from '../../UI/Spinner';
import {getNewParam} from "../../../api/admin";
// @ts-ignore
import { useNewWb } from '../../../pages/Admin/store';
import { loadDataPromotion } from '../../../api/wb-data';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '.MuiMenuItem-root': {
        fontSize: 14,
        lineHeight: '16px',
        whiteSpace: 'unset',
      },
      // '.MuiPopover-paper': {
      //   top: '580px !important',
      // },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      padding: '16px 32px',
    },
    wrapProd1: {
      border: '2px solid #fa4af7',
      display: 'flex',
      height: 'fit-content',
      flexDirection: 'column',
      padding: 15,
      borderRadius: 10,
      background: '#fdf3fb',
      fontSize: 15,
      marginRight: 10,
    },
    number: {
      color: 'white',
      padding: '2px 7px',
      background: '#bf1ba2',
      borderRadius: 2,
    },
    textNumberProd: {
      fontWeight: 700,
    },
    textNumberProd2: {
      fontWeight: 700,
      color: '#e43015',
    },
    textNumberProd1: {
      fontWeight: 600,
      color: '#c2c2c2',
      marginTop: 10,
      cursor: 'pointer',
    },
    textNumberProd1_1: {
      fontWeight: 700,
      color: '#e43015',
      marginTop: 0,
      cursor: 'pointer',
    },
    toolbarCount: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginRight: 10
    },
    toolbarBtns: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    toolbarSearchBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      // textDecoration: 'underline',
      cursor: 'pointer',
      marginRight: 16,

      '& svg': {
        stroke: '#37352F',
      },
      '&:hover svg': {
        stroke: '#827F75',
      },
      '&:hover': {
        color: '#827F75',
      },
    },
    toolbarSearchBtnText: {
      marginLeft: 4,
      borderBottom: '1px solid #000',
      paddingBottom: 1,

      '&:hover': {
        borderBottom: '1px solid #827F75',
      }
    },
    toolbarSearch: {
      border: 'none',
      outline: 'none',
      borderBottom: '1px solid #37352F',
      marginLeft: 8,
      marginBottom: 2,
      width: 222,
      transition: 'all .3s ease-out',
      paddingBottom: 1,
      cursor: 'pointer',

      // '&:focus': {
      //   width: 222,
      //   cursor: 'auto',
      // },
      '&::placeholder': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#37352F',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        // textDecoration: 'underline',
        cursor: 'pointer',
        transition: 'all .2s ease-out',
      },
      '&.focus': {
        '&::placeholder': {
          fontFamily: '"Work Sans", sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px',
          color: '#827F75',
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none',
          // textDecoration: 'underline',
        },
      },
    },
    toolbarFilter: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 16,
    },
    toolbarFilterBtn: {
      position: 'relative',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '14px',
      color: '#37352F',
      textTransform: 'capitalize',
      borderBottom: '1px solid #000',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:hover': {
        borderBottom: '1px solid #827F75',
        color: '#827F75',
      },
    },
    settingChip: {
      position: 'absolute',
      top: -10, right: -10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '12px',
      width: 14,
      height: 14,
      color: 'white',
      background: '#FF6C4B',
      borderRadius: '50%',
    },
    toolbarAddBtn: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'none',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      padding: '8px 16px 8px 10px',
      cursor: 'pointer',

      '& svg': {
        stroke: '#ffffff',
        marginRight: 4,
      },

      '&:hover': {
        background: '#827F75',
      },
    },
    toolbarIconBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 40,
      marginRight: 16,
      cursor: 'pointer',
    },
    settingsMenu: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 8,
        // top: '126px !important',
        transform: 'translateY(60px) !important',
        maxHeight: '70vh',
      },
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    settingsContet: {
      padding: 16,
      maxWidth: 330,
      width: '100vh',
    },
    settingsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,

      '& svg': {
        width: 20,
        height: 20,
      }
    },
    settingsTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    settingsList: {

    },
    settingsItem: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      cursor: 'grab !important',
    },
    settingsItemContent: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      padding: '6px 0',
      borderTop: '1px solid #fff',
      transition: 'all 0.1s ease-in-out',

      '& svg': {
        marginRight: 4,
        minWidth: 16,
      },
    },
    settingsItemSwitch: {
      width: 35,
      height: 18,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#000',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: '#ccc',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 14,
        height: 14,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: 'all .5s ease-in-out',
      },
    },
    settingsReportContent: {
      padding: 8,
      minWidth: 185,
    },
    settingsReportItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: 10,
      cursor: 'pointer',
    },
    selectBlock: {
      width: '100%',
      outline: 'none',
      // minHeight: 30,
      border: 'none !important',
      borderRadius: 8,
      marginBottom: 40,
      minHeight: 44,

      '&.Mui-focused': {
        outline: 'none !important',
        border: 'none !important',
      },
      '& .MuiSelect-select': {
        padding: '0 !important',
        background: 'transparent !important',
        paddingTop: '0px !important',
      }
    },
    selectInput: {
      border: '1px solid #D3D1CB',
      outline: 'none',
    },
    selectChip: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      marginRight: 3,
      padding: '4px 8px',
      margin: 4,
      marginTop: 0,
      marginBottom: 4,
    },
    selectChipClose: {
      width: '16px !important',
      height: '16px !important',
      marginLeft: '8px !important'
    },
    notParamsContent: {
      paddingTop: 32,
      paddingBottom: 32,
    },
    notParamsLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    // notParamsContent: {},
    notParamsText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
      marginBottom: 32,
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      // marginTop: 32,
      marginBottom: 16,

      '& span': {
        color: '#FF6C4B',
      },
    },
    miniField: {
      maxWidth: 300,
      marginBottom: 40,
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: '100%',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '14px 16px',
      width: '100%',
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,
    },
    filterAddBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      textTransform: 'none',
      border: 'none',
      outline: 'none',
      background: 'none',
      padding: 0,
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        marginRight: 4,
        stroke: '#37352F',
      },
    },
    reportModal: {
      paddingTop: 32,
    },
    reportModalText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
      marginBottom: 32,
    },
    reportModalBtnGroupWrap: {},
    reportModalLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    reportModalBtns: {
      marginBottom: 32,
    },
    reportModalBtn: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: '#37352F',
      textTransform: 'none',
      background: '#FFFFFF',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      minHeight: '44px !important',
      minWidth: '210px !important',

      '&:last-child': {
        borderRadius: '0px 8px 8px 0px',
      },
    },
    reportModalFirstBtn: {
      boxSizing: 'border-box',
      borderRadius: '8px 0px 0px 8px',
    },
    reportModalLastBtn: {
      borderRadius: '0px 8px 8px 0px',
    },
    active: {
      background: '#E9E9E7',
      border: '1px solid #37352F',
    },
    listModalContent: {
      paddingTop: 32,
      paddingBottom: 32,
    },
    settingsCancelBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 8,
      paddingBottom: 8,
    },
    settingsCancel: {
      cursor: 'pointer',
      borderBottom: '1px solid #37352F',
    },
    previewSheet: {
      padding: '32px 0'
    },
    setDataModalChips: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    setDataModalChip: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      margin: 3,
      padding: '4px 8px',
    },
    setDataModalChipClose: {
      width: '16px !important',
      height: '16px !important',
      marginLeft: '8px !important'
    },
    warnSearch:{
      borderBottom: '1px solid #fc0101!important',
      color: '#fc0101!important'
    },
  })
)

interface Props {
  cols: Array<{
    column_caption: string;
    column_name: string;
    showed: boolean;
  }>;
  toggleNew?: boolean;
  numberProduct?: any;
  numberProduct1?: any;
  reportHandler?: () => void;
  count?: number;
  filterList?: any;
  sendFilters?: (filter: any) => void;
  sendColumn?: (column: any) => void;
  newColumnPosition?: (column: any) => void;
  updateTable?: () => void;
  search?: (value: string) => void;
  refetch?: () => void;
  setLoading?: () => void;
  setPage?: any,
  dataFetch?: any,
  columnFetch?: any,

};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null
};
const getStyles = (name: string, values: any, fromBtnsParam: string, theme: Theme) => {
  return {
    fontWeight:
      values[fromBtnsParam || ''].indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    };
};
const getStylesCategories = (name: string, personName: readonly string[], theme: Theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const PriceDropToolbar = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles();

  const [loaded, setLoaded] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [cols, setCols] = React.useState<any>([]);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [reportModalOpen, setReportModalOpen] = React.useState(false);
  const [listModalOpen, setListModalOpen] = React.useState(false);
  // const [openReport, setOpenReport] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [showTip, setShowTip] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notParamsOpen, setNotParamsOpen] = React.useState(false);
  const [accesses, setAccesses] = React.useState<Array<any>>([]);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [countPoints, setCountPoints] = React.useState('');
  const [countPercent, setCountPercent] = React.useState('');
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [showNotify, setShowNotify] = React.useState(false);
  const [tipsActiveChip, setTipsActiveChip] = React.useState(false);
  const [notifyActiveChip, setNotifyActiveChip] = React.useState(false);
  const [openForParamsSelect, setOpenForParamsSelect] = React.useState(false);
  const [forParams, setForParams] = React.useState<any>({
    brand: [],
    category: [],
    name_: []
  });
  const [priceOrStockParam, setPriceOrStockParam] = React.useState();
  const [fromBtnsParam, setFromBtnsParam] = React.useState('category');
  const [unitParam, setUnitParam] = React.useState();
  const [price, setPrice] = React.useState('');
  const [percent, setPercent] = React.useState('');
  const [fileBase64, setFileBase64] = React.useState('');
  const [previewSheet, setPreviewSheet] = React.useState<any>();
  const [showPreview, setShowPreview] = React.useState(false);
  const [settingsCount, setSettingsCount] = React.useState(0);
  const [search, setSearch] = React.useState('')
  const [currentFilter, setCurrentFilter] = React.useState([]);
  const [categoriesData, setCategoriesData] = React.useState(
    [
      { categories: [], points: '', percent: '' }
    ]
  );
  const [searchWarn, setSearchWarn] = useState(false)
  const [searchIsSended, setSearchIsSended] = useState(false)
  const [anchorReport, setAnchorReport] = React.useState<null | HTMLElement>(null);
  const [isOpenExcel, setIsOpenExcel] = useState(false)
  const openReport = Boolean(anchorReport);
  const priceOrStockBtns = [
    {id: 1, name: 'Повысить розничную цену'},
    {id: 0, name: 'Снизить скидку'},
  ];
  const unitBtns = [
    {id: 1, name: '₽'},
    {id: 0, name: '%'},
  ];
  const fromBtns = [
    {id: 'brand', name: 'Бренд'},
    {id: 'category', name: 'Категория'},
    {id: 'name_', name: 'Товар'},
  ];

  const handleCloseSelect = () => {
    setOpenSelect(false);
  };
  const handleOpenSelect = (e: any) => {
    setOpenSelect(true);
  };
  const handleCloseForParams = () => {
    setOpenForParamsSelect(false);
  };
  const handleOpenForParamsSelect = (e: any) => {
    if (!e?.target?.className.length) {
      setOpenForParamsSelect(false);
      return;
    }
    if (e?.target?.tagName === 'svg' || e?.target?.tagName === 'path') {
      return;
    }
    setOpenForParamsSelect(true);
  };

  const removeChip = (id: string) => {
    let selectedCopy: any = JSON.parse(JSON.stringify(forParams));
    selectedCopy[fromBtnsParam] = selectedCopy[fromBtnsParam].filter((item: any) => item !== id);
    setForParams(selectedCopy);
  };

  const filterStocks = useQuery<any>('filterStocks', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await getFilterSale({
      rubles: 1,
      max_sale: 1,
      max_sale_value: 1,
      org: uid,
      colum: 'category',
    });

    return result.data.data;
  });

  const filterStocksFromParam = useQuery<any>(['filterStocksFromParam', fromBtnsParam], async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await getFilterSale({
      rubles: 1,
      max_sale: 1,
      max_sale_value: 1,
      org: uid,
      colum: fromBtnsParam,
    });
    return result.data.data;
  });



  useEffect(() => {
    if (loaded) return;
    if (props.cols.length) {
      let count = 0;

      props.cols.forEach((item: any) => {
        if (!item.showed) {
          count += 1;
        }
      });
      setSettingsCount(count);
      setCols(props?.cols);
      setLoaded(true);
    }
  });
  const profileWb = localStorage.getItem('profileWb')
  const [isProfileWb, setIsProfileWb] = React.useState(false)
  const [space, setSpace] = React.useState(0)

  useEffect(() => {
    setIsProfileWb(profileWb)
    if(profileWb) {
      setSpace(-10)
    } else {
      setSpace(10)
    }

  }, [profileWb])
  // console.log(isProfileWb)

  const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSettings(true);
  };
  const handleCloseSettings = () => {
    setShowSettings(false);
    if (props.sendColumn)
      props.sendColumn(cols);
    // setCols([]);
  };


  const sendFilters = (filters: any) => {
    if (props.sendFilters) props?.sendFilters(filters);
  };

  const [currentItem, setCurrentItem] = React.useState<any>();
  const [currentItemIdx, setCurrentItemIdx] = React.useState<number>(-1);

  const onDragStartEvent = (e: any, item: any, index: number) => {
    setCurrentItem(item);
    setCurrentItemIdx(index);
  };
  const onDragOverEvent = (e: any, item: any, index: number) => {
    e.preventDefault();
    if (e.target.className.includes('settingsItemContent')) {
      e.target.style.borderTop = '10px solid rgba(255, 108, 75, 0.2)';
    }
  };
  const onDragLeaveEvent = (e: any) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDragEndEvent = (e: any) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDropEvent = (e: any, overedItemIdx: number) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
    let colsCopy = JSON.parse(JSON.stringify(cols));
    colsCopy.splice(currentItemIdx, 1);
    colsCopy.splice(overedItemIdx, 0, currentItem);
    setCols(colsCopy);
    if (props.newColumnPosition) props.newColumnPosition(colsCopy);
  };
  const onChangeCity = async (index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyCols = JSON.parse(JSON.stringify(cols));
    let curEl: any = null;

    copyCols[index].showed = !copyCols[index].showed;
    curEl = copyCols[index];
    setCols(copyCols);

    if (curEl) {
      await setColum({
        colum: curEl.column_name,
        organization_uid: uid,
        calc: 'b112d499-d632-42d8-970f-c6747ee54de3',
        del: curEl.showed,
      });
    }
    let count = 0;
    copyCols.forEach((item: any) => {
      if (!item.showed) {
        count += 1;
      }
    });

    setSettingsCount(count);
    // if (props.newColumnPosition) props.newColumnPosition(copyCols);
  };

  const handleChangeForParams = (value: any) => {
    let copy = JSON.parse(JSON.stringify(forParams));
    copy[fromBtnsParam] = value;
    setForParams(copy);
    // handleCloseForParams();
  };

  const handleChangeAccesses = (event: any) => {
    const { target: { value } } = event;
    setAccesses(value);
    handleCloseSelect();
  };

  const saveNoParamsField = async (field: string, val: string) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await setCalc({
      org: uid,
      column: field || 'number_change_points',
      val: Number(val),
      articl: accesses,
    });

    if (props.updateTable) props.updateTable();
    return result.data.data;
  };
  const saveNoParam = async () => {
    setNotParamsOpen(false);
    setReportModalOpen(true);
  };

  const generateReport = async () => {
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const result: any = await downloadListProducts({
        org: uid,
        [fromBtnsParam]: forParams?.[fromBtnsParam]
      });
      const base64str: string = result?.data?.data;
      const workbook = XLSX.read(base64str, {type:'base64'});
      let worksheets: any = {};

      for (const sheetName of workbook.SheetNames) {
        worksheets[sheetName] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      };

      // setShowPreview(true);
      setPreviewSheet(worksheets);
      setFileBase64(result?.data?.data);
      downloadExcel(result?.data?.data);

      return result?.data?.data;
    } catch (error: any) {
      setSnackbarMessage(error.data.description[0].reason);
      setShowSnackbar(true);
    }
  };

  const handleSearch = (value: string) => {
    if (props.search) props.search(value);
  };

  const cancelSettings = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await clearFilter({
      organization_uid: uid,
      primary_data: 'b112d499-d632-42d8-970f-c6747ee54de3',
      type: 1
    });
    await clearFilter({
      organization_uid: uid,
      primary_data: 'b112d499-d632-42d8-970f-c6747ee54de3',
      type: 2
    });

    let copyCols = JSON.parse(JSON.stringify(cols));

    copyCols.forEach((item: any) => item.showed = true);
    setCols(copyCols);

    let count = 0;
    copyCols.forEach((item: any) => {
      if (!item.showed) {
        count += 1;
      }
    });

    setSettingsCount(count);
    if (props.refetch) props.refetch();
  };

  const cancelFilter = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await clearFilter({
      organization_uid: uid,
      primary_data: 'b112d499-d632-42d8-970f-c6747ee54de3',
      type: 3
    });

    if (props.refetch) props.refetch();
  };

  const sendReport = async () => {
    if (!percent.length) {
      setSnackbarMessage('Заполните все поля');
      setShowSnackbar(true);
      return;
    }
    let type: any = 2;
    let articl = forParams?.[fromBtnsParam];
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const plan = await findTariff(uid);
    const result = await setCalcOrg({
      org: uid,
      rubles_procent: unitParam,
      max_sale: priceOrStockParam,
      max_sale_value_1: priceOrStockParam ? Number(percent) : undefined,
      max_sale_value: !priceOrStockParam ? Number(percent) : undefined
    });
    switch (fromBtnsParam) {
      case 'brand':
        type = 1;
        break;
      case 'category':
        type = 2;
        break;
      case 'name_':
        type = null;
        break;
    }
    const calc = await setCalc({
      org: uid,
      type: type,
      column: 'volume_max',
      val: Number(price) || Number(percent),
      articl
    });
    generateReport();
    // setReportModalOpen(false);

    return result?.data?.data;
  };

  const downloadExcel = (base64?: string) => {
    fileDownload(base64 || fileBase64, `export-${moment()}.xlsx`)
  };

  const handleClickReportBtn = async (event: React.MouseEvent<HTMLButtonElement>, toggle: boolean) => {

    setAnchorReport(event.currentTarget);

  };


  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
     if(props.search) {
       props.setPage(1)
       props.search(e.target.value)
       setSearchIsSended(true)
     }
    }
  };
  function onBlurSearch(value: string){

    if(value?.trim() !== "") {
      if (!searchIsSended) setSearchWarn(true)
      else setSearchWarn(false)
    }
    else setSearchWarn(false)
  }

  const onChangeSearch = (e) => {
    setSearch(e)
    setSearchIsSended(false)
    if(e.length === 0) {
    const Debounce = setTimeout(() => {
      if(props.search) {
        props.setPage(1)
        props.search('')
      }
    }, 500)
    return () => clearTimeout(Debounce)
  }
  }

  ////Новое оповещение
  const [countNotifications, setCountNotifications] = useState(0)

  const StyledBadge = withStyles((theme) => ({
    badge: {
      color: '#fff',
      background: '#FF6C4B',
      right: 2,
      top: 6,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge);

  const handleCloseReport = () => {
    setAnchorReport(null);
  };
  const openModalExportToExcel = () => {
    setIsOpenExcel(true)
  }

  const closeModalExportToExcel = () => {
    setIsOpenExcel(false)
  }

  const [loader, setLoader] = useState(false)

  const reportHandlerProm = async (event: React.MouseEvent<HTMLButtonElement>, toggle: boolean) => {
    props.setLoading(true);
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const plan = await findTariff(uid);
    const excel = await excelDownloadListPromotion({
      org: uid,
      plan: plan.data.data.uid || '45124e44-22d7-4714-9ad4-83129b787202',
      info: toggle
    });

    if (toggle === false) {
      fileDownload(excel.data.data, 'export.xlsx');
      setSnackbarMessage('Отчет успешно выгружен');
      setShowSnackbar(true);
    }

    props.setLoading(false);
  }


  // getNewParam
  // async function getNewParamHandler() {
  //   try {
  //     const res = await getNewParam()
  //     localStorage.setItem('weeks', '');
  //     const type = localStorage.getItem('typeName')
  //   } catch (err) {
  //     console.log('при получении состояния профиля WB произошла ошибка', err)
  //   }
  // }

  return (
    <div className={classes.toolbar}>
      <Spinner open={loader} />
      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />
      <div>
        <p className={classes.toolbarCount} style={{marginBottom: 10}}>Всего без участия в акции: {props.count}</p>
        {props.numberProduct && props.toggleNew &&
          <p className={classes.toolbarCount}>Всего: {props.numberProduct.all_nom === 'None'? 0 : props.numberProduct.all_nom}</p>
        }
      </div>
      <CustomModal
        title="Предпросмотр"
        show={showPreview}
        successText="Скачать"
        cancelText="Отмена"
        successEvent={() => downloadExcel()}
        closeEvent={() => setShowPreview(false)}
      >
        <div className={classes.previewSheet}>
          Файл пуст
        </div>
      </CustomModal>

      <CustomModal
        title="Заполнение недостающих параметров"
        show={notParamsOpen}
        successText="Сохранить"
        cancelText="Отмена"
        successEvent={() => saveNoParam()}
        closeEvent={() => setNotParamsOpen(false)}
      >
        <div className={classes.notParamsContent}>
          <p className={classes.notParamsText}>
            Для того, чтобы сформировать отчет, необходимо дополнить данные
          </p>
          <div>
            <p className={classes.notParamsLabel}>Категория товаров <span>*</span></p>
            <Select
              className={classes.selectBlock}
              multiple
              value={accesses}
              onChange={handleChangeAccesses}
              input={<OutlinedInput className={classes.selectInput} />}
              renderValue={(selected: any) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', marginRight: 5 }}>
                  {selected.map((value: any) => (
                    <div className={classes.selectChip} key={value}>
                      {value} <span onClick={() => {
                        removeChip(value);
                      }}>
                        <CloseIcon className={classes.selectChipClose} />
                      </span>
                    </div>
                  ))}
                </Box>
              )}
              open={openSelect}
              onClose={handleCloseSelect}
              onOpen={handleOpenSelect}
              MenuProps={MenuProps}
            >
              {filterStocks?.data?.category && filterStocks?.data?.category?.map((access: any) => (
                <MenuItem
                  key={access}
                  value={access}
                  style={getStylesCategories(access, accesses, theme)}
                >
                  {access}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classNames(classes.fieldBlock, classes.miniField)}>
            <label htmlFor="name" className={classes.fieldLabel}>
              Кол-во пунктов, на которые нужно изменить параметр текущей скидки <span>*</span>
            </label>
            <div className={classes.iconField}>
              <input
                type="number"
                placeholder="Только цифры"
                className={classes.field}
                value={countPoints}
                onChange={(e: any) => setCountPoints(e.target.value)}
                onBlur={() => saveNoParamsField('number_change_points', countPoints)}
              />
              <Check className={classNames(classes.checkIcon, countPoints.length > 0 ? classes.checkIconActive : '')} />
            </div>
          </div>
          <div className={classNames(classes.fieldBlock, classes.miniField)}>
            <label htmlFor="name" className={classes.fieldLabel}>
              %, на который нужно изменить цену для клиента <span>*</span>
            </label>
            <div className={classes.iconField}>
              <input
                type="number"
                placeholder="Только цифры"
                className={classes.field}
                value={countPercent}
                onChange={(e: any) => setCountPercent(e.target.value)}
                onBlur={() => saveNoParamsField('number_change_percent', countPercent)}
              />
              <Check className={classNames(classes.checkIcon, countPercent.length > 0 ? classes.checkIconActive : '')} />
            </div>
          </div>

          {/* <button className={classes.filterAddBtn}>
            <Plus /> Добавить категории
          </button> */}
        </div>
      </CustomModal>

      <CustomModal
        title="Формирование отчета"
        show={reportModalOpen}
        successText="Сформировать отчет"
        cancelText="Отмена"
        successEvent={() => sendReport()}
        closeEvent={() => setReportModalOpen(false)}
      >
        <div className={classes.reportModal}>
          <p className={classes.reportModalText}>
            Укажите условия
          </p>
          <div className={classes.reportModalBtnGroupWrap}>
            <p className={classes.reportModalLabel}>Что сделать</p>
            <ButtonGroup className={classes.reportModalBtns} variant="outlined">
              {priceOrStockBtns.map((item: any) => (
                <Button
                  className={
                    classNames(
                      classes.reportModalFirstBtn,
                      classes.reportModalBtn,
                      priceOrStockParam === item.id ? classes.active : ''
                    )
                  }
                  onClick={() => setPriceOrStockParam(item.id)}
                  key={item.id}
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div>
            <div className={classes.reportModalBtnGroupWrap}>
              <p className={classes.reportModalLabel}>Единица измерения</p>
              <ButtonGroup className={classes.reportModalBtns} variant="outlined">
                {unitBtns.map((item: any) => (
                  <Button
                    className={
                      classNames(
                        // classes.reportModalFirstBtn,
                        classes.reportModalBtn,
                        unitParam === item.id ? classes.active : ''
                      )
                    }
                    style={{
                      display: priceOrStockParam === 0 && item.id === 1
                        ? 'none'
                        : null,
                      borderRadius: priceOrStockParam === 0 && item.id === 0
                        ? '8px'
                        : null
                    }}
                    onClick={() => setUnitParam(item.id)}
                    key={item.id}
                  >
                    {item.name}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            <div className={classNames(classes.fieldBlock, classes.miniField)}>
              {(unitParam === 0 || unitParam === 1) ? (
                <>
                  {priceOrStockParam
                    ? <label htmlFor="name" className={classes.fieldLabel}>
                        На сколько повысить цену, {unitParam === 0 ? '%' : '₽'} <span>*</span>
                      </label>
                    : <label htmlFor="name" className={classes.fieldLabel}>
                        Снизить скидку на, {unitParam === 0 ? '%' : '₽'} <span>*</span>
                      </label>}
                  <div className={classes.iconField}>
                    <input
                      id="name"
                      type="number"
                      className={classes.field}
                      value={percent}
                      onChange={(e) => setPercent(e.target.value)}
                    />
                    <Check className={classNames(classes.checkIcon, percent.length > 0 ? classes.checkIconActive : '')} />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className={classes.reportModalBtnGroupWrap}>
            <p className={classes.reportModalLabel}>Для</p>
            <ButtonGroup className={classes.reportModalBtns} variant="outlined">
              {fromBtns.map((item: any) => (
                <Button
                  className={
                    classNames(
                      classes.reportModalFirstBtn,
                      classes.reportModalBtn,
                      fromBtnsParam === item.id ? classes.active : ''
                    )
                  }
                  onClick={() => setFromBtnsParam(item.id)}
                  key={item.id}
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div>
            <div>
              {fromBtnsParam === 'brand' &&
                <p className={classes.fieldLabel}>Бренд <span>*</span></p>
              }
              {fromBtnsParam === 'category' &&
                <p className={classes.fieldLabel}>Категория <span>*</span></p>
              }
              {fromBtnsParam === 'name_' &&
                <p className={classes.fieldLabel}>Товар <span>*</span></p>
              }
              <Select
                className={`${classes.selectBlock} selectAbsolute`}
                multiple
                value={forParams?.[fromBtnsParam]}
                onChange={(e: any) => handleChangeForParams(e.target.value)}
                input={<OutlinedInput className={classes.selectInput} />}
                renderValue={(selected: any) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', marginRight: 5 }}>
                    {selected.map((value: any) => (
                      <div className={classes.setDataModalChips}>
                        <div className={classes.setDataModalChip} onClick={() => removeChip(value)}>
                          {value} <CloseIcon className={classes.setDataModalChipClose} />
                        </div>
                      </div>
                    ))}
                  </Box>
                )}
                open={openForParamsSelect}
                onClose={handleCloseForParams}
                onOpen={handleOpenForParamsSelect}
                MenuProps={MenuProps}
              >
                {filterStocksFromParam?.data?.[fromBtnsParam]?.map((el: any, i: number) => (
                  // style={getStyles(item, forParams, fromBtnsParam, theme)}
                  <MenuItem value={el} key={i}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        title="Формирование списка товаров для заказа"
        show={listModalOpen}
        successText="Сформировать отчет"
        cancelText="Отмена"
        successEvent={() => console.log()}
        closeEvent={() => setListModalOpen(false)}
      >
        <div className={classes.listModalContent}>
          <p>Выберите условие</p>
        </div>
      </CustomModal>

      <div className={classes.toolbarBtns}>
        {cols.length
          ? <SettingsMenu
              cols={cols}
              calc={'b112d499-d632-42d8-970f-c6747ee54de3'}
              newColumnPosition={props.newColumnPosition}
              sendColumn={props.sendColumn}
              refetch={props.refetch}
            />
          : null
        }
        <div className={classes.toolbarSearchBtn}>
          <div onClick={() => {
            props.setPage(1)
            props.search(search)
            }}>
              <Search />
          </div>
          <input
            type="text"
            placeholder={!openSearch ? 'Поиск' : 'Введите параметр для поиска...'}
            className={classNames(classes.toolbarSearch, searchWarn ? classes.warnSearch : '', openSearch ? 'focus' : '')}
            // onFocus={() => setOpenSearch(true)}
            onBlur={(e) => {
              setOpenSearch(false)
              onBlurSearch(e.target.value)
            }}
            onFocus={()=>{setSearchWarn(false)}}
            onKeyPress={_handleKeyDown}
            value={search}
            onChange={(e) => onChangeSearch(e.target.value)}
          />
        </div>
        <div className={classes.toolbarFilter}>
          <PriceDropFilterMenu
            setCurrentFilter={setCurrentFilter}
            setPage = {props.setPage}
            cols={props.cols || []}
            filterList={props.filterList || []}
            sendFilters={sendFilters}
            cancelFilter={cancelFilter}
          />
        </div>
        <span
          className={
            classNames(
              classes.toolbarIconBtn,
              notifyActiveChip ? 'active' : null
            )
          }
          onClick={() => {
            setShowNotify(true)
            setShowTip(false)
          }}
        >
           <IconButton aria-label="cart">
            <StyledBadge badgeContent={countNotifications}>
                <Bell />
            </StyledBadge>
          </IconButton>

        </span>
        <PriceDropNotificationModal
          setCount={setCountNotifications}
          show={showNotify}
          activeNotifyChip={(active) => setNotifyActiveChip(active)}
          closeEvent={() => setShowNotify(false)}
        />
        <span className={classes.toolbarIconBtn} onClick={() => {
          setShowTip(true);
          setShowNotify(false)
        }}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.375 22.3304H11.4541H12.2H12.9592H13.025H13.6705H14.6C15.5274 22.3304 16.29 21.5679 16.29 20.6404V15.8404C16.29 15.3608 16.4266 15.0354 16.6788 14.6802C16.8602 14.4248 17.1001 14.1758 17.3803 13.885C17.507 13.7534 17.642 13.6133 17.7836 13.4601L17.7839 13.4597C18.6798 12.4865 19.65 11.1051 19.65 8.64043C19.65 4.53111 16.3093 1.19043 12.2 1.19043C8.09068 1.19043 4.75 4.53111 4.75 8.64043C4.75 11.1452 5.7103 12.645 6.61149 13.6796C6.79724 13.8933 6.98066 14.0899 7.14888 14.2703C7.18161 14.3054 7.21377 14.3399 7.24526 14.3738C7.44098 14.5843 7.60905 14.7693 7.74147 14.9424C8.00502 15.2867 8.11 15.5303 8.11 15.8404V20.6404C8.11 21.5679 8.87255 22.3304 9.8 22.3304H11.375ZM16.6963 12.4758L16.6959 12.4763C16.5934 12.5878 16.4866 12.6994 16.3782 12.8126C16.0759 13.1284 15.7611 13.4572 15.4915 13.8353C15.1134 14.3654 14.83 15.0282 14.83 15.8404V16.7504H9.57V15.8404C9.57 15.1164 9.25399 14.529 8.88822 14.0531C8.66259 13.7595 8.39961 13.4758 8.1393 13.195C7.99242 13.0365 7.84639 12.879 7.7084 12.7211C7.31324 12.268 6.94189 11.7781 6.66725 11.1407C6.39301 10.5042 6.21 9.70827 6.21 8.64043C6.21 5.32663 8.8862 2.65043 12.2 2.65043C15.5138 2.65043 18.19 5.32663 18.19 8.64043C18.19 9.68238 18.0086 10.4271 17.7376 11.0125C17.4657 11.5997 17.0971 12.0418 16.6963 12.4758ZM10.2158 8.37617L10.203 8.37716L10.1903 8.37946C10.1626 8.38449 10.137 8.39116 10.1164 8.39707C9.84873 8.45023 9.63542 8.64817 9.55991 8.90823L9.55972 8.90891C9.48357 9.17401 9.56167 9.45714 9.7611 9.64509L11.47 11.354V13.9804V14.2304H11.72H12.68H12.93V13.9804V11.354L14.6418 9.64221C14.9307 9.35332 14.9307 8.88753 14.6418 8.59865C14.3529 8.30977 13.8871 8.30977 13.5982 8.59865L12.2 9.99688L10.8046 8.60151C10.6518 8.4394 10.4337 8.35941 10.2158 8.37617ZM14.83 18.2504V20.6404C14.83 20.7686 14.7282 20.8704 14.6 20.8704H10.8727C10.7934 20.8573 10.7116 20.8573 10.6323 20.8704H9.8C9.67182 20.8704 9.57 20.7686 9.57 20.6404V18.2504H14.83Z" fill="#37352F" stroke="#37352F" strokeWidth="0.5"/>
            <rect x="6" y="18" width="12" height="3" fill="white"/>
            {tipsActiveChip && <circle cx="19" cy="5" r="5" fill="#FF6C4B" />}
          </svg>
        </span>
        <PriceDropTipsModal
          show={showTip}
          activeTipsChip={(active) => setTipsActiveChip(active)}
          closeEvent={() => setShowTip(false)}
        />
          <div className={classes.wrapProd1}>
            {
              !props.toggleNew &&
              <div className={classes.textNumberProd2}>Количество товаров: <span className={classes.number}>{props.numberProduct1}</span></div>
            }
            { props.toggleNew && props.numberProduct &&
              <>
                <div className={classes.textNumberProd2} style={{fontSize: 13, marginBottom: 8}}>Товаров уже в акции: <span className={classes.number}>{props.numberProduct.yes_no === 'None'? 0 : props.numberProduct.yes_no} поз., {props.numberProduct.yes_no_procent === 'None'? 0 : props.numberProduct.yes_no_procent}%</span></div>
                <div className={classes.textNumberProd2} style={{fontSize: 13, marginBottom: 8}}>Товаров будет в акции: <span className={classes.number}>{props.numberProduct.count_seles_galka_sum_yes_no === 'None'? 0 : props.numberProduct.count_seles_galka_sum_yes_no} поз., {props.numberProduct.count_seles_galka_sum_yes_no_procent === 'None'? 0 : props.numberProduct.count_seles_galka_sum_yes_no_procent}%</span></div>
                <div className={classes.textNumberProd2} style={{fontSize: 13, marginBottom: 8}}>Выбрано товаров: <span className={classes.number}>{props.numberProduct.count_seles_galka === 'None'? 0 : props.numberProduct.count_seles_galka} поз.  {/* {numberProduct}% */}</span></div>

              </>
            }
              <div className={classes.textNumberProd1_1} onClick={(e: any) => props.reportHandler()}>Обновить</div>
          </div>
        <Button
          id="basic-button"
          aria-controls={openReport ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openReport ? 'true' : undefined}
          onClick={handleClickReportBtn}
          className={classes.toolbarAddBtn}
        >
          <RightArrow color="#fff" /> Сформировать отчет
        </Button>
        <Menu
          id="settings-menu"

          className={classes.settingsMenu}
          anchorEl={anchorReport}
          open={openReport}
          style={{marginTop: 17, top: `${space}px`}}
          onClose={handleCloseReport}
        >
          <div className={classes.settingsReportContent}>
            <div className={classes.settingsReportItem} onClick={(e: any) => reportHandlerProm(e, false)} >
              {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
              {/*  <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
              {/*  <path d="M7 10L12 15L17 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
              {/*  <path d="M12 15V3" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
              {/*</svg> */}
              Сформировать отчет для акций
            </div>
            <div className={classes.settingsReportItem} onClick={openModalExportToExcel}>Выгрузить визуальную часть таблицы</div>
            <ExcelDialog loader={setLoader}
            setShowSnackbar={setShowSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            isOpenExcel={isOpenExcel}
            closeModalExportToExcel={closeModalExportToExcel}
            type={'calc_sale'} filterList={currentFilter} dataFetch={props.dataFetch} colsFetch={props.columnFetch}/>
          </div>
        </Menu>
      </div>
    </div>
  )
}

export default PriceDropToolbar;
