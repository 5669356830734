import React from 'react'
import Main from '../../components/layout/main';

const ProfileOrganization = () => {
  return (
    <Main>
      <div>ProfileOrganization</div>
    </Main>
  )
}

export default ProfileOrganization;
