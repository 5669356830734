import React, {useEffect} from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { Button, createStyles, Dialog, makeStyles } from '@material-ui/core';

interface Props {
  show: boolean;
  title?: string;
  children?: any;
  closeEvent: () => void;
  successEvent: () => void;
  showSuccessBtn?: true | boolean;
  showCancelBtn?: true | boolean;
  successText?: string;
  cancelText?: string;
  disabledSuccessBtn?: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      '& .MuiDialog-paperWidthSm': {
        width: '100%',
        padding: 32,
        borderRadius: 8,
      }
    },
    modalHeader: {},
    modalTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '23px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: '0 0 20px 0',
    },
    modalClose: {
      position: 'absolute',
      top: 32,
      right: 32,
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',

      '& svg': {
        width: 24,
        height: 24,
      }
    },
    modalInfoText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#000000',
      margin: 0,
      marginTop: 32,
      marginBottom: 32,
    },
    modalContent: {},
    modalFooter: {},
    modalSuccessBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'none',
      padding: '12px 16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      marginRight: 16,

      '&:hover': {
        background: '#827F75',
      },
      '&:disabled': {
        background: '#D3D1CB',
        cursor: 'default',
        color: '#fff',
      },
    },
    modalCancelBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'none',
      padding: '12px 16px',
      color: '#37352F',
      border: '1px solid #37352F',
      borderRadius: 8,

      '&:hover': {
        color: '#827F75',
        background: '#fff',
        border: '1px solid #827F75',
      },
    },
  }),
);

const CustomModal = (props: Props) => {
  const classes = useStyles();

  const handleClose = () => {
    props.closeEvent();
  };

  return (
    <Dialog
      className={classes.modal}
      onClose={handleClose}
      open={props.show}
    >
      <div className={classes.modalHeader}>
        <h2 className={classes.modalTitle}>{props.title}</h2>
        <button className={classes.modalClose} onClick={handleClose}>
          <CloseIcon width={40}/>
        </button>
      </div>
      <div className={classes.modalContent}>
        {props.children}
      </div>
      <div className={classes.modalFooter}>
        {(typeof props.showSuccessBtn === 'undefined' || props.showSuccessBtn) && (
          <Button
            className={classes.modalSuccessBtn}
            onClick={() => props.successEvent()}
            disabled={props.disabledSuccessBtn}
          >
            {props.successText}
          </Button>
        )}
        {(typeof props.showCancelBtn === 'undefined' || props.showCancelBtn) && (
          <Button
            variant="outlined"
            className={classes.modalCancelBtn}
            onClick={() => props.closeEvent()}
          >
            {props.cancelText}
          </Button>
        )}
      </div>
    </Dialog>
  )
}

export default CustomModal
