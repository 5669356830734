import React from 'react';
import moment from 'moment';
import { ClockIcon, InfoIcon } from '../../components/UI/Icons';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles: any = makeStyles(() =>
  createStyles({
    needUpdate: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FF6C4B',
      display: 'inline-flex',
      alignItems: 'center',
    },
  }),
);

interface Props {
  item: any
};

const ActualLeftovers = (props: Props) => {
  const classes = useStyles();

  const differenceDates = () => {
    const regex = /([0-9]+):([0-9]+)/;
    const a: any = moment().startOf('day');
    const b: any = moment(props.item.lastDateUpload.replace(regex, '00:00').split('00:00')[0].trim());

    return Math.round(moment.duration(a - b).asDays()) * -1 >= 0;
  };


  const checkActual = () => {
    // console.log()
    switch (props.item.typeCheck) {
      case 'custom':
        if (props.item.lastDateUpload) {
          // const days = differenceDates();
          return props.item.isActual;
        }
        break;
      case 'standard':
        if (props.item.lastDateUpload) {
          const days = differenceDates();
          if (days) return true;
        }
        break;
      default: return false;
    }
  };


  return (
    <>
    {props.item && props.item.isItActual ?
        <>
            <ClockIcon />Остатки актуальны {props.item.lastDateUpload ? `, загружен ${moment(props.item.lastDateUpload).format('LLL')}` : null}
        </>
        : <span className={classes.needUpdate}><InfoIcon /> Необходимо обновить остатки</span>
      }
    </>
  );
};

export default ActualLeftovers;