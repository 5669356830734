import {Button, createStyles, FormControlLabel, Input, InputAdornment, makeStyles, TextField,} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { ChangeEvent, useEffect, useState } from "react";
import Search from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { adminGetRoles, adminGetRolesActive, adminRoleOrgs, adminRoleUsers, adminSetRoles } from '../../api/admin'
import Checkbox from '@material-ui/core/Checkbox';
import CustomSnackbar from "../../components/UI/CustomSnackbar";
import Spinner from "../../components/UI/Spinner";
import { useRoles } from "./store";
import {Upload} from "../../components/UI/Icons";
import CustomModal from "../../components/UI/CustomModal";


const useStyles: any = makeStyles(() =>
  createStyles({
    text: {
      fontSize: 14,
      margin: '0 0 10px 20px',
    },
    wrapper: {
      padding: '50px 70px 0px 35px'
    },
    input: {
      '& .MuiInput-underline:after': {
        borderBottom: '2px solid black',
      },
      '& .MuiAutocomplete-inputFocused': {
        width: '100%',
      },
      display: 'flex',
      padding: '10px 15px',
      background: '#E9E9E7',
      alignItems: 'center',
      borderRadius: 10
    },
    tabl: {
      '& .MuiTableCell-sizeSmall': {
        border: '1px solid #F2F2F0',
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
      },
      '& .MuiCheckbox-colorSecondary.Mui-checked' : {
        color: 'black'
      }
    },
    compareModalSaveBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      textTransform: 'capitalize',
      padding: '12px 16px',
      marginRight: 16,
      borderRadius: 8,
      height: 50,
      marginBottom: 1,
      marginLeft: 15,
      '&:hover': {
        background: '#827F75 !important',
      },
    },
    modalTitle: {
      marginBottom: 20
    }
  })
);

interface IOrgs {
  organization_name: string;
  uid: string;
  user_mobile: string;
}

interface IUsers {
  organization_name: string;
  users_uid: string;
  concat: string;
  organizations_uid: string;
  user_name: string
}

interface IRoles {
  role_name: string;
  uid: string;
}

interface IRolesActive {
  role_name: string;
  uid: string;
  active: number;
  edit: number;
}


const Role = () => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false)
    const [orgs, setOrgs] = useState<IOrgs[] | []>([])
    const [usersOrg, setUsersOrg] = useState<IUsers[] | []>([])
    const [uidOrg, setUidOrg] = useState<string>('')
    const [uidUser, setUidUser] = useState<string>('')
    const [loader, setLoader] = useState<boolean>(false)
    const [toggle, setToggle] = useState<boolean>(false)
    const [tel, setTel] = useState('')
    const dataStore = useRoles()

    const [roles, setRoles] = useState<IRoles[] | []>([])
    const [showFBSModal, setShowFBSModal] = useState<boolean>(false)
    const [rolesActive, setRolesActive] = useState<IRolesActive[] | []>([])

    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);



    const getOrgs = async() => {
      const result = await adminRoleOrgs({})
      const filter = result.data.org_org_admin.sort((a,b) => a.user_mobile - b.user_mobile)
      setOrgs(filter);
    }

    const getUsersOrg = async(uid: string) => {
      const result = await adminRoleUsers({organization_uid: uid})
      setUsersOrg(result.data.org_user_admin);
      setUidUser(result.data.org_user_admin[0].users_uid)
      setShowFBSModal(false)
    }

    const getRoles = async() => {
      const result = await adminGetRoles({})
      setRoles(result.data.roles);
    }

    const getRolesActive = async(uidOrg: string, uidUser: string) => {
      setLoader(true)
      const result = await adminGetRolesActive({organization_uid: uidOrg, uid_user: uidUser})
      setRolesActive(result.data.active_roles);
      if(result) setLoader(false)
    }

    useEffect(() => {
      getOrgs()
      getRoles()
    }, []);




    useEffect(() => {
      if(uidOrg) getUsersOrg(uidOrg)
    }, [uidOrg]);

    useEffect(() => {
      if(usersOrg.length > 0) {
        const filter = usersOrg.filter(el => el.concat.replace('+', '') === tel)
        if(filter.length > 0) {
          dataStore.getUserOrg(filter[0])
          setUidUser(filter[0].users_uid);
        }
      }
    }, [usersOrg, tel]);



    useEffect(() => {
      if(uidUser) getRolesActive(uidOrg, uidUser)
    }, [uidOrg, uidUser, toggle]);


    const setRolesRes = async(el) => {
      setLoader(true)
      const name = el.role_name
      const filter = rolesActive.filter(el => el.role_name === name)
      let active = 0
      if(filter[0].active === 0) active = 1
      await adminSetRoles({
        active: active,
        uid_organization: uidOrg,
        uid_roles: el.uid,
        uid_user: uidUser,
      })
      .then(res => {
        if(!res.data.success) {
          showMessage(res.data.description.reason)
        }
        else {
          setToggle(true)
          showMessage('Успешно')
        }
      })
      .catch(err => {
        showMessage('Ошибка')
        console.log(err)
      })
      .finally(() => {
        setToggle(false)
        setLoader(false)})
    }

    const showMessage = (msg: string) => {
      if (!msg?.length) return;
      setSnackbarMessage(msg);
      setShowSnackbar(true);
    };


    useEffect(() => {
      if(!dataStore.org) {
        dataStore.getUserOrg(null)
        setUsersOrg([])
      }
    }, [dataStore.org]);

    useEffect(() => {
      if(dataStore.org) {
        setUidOrg(dataStore.org.uid);
      }
    }, []);

    const handleChange = (e, newValue) => {
      dataStore.getOrg(newValue)
      setUidOrg(newValue.uid);
      setUidUser('')
      setRolesActive([])
      dataStore.getUserOrg(null)
      setTel(newValue.user_mobile)
    }

    const handleChange1 = (e, newValue) => {
        dataStore.getUserOrg(newValue)
        setUidUser(newValue.users_uid);
    }

    const check = (el) => {
      if(rolesActive.length > 0) {
      const filter: any = rolesActive.filter(item => item.role_name === el.role_name)
      if(filter[0].active) {
        return true
      }
      else return false
      }
      else return false
    }

    return (
        <div>
            <Spinner open={loader} />
            <div className={classes.wrapper}>
              <CustomSnackbar
                  open={showSnackbar}
                  message={snackbarMessage}
                  handleClose={() => setShowSnackbar(false)}
                />
                <div style={{display: 'flex', marginBottom: 50}}>
                    <div>
                      <div className={classes.text}>Организация</div>
                      <div className={classes.input}>
                        <Search color="disabled" style={{fontSize: 30, marginRight: 7}}/>

                        <Autocomplete
                          options={orgs}
                          getOptionLabel={(option) => `+${option.user_mobile} ${option.organization_name ? `(${option.organization_name})`: null}`}
                          value={dataStore.org}
                          style={{ width: 350 }}
                          onChange={handleChange}
                          renderInput={(params) => <TextField {...params} placeholder="Введите параметр для поиска ..." />}
                        />
                      </div>

                    </div>
                    <div style={{marginLeft: 60, marginRight: 40}}>
                      <div className={classes.text}>Пользователь</div>
                      <div className={classes.input}>
                      <Search color="disabled" style={{fontSize: 30, marginRight: 7}}/>
                      <Autocomplete
                          options={usersOrg}
                          getOptionLabel={(option) => `${option.concat} ${option.user_name ?`(${option.user_name})` : ''}`}
                          value={dataStore.userOrg}
                          style={{ width: 350 }}
                          onChange={handleChange1}
                          renderInput={(params) => <TextField {...params} placeholder="Введите параметр для поиска ..." />}
                        />
                      </div>
                    </div>
                  <CustomModal
                    show={showFBSModal}
                    title="Подтверждение действий"
                    closeEvent={() => setShowFBSModal(false)}
                    successEvent={() => getUsersOrg(uidOrg)}
                    successText="Очистить организацию"
                    cancelText="Отмена"
                  >
                  </CustomModal>

                  {/*<Button*/}
                  {/*  className={classes.compareModalSaveBtn}*/}
                  {/*  onClick={() => setShowFBSModal(true)}*/}
                  {/*>*/}
                  {/*  Очистить организацию*/}
                  {/*</Button>*/}
                </div>
                {roles &&
                <>
                  <div style={{marginBottom: 50}}>
                  <TableContainer component={Paper}>
                    <Table className={classes.tabl} size="small" aria-label="a dense table">
                      <TableHead >
                        <TableRow >
                          <TableCell style={{background: '#F2F2F0', width: 180}}>Пользовательские роли</TableCell>
                          <TableCell style={{background: '#F2F2F0', width: 1300}}></TableCell>
                          <TableCell style={{background: '#F2F2F0'}} align="center">Включить</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          {roles.map(el => (
                            el.type === 1 &&
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {el.role_name}
                              </TableCell>
                              <TableCell >{el.role_description}</TableCell>
                              <TableCell align="center">
                                <Checkbox checked={check(el)} onChange={() => setRolesRes(el)} disabled={uidOrg && uidUser ? false : true}/>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </div>

                  <div style={{marginBottom: 50}}>
                  <TableContainer component={Paper}>
                    <Table className={classes.tabl} size="small" aria-label="a dense table">
                      <TableHead >
                        <TableRow >
                          <TableCell style={{background: '#F2F2F0', width: 180}}>Административные роли</TableCell>
                          <TableCell style={{background: '#F2F2F0', width: 1300}}></TableCell>
                          <TableCell style={{background: '#F2F2F0'}} align="center">Включить</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          {roles.map(el => (
                            el.type === 2 &&
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {el.role_name}
                              </TableCell>
                              <TableCell>{el.role_description}</TableCell>
                              <TableCell align="center">
                                <Checkbox checked={check(el)} onChange={() => setRolesRes(el)} disabled={uidOrg && uidUser ? false : true}/>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </div>
                </>
                }
            </div>
        </div>
     );
}

export default Role;
