import { makeStyles, createStyles } from '@material-ui/core'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getOrganizations } from '../../api/organizations'
import Main from '../../components/layout/Main'
import { Card, Message, RubCircle, UserProfile } from '../../components/UI/Icons'
import RightArrow from '../../components/UI/Icons/rightArrow'
import DocumentSvg from '../../assets/icons/documents.svg'
import AdminRole from '../../components/UI/Icons/adminRole'

const useStyles = makeStyles(() => 
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 680,
      padding: 32,
    },
    adminItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: 16,
      paddingBottom: 16,
      borderBottom: '1px solid #e9e9e7',
      cursor: 'pointer',
    },
    adminContent: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    adminContentInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 8,
    },
    adminContentTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      marginBottom: 8,
    },
    adminContentText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
    },
    adminItemBtn: {
      // cursor: 'pointer',
    },
  }),
);

const AdminPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useQuery<Array<any>>('organs', async () => {
    const result = await getOrganizations();

    document.cookie = `org_uid=${result?.data?.data?.[0]?.org_uid};Path=/;`;
    return result.data.data;
  });


  const toTariffs = () => {
    navigate('/admin/tariffs');
  }
  const toUsers = () => {
    navigate('/admin/users');
  };
  const toWBData = () => {
    navigate('/admin/wb-data');
  };
  const toJuristicDocuments = () => {
    navigate('/admin/jur-documents')
  }
  const toRole = () => {
    navigate('/admin/role')
  }

  return (
    <div className={classes.content}>
      <div className={classes.adminItem} onClick={() => toTariffs()}>
        <div className={classes.adminContent}>
          <Card />
          <div className={classes.adminContentInfo}>
            <span className={classes.adminContentTitle}>Тарифы</span> 
            <span className={classes.adminContentText}>Создание тарифной сетки и настройка тарифов</span>
          </div>
        </div>
        <span className={classes.adminItemBtn}>
          <RightArrow />
        </span>
      </div>
      <div className={classes.adminItem} onClick={() => toUsers()}>
        <div className={classes.adminContent}>
          <UserProfile />
          <div className={classes.adminContentInfo}>
            <span className={classes.adminContentTitle}>Пользователи</span> 
            <span className={classes.adminContentText}>Список запросов на активацию пользователей и их статус</span>
          </div>
        </div>
        <span className={classes.adminItemBtn}>
          <RightArrow />
        </span>
      </div>
      <div className={classes.adminItem} onClick={() => toRole()}>
        <div className={classes.adminContent}>
          <AdminRole />
          <div className={classes.adminContentInfo}>
            <span className={classes.adminContentTitle}>Роли</span> 
            <span className={classes.adminContentText}>Управление ролями</span>
          </div>
        </div>
        <span className={classes.adminItemBtn}>
          <RightArrow />
        </span>
      </div>
      {/* <div className={classes.adminItem} onClick={() => toWBData()}>
        <div className={classes.adminContent}>
          <RubCircle />
          <div className={classes.adminContentInfo}>
            <span className={classes.adminContentTitle}>Общие данные из WB</span> 
            <span className={classes.adminContentText}>Данные по комиссиям, расценкам на логистику и условиям хранения</span>
          </div>
        </div>
        <span className={classes.adminItemBtn}>
          <RightArrow />
        </span>
      </div> */}
      <div className={classes.adminItem} onClick={() => toJuristicDocuments()}>
        <div className={classes.adminContent}>
          <img src={DocumentSvg} alt="doc" />
          <div className={classes.adminContentInfo}>
            <span className={classes.adminContentTitle}>Загрузка юр. документов</span> 
            <span className={classes.adminContentText}>Загрузка документов: оферта (лицензионное соглашение), политика <br/>
              конфиденциальности, политика обработки персональных данных</span>
          </div>
        </div>
        <span className={classes.adminItemBtn}>
          <RightArrow />
        </span>
      </div>
    </div>
  )
}

export default AdminPage