import { Button, Checkbox, createStyles, FormControlLabel, IconButton, makeStyles, Menu, MenuItem, Switch, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames'
import cookie from 'cookie';
import moment from 'moment';

import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query';
import { clearFilter, deleteColum, delStock, getBell, getDetailData, oldRep, setColum } from '../../api/reports'
import CustomSnackbar from '../UI/CustomSnackbar';
import { Bell, ClockIcon, ErrorIcon, InfoIcon, MoreIcon, MoreVerticalIcon, Plus, Search, Upload } from '../UI/Icons'
import { SetDataModal } from './SetDataModal';
import NotificationModal from './NotificationModal';
import { ToolbarUpload } from './ToolbarUpload';
import { FilterMenu } from './FilterMenu';
import CustomModal from '../UI/CustomModal';
import { Check } from '@material-ui/icons';
import { useSearchParams } from 'react-router-dom';
import NotificationModalSizePage from './NotificationModalSizePage';
import { dateilDate, dateTrunc, getOrganizations, updateOrgStorageType } from '../../api/organizations';
import Spinner from '../UI/Spinner';
import SettingsMenu from '../UI/SettingsMenu';
import FreeTariffSnackbar from '../UI/FreeTariffSnackbar';
import {ExcelDialog} from "../ManagePrices/ExcelDialogComponent/ExcelDialog";
import { excelDownloadAll, registerDuplicatesBKT } from '../../api/calc';
import fileDownload from '../../common/file-download';
import DoublesModal from '../Doubles/DoublesModal';

const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    toolbarCount: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginRight: 10
    },
    toolbarBtns: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    buttonXlsx: {
      padding: 8,
      border: 'none',
      font: 'inherit',
      color: 'inherit',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    toolbarSearchBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      // textDecoration: 'underline',
      cursor: 'pointer',
      marginRight: 16,

      '& svg': {
        stroke: '#37352F',
      },
      '&:hover svg': {
        stroke: '#827F75',
      },
      '&:hover': {
        color: '#827F75',
      },
    },
    toolbarSearchBtnText: {
      marginLeft: 4,
      borderBottom: '1px solid #000',
      paddingBottom: 1,

      '&:hover': {
        borderBottom: '1px solid #827F75',
      }
    },
    toolbarSearch: {
      border: 'none',
      outline: 'none',
      borderBottom: '1px solid #37352F',
      marginLeft: 8,
      marginBottom: 2,
      width: 222,
      transition: 'all .3s ease-out',
      paddingBottom: 1,
      cursor: 'pointer',

      // '&:focus': {
      //   width: 222,
      //   cursor: 'auto',
      // },
      '&::placeholder': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#37352F',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        // textDecoration: 'underline',
        cursor: 'pointer',
        transition: 'all .2s ease-out',
      },
      '&.focus': {
        '&::placeholder': {
          fontFamily: '"Work Sans", sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px',
          color: '#827F75',
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none',
          // textDecoration: 'underline',
        },
      },
    },
    toolbarFilter: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 16,
      '& .MuiButtonBase-root': {
        padding: 0,
        marginRight: 9,
      },
    },
    toolbarFilterBtn: {
      position: 'relative',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '14px',
      color: '#37352F',
      borderBottom: '1px solid #000',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:hover': {
        borderBottom: '1px solid #827F75',
        color: '#827F75',
      },
    },
    settingChip: {
      position: 'absolute',
      top: -10, right: -10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '12px',
      width: 14,
      height: 14,
      color: 'white',
      background: '#FF6C4B',
      borderRadius: '50%',
    },
    toolbarAddBtn: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'capitalize',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      padding: '8px 16px 8px 10px',
      cursor: 'pointer',

      '& svg': {
        stroke: '#ffffff',
      },

      '&:hover': {
        background: '#827F75',
      },
    },
    toolbarIconBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 40,
      marginRight: 16,
      cursor: 'pointer',
    },
    settingsMenu: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 16,
        top: '126px !important',
        maxHeight: '70vh',
      },
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    settingsContet: {
      padding: 16,
      maxWidth: 330,
      width: '100vh',
    },
    settingsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,

      '& svg': {
        width: 20,
        height: 20,
      }
    },
    settingsTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    settingsList: {

    },
    settingsItem: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      cursor: 'grab !important',
    },
    settingsItemContent: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      padding: '6px 0',
      borderTop: '1px solid #fff',
      transition: 'all 0.1s ease-in-out',

      '& svg': {
        marginRight: 4,
        minWidth: 16,
      },
    },
    settingsItemSwitch: {
      width: 35,
      height: 18,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#000',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: '#ccc',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 14,
        height: 14,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: 'all .5s ease-in-out',
      },
    },
    alert: {
      background: 'rgba(255, 108, 75, 0.1)',
      borderRadius: 8,
      padding: '8px 16px 8px 24px',
      marginBottom: 16,

      '& .MuiAlert-message': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
    alertText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      display: 'flex',
      alignItems: 'center',

      '& span': {
        paddingBottom: 2,
        borderBottom: '1px solid #37352F',
        marginLeft: 4,
        marginTop: 3,
        cursor: 'pointer',
      },
      '& svg': {
        marginRight: 8,
      },
    },
    alertBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      textTransform: 'none',
      padding: '0 16px',
      height: 40,
      background: '#37352F',
      borderRadius: 8,

      '&:hover': {
        background: '#827F75',
      },
    },
    detailsContent: {
      paddingBottom: 32,
    },
    detailsText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginTop: 32,
      marginBottom: 16,
    },
    details: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      minWidth: 112,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 4,

      '& .MuiSvgIcon-root': {
        width: 16, height: 16
      },
      '& .check': {
        fill: '#37352F',
      },
      '& span': {
        whiteSpace: 'nowrap',
      },
    },
    detailsFooter: {},
    modalCancelBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'none',
      padding: '12px 16px',
      color: '#37352F',
      border: '1px solid #37352F',
      borderRadius: 8,
      marginLeft: 8,

      '&:hover': {
        color: '#827F75',
        background: '#fff',
        border: '1px solid #827F75',
      },
    },
    settingsCancelBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 8,
      paddingBottom: 8,
    },
    settingsCancel: {
      cursor: 'pointer',
      borderBottom: '1px solid #37352F',
    },
    warnSearch: {
      borderBottom: '1px solid #fc0101!important',
      color: '#fc0101!important'
    },
  })
)

function useFocus(): [any, any] {
  const htmlElRef = useRef(null)
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

  return [htmlElRef, setFocus]
}

interface Props {
  namePage?: string;
  primary?: string;
  primaryDataUploadUid?: string;
  newPrimaryDataUploadUid: (val) => void;
  cols?: any[];
  count?: number;
  showAlert?: boolean | null;
  filterList: any;
  getOldId: (id: string | undefined) => void;
  newColsReq: (cols: any[]) => void;
  newCols?: (cols: any[]) => void;
  getNewVersion: () => void;
  sendFilters: (filter: any) => void;
  searchEl: (val: string) => void;
  refetch?: () => void;
  setFilterLoaded?: (loaded: boolean) => void;
  showEmptyData: (val: boolean) => void;
  setPage: any;
  search: any;
  getSearch: any;
  loader: boolean
  newFilter: []
  dataFetch: any
  colsFetch: any
};

const LoadDataToolbar = (props: Props) => {
  moment.locale('ru');
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loaded, setLoaded] = React.useState(false);
  const [count, setCount] = React.useState(0);
  // const [open, setOpen] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [openFilters, setOpenFilters] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [cols, setCols] = React.useState<null | any>(null);

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [periodNotFound, setPeriodNotFound] = React.useState(false);
  const [weeksList, setWeekList] = React.useState<any>(null);
  const [detailsUid, setDetailsUid] = React.useState<string | null>();
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [settingsCount, setSettingsCount] = React.useState(0);
  const [searchRef, setSearchFocus] = useFocus();
  const [showEmptyData, setShowEmptyData] = React.useState(false);
  const [oldUid, setOldUid] = React.useState();
  const [oldDate, setOldDate] = React.useState();
  const [openFreeTariffSnackbar, setOpenFreeTariffSnackbar] = React.useState(false);
  const [openDoubles, setOpenDoubles] = React.useState(false);
  const [search, setSearch] = React.useState('')
  const [searchWarn, setSearchWarn] = useState(false)
  const [searchIsSended, setSearchIsSended] = useState(false)
  const [isOpenExcel, setIsOpenExcel] = useState(false)
  const [currentFilter, setCurrentFilter] = useState([])
  const [typeRep, setTypeRep] = useState('')
  const [dataDoubles, setDataDoubles] = useState([])
  const [colsDoubles, setColsDoubles] = useState<any>()

  const checkBox: any = {
    colum: null,
    column_caption: "Выбор",
    column_edit: "False",
    column_filter: "True",
    column_name: "check",
    column_type: "bool",
    showed: true,
    width: 200,
    sort: null
  }

  useEffect(() => {
    if(props.namePage === 'Параметры бренд/категория/товар') setTypeRep('brand_category_product')
    else if(props.namePage === 'Текущие акции') setTypeRep('stocks')
    else if(props.namePage === 'Еженедельная динамика продаж') setTypeRep('current_sales_week')
  }, []);

  useEffect(() => {
    setCols(props.cols)

    if(props.cols) {
    const filter = props.cols.filter(el => el.showed)
    checkBox.sort = filter.length
    filter.push(checkBox)
    setColsDoubles(filter)
    }

    let count = 0;
    if (props.cols) {
      props.cols.forEach((item: any) => {
        if (!item.showed) {
          count += 1;
        }
      });
    }
    setSettingsCount(count);

    if (loaded) return;
    if (
      (searchParams.get("id") === '7e8d9639-2419-47cd-aca1-0c8829528622')) {
      // || searchParams.get("id") === '94e066eb-3b01-4916-993c-73f06978145b')
      setDetailsUid('7e8d9639-2419-47cd-aca1-0c8829528622');
      setShowDetailsModal(true);
    }
    setLoaded(true);
  }, [props.cols]);


  const getLastWeek = (i) => {
    let weekOfDay = parseInt(moment().format('E'));
    let last_monday = moment().subtract(weekOfDay + 7 * i - 1, 'days').format('DD.MM');
    let last_sunday = moment().subtract(weekOfDay + 7 * (i - 1), 'days').format('DD.MM');
    return `${last_monday}-${last_sunday}`;
  }

  const details = useQuery('details', async () => {
    setLoader(true);
    const weeks = [
      { weekNum: 1, name: '- 1 неделя', keyWeek: 'week_1', keyThisWeek: 'this_week_1' },
      { weekNum: 2, name: '- 2 неделя', keyWeek: 'week_2', keyThisWeek: 'this_week_2' },
      { weekNum: 3, name: '- 3 неделя', keyWeek: 'week_3', keyThisWeek: 'this_week_3' },
      { weekNum: 4, name: '- 4 неделя', keyWeek: 'week_4', keyThisWeek: 'this_week_4' },
      { weekNum: 5, name: '- 5 неделя', keyWeek: 'week_5', keyThisWeek: 'this_week_5' },
      { weekNum: 6, name: '- 6 неделя', keyWeek: 'week_6', keyThisWeek: 'this_week_6' },
      { weekNum: 7, name: '- 7 неделя', keyWeek: 'week_7', keyThisWeek: 'this_week_7' },
      { weekNum: 8, name: '- 8 неделя', keyWeek: 'week_8', keyThisWeek: 'this_week_8' },
      { weekNum: 9, name: '- 9 неделя', keyWeek: 'week_9', keyThisWeek: 'this_week_9' },
      { weekNum: 10, name: '- 10 неделя', keyWeek: 'week_10', keyThisWeek: 'this_week_10' },
      { weekNum: 11, name: '- 11 неделя', keyWeek: 'week_11', keyThisWeek: 'this_week_11' }
    ];
    setWeekList(null);
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const copyWeeksList = JSON.parse(JSON.stringify(weeks));
      const dates = await dateTrunc(uid);

      let checkNotFound = false;

      if (dates?.data?.data) {
        copyWeeksList.forEach((item: any, i: number) => {
          item.dateRange = getDateRow(dates.data.data[i].date_1, dates.data.data[i].date_2);
          item.val = dates.data.data[i].val;

          if (!checkNotFound) {
            checkNotFound = !dates.data.data[i].val ? true : false;
            setPeriodNotFound(checkNotFound);
          }
          item.checkNotFound = checkNotFound;
        });
      }

      setWeekList(copyWeeksList);
      return dates.data.data || [];
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  });

  const getDateRow = (d1, d2) => {
    return `${moment(d1).format('DD.MM')}-${moment(d2).format('DD.MM')}`;
  };

  const notify = useQuery(['notify', openNotification], async (openNotification: any) => {
    try {
      if (openNotification.queryKey[1]) {
        setLoader(true);
        const uid = await cookie.parse(document.cookie)['org_uid'];

        let result: any = null;
        result = await getBell({
          primary: props.primary || '',
          history: props.primaryDataUploadUid || '',
          org: uid
        });
        setLoader(false);
        return result?.data;
      }
    } catch (error) {
      if (error.status === 400) {
        setOpenNotification(true)
      } else {
        console.error("Error occurred:", error);
      }
      setLoader(false);
      setOpenNotification(true)
    }
  });


  const storageType = useQuery('storageType', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await updateOrgStorageType({ org: uid, action: 'show' });
    return result.data.data;
  });

  const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSettings(true);
  };
  const handleCloseSettings = () => {
    setShowSettings(false);
    if (props.newColsReq) props.newColsReq(cols);
  };
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl2);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl2(null);
  };

  const noCurrentStock = async () => {
    setLoader(true)
    const uid = await cookie.parse(document.cookie)['org_uid'];

    const res = await deleteColum({
      org: uid,
      primary: '960d6482-dc35-4834-871a-1ee8a26f1d02'
    });

    const result = await delStock({
      org: uid
    })



    if (!result.data.success) {
      setShowSnackbar(true);
      setSnackbarMessage(result.data.description[0].reason);
      setTimeout(() => {
        window.location.href = '/load-data';
      }, 3000)

      return;
    }
    if (result.data.success) {
      window.location.href = '/load-data';
    }
    handleClose();
    // if (props.refetch) props.refetch()
  };
  const viewOldVersion = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await oldRep({
      org: uid,
      rest: false,
      primary: props.primaryDataUploadUid || ''
    });
    setOldUid(result.data.data);
    setOldDate(result.data.date_upload);
    props.getOldId(result.data.data);
    handleClose();
    setLoader(false)
  };

  const restCurrentVersion = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await oldRep({
      org: uid,
      rest: true,
      primary: oldUid || props.primaryDataUploadUid || ''
    });
    if (result.data.success && !result.data.data) {
      document.location.reload();
      return
    }
    props.getOldId(oldUid);
  };

  const [overedItem, setOveredItem] = React.useState<any>();
  const [currentItem, setCurrentItem] = React.useState<any>();
  const [currentItemIdx, setCurrentItemIdx] = React.useState<number>(-1);

  const onDragStartEvent = (e: any, item: any, index: number) => {
    setCurrentItem(item);
    setCurrentItemIdx(index);
  };
  const onDragOverEvent = (e: any, item: any, index: number) => {
    e.preventDefault();
    if (e.target.className.includes('settingsItemContent')) {
      e.target.style.borderTop = '10px solid rgba(255, 108, 75, 0.2)';
    }
    setOveredItem(item);
  };
  const onDragLeaveEvent = (e: any) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
    setOveredItem(null);
  };
  const onDragEndEvent = (e: any) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDropEvent = (e: any, overedItemIdx: number) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
    let colsCopy = JSON.parse(JSON.stringify(cols));
    colsCopy.splice(currentItemIdx, 1);
    colsCopy.splice(overedItemIdx, 0, currentItem);
    setCols(colsCopy);
    if (props.newCols) props.newCols(colsCopy);
  };
  const onChangeCity = async (index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyCols = JSON.parse(JSON.stringify(cols));
    let curEl: any = null;

    copyCols[index].showed = !copyCols[index].showed;
    curEl = copyCols[index];
    setCols(copyCols);

    if (curEl) {
      await setColum({
        colum: curEl.column_name,
        organization_uid: uid,
        calc: searchParams.get("id") || '',
        del: curEl.showed,
      });
    }

    let count = 0;
    copyCols.forEach((item: any) => {
      if (!item.showed) {
        count += 1;
      }
    });

    if (props.newColsReq) props.newColsReq(copyCols);
    if (props.newCols) props.newCols(copyCols);
    setSettingsCount(count);
  };

  const cancelSettings = async () => {
    let copyCols = JSON.parse(JSON.stringify(cols));
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await clearFilter({
      organization_uid: uid,
      primary_data: searchParams.get("id") || '',
      type: 1
    });
    await clearFilter({
      organization_uid: uid,
      primary_data: searchParams.get("id") || '',
      type: 2
    });

    copyCols.forEach((item: any) => item.showed = true);
    setCols(copyCols);

    refechList();
  };

  const cancelFilter = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await clearFilter({
      organization_uid: uid,
      primary_data: searchParams.get("id") || '',
      type: 3
    });

    if (result) refechList();
  };

  const refechList = () => {
    if (props.refetch) props.refetch();
  };

  const closeSetDataModal = () => {
    refechList();
    setOpenFilters(false)
  };

  const hideDetailsModal = () => {
    setShowDetailsModal(false);
  };

  const handleShowEmpty = () => {
    props.showEmptyData(!showEmptyData);
    setShowEmptyData(!showEmptyData);
  };

  const updateList = () => {
    if (props.refetch)
      props.refetch();
  };
  const newPrimaryDataUploadUid = (val: number) => {
    props.newPrimaryDataUploadUid(val);
    details.refetch();
  };

  const doRefetch = () => {
    notify.refetch();
    updateList();
  }

  const sendColumn = (cols: any) => {
    props.newColsReq(cols);
    if (props.newCols) props.newCols(cols);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      props.setPage(1)
      setSearchIsSended(true)
      props.searchEl(e.target.value)

    }
  };
  function onBlurSearch(value: string) {

    if (value?.trim() !== "") {
      if (!searchIsSended) setSearchWarn(true)
      else setSearchWarn(false)
    }
    else setSearchWarn(false)
  }
  const onChangeSearch = (e) => {
    setSearchIsSended(false)
    setSearch(e)
    if (e.length === 0) {
      const Debounce = setTimeout(() => {
        props.setPage(1)
        props.searchEl('')
      }, 500)
      return () => clearTimeout(Debounce)
    }
  }

  const URL_XLSX = `${window.origin}/пример шаблона для загрузки БКТ.xlsx`

  const downloadFile = (url) => {
    const fileName = url.split('/').pop()
    const aTag = document.createElement('a')
    aTag.href = url
    aTag.setAttribute('download', fileName)
    document.body.appendChild(aTag)
    aTag.click()
    aTag.remove()
  }

  const downloadRegisterDuplicates = async() => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const excel = await excelDownloadAll({
        org: uid,
        xls: "xls"
      });
    const res = await getOrganizations()
    const nameOrg = res.data.data[0].org_name
    const date = moment().format('DD.MM.YYYY')
    if(nameOrg) {
      fileDownload(excel.data.data, `Реестр дублей ${nameOrg.replace(/['"«»]/g, '')} ${date}.xlsx`);
    }
    else {
      fileDownload(excel.data.data, `Реестр дублей организация без названия ${date}.xlsx`);
    }
    handleClose()
  }

  const checkDuplicates = async() => {
    setOpenDoubles(true)
  }

  const getCheck = async() => {
    setLoader(true)
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await registerDuplicatesBKT(uid)
    .then(res => {
      const datas = res.data.data.map(el => {
        return {...el, check: false}
      })
      setDataDoubles(datas)
    })
    .catch((err) => console.log(err))
    .finally(() => setLoader(false))
  }

  useEffect(() => {
    const check = localStorage.getItem('checkModalDouble')
    if(check === 'true') {
        setOpenDoubles(true)
    }
    getCheck()
  }, []);

  const openModalExportToExcel = () => {
    setIsOpenExcel(true)
  }

  const closeModalExportToExcel = () => {
    setIsOpenExcel(false)
  }

  const [btnCount, setBtnCount] = useState(0)
  // searchParams.get('id')
  // 5fe9a4b9-423d-4bad-bbc9-e15660568081
  useEffect(() => {
    if(searchParams.get('id') === '5fe9a4b9-423d-4bad-bbc9-e15660568081') {
      setBtnCount(90)
    } else {
      setBtnCount(45)
    }
  }, [searchParams.get('id')])


  const notifyNull = {}
  return (
    <div>
      <Spinner open={loader} />
      <FreeTariffSnackbar
        open={openFreeTariffSnackbar}
        closeEvent={() => setOpenFreeTariffSnackbar(false)}
      />
      <DoublesModal
        open={openDoubles}
        cols={colsDoubles}
        closeEvent={() => setOpenDoubles(false)}
      />
      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />
      <CustomModal
        show={(periodNotFound && showDetailsModal && details && details.data)}
        title="Отсутствуют данные за период"
        closeEvent={() => hideDetailsModal()}
        successEvent={() => hideDetailsModal()}
        successText="Загрузить"
        cancelText="Отмена"
        showSuccessBtn={false}
        showCancelBtn={false}
      >
        <div className={classes.detailsContent}>
          <p className={classes.detailsText}>
            В отчете по «Детализации» отсутствуют данные за определенные периоды. <br />
            Загрузите данные для корректной работы сервиса.
          </p>

          {weeksList?.map((item: any, index: number) => (
            <Typography
              style={{
                maxWidth: 450,
                width: '100%',
                color: details?.data?.[11]?.[item.keyThisWeek] ? '#FF6C4B' : '#37352F'
              }}
              className={classes.details}
              key={index}
            >
              <span>{item.weekNum} ({item.dateRange})</span>
              <span style={{ width: 226.11 }}>
                {(item.val || details?.data?.[11]?.[item.keyThisWeek]) ? (<>
                  <Check className="check" /> Данные загружены
                </>) : (<>
                  <ErrorIcon /> Необходимо загрузить данные
                </>)}
              </span>
            </Typography>
          ))}
        </div>
        <div className={classes.detailsFooter}>
          <ToolbarUpload
            primaryDataUploadUid={props.primaryDataUploadUid}
            newPrimaryDataUploadUid={(val) => newPrimaryDataUploadUid(val)}
            hideIcon={true}
            typeUid={detailsUid}
            updateList={updateList}
            storageType={(storageType?.data?.length) ? storageType?.data?.[0].type : []}
          />
          <Button
            variant="outlined"
            className={classes.modalCancelBtn}
            onClick={() => setShowDetailsModal(false)}
          >
            Отмена
          </Button>
        </div>
      </CustomModal>
      {props.showAlert && (
        <Alert className={classes.alert} icon={false} severity="error">
          <p className={classes.alertText}><ClockIcon />
            Вы просматриваете старую версию отчета. Смотреть <span onClick={() => props.getNewVersion()}>текущую версию.</span> &nbsp; {moment(oldDate).format('LLL')}
          </p>
          <Button className={classes.alertBtn} onClick={() => restCurrentVersion()}>
            Восстановить эту версию
          </Button>
        </Alert>
      )}
      <div className={classes.toolbar}>
        <p className={classes.toolbarCount}>Всего {props.count}</p>

        <div className={classes.toolbarBtns}>
          {cols?.length
            ? <SettingsMenu
              cols={cols}
              calc={searchParams.get("id") || ''}
              // newColumnPosition={props.newColumnPosition}
              sendColumn={sendColumn}
              refetch={props.refetch}
            />
            : null
          }
          <div className={classes.toolbarSearchBtn} onClick={setSearchFocus}>
            <div onClick={() => {
              props.setPage(1)
              props.searchEl(search)
            }}>
              <Search />
            </div>

            {/* <span className={classes.toolbarSearchBtnText}>Поиск</span> */}
            <input
              type="text"
              placeholder={!openSearch ? 'Поиск' : 'Введите параметр для поиска...'}
              className={classNames(classes.toolbarSearch, searchWarn ? classes.warnSearch : '', openSearch ? 'focus' : '')}
              // onFocus={() => setOpenSearch(true)}
              disabled={props.loader}
              ref={searchRef}
              value={search}
              // onBlur={() => setOpenSearch(false)}
              onChange={(e) => onChangeSearch(e.target.value)}
              onKeyPress={_handleKeyDown}
              onBlur={(e) => {
                onBlurSearch(e.target.value)
              }}
              onFocus={() => { setSearchWarn(false) }}
            />
          </div>
          <div className={classes.toolbarFilter}>
            <FilterMenu
              setCurrentFilter={setCurrentFilter}
              setPage={props.setPage}
              cols={cols || []}
              filterList={props.filterList}
              primaryDataUploadUid={props.primaryDataUploadUid || ''}
              sendFilters={props.sendFilters}
              cancelFilter={cancelFilter}
              setFilterLoaded={
                (loaded: boolean) =>
                  props.setFilterLoaded
                    ? props.setFilterLoaded(loaded)
                    : null
              }
            />
          </div>
          <SetDataModal
            open={openFilters}
            primaryDataUploadUid={props.primaryDataUploadUid || ''}
            setOpenFreeTariffSnackbar={setOpenFreeTariffSnackbar}
            closeEvent={() => closeSetDataModal()}
          />
          <span className={classes.toolbarIconBtn} onClick={() => setOpenNotification(true)}>
            <Bell />
          </span>

          <ExcelDialog loader={setLoader}
           setShowSnackbar={setShowSnackbar}
           setSnackbarMessage={setSnackbarMessage}
            isOpenExcel={isOpenExcel}
            closeModalExportToExcel={closeModalExportToExcel}
            type={typeRep} filterList={currentFilter} dataFetch={props.dataFetch} colsFetch={props.colsFetch}/>

          {notify.data ? (
            (notify.data && searchParams.get("id") !== 'ceee8d45-32fb-4710-aa62-d26d2a75bb87')
            || (notify.data && searchParams.get("id") !== '005fea2d-b561-4c81-a1e6-85554bfbd7ba')) && (
              <NotificationModal
                show={openNotification}
                list={notify.data}
                closeEvent={() => setOpenNotification(false)}
              />
            ) : <NotificationModal
            show={openNotification}
            list={notifyNull}
            closeEvent={() => setOpenNotification(false)}
          />}
          {/* {JSON.stringify(notify.data)} */}
          {(
            (notify.data && searchParams.get("id") === 'ceee8d45-32fb-4710-aa62-d26d2a75bb87')
            || (notify.data && searchParams.get("id") === '5fe9a4b9-423d-4bad-bbc9-e15660568081')
            || (notify.data && searchParams.get("id") === '005fea2d-b561-4c81-a1e6-85554bfbd7ba')) && (
              <NotificationModalSizePage
                show={openNotification}
                list={notify.data}
                closeEvent={() => setOpenNotification(false)}
                refetch={() => doRefetch()}
              />
            )}

          {searchParams.get("id") === '5fe9a4b9-423d-4bad-bbc9-e15660568081' ? (
            <div style={{ display: 'flex' }}>
              <label style={{ marginRight: 10 }} className={classes.toolbarAddBtn} htmlFor="upload" onClick={() => setOpenFilters(true)}>
                <Plus /> Установить значения
              </label>
              <ToolbarUpload
                primaryDataUploadUid={props.primaryDataUploadUid}
                newPrimaryDataUploadUid={(val) => props.newPrimaryDataUploadUid(val)}
                updateList={updateList}
                storageType={(storageType?.data?.length) ? storageType?.data?.[0].type : []}
              />
            </div>

          ) : (
            <>
              {/* <label  className={classes.toolbarAddBtn} htmlFor="upload">
                <Upload /> Загрузить
              </label> */}
              <ToolbarUpload
                sensor={true}
                typeUid={searchParams.get("id")}
                primaryDataUploadUid={props.primaryDataUploadUid}
                newPrimaryDataUploadUid={(val) => props.newPrimaryDataUploadUid(val)}
                updateList={updateList}
                storageType={(storageType?.data?.length) ? storageType?.data?.[0].type : []}
              />
            </>
          )}

          {(
            searchParams.get('id') === '960d6482-dc35-4834-871a-1ee8a26f1d02'
            || searchParams.get('id') === '005fea2d-b561-4c81-a1e6-85554bfbd7ba'
            || searchParams.get('id') === '147bda8c-d997-4050-b497-d7a0aed59265'
            || searchParams.get('id') === '5fe9a4b9-423d-4bad-bbc9-e15660568081'
            || searchParams.get('id') === '94e066eb-3b01-4916-993c-73f06978145b'
          ) && (
              <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                edge="end"
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            )}

          <Menu
            id="basic-menu"
            style={{position: 'absolute', top: `${btnCount}px`}}
            anchorEl={anchorEl2}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {searchParams.get('id') === '960d6482-dc35-4834-871a-1ee8a26f1d02' && (
              <MenuItem  >
                <button className={classes.buttonXlsx} onClick={noCurrentStock}>Нет текущей акции</button>
              </MenuItem>
            )}
            {/*{searchParams.get('id') === '5fe9a4b9-423d-4bad-bbc9-e15660568081' && (*/}
              <div>
                {
                  searchParams.get('id') === '5fe9a4b9-423d-4bad-bbc9-e15660568081' &&
                  <MenuItem>
                    <button className={classes.buttonXlsx} onClick={() => downloadFile(URL_XLSX)}>Шаблон БКТ</button>
                  </MenuItem>
                }

                {
                  searchParams.get('id') === '5fe9a4b9-423d-4bad-bbc9-e15660568081' &&
                  <MenuItem>
                    <button className={classes.buttonXlsx} onClick={downloadRegisterDuplicates}>Выгрузить реестр дублей</button>
                  </MenuItem>
                }

                {
                  searchParams.get('id') === '5fe9a4b9-423d-4bad-bbc9-e15660568081' &&
                  dataDoubles.length > 0 &&
                  <MenuItem>
                    <button className={classes.buttonXlsx} onClick={checkDuplicates}>Реестр дублей Параметров БКТ</button>
                  </MenuItem>
                }

                <MenuItem>
                  <button className={classes.buttonXlsx} onClick={openModalExportToExcel}>Выгрузить визуальную часть таблицы</button>
                </MenuItem>
              </div>
             {/* )}*/}
            {searchParams.get('id') === '005fea2d-b561-4c81-a1e6-85554bfbd7ba' && (
              <MenuItem>
                <a
                  style={{ color: '#37352F', textDecoration: 'none' }}
                  href="/rental price.xlsx"
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  Скачать шаблон для импорта
                </a>
              </MenuItem>
            )}
            {searchParams.get('id') === '147bda8c-d997-4050-b497-d7a0aed59265' && (
              <MenuItem onClick={viewOldVersion}>
                Посмотреть старую версию
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 16 }}>
        {searchParams.get("id") === '5fe9a4b9-423d-4bad-bbc9-e15660568081'
          ? <div className={classes.toolbarFilter}>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  value={showEmptyData}
                  onChange={() => handleShowEmpty()}
                />
              }
              label="Отображать незаполненные значения"
            />
          </div>
          : null
        }
      </div>
    </div>
  )
}

export default LoadDataToolbar
