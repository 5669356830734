import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    tableContainerBlock: {
      overflowX: 'hidden',
      minWidth: 'fit-content',
      border: 'none',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      tableLayout: 'fixed',

      '& .MuiTableRow-head': {
        background: '#fff',
      },
      '& .MuiTableCell-head': {
        borderRight: '1px solid #E9E9E7',
        textAlign: 'center',
        lineHeight: 1,
        boxSizing: 'border-box',
        padding: '8px !important',
        verticalAlign: 'middle',
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        padding: '2px 8px !important',
        borderRight: '1px solid #E9E9E7',
        boxSizing: 'border-box',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 16px !important',
        borderRight: 'none',
      },
    },
    tableHeader: {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 8,
      boxShadow: 'none',
    },
    tableCell: {
      verticalAlign: 'initial',
      textAlign: 'left',
      fontSize: '0.775rem',
      position: 'relative',
      minHeight: 30,
      padding: 10,

      '&:hover': {
        background: 'rgba(225, 225, 228, 0.5) !important'
      },
    },
    tableHeadRow: {},
    tableCellTh: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      position: 'relative',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      padding: 8,
      margin: 0,
      verticalAlign: 'middle',
      textAlign: 'center',

      '& span': {
        fontWeight: 'bold',
        color: 'black',
      },
    },
  })
);

interface Props {
  cols: any;
  param?: string;
  tableWidth: number;
}

const DoublesDataTableHeader = (props: Props) => {
  const classes = useStyles();

  const cellRenderCondition = (name, showed) => {
    if (name.indexOf('week_') !== -1 && name.indexOf(`_${props.param}`) === -1) return false;
    if (!showed) return false;
    if (showed) return true;
    return true;
  };


  return (
    <TableContainer
      component={Paper}
      className={
        classNames(classes.tableContainerBlock, classes.tableHeader)
      }
    >
      <Table className={classes.table} style={{width: props.tableWidth}}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            {props.cols.map((col: any, index: number) => (
              cellRenderCondition(col.column_name, col.showed) &&
                <TableCell
                  className={classNames(classes.tableCell, classes.tableCellTh)}
                  style={{
                    width: col.width,
                    maxWidth: col.width,
                    minWidth: col.width
                  }}
                  key={index}
                >
                  <div dangerouslySetInnerHTML={{__html: col.column_caption}} />
                </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  )
}

export default DoublesDataTableHeader;
