import { Card, createStyles, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import cookie from 'cookie';
import { NavLink } from 'react-router-dom';
import { deleteColum } from '../../api/reports';
import CustomModal from '../UI/CustomModal';
import ActualInfo from './ActualInfo';
import MorePopoverDetail from './MorePopover/Detail';
import MorePopoverFBS from './MorePopover/FBS';
import MorePopoverSale from './MorePopover/Sale';
import { ToolbarUpload } from './ToolbarUpload';
import { ClockIcon, InfoIcon } from '../UI/Icons';
import StocksActualInfo from './StocksActualInfo';
import StockRemainsActualInfo from './StockRemainsActualInfo';
import { getReportList } from '../../api/history';
import ActualLeftovers from './ActualLeftovers';

const useStyles: any = makeStyles(() =>
  createStyles({
    card: {
      background: '#F2F2F0',
      borderRadius: 8,
      boxShadow: 'none',
      padding: '24px 32px',
      marginBottom: 16,
    },
    statusesTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
      marginBottom: 20,
    },
    cardChips: {
      display: 'flex',
      marginBottom: 8,
    },
    cardWBChip: {
      padding: '4px 8px',
      background: 'rgba(191, 17, 166, 0.05)',
      border: '1px solid #BF11A6',
      borderRadius: 8,
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#BF11A6',
      marginRight: 4,
    },
    cardOwnChip: {
      padding: '4px 8px',
      background: 'rgba(23, 115, 103, 0.05)',
      border: '1px solid #177367',
      borderRadius: 8,
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#177367',
      marginRight: 4,
    },
    cardCumulativeChip: {
      padding: '4px 8px',
      background: 'rgba(55, 53, 47, 0.05)',
      border: '1px solid #37352F',
      borderRadius: 8,
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginRight: 4,
    },
    cardEveryChip: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      background: '#E9E9E7',
      borderRadius: 8,
      padding: '4px 8px',
      marginRight: 4,
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cardTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    cardText: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',

      '& svg': {
        marginRight: 4,
      },
    },
    cardBtns: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    cardUploadBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      padding: '8px 10px',
      background: '#37352F',
      borderRadius: 8,
      textTransform: 'capitalize',
      marginBottom: 8,

      '&:hover': {
        background: '#827F75',
      },
    },
    cardLink: {
      position: 'relative',
      display: 'inline-block',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      height: '16px',
      color: '#37352F',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    cardWatch: {
      marginTop: 8,
    },
    cardLinkLine: {
      position: 'absolute',
      display: 'inline-block',
      bottom: -1,
      left: 0,
      width: '100%',
      height: 1,
      background: '#000',
    },
  })
);

interface Props {
  item: any;
  storageType: number;
  updateList: () => void;
  allGoodUploadDate?: string;
  setLoader: any;
  actualLeftover?: any
}

const LoadDataReportCard = (props: Props) => {
  const classes = useStyles();

  const [fbsNoActual, setFbsNoActual] = React.useState(false);
  const [saleNoActual, setSaleNoActual] = React.useState(false);
  const [detailNoActual, setDetailNoActual] = React.useState(false);
  const [confirmAction, setConfirmAction] = React.useState(false);
  const [currentTypeUid, setCurrentTypeUid] = React.useState<string | null>(null);
  const [actualUpdate, setActualUpdate] = React.useState(1);

  const differenceDates = (lastDateUpload: string) => {
    const a: any = moment().startOf('day');
    const b: any = moment(lastDateUpload);
    return Math.round(moment.duration(a - b).asDays()) * -1 > -1;
  };

  const handleConfirmAction = (show: boolean, uid: string) => {
    setConfirmAction(show);
    setCurrentTypeUid(uid);
  };

  const resetReport = async () => {
    props.setLoader(true)
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await deleteColum({
      primary: currentTypeUid,
      org: uid
    });
    handleConfirmAction(false, null);
    props.updateList();
    setActualUpdate(actualUpdate + 1);
  };

  const updatePage = () => {
    props.updateList();
    setActualUpdate(actualUpdate + 1);
  };

  
  return (
    <Card className={classes.card} style={{padding: props.item.uid === '5fe9a4b9-423d-4bad-bbc9-e15660568081' ? '30px 32px 5px' : null}}>
      <div className={classes.cardChips}>
        {props.item.primaryDataTypeOwner.toLowerCase() === 'wb' && (
          <span className={classes.cardWBChip}>
            Wildberries
          </span>
        )}
        {props.item.primaryDataTypeOwner.toLowerCase() === 'собственный' && (
          <span className={classes.cardOwnChip}>
            Собственный
          </span>
        )}
        {props.item.primaryDataTypeOwner.toLowerCase() === '' && (
          <span className={classes.cardCumulativeChip}>
            Накопительный
          </span>
        )}
        {(props.item.uid === '7e8d9639-2419-47cd-aca1-0c8829528622' &&
          (props.item.uid !== '960d6482-dc35-4834-871a-1ee8a26f1d02' ||
          props.item.uid !== '5fe9a4b9-423d-4bad-bbc9-e15660568081' ||
          props.item.uid !== '005fea2d-b561-4c81-a1e6-85554bfbd7ba' ||
          props.item.uid !== 'ceee8d45-32fb-4710-aa62-d26d2a75bb87'))
          ? <span className={classes.cardEveryChip}>
            Еженедельные
          </span>
          : (props.item.uid === '960d6482-dc35-4834-871a-1ee8a26f1d02' ||
              props.item.uid === '5fe9a4b9-423d-4bad-bbc9-e15660568081' ||
              props.item.uid === '005fea2d-b561-4c81-a1e6-85554bfbd7ba' ||
              props.item.uid === 'ceee8d45-32fb-4710-aa62-d26d2a75bb87')
              ? <span className={classes.cardEveryChip}>
                Разовый
              </span>
              : <span className={classes.cardEveryChip}>
                Ежедневные
              </span>
        }
      </div>
      <div className={classes.cardContent}>
        <div className={classes.cardInfo}>
          <h2 className={classes.cardTitle}>{props.item.primaryDataTypeName === 'Текущая неделя продаж' ? 'Еженедельная динамика продаж' : props.item.primaryDataTypeName}</h2>
          <p className={classes.cardText}>
            {props.item.uid === '7e8d9639-2419-47cd-aca1-0c8829528622'
              ? <ActualInfo
                  typeCheck={'custom'}
                  isActual={!detailNoActual}
                  lastDateUpload={props.item.lastDateUpload}
                  uid={props.item.isItActual}
                /> : null}
            {props.item.uid === '974b957b-6df3-4811-9eaf-54c12898227f'
            ? <ActualInfo
                typeCheck={'custom'}
                isActual={!fbsNoActual}
                lastDateUpload={props.item.lastDateUpload}
              /> : null}

            {props.item.uid === '94e066eb-3b01-4916-993c-73f06978145b'
            ? <ActualInfo
                typeCheck={'standard'}
                lastDateUpload={props.item.lastDateUpload}
              /> : null}

            {props.item.uid === '960d6482-dc35-4834-871a-1ee8a26f1d02'
              ? <StocksActualInfo
                  typeCheck={'custom'}
                  isActual={props.item.isItActual}
                  uid={props.item.uid}
                  lastDateUpload={props.item.lastDateUpload}
                /> : null}

            {props.item.uid === '147bda8c-d997-4050-b497-d7a0aed59265'
              ? <StockRemainsActualInfo
                  typeCheck={'standard'}
                  isActual={!saleNoActual}
                  uid={props.item.uid}
                  lastDateUpload={props.item.lastDateUpload}
                  allGoodUploadDate={props.allGoodUploadDate}
                /> : null}
            {props.item.uid !== '147bda8c-d997-4050-b497-d7a0aed59265' &&
              props.item.uid !== '7e8d9639-2419-47cd-aca1-0c8829528622' &&
              props.item.uid !== '974b957b-6df3-4811-9eaf-54c12898227f' &&
              props.item.uid !== '960d6482-dc35-4834-871a-1ee8a26f1d02' &&
              props.item.uid !== '005fea2d-b561-4c81-a1e6-85554bfbd7ba' &&
              props.item.uid !== 'ceee8d45-32fb-4710-aa62-d26d2a75bb87' &&
              props.item.uid !== '5fe9a4b9-423d-4bad-bbc9-e15660568081' &&
              props.item.uid !== '94e066eb-3b01-4916-993c-73f06978145b'
              ?
              <div>
                <div style={{display: 'flex', marginBottom: '10px'}}>
                  <ActualInfo
                    typeCheck={'standard'}
                    all={true}
                    lastDateUpload={props.item.lastDateUpload}
                  />
                </div>
                <div style={{display: 'flex'}}>
                  <ActualLeftovers item={props.actualLeftover}/>
                </div>
              </div>
              : null}
            {props.item.lastDateUpload &&
              (props.item.uid === '005fea2d-b561-4c81-a1e6-85554bfbd7ba' ||
              props.item.uid === 'ceee8d45-32fb-4710-aa62-d26d2a75bb87' ||
              props.item.uid === '5fe9a4b9-423d-4bad-bbc9-e15660568081')
                ? <><ClockIcon /> Обновлен {moment(props.item.lastDateUpload).format('LLL')}</>
                : null}
            {props.item.uid === '7e8d9639-2419-47cd-aca1-0c8829528622'
              ? <MorePopoverDetail
                  update={actualUpdate}
                  detailNoActual={(val: boolean) => setDetailNoActual(val)}
                />
              : null
            }
            {props.item.uid === '974b957b-6df3-4811-9eaf-54c12898227f'
              ? <MorePopoverFBS
                  update={actualUpdate}
                  actual={props.item.isItActual}
                  fbsNoActual={(val: boolean) => setFbsNoActual(val)}
                />
              : null}
            {props.item.uid === '960d6482-dc35-4834-871a-1ee8a26f1d02'
              ? <MorePopoverSale
                  update={actualUpdate}
                  actual={props.item.isItActual}
                  saleNoActual={(val: boolean) => setSaleNoActual(val)}
                />
              : null}
          </p>
        </div>
        <div className={classes.cardBtns}>
          {/* {!props.item.primaryDataTypeAccumulative ? (
            <ToolbarUpload
              updateList={updatePage}
              typeUid={props.item.uid}
              storageType={props.storageType}
            />
          ) :
            <ToolbarUpload
                updateList={updatePage}
                typeUid={props.item.uid}
                storageType={props.storageType}
            />
          } */}
          {props.item.uid === '5fe9a4b9-423d-4bad-bbc9-e15660568081' ? (
            <ToolbarUpload
            updateList={updatePage}
            typeUid={props.item.uid}
            storageType={props.storageType}
        />
          ) :
            <ToolbarUpload
                updateList={updatePage}
                typeUid={props.item.uid}
                storageType={props.storageType}
            />
          }
          {/*тут надо поставить null, primaryDataTypeAccumulative должен быть по дефолту false*/}
          <br />
          {(props.item.uid !== '147bda8c-d997-4050-b497-d7a0aed59265'
              && props.item.uid !== '960d6482-dc35-4834-871a-1ee8a26f1d02'
              && props.item.uid !== '5fe9a4b9-423d-4bad-bbc9-e15660568081'
              && props.item.isItActual) ? (
            <NavLink
              className={classNames(classes.cardLink, classes.cardWatch)}
              to={`/load-data/load-params?id=${props.item.uid}&name=${props.item.primaryDataTypeName === 'Текущая неделя продаж' ? 'Еженедельная динамика продаж' : props.item.primaryDataTypeName}`}
              onClick={() => localStorage.setItem('typeName', props.item.primaryDataTypeName === 'Текущая неделя продаж' ? 'Еженедельная динамика продаж' : props.item.primaryDataTypeName)}
            >
              Перейти к отчету
              <span className={classes.cardLinkLine}></span>
            </NavLink>
          ): null}
          {(props.item.uid === '5fe9a4b9-423d-4bad-bbc9-e15660568081') ? (
            <NavLink
              style={{bottom: 27}}
              className={classNames(classes.cardLink, classes.cardWatch)}
              to={`/load-data/load-params?id=${props.item.uid}&name=${props.item.primaryDataTypeName}`}
              onClick={() => localStorage.setItem('typeName', props.item.primaryDataTypeName)}
            >
              Перейти к отчету
              <span className={classes.cardLinkLine}></span>
            </NavLink>
          ): null}
          {(props.item.uid === '147bda8c-d997-4050-b497-d7a0aed59265'
              && props.item.isItActual) && (
            <NavLink
              className={classNames(classes.cardLink, classes.cardWatch)}
              to={`/load-data/load-params?id=${props.item.uid}&name=${props.item.primaryDataTypeName}`}
              onClick={() => localStorage.setItem('typeName', props.item.primaryDataTypeName)}
            >
              Перейти к отчету
              <span className={classes.cardLinkLine}></span>
            </NavLink>
          )}
          {(props.item.uid === '960d6482-dc35-4834-871a-1ee8a26f1d02'
              && props.item.isItActual) && (
            <NavLink
              className={classNames(classes.cardLink, classes.cardWatch)}
              to={`/load-data/load-params?id=${props.item.uid}&name=${props.item.primaryDataTypeName}`}
              onClick={() => localStorage.setItem('typeName', props.item.primaryDataTypeName)}
            >
              Перейти к отчету
              <span className={classes.cardLinkLine}></span>
            </NavLink>
          )}
        </div>
      </div>

      {
        props.item.primaryDataTypeRequire
        ? <span
            className={classes.cardLink}
            style={{ marginRight: 5, }}
            onClick={() => handleConfirmAction(true, props.item.uid)}
          >
            Удалить последнюю загрузку
            <span className={classes.cardLinkLine}></span>
          </span>
        : null}
      <CustomModal
        title="Подтвердите действие"
        show={confirmAction}
        closeEvent={() => setConfirmAction(false)}
        successEvent={() => resetReport()}
        successText="Да"
        cancelText="Нет"
      >
        <br />
      </CustomModal>
    </Card>
  )
}

export default LoadDataReportCard