import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
}

const ChevronsLeft = (props: Props) => {
  return (
    <svg width={props.width || '24px'} height={props.height || '24px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 17L6 12L11 7" strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 17L13 12L18 7" strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ChevronsLeft;
