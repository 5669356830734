import { useState, useEffect } from 'react'
import { getPrivacyPolicy } from '../../api/registration'
import Empty from '../../components/layout/empty'
import React from 'react'

const PrivacyPolicy = () => {
    const [b64, setB64] = useState<any>()

    useEffect(() => {
        getPrivacyPolicy(setB64) 
    }, [])

    const base64 = b64?.file.replace('data:application/pdf;base64,','') || "".replace('data:application/pdf;base64,','')
    const blob = base64ToBlob(base64 || "", 'application/pdf');
    const url = URL.createObjectURL(blob);
    if(blob.size>0){
        return (
            <Empty>
                 <iframe style={{ width: '100%', minHeight: '100vh' }} src={url || ""}></iframe>
            </Empty>
        )
    }else{
        return (
            <Empty>
                 <iframe style={{ width: '100%', minHeight: '100vh' }} src={""}></iframe>
            </Empty>
        )
    }  
    
    function base64ToBlob(base64, type = "application/octet-stream") {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return new Blob([arr], {
            type: type
        });
    }
}

export default PrivacyPolicy;