import axios, {AxiosInstance, AxiosResponse} from 'axios';
import jwtDecode from 'jwt-decode';
import { getLocalStorageValue, removeLocalStorageValue } from '../utils';
import cookie from "cookie";
import {useCallback, useRef} from "react";

export const TOKEN_KEY = 'token';
export const ROLE_KEY = 'guid';

// axios.defaults.baseURL = 'https://authorization-wb.api.price-market.online/';
async function createInstanceAxiosWithUrls(){
  const instance = axios
  const urlConfig = await instance.get('/configApi.json')
  return [instance, urlConfig]
}
let intanceWithUrls: [null | AxiosInstance, null | AxiosResponse<any> ]
createInstanceAxiosWithUrls().then(e => intanceWithUrls = [e[0] as AxiosInstance, e[1] as AxiosResponse])
async function preflyForTariffs(){
  const isDowngradeStatus = localStorage.getItem("isDowngradeStatus")
  if(!isDowngradeStatus || isDowngradeStatus == "false"){
    const [instance, urlConfig] = intanceWithUrls
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const url = `${urlConfig?.data?.tariffService}/tariffs/show_greeting`
    const res = await instance?.post(url, { organization: uid, isDowngrade:true });
    // (res as any).data.isdowngrade = true
    // console.log("id Downgrade:", res?.data?.isdowngrade)
    if(res?.data?.isdowngrade){
      localStorage.setItem('isDowngradeStatus', "true");
      window.location.href = '/profile';
    }
  }
}

function debounceDeco(callback:any, delay: number) {
  let timer: any;

  // @ts-ignore
  const deboncedCallback = (...args: []) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      callback(...args)
    }, delay)
  }

  return deboncedCallback;
}
const debouncedPrefly = debounceDeco(preflyForTariffs, 400)
axios.interceptors.request.use(async (request)=>{
  // localStorage.setItem('isDowngradeStatus', "false");
  const {token, org_uid} = await cookie.parse(document.cookie)

  if (!request?.url?.includes("configApi.json") &&
      !request?.url?.includes("show_greeting") &&
      !window.location.href?.includes("login") &&
      !window.location.href?.includes("verification") &&
      token && org_uid) {

    debouncedPrefly()
  }
  return request
},(error)=>{
  return Promise.reject(error);
})

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error?.response?.status) {
      case 401:
        removeLocalStorageValue(TOKEN_KEY);
        window.location.href = '/login';
        break;
      // case 404:
      case 403:
        window.location.href = '/';
        break;
    }
    return Promise.reject(error.response);
  },
);

export function setToken(token: string | null) {
  // Access-Control-Allow-Origin
  // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

const setAxiosToken = async () => setToken(await getLocalStorageValue(TOKEN_KEY));
setAxiosToken();

type JWTPayload = {
  id: string;
  username: string;
  exp: number;
};

export function isTokenValid(token: string) {
  try {
    const decoded_jwt: JWTPayload = jwtDecode(token);
    const current_time = Date.now().valueOf() / 1000;
    return decoded_jwt.exp > current_time;
  } catch (error) {
    return false;
  }
}

export default axios;
