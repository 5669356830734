import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
  fill?: string;
}

const EmailCircle = (props: Props) => {
  return (
    <svg width={props.width || '40'} height={props.height || '40'} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#E9E9E7"/>
      <path d="M12 12H28C29.1 12 30 12.9 30 14V26C30 27.1 29.1 28 28 28H12C10.9 28 10 27.1 10 26V14C10 12.9 10.9 12 12 12Z" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M30 14L20 21L10 14" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default EmailCircle;
