import { makeStyles, createStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { Close } from '@material-ui/icons';
import cookie from 'cookie';

import { useQuery } from 'react-query';
import { getBell } from '../../../api/reports';

const useStyles = makeStyles(() =>
  createStyles({
    notificationModal: {
      // display: 'none',
      position: 'fixed',
      width: 329,
      height: '100vh',
      background: '#fff',
      top: 0, right: 0,
      transform: 'translateX(100%)',
      transition: 'all .2s ease-out',
      padding: 16,
      paddingRight: 12,
      boxSizing: 'border-box',
      borderLeft: '1px solid #D3D1CB',
      zIndex: 1299,
    },
    show: {
      // display: 'block',
      transform: 'translateX(0)',
    },
    notificationHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 32,
    },
    notificationTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
    },
    notificationClose: {
      fontSize: 24,
      cursor: 'pointer',
    },
    notificationContent: {},
    notificationMiniTitle: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginBottom: 8,

      '& svg': {
        marginRight: 4,
      },
    },
    notificationList: {
      padding: 0,
      listStyle: 'none',
    },
    notificationListItem: {
      maxWidth: 280,
      paddingTop: 12,
      paddingBottom: 12,
      borderBottom: '1px solid #E9E9E7',
    },
    notificationName: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 4,

      '& span': {
        color: '#827F75',
      },
    },
    notificationInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,

      '& span': {
        color: '#827F75',
      },
    },
    notificationPopover: {},
  }),
);

interface Props {
  show: boolean;
  list?: any;
  closeEvent: () => void;
};

const LogisticsCostNotificationModal = (props: Props) => {
  const classes = useStyles();

  const notify = useQuery('notify', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let result: any = null;
    result = await getBell({
      primary: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      history: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      org: uid
    });
    return result?.data?.data;
  });

  return (
    <div className={classNames(classes.notificationModal, props.show ? classes.show : '')}>
      <div className={classes.notificationHeader}>
        <h1 className={classes.notificationTitle}>Уведомления</h1>
        <span className={classes.notificationClose} onClick={() => props.closeEvent()}>
          <Close />
        </span>
      </div>
      <div className={classes.notificationContent}>
        <h5 className={classes.notificationMiniTitle}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L8 10L12 6" stroke="#37352F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Данные отсутствуют
        </h5>

        <ul className={classes.notificationList}>
          {notify.data?.map((item: any, index: number) => (
            <li className={classes.notificationListItem} key={index}>
              <p className={classes.notificationName}><span>Товар:</span> {item.category}</p>
              <p className={classes.notificationInfo}>
                <span>
                  {(item.base_price === '' || item.base_price === '0') ? (
                    'Текущая розн. цена (до скидки), ₽:'
                  ) : null}
                  {(item.rest_wb_fbo === '' || item.rest_wb_fbo === '0') ? (
                    'Остаток товара, шт:'
                  ) : null}
                </span>
                <Tooltip title={item.rest_wb_fbo} placement="top">
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </span>
                </Tooltip>
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default LogisticsCostNotificationModal;
