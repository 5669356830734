import React, {useEffect} from "react"
import DocumentsItem from "./DocumentsItem"
import { makeStyles, createStyles } from '@material-ui/core'
import {sendDocuments} from '../../api/documents/index'
import Cheked from '../../assets/icons/check-circle.svg'
import NoCheked from '../../assets/icons/x-circle.svg'
import Time from '../../assets/icons/time.svg'
import moment from 'moment'
import {getLicenseAgreement, getPersonalDataProcessingPolicy, getPrivacyPolicy} from '../../api/registration'
import PopUP from "../../components/UI/CustomPopup"
import Spinner from "../../components/UI/Spinner"
import CustomSnackbar from "../../components/UI/CustomSnackbar";
import Load from "../../assets/icons/load.svg";



const useStyles = makeStyles(() =>
  createStyles({
    wrapperDocuments: {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        width: '100%',
        padding: 32,
        maxWidth: 722,
    },
    documentsCard: {
        background: '#F2F2F0',
        borderRadius: 8,
        maxWidth: 722,
        padding: '24px 32px',
        marginBottom: 16,
    },
    errorWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        width: 274,
        height: 64,
        right: 55,
        top: 88,
        background: '#37352F',
        borderRadius: 8,
        color: 'white',
        textAlign: 'center'
    },
    loadImg: {
        marginLeft: 18,
        marginRight: 18
    },
    errorText: {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
    },
    link: {
        color: 'black',
        fontSize: 14,
    },
    time: {
        marginTop: 15,
        display: 'flex',
        // color: '#827F75',
        fontSize: 14,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        color: '#000000',

    },
    timeSvg: {
        marginRight: 5,
        color: '#000000',
    },
    textPop: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        textAlign: 'start',
    },
      castomInputFile: {
          width: 155,
          height: 40,
          display: 'flex',
          color: 'white',
          backgroundColor: '#37352F',
          borderRadius: 8,
          fontFamily: 'Work Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'space-evenly'
      },
      loadImg1: {
          width: 24,
          height: 24,
      },
      splitData:{
        display: 'flex',
          justifyContent:"space-between",
      },
      centerData:{
        display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
      }
  }))

const AdminDocuments = () => {
    const classes = useStyles();

    const [fileLicense, setFileLicense] = React.useState(null)
    const [privacyPolicy, setprivacyPolicy] = React.useState(null)
    const [personalData, setpersonalData] = React.useState(null)
    const [errorPDF, setErrorPDF] = React.useState(false)
    const [errorSize, setErrorSize] = React.useState(false)
    const [okServer, setOk] = React.useState(false)
    const [loader, setLoader] = React.useState(false)


    const [licenseOld, setLicense] = React.useState(null)
    const [personalDataOld, setPersonalData] = React.useState(null)
    const [privacyPolicyOld, setPrivacyPolicy] = React.useState(null)

    useEffect(() => {
        getLicenseAgreement(setLicense) 
        getPersonalDataProcessingPolicy(setPersonalData)
        getPrivacyPolicy(setPrivacyPolicy)
    }, [fileLicense, privacyPolicy, personalData])


    const convertBase64 = (file) => {
        return new Promise ((resolve, reject)  => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
    
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };

    const handleLicense = async(e) => {
       
        const file = e.target.files[0]
        const b64 = await convertBase64(file)
        setLoader(true)
        if(file.type !== "application/pdf") {
            setErrorPDF(true)
            setLoader(false)
        }
        else if(file.size > 5242880) {
            setErrorSize(true)
            setLoader(false)
        }
        else {
            const check = await sendDocuments({
                type: 'license_agreement' || '',
                name: file.name || '',
                file: b64 || ''
            })
            
            if(check.status === 200) {
                setOk(true)
                setFileLicense(e.target.files[0])
                setLoader(false)
            }
        }
        e.target.value = ''
    }


    const handlePrivacyPolicy = async(e) => {
        
        const file = e.target.files[0]
        const b64 = await convertBase64(file)
        setLoader(true)

        if(file.type !== "application/pdf") {
            setErrorPDF(true)
            setLoader(false)
        }
        else if(file.size > 5242880) {
            setErrorSize(true)
            setLoader(false)
        }
        else{
            const check =await sendDocuments({
                type: 'privacy_policy' || '',
                name: file.name || '',
                file: b64 || ''
            })
            if(check.status === 200) {
                setOk(true)
                setprivacyPolicy(e.target.files[0])
                setLoader(false)
            }
        }
        e.target.value = ''       
            
    }
    const handlePersonalData = async(e) => {

        const file = e.target.files[0]
        const b64 = await convertBase64(file)
        setLoader(true)

        if(file.type !== "application/pdf") {
            setErrorPDF(true)
            setLoader(false)
        }
        else if(file.size > 5242880) {
            setErrorSize(true)
            setLoader(false)
        }
        else{
            const check = await sendDocuments({
                type: 'personal_data_processing_policy',
                name: file.name || '',
                file: b64 || ''
            })
            if(check.status === 200) {
                setOk(true)
                setpersonalData(e.target.files[0])
                setLoader(false)
            }
        }       
        e.target.value = ''    
    }
    
    
    const Data = new Date()
    

    return (
        <>
        <Spinner open={loader}/>
        <div className={classes.wrapperDocuments}>
            <div className={classes.documentsCard + ' ' + classes.splitData}>
                <div>
                <DocumentsItem nameDocuments='Оферта (лицензионное соглашение)'
                    onChanges={handleLicense}
                />
                {fileLicense === null ?
                        <>
                            <a href='/admin/jur-license_agreement' target="_blank" className={classes.link}>{licenseOld?.name.slice(0, -4)}</a>
                            <div className={classes.time}>
                                <img className={classes.timeSvg} src={Time} alt="time" />
                                Загружен {moment(licenseOld?.date).format('D MMMM YYYY')}
                            </div>
                        </>
                        
                    :
                        <>
                            <a href='/admin/jur-license_agreement' target="_blank" className={classes.link}>{fileLicense.name.slice(0, -4)}</a>
                            <div className={classes.time}>
                                 <img className={classes.timeSvg} src={Time} alt="time" />
                                 Загружен {moment(Data).format('D MMMM YYYY')}
                             </div>
                        </>
                }
                </div>
                <div className={classes.centerData}>
                    <label className={classes.castomInputFile}>
                        <input type="file" onChange={handleLicense} accept='application/pdf'/>
                        <img className={classes.loadImg1} alt="load" src={Load}/>
                        <span>Загрузить PDF</span>
                    </label>
                </div>
            </div>
            
            <div className={classes.documentsCard + ' ' + classes.splitData}>
                <div>


                <DocumentsItem nameDocuments='Политика конфиденциальности'
                    onChanges={handlePrivacyPolicy} 
                />
                {privacyPolicy === null ?
                        <>
                            <a href='/admin/jur-privacy_policy' target="_blank" className={classes.link}>{privacyPolicyOld?.name.slice(0, -4)}</a>
                            <div className={classes.time}>
                                <img className={classes.timeSvg} src={Time} alt="time" />
                                Загружен {moment(privacyPolicyOld?.date).format('D MMMM YYYY')}
                            </div>
                        </>
                        :
                        <>
                            <a href= '/admin/jur-privacy_policy' target="_blank" className={classes.link}>{privacyPolicy.name.slice(0, -4)}</a>
                            <div className={classes.time}>
                                <img className={classes.timeSvg} src={Time} alt="time" />
                                Загружен {moment(Data).format('D MMMM YYYY')}
                            </div>
                        </> 
                }
                </div>
                <div  className={classes.centerData}>
                    <label className={classes.castomInputFile}>
                        <input type="file" onChange={handlePrivacyPolicy} accept='application/pdf'/>
                        <img className={classes.loadImg1} alt="load" src={Load}/>
                        <span>Загрузить PDF</span>
                    </label>
                </div>
            </div>

            <div className={classes.documentsCard + ' ' + classes.splitData}>
                <div>
                <DocumentsItem nameDocuments='Политика обработки персональных данных'
                    onChanges={handlePersonalData}
                />
                {personalData === null ?
                        <>
                             <a href='/admin/jur-personal_data_processing_policy' target="_blank" className={classes.link}>{personalDataOld?.name.slice(0, -4)}</a>
                             <div className={classes.time}>
                                 <img className={classes.timeSvg} src={Time} alt="time" />
                                 Загружен {moment(personalDataOld?.date).format('D MMMM YYYY')}
                             </div>
                        </>
                        :
                        <>
                            <a href='/admin/jur-personal_data_processing_policy' target="_blank" className={classes.link}>{personalData.name.slice(0, -4)}</a>
                            <div className={classes.time}>
                                <img className={classes.timeSvg} src={Time} alt="time" />
                                Загружен {moment(Data).format('D MMMM YYYY')}
                            </div>
                        </>
                }
                </div>
                <div  className={classes.centerData}>
                    <label className={classes.castomInputFile}>
                        <input type="file" onChange={handlePersonalData} accept='application/pdf'/>
                        <img className={classes.loadImg1} alt="load" src={Load}/>
                        <span>Загрузить PDF</span>
                    </label>
                </div>
            </div>
            {errorPDF && (
                <CustomSnackbar
                    open={errorPDF}
                    message={'Доступный формат для загрузки файлов - pdf'}
                    handleClose={() => setErrorPDF(false)}
                />
                // <PopUP setOk={setErrorPDF}
                //     className={classes.errorWrapper}
                //     classImg={classes.loadImg}
                //     img={NoCheked}
                //     delay={15000}
                //     classText={classes.textPop}
                //     text='Доступный формат для загрузки файлов - pdf'/>
            )}
            {errorSize && (
                <CustomSnackbar
                    open={errorSize}
                    message={'Максимально допустимый размер файла - 5 МБ'}
                    handleClose={() => setErrorSize(false)}
                />
                // <PopUP setOk={setErrorSize}
                //     className={classes.errorWrapper}
                //     classImg={classes.loadImg} img={NoCheked}
                //     delay={15000}
                //     classText={classes.textPop}
                //     text='Максимально допустимый размер файла - 5 МБ'/>
            )}
            {okServer && (
                <CustomSnackbar
                    open={okServer}
                    message={'Файл успешно загружен'}
                    handleClose={() => setOk(false)}
                />
                // <PopUP setOk={setOk}
                //     className={classes.errorWrapper}
                //     classImg={classes.loadImg}
                //     img={Cheked}
                //     delay={15000}
                //     classText={classes.textPop}
                //     text='Файл успешно загружен'/>
            )}
        </div>
        </>
    )
}

export default AdminDocuments;