import { makeStyles, createStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import cookie from 'cookie';
import { getData, getReport, setData } from '../../api/reports';
import Main from '../../components/layout/main'
import LoadDataToolbar from '../../components/LoadData/Toolbar';
import Spinner from '../../components/UI/Spinner';
import CustomSnackbar from '../../components/UI/CustomSnackbar';
import moment from 'moment';
import CurrencyFormatter from '../../components/UI/CurrencyFormatter';
import SaleParams from '../../components/LoadData/SaleParams';
import { getCellWidth } from '../../common/row-sizes'
import LoadDataTable from '../../components/LoadData/LoadDataTable/LoadDataTable';

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      '.makeStyles-modal-79 .MuiDialog-paperWidthSm': {
        maxWidth: '786px !important',
      },
    },
    content: {
      padding: 32,
      boxSizing: 'border-box',
      height: 'calc(100vh - 63px)',
    },
    tableHeader: {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 8,
      boxShadow: 'none',
    },
    tableWrap: {
      overflowY: 'auto',
      width: 'fit-content',
      maxWidth: '100%',
      maxHeight: '91%',

      '& .MuiPaper-root': {
        backgroundColor: 'transparent !important',
      },
    },
    tableContainer: {
      boxShadow: 'none',
      borderTop: '1px solid #E9E9E7',
      borderBottom: '1px solid #E9E9E7',
      border: 'none !important',
      width: 'fit-content',
      '& .MuiTableContainer-root': {
        overflowX: 'hidden !important',
      },
    },
    tableContainerBlock: {
      overflowX: 'hidden',
      minWidth: 'fit-content',
      border: 'none',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      tableLayout: 'fixed',

      '& .MuiTableRow-head': {
        background: '#fff',
      },
      '& .MuiTableCell-head': {
        borderRight: '1px solid #E9E9E7',
        textAlign: 'center',
        lineHeight: 1,
        boxSizing: 'border-box',
        padding: '8px !important',
        verticalAlign: 'middle',
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        padding: '2px 8px !important',
        borderRight: '1px solid #E9E9E7',
        boxSizing: 'border-box',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 16px !important',
        borderRight: 'none',
      },
    },
    tableCell: {
      verticalAlign: 'initial',
      textAlign: 'left',
      fontSize: '0.775rem',
      position: 'relative',
      minHeight: 30,
      padding: 10,

      '&:hover': {
        background: 'rgba(225, 225, 228, 0.5) !important'
      },
    },
    cellBlock: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    cellText: {
      display: 'inline-flex',
      width: '100%',
      height: '100%',
      padding: 0,
      margin: 0,
    },
    tableHeadRow: {},
    tableCellTh: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      position: 'relative',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      padding: 8,
      margin: 0,
      verticalAlign: 'middle',
      textAlign: 'center',
    },
    tableRow: {
      '&:hover': {
        background: '#f0f0ef',
      },
    },
    tableNoData: {
      backgroundColor: 'rgba(255, 108, 75, 0.08)',
    },
    cellInput: {
      width: '100%',
      background: 'transparent',
      border: 'none',
      outline: 'none',
    },
    cellCheckbox: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: 0,
      margin: 0,

      '& svg': {
        fill: '#37352F',
      },
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
    paramsAccordion: {
      width: 'fit-content',
      boxShadow: 'none',
      marginTop: '0 !important',

      '&::before': {
        opacity: '0 !important',
      },
    },
    paramsAccordionsSummary: {
      padding: '0 !important',
      borderBottom: '1px solid #E9E9E7',

      '& .MuiAccordionSummary-content': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& p': {
        display: 'flex !important',
        alignItems: 'center !important',
      },
      '& svg': {
        width: 16,
        transition: 'all .2s ease-out',
      },
      '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '24px !important',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
      },
      '& .Mui-expanded svg': {
        transform: 'rotate(90deg)',
      },
    },
    paramsAccordionTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginLeft: 13,
    },
    paramsAccordionsTitleBlock: {
      display: 'flex !important',
      alignItems: 'center !important',
    },
    paramsAccordSuccessChip: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#177367',
      padding: '4px 10px',
      maxWidth: 29,
      maxHeight: 24,
      background: 'rgba(23, 115, 103, 0.1)',
      borderRadius: 8,
    },
    paramsAccordionsDetails: {},
    paramsAccordionsList: {
      padding: 0,
      paddingLeft: 12,
      listStyle: 'none',
      margin: 0,
    },
    paramsAccordionsListItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'justify',
      color: '#827F75',
      marginBottom: 4,
    },
    paramsAccordionDetails: {
      padding: '0 !important'
    },
    noData: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      width: '100%',
      minHeight: 658,
      color: '#37352F',
      background: '#F2F2F0',
      '& p': {
        margin: 0,
        marginBottom: 8,
      },
    },
    detailsContent: {
      paddingBottom: 32,
    },
    detailsText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginTop: 32,
      marginBottom: 16,
    },
    details: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      minWidth: 112,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 4,

      '& .MuiSvgIcon-root': {
        width: 16, height: 16
      },
      '& .check': {
        fill: '#37352F',
      },
    },
  })
);

const LoadDataParamsV2 = props => {
  const classes = useStyles();

  const [loader, setLoader] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cols, setCols] = React.useState<null | any>([]);
  const [colsReq, setColsReq] = React.useState<null | any>([]);
  const [primaryDataUploadUid, setPrimaryDataUploadUid] = React.useState<string | null>();
  const [noData, setNoData] = React.useState('');
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [idPrimary, setIdPrimary] = React.useState<string | null>(null);
  const [oldVersion, setOldVersion] = React.useState(false);
  const [filterLoaded, setFilterLoaded] = React.useState(false);
  const [filterReq, setFilterReq] = React.useState<Array<any> | null>(null);
  const [filteredParams, setFilteredParams] = React.useState<any>([]);
  const [fullParams, setFullParams] = React.useState<any>([]);
  const [groupParams, setGroupParams] = React.useState<any>();
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [editCellPos, setEditCellPos] = React.useState<any>({ x: null, y: null });
  const [columnsList, setColumnsList] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [filterList, setFilterList] = React.useState([]);
  const [hoveredCol, setHoveredCol] = React.useState(-1);
  const [tableWidth, setTableWidth] = React.useState(0);
  const [weekOrderdOrSale, setWeekOrderdOrSale] = React.useState('buyer_order_date');
  const [showEmptyData, setShowEmptyData] = React.useState(false);


  useEffect(() => {
    if (loaded) return;
    if (
        searchParams.get("id") === '7e8d9639-2419-47cd-aca1-0c8829528622'
          || searchParams.get("id") === '94e066eb-3b01-4916-993c-73f06978145b'
        ) {
      setShowDetailsModal(true);
    }

    setLoaded(true);
  }, [searchParams]);


  useEffect(() => {
    let search = {
      id: searchParams.get('id') || '',
      name: searchParams.get('name') || '',
      primaryDataUploadUid: primaryDataUploadUid ? primaryDataUploadUid : ''
    };
    setSearchParams(search, { replace: true });
  }, [primaryDataUploadUid]);

  React.useMemo(() => {
    const copyParams = [...fullParams];

    if (showEmptyData) {
      setFilteredParams(copyParams.filter(el => {
        if (!Number(el.avg_price)) return el;
        if (!Number(el.delivery_time)) return el;
        if (!Number(el.max_price)) return el;
        if (!Number(el.min_price)) return el;
      }));
    } else {
      setFilteredParams(fullParams);
    }
  }, [showEmptyData]);

  const newWeekColName = (item) => {
    let buyer = item.column_caption.indexOf('заказано') !== -1;
    let sale = item.column_caption.indexOf('продано') !== -1;
    let regexp = /week_[0-9]+/gm;
    let regexpNum = /[0-9]+/gm;
    let str = item.column_name.match(regexp);
    let num = str?.[0].match(regexpNum);

    if (num?.[0]) {
      const d = getLastWeek(num?.[0]);
      if (buyer) return `Заказано ${d}`;
      if (sale) return `Продано ${d}`;
    }
  };

  const getLastWeek = (i) => {
    let weekOfDay = parseInt(moment().format('E'));
    let last_monday = moment().subtract(weekOfDay + 7 * i - 1, 'days').format('DD.MM');
    let last_sunday = moment().subtract(weekOfDay + 7 * (i - 1), 'days').format('DD.MM');
    return `${last_monday}-${last_sunday}`;
  };

  const colsQuery = useQuery('colsQuery', async () => {
    setLoader(true);
    const uid = await cookie.parse(document.cookie)['org_uid'];

    let columns = await getReport({
      id_org: uid,
      primary_data: searchParams.get("id") || ''
    });


      let width: number = 0;
      let colRes = columns?.data?.column.map((item: any, index: number) => {
        // if (item.showed) width += getCellWidth(item);
        return {
          sort: index,
          showed: item.colum !== null ? false : true,
          colum: item.colum,
          column_caption: newWeekColName(item) || item.column_caption,
          column_edit: item.column_edit,
          column_filter: item.column_filter,
          column_name: item.column_name,
          column_type: item.column_type,
          width: item.size ? Number(item.size) : 200,
        };
      });
      const resultFilter = columns?.data?.filter
      .map((f: any) => {
        return {
          ...f,
          column_type: columns.data.column.filter((c:  any) => f.filter === c.column_name)[0].column_type
        }
      });

      let filterResult: any = [];
      resultFilter.forEach((item: any) => {
        const idx = filterResult.findIndex((item2: any) => item2.column === item.filter);
        if (idx === -1) {
          filterResult.push({
            column: item.filter,
            value: [item.value_filter],
            type: Number(item.type)
          });
        } else {
          filterResult[idx].column = item.filter;
          filterResult[idx].type = Number(item.type);
          filterResult[idx].value.push(item.value_filter);
        }
      });

      setPrimaryDataUploadUid(columns.data.primary_data_upload_uid);
      setFilterList(resultFilter);
      setFilterReq(filterResult);
      setTableWidth(width || 0);
      // setCols([...colRes, {column: null, column_caption: 'Исключен из управления', showed: true, sort: colRes.length, width: 200, column_filter: 'True', column_edit: 'True',
      //   column_type: 'Boolean', column_name: 'is_excluded'}]);
      setCols(colRes)
      setColsReq(colRes);
      // setFilterLoaded(true);
      setLoader(false);
  })

  const [page , setPage] = useState(1)
  const [total, setTotalPage] = useState<any>(null)
  const [results, setResults] = useState<any>(null)
  const [datas, setDatas] = useState([])
  const [dataFetch, setDataFetch] = useState<any>(null)



  const fetchParams = async (
    idPrimary: string | null,
    filterReq: any,
    colsReq: any,
    primaryDataUploadUid: string | null | undefined,
    filterLoaded,
    page: any,
    total: any,
  ) => {
    if (colsQuery.isLoading || !filterLoaded) return;
    if (!(primaryDataUploadUid || idPrimary) || (!filterReq || !colsReq.length)) return;
    setLoader(true);
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      let data = 500

        const result = await getData({
          search: search,
          org: uid,
          type: 0,
          primary: idPrimary || primaryDataUploadUid || '',
          column:
            colsReq.length
              ? colsReq
                  .filter((item: any) => {
                    return item.showed;
                  })
                  .map((item: any) => {
                    return item.column_name
                  }) : [],
          filter:  filterReq.length ? filterReq : [{}]
        },page > total && filterReq.length? 0 : page - 1, data);
        const dataFetch = {
          org: uid,
          type: 0,
          primary: idPrimary || primaryDataUploadUid || '',
          column:
            colsReq.length
              ? colsReq
                  .filter((item: any) => {
                    return item.showed;
                  })
                  .map((item: any) => {
                    return item.column_name
                  }) : [],
          filter:  filterReq.length ? filterReq : [{}]
        }
        setDataFetch(dataFetch)
        setTotalPage(result.data.data.maxPages)
        setResults(result.data.data.total)
        setDatas(result.data.data.result)
        let resultTable = result?.data?.data?.result.map((el, rowIndex) => {
          return {
            ...el,
            rowIndex
          }
        });

        colsReq.forEach(col => {
          resultTable.forEach(row => {
            if (col.column_caption.indexOf('<') !== -1)
              if (row[col.column_name] && row[col.column_name].indexOf('.') !== -1)
                row[col.column_name] = row[col.column_name].split('.')[0]
          })
        });

        setFilteredParams(resultTable);
        setFullParams(resultTable);
        let unique: any;
        if(searchParams.get("id") !== '960d6482-dc35-4834-871a-1ee8a26f1d02') {
          unique = resultTable
            .map((item: any) => item.brand)
          unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);
        }
        if(searchParams.get("id") === '960d6482-dc35-4834-871a-1ee8a26f1d02') {
          unique = resultTable
            .map((item: any) => item.name_shares)
          unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);
        }

        setGroupParams(unique);
        // searchElement(search, resultTable);
        return result?.data?.data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  }

  const paramsList = useQuery(
    ['paramsList', {page, search, colsQuery, idPrimary, filterReq, colsReq, primaryDataUploadUid, filterLoaded }],
    () => fetchParams(idPrimary, filterReq, colsReq, primaryDataUploadUid, filterLoaded, page, total), {
      keepPreviousData: true,
    }
  );




  const getOldIdReport = (id: string | undefined) => {
    if (!id) {
      setSnackbarMessage('Старая версия отсутствует');
      setShowSnackbar(true);
      return;
    }
    setIdPrimary(id);
    setOldVersion(true);
    paramsList.refetch();
  };
  const getNewReportVersion = () => {
    setIdPrimary(null);
    setOldVersion(false);
  };

  const newCols = (cols: any[]) => {
    setColsReq(cols);
    setCols(cols);
    let width = 0;
    cols.map((item: any, index: number) => {
      if (item.showed) width += Number(item.width);
    });
    setTableWidth(width || 0);
  };

  const setNewData = (data: any, rowIndex: number, colName: string) => {
    const copyData = [...filteredParams];
    copyData[rowIndex][colName] = data ? 'true' : 'false';
    setFilteredParams(copyData);
  };

  const nameOrg = searchParams.get("name");



  const newData = async(search1) => {
    if(datas.length === 0 && filterReq) {
      const uid =  await cookie.parse(document.cookie)['org_uid'];
      let data = 500

        const result = await getData({
          search: search || search1,
          org: uid,
          type: 0,
          primary: idPrimary || primaryDataUploadUid || '',
          column:
            colsReq.length
              ? colsReq
                  .filter((item: any) => {
                    return item.showed;
                  })
                  .map((item: any) => {
                    return item.column_name
                  }) : [],
          filter:  filterReq.length ? filterReq : [{}]
        },0, data);
        const dataFetch = {
          search: search || search1,
          org: uid,
          type: 0,
          primary: idPrimary || primaryDataUploadUid || '',
          column:
            colsReq.length
              ? colsReq
                  .filter((item: any) => {
                    return item.showed;
                  })
                  .map((item: any) => {
                    return item.column_name
                  }) : [],
          filter:  filterReq.length ? filterReq : [{}]
        }
        setDataFetch(dataFetch)
        setTotalPage(result.data.data.maxPages)
        setResults(result.data.data.total)
        setDatas(result.data.data.result)
        let resultTable = result?.data?.data?.result.map((el, rowIndex) => {
          return {
            ...el,
            rowIndex
          }
        });

        colsReq.forEach(col => {
          resultTable.forEach(row => {
            if (col.column_caption.indexOf('<') !== -1)
              if (row[col.column_name] && row[col.column_name].indexOf('.') !== -1)
                row[col.column_name] = row[col.column_name].split('.')[0]
          })
        });

        setFilteredParams(resultTable);
        setFullParams(resultTable);
        let unique: any;
        if(searchParams.get("id") !== '960d6482-dc35-4834-871a-1ee8a26f1d02') {
          unique = resultTable
            .map((item: any) => item.brand)
          unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);
        }
        if(searchParams.get("id") === '960d6482-dc35-4834-871a-1ee8a26f1d02') {
          unique = resultTable
            .map((item: any) => item.name_shares)
          unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);
        }

        setGroupParams(unique);
        // searchElement(search, resultTable);
        return result?.data?.data;
    }
  }

  const searchElement = (val: string) => {
    setSearch(val);
    // let copyFilteredParams = JSON.parse(JSON.stringify(arr || fullParams));
    // if (val.length > 2) {
    //   copyFilteredParams = copyFilteredParams.filter((item: any) => {
    //     let searched = false;

    //     for (let i = 0; i < cols.length; i++) {
    //       const col = cols[i];

    //       if (!searched)
    //         if (typeof item[col.column_name] === 'string')
    //           if (item[col.column_name].toLowerCase().indexOf(val.toLowerCase()) !== -1)
    //             searched = true;
    //     }

    //     if (searched) return item;
    //     return false;
    //   });
    // }

    // let unique: any;
    // if(searchParams.get("id") !== '960d6482-dc35-4834-871a-1ee8a26f1d02') {
    //   unique = copyFilteredParams
    //     .map((item: any) => item.brand)
    //   unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);
    // }
    // if(searchParams.get("id") === '960d6482-dc35-4834-871a-1ee8a26f1d02') {
    //   unique = copyFilteredParams
    //     .map((item: any) => item.name_shares)
    //   unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);
    // }
    // setGroupParams(unique);

    // setFilteredParams(copyFilteredParams);
  };

  useEffect(() => {
    // newData(search) //Не рабочая хуета, вечный цикл
  }, [datas]);


  return (
    <Main staticName={nameOrg || ''}>
      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />

      {searchParams.get("id") === '7e8d9639-2419-47cd-aca1-0c8829528622'
        ? <SaleParams
            setParams={(val: string) => setWeekOrderdOrSale(val)}
          />
        : null}

      <div className={classes.content}>
        {(filterList && primaryDataUploadUid && !colsQuery.isLoading) && (
          <LoadDataToolbar
            dataFetch={dataFetch}
            colsFetch={colsReq}
            setPage={setPage}
            cols={cols || []}
            count={results}
            filterList={filterList}
            namePage={nameOrg || ''}
            primaryDataUploadUid={primaryDataUploadUid}
            newPrimaryDataUploadUid={(val) => setPrimaryDataUploadUid(val)}
            primary={searchParams.get("id") || ''}
            getOldId={getOldIdReport}
            showAlert={oldVersion}
            getNewVersion={getNewReportVersion}
            newColsReq={newCols}
            newCols={(cols: any) => setCols(cols)}
            sendFilters={(filter: any) => setFilterReq(filter)}
            searchEl={searchElement}
            getSearch={() => newData(search)}
            search={search}
            loader={loader}
            refetch={() => paramsList.refetch()}
            setFilterLoaded={(loaded: boolean) => setFilterLoaded(loaded)}
            showEmptyData={(val) => setShowEmptyData(val)}
          />
        )}

        <Spinner open={loader || !filterLoaded} />

        <LoadDataTable
          search={search}
          cols={cols || []}
          data={filteredParams}
          param={weekOrderdOrSale}
          groups={groupParams}
          tableWidth={tableWidth}
          setNewData={setNewData}
          total={total}
          page={page}
          setPage={setPage}
          results={results}
        />
      </div>
    </Main>
  )
};

export default LoadDataParamsV2;
