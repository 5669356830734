import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
}

const Activity = (props: Props) => {
  return (
    <svg width={props.width || '24px'} height={props.height || '24px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.5 9L9 17" strokeWidth={props.strokeWidth || 2} strokeLinecap="round"/>
      <path d="M12.0002 8.9999L9.00018 16.9999" strokeWidth={props.strokeWidth || 2} strokeLinecap="round"/>
      <path d="M11.9998 8.9999L14.9998 16.9999" strokeWidth={props.strokeWidth || 2} strokeLinecap="round"/>
      <path d="M17.5 9L15 17" strokeWidth={props.strokeWidth || 2} strokeLinecap="round"/>
    </svg>
  )
}

export default Activity;
