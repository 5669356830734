import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { makeStyles, createStyles } from '@material-ui/styles';
import React from 'react';
import '../../../../src/pages/ManagePrices/Pagination.css'

import LoadDataTableBody from './LoadDataTableBody';

const useStyles = makeStyles(() =>
  createStyles({
    paramsAccordion: {
      width: 'fit-content',
      boxShadow: 'none',
      marginTop: '0 !important',

      '&::before': {
        opacity: '0 !important',
      },
    },
    paramsAccordionsSummary: {
      padding: '0 !important',
      borderBottom: '1px solid #E9E9E7',

      '& .MuiAccordionSummary-content': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& p': {
        display: 'flex !important',
        alignItems: 'center !important',
      },
      '& svg': {
        width: 16,
        transition: 'all .2s ease-out',
      },
      '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '24px !important',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
      },
      '& .Mui-expanded svg': {
        transform: 'rotate(90deg)',
      },
    },
    paramsAccordionTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginLeft: 13,
    },
    paramsAccordionsTitleBlock: {
      display: 'flex !important',
      alignItems: 'center !important',
      position: 'sticky',
      top: 0,
      left: 0,
    },
    paramsAccordSuccessChip: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#177367',
      padding: '4px 10px',
      maxWidth: 29,
      maxHeight: 24,
      background: 'rgba(23, 115, 103, 0.1)',
      borderRadius: 8,
    },
    paramsAccordionsDetails: {},
    paramsAccordionsList: {
      padding: 0,
      paddingLeft: 12,
      listStyle: 'none',
      margin: 0,
    },
    paramsAccordionsListItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'justify',
      color: '#827F75',
      marginBottom: 4,
    },
    paramsAccordionDetails: {
      padding: '0 !important'
    },
  })
);

interface Props {
  cols: any;
  data: any;
  param?: string;
  groups?: Array<string>;
  tableWidth?: number;
  setNewData?: (data: any, rowIndex: number, colName: string) => void,
  results?: number,
  total?: any,
  page?: any,
  setPage?: any,
  search?: string
  setDoubles?: any
};

const LoadDataTableAccordion = (props: Props) => {
  const classes = useStyles();

  return (
    <>
      {props.results >= 500
      ?
      props?.groups?.map((group: string, index: number) => (
        <div key={index}>
              <LoadDataTableBody
                search={props.search}
                cols={props.cols || []}
                data={props.data.filter(el => el.brand === group || el.name_shares === group) || []}
                param={props.param}
                tableWidth={props.tableWidth}
                setNewData={props.setNewData}
              />
            <div style={{position: 'fixed', bottom: 0,right: 30, marginBottom: 10}}>
              <Pagination count={props.total} page={props.page} onChange={(_, num) => props.setPage(num)} shape="rounded"/>
            </div>
        </div>
      ))
      :
      props?.groups?.map((group: string, index: number) => (
        <div key={index}>
          <Accordion
            className={classes.paramsAccordion}
            TransitionProps={{ timeout: 0 }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMore />}
              className={classes.paramsAccordionsSummary}
            >
              <div className={classes.paramsAccordionsTitleBlock}>
                <ArrowForwardIos />
                <span className={classes.paramsAccordionTitle}>{group}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.paramsAccordionDetails}>
              <LoadDataTableBody
                search={props.search}
                cols={props.cols || []}
                data={props.data.filter(el => el.brand === group || el.name_shares === group) || []}
                param={props.param}
                tableWidth={props.tableWidth}
                setNewData={props.setNewData}
                setDoubles={props.setDoubles}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      ))
      }
      
    </>
  )
};

export default LoadDataTableAccordion;
