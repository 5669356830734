import { SnackbarOrigin, Snackbar, createStyles, makeStyles, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { downloadListProducts, excelDownloadNew, excelDownloadRegistry } from '../../api/calc';
import { getOrganizations } from '../../api/organizations';
import fileDownload from '../../common/file-download';

const useStyles = makeStyles(() =>
  createStyles({
    customSnackbar: {
      '& .MuiSnackbarContent-root': {
        maxWidth: 512,
        display: 'flex',
        justifyContent: 'space-between',
        background: '#E8F1F0',
        borderRadius: 8,
        padding: '29px 17px',
        paddingBottom: '32px !important',
      },
      '& .MuiSnackbarContent-message': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '16px',
        color: '#FFFFFF',
        padding: 0,
        maxWidth: 370,
      },
      '& .MuiSnackbarContent-action': {
        margin: 0,
        paddingLeft: 32,
      },
    },
    customSnackbarDividir: {},
    customSnackbarBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      marginLeft: 12,
      paddingBottom: 2,
      borderBottom: '1px solid #fff',
      cursor: 'pointer',
    },
    freeTariff: {
      display: 'flex',
    },
    freeTariffIcon: {
      minWidth: 24,
      minHeight: 24,
    },
    freeTariffContent: {
      maxWidth: 258,
      marginLeft: 10,
    },
    freeTariffTitle: {
      display: 'inline-block',
      width: '100%',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginBottom: 4,
    },
    freeTariffText: {
      display: 'inline-block',
      width: '100%',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    freeTariffBtn: {
      padding: '12px 16px',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        background: '#827F75;',
      },
    },
    iconClose: {
        position: 'absolute',
        right: '10px',
        top: '6px',
        color: '#37352F',
    }
  }),
);

export interface State extends SnackbarOrigin {
  open: boolean;
};
interface Props {
  open: boolean;
  data: any;
  message?: string;
  btnText?: string | undefined;
  showAction?: string | undefined;
  closeEvent: () => void;
};
export default function SnackbarMin(props: Props){
  
  const classes = useStyles();

  const newMet = async() => {
    const excel = await excelDownloadRegistry({
        org: props.data.org,
        report_type: props.data.type
      });
    const res = await getOrganizations()
    const nameOrg = res.data.data[0].org_name
    const date = moment().format('DD.MM.YYYY')
    if(nameOrg) {
      fileDownload(excel.data.data, `${nameOrg.replace(/['"«»]/g, '')} реестр по макс порогу ${date}.xlsx`);
    }
    else {
      fileDownload(excel.data.data, `Организация без названия реестр по макс порогу ${date}.xlsx`);
    }
    props.closeEvent()
  }

    
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    if (props.closeEvent) props.closeEvent();
  };


  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={props.open}
      onClose={handleClose}
      message={
        <div className={classes.freeTariff}>
          <div className={classes.iconClose}>{action}</div>
          <svg
            className={classes.freeTariffIcon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.375 22.3304H11.4541H12.2H12.9592H13.025H13.6705H14.6C15.5274 22.3304 16.29 21.5679 16.29 20.6404V15.8404C16.29 15.3608 16.4266 15.0354 16.6788 14.6802C16.8602 14.4248 17.1001 14.1758 17.3803 13.885C17.507 13.7534 17.642 13.6133 17.7836 13.4601L17.7839 13.4597C18.6798 12.4865 19.65 11.1051 19.65 8.64043C19.65 4.53111 16.3093 1.19043 12.2 1.19043C8.09068 1.19043 4.75 4.53111 4.75 8.64043C4.75 11.1452 5.7103 12.645 6.61149 13.6796C6.79724 13.8933 6.98066 14.0899 7.14888 14.2703C7.18161 14.3054 7.21377 14.3399 7.24526 14.3738C7.44098 14.5843 7.60905 14.7693 7.74147 14.9424C8.00502 15.2867 8.11 15.5303 8.11 15.8404V20.6404C8.11 21.5679 8.87255 22.3304 9.8 22.3304H11.375ZM16.6963 12.4758L16.6959 12.4763C16.5934 12.5878 16.4866 12.6994 16.3782 12.8126C16.0759 13.1284 15.7611 13.4572 15.4915 13.8353C15.1134 14.3654 14.83 15.0282 14.83 15.8404V16.7504H9.57V15.8404C9.57 15.1164 9.25399 14.529 8.88822 14.0531C8.66259 13.7595 8.39961 13.4758 8.1393 13.195C7.99242 13.0365 7.84639 12.879 7.7084 12.7211C7.31324 12.268 6.94189 11.7781 6.66725 11.1407C6.39301 10.5042 6.21 9.70827 6.21 8.64043C6.21 5.32663 8.8862 2.65043 12.2 2.65043C15.5138 2.65043 18.19 5.32663 18.19 8.64043C18.19 9.68238 18.0086 10.4271 17.7376 11.0125C17.4657 11.5997 17.0971 12.0418 16.6963 12.4758ZM10.2158 8.37617L10.203 8.37716L10.1903 8.37946C10.1626 8.38449 10.137 8.39116 10.1164 8.39707C9.84873 8.45023 9.63542 8.64817 9.55991 8.90823L9.55972 8.90891C9.48357 9.17401 9.56167 9.45714 9.7611 9.64509L11.47 11.354V13.9804V14.2304H11.72H12.68H12.93V13.9804V11.354L14.6418 9.64221C14.9307 9.35332 14.9307 8.88753 14.6418 8.59865C14.3529 8.30977 13.8871 8.30977 13.5982 8.59865L12.2 9.99688L10.8046 8.60151C10.6518 8.4394 10.4337 8.35941 10.2158 8.37617ZM14.83 18.2504V20.6404C14.83 20.7686 14.7282 20.8704 14.6 20.8704H10.8727C10.7934 20.8573 10.7116 20.8573 10.6323 20.8704H9.8C9.67182 20.8704 9.57 20.7686 9.57 20.6404V18.2504H14.83Z" fill="#37352F" stroke="#37352F" strokeWidth="0.5"/>
            <rect x="6" y="18" width="12" height="3" fill="white"/>
          </svg>

          <div className={classes.freeTariffContent}>
            <span className={classes.freeTariffTitle}>Уведомление</span>
            <span className={classes.freeTariffText}>
            Часть товара, где пользователь не установил максимальный порог, исключена из отчета.
            </span>
          </div>
        </div>
      }
      className={classes.customSnackbar}
      action={
        <div onClick={newMet} className={classes.freeTariffBtn}>
          Выгрузить реестр
        </div>
      }
    />
  );
}
