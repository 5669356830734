import React, { useRef } from 'react'
import { Check } from '@material-ui/icons';
import { makeStyles, createStyles, Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ButtonGroup, TableSortLabel } from '@material-ui/core';
import classNames from 'classnames';
import cookie from 'cookie';
import Main from '../../components/layout/Main'
import { Plus, Search } from '../../components/UI/Icons';
import { useQuery } from 'react-query';
import { getOrganizationsAllUsers } from '../../api/organizations';
import CustomModal from '../../components/UI/CustomModal'
import ReactInputMask from 'react-input-mask';
import { getAllActiveTariffs, TariffsType, updateByPurchaseTariffs } from '../../api/tariffs';
import { AdminUsersFilterMenu } from '../../components/Admin/Users/FilterMenu';
import { setAdminOrg } from '../../api/registration';
import CustomSnackbar from '../../components/UI/CustomSnackbar';
import moment from 'moment';
import { useRows, useUsers } from './store';
import { useStore } from 'zustand';
import * as _ from 'lodash';


const useStyles = makeStyles(() =>
  createStyles({
    users: {
      padding: 32,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 16,
      alignItems: 'baseline',
    },
    toolbarCount: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginRight: 10
    },
    toolbarCountWrap: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    toolbarUpdateBtn: {
      fill: '#37352F',
      marginLeft: 8,
      cursor: 'pointer',
    },
    toolbarBtns: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    toolbarSearchBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      // textDecoration: 'underline',
      cursor: 'pointer',
      marginRight: 16,

      '& svg': {
        stroke: '#37352F',
      },
      '&:hover svg': {
        stroke: '#827F75',
      },
      '&:hover': {
        color: '#827F75',
      },
    },
    toolbarSearchBtnText: {
      marginLeft: 4,
      borderBottom: '1px solid #000',
      paddingBottom: 1,

      '&:hover': {
        borderBottom: '1px solid #827F75',
      }
    },
    toolbarSearch: {
      border: 'none',
      outline: 'none',
      borderBottom: '1px solid #37352F',
      marginLeft: 8,
      marginBottom: 2,
      width: 40,
      transition: 'all .3s ease-out',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:focus': {
        width: 222,
        cursor: 'auto',
      },
      '&::placeholder': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#37352F',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        // textDecoration: 'underline',
        cursor: 'pointer',
        transition: 'all .2s ease-out',
      },
      '&.focus': {
        '&::placeholder': {
          fontFamily: '"Work Sans", sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px',
          color: '#827F75',
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none',
          // textDecoration: 'underline',
        },
      },
    },
    toolbarFilter: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 16,
    },
    toolbarFilterBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '14px',
      color: '#37352F',
      textTransform: 'capitalize',
      borderBottom: '1px solid #000',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:hover': {
        borderBottom: '1px solid #827F75',
        color: '#827F75',
      },
    },
    toolbarAddBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'capitalize',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      padding: '8px 16px 8px 10px',

      '& svg': {
        stroke: '#ffffff',
      },

      '&:hover': {
        background: '#827F75',
      },
    },
    tableContainer: {
      boxShadow: 'none',
      borderTop: '1px solid #E9E9E7',
      borderBottom: '1px solid #E9E9E7',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',

      '& .MuiTableCell-head': {
        padding: '8px 16px 8px 32px',
        borderRight: '1px solid #E9E9E7',
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        padding: '0 16px !important',
        borderRight: '1px solid #E9E9E7',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 16px !important',
        borderRight: 'none',
      },
    },
    userModal: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'calc(786px - 64px)',
        width: '100%',
        padding: 32,
        borderRadius: 8,
      }
    },
    tableRow: {
      height: 43,
   
    },
    tableCell: {
      '& .MuiTableSortLabel-icon': {
        marginRight:0
      }
    },
    userModalHeader: {},
    userModalTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '23px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
      marginBottom: 32,
    },
    userModalClose: {
      position: 'absolute',
      top: 32,
      right: 32,
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',

      '& svg': {
        width: 24,
        height: 24,
      }
    },
    userModalInfoText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#000000',
      margin: 0,
      marginTop: 16,
      marginBottom: 32,
    },
    userModalContent: {
      maxWidth: 688,
      width: '100%',
      paddingTop: 32,
      paddingBottom: 56,
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      width: '100%',
      display: 'inline-block',
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,

      '& span': {
        color: '#FF6C4B',
      },
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: 'calc(100% - 22px)',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      marginBottom: 40,
      overflow: 'hidden',

      small: {
        maxWidth: 300,
      },
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '13px 16px',
      width: '100%',
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,
    },
    smallInput: {
      maxWidth: 300 - 22,
    },
    userModalBtnGroupBlock: {},
    userModalBtnGroup: {
      '& .MuiButton-label': {
        fontFamily: '"Inter", sans-serif',
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '140%',
        color: '#37352F',
      },
      '& .MuiButtonBase-root': {
        padding: '12px 27px !important',
      },
      '& .MuiButtonBase-root:first-child': {
        borderRadius: '8px 0px 0px 8px',
      },
      '& .MuiButtonBase-root:last-child': {
        borderRadius: '0px 8px 8px 0px',
      },
    },
    activeTariff: {
      background: '#E9E9E7',
      border: '1px solid #37352F',
      boxSizing: 'border-box',
    },
    activeConfirmPay: {
      background: '#E9E9E7',
      border: '1px solid #37352F',
      boxSizing: 'border-box',
    },
  }),
);

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useStyles();

  const headCells = [
    {
      id: "organization_name",
      numeric: false,
      disablePadding: true,
      label: "Организация"
    },
    { id: "user_mobile", numeric: true, disablePadding: false, label: "Телефон" },
    { id: "user_email", numeric: false, disablePadding: false, label: "E-mail" },
    { id: "plan_name", numeric: false, disablePadding: false, label: "Тариф" },
    { id: "plan_is_confirmed", numeric: true, disablePadding: false, label: "Подтверждение оплаты" },
    { id: "date_end", numeric: false, disablePadding: false, label: "Дата окончания тарифа" },
    { id: "date_last_update", numeric: false, disablePadding: false, label: "Дата и время последнего изменения" },
  ];

  return (
    <TableHead>
    <TableRow className={classes.tableCell}>
      {headCells.map((headCell) => (
        <TableCell align="center"
          key={headCell.id}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {/* {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </span>
            ) : null} */}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
  );
}

function useFocus(): [any, any] {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

  return [ htmlElRef, setFocus ] 
}

export const AdminUsers = () => {
  const dataStore = useUsers()
  const dataRowsStore = useRows()

  
  const classes = useStyles();
  const cols =  [
    { column_name: 'organization_name', column_caption: 'Организация' },
    { column_name: 'user_mobile', column_caption: 'Телефон' },
    { column_name: 'user_email', column_caption: 'E-mail' },
    { column_name: 'plan_uid', column_caption: 'Тариф' },
    { column_name: 'plan_is_confirmed', column_caption: 'Подтверждение оплаты' },

  ];

  // Организация
  // Телефон
  // E-mail
  // Тариф
  // Подтверждение оплаты
  const [open, setOpen] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [formattPhone, setFormattPhone] = React.useState('');
  const [selectedTariff, setSelectedTariff] = React.useState('');
  const [confirmPay, setConfirmPay] = React.useState(false);
  const [selectedMonth, setSelectedMonth] = React.useState(1);
  const [filter, setFilter] = React.useState<any>({});
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [userRole, setUserRole] = React.useState('');
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [searchRef, setSearchFocus] = useFocus()


  const tariffs = useQuery<Array<TariffsType>>('tariffs', async () => {
    const result = await getAllActiveTariffs();

    return result?.data?.data;
  });

  
  const allUsers = useQuery(['allUsers', filter], async () => {
    setFilteredUsers([]);
    try {
      const result: any = await getOrganizationsAllUsers(filter);
      const resultFiler = dataRowsStore.rows.map(el => {
        if(el.equal instanceof Object && el.where && el.symbol) {
          return {row: el.where, value: el.equal.val, symbol: el.symbol}
        }
        if(el.equal && el.where && el.symbol) {
          return {row: el.where, value: el.equal, symbol: el.symbol}
        }
        else {
          return null
        }
      })
      const rename = result.data.data.map(el => {
        if(!el.organization_name && !el.user_email) {
          return {...el, organization_name: '', user_email: ''}
        }
        if(!el.organization_name) {
          return {...el, organization_name: ''}
        }
        if(!el.user_email) {
          return {...el, user_email: ''}
        }
        else return el
      })

      rename.sort(function(a, b) {
        if (a.organization_name > b.organization_name) {
          return -1;
        }
        if (a.organization_name < b.organization_name) {
          return 1;
        }
        return 0;
      });

      let sorted = _.sortBy(rename, ['plan_name', 'plan_is_confirmed', 'uid']);

      
      const arr: any = []
      
      sorted.map(el => {      
        resultFiler.map(item => {
          if(item) {
            if(item.symbol === '=') {
              if(el[item.row] === item.value) {
                arr.push(el)
              }
            }
            if(item.symbol === '!=') {
              if(el[item.row] !== item.value) {
                arr.push(el)
              }
            }
            if(item.symbol === '>') {
              if(new Date(el[item.row]).getTime() > new Date(item.value).getTime()) {
                arr.push(el)
              }
            }
            if(item.symbol === '<') {
              if(new Date(el[item.row]).getTime() > new Date(item.value).getTime()) {
                arr.push(el)
              }
            }
          }
      })
      })
      
      
      if(resultFiler.length === 0 || resultFiler[0] === null) {
        dataStore.getDataUsers(sorted)
        return sorted;
      }
      
      if(resultFiler.length > 0 || resultFiler[0] !== null) {
        dataStore.getDataUsers(arr)
        return arr
      }
      // dataStore.getDataUsers(rename)
      // setFilteredUsers(rename);
      
    } catch (error) {
      console.error(error);
    }
  });

  const handleClose = () => {
    setOpen(false);
  };

  const selectMonth = (month: number) => {
    setSelectedMonth(month);
  };

  const changePhone = async (phone: string) => {
    await setPhone(phone);
    await setFormattPhone(phone.replace(/\D+/g, ''));
  };

  const saveUser = async () => {
    try {
      if (!name || !phone || !email || !selectedTariff) {
        setSnackbarMessage('Заполните все поля');
        setShowSnackbar(true);
        return;
      }
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const result = await setAdminOrg({
        org: uid,
        name: name,
        phone: phone.replace(/\D+/g, ''),
        email: email,
        plan: selectedTariff,
        plan_is_confirmed: confirmPay,
        month: selectedMonth,
        role: '73ac1ea1-ee77-454e-b5f0-6c9c0dca239a',
      });
  
      if (result) {
        allUsers.refetch();
        setOpen(false);
      }
    } catch (error) {
      setSnackbarMessage(error.data.description[0].reason);
      setShowSnackbar(true);
    }
  };


  const sendFilters = async (column: any) => {
    if (!column.length || (!column[0].where.length && !column[0].equal.length)) {
      setFilter({});
      return;
    }
    setFilter({
      filter: column.map((el: any) => {
        return{
          column: el.where === 'user_name' ? 'users.user_name' : el.where.replace(/_/gi, '.'),
          value: [typeof el.equal === 'object' ? el.equal.val : el.equal ]
        }
      })
    });
  };

  const updateUserTariff = async (id: string, confirm: boolean) => {
    let copyFilteredUsers = JSON.parse(JSON.stringify(dataStore.dataUsers));
    
    dataStore.getDataUsers(copyFilteredUsers.map(el => {
      if (el.uid === id)
        el.plan_is_confirmed = confirm;
      return el;
    }))
    // setFilteredUsers(copyFilteredUsers.map(el => {
    //   if (el.uid === id)
    //     el.plan_is_confirmed = confirm;
    //   return el;
    // }));

    if (!id) {
      setSnackbarMessage('Нет запроса на повышение тарифа');
      setShowSnackbar(true);
      return;
    }

    await updateByPurchaseTariffs(id, {
      planIsConfirmed: confirm,
    });
    allUsers.refetch()
    // if (result) allUsers.refetch();
  };

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = async(value: string) => {

    // let copyFilteredUsers = JSON.parse(JSON.stringify(dataStore.dataUsers));
    const result: any = await getOrganizationsAllUsers({});

    if (value.length > 2) {
   

      const search = result.data.data.filter((item: any) => {
        let searched = false;

        for (const key in item) {
          const element = item[key];

          if (!searched)
            if (typeof element === 'string')
              if (element.toLowerCase().indexOf(value.toLowerCase()) !== -1)
                searched = true;
        }

        if (searched) return item;
        return false;
      });
      dataStore.getDataUsers(search);
    }

    if(value.length === 0) {
      allUsers.refetch();
    }
    
  };

  const mask = "+7 (999) 999-99-99";




  return (
    <>
      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />
      <CustomModal
        title="Добавление пользователя"
        show={open}
        successText="Добавить"
        cancelText="Отмена"
        successEvent={saveUser}
        closeEvent={handleClose}
      >
        <div className={classes.userModalContent}>
          <div className={classes.fieldBlock}>
            <label htmlFor="name" className={classes.fieldLabel}>Наименование организации <span>*</span></label>
            <div className={classes.iconField}>
              <input
                id="name"
                className={classes.field}
                type="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
              />
              <Check className={classNames(classes.checkIcon, name.length ? classes.checkIconActive : '')} />
            </div>
          </div>
          <div className={classNames(classes.fieldBlock, classes.smallInput)}>
            <label htmlFor="name" className={classes.fieldLabel}>Телефон <span>*</span></label>
            <div className={classes.iconField}>
              <ReactInputMask
                id="phone"
                className={classes.field}
                mask={mask}
                value={phone}
                placeholder="+7 (921) 234-45-67"
                onChange={(e: any) => changePhone(e.target.value)}
              />
              <Check className={classNames(classes.checkIcon, formattPhone.length > 10 ? classes.checkIconActive : '')} />
            </div>
          </div>
          <div className={classNames(classes.fieldBlock, classes.smallInput)}>
            <label htmlFor="email" className={classes.fieldLabel}>E-mail</label>
            <div className={classes.iconField}>
              <input
                id="email"
                className={classes.field}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Check className={classNames(classes.checkIcon, email.length ? classes.checkIconActive : '')} />
            </div>
          </div>
          <div className={classes.userModalBtnGroupBlock} style={{marginBottom: 40}}>
            <label className={classes.fieldLabel}>Тариф <span>*</span></label>
            <ButtonGroup className={classes.userModalBtnGroup} variant="outlined">
              {tariffs?.data?.map((tariff: any, idx: number) => (
                <Button
                  className={classNames(selectedTariff === tariff.uid ? classes.activeTariff : '')}
                  onClick={() => setSelectedTariff(tariff.uid)}
                  key={idx}
                >
                  {tariff.planName}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div className={classes.userModalBtnGroupBlock} style={{marginBottom: 40}}>
            <label className={classes.fieldLabel}>Количество <span>*</span></label>
            <ButtonGroup
              className={classes.userModalBtnGroup}
              variant="outlined"
              aria-label="outlined button group"
            >
              <Button
                onClick={() => selectMonth(1)}
                className={
                  classNames(selectedMonth === 1 ? classes.activeConfirmPay : '')
                }
              >
                1
              </Button>
              <Button
                onClick={() => selectMonth(3)}
                className={
                  classNames(selectedMonth === 3 ? classes.activeConfirmPay : '')
                }
              >
                3
              </Button>
              <Button
                onClick={() => selectMonth(6)}
                className={
                  classNames(selectedMonth === 6 ? classes.activeConfirmPay : '')
                }
              >
                6
              </Button>
              <Button
                onClick={() => selectMonth(8)}
                className={
                  classNames(selectedMonth === 8 ? classes.activeConfirmPay : '')
                }
              >
                8
              </Button>
              <Button
                onClick={() => selectMonth(12)}
                className={
                  classNames(selectedMonth === 12 ? classes.activeConfirmPay : '')
                }
              >
                12
              </Button>
            </ButtonGroup>
          </div>
          <div className={classes.userModalBtnGroupBlock}>
            <label className={classes.fieldLabel}>Подтверждение оплаты <span>*</span></label>
            <ButtonGroup className={classes.userModalBtnGroup} variant="outlined">
              <Button
                className={classNames(confirmPay ? classes.activeConfirmPay : '')}
                onClick={() => setConfirmPay(true)}
              >
                Да
              </Button>
              <Button
                className={classNames(!confirmPay ? classes.activeConfirmPay : '')}
                onClick={() => setConfirmPay(false)}
              >
                Нет
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </CustomModal>
      <div className={classes.users}>
        <div className={classes.toolbar}>
          <div className={classes.toolbarCountWrap}>
            {dataStore.dataUsers ?
            <p className={classes.toolbarCount}>Всего {dataStore.dataUsers.length}</p>
            :
            <p className={classes.toolbarCount}>Всего 0</p>
            }
            <span
              title="Обновить список"
              className={classes.toolbarUpdateBtn}
              onClick={() => {
                setOrderBy('')
                allUsers.refetch()}}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M20.944 12.979c-.489 4.509-4.306 8.021-8.944 8.021-2.698 0-5.112-1.194-6.763-3.075l1.245-1.633c1.283 1.645 3.276 2.708 5.518 2.708 3.526 0 6.444-2.624 6.923-6.021h-2.923l4-5.25 4 5.25h-3.056zm-15.864-1.979c.487-3.387 3.4-6 6.92-6 2.237 0 4.228 1.059 5.51 2.698l1.244-1.632c-1.65-1.876-4.061-3.066-6.754-3.066-4.632 0-8.443 3.501-8.941 8h-3.059l4 5.25 4-5.25h-2.92z"/></svg>
            </span>
          </div>

          <div className={classes.toolbarBtns}>
            <div className={classes.toolbarSearchBtn} onClick={setSearchFocus}>
              <Search />
              <input
                type="text"
                placeholder={!openSearch ? 'Поиск' : 'Введите параметр для поиска...'}
                className={classNames(classes.toolbarSearch, openSearch ? 'focus' : '')}
                // onFocus={() => setOpenSearch(true)}
                ref={searchRef}
                onBlur={() => setOpenSearch(false)}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className={classes.toolbarFilter}>
              {/* <span className={classes.toolbarFilterBtn}>Фильтр</span> */}
              <AdminUsersFilterMenu
                cols={cols || []}
                users={dataStore.dataUsers}
                sendFilters={sendFilters}
                setFiltersUser = {setFilteredUsers}
              />
            </div>
            <Button className={classes.toolbarAddBtn} onClick={() => setOpen(true)}>
              <Plus /> Добавить
            </Button>
          </div>
        </div>

        {dataStore.dataUsers &&
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <EnhancedTableHead 
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={dataStore.dataUsers.length}
            />
            <TableBody>
            {stableSort(dataStore.dataUsers, getComparator(order, orderBy))?.map((item: any, index: number) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell >
                    {item.organization_name}
                  </TableCell>
                  <TableCell align="center">
                    {item.user_mobile}
                  </TableCell>
                  <TableCell >
                    {item.user_email}
                  </TableCell>
                  <TableCell align="center">
                    {item.plan_name}
                  </TableCell>
                  <TableCell onClick={() => updateUserTariff(item.uid, !item.plan_is_confirmed)} align="center">
                    {item.uid && <Checkbox checked={item.plan_is_confirmed} color="default" />}
                  </TableCell>
                  <TableCell align="center">
                    {item.date_end &&
                      moment(item.date_end).locale('ru').format('L').replace(/\./g, "-").split('-').reverse().join('-')
                    }
                  </TableCell>
                  <TableCell align="center">
                    {item.date_last_update &&
                      `${moment(item.date_last_update).locale('ru').format('L').replace(/\./g, "-").split('-').reverse().join('-')} ${moment(item.date_last_update).locale('ru').format('LTS')}`
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        }
      </div>
    </>
  )
}
