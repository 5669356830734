import React from 'react'
import '../../index.css'

interface Props {
  currency: number;
  light?: any;
};

const CurrencyFormatter = (props: Props) => {
  const currencyFormat = (num: number) => {
    return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }

  return (
    <span className='vid'>
      {props.light ?
      props.light(currencyFormat(props.currency))
      : currencyFormat(props.currency)
      }
    </span>
  )
}

export default CurrencyFormatter