import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
}

const Dollar = (props: Props) => {
  return (
    <svg width={props.width || '24px'} height={props.height || '24px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 4V21" strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 4H15.5C16.4283 4 17.3185 4.36875 17.9749 5.02513C18.6313 5.6815 19 6.57174 19 7.5C19 8.42826 18.6313 9.3185 17.9749 9.97487C17.3185 10.6313 16.4283 11 15.5 11H5.5" strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.5 16H17" strokeWidth={props.strokeWidth || 2} strokeLinecap="round"/>
    </svg>
  )
}

export default Dollar;
