import React from 'react';
import moment from 'moment';
import { ClockIcon, InfoIcon } from '../../components/UI/Icons';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles: any = makeStyles(() =>
  createStyles({
    needUpdate: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FF6C4B',
      display: 'inline-flex',
      alignItems: 'center',
    },
  }),
);

interface Props {
  typeCheck: 'custom' | 'standard';
  isActual?: boolean;
  lastDateUpload: string;
  uid?: string;
  all?: boolean
};

const ActualInfo = (props: Props) => {
  const classes = useStyles();
  // const details = useQuery('details', async () => {
  //   try {
  //     const uid = await cookie.parse(document.cookie)['org_uid'];
  //     const dates = await dateTrunc(uid);
  //     console.log(dates.data.data?.filter((e: any) => !e?.val))
  //     return dates.data.data;
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //   }
  // });
  const differenceDates = () => {
    const regex = /([0-9]+):([0-9]+)/;
    const a: any = moment().startOf('day');
    const b: any = moment(props.lastDateUpload.replace(regex, '00:00').split('00:00')[0].trim());

    return Math.round(moment.duration(a - b).asDays()) * -1 >= 0;
  };


  const checkActual = () => {
    // console.log()
    switch (props.typeCheck) {
      case 'custom':
        if (props.lastDateUpload) {
          // const days = differenceDates();
          return props.isActual;
        }
        break;
      case 'standard':
        if (props.lastDateUpload) {
          const days = differenceDates();
          if (days) return true;
        }
        break;
      default: return false;
    }
  };

  

  return (
    <>
    {props.uid ?
      props.uid ?
        <>
            <ClockIcon />Актуален {props.lastDateUpload ? `, загружен ${moment(props.lastDateUpload).format('LLL')}` : null}
        </>
        : <span className={classes.needUpdate}><InfoIcon /> Необходимо обновить</span>
      :
      checkActual()
        ? <>
            <ClockIcon />{props.all ? 'Весь товар актуален' : 'Актуален'} {props.lastDateUpload ? `, загружен ${moment(props.lastDateUpload).format('LLL')}` : null}
          </>
        : <span className={classes.needUpdate}><InfoIcon />{props.all ? 'Необходимо обновить весь товар' : 'Необходимо обновить'} </span>
      }
    </>
  );
};

export default ActualInfo;
