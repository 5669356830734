import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
}

const LoadingIcon = (props: Props) => {
  return (
    <svg width={props.width || '48px'} height={props.height || '48px'} stroke={props.color} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 4V12" strokeWidth={props.strokeWidth || 4} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24 36V44" strokeWidth={props.strokeWidth || 4} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.85986 9.86011L15.5199 15.5201" strokeWidth={props.strokeWidth || 4} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.48 32.48L38.14 38.14" strokeWidth={props.strokeWidth || 4} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 24H12" strokeWidth={props.strokeWidth || 4} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M36 24H44" strokeWidth={props.strokeWidth || 4} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.85986 38.14L15.5199 32.48" strokeWidth={props.strokeWidth || 4} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.48 15.5201L38.14 9.86011" strokeWidth={props.strokeWidth || 4} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default LoadingIcon
