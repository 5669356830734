import React from 'react';
import cookie from 'cookie';
import {
  makeStyles,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';
import classNames from 'classnames';
import { Check } from '@material-ui/icons';

import { Plus } from '../../components/UI/Icons'
import Spinner from '../../components/UI/Spinner'
import { useQuery } from 'react-query';
import { organizationsStorage } from '../../api/organizations';
import CustomModal from '../../components/UI/CustomModal';
import { TrashIcon } from '../../components/UI/Icons'
import CustomSnackbar from '../../components/UI/CustomSnackbar';

const useStyles = makeStyles(() =>
  createStyles({
    stockToolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 32,
      paddingBottom: 16,
    },
    content: {
      padding: 32,
    },
    tableContainer: {
      boxShadow: 'none',
      border: 'none',
      // borderBottom: '1px solid #E9E9E7',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderTop: 'none',
      marginBottom: 16,

      '& .MuiTableCell-head': {
        padding: '8px 16px',
        borderRight: '1px solid #E9E9E7',
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        borderRight: '1px solid #E9E9E7',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 16px !important',
        borderRight: 'none',
      },
    },
    stockAddBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'none',
      padding: '12px 16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      marginRight: 16,

      '&:hover': {
        background: '#827F75',
      },
      '&:disabled': {
        background: '#D3D1CB',
        cursor: 'default',
      },
    },
    stockContent: {
      paddingTop: 32,
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      // marginTop: 32,
      marginBottom: 16,

      '& span': {
        color: '#FF6C4B',
      },
    },
    miniField: {
      maxWidth: 300,
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: '100%',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '14px 16px',
      width: '100%',
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,
    },
    tableCell: {
      padding: 0,
    },
    tableCellText: {
      padding: '10px 16px !important',
      margin: 0,
    },
    cellInput: {
      width: '100%',
      // padding: '0 !important',
      boxSizing: 'border-box',
      border: 'none !important',
      outline: 'none',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '10px 16px !important',
      background: 'transparent',

      '&:focus': {
        outline: '1px solid #37352F',
      },
    },
    removeBtn: {
      stroke: '#FF6C4B',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    addBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      stroke: '#37352F',
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all .3s ease-out',
      textAlign: 'center',
      '& svg': {
        marginRight: 4,
      },

      '&:hover': {
        color: '#827F75',
        stroke: '#827F75',
      }
    },
    acceptedChanges: {
      height: '30px',
      width: '120px',
      // background: '#4aa156',
      color: 'black',
      borderRadius: '6px',
      textAlign: 'center'
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  })
);

export const Stocks = () => {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [showAddStock, setShowAddStock] = React.useState(false);
  const [nameStock, setNameStock] = React.useState('');
  const [adressStock, setAdressStock] = React.useState('');
  const [editNameStock, setEditNameStock] = React.useState('');
  const [editAdressStock, setEditAdressStock] = React.useState('');
  const [editUidStock, setEditUidStock] = React.useState('');
  const [showEditFields, setShowEditFields] = React.useState(-1);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false)

  const stockList = useQuery('stockList', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await organizationsStorage({
      organization_uid: uid,
      task: "check"
    });

    return result.data.data;
  });

  const changeEditStockField = (stock: any, index: number) => {
    if(index !== showEditFields) {
      setEditNameStock(stock.name_storage);
      setEditAdressStock(stock.adress_storage);
      setEditUidStock(stock.uid);
      setShowEditFields(index);
      setEditMode(true)
    }
  };
  const [currentItem, setCurrentItem] = React.useState<any>({})
  const updateStock = async (item) => {
    console.log(item)
    if (!editNameStock.length) {
      setSnackbarMessage('Имя склада не может быть пустым');
      setShowSnackbar(true);
      setEditNameStock('');
      setEditAdressStock('');
      setEditUidStock('');
      setShowEditFields(-1);
      return;
    }

    await organizationsStorage({
      uid: currentItem.uid,
      organization_uid: currentItem.organization_uid,
      name_storage: editNameStock || '',
      adress_storage: editAdressStock || '',
      task: "change"
    });
    stockList.refetch();
    setEditNameStock('');
    setEditAdressStock('');
    setEditUidStock('');
    setShowEditFields(-1);
    setEditMode(false)
  };

  const addStock = async () => {
    if (!nameStock.length) {
      setSnackbarMessage('Имя склада не может быть пустым');
      setShowSnackbar(true);
      return;
    }

    const uid = await cookie.parse(document.cookie)['org_uid'];
    try {
      await organizationsStorage({
        organization_uid: uid,
        name_storage: nameStock || '',
        adress_storage: adressStock || '',
        task: "create"
      });
      stockList.refetch();
      hideAddStock();
    } catch (error) {
      setSnackbarMessage(error.data.description[0].reason);
      setShowSnackbar(true);
    }
  };

  const removeStock = async (item: any, index: number) => {
    await organizationsStorage({
      uid: item.uid,
      organization_uid: item.organization_uid,
      name_storage: item.name_storage,
      adress_storage: item.adress_storage,
      task: "delete"
    });
    stockList.refetch();
  };

  const hideAddStock = () => {
    setNameStock('');
    setAdressStock('');
    setShowAddStock(false);
  };
  console.log(currentItem)
  return (
    <>
      <Spinner open={loading} />

      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />

      <div className={classes.content}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width="50%">Название</TableCell>
                <TableCell width="50%">Адрес</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stockList.data ? stockList.data.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => changeEditStockField(item, index)}
                  >
                    <input
                      type="text"
                      className={classes.cellInput}
                      value={showEditFields !== index ? item.name_storage : editNameStock}
                      onChange={(e => setEditNameStock(e.target.value))}
                      onBlur={(e) => setCurrentItem({...item, name_storage: editNameStock})}
                      readOnly={showEditFields !== index}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => changeEditStockField(item, index)}
                  >
                    <input
                      type="text"
                      className={classes.cellInput}
                      value={showEditFields !== index ? item.adress_storage || '' : editAdressStock}
                      onChange={(e => setEditAdressStock(e.target.value))}
                      onBlur={() => setCurrentItem({...item, adress_storage: editAdressStock})}
                      readOnly={showEditFields !== index}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => removeStock(item, index)}
                  >
                    <span className={classes.removeBtn}>
                      <TrashIcon />
                    </span>
                  </TableCell>
                </TableRow>
              )) : null}
              {showAddStock && (
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <input
                      type="text"
                      className={classes.cellInput}
                      value={nameStock}
                      onChange={(e) => setNameStock(e.target.value)}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <input
                      type="text"
                      className={classes.cellInput}
                      value={adressStock}
                      onChange={(e) => setAdressStock(e.target.value)}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => hideAddStock()}
                  >
                    <span className={classes.removeBtn}>
                      <TrashIcon />
                    </span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.footer}>
          {editMode ?
            <div className={classes.acceptedChanges}>
              <span style={{marginTop: 5, color: 'black', textAlign: 'center'}}  className={classes.addBtn} onClick={() => showAddStock ? addStock() : updateStock(currentItem)}>
                 <Check /> Применить
              </span>
            </div> :
            <span className={classes.addBtn} onClick={() => setShowAddStock(true)}>
              <Plus /> Добавить
            </span>
          }

        </div>
      </div>
    </>
  )
}
