import { makeStyles, createStyles } from '@material-ui/core';
import cookie from 'cookie';
import React from 'react';
import { useQuery } from 'react-query';
import { calcStocks, getCalc, getCalcOrg, setCalc } from '../../api/calc';

import { ManagePricesParams } from '../../components/ManagePrices/CurrentRemainder/Params';
import CurrentRemainderToolbar from '../../components/ManagePrices/CurrentRemainder/Toolbar';
import Spinner from '../../components/UI/Spinner';
import { getCellWidth } from '../../common/row-sizes';

import { GridExample } from '../../components/ManagePrices/GridExample';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      height: 'calc(100vh - 63px)',
    },
    tableWrap: {
      overflowX: 'auto',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 198.6px)',
      margin: '0 32px',

      '& .MuiPaper-root': {
        backgroundColor: 'transparent !important',
      },
    },
    tableHeader: {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 8,
    },
    tableContainer: {
      boxShadow: 'none',
      borderTop: '1px solid #E9E9E7',
      boxSizing: 'border-box',
      borderBottom: '1px solid #E9E9E7',
      width: 'fit-content !important'
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',

      '& .MuiTableRow-head': {
        background: '#fff',
      },
      '& .MuiTableCell-head': {
        padding: '8px 16px',
        borderRight: '1px solid #E9E9E7',
        verticalAlign: 'middle',
        textAlign: 'center',
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        padding: '0 16px !important',
        borderRight: '1px solid #E9E9E7',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 16px !important',
        borderRight: 'none',
      },
    },
    tableRow: {
      height: 28,
      '&:hover': {
        background: 'rgba(242, 242, 240, 0.5) !important',
      },
    },
    errorRow: {
      background: 'rgba(255, 108, 75, 0.1)',
      '&:hover': {
        background: '#f2e3df !important',
      },
    },
    successRow: {
      background: 'rgba(23, 115, 103, 0.05)',
      '&:hover': {
        background: '#e5eae8 !important',
      },
    },
    tableCell: {
      minHeight: 28,
      fontSize: '0.775rem',
      padding: 10,
      '&:hover': {
        background: 'rgba(225, 225, 228, 0.5) !important'
      },
    },
    tableCellTh: {
      verticalAlign: 'middle',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      padding: 10,
      position: 'relative',
      backgroundColor: '#fff',
    },
    paramsAccordion: {
      width: 'fit-content',
      boxShadow: 'none',
      marginTop: '0 !important',

      '&::before': {
        opacity: '0 !important',
      },
    },
    paramsAccordionsSummary: {
      padding: '0 !important',

      '& .MuiAccordionSummary-content': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& p': {
        display: 'flex !important',
        alignItems: 'center !important',
      },
      '& svg': {
        width: 16,
        transition: 'all .2s ease-out',
      },
      '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '24px !important',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
      },
      '& .Mui-expanded svg': {
        transform: 'rotate(90deg)',
      },
    },
    paramsAccordionTitle: {
      width: 'fit-content',
      marginLeft: 13,
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    paramsAccordionsTitleBlock: {
      display: 'flex !important',
      alignItems: 'center !important',
    },
    paramsAccordSuccessChip: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#177367',
      padding: '4px 10px',
      maxWidth: 29,
      maxHeight: 24,
      background: 'rgba(23, 115, 103, 0.1)',
      borderRadius: 8,
    },
    paramsAccordionsDetails: {},
    paramsAccordionsList: {
      padding: 0,
      paddingLeft: 12,
      listStyle: 'none',
      margin: 0,
    },
    paramsAccordionsListItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'justify',
      color: '#827F75',
      marginBottom: 4,
    },
    paramsAccordionDetails: {
      padding: '0 !important',
      width: 'fit-content'
    },
    cellCheckbox: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      padding: 0,

      '& svg': {
        fill: '#37352F',
      },
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
  })
);

export const CurrentRemainderDemo = () => {
  const classes = useStyles();
  const fakeRows = [0, 1, 2, 3, 4, 5, 6, 7, 8];

  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [columnsReq, setColumnsReq] = React.useState([]);
  const [groupParams, setGroupParams] = React.useState([]);
  const [filterList, setFilterList] = React.useState([]);
  const [filteredTable, setFilteredTable] = React.useState([]);
  const [fullTable, setFullTable] = React.useState([]);
  const [calcType, setCalcType] = React.useState(1);
  const [weeksCount, setWeeksCount] = React.useState(8);
  const [stockType, setStockType] = React.useState(1);

  useQuery('orgParams', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await getCalcOrg({ org: uid });

    setCalcType(result.data.data[0].calculate_type);
    setWeeksCount(result.data.data[0].weeks_count);
    setStockType(result.data.data[0].stocks_type);
    return result.data.data[0];
  });

  useQuery('cols', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await getCalc({
      org: uid,
      type: '71a95970-2b70-4586-8813-44320ffc5fc1'
    });
    const colsRes = result?.data?.data?.map((item: any) => {
      return {
        colum: item.colum,
        column_caption: item.column_caption,
        column_name: item.column_name,
        column_type: item.column_type,
        showed: item.colum !== null ? false : true,
        width: getCellWidth(item),
      };
    });

    const resultFilter = result?.data?.filter.map((f: any) => {
      return {
        ...f,
        column_type: result.data.data.filter((c:  any) => f.filter === c.column_name)?.[0]?.column_type || 'text'
      }
    });

    setColumns(colsRes.map(el => {
      return {
        ...el,
        title: el.column_caption,
        id: el.column_name
      }
    }));
    setColumnsReq(colsRes);
    setFilterList(resultFilter);
    setFilters(resultFilter.map(el => {
      return { column: el.filter, value: [el.value_filter], type: Number(el.type) }
    }));

    return result?.data?.data;
  });

  const table = useQuery(
    ['table', { filters, columnsReq, calcType, weeksCount, stockType }],
    async (queryParams: any) => {
      try {
        if (!columnsReq.length) return; 
        setLoading(true);
        const uid = await cookie.parse(document.cookie)['org_uid'];
        const colReq: any = [...new Set(queryParams.queryKey[1].columnsReq
          .filter((item: any) => item.showed)
          .map((item: any) => item.column_name)
        )];

        const result: any = await calcStocks({
          org: uid,
          weeks_count: weeksCount,
          calculate_type: calcType || 1,
          stocks_type: stockType,
          colum: colReq,
          filter: filters,
          type: 0,
        });

        let tableResult = result.data.data.data.map((item: any) => {
          let error = false;
          const remainingRestSum = Number(item.remaining_wb_fbs) + Number(item.rest_wb_fbo);
          const ordered_0_1 = remainingRestSum <= Number(item.ordered_0_1);
          const ordered_1_4 = remainingRestSum <= Number(item.ordered_1_4);
          const ordered_1_8 = remainingRestSum <= Number(item.ordered_1_8);
          const ordered_1_11 = remainingRestSum <= Number(item.ordered_1_11);
          if (ordered_0_1 || ordered_1_4 || ordered_1_8 || ordered_1_11) {
            error = true;
          }

          return {...item, ...{ error }};
        });

        let unique = result.data.data.data
          .map((item: any) => item.brand)
        unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);

        setGroupParams(unique);
        setFilteredTable(tableResult);
        setFullTable(tableResult);
        setLoading(false);

        return tableResult;
      } catch (error) {
        setLoading(false);
      }
    },
    {
      keepPreviousData: true,
    }
  );

  const saveData = async (supplier_article: string, val: string | number, colName: string, index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await setCalc({
      org: uid,
      column: 'stocks_staus',
      val: val,
      articl: [supplier_article]
    });

    let copyFilteredTable = JSON.parse(JSON.stringify(filteredTable));

    copyFilteredTable[index][colName] = String(val);
    setFilteredTable(copyFilteredTable);
  };

  const handleSearch = (value: string) => {
    let copyTable = JSON.parse(JSON.stringify(fullTable));

    if (value.length > 2) {
      copyTable = copyTable.filter((item: any) => {
        let searched = false;

        for (const key in item) {
          const element = item[key];

          if (!searched)
            if (typeof element === 'string')
              if (element.toLowerCase().indexOf(value.toLowerCase()) !== -1)
                searched = true;
        }

        if (searched) return item;
        return false;
      });
    }

    let unique = copyTable.map((item: any) => item.brand)
    unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);

    setGroupParams(unique);
    setFilteredTable(copyTable);
  };
  const saveCols = (columns: any) => {
    setColumnsReq(columns);
    setColumns(columns);
  };

  const getAlign = (colType: string) => {
    switch (colType) {
      case 'int': return 'flex-end';
      case 'bool': return 'center';
    };
  };

  const updateTable = (req: any) => {
    setCalcType(req.calculate_type);
    setWeeksCount(req.weeks_count);
    setStockType(req.stocks_type);
    // table.refetch()
  };

  const checkCalcType = (name) => {
    if (calcType === 1) return name.replace(' / продажи ', ' ');
    if (calcType === 2) return name.replace('заказы / ', ' ');
    return name;
  };

  return (
    <>
      <div className={classes.content}>
        <Spinner open={loading} />

        <ManagePricesParams
          updateTable={() => table.refetch()}
          updateParams={(req: any) => updateTable(req)}
          setCalcType={(val: number) => setCalcType(val)}
          calculate_type={calcType}
          weeksCount={weeksCount}
          stockType={stockType}
        />
        <CurrentRemainderToolbar
          filterList={filterList}
          cols={columns || []}
          count={table?.data?.length || 0}
          updateTable={() => table.refetch()}
          sendFilters={(filter: any) => setFilters(filter)}
          sendColumn={(columns: any) => saveCols(columns)}
          newColumnPosition={(columns: any) => setColumns(columns)}
          search={(value) => handleSearch(value)}
          refetch={() => table.refetch()}
        />

        <div className={classes.tableWrap}>
          <GridExample />
        </div>
      </div>
    </>
  )
}

