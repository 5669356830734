import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// const baseURL = 'http://85.175.216.246:8083';

export const getReportList = async (id: string) => {
  const urlAdres = await url()
  return await API.get(`${urlAdres.data.searchpPrimaryData}/primary_data_search/list_of_reports/${id}`);
};
export const getReportHistoryList = async (id1: string, id2 = 'no_report') => {
  const urlAdres = await url()
  return await API.get(`${urlAdres.data.searchpPrimaryData}/primary_data_search/list_of_reports_history/${id1}/${id2}`);
};
export const getPrimaryDataSearchActual = async (org: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.searchpPrimaryData}/primary_data_search/actual`, { org });
};
