import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import Load from '../../assets/icons/load.svg'

const useStyles = makeStyles(() =>
  createStyles({
    documentsItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
    },
    titelDoc: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#37352F',
    },
    castomInputFile: { 
        width: 155,
        height: 40,
        display: 'flex',
        color: 'white',
        backgroundColor: '#37352F',
        borderRadius: 8,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    loadImg: {
        width: 24,
        height: 24,
    }
  }))

const DocumentsItem = ({ nameDocuments, onChanges }) => {
    const classes = useStyles();


    return (
        <div className={classes.documentsItem}>
            <div className={classes.titelDoc}>
                {nameDocuments}
            </div>
            {/*<label className={classes.castomInputFile}>*/}
            {/*    <input type="file" onChange={onChanges} accept='application/pdf'/>*/}
            {/*    <img className={classes.loadImg} alt="load" src={Load}/>*/}
            {/*    <span>Загрузить PDF</span>*/}
            {/*</label>*/}
            
        </div>
    )
}

export default DocumentsItem;