import { Box, Button, ButtonGroup, Checkbox, createStyles, FormControlLabel, makeStyles, Menu, MenuItem, OutlinedInput, Select, Theme, Typography, useTheme } from '@material-ui/core';
import { ErrorIcon, Plus } from '../../UI/Icons';
import { Check } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query';
import cookie from 'cookie';
import * as XLSX from 'xlsx';
import CustomModal from '../../UI/CustomModal';
import { Bell, RightArrow, Search } from '../../UI/Icons'
import { CurrentRemainderFilterMenu } from './FilterMenu'
import { downloadListProducts, excelDownloadAllGoods, excelDownloadListOrder, excelDownloadNew, getFilterStocks, setCalcNumber, setCalcOrg } from '../../../api/calc';
import { findTariff } from '../../../api/tariffs';
import { CurrentRemainderTipsModal } from './TipsModal';
import fileDownload from '../../../common/file-download'
import CustomSnackbar from '../../UI/CustomSnackbar';
import { clearFilter, setColum } from '../../../api/reports';
import CurrentRemainderNotificationModal from './NotificationModal';
import Spinner from '../../UI/Spinner';
import SettingsMenu from '../../UI/SettingsMenu';
import FreeTariffSnackbar from '../../UI/FreeTariffSnackbar';
import moment from 'moment';
import DataNew from '../../../context/context';
import useDebounce from '../../../hooks/useDebounce';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { dateilDate, dateTrunc, getOrganizations } from '../../../api/organizations';
import { excelPrimarySaleWeekDetailing, excelThresholdReport, uploadExcelWbData, wbExcelPrimaryCheck } from '../../../api/wb-data';
import { useNavigate } from 'react-router-dom';
import SnackbarMax from '../../UI/SnackbarMax';
import SnackbarMin from '../../UI/SnackbarMin';
import {ExcelDialog} from "../ExcelDialogComponent/ExcelDialog";
// @ts-ignore
import LoadDataValueMatchingModal from '../../LoadData/ValueMatchingModal';


const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '.MuiPopover-paper': {
        // top: '189px !important',
      },
      '.MuiDialog-paperWidthSm': {
        minWidth: '722px',
        maxWidth: '722px',
      },
    },
    detailsContent: {
      paddingBottom: 32,
    },
    detailsText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginTop: 32,
      marginBottom: 16,
    },
    details: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      minWidth: 112,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 4,

      '& .MuiSvgIcon-root': {
        width: 16, height: 16
      },
      '& .check': {
        fill: '#37352F',
      },
      '& span': {
        whiteSpace: 'nowrap',
      },
    },
    detailsFooter: {
      display: 'flex'
    },
    modalCancelBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'none',
      padding: '12px 16px',
      color: '#37352F',
      border: '1px solid #37352F',
      borderRadius: 8,
      marginLeft: 8,

      '&:hover': {
        color: '#827F75',
        background: '#fff',
        border: '1px solid #827F75',
      },
    },
    wrapProd: {
      border: '2px solid #fa4af7',
      display: 'flex',
      height: 'fit-content',
      flexDirection: 'column',
      padding: 15,
      borderRadius: 10,
    },
    wrapProd1: {
      border: '2px solid #fa4af7',
      display: 'flex',
      height: 'fit-content',
      flexDirection: 'column',
      padding: 15,
      borderRadius: 10,
      background: '#fdf3fb',
    },
    number: {
      color: 'white',
      padding: '2px 7px',
      background: '#bf1ba2',
      borderRadius: 2,
    },
    textNumberProd: {
      fontWeight: 700,
    },
    textNumberProd2: {
      fontWeight: 700,
      color: '#e43015',
    },
    textNumberProd1: {
      fontWeight: 600,
      color: '#c2c2c2',
      marginTop: 10,
      cursor: 'pointer',
    },
    textNumberProd1_1: {
      fontWeight: 700,
      color: '#e43015',
      marginTop: 10,
      cursor: 'pointer',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      padding: '16px 32px',
    },
    toolbarCount: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginRight: 10
    },
    toolbarBtns: {
      display: 'flex',
      alignItems: 'center',
      // flexWrap: 'wrap',
    },
    toolbarSearchBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      cursor: 'pointer',
      marginRight: 16,

      '& svg': {
        stroke: '#37352F',
      },
      '&:hover svg': {
        stroke: '#827F75',
      },
      '&:hover': {
        color: '#827F75',
      },
    },
    toolbarSearchBtnText: {
      marginLeft: 4,
      borderBottom: '1px solid #000',
      paddingBottom: 1,

      '&:hover': {
        borderBottom: '1px solid #827F75',
      }
    },
    warnSearch: {
      borderBottom: '1px solid #fc0101!important',
      color: '#fc0101!important'
    },
    toolbarSearch: {
      border: 'none',
      outline: 'none',
      borderBottom: '1px solid #37352F',
      marginLeft: 8,
      marginBottom: 2,
      width: 222,
      transition: 'all .3s ease-out',
      paddingBottom: 1,
      cursor: 'pointer',
      // '&:focus': {
      //   width: 222,
      //   cursor: 'auto',
      // },
      '&::placeholder': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#37352F',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        cursor: 'pointer',
        transition: 'all .2s ease-out',
      },
      '&.focus': {
        '&::placeholder': {
          fontFamily: '"Work Sans", sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px',
          color: '#827F75',
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none',
        },
      },
    },
    toolbarFilter: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 16,
    },
    toolbarFilterBtn: {
      position: 'relative',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '14px',
      color: '#37352F',
      textTransform: 'capitalize',
      borderBottom: '1px solid #000',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:hover': {
        borderBottom: '1px solid #827F75',
        color: '#827F75',
      },
    },
    settingChip: {
      position: 'absolute',
      top: -10, right: -10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '12px',
      width: 14,
      height: 14,
      color: 'white',
      background: '#FF6C4B',
      borderRadius: '50%',
    },
    toolbarAddBtn: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'capitalize',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      padding: '8px 16px 8px 10px',
      cursor: 'pointer',
      whiteSpace: "pre",

      '& svg': {
        stroke: '#ffffff',
        transform: 'rotate(90deg)',
        marginRight: 4,
      },

      '&:hover': {
        background: '#827F75',
      },
    },
    toolbarIconBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 40,
      marginRight: 16,
      cursor: 'pointer',
    },
    settingsMenu: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 8,
        top: '126px !important',
        transform: 'translateY(60px) !important',
        maxHeight: '70vh',
      },
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    settingsContet: {
      padding: 16,
      maxWidth: 330,
      width: '100vh',
    },
    settingsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,

      '& svg': {
        width: 20,
        height: 20,
      }
    },
    settingsTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    settingsList: {

    },
    settingsItem: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      cursor: 'grab !important',
    },
    settingsItemContent: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      padding: '6px 0',
      borderTop: '1px solid #fff',
      transition: 'all 0.1s ease-in-out',

      '& svg': {
        marginRight: 4,
        minWidth: 16,
      },
    },
    settingsItemSwitch: {
      width: 35,
      height: 18,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#000',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: '#ccc',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 14,
        height: 14,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: 'all .5s ease-in-out',
      },
    },
    settingsReportContent: {
      padding: 8,
    },
    settingsReportItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: 10,
      cursor: 'pointer',
    },
    selectBlock: {
      width: '100%',
      outline: 'none',
      // minHeight: 32,
      border: 'none !important',
      borderRadius: 8,
      marginBottom: 56,
      minHeight: 44,

      '&.Mui-focused': {
        outline: 'none !important',
        border: 'none !important',
      },
      '& .MuiSelect-select': {
        padding: 0,
        background: 'transparent !important',
      }
    },
    selectInput: {
      border: '1px solid #D3D1CB',
      outline: 'none',
    },
    selectChip: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      // marginRight: 3,
      padding: '4px 8px',
      margin: 4,
      // marginTop: 0,
      // marginBottom: 4,
    },
    selectChipClose: {
      width: '16px !important',
      height: '16px !important',
      marginLeft: '8px !important'
    },
    notParamsContent: {
      paddingTop: 32,
      paddingBottom: 32,
    },
    notParamsLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    notParamsText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
      marginBottom: 32,
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 16,

      '& span': {
        color: '#FF6C4B',
      },
    },
    miniField: {
      maxWidth: 300,
      marginBottom: 40,
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,

      '& span': {
        color: '#FF6C4B',
      }
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: '100%',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '14px 16px',
      width: '100%',
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,
    },
    filterFooter: {
      display: 'flex',
      alignItems: 'center',
    },
    filterAddBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      textTransform: 'none',
      border: 'none',
      outline: 'none',
      background: 'none',
      padding: 0,
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        marginRight: 4,
        stroke: '#37352F',
      },
    },
    filterRemoveBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      textTransform: 'none',
      border: 'none',
      outline: 'none',
      background: 'none',
      padding: 0,
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      marginLeft: 8,
    },
    filterMinusIcon: {
      fontSize: 32,
      marginBottom: 4,
      marginRight: 4,
      color: '#37352F',
    },
    reportModal: {
      paddingTop: 32,
    },
    reportModalText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
      marginBottom: 32,
    },
    reportModalBtnGroupWrap: {},
    reportModalLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    reportModalBtns: {
      marginBottom: 32,
    },
    reportModalBtn: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: '#37352F',
      textTransform: 'none',
      background: '#FFFFFF',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      minHeight: '44px !important',
      minWidth: '210px !important',

      '&:last-child': {
        borderRadius: '0px 8px 8px 0px',
      },
    },
    reportModalFirstBtn: {
      boxSizing: 'border-box',
      borderRadius: '8px 0px 0px 8px',
    },
    reportModalLastBtn: {
      borderRadius: '0px 8px 8px 0px',
    },
    active: {
      background: '#E9E9E7',
      border: '1px solid #37352F',
    },
    listModalContent: {
      paddingTop: 32,
      paddingBottom: 32,
    },
    listModalLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    listModalCheckbox: {
      width: '100%',
      marginBottom: 10,
      '& span': {
        fontSize: 14,
      },
    },
    settingsCancelBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 8,
      paddingBottom: 8,
    },
    settingsCancel: {
      cursor: 'pointer',
      borderBottom: '1px solid #37352F',
    },
    setDataModalChips: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 4,
    },
    setDataModalChip: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      marginRight: 3,
      padding: '4px 8px',
    },
    setDataModalChipClose: {
      width: '16px !important',
      height: '16px !important',
      marginLeft: '8px !important'
    },
    loadBtn: {
      color: '#FFFFFF',
      fontSize: 14,
      background: '#37352F',
      fontStyle: 'normal',
      fontFamily: "Work Sans",
      fontWeight: 400,
      borderRadius: 8,
      textTransform: 'none',
      padding: '8px 10px',
    }
  })
)

function useFocus(): [any, any] {
  const htmlElRef = useRef(null)
  const setFocus = () => { htmlElRef.current && htmlElRef.current?.focus() }

  return [htmlElRef, setFocus]
}

interface Props {
  cols: Array<{
    column_caption: string;
    column_name: string;
    showed: boolean;
  }>;
  count?: number;
  filterList: any;
  sendFilters?: (filter: any) => void;
  sendColumn?: (column: any) => void;
  newColumnPosition?: (column: any) => void;
  updateTable?: () => void;
  search?: (value: string) => void;
  refetch?: () => void;
  setLoading?: () => void;
  setToggleBum?: any,
  setOranges?: any,
  setPage?: any,
  dataFetch?: any,
  columnFetch?: any,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null
};
const getStylesCategories = (name: string, personName: readonly string[], theme: Theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ManagePricesToolbar = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { funcOrange } = useContext(DataNew)
  const setOrange = funcOrange

  const [loaded, setLoaded] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [cols, setCols] = React.useState<any>([]);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [reportModalOpen, setReportModalOpen] = React.useState(false);
  const [reportModalType, setReportModalType] = React.useState<string | null>(null);
  const [listModalOpen, setListModalOpen] = React.useState(false);
  const [listModalOpen1, setListModalOpen1] = React.useState(false);
  const [modalRapids, setModalRapids] = React.useState(false);
  const [showTip, setShowTip] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorReport, setAnchorReport] = React.useState<null | HTMLElement>(null);
  const [notParamsOpen, setNotParamsOpen] = React.useState(false);
  const [accesses, setAccesses] = React.useState<Array<any>>([]);
  const [openSelect, setOpenSelect] = React.useState(-1);
  const [openForParamsSelect, setOpenForParamsSelect] = React.useState(false);
  const [forParams, setForParams] = React.useState<any>({
    brand: [],
    category: [],
    name_: []
  });
  const [radioBtn, setRadioBtn] = React.useState('');
  const [countPoints, setCountPoints] = React.useState('');
  const [countPercent, setCountPercent] = React.useState('');
  const [priceOrStockParam, setPriceOrStockParam] = React.useState(1);
  const [priceOrStockParam1, setPriceOrStockParam1] = React.useState(1);
  const [priceOrStockParam3, setPriceOrStockParam3] = React.useState(1);
  const [fromBtnsParam, setFromBtnsParam] = React.useState('category');
  const [unitParam, setUnitParam] = React.useState();
  const [price, setPrice] = React.useState('');
  const [percent, setPercent] = React.useState('');
  const [percent1, setPercent1] = React.useState('');
  const [percent2, setPercent2] = React.useState('');
  const [percent3, setPercent3] = React.useState('');
  const [currentCountSmaller, setCurrentCountSmaller] = React.useState(false);
  const [currentEqualZero, setCurrentEqualZero] = React.useState(false);

  const [currentRapid1, setCurrentRapid1] = React.useState(false)
  const [currentRapid2, setCurrentRapid2] = React.useState(false)
  const [currentRapid3, setCurrentRapid3] = React.useState(false)

  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [showNotify, setShowNotify] = React.useState(false);
  const [tipsList, setTipsList] = React.useState([]);
  const [tipsActiveChip, setTipsActiveChip] = React.useState(false);
  const [notifyActiveChip, setNotifyActiveChip] = React.useState(false);
  const [settingsCount, setSettingsCount] = React.useState(0);
  const [searchLength, setSearchLength] = React.useState(0);
  const [currentFilter, setCurrentFilter] = React.useState([]);
  const [categoriesData, setCategoriesData] = React.useState(
    [
      { categories: [], points: '', percent: '' }
    ]
  );
  const [searchRef, setSearchFocus] = useFocus()
  const openReport = Boolean(anchorReport);
  const [searchWarn, setSearchWarn] = useState(false)
  const [searchIsSended, setSearchIsSended] = useState(false)

  const priceOrStockBtns = [
    { id: 1, name: 'Повысить розничную цену' },
    { id: 0, name: 'Снизить скидку' },
  ];
  const priceOrStockBtns1 = [
    { id: 1, name: 'Спрос есть' },
    { id: 0, name: 'Спроса нет' },
  ];

  const priceOrStockBtns2 = [
    { id: 1, name: 'Снизить розничную цену' },
    { id: 0, name: 'Повысить скидку' },
  ];

  const unitBtns = [
    { id: 1, name: '₽' },
    { id: 0, name: '%' },
  ];
  const fromBtns = [
    { id: 'brand', name: 'Бренд' },
    { id: 'category', name: 'Категория' },
    { id: 'name_', name: 'Товар' },
  ];
  // {id: 'name_', name: 'Товар'},

  const handleOpenForParamsSelect = (e: any) => {
    if (!e?.target?.className.length) {
      setOpenForParamsSelect(false);
      return;
    }
    if (e?.target?.tagName === 'svg' || e?.target?.tagName === 'path') {
      return;
    }
    setOpenForParamsSelect(true);
  };


  const handleCloseSelect = () => {
    setOpenSelect(-1);
  };
  const handleOpenSelect = (index: number, e: any) => {
    if (!e?.target?.className?.length) {
      setOpenSelect(-1);
      return;
    }
    if (e?.target?.tagName === 'svg' || e?.target?.tagName === 'path') {
      return;
    }
    setOpenSelect(index);
  };

  const removeChip = (id: string, index: number) => {
    let selectedCopy: any = JSON.parse(JSON.stringify(categoriesData));
    selectedCopy[index].categories =
      selectedCopy[index].categories.filter((item: any) => item !== id);
    setCategoriesData(selectedCopy);
  };

  const handleCloseForParams = () => {
    setOpenForParamsSelect(false);
  };

  const filterStocks = useQuery<any>('filterStocks', async () => {
    if (filterStocks.isLoading) return;
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await getFilterStocks({
      weeks_count: 8,
      calculate_type: 1,
      stocks_type: 1,
      org: uid,
      colum: 'category',
    });

    return result.data.data;
  });

  const filterStocksFromParam = useQuery<any>(['filterStocksFromParam', fromBtnsParam], async () => {
    if (filterStocks.isLoading) return;
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await getFilterStocks({
      weeks_count: 8,
      calculate_type: 1,
      stocks_type: 1,
      org: uid,
      colum: fromBtnsParam,
    });
    return result.data.data;
  });

  const [myTarif, setTarif] = useState<any>(null)
  useEffect(() => {
    const fetch = async () => {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const plan = await findTariff(uid);
      setTarif(plan.data.data.planName)
    }
    fetch()
  }, []);
  useEffect(() => {
    if (loaded) return;
    if (props.cols.length) {
      let count = 0;
      props.cols.forEach((item: any) => {
        if (!item.showed) {
          count += 1;
        }
      });

      setCols(props?.cols);
      setSettingsCount(count);
      setLoaded(true);
    }
  });


  const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSettings(true);
  };
  const handleCloseSettings = () => {
    setShowSettings(false);
    if (props.sendColumn) props.sendColumn(cols);
  };

  const handleClickReportBtn = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorReport(event.currentTarget);
  };

  const handleCloseReport = () => {
    setAnchorReport(null);
  };

  const sendFilters = (filters: any) => {
    if (props.sendFilters) props?.sendFilters(filters);
  };

  const [currentItem, setCurrentItem] = React.useState<any>();
  const [currentItemIdx, setCurrentItemIdx] = React.useState<number>(-1);

  const onDragStartEvent = (e: any, item: any, index: number) => {
    setCurrentItem(item);
    setCurrentItemIdx(index);
  };
  const onDragOverEvent = (e: any, item: any, index: number) => {
    e.preventDefault();
    if (e.target.className.includes('settingsItemContent')) {
      e.target.style.borderTop = '10px solid rgba(255, 108, 75, 0.2)';
    }
  };
  const onDragLeaveEvent = (e: any) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDragEndEvent = (e: any) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDropEvent = (e: any, overedItemIdx: number) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
    let colsCopy = JSON.parse(JSON.stringify(cols));
    colsCopy.splice(currentItemIdx, 1);
    colsCopy.splice(overedItemIdx, 0, currentItem);
    setCols(colsCopy);
    if (props.newColumnPosition) props.newColumnPosition(colsCopy);
  };
  const onChangeCity = async (index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyCols = JSON.parse(JSON.stringify(cols));
    let curEl: any = null;

    copyCols[index].showed = !copyCols[index].showed;
    curEl = copyCols[index];
    setCols(copyCols);

    if (curEl) {
      await setColum({
        colum: curEl.column_name,
        organization_uid: uid,
        calc: '71a95970-2b70-4586-8813-44320ffc5fc1',
        del: curEl.showed,
      });
    }

    let count = 0;
    copyCols.forEach((item: any) => {
      if (!item.showed) {
        count += 1;
      }
    });

    setSettingsCount(count);
  };

  const downloadThresholdReport = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let reportType = ''
    if (currentRapid1) reportType = 'limit_one';
    if (currentRapid2) reportType = 'limit_two';
    if (currentRapid3) reportType = 'limit_three';

    const result = await excelThresholdReport({
      org: uid,
      report_type: reportType
    })
    const workbook = XLSX.read(result.data.data, { type: 'base64' });
    let worksheets: any = {};

    for (const sheetName of workbook.SheetNames) {
      worksheets[sheetName] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
    };

    const res = await getOrganizations()
    const nameOrg = res.data.data[0].org_name
    const date = moment().format('DD.MM.YYYY')
    if (reportType === 'limit_one') {
      fileDownload(result.data.data, `PM, нет мин.цен ${nameOrg ? nameOrg.replaceAll(/['"«»/\\?.,]/g, '').replaceAll(/[<]/g, '(М)').replaceAll(/[>]/g, '(Б)') : 'Без названия'}-(${worksheets.Лист1.length}) ${date}.xlsx`);
    }
    if (reportType === 'limit_two') {
      fileDownload(result.data.data, `PM, нет макс. цен ${nameOrg ? nameOrg.replaceAll(/['"«»/\\?.,]/g, '').replaceAll(/[<]/g, '(М)').replaceAll(/[>]/g, '(Б)') : 'Без названия'}-(${worksheets.Лист1.length}) ${date}.xlsx`);
    }
    if (reportType === 'limit_three') {
      fileDownload(result.data.data, `PM, тек. цена после скидки (М) мин цены ${nameOrg ? nameOrg.replaceAll(/['"«»/\\?.,]/g, '').replaceAll(/[<]/g, '(М)').replaceAll(/[>]/g, '(Б)') : 'Без названия'}-(${worksheets.Лист1.length}) ${date}.xlsx`);
    }
    setShowSnackbar(true);
    setSnackbarMessage('Отчет успешно выгружен');
    hideAllModal()
  }

  const downloadListOrder = async (toggle) => {
    if (toggle === false) {
      props.setLoading(true);
    }
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const plan = await findTariff(uid);
    let typeSelect = 0;

    if (!currentCountSmaller && !currentEqualZero) typeSelect = 10;
    if (!currentCountSmaller && currentEqualZero) typeSelect = 0;
    if (currentCountSmaller && !currentEqualZero) typeSelect = 1;
    if (currentCountSmaller && currentEqualZero) typeSelect = 2;

    if (typeSelect === 10 && toggle === true) {
      setSnackbarMessage('Выберите условие');
      setShowSnackbar(true);
      props.setLoading(false);
    }

    else {
      const result = await excelDownloadListOrder({
        org: uid,
        plan: plan.data.data.uid || '45124e44-22d7-4714-9ad4-83129b787202',
        type: typeSelect,
        info: toggle
      });
      if (result.data.data && toggle === true) {
        setNumberProd1(result.data.data.product_count)
        setStyleProduct(false)
      }

      if (!result.data.success && result.data.description) {
        setSnackbarMessage(result.data.description[0].reason);
        setShowSnackbar(true);
      }

      if (result.data.data && toggle === false) {
        const role = await cookie.parse(document.cookie)['guid'];
        if (!role.includes('Техсотрудник')) {
          hideAllModal();
          setShowSnackbar(true);
          setCurrentEqualZero(false);
          setCurrentCountSmaller(false);
        }
        fileDownload(result.data.data, 'export.xlsx');
        setButtonProfile(false)
        setSnackbarMessage('Отчет успешно выгружен');
        document.cookie = `orange = ${''}`;
        props.setOranges([''])
      }
    }




    props.setLoading(false);

  };

  const setCalcNumberArr = () => { };

  const saveNoParam = async () => {
    setLoader(true);
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const plan = await findTariff(uid);
    let error = false;
    categoriesData.forEach((item: any) => {
      if (!item.categories.length) error = true;
      if (!item.points) error = true;
      if (!item.percent) error = true;
    });

    if (error) {
      setButtonProfile(false)
      setSnackbarMessage('Заполните все поля');
      setShowSnackbar(true);
      setLoader(false);
      return;
    }

    let categoryArr: any = [];
    categoriesData.forEach(async (item: any) => {
      categoryArr = [...categoryArr, ...item.categories]
      await setCalcNumber({
        org: uid,
        number_change_points: item.points,
        number_change_percent: item.percent,
        articl: item.categories,
      });
    });

    setTimeout(async () => {
      const excel = await excelDownloadAllGoods({
        org: uid,
        plan: plan.data.data.uid || '45124e44-22d7-4714-9ad4-83129b787202',
        type: 1,
        category: fromBtnsParam === 'category' ? categoryArr : [],
      });

      fileDownload(excel.data.data, 'export.xlsx');
      if (props.updateTable) props.updateTable();
      hideAllModal();
      setLoader(false);
      setButtonProfile(false)
      setSnackbarMessage('Отчет успешно выгружен');
      document.cookie = `orange = ${''}`;
      setShowSnackbar(true);
      categoriesData.forEach((el, index) => {
        handleChangeCategory([], index);
        handleCountPoints('', index);
        handleCountPercent('', index);
      })
    }, 2000);
    // setReportModalOpen(true);
  };

  const sendReport = async (toggle) => {

    if (toggle === false) {
      setLoader(true);
    }
    try {
      if (reportModalType === 'products') {
        if (!percent3.length) {
          setButtonProfile(false)
          setSnackbarMessage('Заполните все поля');
          setShowSnackbar(true);
          return;
        }
      }
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const plan = await findTariff(uid);
      const result = await setCalcOrg({
        org: uid,
        rubles_procent: unitParam ? unitParam : 0,
        max_sale: priceOrStockParam,
        percent_more: String(percent3),
        max_sale_value_1: priceOrStockParam ? Number(price) || Number(percent) : undefined,
        max_sale_value: !priceOrStockParam ? Number(price) || Number(percent) : undefined,
        check_max_value: checkMaxVal ? 1 : 0,
        check_min_value: Number(minCost),
        percent_fork_2250: Number(isPriceIncrease),
      });



      if (reportModalType === 'goods') {
        const excel = await excelDownloadAllGoods({
          org: uid,
          plan: plan.data.data.uid || '45124e44-22d7-4714-9ad4-83129b787202',
          type: 1,
          brand: fromBtnsParam === 'brand' ? forParams?.[fromBtnsParam] : [],
          category: fromBtnsParam === 'category' ? forParams?.[fromBtnsParam] : [],
          name_: fromBtnsParam === 'name_' ? forParams?.[fromBtnsParam] : [],
          info: toggle
        });
        if (toggle === false) {
          fileDownload(excel.data.data, 'export.xlsx')
        }
        if (toggle === true) {
          setNumberProd2(excel.data.data.product_count)
          setStyleProduct(false)
        }
      }

      if (reportModalType === 'products') {
        excelDownloadListProduct(toggle);
      }

      if (toggle === false) {
        const role = await cookie.parse(document.cookie)['guid'];
        if (!role.includes('Техсотрудник')) {
          hideAllModal();
          clearFields();
        }
        setButtonProfile(false)
        setSnackbarMessage('Отчет успешно выгружен');
        document.cookie = `orange = ${''}`;
        props.setOranges([''])
        setShowSnackbar(true);
        // setUnitParam(undefined);
        // setPercent('');
        // setFromBtnsParam('');
        // handleChangeForParams('');
        // setForParams({
        //   brand: [],
        //   category: [],
        //   name_: []
        // });
      }


      return result?.data?.data;
    } catch (error) {
      setSnackbarMessage(error?.data?.description[0]?.reason);
      setShowSnackbar(true);
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const [dataFetch, setDataFetch] = useState(null)

  useEffect(() => {
    const uid = cookie.parse(document.cookie)['org_uid'];
    setDataFetch({
      org: uid,
    })
  }, []);

  const excelDownloadListProduct = async (toggle) => {
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const plan = await findTariff(uid);
      const excel = await downloadListProducts({
        org: uid,
        plan: plan.data.data.uid || '45124e44-22d7-4714-9ad4-83129b787202',
        type: 1,
        brand: fromBtnsParam === 'brand' ? forParams?.[fromBtnsParam] : [],
        category: fromBtnsParam === 'category' ? forParams?.[fromBtnsParam] : [],
        name_: fromBtnsParam === 'name_' ? forParams?.[fromBtnsParam] : [],
        info: toggle,
      });
      if (toggle === false) {
        if (excel.data.description[0].reason) {
          setDataFetch({
            org: uid,
            type: 'list_products'
          })
          setSnackbar(true)
          setShowSnackbar(false);
        }
        fileDownload(excel.data.data, 'export.xlsx');
      }
      if (toggle === true) {
        setNumberProd3(excel.data.data.product_count)
        setStyleProduct(false)
      }
    } catch (error) {
      setSnackbarMessage(error.data.description[0].reason);
      setShowSnackbar(true);
      console.error(error);
    }
  };

  const [toggleNotif, setToggleNotif] = React.useState(false)

  const excelDownloadNewMy = async () => {
    setLoader(true);
    props.setToggleBum(true)
    try {

      let type_descending = 0
      if (priceOrStockParam1 === 1 && unitParam === 1) type_descending = 1;
      if (priceOrStockParam1 === 1 && unitParam === 0) type_descending = 2;
      if (priceOrStockParam1 === 0 && unitParam === 0) type_descending = 3;

      if (priceOrStockParam3 === 1) {
        if (percent.length === 0) {
          setSnackbarMessage('Заполните все поля');
          setShowSnackbar(true);
          return;
        }
      }

      if (type_descending === 0) {
        setSnackbarMessage('Заполните все поля');
        setShowSnackbar(true);
        return;
      }

      if (type_descending === 1) {
        if (percent1.length === 0) {
          setSnackbarMessage('Заполните все поля');
          setShowSnackbar(true);
          return;
        }
      }
      if (type_descending === 2) {
        if (percent1.length === 0) {
          setSnackbarMessage('Заполните все поля');
          setShowSnackbar(true);
          return;
        }
      }

      if (type_descending === 3) {
        if (percent2.length === 0) {
          setSnackbarMessage('Заполните все поля');
          setShowSnackbar(true);
          return;
        }
      }
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const plan = await findTariff(uid);
      const result = await setCalcOrg({
        org: uid,
        demand: priceOrStockParam3,
        percent_more: priceOrStockParam3 === 1 ? String(percent) : '0',
        type_descending: type_descending,
        value_descending: priceOrStockParam1 === 1 ? Number(percent1) : Number(percent2),
        check_min_value: currentCountMin ? 1 : 0,
        check_avg_value: currentCountSred ? 1 : 0,
        check_max_value: checkMax ? 1 : 0,
        percent_fork_2250: Number(isPriceIncrease),
      });

      const excel = await excelDownloadNew({
        org: uid,
        plan: plan.data.data.uid || '45124e44-22d7-4714-9ad4-83129b787202',
        type: 1,
        brand: fromBtnsParam === 'brand' ? forParams?.[fromBtnsParam] : [],
        category: fromBtnsParam === 'category' ? forParams?.[fromBtnsParam] : [],
        name_: fromBtnsParam === 'name_' ? forParams?.[fromBtnsParam] : [],
      });
      let today = new Date();
      let date = moment(today).format('LL')

      const base64str = excel.data.data



      const workbook = XLSX.read(base64str, { type: 'base64' });
      let worksheets: any = {};

      for (const sheetName of workbook.SheetNames) {
        worksheets[sheetName] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      };


      const orangeId: Array<number> = []
      worksheets.Лист1.map((el) => {
        orangeId.push(el['Артикул WB'])
      })

      document.cookie = `orange = ${orangeId}`;
      let org = cookie.parse(document.cookie)['orange'];
      const oranges = [org]
      props.setOranges(oranges)

      if (excel.data.description[0].reason) {
        setDataFetch({
          org: uid,
          type: 'list_products'
        })
      }
      if (checkMax || currentCountMin) {
        if (excel.data.description[0].reason === '1') {
          setSnackbar(true)
          setShowSnackbar(false);
        }
        if (excel.data.description[0].reason === '2') {
          setSnackbar1(true)
          setShowSnackbar(false);
        }
        if (excel.data.description[0].reason === '3') {
          setSnackbar1(true)
          setSnackbar(true)
          setShowSnackbar(false);
        }
      }

      fileDownload(excel.data.data,
        `PM, ${priceOrStockParam3 === 1 ? `cпроос есть (${String(percent)}%)` : 'cпроса нет'}, ${priceOrStockParam1 === 1 && unitParam === 1 ? `понижение цены (${percent1}р) -` : ''}${priceOrStockParam1 === 1 && unitParam === 0 ? `понижение цены (${percent1}%) -` : ''}${priceOrStockParam1 === 0 && unitParam === 0 ? `повышение скидки (${percent2}%) -` : ''}${worksheets.Лист1.length}шт - ${date}.xlsx`)
      const role = await cookie.parse(document.cookie)['guid'];
      if (!role.includes('Техсотрудник')) {
        hideAllModal();
        clearFields();
      }

      setStyleProduct(false)
      setButtonProfile(false)
      setToggleNotif(prev => !prev)
      localStorage.setItem('orangeDay', moment(new Date).format('L').substr(0, 2))
      setSnackbarMessage('Отчет успешно выгружен');
      setShowSnackbar(true);
      return result?.data?.data;

    } catch (error) {
      setShowSnackbar(true);
      setSnackbarMessage(error.data.description[0].reason);
      if (error.data.data.reason) {
        const uid = await cookie.parse(document.cookie)['org_uid'];
        // console.log('мы попали куда нужно')
        setDataFetch({
          org: uid,
          type: 'report_desc_price'
        })
        if (currentCountMin || checkMax) {
          if (error.data.data.reason === '1') {
            setSnackbar(true)
            setShowSnackbar(false);
          }
          if (error.data.data.reason === '2') {
            setSnackbar1(true)
            setShowSnackbar(false);
          }
          if (error.data.data.reason === '3') {
            setSnackbar(true)
            setSnackbar1(true)
            setShowSnackbar(false);
          }
        }
      }
      console.error(error);
    } finally {
      setLoader(false);
    }
  };



  const handleSearch = (value: string) => {
    setSearchLength(value.length);
    if (props.search) props.search(value);
  };
  const closeSearch = () => {
    if (searchLength > 0) return;
    if (searchLength === 0) setOpenSearch(false);
  };

  const handleChangeCategory = (event: any, index: number) => {
    const { target: { value } } = event;
    let copyCategory = JSON.parse(JSON.stringify(categoriesData));

    copyCategory[index].categories = value;

    // handleCloseSelect();
    setCategoriesData(copyCategory);
  };

  const handleCountPoints = (value: string, index: number) => {
    let copyCategory = JSON.parse(JSON.stringify(categoriesData));

    copyCategory[index].points = value;

    handleCloseSelect();
    setCategoriesData(copyCategory);
  };
  const handleCountPercent = (value: string, index: number) => {
    let copyCategory = JSON.parse(JSON.stringify(categoriesData));

    copyCategory[index].percent = value;

    handleCloseSelect();
    setCategoriesData(copyCategory);
  };

  const addCategory = () => {
    let copyCategory = JSON.parse(JSON.stringify(categoriesData));
    copyCategory.push({ categories: [], points: '', percent: '' });
    setCategoriesData(copyCategory);
  };
  const removeCategory = () => {
    let copyCategory = JSON.parse(JSON.stringify(categoriesData));
    copyCategory.pop();
    setCategoriesData(copyCategory);
  };

  const cancelSettings = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await clearFilter({
      organization_uid: uid,
      primary_data: '71a95970-2b70-4586-8813-44320ffc5fc1',
      type: 1
    });
    await clearFilter({
      organization_uid: uid,
      primary_data: '71a95970-2b70-4586-8813-44320ffc5fc1',
      type: 2
    });

    let copyCols = JSON.parse(JSON.stringify(cols));

    copyCols.forEach((item: any) => item.showed = true);
    setCols(copyCols);

    let count = 0;
    copyCols.forEach((item: any) => {
      if (!item.showed) {
        count += 1;
      }
    });

    setSettingsCount(count);

    if (props.refetch) props.refetch();
  };

  const cancelFilter = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await clearFilter({
      organization_uid: uid,
      primary_data: '71a95970-2b70-4586-8813-44320ffc5fc1',
      type: 3
    });

    if (props.refetch) props.refetch();
  };

  const handleChangeForParams = (value: any) => {
      let copy = JSON.parse(JSON.stringify(forParams));
      copy[fromBtnsParam] = value;
      setForParams(copy);
    // handleCloseForParams();
  };

  const removeFromBtnChip = (id: string) => {
    let selectedCopy: any = JSON.parse(JSON.stringify(forParams));
    selectedCopy[fromBtnsParam] = selectedCopy[fromBtnsParam].filter((item: any) => item !== id);
    setForParams(selectedCopy);
  };

  const openReportModal = (type: 'goods' | 'products') => {
    if (myTarif === 'Демо') {
      setCheckMaxVal(false)
    }
    else  {
      setCheckMaxVal(true)
    }
    setMinCost(true)
    setCurrentMin(!currentCountMin);
    setCurrentMinNum(1);
    setReportModalOpen(true);
    filterStocksFromParam.refetch()
    setReportModalType(type);
    setNumberProd3(0);
  };

  const hideAllModal = () => {
    handleCloseReport();
    setNotParamsOpen(false);
    setModalRapids(false);
    setReportModalOpen(false);
    setListModalOpen(false);
    setListModalOpen1(false);
    clearFields();
  };
  const clearFields = () => {
    setCheckMax(false)
    setCheckMaxVal(false)
    setPriceOrStockParam(1);
    setPriceOrStockParam1(1);
    setPriceOrStockParam3(1);
    setUnitParam(undefined);
    setPercent('');
    setPercent1('');
    setPercent2('');
    setPercent3('');
    setCurrentMinNum(0);
    setCurrentMaxValue(0);
    setCurrentMax(0);
    setCurrentSredNum(0);
    setPriceOrStockNum(0);
    setCurrentMin(true);
    setCurrentSred(false);
    setFromBtnsParam('category');
    handleChangeForParams('');
    setForParams({
      brand: [],
      category: [],
      name_: []
    });
  };

  const [buttonProfile, setButtonProfile] = useState(false)
  const [snackbar, setSnackbar] = useState(false)
  const [snackbar1, setSnackbar1] = useState(false)
  const [currentCountMin, setCurrentMin] = useState(true)
  const [currentCountSred, setCurrentSred] = useState(false)
  const [numberProd, setNumberProd] = useState(0);
  const [numberProd1, setNumberProd1] = useState(0);
  const [numberProd2, setNumberProd2] = useState(0);
  const [numberProd3, setNumberProd3] = useState(0);
  const [styleProduct, setStyleProduct] = useState(true);
  const [currentSredNum, setCurrentSredNum] = useState(0);
  const [currentMinNum, setCurrentMinNum] = useState(0);
  const [currentMaxValue, setCurrentMaxValue] = useState(0);
  const [currentMax, setCurrentMax] = useState(0);
  const [priceStockNum, setPriceOrStockNum] = useState(0);
  const [search, setSearch] = React.useState('')
  const [isOpenExcel, setIsOpenExcel] = useState(false)
  const [checkMax, setCheckMax] = useState(true)
  const [checkMaxVal, setCheckMaxVal] = useState(true)
  const [isPriceIncrease, setIsPriceIncrease] = useState(false)
  const [disablePriceIncrease, setDisablePriceIncrease] = useState(false)
  const changeMaxVal = () => {
    if (myTarif === 'Демо') {
      setButtonProfile(true)
    }
    else {
      setCheckMaxVal(prev => !prev)
      setCurrentMaxValue(1);
    }
  }

  function priceIncreaseHandler() {
    setIsPriceIncrease(!isPriceIncrease)
  }

  useEffect(() => {
    setIsPriceIncrease(false)
  }, [priceOrStockParam3])

  useEffect(() => {
    let defis = percent.split('-')

    if(defis.length > 1 && defis[0] === '' && +defis[2] > 2249) {
      setDisablePriceIncrease(true)
      return;
    }

    if(defis.length > 1 && defis[0] !== '' && (+defis[0] > 2249 || +defis[1] > 2249)) {
      setDisablePriceIncrease(true)
      return
    }

    if(+percent > 2249 && priceOrStockParam3 === 1) {
      setDisablePriceIncrease(true)
    } else if(priceOrStockParam3 === 0) {
      setDisablePriceIncrease(true)
    } else {
      setDisablePriceIncrease(false)
    }

    if(!disablePriceIncrease) {
      setIsPriceIncrease(false)
    }

  }, [percent, priceOrStockParam3])

  const openModalExportToExcel = () => {
    setIsOpenExcel(true)
  }


  const closeModalExportToExcel = () => {
    console.log(isOpenExcel)
    setIsOpenExcel(false)
  }

  const changeMax = () => {
    if (myTarif === 'Демо') setButtonProfile(true)
    else {
      setCheckMax(prev => !prev)
      setCurrentMax(1);
    }
  }


  const changeSmaler = () => {
    setCurrentCountSmaller(!currentCountSmaller);
  }

  const changeEqualZeo = () => {
    setCurrentEqualZero(!currentEqualZero);
  }


  const currentMin = () => {
    setCurrentMin(!currentCountMin);
    setCurrentMinNum(1);
  }

  const currentSred = () => {
    setCurrentSred(!currentCountSred);
    setCurrentSredNum(1);
  }

  const descending = id => {
    setPriceOrStockParam3(id)
    setPriceOrStockNum(1)
  }

  const numberProduct = async () => {
    let type_descending = 0
    if (priceOrStockParam1 === 1 && unitParam === 1) type_descending = 1;
    if (priceOrStockParam1 === 1 && unitParam === 0) type_descending = 2;
    if (priceOrStockParam1 === 0 && unitParam === 0) type_descending = 3;

    if (percent === '' && priceOrStockParam3 === 1) {
      setButtonProfile(false)
      setSnackbarMessage('Заполните все поля');
      setShowSnackbar(true);
    }

    else {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const plan = await findTariff(uid);
      const result = await setCalcOrg({
        org: uid,
        demand: priceOrStockParam3,
        percent_more: priceOrStockParam3 === 1 ? String(percent) : '0',
        type_descending: type_descending,
        value_descending: priceOrStockParam1 === 1 ? Number(percent1) : Number(percent2),
        check_min_value: currentCountMin ? 1 : 0,
        check_avg_value: currentCountSred ? 1 : 0,
        check_max_value: checkMax ? 1 : 0,
        percent_fork_2250: Number(isPriceIncrease),
      });

      const number = await excelDownloadNew({
        org: uid,
        plan: plan.data.data.uid || '45124e44-22d7-4714-9ad4-83129b787202',
        type: 1,
        brand: fromBtnsParam === 'brand' ? forParams?.[fromBtnsParam] : [],
        category: fromBtnsParam === 'category' ? forParams?.[fromBtnsParam] : [],
        name_: fromBtnsParam === 'name_' ? forParams?.[fromBtnsParam] : [],
        info: true,
      });
      setNumberProd(number.data.data.product_count)
      setStyleProduct(false)
      setCurrentMinNum(0)
      setCurrentMaxValue(0)
      setCurrentMax(0)
      setCurrentSredNum(0)
      setPriceOrStockNum(0)
    }
  }

  const debounced = useDebounce(numberProduct, 100);

  const onChange1 = e => {
    setPercent(e.target.value.replace(/[^0-9 -]/i, "").replace(/\s/g, ""))
    // debounced(e.target.value)
  }

  const onChange4 = e => {
    setPercent3(e.target.value.replace(/[^0-9 -]/i, "").replace(/\s/g, ""))
    // debounced(e.target.value)
  }

  const onChange2 = e => {
    setPercent1(e.target.value)
    // debounced(e.target.value)
  }

  const onChange3 = e => {
    setPercent2(e.target.value)
    // debounced(e.target.value)
  }

  function onBlurSearch(value: string) {

    if (value?.trim() !== "") {
      if (!searchIsSended) setSearchWarn(true)
      else setSearchWarn(false)
    }
    else setSearchWarn(false)
  }

  let cookies = cookie.parse(document.cookie);
  const tel = cookies.n.replace(/\D+/g, '');


  const changeColor = () => {
    if (percent.length > 0 ||
      percent1.length > 0 ||
      percent2.length > 0 ||
      percent3.length > 0 ||
      currentSredNum === 1 ||
      currentMinNum === 1 ||
      currentMaxValue === 1 ||
      currentMax === 1 ||
      priceStockNum === 1 ||
      currentCountSmaller === true ||
      currentEqualZero === true) {
      setStyleProduct(true)
    }
    else if (currentCountSmaller === false && currentEqualZero === false) {
      setStyleProduct(true)
      setNumberProd1(0)
    }
    else {
      setStyleProduct(false)
    }
  }

  useEffect(() => {
    changeColor()
  }, [forParams,
    percent,
    percent1,
    percent2,
    percent3,
    currentCountMin,
    currentCountSred,
    priceOrStockParam3,
    currentEqualZero,
    checkMaxVal,
    checkMax,
    currentCountSmaller]);

  const openNewModal = () => {
    if (myTarif === 'Демо') {
      setCheckMax(false)
    }
    else  {
      setCheckMax(true)
    }
    setCurrentMin(true);
    setCurrentMinNum(1);
    setListModalOpen1(true)
    setNumberProd(0);
    filterStocksFromParam.refetch()
  }

  const openNewModal1 = () => {
    setListModalOpen(true)
    setNumberProd1(0);
    filterStocksFromParam.refetch()
  }


  const openNewModal2 = () => {
    openReportModal('goods')
    setNumberProd2(0);
    filterStocksFromParam.refetch()
  }

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (props.search) {
        props.setPage(1)
        props.search(e.target.value)
        setSearchIsSended(true)
      }
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e)
    setSearchIsSended(false)
    if (e.length === 0) {
      const Debounce = setTimeout(() => {
        if (props.search) {
          props.setPage(1)
          props.search('')
        }
      }, 500)
      return () => clearTimeout(Debounce)
    }
  }

  ////Новое оповещение
  const [countNotifications, setCountNotifications] = useState(0)

  const StyledBadge = withStyles((theme) => ({
    badge: {
      color: '#fff',
      background: '#FF6C4B',
      right: 2,
      top: 6,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge);

  /////////

  const [weeksList, setWeekList] = React.useState<any>(null);
  const [toggle, setToggle] = React.useState<boolean>(false);
  const [minCost, setMinCost] = React.useState<boolean>(true)
  const [checkModal, setCheckModal] = React.useState<boolean>(false)

  const [validateTrue, setValidateTrue] = React.useState([]);
  let [equalityList, setEqualityList] = React.useState([
    { id: 0, name: 'Бренд', equalityId: 0, equality: '' },
    { id: 1, name: 'Категория', equalityId: 0, equality: '' },
    { id: 2, name: 'Артикул поставщика', equalityId: 0, equality: '' },
    { id: 3, name: 'Артикул производителя', equalityId: 0, equality: '' },
  ]);
  const [importList, setImportList] = React.useState<any>([
    { equalityId: 0, equality: 'Значение 1' },
    { equalityId: 1, equality: 'Значение 2' },
    { equalityId: 2, equality: 'Значение 3' },
  ]);
  const [fileData, setFileData] = React.useState<string | null | undefined>(null);
  const [base64, setBase64] = React.useState<string | null>();
  const [swapData, setSwapData] = React.useState<null | Array<any>>(null);
  const [showCompareModal, setShowCompareModal] = React.useState(false);
  const [showError, setShowError] = React.useState(false);


  const closeCompareModal = () => {
    setEqualityList([]);
    setImportList([]);
    setShowCompareModal(false);
  };

  const getDateRow = (d1, d2) => {
    return `${moment(d1).format('DD.MM')}-${moment(d2).format('DD.MM')}`;
  };

  const details = useQuery('details', async () => {
    setLoader(true);
    const weeks = [
      { weekNum: 1, name: '- 1 неделя', keyWeek: 'week_1', keyThisWeek: 'this_week_1' },
      { weekNum: 2, name: '- 2 неделя', keyWeek: 'week_2', keyThisWeek: 'this_week_2' },
      { weekNum: 3, name: '- 3 неделя', keyWeek: 'week_3', keyThisWeek: 'this_week_3' },
      { weekNum: 4, name: '- 4 неделя', keyWeek: 'week_4', keyThisWeek: 'this_week_4' },
      { weekNum: 5, name: '- 5 неделя', keyWeek: 'week_5', keyThisWeek: 'this_week_5' },
      { weekNum: 6, name: '- 6 неделя', keyWeek: 'week_6', keyThisWeek: 'this_week_6' },
      { weekNum: 7, name: '- 7 неделя', keyWeek: 'week_7', keyThisWeek: 'this_week_7' },
      { weekNum: 8, name: '- 8 неделя', keyWeek: 'week_8', keyThisWeek: 'this_week_8' },
      { weekNum: 9, name: '- 9 неделя', keyWeek: 'week_9', keyThisWeek: 'this_week_9' },
      { weekNum: 10, name: '- 10 неделя', keyWeek: 'week_10', keyThisWeek: 'this_week_10' },
      { weekNum: 11, name: '- 11 неделя', keyWeek: 'week_11', keyThisWeek: 'this_week_11' }
    ];
    setWeekList(null);
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const copyWeeksList = JSON.parse(JSON.stringify(weeks));
      const dates = await dateTrunc(uid);

      let checkNotFound = false;

      if (dates?.data?.data) {
        copyWeeksList.forEach((item: any, i: number) => {
          item.val = dates.data.data[i].val;
          item.checkNotFound = checkNotFound;
          item.dateRange = getDateRow(dates.data.data[i].date_1, dates.data.data[i].date_2);
        });
      }

      setWeekList(copyWeeksList);
      return dates.data.data || [];
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  });

  useEffect(() => {
    localStorage.setItem('missed', '0')
  }, []);

  const filter = () => {
    const result = weeksList?.map((item: any, index: number) => {
      if (item.val || details?.data?.[11]?.[item.keyThisWeek]) {
        return { ...item, toggle: true }
      }
      else {
        return { ...item, toggle: false }
      }
    })
    let missed: any = []
    if (result) {
      const missedFilter = result.filter(el => {
        if (el.toggle === true) {
          return missed.push(el.weekNum)
        }
      })
    }

    if (missed.length === missed[missed.length - 1]) {
      return localStorage.setItem('missed', '0');
    } else {
      let resultLength = missed[missed.length - 1] + 1;

      for (let i = 1; i < resultLength; i++) {
        if (missed.indexOf(i) === -1) {
          return localStorage.setItem('missed', String(i));;
        }
      }
    }
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const excelPrimaryCheck = async (file?: any, fileDataS?: any, type?: any) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    try {
      const check: any = await wbExcelPrimaryCheck({
        typeUid: type,
        content: file,
        orgUid: uid || '',
        nameFile: fileDataS.name.replace(/[^a-zа-яё0-9\s.]/gi, ''),
        dateCreation: `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
        dateUpdate: `${moment(fileDataS.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
        size: fileDataS.size || 0
      });

      if (!check.data.success) {
        setLoader(false)
        setShowSnackbar(true);
        setSnackbarMessage('Ошибка')
        console.log('ошибка')
        return;
      }
      if(!check.data.data.validateTrue) setValidateTrue([])
      if (check.data.data.validateTrue) setValidateTrue(check.data.data.validateTrue);
      if (!check.data.validate) {
        const dbColumn = check.data.data.validateFalse.dbColumn;

        const excelColumn = check.data.data.validateFalse.excel;
        setEqualityList(dbColumn.map((item: any, index: number) => {
          return {
            id: item.id,
            name: item.name,
            equalityId: 0,
            equality: '',
            default: item.default ? item.default : false,
            disabled: false
          };
        }));
        setImportList(excelColumn.map((item: any, index: number) => {
          return { equalityId: index, equality: item };
        }));
        setFileData(fileDataS)
      }
      else {
        setBase64(file);
        setSwapData(check.data.data.validateTrue);
      }
      return check;
    } catch (error) {
      closeCompareModal();
    }
  };

  const uploadFile = async (file: any) => {
    localStorage.setItem('missed', '0')
    setLoader(true)
    const base64 = await convertBase64(file);
    const b64 = base64.split(',')[1]
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const res = await wbExcelPrimaryCheck({
      typeUid: '7e8d9639-2419-47cd-aca1-0c8829528622' || '',
      content: b64 || '',
      orgUid: uid || '',
      nameFile: file.name.replace(/[^a-zа-яё0-9\s.]/gi, '') || '',
      dateCreation: `${moment(file.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
      dateUpdate: `${moment(file.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
      size: file.size || 0
    })
    if (res.data.success) {
      const result = await excelPrimarySaleWeekDetailing({
        content: b64,
        orgUid: uid,
        swap: res.data.data.validateTrue,
        typeUid: "7e8d9639-2419-47cd-aca1-0c8829528622"
      })
      .then(async(result: any) => {
        await dateilDate({
          org: uid,
          date_1: result.data.data[0].from,
          date_2: result.data.data[0].to,
        })
          .then(async () => {
            await uploadExcelWbData({
              content: b64,
              dateCreation: moment(file.lastModifiedDate).format('L') || '',
              dateUpdate: moment(file.lastModifiedDate).format('L') || '',
              nameFile: file.name.replace(/[^a-zа-яё0-9\s.]/gi, '') || '',
              orgUid: uid,
              size: file.size || 0,
              swap: res.data.data.validateTrue,
              typeUid: "7e8d9639-2419-47cd-aca1-0c8829528622"
            })
            setToggle(prev => !prev)
            setShowSnackbar(true);
            setSnackbarMessage('Отчет загружен')
            setLoader(false)
          })
          .catch(() => {
            setLoader(false)
            setShowSnackbar(true);
            setSnackbarMessage(res.data.description[0].reason)
          })
      })
      .catch(err => {
        setLoader(false)
        setShowSnackbar(true);
        setSnackbarMessage('Загружен неверный файл')
      })
    }
    if (res.data.success === false) {
      setLoader(false)
      setShowSnackbar(true);
      setSnackbarMessage(res.data.description[0].reason)
    }
    setLoader(false)
  }

  const uploadFileDin = async (newSwap?: any, fileData?: any) => {
    setLoader(true)
    const base64 = await convertBase64(fileData);
    const b64 = base64.split(',')[1]
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const check: any = await excelPrimaryCheck(b64 as string, fileData, '94e066eb-3b01-4916-993c-73f06978145b');
    if (!newSwap && !swapData && check?.data?.data?.validateFalse) {
      setShowError(true)
      setLoader(false)
      return;
    };
    if (check.data.success) {
      await uploadExcelWbData({
        typeUid: '94e066eb-3b01-4916-993c-73f06978145b' || '',
        orgUid: uid || '',
        swap: check.data.data.validateTrue || swapData || newSwap,
        content: b64 || '',
        nameFile: fileData.name.replace(/[^a-zа-яё0-9\s.]/gi, '') || '',
        dateCreation: `${moment(fileData.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
        dateUpdate: `${moment(fileData.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
        size: fileData.size || 0,
      })
      .then(() => {
        setShowSnackbar(true);
        setSnackbarMessage('Отчет загружен')
        setLoader(false)
        window.location.reload()
      })
      .catch(err => {
        setLoader(false)
        setShowSnackbar(true);
        setSnackbarMessage('Загружен неверный файл')
      })
    }
    if (check.data.success === false) {
      setLoader(false)
      setShowSnackbar(true);
      setSnackbarMessage(check.data.description[0].reason)
    }
    setLoader(false)
  }


  useEffect(() => {
    details.refetch()
  }, [toggle]);

  useEffect(() => {
    filter()
  }, [weeksList, details]);

  const navigate = useNavigate();

  const backLocation = () => {
    navigate('/load-data', { replace: true })
  }

  const fileInput = useRef()
  const fileInputDin = useRef()
  const missedData = localStorage.getItem('missed')
  const [next, setNext] = useState<boolean>(false)

  const loadFile = () => {
    fileInput?.current?.click();
  };

  const loadFileDin = () => {
    fileInputDin?.current?.click();
  };

  function UploadButtons() {
    return (
      <div >
        <input
          ref={fileInput}
          type="file"
          name="file"
          id="file"
          style={{ display: 'none', }}
          accept=".xlsx"
          onChange={(e) => uploadFile(e.target.files[0])}
        />
        <Button
          className={classes.loadBtn}
          onClick={() => loadFile()}
        >
          Загрузить детализацию
        </Button>
      </div>
    );
  }

  function UploadButtonsDinamic() {
    return (
      <div >
        <input
          ref={fileInputDin}
          type="file"
          name="file"
          id="file"
          style={{ display: 'none', }}
          accept=".xlsx"
          onChange={(e) => uploadFileDin('', e.target.files[0])}
        />
        <Button
          className={classes.loadBtn}
          style={{marginLeft: '10px'}}
          onClick={() => loadFileDin()}
        >
          Загрузить динамику
        </Button>
      </div>
    );
  }

  const handleClose = () => {
    setShowError(false);
  };
  const openCompareModal = () => {
    setShowCompareModal(true);
    handleClose();
  };


  function currentRapid(type: string) {
    if (type === '1') {
      setCurrentRapid1(!currentRapid1)
      setCurrentRapid2(false)
      setCurrentRapid3(false)
    }
    else if (type === '2') {
      setCurrentRapid2(!currentRapid2)
      setCurrentRapid1(false)
      setCurrentRapid3(false)
    }
    else if (type === '3') {
      setCurrentRapid3(!currentRapid3)
      setCurrentRapid2(false)
      setCurrentRapid1(false)
    }
  }


  useEffect(() => {
    if(missedData !== '0') setNext(false)
    else setNext(true)
  }, []);


  return (
    <>
    <div style={{width: '1600px'}}>
      <LoadDataValueMatchingModal
        open={showCompareModal}
        fileData={fileData}
        primaryDataTypeName={''}
        base64={base64 || ''}
        currentTypeUid={'94e066eb-3b01-4916-993c-73f06978145b'}
        validateTrue={validateTrue}
        equalityList={equalityList}
        importList={importList}
        type={'1'}
        saveEvent={(newSwap: any, fileData: any) => uploadFileDin(newSwap, fileData)}
        closeModal={() => closeCompareModal()}
      />
    </div>
    <div className={classes.toolbar}>
      <Spinner open={loader} />
      <FreeTariffSnackbar open={buttonProfile} closeEvent={() => setButtonProfile(false)} />
      <SnackbarMax
        open={snackbar}
        data={dataFetch}
        closeEvent={() => setSnackbar(false)}
      />
      <SnackbarMin
        open={snackbar1}
        toggle={snackbar ? '1' : '0'}
        data={dataFetch}
        closeEvent={() => setSnackbar1(false)}
      />

      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />



      <p className={classes.toolbarCount}>Всего {props.count}</p>
      <CustomModal
        show={next ? false : true}
        title="Отсутствуют данные за период"
        closeEvent={() => backLocation()}
        cancelText="Отмена"
        showSuccessBtn={false}
        showCancelBtn={false}
      >
        <div className={classes.detailsContent}>
          <p className={classes.detailsText}>
            В отчете по «Расчет цены по спросу и остатку» отсутствуют данные за определенные периоды. <br />
            Загрузите данные для корректной работы сервиса.
          </p>

          {weeksList?.map((item: any, index: number) => (
            <Typography
              style={{
                maxWidth: 450,
                width: '100%',
                color: details?.data?.[11]?.[item.keyThisWeek] ? '#FF6C4B' : '#37352F'
              }}
              className={classes.details}
              key={index}
            >
              <span>{item.weekNum} ({item.dateRange})</span>
              <span style={{ width: 226.11 }}>
                {(item.val || details?.data?.[11]?.[item.keyThisWeek]) ? (<>
                  <Check className="check" /> Данные загружены
                </>) : (<>
                  <ErrorIcon /> Необходимо загрузить данные
                </>)}
              </span>
            </Typography>
          ))}
        </div>
        <FormControlLabel
          control={
            <Checkbox defaultChecked color="default" checked={checkModal} />
          }
          label="Игнорировать отсутствие первых и промежуточных недель"
          onChange={(e: any) => setCheckModal(e.target.checked)}
        />
        <div className={classes.detailsFooter}>
          <div style={{display: 'flex'}}>
            <Button variant="outlined" className={classes.modalCancelBtn} style={{marginRight: '10px'}}
            disabled={checkModal ? false : true}
            onClick={() => setNext(true)}
            >
              Продолжить
            </Button>
            <UploadButtons />
            <UploadButtonsDinamic />
            <Button
              variant="outlined"
              className={classes.modalCancelBtn}
              onClick={() => backLocation()}
            >
              Отмена
            </Button>
          </div>
        </div>
      </CustomModal>

      <div style={{width: '1600px'}}>
      <CustomModal
        show={showError}
        successEvent={() => openCompareModal()}
        closeEvent={() => handleClose()}
        successText="Сопоставить значения"
        cancelText="Отмена"
      >
        <p>
          Не удалось загрузить файл. Одно или несколько полей не совпадают. <br />
          Вы можете сопоставить значения и продолжить работу.
        </p>
      </CustomModal>
      </div>

      <CustomModal
        title="Заполнение недостающих параметров"
        show={notParamsOpen}
        successText="Сохранить"
        cancelText="Отмена"
        successEvent={() => saveNoParam()}
        // successEvent={() => sendReport()}
        closeEvent={hideAllModal}
      >
        <div className={classes.notParamsContent}>
          <p className={classes.notParamsText}>
            Для того, чтобы сформировать отчет, необходимо дополнить данные
          </p>
          {categoriesData.map((item: any, index: number) => (
            <div key={index}>
              <div>
                <p className={classes.notParamsLabel}>Категория товаров <span>*</span></p>
                <Select
                  className={classes.selectBlock}
                  multiple
                  value={item.categories}
                  onChange={(e) => handleChangeCategory(e, index)}
                  input={<OutlinedInput className={classes.selectInput} />}
                  renderValue={(selected: any) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', marginRight: 5 }}>
                      {selected.map((value: any) => (
                        <div className={classes.setDataModalChips}>
                          <div className={classes.setDataModalChip} onClick={() => removeChip(value, index)}>
                            {value} <CloseIcon className={classes.setDataModalChipClose} />
                          </div>
                        </div>
                      ))}
                    </Box>
                  )}
                  open={openSelect === index}
                  onClose={handleCloseSelect}
                  onOpen={(e: any) => handleOpenSelect(index, e)}
                  MenuProps={MenuProps}
                >
                  {filterStocks?.data?.category && filterStocks?.data?.category?.map((access: any) => (
                    <MenuItem
                      key={access}
                      value={access}
                      style={getStylesCategories(access, accesses, theme)}
                    >
                      {access}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classNames(classes.fieldBlock, classes.miniField)}>
                <label htmlFor="name" className={classes.fieldLabel}>
                  Кол-во пунктов, на которые нужно изменить параметр текущей скидки <span>*</span>
                </label>
                <div className={classes.iconField}>
                  <input
                    type="number"
                    placeholder="Только цифры"
                    className={classes.field}
                    value={item.points}
                    onChange={(e: any) => handleCountPoints(e.target.value, index)}
                  />
                  <Check className={classNames(classes.checkIcon, countPoints.length > 0 ? classes.checkIconActive : '')} />
                </div>
              </div>
              <div className={classNames(classes.fieldBlock, classes.miniField)}>
                <label htmlFor="name" className={classes.fieldLabel}>
                  %, на который нужно изменить цену для клиента <span>*</span>
                </label>
                <div className={classes.iconField}>
                  <input
                    type="number"
                    placeholder="Только цифры"
                    className={classes.field}
                    value={item.percent}
                    onChange={(e: any) => handleCountPercent(e.target.value, index)}
                  />
                  <Check className={classNames(classes.checkIcon, countPercent.length > 0 ? classes.checkIconActive : '')} />
                </div>
              </div>
            </div>
          ))}
          <div className={classes.filterFooter}>
            <button className={classes.filterAddBtn} onClick={addCategory}>
              <Plus /> Добавить категории
            </button>
            {categoriesData.length > 1 && (
              <button className={classes.filterRemoveBtn} onClick={removeCategory}>
                <span className={classes.filterMinusIcon}>-</span> Удалить последнюю категории
              </button>
            )}
          </div>
        </div>
      </CustomModal>

      <CustomModal
        title={reportModalType === 'products' ? `Параметры для формирования отчета по товарам для повышения розничной цены (спрос > 0, остаток > 0) `
          : 'Параметры для формирования отчёта по товарам для повышения розничной цены (спрос > остатков, остаток = 0)'}
        show={reportModalOpen}
        successText="Сформировать отчет"
        cancelText="Отмена"
        successEvent={() => sendReport(false)}
        closeEvent={hideAllModal}
      >
        <div className={classes.reportModal}>
          <p className={classes.reportModalText}>
            Укажите условия
          </p>
          <div style={{ position: 'absolute', right: 35, top: 80 }}>
            <div className={styleProduct ? classes.wrapProd1 : classes.wrapProd}>
              <div className={styleProduct ? classes.textNumberProd2 : classes.textNumberProd}>Количество товаров: <span className={classes.number}>{reportModalType === 'products' ? numberProd3 : numberProd2}</span></div>
              <div className={styleProduct ? classes.textNumberProd1_1 : classes.textNumberProd1} onClick={styleProduct ? () => sendReport(true) : null}>Обновить</div>
            </div>
          </div>
          {reportModalType === 'products' ?
            <div className={classNames(classes.fieldBlock, classes.miniField)}>
              <div style={{ marginBottom: 10 }}>
                <label htmlFor="number" className={classes.fieldLabel} >
                  Введите %, на который спрос должен быть больше текущих остатков<span>*</span>
                </label>
              </div>
              <div className={classes.iconField}>
                <input
                  id="number"
                  name="nonoAuto"
                  autoComplete='nonoAuto'
                  type="text"
                  className={classes.field}
                  value={percent3}
                  onChange={onChange4}
                />
                <Check className={classNames(classes.checkIcon, percent3.length > 0 ? classes.checkIconActive : '')} />
              </div>
            </div>
            :
            null
          }

          <div className={classes.reportModalBtnGroupWrap}>
            <p className={classes.reportModalLabel}>Что сделать</p>
            <ButtonGroup className={classes.reportModalBtns} variant="outlined">
              {priceOrStockBtns.map((item: any) => (
                <Button
                  className={
                    classNames(
                      classes.reportModalFirstBtn,
                      classes.reportModalBtn,
                      priceOrStockParam === item.id ? classes.active : ''
                    )
                  }
                  onClick={() => setPriceOrStockParam(item.id)}
                  key={item.id}
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div>
            <div className={classes.reportModalBtnGroupWrap}>
              <p className={classes.reportModalLabel}>Единица измерения</p>
              <ButtonGroup className={classes.reportModalBtns} variant="outlined">
                {unitBtns.map((item: any) => (
                  <Button
                    className={
                      classNames(
                        // classes.reportModalFirstBtn,
                        classes.reportModalBtn,
                        unitParam === item.id ? classes.active : ''
                      )
                    }
                    style={{
                      display: priceOrStockParam === 0 && item.id === 1
                        ? 'none'
                        : null,
                      borderRadius: priceOrStockParam === 0 && item.id === 0
                        ? '8px'
                        : null
                    }}
                    onClick={() => setUnitParam(item.id)}
                    key={item.id}
                  >
                    {item.name}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            {(unitParam === 0 || unitParam === 1) ? (
              <div className={classNames(classes.fieldBlock, classes.miniField)}>
                <>
                  {priceOrStockParam
                    ? <label htmlFor="name1" className={classes.fieldLabel}>
                      На сколько повысить цену, {unitParam === 0 ? '%' : '₽'} <span>*</span>
                    </label>
                    : <label htmlFor="name1" className={classes.fieldLabel}>
                      Снизить скидку на, {unitParam === 0 ? '%' : '₽'} <span>*</span>
                    </label>}
                  <div className={classes.iconField}>
                    <input
                      id="name1"
                      type="number"
                      className={classes.field}
                      value={percent}
                      onChange={(e) => setPercent(e.target.value)}
                    />
                    <Check className={classNames(classes.checkIcon, percent.length > 0 ? classes.checkIconActive : '')} />
                  </div>
                </>
              </div>
            ) : null}
          </div>

          {reportModalType === 'products' &&
            <div className={classes.reportModalBtnGroupWrap} >
              <FormControlLabel
                className={classes.listModalCheckbox}
                style={{ color: myTarif === 'Демо' ? 'rgba(0, 0, 0, 0.38)' : null }}
                control={
                  <Checkbox color="default" checked={checkMaxVal} />
                }
                label="учитывать максимальный порог по цене"
                onChange={changeMaxVal}
              />
            </div>
          }
          <FormControlLabel
              className={classes.listModalCheckbox} style={{ marginBottom: 20 }}
              control={
                <Checkbox
                    color="default" checked={minCost}
                />
              }
              onChange={() => setMinCost(!minCost)}
              label={'учитывать минимальный порог по цене'}
          />



          <div className={classes.reportModalBtnGroupWrap}>
            <p className={classes.reportModalLabel}>Для</p>
            <ButtonGroup className={classes.reportModalBtns} variant="outlined">
              {fromBtns.map((item: any) => (
                <Button
                  className={
                    classNames(
                      classes.reportModalFirstBtn,
                      classes.reportModalBtn,
                      fromBtnsParam === item.id ? classes.active : ''
                    )
                  }
                  onClick={() => setFromBtnsParam(item.id)}
                  key={item.id}
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div>
            <div>
              {fromBtnsParam === 'brand' &&
                <p className={classes.fieldLabel}>Бренд <span>*</span></p>
              }
              {fromBtnsParam === 'category' &&
                <p className={classes.fieldLabel}>Категория <span>*</span></p>
              }
              {fromBtnsParam === 'name_' &&
                <p className={classes.fieldLabel}>Товар <span>*</span></p>
              }
              <Select
                className={`${classes.selectBlock} selectAbsolute`}
                multiple
                disabled={fromBtnsParam ? false : true}
                value={forParams?.[fromBtnsParam] || []}
                onChange={(e: any) => handleChangeForParams(e.target.value)}
                input={<OutlinedInput className={classes.selectInput} />}
                renderValue={(selected: any) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', marginRight: 5 }}>
                    {selected.map((value: any) => (
                      <div className={classes.setDataModalChips}>
                        <div className={classes.setDataModalChip} onClick={() => removeFromBtnChip(value)}>
                          {value} <CloseIcon className={classes.setDataModalChipClose} />
                        </div>
                      </div>
                    ))}
                  </Box>
                  // <Box sx={{ display: 'flex', flexWrap: 'wrap', marginRight: 5 }}>
                  //   <div className={classes.setDataModalChips}>
                  //     <div className={classes.setDataModalChip} onClick={() => removeChip(value)}>
                  //       {value} <CloseIcon className={classes.setDataModalChipClose} />
                  //     </div>
                  //   </div>
                  //   {selected?.map((value: any) => (
                  //     <div className={classes.selectChip} key={value}>
                  //       {value} <span onClick={() => {
                  //         removeChip(value);
                  //       }}>
                  //         <CloseIcon className={classes.selectChipClose} />
                  //       </span>
                  //     </div>
                  //   ))}
                  // </Box>
                )}
                open={openForParamsSelect}
                onClose={handleCloseForParams}
                onOpen={handleOpenForParamsSelect}
                MenuProps={MenuProps}
              >
                {filterStocksFromParam?.data?.[fromBtnsParam].length === 0 &&
                  <MenuItem disabled value="">
                    {fromBtnsParam === 'brand' && 'Нет брендов'}
                    {fromBtnsParam === 'category' && 'Нет категорий'}
                    {fromBtnsParam === 'name_' && 'Нет товара'}
                  </MenuItem>
                }
                {filterStocksFromParam?.status === 'success' ?
                  filterStocksFromParam?.data?.[fromBtnsParam]?.sort().map((el: any, i: number) => (
                    // style={getStyles(item, forParams, fromBtnsParam, theme)}
                    <MenuItem value={el} key={i}>
                      {el}
                    </MenuItem>
                  ))
                  :
                  <MenuItem>
                    Загрузка
                  </MenuItem>
                }

              </Select>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        title="Формирование списка товаров для заказа"
        show={listModalOpen}
        successText="Сформировать отчет"
        cancelText="Отмена"
        successEvent={() => downloadListOrder(false)}
        closeEvent={hideAllModal}
      >
        <div className={classes.listModalContent}>

          <p className={classes.listModalLabel}>
            Выберите условие
          </p>
          <div style={{ position: 'absolute', right: 35, top: 80 }}>
            <div className={styleProduct ? classes.wrapProd1 : classes.wrapProd}>
              <div className={styleProduct ? classes.textNumberProd2 : classes.textNumberProd}>Количество товаров: <span className={classes.number}>{numberProd1}</span></div>
              <div className={styleProduct ? classes.textNumberProd1_1 : classes.textNumberProd1} onClick={styleProduct ? () => downloadListOrder(true) : null}>Обновить</div>
            </div>
          </div>

          <FormControlLabel
            className={classes.listModalCheckbox}
            control={
              <Checkbox color="default" checked={currentEqualZero} />
            }
            label="текущие остатки = 0, необходимые остатки > 0"
            onChange={changeEqualZeo}
          />
          <FormControlLabel
            className={classes.listModalCheckbox}
            control={
              <Checkbox color="default" checked={currentCountSmaller} />
            }
            label="текущие остатки < необходимых остатков, текущие остатки > 0"
            onChange={changeSmaler}
          />
        </div>
      </CustomModal>

      <CustomModal
        title="Формирование списка товаров по параметрам"
        show={modalRapids}
        successText="Сформировать отчет"
        cancelText="Отмена"
        disabledSuccessBtn={!currentRapid1 && !currentRapid2 && !currentRapid3}
        successEvent={downloadThresholdReport}
        closeEvent={hideAllModal}
      >
        <div className={classes.listModalContent}>

          <p className={classes.listModalLabel}>
            Выберите условие
          </p>
          {/* <div style={{ position: 'absolute', right: 35, top: 80 }}>
            <div className={styleProduct ? classes.wrapProd1 : classes.wrapProd}>
              <div className={styleProduct ? classes.textNumberProd2 : classes.textNumberProd}>Количество товаров: <span className={classes.number}>{numberProd1}</span></div>
              <div className={styleProduct ? classes.textNumberProd1_1 : classes.textNumberProd1} onClick={styleProduct ? () => alert('Нет метода') : null}>Обновить</div>
            </div>
          </div> */}

          <FormControlLabel
            className={classes.listModalCheckbox}
            control={
              <Checkbox color="default" checked={currentRapid1} />
            }
            label="остатки > 0 / нет мин. цены"
            onChange={() => currentRapid('1')}
          />
          <FormControlLabel
            className={classes.listModalCheckbox}
            control={
              <Checkbox color="default" checked={currentRapid2} />
            }
            label="остатки > 0 / нет макс. цены"
            onChange={() => currentRapid('2')}
          />
          <FormControlLabel
            className={classes.listModalCheckbox}
            style={{ color: myTarif === 'Демо' ? 'rgba(0, 0, 0, 0.38)' : null }}
            control={
              <Checkbox color="default" checked={currentRapid3} />
            }
            label="остатки > 0 / тек. цена после скидки < мин цены"
            onChange={() => myTarif === 'Демо' ? setButtonProfile(true) : currentRapid('3')}
          />
        </div>
      </CustomModal>

      <CustomModal
        title="Параметры для формирования отчета по товарам для снижения розничной цены (после скидки)"
        show={listModalOpen1}
        successText="Сформировать отчет"
        cancelText="Отмена"
        successEvent={() => excelDownloadNewMy()}
        closeEvent={hideAllModal}
      >
        <div className={classes.listModalContent}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <p className={classes.listModalLabel}>
                Выберите условие
              </p>
              <ButtonGroup className={classes.reportModalBtns} variant="outlined">
                {priceOrStockBtns1.map((item: any) => (
                  <Button
                    className={
                      classNames(
                        classes.reportModalFirstBtn,
                        classes.reportModalBtn,
                        priceOrStockParam3 === item.id ? classes.active : ''
                      )
                    }
                    onClick={() => descending(item.id)}
                    key={item.id}
                  >
                    {item.name}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            <div className={styleProduct ? classes.wrapProd1 : classes.wrapProd}>
              <div className={styleProduct ? classes.textNumberProd2 : classes.textNumberProd}>Количество товаров: <span className={classes.number}>{numberProd}</span></div>
              <div className={styleProduct ? classes.textNumberProd1_1 : classes.textNumberProd1} onClick={styleProduct ? numberProduct : null}>Обновить</div>
            </div>
          </div>
          {priceOrStockParam3 === 1
            ?
            <div className={classNames(classes.fieldBlock, classes.miniField)}>
              <div style={{ marginBottom: 10 }}>
                <label htmlFor="name" className={classes.fieldLabel} >
                  Введите %, на который текущие остатки должны быть больше спроса<span>*</span>
                </label>
              </div>
              <div className={classes.iconField}>
                <input
                  id="name"
                  type="text"
                  name="nonoAuto"
                  autoComplete='nonoAuto'
                  className={classes.field}
                  value={percent}
                  onChange={onChange1}
                />
                <Check className={classNames(classes.checkIcon, percent.length > 0 ? classes.checkIconActive : '')} />
              </div>
            </div>
            :
            null}
          <div className={classes.reportModalBtnGroupWrap}>
            <p className={classes.reportModalLabel}>Что сделать</p>
            <ButtonGroup className={classes.reportModalBtns} variant="outlined">
              {priceOrStockBtns2.map((item: any) => (
                <Button
                  className={
                    classNames(
                      classes.reportModalFirstBtn,
                      classes.reportModalBtn,
                      priceOrStockParam1 === item.id ? classes.active : ''
                    )
                  }
                  onClick={() => setPriceOrStockParam1(item.id)}
                  key={item.id}
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div className={classes.reportModalBtnGroupWrap}>
            <p className={classes.reportModalLabel}>Единица измерения</p>
            <ButtonGroup className={classes.reportModalBtns} variant="outlined">
              {unitBtns.map((item: any) => (
                <Button
                  className={
                    classNames(
                      // classes.reportModalFirstBtn,
                      classes.reportModalBtn,
                      unitParam === item.id ? classes.active : ''
                    )
                  }
                  style={{
                    display: priceOrStockParam1 === 0 && item.id === 1
                      ? 'none'
                      : null,
                    borderRadius: priceOrStockParam1 === 0 && item.id === 0
                      ? '8px'
                      : null
                  }}
                  onClick={() => setUnitParam(item.id)}
                  key={item.id}
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          {(unitParam === 0 || unitParam === 1) ? (
            <div className={classNames(classes.fieldBlock, classes.miniField)}>
              <>
                {priceOrStockParam1
                  ?
                  <div>
                    <label htmlFor="name" className={classes.fieldLabel}>
                      {priceOrStockParam1 === 1 ? 'На сколько снизить цену' : 'Повысить скидку'} на, {unitParam === 0 ? '%' : '₽'} <span>*</span>
                    </label>
                    <div className={classes.iconField}>
                      <input
                        id="name"
                        type="number"
                        name="nonoAuto"
                        autoComplete='nonoAuto'
                        className={classes.field}
                        value={percent1}
                        onChange={onChange2}
                      />
                      <Check className={classNames(classes.checkIcon, percent1.length > 0 ? classes.checkIconActive : '')} />
                    </div>
                  </div>
                  :
                  <div>
                    <label htmlFor="name" className={classes.fieldLabel}>
                      {priceOrStockParam1 === 1 ? 'На сколько снизить цену' : 'Повысить скидку'} на, % <span>*</span>
                    </label>
                    <div className={classes.iconField}>
                      <input
                        id="name"
                        type="number"
                        name="nonoAuto"
                        autoComplete='nonoAuto'
                        className={classes.field}
                        value={percent2}
                        onChange={onChange3}
                      />
                      <Check className={classNames(classes.checkIcon, percent2.length > 0 ? classes.checkIconActive : '')} />
                    </div>
                  </div>

                }
              </>
            </div>
          ) : null}
          {myTarif === 'Демо'
            ?
            <FormControlLabel
              className={classes.listModalCheckbox}
              control={
                <Checkbox color="default" checked={false} />
              }
              label="учитывать минимальный порог по цене"
              onClick={() => setButtonProfile(true)}
            />
            :
            <FormControlLabel
              className={classes.listModalCheckbox}
              control={
                <Checkbox color="default" checked={currentCountMin} />
              }
              label="учитывать минимальный порог по цене"
              onChange={currentMin}
            />
          }

          {myTarif === 'Демо'
            ?
            <FormControlLabel
              className={classes.listModalCheckbox}
              control={
                <Checkbox color="default" checked={false} />
              }
              label="учитывать среднее значение цены"
              onClick={() => setButtonProfile(true)}
            />
            :
            <FormControlLabel
              className={classes.listModalCheckbox}
              control={
                <Checkbox color="default" checked={currentCountSred} />
              }
              label="учитывать среднее значение цены"
              onChange={currentSred}
            />
          }

          <div className={classes.reportModalBtnGroupWrap}>
            <FormControlLabel
              className={classes.listModalCheckbox}
              style={{ color: myTarif === 'Демо' ? 'rgba(0, 0, 0, 0.38)' : null }}
              control={
                <Checkbox color="default" checked={checkMax} />
              }
              label="учитывать максимальный порог по цене"
              onChange={changeMax}
            />
          </div>
          {disablePriceIncrease &&
            <FormControlLabel
              className={classes.listModalCheckbox}
              control={
                <Checkbox color="default" checked={isPriceIncrease} />
              }
              label={"повысить цену на 15%, если минимальный порог = текущей цене"}
              onChange={priceIncreaseHandler}

            />
          }

          <div className={classes.reportModalBtnGroupWrap}>
            <p className={classes.reportModalLabel}>Для</p>
            <ButtonGroup className={classes.reportModalBtns} variant="outlined">
              {fromBtns.map((item: any) => (
                <Button
                  className={
                    classNames(
                      classes.reportModalFirstBtn,
                      classes.reportModalBtn,
                      fromBtnsParam === item.id ? classes.active : ''
                    )
                  }
                  onClick={() => setFromBtnsParam(item.id)}
                  key={item.id}
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div>
            <div>
              {fromBtnsParam === 'brand' &&
                <p className={classes.fieldLabel}>Бренд <span>*</span></p>
              }
              {fromBtnsParam === 'category' &&
                <p className={classes.fieldLabel}>Категория <span>*</span></p>
              }
              {fromBtnsParam === 'name_' &&
                <p className={classes.fieldLabel}>Товар <span>*</span></p>
              }
              <Select
                className={`${classes.selectBlock} selectAbsolute`}
                multiple
                disabled={fromBtnsParam ? false : true}
                value={forParams?.[fromBtnsParam] || []}
                onChange={(e: any) => handleChangeForParams(e.target.value)}
                input={<OutlinedInput className={classes.selectInput} />}
                renderValue={(selected: any) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', marginRight: 5 }}>
                    {selected.map((value: any) => (
                      <div className={classes.setDataModalChips}>
                        <div className={classes.setDataModalChip} onClick={() => removeFromBtnChip(value)}>
                          {value} <CloseIcon className={classes.setDataModalChipClose} />
                        </div>
                      </div>
                    ))}
                  </Box>
                )}
                open={openForParamsSelect}
                onClose={handleCloseForParams}
                onOpen={handleOpenForParamsSelect}
                MenuProps={MenuProps}
              >
                {filterStocksFromParam?.data?.[fromBtnsParam].length === 0 &&
                  <MenuItem disabled value="">
                    {fromBtnsParam === 'brand' && 'Нет брендов'}
                    {fromBtnsParam === 'category' && 'Нет категорий'}
                    {fromBtnsParam === 'name_' && 'Нет товара'}
                  </MenuItem>
                }
                {filterStocksFromParam?.status === 'success' ?
                  filterStocksFromParam?.data?.[fromBtnsParam]?.sort().map((el: any, i: number) => (
                    // style={getStyles(item, forParams, fromBtnsParam, theme)}
                    <MenuItem value={el} key={i}>
                      {el ? el : 'ddd'}
                    </MenuItem>
                  ))
                  :
                  <MenuItem>
                    Загрузка
                  </MenuItem>
                }
              </Select>
            </div>
          </div>
        </div>
      </CustomModal>

      <div className={classes.toolbarBtns}>
        {cols.length
          ? <SettingsMenu
            cols={cols}
            calc={'71a95970-2b70-4586-8813-44320ffc5fc1'}
            newColumnPosition={props.newColumnPosition}
            sendColumn={props.sendColumn}
            refetch={props.refetch}
          />
          : null
        }
        <div className={classes.toolbarSearchBtn} onClick={setSearchFocus}>
          <div onClick={() => {
            props.setPage(1)
            props.search(search)
          }}>
            <Search />
          </div>
          <input
            type="text"
            placeholder={!openSearch ? 'Поиск' : 'Введите параметр для поиска...'}
            className={classNames(classes.toolbarSearch, searchWarn ? classes.warnSearch : '', openSearch ? 'focus' : '')}
            // onFocus={() => setOpenSearch(true)}
            ref={searchRef}
            onBlur={(e) => {
              closeSearch();
              onBlurSearch(e.target.value)
            }}
            onFocus={() => { setSearchWarn(false) }}
            onKeyPress={_handleKeyDown}
            value={search}
            onChange={(e) => onChangeSearch(e.target.value)}
          />
        </div>
        <div className={classes.toolbarFilter}>
          <CurrentRemainderFilterMenu
            setCurrentFilter={setCurrentFilter}
            setPage={props.setPage}
            filterList={props.filterList}
            cols={props.cols || []}
            sendFilters={props.sendFilters}
            cancelFilter={cancelFilter}
          />
          {/* <span className={classes.toolbarFilterBtn}>Фильтр</span> */}
        </div>
        <span
          className={
            classNames(
              classes.toolbarIconBtn,
              notifyActiveChip ? 'active' : null
            )
          }
          onClick={() => setShowNotify(true)}
        >
          <IconButton aria-label="cart">
            <StyledBadge badgeContent={countNotifications}>
              <Bell />
            </StyledBadge>
          </IconButton>
        </span>
        <CurrentRemainderNotificationModal
          toggleNotif={toggleNotif}
          setCount={setCountNotifications}
          show={showNotify}
          activeNotifyChip={(active) => setNotifyActiveChip(active)}
          closeEvent={() => setShowNotify(false)}
        />
        <span className={classes.toolbarIconBtn} onClick={() => setShowTip(true)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.375 22.3304H11.4541H12.2H12.9592H13.025H13.6705H14.6C15.5274 22.3304 16.29 21.5679 16.29 20.6404V15.8404C16.29 15.3608 16.4266 15.0354 16.6788 14.6802C16.8602 14.4248 17.1001 14.1758 17.3803 13.885C17.507 13.7534 17.642 13.6133 17.7836 13.4601L17.7839 13.4597C18.6798 12.4865 19.65 11.1051 19.65 8.64043C19.65 4.53111 16.3093 1.19043 12.2 1.19043C8.09068 1.19043 4.75 4.53111 4.75 8.64043C4.75 11.1452 5.7103 12.645 6.61149 13.6796C6.79724 13.8933 6.98066 14.0899 7.14888 14.2703C7.18161 14.3054 7.21377 14.3399 7.24526 14.3738C7.44098 14.5843 7.60905 14.7693 7.74147 14.9424C8.00502 15.2867 8.11 15.5303 8.11 15.8404V20.6404C8.11 21.5679 8.87255 22.3304 9.8 22.3304H11.375ZM16.6963 12.4758L16.6959 12.4763C16.5934 12.5878 16.4866 12.6994 16.3782 12.8126C16.0759 13.1284 15.7611 13.4572 15.4915 13.8353C15.1134 14.3654 14.83 15.0282 14.83 15.8404V16.7504H9.57V15.8404C9.57 15.1164 9.25399 14.529 8.88822 14.0531C8.66259 13.7595 8.39961 13.4758 8.1393 13.195C7.99242 13.0365 7.84639 12.879 7.7084 12.7211C7.31324 12.268 6.94189 11.7781 6.66725 11.1407C6.39301 10.5042 6.21 9.70827 6.21 8.64043C6.21 5.32663 8.8862 2.65043 12.2 2.65043C15.5138 2.65043 18.19 5.32663 18.19 8.64043C18.19 9.68238 18.0086 10.4271 17.7376 11.0125C17.4657 11.5997 17.0971 12.0418 16.6963 12.4758ZM10.2158 8.37617L10.203 8.37716L10.1903 8.37946C10.1626 8.38449 10.137 8.39116 10.1164 8.39707C9.84873 8.45023 9.63542 8.64817 9.55991 8.90823L9.55972 8.90891C9.48357 9.17401 9.56167 9.45714 9.7611 9.64509L11.47 11.354V13.9804V14.2304H11.72H12.68H12.93V13.9804V11.354L14.6418 9.64221C14.9307 9.35332 14.9307 8.88753 14.6418 8.59865C14.3529 8.30977 13.8871 8.30977 13.5982 8.59865L12.2 9.99688L10.8046 8.60151C10.6518 8.4394 10.4337 8.35941 10.2158 8.37617ZM14.83 18.2504V20.6404C14.83 20.7686 14.7282 20.8704 14.6 20.8704H10.8727C10.7934 20.8573 10.7116 20.8573 10.6323 20.8704H9.8C9.67182 20.8704 9.57 20.7686 9.57 20.6404V18.2504H14.83Z" fill="#37352F" stroke="#37352F" strokeWidth="0.5" />
            <rect x="6" y="18" width="12" height="3" fill="white" />
            {tipsActiveChip && <circle cx="19" cy="5" r="5" fill="#FF6C4B" />}
          </svg>
        </span>
        <CurrentRemainderTipsModal
          show={showTip}
          activeTipsChip={(active) => setTipsActiveChip(active)}
          closeEvent={() => setShowTip(false)}
        />

        {/* <label  className={classes.toolbarAddBtn} htmlFor="upload"> */}
        <Button
          id="basic-button"
          aria-controls={openReport ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openReport ? 'true' : undefined}
          onClick={handleClickReportBtn}
          className={classes.toolbarAddBtn}
        >
          <RightArrow color="#fff" /> Сформировать отчет
        </Button>
        {/* </label> */}
        <Menu
          id="settings-menu"
          className={classes.settingsMenu}
          anchorEl={anchorReport}
          open={openReport}
          onClose={handleCloseReport}
        >
          <div className={classes.settingsReportContent}>
            <div
              className={classes.settingsReportItem}
              onClick={openNewModal1}
            >
              Список товаров необходимых для заказа
            </div>
            <div
              className={classes.settingsReportItem}
              onClick={() => setModalRapids(true)}
            >
              Отчет по порогам
            </div>
            {/* setListModalOpen */}
            <div
              className={classes.settingsReportItem}
              onClick={openNewModal2}
            >
              Товары для повышения цены, <br />
              (спрос {'>'} 0, остаток = 0)
            </div>
            {/* onClick={downloadProductMinPrice} */}
            {/* setNotParamsOpen */}
            <div
              className={classes.settingsReportItem}
              onClick={() => openReportModal('products')}
            >
              Товары для повышения цены, <br />
              (спрос {'>'} остатков, остаток {'>'} 0)
            </div>
            <div
              className={classes.settingsReportItem}
              onClick={openNewModal}
            >
              Товары для понижения цены, <br />
              (остаток {'>'} спроса)
            </div>
            <div
              className={classes.settingsReportItem}
              onClick={openModalExportToExcel}
            > Выгрузить визуальную часть таблицы
            </div>
            <ExcelDialog
             setShowSnackbar={setShowSnackbar}
             setSnackbarMessage={setSnackbarMessage}
            loader={setLoader}
            isOpenExcel={isOpenExcel}
            closeModalExportToExcel={closeModalExportToExcel} type={'calc_stocks'} filterList={currentFilter} dataFetch={props.dataFetch} colsFetch={props.columnFetch}/>
          </div>

        </Menu>
        {/* <input
          id="upload"
          type="file"
          accept=".xlsx"
          style={{display: 'none'}}
          onChange={(e: any) => onFileChange(e)}
        /> */}
      </div>
    </div>
    </>
  )
}

export default ManagePricesToolbar
