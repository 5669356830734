
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import CurrencyFormatter from '../../UI/CurrencyFormatter';
import { TableContainer, Paper, Table, TableBody, TableRow, TableCell, FormControlLabel, Checkbox, createStyles, makeStyles } from '@material-ui/core';
import LoadDataCellByType from './LoadDataTableCellByType';
import LoadDataTableCell from './LoadDataTableCell';
import LoadDataTableRow from './LoadDataTableRow';

const useStyles = makeStyles(() =>
  createStyles({
    tableContainerBlock: {
      overflowX: 'hidden',
      minWidth: 'fit-content',
      border: 'none',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      tableLayout: 'fixed',

      '& .MuiTableRow-head': {
        background: '#fff',
      },
      '& .MuiTableCell-head': {
        borderRight: '1px solid #E9E9E7',
        textAlign: 'center',
        lineHeight: 1,
        boxSizing: 'border-box',
        padding: '8px !important',
        verticalAlign: 'middle',
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        padding: '2px 8px !important',
        borderRight: '1px solid #E9E9E7',
        boxSizing: 'border-box',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 16px !important',
        borderRight: 'none',
      },
    },
    tableCell: {
      verticalAlign: 'middle',
      textAlign: 'left',
      fontSize: '0.775rem',
      position: 'relative',
      minHeight: 30,
      padding: 10,

      '&:hover': {
        background: 'rgba(225, 225, 228, 0.5) !important'
      },
    },
    tableHeadRow: {},
    tableCellTh: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      position: 'relative',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      padding: 8,
      margin: 0,
      verticalAlign: 'middle',
      textAlign: 'center',
    },
    tableRow: {
      '&:hover': {
        background: '#f0f0ef',
      },
    },
    tableNoData: {
      backgroundColor: 'rgba(255, 108, 75, 0.08)',
    },
    cellBlock: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
    },
  })
);

interface Props {
  cols: any;
  data: any;
  param?: string;
  tableWidth?: number;
  setNewData?: (data: any, rowIndex: number, colName: string) => void;
  search?: string;
  setDoubles?: any
  changeCheckboxHandler: (item: any) => void
};

const LoadDataTableBody = (props: Props) => {
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const [noData, setNoData] = React.useState('');
  const [editCellPos, setEditCellPos] = React.useState<any>({ x: null, y: null });

  const getAlign = (colType: string) => {
    switch (colType) {
      case 'int': return 'flex-end';
      case 'bool': return 'center';
    };
  };


  return (
    <TableContainer component={Paper} style={{width: 'fit-content'}}>
      <Table
        className={classes.table}
        style={{
          width: props.tableWidth,
          height: props?.data?.length || 1 * 38
        }}
      >
        <TableBody>
          {props.data.map((item: any, index: number) => (
            <LoadDataTableRow
              search={props.search}
              cols={props.cols}
              data={props.data}
              param={props.param}
              tableWidth={props.tableWidth}
              item={item}
              setNewData={props.setNewData}
              key={index}
              setDoubles={props.setDoubles}
              changeCheckboxHandler={props.changeCheckboxHandler}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(LoadDataTableBody);
