import React from 'react'
import { makeStyles, createStyles, Button, Dialog, Box, MenuItem, OutlinedInput, Select, Theme, useTheme, Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from 'react-query';

import Main from '../../components/layout/Main';
import CurrencyFormatter from '../../components/UI/CurrencyFormatter';
import { getAllActiveTariffs, TariffsType, updateTariff } from '../../api/tariffs';
import { Check } from '@material-ui/icons';
import classNames from 'classnames';
import { getTariffsSettings, orgPlansAccess, tariffAccessSettings } from '../../api/tariffs/access';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const getStyles = (name: string, personName: readonly string[], theme: Theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(() => 
  createStyles({
    content: {
      padding: 32,
      maxWidth: 722,
      width: '100%',
      margin: '0 auto',
    },
    tariffList: {
      display: 'flex',
      flexDirection: 'column',
    },
    tariffCard: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 24,
      border: '1px solid #E9E9E7',
      borderRadius: 8,
    },
    tariffInfo: {
      padding: 32,
      maxWidth: 442 - 64,
      width: '100%',
      borderRight: '1px solid #E9E9E7',
    },
    tariffText: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
      maxWidth: 342,
    },
    tariffName: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '38px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    tariffPriceBlock: {
      maxWidth: 280,
      width: '100%',
    },
    tariffPriceWrap: {
      padding: '32px 32px 13px 43px'
    },
    tariffPrice: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '38px',
      color: '#37352F',
      margin: 0,
    },
    tariffRub: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 32,
      lineHeight: '38px',
      color: '#827F75',
      marginLeft: 6,
    },
    tariffBtn: {
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      width: 216,
      height: 40,
      textTransform: 'capitalize',
      background: '#37352F',
      borderRadius: 8,
      margin: '0 38px 36px 26px',

      '&:hover': {
        background: '#827F75',
      },
    },
    tariffModal: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'calc(786px - 64px)',
        width: '100%',
        padding: 32,
        borderRadius: 8,
      }
    },
    tariffModalHeader: {},
    tariffModalTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '23px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
      marginBottom: 32,
    },
    tariffModalClose: {
      position: 'absolute',
      top: 32,
      right: 32,
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',

      '& svg': {
        width: 24,
        height: 24,
      }
    },
    tariffModalInfoText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#000000',
      margin: 0,
      marginTop: 16,
      marginBottom: 32,
    },
    tariffModalContent: {
      maxWidth: 688,
      width: '100%',
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& span': {
        color: '#FF6C4B',
      },
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: '100%',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      marginBottom: 40,
      overflow: 'hidden',

      small: {
        maxWidth: 300,
      },
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '14px 16px',
      width: '100%',
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,
    },
    smallSize: {
      maxWidth: 300,
    },
    selectBlock: {
      width: '100%',
      outline: 'none',
      minHeight: 44,
      border: 'none !important',
      borderRadius: 8,
      marginBottom: 40,

      '&.Mui-focused': {
        outline: 'none !important',
        border: 'none !important',
      },
      '& .MuiSelect-select': {
        background: 'transparent !important',
        padding: 0,
      }
    },
    selectInput: {
      border: '1px solid #D3D1CB',
      outline: 'none',
    },
    selectChip: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      marginRight: 3,
      padding: '4px 8px',
      margin: 5,
    },
    selectChipClose: {
      width: '16px !important',
      height: '16px !important',
      marginLeft: '8px !important'
    },
    modalBtns: {},
    modalPrimaryBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#fff',
      background: '#37352F',
      borderRadius: 8,
      padding: '12px 16px',
      marginRight: 16,
      textTransform: 'none !important',

      '&:hover': {
        background: '#827F75',
      },
      '&[disabled]': {
        background: '#D3D1CB',
        color: '#fff',
      },
    },
    modalOutlinedBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      border: '1px solid #37352F',
      borderRadius: 8,
      padding: '12px 16px',
      textTransform: 'none !important',

      '&:hover': {
        borderColor: '#827F75',
        color: '#827F75',
        background: '#fff',
      },
    },
  }),
);

const AdminTariffs = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [selectedTariff, setSelectedTariff] = React.useState<TariffsType>();
  const [openSelect, setOpenSelect] = React.useState(false);
  const [name, setName] = React.useState<string>('');
  const [period, setPeriod] = React.useState<string>('');
  const [cost, setCost] = React.useState<string>('');
  const [disabled, setDisabled] = React.useState(true);

  const [accesses, setAccesses] = React.useState<Array<any>>([]);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setDisabled(true);
    if (!name.length) return;
    if (!period.length) return;
    if (!cost.length) return;
    setDisabled(false);
  }, [name.length, period.length, cost.length]);

  const handleCloseSelect = () => {
    setOpenSelect(false);
  };

  const handleOpenSelect = (e: any) => {
    if (e?.target?.tagName === 'svg' || e?.target?.tagName === 'path') {
      return;
    }
    setOpenSelect(true);
  };

  const accessesList = useQuery<Array<any>>('accessList', async () => {
    const result = await getTariffsSettings();
    return result.data.data || [];
  });

  const tariffs = useQuery<Array<TariffsType>>('tariffs', async () => {
    const result = await getAllActiveTariffs();

    return result?.data?.data;
  }).data;

  const getMonthOrDay = (period: number) => {
    var last = period.toString().slice(-1);

    if (Math.floor(Number(last)) === 1) return `${Math.floor(period)} день`;
    if (Math.floor(Number(last)) > 1 && Math.floor(period) < 5) return `${Math.floor(period)} дня`;
    return `${Math.floor(period)} дней`;
  };

  const editTariff = async (tariff: TariffsType) => {
    setSelectedTariff(tariff);
    setOpen(true);
    const currentOrgPlans: any = await orgPlansAccess(tariff.uid);
    setAccesses(currentOrgPlans.data.data || []);
    setName(tariff.planName);
    setPeriod(String(tariff.planPeriodDays));
    setCost(String(tariff.planCost));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeAccesses = (event: any) => {
    const { target: { value } } = event;
    setAccesses(value);
    handleCloseSelect();
    saveAccess(value);
  };

  const saveTariff = () => {
    if (selectedTariff) {
      let newTariff = selectedTariff;
      newTariff.planName = name;
      newTariff.planPeriodDays = Number(period);
      newTariff.planCost = Number(cost);
      updateTariff(selectedTariff?.uid, newTariff);
    }
    handleClose();
  };

  const saveAccess = (value: any) => {
    if (selectedTariff) {
      value.forEach((el: any) => {
        tariffAccessSettings({
          whatHappening: "1",
          planUid: selectedTariff?.uid,
          accessUid: el.uid
        });
      });
    }
  };

  const removeChip = (id: string) => {
    let selectedCopy: any = JSON.parse(JSON.stringify(accesses));
    const accessUid = selectedCopy.filter((item: any) => item.uid === id)[0].uid;
    tariffAccessSettings({
      whatHappening: "2",
      planUid: selectedTariff?.uid,
      accessUid: accessUid
    });
    selectedCopy = selectedCopy.filter((item: any) => item.uid !== id);
    setAccesses(selectedCopy);
  };

  const getTagProps = (e: any) => {}

  const handleChangeCost = (val: string) => {
    const regex = /[a-zA-Zа-яёА-ЯЁ]/gi;
    if (val.indexOf(',') === -1)
      setCost(val.replace(regex, ''));
  };

  const handleChangePeriod = (val: string) => {
    const regex = /((?=.)[^0-9]|^$)/gi;
    // if (val.indexOf(',') > -1 || val.indexOf('.') > -1) return;
    // if (val.indexOf(',') === -1 || val.indexOf('.') === -1)
      setPeriod(String(Number(val.replace(regex, ''))));
  };

  return (
    <>
      <Dialog
        className={classes.tariffModal}
        onClose={handleClose}
        open={open}
      >
        <div className={classes.tariffModalHeader}>
          <h2 className={classes.tariffModalTitle}>
            Изменение тарифа
          </h2>
          <button className={classes.tariffModalClose} onClick={handleClose}>
            <CloseIcon />
          </button>
          <div className={classes.tariffModalContent}>
            <div className={classes.fieldBlock}>
              <label htmlFor="name" className={classes.fieldLabel}>Название <span>*</span></label>
              <div className={classes.iconField}>
                <input
                  id="name"
                  className={classes.field}
                  type="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Check className={classNames(classes.checkIcon, name.length ? classes.checkIconActive : '')} />
              </div>
            </div>
            <div className={classes.fieldBlock}>
              <label htmlFor="cost" className={classes.fieldLabel}>Стоимость в ₽ <span>*</span></label>
              <div className={classNames(classes.iconField, classes.smallSize)}>
                <input
                  id="cost"
                  className={classNames(classes.field, classes.smallSize)}
                  type="string"
                  value={cost}
                  onChange={(e) => handleChangeCost(e.target.value)}
                />
                <Check className={classNames(classes.checkIcon, cost.length ? classes.checkIconActive : '')} />
              </div>
            </div>
            <div className={classes.fieldBlock}>
              <label htmlFor="period" className={classes.fieldLabel}>Срок действия, дней <span>*</span></label>
              <div className={classNames(classes.iconField, classes.smallSize)}>
                <input
                  id="period"
                  className={classNames(classes.field, classes.smallSize)}
                  type="text"
                  value={period}
                  onChange={(e) => handleChangePeriod(e.target.value)}
                />
                <Check className={classNames(classes.checkIcon, period.length ? classes.checkIconActive : '')} />
              </div>
            </div>
            <Select
              className={classes.selectBlock}
              multiple
              value={accesses}
              onChange={handleChangeAccesses}
              input={<OutlinedInput className={classes.selectInput} />}
              renderValue={(selected: any) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', marginRight: 5 }}>
                  {selected.map((value: any, index: number) => (
                    // <Chip className={classes.selectChip} key={value} label={value} />
                    <div className={classes.selectChip} key={value.uid}>
                      {value.accessName} <span onClick={() => removeChip(value.uid)}>
                        <CloseIcon className={classes.selectChipClose} />
                      </span>
                    </div>
                  ))}
                </Box>
              )}
              open={openSelect}
              onClose={handleCloseSelect}
              onOpen={handleOpenSelect}
              MenuProps={MenuProps}
            >
              {accessesList.data && accessesList.data.map((access: any) => (
                <MenuItem
                  key={access.uid}
                  value={access}
                  style={getStyles(access, accesses, theme)}
                >
                  {access.accessName}
                </MenuItem>
              ))}
            </Select>

            <div className={classes.modalBtns}>
                <Button
                  className={classes.modalPrimaryBtn}
                  onClick={() => saveTariff()}
                  disabled={disabled}
                >
                  Сохранить
                </Button>
                <Button className={classes.modalOutlinedBtn} onClick={() => handleClose()}>
                  Отмена
                </Button>
            </div>
          </div>
        </div>

      </Dialog>
      <div className={classes.content}>
        <div className={classes.tariffList}>
          {tariffs?.map((item: TariffsType) => (
            <div className={classes.tariffCard} key={item.uid}>
              <div className={classes.tariffInfo}>
                <p className={classes.tariffText}>Тариф</p>
                <p className={classes.tariffName}>{item.planName}</p>
                <p className={classes.tariffText}>Описание тарифа, доступы к возможностям сервиса</p>
              </div>
              <div className={classes.tariffPriceBlock}>
                <div className={classes.tariffPriceWrap}>
                  <p className={classes.tariffText}>
                    Цена за {getMonthOrDay(item.planPeriodDays)}</p>
                  <h2 className={classes.tariffPrice}>
                    <CurrencyFormatter currency={item.planCost} />
                    <span className={classes.tariffRub}>₽</span>
                  </h2>
                </div>
                <Button className={classes.tariffBtn} onClick={() => editTariff(item)}>
                  Изменить
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
};

export default AdminTariffs;