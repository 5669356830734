import { makeStyles, createStyles } from '@material-ui/core'
import React, {useCallback, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Main from '../../components/layout/Main'
import { Card, RubCircle, UserProfile } from '../../components/UI/Icons'
import { BoxIcon } from '../../components/UI/Icons/BoxIcon'
import { DiagramIcon } from '../../components/UI/Icons/DiagramIcon'
import RightArrow from '../../components/UI/Icons/rightArrow'
import rightArrow from '../../components/UI/Icons/rightArrow'
import routes from "../../components/UI/Breadcrumbs/routesList";

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 680,
      padding: 32,
    },
    managePricesItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: 16,
      paddingBottom: 16,
      borderBottom: '1px solid #e9e9e7',
      cursor: 'pointer',
    },
    managePricesContent: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    managePricesContentInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 8,
    },
    managePricesContentTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      marginBottom: 8,
    },
    managePricesContentText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
    },
    managePricesItemBtn: {
      // cursor: 'pointer',
    },
  }),
);

const ManagePrices = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const toRemainder = () => {
    // navigate('/manage-prices/current-remainder');
    window.location.href = '/manage-prices/current-remainder';
  }
  const toPriceDrop = () => {
    // navigate('/manage-prices/price-drop');
    window.location.href = '/manage-prices/price-drop';
  };
  const toLogisticsCost = () => {
    navigate('/manage-prices/logistics-cost');
  };

  const token = document.cookie.split('token="')
  const filterToken = token[1]?.split('\"')
  const logResult = useCallback(() => {
    return window.location.pathname
  }, [window.location.pathname])

  useEffect(() => {
    if(!filterToken && window.location.pathname !== '/login') {
      window.location.replace('/login')
    }

  }, [logResult])


  return (
    <>
      <div className={classes.content}>
        <div className={classes.managePricesItem} onClick={() => toRemainder()}>
          <div className={classes.managePricesContent}>
            <BoxIcon />
            <div className={classes.managePricesContentInfo}>
              <span className={classes.managePricesContentTitle}>Расчет цены по спросу и остаткам</span>
              <span className={classes.managePricesContentText}>Прогноз на необходимые остатки в зависимости от срока поставки и спроса</span>
            </div>
          </div>
          <span className={classes.managePricesItemBtn}>
            <RightArrow />
          </span>
        </div>
        <div className={classes.managePricesItem} onClick={() => toPriceDrop()}>
          <div className={classes.managePricesContent}>
            <DiagramIcon />
            <div className={classes.managePricesContentInfo}>
              <span className={classes.managePricesContentTitle}>Расчет цены для акций</span>
              <span className={classes.managePricesContentText}>Список запросов на активацию пользователей и их статус</span>
            </div>
          </div>
          <span className={classes.managePricesItemBtn}>
            <RightArrow />
          </span>
        </div>
        {/* <div className={classes.managePricesItem} onClick={() => toLogisticsCost()}>
          <div className={classes.managePricesContent}>
            <RubCircle />
            <div className={classes.managePricesContentInfo}>
              <span className={classes.managePricesContentTitle}>Стоимости логистики и процента комиссии</span>
              <span className={classes.managePricesContentText}>Данные по комиссиям, расценкам на логистику и условиям хранения</span>
            </div>
          </div>
          <span className={classes.managePricesItemBtn}>
            <RightArrow />
          </span>
        </div> */}
      </div>
    </>
  )
}

export default ManagePrices
