import React, { useEffect, useState } from 'react';
import cookie from 'cookie';
import classNames from 'classnames';
import { Close } from '@material-ui/icons';
import { makeStyles, createStyles, Tooltip } from '@material-ui/core';

import { delBell } from '../../api/reports';

const useStyles = makeStyles(() =>
  createStyles({
    notificationModal: {
      // display: 'none',
      position: 'fixed',
      width: 329,
      height: '100vh',
      background: '#fff',
      top: 0, right: 0,
      transform: 'translateX(100%)',
      transition: 'all .2s ease-out',
      padding: 16,
      paddingRight: 12,
      boxSizing: 'border-box',
      borderLeft: '1px solid #D3D1CB',
      zIndex: 1299,
      overflowY: 'auto',
    },
    show: {
      // display: 'block',
      transform: 'translateX(0)',
    },
    notificationHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 32,
    },
    notificationTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
    },
    notificationClose: {
      fontSize: 24,
      cursor: 'pointer',
    },
    notificationContent: {},
    notificationMiniTitle: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginBottom: 8,

      '& svg': {
        marginRight: 4,
      },
    },
    notificationList: {
      padding: 0,
      listStyle: 'none',
    },
    notificationListItem: {
      maxWidth: 280,
      paddingTop: 12,
      paddingBottom: 12,
      borderBottom: '1px solid #E9E9E7',
    },
    notificationName: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 4,

      '& span': {
        color: '#827F75',
      },
    },
    notificationInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,

      '& span': {
        color: '#827F75',
      },
    },
    notificationPopover: {},
    setBtn: {
      display: 'inline-block',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textDecorationLine: 'underline',
      color: '#177367',
      marginTop: 12,
      marginBottom: 12,
      marginRight: 16,
      cursor: 'pointer',
    },
  }),
);

interface Props {
  show: boolean;
  list?: any;
  closeEvent: () => void;
  refetch: () => void;
};

const dataList = [
  { key: 'data_1', name: 'Данные отсутствуют' },
  { key: 'data_2', name: 'Данные совпадают' },
  { key: 'data_3', name: 'Не хватает данных' },
  { key: 'data_4', name: '' }
];

const NotificationModalSizePage = (props: Props) => {
  const classes = useStyles();

  const [loaded, setLoaded] = React.useState(false);

  const [hideData1, setHideData1] = React.useState(false);
  const [hideData2, setHideData2] = React.useState(false);
  const [hideData3, setHideData3] = React.useState(false);
  const [hideData4, setHideData4] = React.useState(false);
  const [hideData5, setHideData5] = React.useState(false);
  const [notifyList, setNotifyList] = React.useState([]);

  const equalCheck = (el: any, key: string, item?: any): any => {
    const check = !!(item?.value?.length === 1) || el[0]?.[key] !== el[el.length - 1]?.[key];

    const names: any = {
      'height': "Высота",
      'length_': "Длина",
      'volume': "Объем",
      'weight': "Вес",
      'width': "Ширина",
    }

    return {
      oldUid: el[0]?.uid,
      newUid: el[el.length - 1]?.uid,
      new: el[el.length - 1]?.[key],
      old: el[0]?.[key] || 0,
      check: check,
      name: names?.[key]
    }
  };
  const [open, setOpen] = useState(false);
  const [delayedClose, setDelayedClose] = useState<NodeJS.Timeout | null>(null);

  const sendDelBell = async (uid: string) => {
    const org = await cookie.parse(document.cookie)['org_uid'];
    await delBell({ org, uid });
    props.refetch();
  };

  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  const handleCloseModal = () => {
    setOpen(false);
    props.closeEvent();
  };

  const handleMouseLeave = () => {
    setDelayedClose(setTimeout(() => {
      setOpen(false);
      props.closeEvent();
    }, 500));
  };

  const handleMouseEnter = () => {
    if (delayedClose) {
      clearTimeout(delayedClose);
      setDelayedClose(null);
    }
  };


  return (
    <div
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      className={classNames(classes.notificationModal, props.show ? classes.show : '')}>
      {props?.list && (
        <>
          <div className={classes.notificationHeader}>
            <h1 className={classes.notificationTitle}>Уведомления</h1>
            <span className={classes.notificationClose}
              onClick={handleCloseModal}
            >
              <Close />
            </span>
          </div>
          {(props?.list?.['data_1'] && props?.list?.['data_1'].length) ? (
            <div className={classes.notificationContent}>
              <h5 className={classes.notificationMiniTitle} onClick={() => setHideData1(!hideData1)}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: `rotate(${hideData1 ? '-90deg' : '0'})` }}
                >
                  <path d="M4 6L8 10L12 6" stroke="#37352F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Данные отсутствуют
              </h5>

              <ul className={classes.notificationList}>
                {!hideData1 ? props?.list?.['data_1'].map((item: any, index: number) => (
                  <li className={classes.notificationListItem} key={index}>
                    <p className={classes.notificationName}>
                      <span>Предмет:</span>
                      <br />
                      {item.category} {item.supplier_article}</p>
                    <p className={classes.notificationInfo}>
                      <span>
                        {
                          (!item.height || item.height === '0.0' || item.height === '0')
                            || (!item.length_ || item.length_ === '0.0' || item.length_ === '0')
                            || (!item.volume || item.volume === '0.0' || item.volume === '0')
                            || (!item.weight || item.weight === '0.0' || item.weight === '0')
                            || (!item.width || item.width === '0.0' || item.width === '0') ? (
                            'Отсутствуют данные'
                          ) : null
                        }
                      </span>
                      {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                      {/* </Tooltip> */}
                    </p>
                  </li>
                )) : null}
              </ul>
            </div>
          ) : null}
          {(props?.list?.['data_2'] && props?.list?.['data_2'].length) ? (
            <div className={classes.notificationContent}>
              <h5 className={classes.notificationMiniTitle} onClick={() => setHideData2(!hideData2)}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: `rotate(${hideData2 ? '-90deg' : '0'})` }}
                >
                  <path d="M4 6L8 10L12 6" stroke="#37352F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Данные не совпадают
              </h5>
              <ul className={classes.notificationList}>
                {!hideData2 ? props?.list?.['data_2'].map((item: any, index: number) => (
                  <>
                    {/* {JSON.stringify(item)} */}
                    {(equalCheck(item.value, 'value', item).check
                      || equalCheck(item.value, 'length_', item).check
                      || equalCheck(item.value, 'volume', item).check
                      || equalCheck(item.value, 'weight', item).check
                      || equalCheck(item.value, 'width', item).check) && (
                        <li className={classes.notificationListItem} key={index}>
                          <p className={classes.notificationName}>
                            <span>Предмет:</span>
                            <br />
                            {item.data}
                          </p>
                          <br />
                          {equalCheck(item.value, 'value').check && (
                            <>
                              <p className={classes.notificationInfo}>
                                <span>
                                  {equalCheck(item.value, 'value').name} {equalCheck(item.value, 'value').old} (Старое)
                                </span>
                                {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </span>
                                {/* </Tooltip> */}
                              </p>
                              <p className={classes.notificationInfo}>
                                <span>
                                  {equalCheck(item.value, 'value').name} {equalCheck(item.value, 'value').new} (Новое)
                                </span>
                                {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </span>
                                {/* </Tooltip> */}
                              </p>

                              <span
                                className={classes.setBtn}
                                onClick={() => sendDelBell(equalCheck(item.value, 'value').oldUid)}
                              >
                                Установить {equalCheck(item.value, 'value').old}
                              </span>
                              <span
                                className={classes.setBtn}
                                onClick={() => sendDelBell(equalCheck(item.value, 'value').newUid)}
                              >
                                Установить {equalCheck(item.value, 'value').new}
                              </span>
                            </>
                          )}
                          {equalCheck(item.value, 'length_').check && (
                            <>
                              <p className={classes.notificationInfo}>
                                <span>
                                  {equalCheck(item.value, 'length_').name} {equalCheck(item.value, 'length_').old} (Старое)
                                </span>
                                {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </span>
                                {/* </Tooltip> */}
                              </p>
                              <p className={classes.notificationInfo}>
                                <span>
                                  {equalCheck(item.value, 'length_').name} {equalCheck(item.value, 'length_').new} (Новое)
                                </span>
                                {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </span>
                                {/* </Tooltip> */}
                              </p>

                              <span
                                className={classes.setBtn}
                                onClick={() => sendDelBell(equalCheck(item.value, 'length_').oldUid)}
                              >
                                Установить {equalCheck(item.value, 'length_').old}
                              </span>
                              <span
                                className={classes.setBtn}
                                onClick={() => sendDelBell(equalCheck(item.value, 'length_').newUid)}
                              >
                                Установить {equalCheck(item.value, 'length_').new}
                              </span>
                            </>
                          )}
                          {equalCheck(item.value, 'volume').check && (
                            <>
                              <p className={classes.notificationInfo}>
                                <span>
                                  {equalCheck(item.value, 'volume').name} {equalCheck(item.value, 'volume').old} (Старое)
                                </span>
                                {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </span>
                                {/* </Tooltip> */}
                              </p>
                              <p className={classes.notificationInfo}>
                                <span>
                                  {equalCheck(item.value, 'volume').name} {equalCheck(item.value, 'volume').new} (Новое)
                                </span>
                                {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </span>
                                {/* </Tooltip> */}
                              </p>

                              <span
                                className={classes.setBtn}
                                onClick={() => sendDelBell(equalCheck(item.value, 'volume').oldUid)}
                              >
                                Установить {equalCheck(item.value, 'volume').old}
                              </span>
                              <span
                                className={classes.setBtn}
                                onClick={() => sendDelBell(equalCheck(item.value, 'volume').newUid)}
                              >
                                Установить {equalCheck(item.value, 'volume').new}
                              </span>
                            </>
                          )}
                          {equalCheck(item.value, 'weight').check && (
                            <>
                              <p className={classes.notificationInfo}>
                                <span>
                                  {equalCheck(item.value, 'weight').name} {equalCheck(item.value, 'weight').old} (Старое)
                                </span>
                                {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </span>
                                {/* </Tooltip> */}
                              </p>
                              <p className={classes.notificationInfo}>
                                <span>
                                  {equalCheck(item.value, 'weight').name} {equalCheck(item.value, 'weight').new} (Новое)
                                </span>
                                {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </span>
                                {/* </Tooltip> */}
                              </p>

                              <span
                                className={classes.setBtn}
                                onClick={() => sendDelBell(equalCheck(item.value, 'weight').oldUid)}
                              >
                                Установить {equalCheck(item.value, 'weight').old}
                              </span>
                              <span
                                className={classes.setBtn}
                                onClick={() => sendDelBell(equalCheck(item.value, 'weight').newUid)}
                              >
                                Установить {equalCheck(item.value, 'weight').new}
                              </span>
                            </>
                          )}
                          {equalCheck(item.value, 'width').check && (
                            <>
                              <p className={classes.notificationInfo}>
                                <span>
                                  {equalCheck(item.value, 'width').name} {equalCheck(item.value, 'width').old} (Старое)
                                </span>
                                {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </span>
                                {/* </Tooltip> */}
                              </p>
                              <p className={classes.notificationInfo}>
                                <span>
                                  {equalCheck(item.value, 'width').name} {equalCheck(item.value, 'width').new} (Новое)
                                </span>
                                {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </span>
                                {/* </Tooltip> */}
                              </p>

                              <span
                                className={classes.setBtn}
                                onClick={() => sendDelBell(equalCheck(item.value, 'width').oldUid)}
                              >
                                Установить {equalCheck(item.value, 'width').old}
                              </span>
                              <span
                                className={classes.setBtn}
                                onClick={() => sendDelBell(equalCheck(item.value, 'width').newUid)}
                              >
                                Установить {equalCheck(item.value, 'width').new}
                              </span>
                            </>
                          )}
                        </li>
                      )}
                  </>
                )) : null}
              </ul>
            </div>
          ) : null}
          {(props?.list?.['data_3'] && props?.list?.['data_3'].length) ? (
            <div className={classes.notificationContent}>
              <h5 className={classes.notificationMiniTitle} onClick={() => setHideData3(!hideData3)}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: `rotate(${hideData3 ? '-90deg' : '0'})` }}
                >
                  <path d="M4 6L8 10L12 6" stroke="#37352F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Не хватает данных
              </h5>

              <ul className={classes.notificationList}>
                {!hideData3 ? props?.list?.['data_3'].map((item: any, index: number) => (
                  <li className={classes.notificationListItem} key={index}>
                    <p className={classes.notificationName}>
                      <span>Артикул производителя: </span>
                      {item.supplier_article}</p>
                    <p className={classes.notificationInfo}>
                      <span></span>
                      {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                      {/* </Tooltip> */}
                    </p>
                  </li>
                )) : null}
              </ul>
            </div>
          ) : null}
          {props?.list?.['data_4'] && (
            <div className={classes.notificationContent}>
              <h5 className={classes.notificationMiniTitle} onClick={() => setHideData4(!hideData4)}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: `rotate(${hideData4 ? '-90deg' : '0'})` }}
                >
                  <path d="M4 6L8 10L12 6" stroke="#37352F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Не проставлены пороги
              </h5>
              {!hideData4 && props?.list?.['data_4'] && (
                <ul className={classes.notificationList}>
                  {props?.list?.['data_4']?.map((item: any, index: number) => (
                    <li className={classes.notificationListItem} key={index}>
                      <p className={classes.notificationName}>
                        <span>Предмет:</span>
                        <br />
                        {item.brand} {item.category}</p>
                      <p className={classes.notificationInfo}>
                        <span>
                          {Number(item.id_wb) || null}
                        </span>
                        {/* <Tooltip title={item.rest_wb_fbo} placement="top"> */}
                        <span>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                        {/* </Tooltip> */}
                      </p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {(props?.list?.['data_5'] && props?.list?.['data_5'].length) ? (
            <div className={classes.notificationContent}>
              <h5 className={classes.notificationMiniTitle} onClick={() => setHideData1(!hideData1)}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: `rotate(${hideData1 ? '-90deg' : '0'})` }}
                >
                  <path d="M4 6L8 10L12 6" stroke="#37352F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Обратите внимание
              </h5>

              <ul className={classes.notificationList}>
                {!hideData5 ? props?.list?.['data_5'].map((item: any, index: number) => (
                  <li className={classes.notificationListItem} key={index}>
                    <p className={classes.notificationName}>
                      <span>Предмет:</span>
                      <br />
                      {item.category} {item.supplier_article}</p>
                    <p className={classes.notificationInfo}>
                      <span>
                        {
                          (!item.height || item.height === '0.0' || item.height === '0')
                            || (!item.length_ || item.length_ === '0.0' || item.length_ === '0')
                            || (!item.volume || item.volume === '0.0' || item.volume === '0')
                            || (!item.weight || item.weight === '0.0' || item.weight === '0')
                            || (!item.width || item.width === '0.0' || item.width === '0') ? (
                            'Отсутствуют данные'
                          ) : null
                        }
                      </span>
                      {/* <Tooltip title={item.rest_wb_fbo || ''} placement="top"> */}
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668Z" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8 5.3335V8.00016" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8 10.6665H8.00667" stroke="#FF6C4B" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                      {/* </Tooltip> */}
                    </p>
                  </li>
                )) : null}
              </ul>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default NotificationModalSizePage;
