import { makeStyles, createStyles, ButtonGroup, Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import CustomModal from '../../UI/CustomModal';
import cookie from 'cookie';
import { getCalcOrg, setCalcOrg } from '../../../api/calc';
import { updateOrgStorageType } from '../../../api/organizations';
import { TariffsType, findTariff } from '../../../api/tariffs';
import moment from 'moment';

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      '.makeStyles-modal-134 .MuiDialog-paperWidthSm': {
        maxWidth: '786px !important',
      },
    },
    content: {},
    params: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 32,
      paddingBottom: 0,
    },
    myT: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    paramsBtns: {},
    paramsBtn: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      color: '#37352F',
      textTransform: 'none',
      background: '#FFFFFF',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
    },
    paramsFirstBtn: {
      boxSizing: 'border-box',
      borderRadius: '8px 0px 0px 8px',
    },
    paramsLastBtn: {
      borderRadius: '0px 8px 8px 0px !important',
    },
    paramsForCalculationBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      paddingBottom: 2,
      borderBottom: '1px solid #37352F',
      cursor: 'pointer',
    },
    paramsModal: {
      marginTop: 32,
    },
    paramsModalText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      maxWidth: 600,
      marginBottom: 32,
    },
    paramsModalContent: {},
    paramsModalLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    paramsModalBtns: {
      marginBottom: 32,
    },
    paramsModalBtn: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      height: 44,
      lineHeight: '140%',
      color: '#37352F',
      textTransform: 'none',
      background: '#FFFFFF',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      // borderRadius: '8px 0px 0px 8px !important',

      '&:first-child': {
        borderRadius: '8px 0px 0px 8px',
      },
      '&:last-child': {
        borderRadius: '0px 8px 8px 0px',
      },
    },
    paramsNeedCountModalBtns: {
      marginBottom: 0,
    },
    paramsNeedCountModalBtn: {
      '&:last-child': {
        borderRadius: '0',
      },
    },
    paramsModalFirstBtn: {
      boxSizing: 'border-box',
      borderRadius: '8px 0px 0px 8px',
    },
    paramsModalLastBtn: {
      borderRadius: '0px 8px 8px 0px',
    },
    paramsOneBtn: {
      borderRadius: '8px !important',
    },
    active: {
      background: '#E9E9E7',
      border: '1px solid #37352F',
    },
    fieldBlock: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& span': {
        color: '#FF6C4B',
      },

      '&.disabled': {
        background: '#fafafa',
      },
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: 300,
      border: '1px solid #D3D1CB',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      borderRadius: '0px 8px 8px 0px',
      overflow: 'hidden',
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '6px 8px',
      width: 300,
      border: '1px solid transparent',
      outline: 'none',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      borderRadius: '0px 8px 8px 0px',
    },
    activeField: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#37352F',
      },
    },
    newCountField: {
      // border: '1px solid #f00',

      '& .MuiInputBase-root': {
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
        borderRadius: '0px 8px 8px 0px',
      },
      '& .MuiInputBase-input': {
        padding: '6.2px 14px',
      },
    },
    fieldHint: {
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      marginBottom: 32,
    },
    paramsModalCheckbox: {
      marginBottom: 34,
      '& span': {
        fontSize: 14,
      },
    },
  })
);

interface Props {
  updateParams?: (req: any) => void;
  updateTable?: () => void;
  calculate_type: number;
  weeksCount: number;
  stockType: number;
  setCalcType: (val: number) => void;
  setLoading: (val: boolean) => void;
};

export const ManagePricesParams = (props: Props) => {
  const classes = useStyles()
  const broadcastChannel = new BroadcastChannel("quote_channel");
  const btnSave = document.querySelector('.modalSuccessBtn')

  const weeks = localStorage.getItem('weeks')

  const [param, setParam] = React.useState(1);
  const [loaded, setLoaded] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [selectedWeek, setSelectedWeek] = React.useState<number |  null | undefined>(null);
  const [stocksType, setStocksType] = React.useState(1);
  const [remainBalance, setRemainBalance] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [needCountField, setNeedCountField] = React.useState(false);
  const [disabledNeedCountField, setDisabledNeedCountField] = React.useState(false);
  const [current, setCurrent] = useState<any>(null);
  const [prevRemainsType, setPrevRemainsType] = useState<number>(0);
  // const [toggleDisabled, setToggleDisabled] = useState(false);
  const [a, setA] = useState<any>(null);


useEffect(() => {
  const weeksDop = localStorage.getItem('weeksDop')
    if(weeksDop) {
      setCurrent(JSON.parse(weeksDop.toLowerCase()))
      setA(JSON.parse(weeksDop.toLowerCase()))
    }
}, []);

  useEffect(() => {
    // setSelectedWeek(props.weeksCount);

    setStocksType(props.stockType > 3 ? props.stockType - 3 : props.stockType);
    setRemainBalance(props.stockType > 3);
    setLoaded(true);
  }, [props.stockType, props.weeksCount]);

  useEffect(() => {
    // setParam(props.calculate_type);
    setParam(1)
  }, []);

  useEffect(() => {
    if (selectedWeek !== 8 && selectedWeek !== 11)
      setNeedCountField(true);
  }, [selectedWeek])

  const btns = [
    { id: 0, name: '0 недель' },
    { id: 1, name: '1 неделя' },
    { id: 2, name: '2 недели' },
    { id: 4, name: '4 недели' },
    { id: 6, name: '6 недель' },
    { id: 8, name: '8 недель' },
    { id: 11, name: '11 недель' },
  ];
  const [remainsTypeBtns, setRemainsTypeBtns] = React.useState([
    { id: 1, name: 'Только fbo' },
    { id: 2, name: 'Только fbs' },
    { id: 3, name: 'fbo + fbs' },
  ]);

  const currentTariff = useQuery<TariffsType>('currentTariff', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await findTariff(uid);

    return result.data.data;
  });

  useEffect(() => {
    if (currentTariff?.data?.uid === 'ff4d3434-ebcc-4320-add7-7c00da2c7e16')
      setDisabledNeedCountField(false);
    if (currentTariff?.data?.uid !== 'ff4d3434-ebcc-4320-add7-7c00da2c7e16')
      setDisabledNeedCountField(true);
  }, [currentTariff?.data]);

  useQuery('orgType', async () => {
    const remainsTypes = JSON.parse(JSON.stringify(remainsTypeBtns));
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await updateOrgStorageType({ org: uid, action: 'show' });
    setPrevRemainsType(result.data.data[0].type)
    if (result.data.data[0].type === 0)
      setRemainsTypeBtns(remainsTypes.filter(el => el.id === 1));
    if (result.data.data[0].type === 1)
      setRemainsTypeBtns(remainsTypes.filter(el => el.id === 2));
    return result.data.data;
  });

  useQuery(['params', { param }], async (queryParam: any) => {
    if (!param) return;
    if (param === props.calculate_type) return;
    else props.setCalcType(param);
    props.setLoading(true);
    const uid = await cookie.parse(document.cookie)['org_uid'];

    const result = await setCalcOrg({
      org: uid,
      calculate_type: param,
    });

    // if (props.updateTable) props.updateTable();
    return result.data.data;
  });

  const getEndDayDate = () => {
    const a: any = moment().startOf('day');
    const b: any = moment(moment().add(1, 'days'));
    const endDay = (moment.duration(a - b).asHours() + 29) * -1;
    return moment(moment(), 'YYYY-MM-DD').add(endDay, 'hours');
  };

  const saveParamsForCalculation = async () => {
    localStorage.setItem('weeks', String(selectedWeek));
    localStorage.setItem('weeksDop', String(current));
    setA(current)
    props.setLoading(true);
    document.cookie = `save=1;expires=${getEndDayDate()};`;
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const orgParams = await getCalcOrg({ org: uid });
    let weeks = selectedWeek < 0
      ? orgParams.data.data[0].weeks_count
      : selectedWeek;
    let stockType = stocksType < 0 ? orgParams.data.data[0].stocks_type : stocksType;
    if (remainBalance) {
      stockType += 3;
    }

    let req = {
      org: uid,
      rubles_procent: orgParams.data.data[0].rubles_procent,
      weeks_count: weeks,
      calculate_type: param < 0 ? orgParams.data.data[0].calculate_type : param,
      stocks_type: stockType || 1,
      current_week: current ? 1 : 0
    };

    const result = await setCalcOrg(req);

    if (props.updateParams) {
      props.updateParams(req);
      setOpen(false);
    }
    if (props.updateTable) props.updateTable();
    props.setLoading(false);
    // setToggleDisabled(true);
    return result.data.data;
  };

  const handleSetParam = (val: number) => {
    setParam(val);
  };

  const validateNeedCount = (val: string) => {
    const regex = /((?=.)[^0-9]|^$)/gi;
    const data = Number(val.replace(regex, ''));
    if (data === 0) {
      setSelectedWeek('');
      return;
    }
    if (data >= 1 && data <= 11)
      setSelectedWeek(data);
  };
  const changeCurrent = () => {
    setCurrent(!current)
  }

  const clickWeeks = (item) => {
    if(item.id === 0) {
      setCurrent(true)
    }
        setSelectedWeek(item.id)
    }

  const toggleCheck = () => {
    if(prevRemainsType === stocksType && Number(weeks) === selectedWeek && a === current) {
      return true
    }
    else {
      return false
    }
  }

  useEffect(()=>{
      setSelectedWeek(props.weeksCount)
  },[props.weeksCount])

  const close = () => {
    const weeks = localStorage.getItem('weeks')
    setSelectedWeek(Number(weeks))
    setOpen(false)
  }

  return (
    <div className={classes.params}>

      <CustomModal
        title="Параметры для расчетов"
        show={open}
        successText="Сохранить"
        cancelText="Отмена"
        disabledSuccessBtn={selectedWeek || selectedWeek === 0 ? toggleCheck() : true}
        successEvent={() => saveParamsForCalculation()}
        closeEvent={() => close()}
      >
        <div className={classes.paramsModal}>
          <p className={classes.paramsModalText}>
            Для стабильных продаж необходимо иметь товара доступного к продаже на 60-79 дней, то есть на 2-2,5 месяца. Исходя из этого проставьте параметры по которым хотите расчитывать остатки:
          </p>

          <div className={classes.paramsModalContent}>
            <p className={classes.paramsModalLabel}>
              Необходимый остаток
            </p>
            <ButtonGroup
              className={
                classNames(
                  classes.paramsModalBtns,
                  classes.paramsNeedCountModalBtns
                )
              }
              variant="outlined"
            >
              {btns.map((item: any) => (
                <Button
                  className={
                    classNames(
                      classes.paramsModalBtn,
                      // classes.paramsNeedCountModalBtn, // && !toggleDisabled
                      selectedWeek === item.id ? classes.active : ''
                    )
                  }
                  onClick={() => {
                    clickWeeks(item)
                  }}
                  key={item.id}
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
            <p className={classes.fieldHint}>
              {/* Выберите 1-н из вариантов или введите свой */}
            </p>
          </div>
          {
            selectedWeek === 0 ?
            <FormControlLabel
              style={{ marginBottom: 10 }}

              control={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Checkbox disabled color="default" checked={true} />
                  <div className={classes.myT}>учитывать данные с текущей недели продаж</div>
                </div>

              }
              label=""
            />
          :
            <FormControlLabel
                style={{ marginBottom: 10 }}
                control={
                  <Checkbox color="default" checked={current} />
                }
                label="учитывать данные с текущей недели продаж"
                onChange={changeCurrent}
            />
          }




          <div className={classes.paramsModalContent}>
            <p className={classes.paramsModalLabel}>
              Учитывать остатки
            </p>
            <ButtonGroup className={classes.paramsModalBtns} variant="outlined">
              {remainsTypeBtns.map((item: any) => (
                <Button
                  className={
                    classNames(
                      classes.paramsModalBtn,
                      remainsTypeBtns.length === 1 ? classes.paramsOneBtn : null,
                      stocksType === item.id ? classes.active : ''
                    )
                  }
                  onClick={() => setStocksType(item.id)}
                  key={item.id}
                >
                  {item.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div
            className={"MuiFormControlLabel-root "+
              classNames(
                classes.paramsModalContent,
                classes.paramsModalCheckbox
              )
            }
          >
            {/* <Checkbox
              color="default"
              checked={remainBalance}
              value={remainBalance}
              onChange={() => setRemainBalance(!remainBalance)}
            />
            <span class="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
              учитывать «Свободные остатки / остатки в пути»
            </span> */}
            {/* <FormControlLabel
              className={classes.paramsModalCheckbox}
              control={
                <Checkbox color="default" checked={remainBalance} />
              }
              label="учитывать «Свободные остатки / остатки в пути»"
              value={remainBalance}
              onChange={() => setRemainBalance(!remainBalance)}
            /> */}
          </div>
        </div>
      </CustomModal>
      <ButtonGroup className={classes.paramsBtns} variant="outlined">
        <Button
          className={
            classNames(
              classes.paramsFirstBtn,

              classes.paramsBtn,
              param === 1 ? classes.active : ''
            )
          }
          onClick={() => handleSetParam(1)}
        >
          По заказам
        </Button>
        <Button
          className={
            classNames(
              classes.paramsLastBtn,
              classes.paramsBtn,
              param === 2 ? classes.active : ''
            )
          }
          disabled
          onClick={() => handleSetParam(2)}
        >
          По продажам
        </Button>
      </ButtonGroup>

      <span className={classes.paramsForCalculationBtn} onClick={() => setOpen(true)}>
        Параметры для расчетов
      </span>
    </div>
  )
}
