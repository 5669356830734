import React, { useRef } from 'react'
import classNames from 'classnames';
import { useQuery } from 'react-query';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Button, Chip, createStyles, makeStyles, Menu, MenuItem, OutlinedInput, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, useTheme } from '@material-ui/core'
import cookie from 'cookie';
import moment from 'moment';

import Main from '../../components/layout/Main'
import { Bell, MoreVerticalIcon, Plus, Search } from '../../components/UI/Icons'
import { wbDataReadExcel, wbDataSaveExcel } from '../../api/wb-data';
import Spinner from '../../components/UI/Spinner';
import CustomModal from '../../components/UI/CustomModal';
import { getCategory, setOgrData } from '../../api/calc';
import { LogisticsCostFilterMenu } from '../../components/ManagePrices/LogisticsCost/FilterMenu';
import { Autocomplete } from '@material-ui/lab';
import { clearFilter, getRegions, setColum, setColumLocation } from '../../api/reports';
import LogisticsCostNotificationModal from '../../components/ManagePrices/LogisticsCost/NotificationModal';
import CustomSnackbar from '../../components/UI/CustomSnackbar';
import SettingsMenu from '../../components/UI/SettingsMenu';

const useStyles = makeStyles(() => 
  createStyles({
    '@global': {
      'th': {
        backgroundColor: 'transparent !important',
      },
    },
    content: {
      padding: 32,
      boxSizing: 'border-box',
      height: 'calc(100vh - 63px)',
      overflowY: 'auto',
    },
    contentBlock: {},
    toolbar: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    toolbarCount: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginRight: 10
    },
    toolbarBtns: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    toolbarSearchBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      // textDecoration: 'underline',
      cursor: 'pointer',
      marginRight: 16,

      '& svg': {
        stroke: '#37352F',
      },
      '&:hover svg': {
        stroke: '#827F75',
      },
      '&:hover': {
        color: '#827F75',
      },
    },
    toolbarSearchBtnText: {
      marginLeft: 4,
      borderBottom: '1px solid #000',
      paddingBottom: 1,

      '&:hover': {
        borderBottom: '1px solid #827F75',
      }
    },
    toolbarSearch: {
      border: 'none',
      outline: 'none',
      borderBottom: '1px solid #37352F',
      marginLeft: 8,
      marginBottom: 2,
      width: 40,
      transition: 'all .3s ease-out',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:focus': {
        width: 222,
        cursor: 'auto',
      },
      '&::placeholder': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#37352F',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        // textDecoration: 'underline',
        cursor: 'pointer',
        transition: 'all .2s ease-out',
      },
      '&.focus': {
        '&::placeholder': {
          fontFamily: '"Work Sans", sans-serif',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px',
          color: '#827F75',
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none',
          // textDecoration: 'underline',
        },
      },
    },
    toolbarFilter: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 16,
    },
    toolbarFilterBtn: {
      position: 'relative',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '14px',
      color: '#37352F',
      textTransform: 'capitalize',
      borderBottom: '1px solid #000',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:hover': {
        borderBottom: '1px solid #827F75',
        color: '#827F75',
      },
    },
    settingChip: {
      position: 'absolute',
      top: -10, right: -10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '12px',
      width: 14,
      height: 14,
      color: 'white',
      background: '#FF6C4B',
      borderRadius: '50%',
    },
    toolbarAddBtn: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'capitalize',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      padding: '8px 16px 8px 10px',
      cursor: 'pointer',

      '& svg': {
        stroke: '#ffffff',
      },

      '&:hover': {
        background: '#827F75',
      },
    },
    toolbarIconBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 40,
      marginRight: 16,
      cursor: 'pointer',
    },
    emptyData: {
      width: '100%',
      boxSizing: 'border-box',
      padding: 42,
      border: '1px solid #E9E9E7',
      borderRadius: 8,
    },
    emptyText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      textAlign: 'center',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    tableWrap: {
      overflowX: 'auto',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 198.9px)',
      margin: '32px 0',
      marginBottom: 0,

      '& .MuiPaper-root': {
        backgroundColor: 'transparent !important',
      },
    },
    tableContainer: {
      boxShadow: 'none',
      borderTop: '1px solid #E9E9E7',
      borderBottom: '1px solid #E9E9E7',
      width: 'fit-content !important'
    },
    table: {
      width: 'fit-content',
      borderCollapse: 'collapse',
      position: 'relative',

      '& .MuiTableRow-head': {
        background: '#fff',
      },
      '& .MuiTableCell-head': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#827F75',
        padding: '8px 16px',
        borderRight: '1px solid #E9E9E7',
        boxSizing: 'border-box',
        minWidth: '120px',
        maxWidth: '120px',
        position: 'sticky',
        top: 0,
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
      },

      '& .MuiTableCell-body': {
        padding: '8px 16px !important',
        borderRight: '1px solid #E9E9E7',
        boxSizing: 'border-box',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 16px !important',
        borderRight: 'none',
      },
    },
    tableHead: {
      position: 'sticky',
      top: 0,
      zIndex: 99,
      background: '#fff',
    },
    tableRow: {
      '&:hover': {
        background: 'rgba(242, 242, 240, 0.5) !important',
      },
    },
    tableCell: {
      padding: '8px 16px !important',
      '&:hover': {
        background: 'rgba(225, 225, 228, 0.5) !important'
      },
    },
    infiniteScroll: {
      // height: 'calc(100vh - 63px)',
    },
    settingsMenu: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 16,
        top: '126px !important',
        maxHeight: '70vh',
      },
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    settingsContet: {
      padding: 16,
      maxWidth: 330,
      width: '100vh',
    },
    settingsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,

      '& svg': {
        width: 20,
        height: 20,
      }
    },
    settingsTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    settingsList: {

    },
    settingsItem: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      cursor: 'grab !important',
    },
    settingsItemContent: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      padding: '6px 0',
      borderTop: '1px solid #fff',
      transition: 'all 0.1s ease-in-out',

      '& svg': {
        marginRight: 4,
        minWidth: 16,
      },
    },
    settingsItemSwitch: {
      width: 35,
      height: 18,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#000',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: '#ccc',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 14,
        height: 14,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: 'all .5s ease-in-out',
      },
    },
    tableSelect: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      borderBottom: 'none !important',

      '& .MuiSelect-selectMenu': {
        backgroundColor: 'transparent',
      },
      '&::after': {
        display: 'none !important',
      },
      '&::before': {
        display: 'none !important',
      },
    },
    selectBlock: {
      width: '100%',
      outline: 'none',
      height: 44,
      border: 'none !important',
      borderRadius: 8,
      marginBottom: 40,

      '&.Mui-focused': {
        outline: 'none !important',
        border: 'none !important',
      },
      '& .MuiSelect-select': {
        background: 'transparent !important',
      }
    },
    selectInput: {
      border: '1px solid #D3D1CB',
      outline: 'none',
    },
    selectChip: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      marginRight: 3,
      padding: '4px 8px',
    },
    selectChipClose: {
      width: '16px !important',
      height: '16px !important',
      marginLeft: '8px !important'
    },
    productContent: {
      paddingTop: 32,
    },
    productLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    autocomplete: {
      // border: '1px solid red'
      marginBottom: 56,
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 8,
      },
    },
    autocompleteInput: {
      '& .MuiAutocomplete-inputRoot': {
        border: 'none',
        outline: 'none',
        paddingTop: 3,
        paddingBottom: 3,
      },
      '& .MuiChip-root': {
        background: '#37352F',
        borderRadius: 8,
        height: 24,
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#FFFFFF',
      },
      '& .MuiChip-deleteIcon': {
        fontSize: 21,
        lineHeight: '100%',
        color: '#fff',
      },
    },
    settingsCancelBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 8,
      paddingBottom: 8,
    },
    settingsCancel: {
      cursor: 'pointer',
      borderBottom: '1px solid #37352F',
    },
  }),
);

function useFocus(): [any, any] {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

  return [ htmlElRef, setFocus ] 
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null
};
const getStyles = (name: string, personName: readonly string[], theme: Theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

export const LogisticsCost = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [count, setCount] = React.useState(0);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loader, setLoader] = React.useState(false);
  const [cities, setCities] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [excel, setExcel] = React.useState<any>([]);
  const [list, setList] = React.useState<any>([]);
  const [fullList, setFullList] = React.useState<any>([]);
  const [dwbtUid, setDwbtUid] = React.useState<string>('bbbd04b9-f5de-41e5-8507-502286f27fcc');
  const [selectedCategories, setSelectedCategories] = React.useState<Array<any>>([]);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [showNotify, setShowNotify] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [categories, setCategories] = React.useState([]);
  const [settingsCount, setSettingsCount] = React.useState(0);
  const [searchRef, setSearchFocus] = useFocus()
  const perPage = 150000;

  const regions = useQuery('regions', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await getRegions(uid);
    let countSeting = 0;
    setCities(result.data.data.map((item: any, i: number) => {
      if (!!item.colum) countSeting += 1;
      return {
        uid: item.uid,
        regionName: item.regionName,
        lastUpdate: item.lastUpdate,
        sort: i,
        showed: !item.colum
      }
    }));

    setSettingsCount(countSeting);

    return result.data.data;
  });

  const handleCloseSelect = () => {
    setOpenSelect(false);
  };

  const handleOpenSelect = () => {
    setOpenSelect(true);
  };

  useQuery(['categories', search], async (search: any) => {
    if (search.queryKey[1].length > 2) {
      const result: any = await getCategory({ filter: search.queryKey[1] });
      setCategories(result.data.data.subjects || []);
    }

    return [];
  });

  const resExcel = useQuery(['resExcel', { dwbtUid, page, filters, filtersLoaded }], async (req: any) => {
    if (!filtersLoaded) return;

    setLoader(true);
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await wbDataReadExcel(page, perPage, {
      org: uid,
      dwbtUid: req.queryKey[1].dwbtUid,
      subject_name: filters.map((el: any) => el.equal)
    });

    setExcel(result.data);
    setFullList(result.data.data.result);
    setList(result.data.data.result);
    // setFullList([...list, ...result.data.data.result]);
    // setList([...list, ...result.data.data.result]);
    setCount(result.data.data.total);
    setLoader(false);
  }, {
    keepPreviousData: true,
  });

  const infiniteLoadData = async (e: any) => {
    const bottom = Math.ceil(e.target.scrollHeight - e.target.scrollTop) % e.target.clientHeight === 0
      || Math.ceil(e.target.scrollHeight - e.target.scrollTop) % e.target.clientHeight === 1;
    if (bottom) {
      if (page < excel.data.maxPages) {
        if (!resExcel.isLoading) {
          setPage(page + 1);
        }
      }
    }
  }

  const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSettings(true);
  };
  const handleCloseSettings = () => {
    setShowSettings(false);
  };
  const onChangeCity = async (index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyCities = JSON.parse(JSON.stringify(cities));
    let curEl: any = null;

    copyCities[index].showed = !copyCities[index].showed;
    curEl = copyCities[index];
    setCities(copyCities);

    if (curEl) {
      await setColum({
        colum: curEl.regionName,
        organization_uid: uid,
        calc: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
        del: curEl.showed,
      });
    }

    let count = 0;
    copyCities.forEach((item: any) => {
      if (!item.showed) {
        count += 1;
      }
    });

    setSettingsCount(count);
  };

  const [currentItem, setCurrentItem] = React.useState<any>();
  const [currentItemIdx, setCurrentItemIdx] = React.useState<number>(-1);

  const onDragStartEvent = (e: any, item: any, index: number) => {
    setCurrentItem(item);
    setCurrentItemIdx(index);
  };
  const onDragOverEvent = (e: any, item: any, index: number) => {
    e.preventDefault();
    if (e.target.className.includes('settingsItemContent')) {
      e.target.style.borderTop = '10px solid rgba(255, 108, 75, 0.2)';
    }
  };
  const onDragLeaveEvent = (e: any) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDragEndEvent = (e: any, item: any, index: number) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
  };
  const onDropEvent = async (e: any, overedItemIdx: number) => {
    e.preventDefault();
    e.target.style.borderTop = 'none';
    const uid = await cookie.parse(document.cookie)['org_uid']
    let citiesCopy = JSON.parse(JSON.stringify(cities));
    citiesCopy.splice(currentItemIdx, 1);
    citiesCopy.splice(overedItemIdx, 0, currentItem);
    await setColumLocation({
      organization_uid: uid,
      calc: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      location: citiesCopy.map((item: any) => item.regionName)
    });
    setCities(citiesCopy);
  };

  const addNewProducts = async () => {
    setPage(0);
    if (!selectedCategories.length) {
      setSnackbarMessage('Выберите товар');
      setShowSnackbar(true);
      return;
    }
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      await setOgrData({
        org: uid,
        subject: selectedCategories,
      });
      resExcel.refetch();
    } catch (error: any) {
      setSnackbarMessage(error.data.description[0].reason);
      setShowSnackbar(true);
    } finally {
      setShowProductModal(false);
      setSelectedCategories([]);
      setCategories([]);
    }
  };

  const removeChip = (id: string) => {
    let selectedCopy: any = JSON.parse(JSON.stringify(selectedCategories));
    selectedCopy = selectedCopy.filter((item: any) => item.uid === id);
    setSelectedCategories(selectedCopy);
  };

  const handleChangeCategories = (event: any) => {
    const { target: { value } } = event;
    setSelectedCategories(value);
    handleCloseSelect();
  };

  const handleSelectDwbtUid = async (value: string) => {
    if (!resExcel.isLoading) {
      setFullList([]);
      setList([]);
      setPage(0);
      setDwbtUid(value);
      setCount(0);
      setExcel([]);
    }
  };

  const sendFilters = (value: any) => {
    setPage(0);
    setCount(0);
    setExcel([]);
    setFullList([]);
    setList([]);
    setFilters(value);
    // if (resExcel.refetch) resExcel.refetch();
  };

  const cancelSettings = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await clearFilter({
      organization_uid: uid,
      primary_data: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      type: 1
    });
    await clearFilter({
      organization_uid: uid,
      primary_data: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      type: 2
    });

    regions.refetch();
    if (resExcel.refetch) resExcel.refetch();
  };

  const cancelFilter = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await clearFilter({
      organization_uid: uid,
      primary_data: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      type: 3
    });

    setFilters([]);
  };

  const handleSearch = (value: string) => {
    let copyTable = JSON.parse(JSON.stringify(fullList));

    if (value.length > 2) {
      copyTable = copyTable.filter((item: any) => {
        let searched = false;

        for (const key in item) {
          const element = item[key];

          if (!searched)
            if (typeof element === 'string')
              if (element.toLowerCase().indexOf(value.toLowerCase()) !== -1)
                searched = true;
        }

        if (searched) return item;
        return false;
      });
    }
    setList(copyTable);
  };

  return (
    <div className={classes.content}>
      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />
      <LogisticsCostNotificationModal
        show={showNotify}
        closeEvent={() => setShowNotify(false)}
      />
      <div className={classes.contentBlock}>
        <Spinner open={loader} />

        <div className={classes.toolbar}>
          <p className={classes.toolbarCount}>Всего {count}</p>

          <div className={classes.toolbarBtns}>
            {cities.length
              ? <SettingsMenu
                  cols={cities}
                  calc={'2689fead-ddea-4d86-8ead-db8d7c1d9d8b'}
                  // newColumnPosition={props.newColumnPosition}
                  sendColumn={setCities}
                  refetch={resExcel.refetch}
                />
              : null
            }
            <div className={classes.toolbarSearchBtn} onClick={setSearchFocus}>
              <Search />
              <input
                type="text"
                placeholder={!openSearch ? 'Поиск' : 'Введите параметр для поиска...'}
                className={classNames(classes.toolbarSearch, openSearch ? 'focus' : '')}
                // onFocus={() => setOpenSearch(true)}
                ref={searchRef}
                onBlur={() => setOpenSearch(false)}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className={classes.toolbarFilter}>
              <LogisticsCostFilterMenu
                cols={[{ column_name: 'name_', column_caption: 'Товары' }]}
                sendFilters={sendFilters}
                cancelFilter={cancelFilter}
                setFiltersLoaded={setFiltersLoaded}
              />
            </div>
            <span className={classes.toolbarIconBtn} onClick={() => setShowNotify(true)}>
              <Bell />
            </span>
            {/* <label className={classes.toolbarAddBtn} htmlFor="upload"> */}
            <Button className={classes.toolbarAddBtn} onClick={() => setShowProductModal(true)}>
              <Plus /> Добавить товар
            </Button>
            <CustomModal
              show={showProductModal}
              title="Добавление товара"
              closeEvent={() => setShowProductModal(false)}
              successEvent={() => addNewProducts()}
              successText="Добавить"
              cancelText="Отмена"
              disabledSuccessBtn={!selectedCategories.length}
            >
              <div className={classes.productContent}>
                <p className={classes.productLabel}>Название</p>
                {categories && (
                  <Autocomplete
                    multiple
                    noOptionsText="Начните вводить название"
                    options={categories}
                    autoHighlight
                    value={selectedCategories}
                    onChange={(event: any, newValue: any) => {
                      setSearch('');
                      setSelectedCategories(event?.target?.value || newValue);
                    }}
                    inputValue={search}
                    className={classes.autocomplete}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option}
                          {...getTagProps({ index })}
                          key={index}
                          deleteIcon={<span>&times;</span>}
                        />
                      ))
                    }
                    renderOption={(props, category) => (
                      <li {...props}>
                        {props}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.autocompleteInput}
                        variant="outlined"
                        value={search}
                        onChange={(event: any) => {
                          setSearch(event?.target?.value);
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </CustomModal>
          </div>
        </div>

        {/* onScroll={(e) => infiniteLoadData(e)} */}
        {excel && excel.data && cities.length && (
          <div className={classes.tableWrap}>
            <Table className={classNames(classes.table, classes.tableHead)}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '250px', minWidth: '250px' }} rowSpan={2}>Товар</TableCell>
                  <TableCell style={{ width: `calc(7vw * ${cities.length})`, maxWidth: '39%' }} colSpan={cities.length}>
                    <Select
                      className={classes.tableSelect}
                      value={dwbtUid}
                      onChange={(e: any) => handleSelectDwbtUid(e.target.value)}
                    >
                      <MenuItem value={'bbbd04b9-f5de-41e5-8507-502286f27fcc'}>Процент комиссии</MenuItem>
                      <MenuItem value={'90201e14-ec6a-4252-bc86-6253a7d45172'}>Стоимость логистики Моно и Микс, ₽</MenuItem>
                      <MenuItem value={'3221af3c-f9bc-4d2d-ac79-61926b76a4d1'}>Стоимость логистики Монопаллеты, ₽</MenuItem>
                      <MenuItem value={'11735346-9959-4f38-8358-8028556c377f'}>Стоимость хранения Моно и Микс, ₽/день</MenuItem>
                      <MenuItem value={'0b25f800-81c9-42ed-8257-563a595917f0'}>Стоимость хранения Монопаллеты, ₽/день</MenuItem>
                      <MenuItem value={'aba41377-468d-4449-92af-41db0a6fc49b'}>Расчет по фактическим габаритам товара</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {cities && cities.length && cities.map((item: any, index: number) => (
                    item.showed && (
                      <TableCell
                        className={classes.tableCell}
                        style={{ minWidth: `7vw`, maxWidth: `7vw` }}
                        key={index}
                        align="center"
                      >
                        <span>{item.regionName}</span> <br />
                        <span>
                          {item.lastUpdate
                            ? moment(item.lastUpdate).format('DD.MM.YYYY')
                            : null}
                        </span>
                      </TableCell>
                    )
                  ))}
                </TableRow>
              </TableHead>
            </Table>
            <Table className={classes.table}>
              <TableBody>
                {list.map((item: any, index: number) => (
                  <TableRow className={classes.tableRow} key={index}>
                    <TableCell style={{ width: 250, minWidth: 250, maxWidth: 250 }}>
                      {item.subjectName}
                    </TableCell>
                    {cities.length && cities.map((city: any, idx: number) => (
                      city.showed && (
                        <TableCell
                          className={classes.tableCell}
                          style={{ minWidth: `7vw`, maxWidth: `7vw` }}
                          align="right"
                          key={idx}
                        >
                          {item.regionsValues[city.regionName] !== '0'
                            ? !isNaN(Number(item.regionsValues[city.regionName]))
                                ? Number(item.regionsValues[city.regionName])
                                : item.regionsValues[city.regionName]
                            : '-'}
                        </TableCell>
                      )
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        {!resExcel.isLoading && !excel && !excel.data && !cities.length (
          <div className={classes.emptyData}>
            <p className={classes.emptyText}>Список пуст</p>
          </div>
        )}
      </div>
    </div>
  )
}
