import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core';

import launch_Flatline from '../assets/icons/Campaign launch_Flatline 2.png';

const useStyles = makeStyles(() =>
  createStyles({
    notFound: {
      maxWidth: 1460,
      padding: 32,
      margin: '0 auto',
    },
    notFoundContent: {
      maxWidth: 973,
    },
    notFoundTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontSize: 50,
      fontWeight: 600,
      lineHeight: '50px',
      color: '#37352F',
      margin: '40px 0',
    },
    notFoundText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '23px',
      color: '#37352F',

      margin: 0,
    },
    notFoundPicture: {},
  })
)

export const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.notFound}>
      <div className={classes.notFoundPicture}>
        <img src={launch_Flatline} alt="" />
      </div>
      <div className={classes.notFoundContent}>
        <p className={classes.notFoundTitle}>
          К сожалению, мы не смогли найти то, что вы искали.
        </p>
        <p className={classes.notFoundText}>
          Возможно, вы попали на эту страницу по ошибке,
          либо она была перемещена/удалена.
          Пожалуйста <a href="/support">сообщите нам об этом</a> .
        </p>
      </div>
    </div>
  )
}
