import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
  fill?: string;
}

const CofeCircle = (props: Props) => {
  return (
    <svg width={props.width || '40'} height={props.height || '40'} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill={props.fill || '#E9E9E7'} />
      <path d="M26 16H27C28.0609 16 29.0783 16.4214 29.8284 17.1716C30.5786 17.9217 31 18.9391 31 20C31 21.0609 30.5786 22.0783 29.8284 22.8284C29.0783 23.5786 28.0609 24 27 24H26" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 16H26V25C26 26.0609 25.5786 27.0783 24.8284 27.8284C24.0783 28.5786 23.0609 29 22 29H14C12.9391 29 11.9217 28.5786 11.1716 27.8284C10.4214 27.0783 10 26.0609 10 25V16Z" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 9V12" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 9V12" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22 9V12" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CofeCircle;
