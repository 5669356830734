import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// http://176,99,5,212
// http://37,230,117,54
// const excelBaseURL = 'http://85.175.216.246:8087';
// const baseURL = 'http://85.175.216.246:8090';

interface FilterType {
  column: string;
  value: Array<string>
};

interface CalcStocksReq {
  org: string;
  weeks_count: number;
  calculate_type: number;
  stocks_type: number
  colum: Array<string>;
  filter: Array<FilterType>;
  type?: number;
  save?: number;
  search?: any;
};
interface CalcSaleReq {
  search?: any
  org: string;
  rubles?: number;
  rubles_procent?: number;
  max_sale: number | undefined;
  max_sale_value: number | undefined;
  colum: Array<string>;
  filter: Array<FilterType>;
  type?: number;
}
interface GetCalcReq {
  org: string;
  type?: any;
  column?: string;
  val?: number;
};
interface GetFilterSaleReq {
  org: string;
  rubles: number;
  max_sale: number;
  max_sale_value: number;
  colum: string;
};
interface GetFilterStocksReq {
  org: string;
  weeks_count: number;
  calculate_type: number;
  stocks_type: number;
  colum: string;
};
interface GetExcelReq {
  org: string;
  plan: string;
  type: number;
  brand?: Array<string>;
  category?: Array<string>;
  supplier_article?: Array<string>;
};

interface GetListPromotionReq {
  org: string;
  plan: string;
  info: boolean
}
interface GetExcelListOrder {
  org: string;
  plan: string;
  type?: number;
  info?: boolean;
}
interface SetCalcOrgReq {
  org?: string;
  weeks_count?: number;
  calculate_type?: number;
  stocks_type?: number;
  rubles_procent?: number;
  max_sale?: number;
  max_sale_value?: number | string | null | undefined;
  max_sale_value_1?: number | string | null | undefined;
  min_sale?: number;
  min_stock?: number;
  save?: boolean;
  demand?: number;
  percent_more?: number | string | null | undefined;
  type_descending?: number;
  value_descending?: number;
  check_min_value?: number;
  check_avg_value?: number;
  check_max_value?: number;
  percent_fork_2250?: number;
};
interface SetCalcReq {
  org: string;
  column?: string;
  val?: string | number;
  type?: number | null;
  articl?: Array<string>;
  number_change_points?: string;
  number_change_percent?: string;
  volume_max?: number;
};
interface GetCalcOrgReq {
  org: string;
  report_type: string;
};

interface GetAllReq {
  org: string;
  xls: string;
};
interface DownloadListProductsReq {
  org: string;
  plan?: string;
  type?: number;
  brand?: any[];
  category?: any[];
  supplier_article?: any[];
};
interface SetOgrDataReq {
  org: string;
  subject: Array<string>;
  category?: boolean;
};
interface SetCalcNewReq {
  org: string;
  number_change_points: string;
  number_change_percent: string;
  articl: Array<string>;
  volume_max?: number;
};
interface GetCalcHistoryReq {
  org: string;
};

export const calcStocks = async (req: CalcStocksReq, page: any = 0 ,def: any = 500) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/calc_stocks?page=${page}&perPage=${def}`, req);
};
export const calcSale = async (req: CalcSaleReq, page: any = 0 ,def: any = 500) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/calc_sale?page=${page}&perPage=${def}`, req);
};
export const getCalc = async (req: GetCalcReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/getCalc`, req);
};
export const getCalcOrg = async (req: GetCalcOrgReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/getCalcOrg`, req);
};
// export const getCalcOrg = async (req: GetCalcOrgReq) => {
//   return await API.post(`${urlAdres.data.calc}/calc/getCalcOrg`, req);
// };
export const getFilterSale = async (req: GetFilterSaleReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/getFilterSale`, req);
};
export const getFilterStocks = async (req: GetFilterStocksReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/getFilterStocks`, req);
};
export const setCalc = async (req: SetCalcReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/setCalc`, req);
};
export const setCalcOrg = async (req: SetCalcOrgReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/setCalcOrg`, req);
};
export const excelDownloadAllGoods = async (req: GetExcelReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/all_goods`, req);
}
export const excelDownloadNew = async (req: GetCalcOrgReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/report_desc_price`, req);
}

export const excelDownloadRegistry = async (req: GetCalcOrgReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/no_max_value_register`, req);
}

export const excelDownloadAll = async (req: GetAllReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/bck_double`, req);
}

export const registerDuplicatesBKT = async (org: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/bck_double`, {org});
}

export const downloadDuplicatesBKT = async (org: string, uids: Array<string>) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/set_bck_double`, {org, uids});
}

export const excelDownloadRegistryMin = async (req: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/no_min_value_register`, req);
}

export const excelDownloadListPromotion = async (req: GetListPromotionReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/list_promotion`, req);
}
export const excelDownloadListOrder = async (req: GetExcelListOrder) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/list_orders`, req);
}
export const downloadListProducts = async (req: DownloadListProductsReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.excelDownloader}/excel/primary/download/list_products`, req);
}
export const getCategory = async ({filter, perPage = 100, page = 0}: {filter: string, perPage?: number, page?: number}) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/getCategory?perPage=${perPage}&page=${page}`, { filter: filter });
}
export const setOgrData = async (req: SetOgrDataReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/setOgrData`, req);
}
export const setCalcNumber = async (req: SetCalcNewReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/setCalcNumber`, req);
}
export const getCalcHistory = async (id: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.calc}/calc/getCalcHistory`, { org: id });
}
