import { makeStyles, createStyles, Button, Tooltip } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useState } from 'react'
import cookie from 'cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { refreshTariffs } from '../../api/tariffs';
import Empty from '../../components/layout/empty'
import CurrencyFormatter from '../../components/UI/CurrencyFormatter';
import Spinner from '../../components/UI/Spinner';

const useStyles: any = makeStyles(() =>
  createStyles({
    content: {
      maxWidth: 660,
      width: '100%',
      margin: '0 auto',
      height: '50vh',
    },
    tariffPayHeader: {
      padding: 32,
      paddingBottom: 0,
    },
    back: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: 14,
      '&:hover': {
        color: '#827f75',
      },
      '& svg': {
        stroke: '#37352F',
        marginRight: 13,
      },
      // '&:hover svg': {
      //   stroke: '#827f75',
      // },
    },
    logo: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '38px',
      color: '#37352F',
    },
    tariffCard: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 32,
      border: '1px solid #E9E9E7',
      borderRadius: 8,
      marginBottom: 16,
    },
    tariffText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    tariffName: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '38px',
      color: '#37352F',
      margin: 0,
    },
    tariffPeriod: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    tariffPrice: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '38px',
      color: '#37352F',
      margin: 0,
    },
    rub: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 32,
      lineHeight: '38px',
      color: '#827F75',
    },
    sendInfo: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 16,
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& span': {
        color: '#FF6C4B',
      },
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: 300,
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      marginBottom: 40,
      overflow: 'hidden',
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '14px 16px',
      width: 300,
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,
    },
    tariffModalPayBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#FFFFFF',
      height: 40,
      background: '#37352F',
      borderRadius: 8,
      marginBottom: 8,
      textAlign: 'center',
      textDecoration: 'none',
      width: 216,

      '&:hover': {
        background: '#827F75 !important',
      },
      '&:disabled': {
        color: '#fff',
        background: '#D3D1CB',
        borderRadius: 8,
      },
    },
    tariffModalText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '15px',
      display: 'flex',
      alignItems: 'center',
      color: '#827F75',
      margin: 0,
      marginBottom: 8,
      width: 216,
    },
    again: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      borderBottom: '1px solid #37352F',
      cursor: 'pointer',
    },
    sendedInfoBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    sendedInfo: {},
    toProfileBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 32,
      background: '#E9E9E7',
      cursor: 'pointer',
    },
    '@global': {
      '.MuiTooltip-tooltip': {
        minWidth: 358,
        background: '#37352F',
        borderRadius: 8,

        '& ul': {
          paddingLeft: '16px !important',
        },
        '& li': {
          fontFamily: 'Work Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '16px',
          color: '#FFFFFF',
          marginBottom: 16,
        },
        '& li:last-child': {
          marginBottom: 0,
        }
      }
    }
  })
);

export default function TariffPay() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(false);
  const [sended, setSended] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const name = searchParams.get("name");
  const month = searchParams.get("month");
  const start = searchParams.get("start");
  const end = searchParams.get("end");
  const cost = Number(searchParams.get("cost"));
  const end_date_text = searchParams.get("end_date_text");
  const countTariffs = searchParams.get("count");

  const validateEmail = (mail: string) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(!mail || regex.test(mail) === false){
        // this.setState({
        //     error: "Email is not valid"
        // });
        setEmail(mail);
        setValid(false);
        return false;
    }

    setEmail(mail);
    setValid(true);
  };

  const send = async () => {
    setLoader(true);
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const result = await refreshTariffs({
        planUid: id,
        dateStart: start,
        dateEnd: end,
        datePayment: start,
        planIsConfirmed: true,
        organisationUid: uid,
        paymentAmount: Number(countTariffs),
        email: email
      });
      if (result) {
        setSended(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const toProfile = () => {
    navigate('/profile?check=1');
  };

  const moveBack = () => {
    navigate('/profile', { replace: true });
  };

  return (
    <Empty>
      <Spinner open={loader} />

      <div className={classes.tariffPayHeader}>
        <div className={classes.back} onClick={() => moveBack()}>
          <svg width="6" height="12" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 13L1 7L7 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>

          <p>Назад</p>
        </div>
      </div>

      <div className={classes.content}>
        <h2 className={classes.logo}>PriceMarket</h2>

        <div className={classes.tariffCard}>
          <div className={classes.tariffInfo}>
            <p className={classes.tariffText}>Тариф</p>
            <p className={classes.tariffName}>{name}</p>
          </div>
          <div className={classes.tariffPriceBlock}>
            <p className={classes.tariffPeriod}>Продлить до {end_date_text}</p>
            <p className={classes.tariffPrice}>
              <CurrencyFormatter currency={cost} /> <span className={classes.rub}>₽</span>
            </p>
          </div>
        </div>

        {!sended ? (
          <div>
            <p className={classes.sendInfo}>Ссылка на оплату будет отправлена на вашу почту</p>

            <div className={classes.fieldBlock}>
              <label htmlFor="email" className={classes.fieldLabel}>E-mail <span>*</span></label>
              <div className={classes.iconField}>
                <input
                  id="email"
                  className={classes.field}
                  type="email"
                  value={email}
                  onChange={(e) => validateEmail(e.target.value)}
                />
                <Check className={classNames(classes.checkIcon, valid ? classes.checkIconActive : '')} />
              </div>
            </div>
            <Button
              className={classes.tariffModalPayBtn}
              onClick={send}
              disabled={!valid}
            >
              Оплатить
            </Button>
            <p className={classes.tariffModalText}>После оплаты вы получите доступ к функционалу сервиса, в рамках выбранного тарифа</p>
          </div>
        ) : (
          <div className={classes.sendedInfoBlock}>
            <div className={classes.sendedInfo}>
              <p className={classes.sendInfo}>Ссылка на оплату будет отправлена на вашу почту</p>
              <Tooltip
                title={
                  <React.Fragment>
                    <ul>
                      <li>Проверьте правильно ли введен
                        <br />адрес электронной почты.</li>
                      <li>Проверьте папку "спам".</li>
                    </ul>
                  </React.Fragment>
                }
                placement="bottom"
              >
                {/* onClick={send} */}
                <span className={classes.again}>
                  Не приходит ссылка
                </span>
              </Tooltip>
            </div>

            {/* <div className={classes.toProfileBtn} onClick={toProfile}>
              Вернуться в профиль
            </div> */}
          </div>
        )}
      </div>
    </Empty>
  )
}
