import { makeStyles, createStyles, Popover, Typography, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { Close } from '@material-ui/icons';

import { InfoIcon } from '../../components/UI/Icons';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    TipsModal: {
      // display: 'none',
      position: 'fixed',
      width: 329,
      height: '100vh',
      background: '#fff',
      top: 0, right: 0,
      transform: 'translateX(100%)',
      transition: 'all .2s ease-out',
      padding: 16,
      paddingRight: 12,
      boxSizing: 'border-box',
      borderLeft: '1px solid #D3D1CB',
      zIndex: 1299,
    },
    show: {
      // display: 'block',
      transform: 'translateX(0)',
    },
    TipsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 32,
    },
    TipsTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
    },
    TipsClose: {
      fontSize: 24,
      cursor: 'pointer',
    },
    TipsContent: {},
    TipsMiniTitle: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginBottom: 8,

      '& svg': {
        marginRight: 4,
      },
    },
    TipsList: {
      padding: 0,
      listStyle: 'none',
    },
    TipsListItem: {
      maxWidth: 280,
      paddingBottom: 12,
      borderBottom: '1px solid #E9E9E7',
    },
    TipsName: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 4,

      '& span': {
        color: '#827F75',
      },
    },
    TipsInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,

      '& span': {
        color: '#827F75',
      },
    },
    TipsPopover: {},
  }),
);

interface Props {
  show: boolean;
  list: any;
  closeEvent: () => void;
};

const TipsModal = (props: Props) => {
  const classes = useStyles();

  return (
    <div onMouseLeave={() => props.closeEvent()} className={classNames(classes.TipsModal, props.show ? classes.show : '')}>
      <div className={classes.TipsHeader}>
        <h1 className={classes.TipsTitle}>Советы</h1>
        <span className={classes.TipsClose} onClick={() => props.closeEvent()}>
          <Close />
        </span>
      </div>
      <div className={classes.TipsContent}>
        <h5 className={classes.TipsMiniTitle}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L8 10L12 6" stroke="#37352F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Список шагов
        </h5>

        <ul className={classes.TipsList}>
          {props?.list?.map((item: any, index: number) => (
            <li className={classes.TipsListItem} key={index}>
              
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default TipsModal