import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
  fill?: string;
}

const Card = (props: Props) => {
  return (
    <svg width={props.width || '40'} height={props.height || '40'} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill={props.fill || '#E9E9E7'} />
      <path d="M29 12H11C9.89543 12 9 12.8954 9 14V26C9 27.1046 9.89543 28 11 28H29C30.1046 28 31 27.1046 31 26V14C31 12.8954 30.1046 12 29 12Z" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || '2'} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 18H31" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || '1'} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Card;
