import React, { useRef, useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ReactInputMask from 'react-input-mask'
import { Button, CircularProgress, createStyles, Hidden, makeStyles, NativeSelect, FormControl, InputBase, withStyles} from '@material-ui/core';

import MainLogo from '../assets/icons/mainImage.svg';
import useAuth from '../context/auth';
import { answer, login } from '../api/auth';
import Empty from '../components/layout/empty';
import CustomSnackbar from '../components/UI/CustomSnackbar';
import PhoneInput ,  { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import ru from 'react-phone-number-input/locale/ru'
// import 'react-phone-number-input/style.css'


const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    auth: {
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
      marginLeft: '13%',
    },
    title: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '38px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      marginBottom: 8,
    },
    noAccount: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      marginBottom: 48,
    },
    registrLink: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: 'red',
      marginLeft: 4,
    },
    submit1: {
      background: '#177367',
      width: 400,
      height: 40,
      borderRadius: 8,
      outline: 'none',
      border: 'none',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#fff',
      textTransform: 'capitalize',

      '&:hover': {
        background: '#177367',
        cursor: 'pointer',
      },
      '&[disabled]': {
        color: '#FFFFFF',
        background: '#D3D1CB',
      },
    },
    phoneLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
    },
    phoneInput: {
      width: 400,
      height: 44,
      border: '1px solid #D3D1CB',
      outline: 'none !important',
      boxSizing: 'border-box',
      borderRadius: 8,
      padding: '14px 16px',
      marginTop: 8,
      marginBottom: 56,
    },
    submit: {
      width: 400,
      height: 40,
      background: '#37352F',
      borderRadius: 8,
      outline: 'none',
      border: 'none',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#fff',
      textTransform: 'capitalize',

      '&:hover': {
        background: '#827F75',
        cursor: 'pointer',
      },
      '&[disabled]': {
        color: '#FFFFFF',
        background: '#D3D1CB',
      },
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
    },
    logoImage: {
      maxWidth: 592,
      maxHeight: 500,
      height: '100%',
    }
  })
);


const LoginForm: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [phone, setPhone] = React.useState('');
  const [copyPhone, setCopyPhone] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>();
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [disableBtn, setDisableBtn] = React.useState(false);
  const [value, setValue] = useState<any>()

  const con = ['RU', 'UZ', 'TR', 'KZ', 'KG', 'BY', 'TJ', 'TM']


  const {
    state: { user },
    dispatch,
  } = useAuth();

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const tel = value.replace(/\D+/g, '');
      const result: any = await login(tel);
      // const answerApp: any = await answer(tel, true)
      const user = { phone: tel };


      if (result.data.success) {
        setLoading(true)
        dispatch({ type: 'LOAD_USER', user });
        document.cookie = `n = ${value}`;
        navigate("/verification", { replace: true });
        // setTimeout(() => {
        //   answer(tel, true)
        //   .then(res => {
        //     if(res.data.description[0].message === null) {
        //       document.cookie = `n = ${phone}`;
        //       setLoading(false);
        //       navigate("/verification", { replace: true });
        //     }
        //     else {
        //       setPhone('')
        //       setError(res.data.description[0].message)
        //       setShowSnackbar(true)
        //       setLoading(false);
        //     }
        //   } )
        // }
        //   , 3000);
      } else {
        setError(result.data.description[0].message);
        setShowSnackbar(true);
        setLoading(false);
      }

      document.cookie = `org_uid=;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      // setLoading(false);
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      if (error.status === 422) {
        setError(error.data.errors);
        setShowSnackbar(true)
      }
    }
    
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };


  const onChange = (v: string) => {
    setPhone(v);
  };


  const mask = ["+7 (999) 999-99-99", "+\\9\\96 (999) 99-99-99", "+375 (99) 999-99-99", "+7 (9999) 999-99-99", "+\\9\\98 (99) 999-99-99", "+\\90 (999) 999-99-99", "+\\9\\93 (999) 99-99-99", "+\\9\\92 (99) 999-99-99"]

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  

  // const [country, setСountry] = React.useState<any>(0);

  // const handleChange = (event) => {
  //   setDisableBtn(true)
  //   setPhone('')
  //   setСountry(event.target.value);
  // };
  

  // React.useEffect(() => {
  //   const tel = value.replace(/\D+/g, '');
  //   setDisableBtn(tel.length < 11);
  // }, [phone, country]);
  



  return (
    <Empty>
      <div className={classes.root}>
        <CustomSnackbar
          open={showSnackbar}
          message={error || ''}
          handleClose={() => setShowSnackbar(false)}
        />
        <div className={classes.auth}>
          <div className="logForm">
            <div>
              <h1 className={classes.title}>Вход в PriceMarket</h1>
              <div className={classes.noAccount}>
                <span>Нужна учетная запись?</span>
                <NavLink className={classes.registrLink} to="/registration">
                  Зарегистрироваться
                </NavLink>
              </div>
            </div>
            <div style={{width: 500, marginBottom: 20}}>
                Для пользования системой PriceMarket необходимо, чтобы номер, вбиваемый в поле для авторизации, был зарегистрирован в мессенджере <span style={{color: 'red'}}>WhatsApp</span>, поскольку код авторизации будет приходить в данное приложение.
            </div>
            <div>
            <PhoneInput
            labels={ru}
            countries={con}
            international
            defaultCountry="RU"
            value={value}
            onChange={setValue}
            onKeyPress={_handleKeyDown}/>
            </div>
            <div>
            {/* Is possible: {value && isPossiblePhoneNumber(value) ? 'true' : 'false'} */}
            </div>
            {/* <div>
              <div>
                  <label className={classes.phoneLabel} htmlFor="phone">Страна</label>
              </div>
              <FormControl style={{marginTop: 7}}>
                <NativeSelect
                  style={{width: 400, marginBottom: 10}}
                  id="demo-customized-select-native"
                  value={country}
                  input={<BootstrapInput />}
                  
                  onChange={handleChange}
                >
                  <option value={0}>Россия</option>
                  <option value={1}>Кыргазстан</option>
                  <option value={2}>Белоруссия</option>
                  <option value={3}>Казахстан</option>
                  <option value={4}>Узбекистан</option>
                  <option value={5}>Турция</option>
                  <option value={6}>Туркменистан</option>
                  <option value={7}>Таджикистан</option>
                </NativeSelect>
              </FormControl>
            </div> */}
            {/* <div className="inputPhone">
              <div>
                <label className={classes.phoneLabel} htmlFor="phone">Телефон</label>
              </div>
              <div>
              <ReactInputMask    
                id="phone"
                className={classes.phoneInput}
                mask={mask[country]}
                value={phone}
                // placeholder="+7 (921) 234-45-67"
                onChange={(e: any) => onChange(e.target.value)}
                onKeyDown={_handleKeyDown}
                // autoFocus
              />
              </div> */}
              
              {/* <input
                placeholder="+7 (921) 234-45-67"
                className={classes.phoneInput}
                type="text"
                pattern="/+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2}/"
                value={phone}
                onChange={e => onChange && onChange(e.target.value)}
              /> */}
            {/* </div> */}
            {/* <input
              type="button"
              value="Войти"
              className={classes.submit}
              onClick={handleSubmit}
            /> */}
            <Button
              className={classes.submit}
              onClick={handleSubmit}
              disabled={value && isValidPhoneNumber(value) ? false : true}
            >
            
              {!loading ? 'Войти' : <CircularProgress
                style={{width: 16, height: 16}}
                size="small"
                color="inherit"
              />}
            </Button>
            <div style={{marginTop: 20}}>
            <Button
              className={classes.submit}
              onClick={() => openInNewTab('http://wa.me/79648965197')}
            >
                  <span style={{color: 'white', textDecoration: 'none',textTransform: 'none'}}>Написать в поддержку</span>
            </Button>
            </div>
            <div style={{marginTop: 20}}>
                <Button
                  className={classes.submit1}
                  onClick={() => openInNewTab('https://xn--90ab.xn----7sbburimujfhs.xn--p1ai/')}
                >
                      <span style={{color: 'white', textDecoration: 'none',textTransform: 'none'}}>Возможности сервиса</span>
                </Button>
            </div>
            <div style={{marginTop: 10}}>
                <Button
                  className={classes.submit1}
                  onClick={() => openInNewTab('https://youtube.com/playlist?list=PLGXKaOYQxj9GCzMBZY7__tFBbUkWrNlbo')}
                >
                      <span style={{color: 'white', textDecoration: 'none',textTransform: 'none'}}>Отзывы наших клиентов</span>
                </Button>
            </div>
            <div style={{marginTop: 10}}>
                <Button
                  className={classes.submit1}
                  onClick={() => openInNewTab('https://www.youtube.com/playlist?list=PLGXKaOYQxj9FYs1ooLeyzO3c-4ItmkhUB')}
                >
                      <span style={{color: 'white', textDecoration: 'none',textTransform: 'none'}}>Как это работает?</span>
                </Button>
            </div>
          </div>
        </div>

        <Hidden smDown>
          <div className={classes.logo}>
            <img className={classes.logoImage} src={MainLogo} alt="Main Logo" />
          </div>
        </Hidden>
      </div>
    </Empty>
  );
};
export default LoginForm;
