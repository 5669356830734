import React from 'react'
import {
  makeStyles,
  createStyles,
  Checkbox,
  Paper,
  Table,
  TableBody,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input
} from '@material-ui/core'
import { useQuery } from 'react-query'

import { Plus } from '../../components/UI/Icons'
import Main from '../../components/layout/Main'
import { TrashIcon } from '../../components/UI/Icons'
import { getOrganizationsUsers } from '../../api/organizations'
import cookie from 'cookie'
import { setUserOrg } from '../../api/registration'
import { ArrowForwardIos, ContactSupportOutlined } from '@material-ui/icons'
import ReactInputMask from 'react-input-mask'
import CustomSnackbar from '../../components/UI/CustomSnackbar'
import CustomModal from '../../components/UI/CustomModal'
import Spinner from '../../components/UI/Spinner'
import classNames from 'classnames'
import {getUsers} from "../../api/auth";

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '.MuiPopover-paper': {
        boxShadow: 'none',
        border: '1px solid #37352F',
        borderRadius: '0px 0px 8px 8px',
      },
    },
    content: {
      padding: 32,
    },
    tableContainer: {
      boxShadow: 'none',
      border: 'none',
      // borderBottom: '1px solid #E9E9E7',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderTop: 'none',
      marginBottom: 16,
      tableLayout: 'fixed',

      '& .MuiTableCell-head': {
        padding: '8px 16px',
        borderRight: '1px solid #E9E9E7',
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: 'none',
        width: '16px !important',
      },

      '& .MuiTableCell-body': {
        padding: '2px !important',
        borderRight: '1px solid #E9E9E7',
      },
      '& .MuiTableCell-body:last-child': {
        padding: '0 8px !important',
        borderRight: 'none',
      },
    },
    newUser: {
      background: 'rgba(23, 115, 103, 0.05)',
      animation: '$addedUser 3000ms infinite alternate',
    },
    "@keyframes addedUser": {
      "0%": {
        background: 'rgba(23, 115, 103, 0.06)',
      },
      "100%": {
        background: 'rgba(23, 115, 103, 0.03)',
      },
    },
    removeBtn: {
      stroke: '#FF6C4B',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    shakingParent: {
      borderRadius: '8px',
      border: "none",
      width: '100px',
      padding: 5,
      fontSize: 12
    },
    shaking: {
      animation: '$horizontal-shaking 1200ms normal ',
    },
    "@keyframes horizontal-shaking": {
      "0%": { transform: 'translateX(0)' },
      '25%': { transform: 'translateX(5px)' },
      '50%': { transform: 'translateX(-5px)' },
      '75%': { transform: 'translateX(5px)' },
      '100%': { transform: 'translateX(0)' },
    },
    addBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      stroke: '#37352F',
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all .3s ease-out',

      '& svg': {
        marginRight: 4,
      },

      '&:hover': {
        color: '#827F75',
        stroke: '#827F75',
      }
    },
    addRow: {
      '& .MuiTableCell-body': {
        padding: '1px !important',
        userSelect: 'none',
        // paddingLeft: '1px !important',
      },
      '& .MuiTableCell-body:nth-child(3)': {
        // padding: '0 16px !important',
      },
    },
    cellInput: {
      width: '100%',
      // padding: '0 !important',
      boxSizing: 'border-box',
      border: 'none !important',
      outline: 'none',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '10px 16px !important',
      background: 'transparent',

      '&:focus': {
        outline: '1px solid #37352F',
      },
    },
    selectAdmin: {
      border: 'none !important',

      '&:before': {
        border: 'none !important',
      },
      '&:hover:not(.Mui-disabled):before': {
        border: 'none !important',
      },
      '&:after': {
        border: 'none !important',
      },
      '& .MuiInput-input': {
        background: 'transparent',
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#37352F',
      },
      '& .MuiSelect-select': {
        paddingLeft: 16,
      },
    },
    userData: {
      display: 'inline-block',
      width: '100%',
      height: '100%',
      // padding: '10px 16px !important',
    },
    error: {
      background: 'rgba(255, 108, 75, 0.08)',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  })
);


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null
};
export const ProfileUsers = () => {
  const classes = useStyles();
  const [addUser, setAddUser] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [showEditName, setShowEditName] = React.useState(-1);
  const [showEditPhone, setShowEditPhone] = React.useState(-1);
  const [showEditEmail, setShowEditEmail] = React.useState(-1);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(true);
  const [phone, setPhone] = React.useState('');
  const [role, setRole] = React.useState('73ac1ea1-ee77-454e-b5f0-6c9c0dca239a');
  const [roleErrorState, setRoleErrorState] = React.useState(false);
  const [nameErrorState, setNameErrorState] = React.useState(false)
  const [phoneErrorState, setPhoneErrorState] = React.useState(false);
  const [emailErrorState, setEmailErrorState] = React.useState(false);

  const [lastEditUser, setLastEditUser] = React.useState({
    idx: -1,
    name: null,
    phone: null,
    email: null,
  });
  const [removeUserData, setRemoveUserData] = React.useState<any>();

  const [usersList, setUsersList] = React.useState([]);
  const [addedUserUid, setAddedUserUid] = React.useState<string>('');

  const openAddNewUser = () => {
    setAddUser(true);
  };
  React.useEffect(() => {
    // setName('');
    // setPhone('');
    // setEmail('');
    setRole('73ac1ea1-ee77-454e-b5f0-6c9c0dca239a');
  }, [addUser]);

  const orgUsers = useQuery('orgUsers', async () => {
    setLoading(true);
    const uid = await cookie.parse(document.cookie).org_uid;
    const result = await getOrganizationsUsers(uid);
    setUsersList(result.data.users_org);
    setLoading(false);
    return result.data.users_org;
  });

  const selectList = [
    { id: '73ac1ea1-ee77-454e-b5f0-6c9c0dca239a', name: 'Пользователь' },
    { id: '526043cb-a5c3-4de6-a2a1-2a2c55555460', name: 'Администратор' },
    { id: 'c6b0450d-8349-4ded-be3d-07b41a6b5e8e', name: 'Пользователь акций' },
  ];

  const hideAddUser = () => {
    setName('');
    setPhone('');
    setEmail('');
    setRole('');
    setNewPhone('')
    setAddUser(false);
  };
  const [editMode, setEditMode] = React.useState({active: false, id: undefined})
  const saveUser = async () => {
    try {

      if(validateEmail(email) === undefined || validateEmail(email)) {
        setName(name);
        setPhone(phone);
        setEmail(email);
        setRole(role);
        // if (name?.length > 0 || phone?.length > 0 ) {

          // if (!validateEmail(email)) {
          //
          //   // return
          // }

          const copyUsersList = JSON.parse(JSON.stringify(usersList));
          const uid = await cookie.parse(document.cookie)['org_uid'];

          const result: any = await setUserOrg({
            name: name,
            phone: phone.replace(/\D+/g, '') || '',
            email: email,
            role: role,
            org: uid,
            del: false
          });

          if (result.data.success) {
            copyUsersList.push({
              organization_uid: uid,
              us: false,
              user_email: email,
              user_middlename: null,
              user_mobile: phone.replace(/\D+/g, ''),
              user_name: name,
              user_role_uid: role,
              user_surname: null,
              user_uid: result.data.data,
            });
            hideAddUser();
            if(addUser) {

            }
            setUsersList(copyUsersList);
            setAddedUserUid(result.data.data);
            setTimeout(() => {
              setAddedUserUid('');
            }, 3000);
          }
          if (!result.description.message.includes('Данный номер уже зарегистрирован в системе')) {
            hideAddUser();
            setPhoneErrorState(false)
            setEmailErrorState(false)
            setRoleErrorState(false)
          }

          if (!result.data.success) {
            setSnackbarMessage(result.data.description.message);
            setShowSnackbar(true);
            return;
          }
        // }
        clearFields()
        setEditMode({active: false, id: undefined})
        setShowSnackbar(false)
        setSnackbarMessage('')
      }
    } catch (error: any) {
      setSnackbarMessage(error.data.description[0].reason);
      if(error.data.description[0].reason.includes('Телефон не заполнен либо заполнен в формате отличного от 7**********')) {
        setPhoneErrorState(true);
      }
      setShowSnackbar(true);
    }
  };
  const removeUser = async (item: any) => {
    try {
      const result = await setUserOrg({
        uid: item.user_uid,
        name: '-',
        phone: item.user_mobile.replace(/\D+/g, ''),
        email: item.user_email,
        role: item.user_role_uid,
        org: item.organization_uid,
        del: true
      });

      if (result) orgUsers.refetch();

      if (!result.data.success) {
        setSnackbarMessage(result.data.description.message);
        setShowSnackbar(true);
      }
    } catch (error: any) {
      setSnackbarMessage(error.data.description[0].reason);
      setShowSnackbar(true);
    } finally {
      setRemoveUserData(null);
    }
  };
  const updateUserRole = async (item: any, role: string) => {
    try {
      const result = await setUserOrg({
        uid: item.user_uid,
        name: item.user_name,
        phone: item.user_mobile.replace(/\D+/g, ''),
        email: item.user_email,
        role: role,
        org: item.organization_uid,
        del: false
      });

      if (result) orgUsers.refetch();

      if (!result.data.success) {
        setSnackbarMessage(result.data.description.message);
        setShowSnackbar(true);
      }
    } catch (error: any) {
      setSnackbarMessage(error.data.description[0].reason);
      setShowSnackbar(true);
    } finally {
      setAddUser(false);
    }
  };

  const [currentItem, setCurrentItem] = React.useState<unknown>()

  const updateUserData = async (item: any) => {
    try {
      if(validateEmail(email)) {
        if (!phone?.length) return;

        const result = await setUserOrg({
          uid: currentItem?.user_uid,
          name: name || '',
          phone: phone.replace(/\D+/g, '') || '',
          email: email || '',
          role: currentItem?.user_role_uid || '',
          org: currentItem?.organization_uid,
          del: false
        });

        if (result) orgUsers.refetch();

        if (!result.data.success) {
          setSnackbarMessage(result.data.description.message)
          setShowSnackbar(true);
        }
        clearFields();
        setEditMode({active: false, id: undefined})
        setShowSnackbar(false)
        setSnackbarMessage('')
      }
    } catch (error: any) {
      setSnackbarMessage(error.data.description[0].reason);
      setShowSnackbar(true);
    }
  };
  const [currentCellId, setCurrentCellId] = React.useState<number | undefined>(undefined)
  const [newPhone, setNewPhone] = React.useState('')

  const editUserCell = (item: any, i: number, field: string) => {
    setCurrentItem(item)
    if(editMode.id === undefined) {
      setEditMode({active: editMode.active, id: i})
    }
    console.log(editMode, i)
    if(editMode.id !== i) {
      editModeNotification(i)
    }
    // setAddUser(true);
    if(editMode.id === i) {
      if(currentCellId !== i) {
        setCurrentCellId(i)
        setName(item?.user_name)
        setEmail(item.user_email)
      }
      console.log('я отработал', i, email, name, '44', item?.user_name)
      if(!addUser) {
        if (field === 'name') setShowEditName(i);
        if (field === 'phone') setShowEditPhone(i);
        if (field === 'email') setShowEditEmail(i);
        if(usersList.length + 1 === i) {
          setPhone(newPhone)
        }
        // if(field === 'email') {
        // let isValidate = validateEmail(item.user_email)

        // }

        // setName(currentCellId === i && item?.user_name !== name ? item?.user_name : name);
        // if(name.length === 0) {
        //   setName(item?.user_name)
        // }
        // item?.user_name.indexOf(name) === -1 ? setName(name) : setName(item?.user_name)
        console.log(currentCellId, i, item?.user_name === name)
        if(usersList?.length + 1 !== i) {
          setPhone( item?.user_mobile || newPhone );
        }
        // email.length !== 0 ? setEmail(email) : setEmail(item.user_email)
        // setEmail(item && item?.user_email?.length === 0 ? email : item?.user_email);
      }
    }
    // setAddUser(false);
  };
  function editModeNotification(i) {
    if(editMode.id !== undefined && editMode.id !== i) {
      setSnackbarMessage('Примените или сбросьте изменения перед выбором другого пользователя.')
      setShowSnackbar(true)
      setEditMode({active: true, id: editMode.id})
    }
  }
  function changePhoneNumberHandler(event) {
    setNewPhone(event)
    setPhone(event)
  }

  const validateEmail = (mail: string) => {

    if (!name?.length && !phone?.length) return;

    // const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

    if(email.length === 0) {
      setEmailErrorState(false)
      setShowSnackbar(false);
      return true;
    }

      if(regex.test(mail) === false){
        setSnackbarMessage('Неверный формат E-mail');
        setEmailErrorState(true)
        setShowSnackbar(true);
        // clearFields();
        return false;
      }
      setEmailErrorState(false)
      setShowSnackbar(false);
      return true;

  };

  const clearFields = () => {
    setShowEditName(-1);
    setShowEditPhone(-1);
    setShowEditEmail(-1);
    setName('');
    setPhone('');
    setEmail('');
    setAddUser(false)
    setNewPhone('')
    setEditMode({active: false, id: undefined})
  };

  const confirmDelete = (item: any) => {
    setRemoveUserData(item);
  };

  const updateEmail = (item: any) => {

    if (validateEmail(email)) {
      // setEmail(email);
      // console.log('1111', item, email,)
      // updateUserData(item);
    }
  };

  function updateUserModeHandler() {

    if(addUser) {
      saveUser()
    } else {
      updateUserData(currentItem)
    }

  }

  function nameChangeHandler(event: any) {
    // console.log(event, event.target.value)
    if(event.target.value.length === 0) {
      setNameErrorState(true)
      setSnackbarMessage('Поле является обязательным и не должно быть пустым')
      setShowSnackbar(true)
      // return
    } else {
      setNameErrorState(false)
      setShowSnackbar(false)
      setSnackbarMessage('')
    }
    setName(event.target.value)
    // setNameErrorState()
  }

  function emailChangeHandler(event: any) {
    setEmail(event)
    // validateEmail(event)
  }

  const regex =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return (
    <>
      <Spinner open={loading} />
      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />
      <CustomModal
        title="Вы точно хотите удалить данного пользователя?"
        show={removeUserData}
        closeEvent={() => setRemoveUserData(null)}
        successEvent={() => removeUser(removeUserData)}
        successText="Да"
        cancelText="Нет"
      >
        <br />
      </CustomModal>
      <div className={classes.content}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '31%' }}>Имя</TableCell>
                <TableCell style={{ width: '16%' }}>Телефон</TableCell>
                <TableCell style={{ width: '12%' }}>Роль</TableCell>
                <TableCell style={{ width: '12%' }}>Менеджер</TableCell>
                <TableCell style={{ width: '22%' }}>E-mail</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersList && usersList.map((item: any, i: number) => (
                <TableRow className={classNames(addedUserUid === item.user_uid ? classes.newUser : null)} key={i}>
                  <TableCell aria-disabled={addUser} onClick={() => editUserCell(item, i, 'name')}>
                    {!addUser && editMode.id === i
                      ? <input
                        type="text"
                        className={classes.cellInput}
                        value={showEditName !== i ? item.user_name : name}
                        onChange={(event => nameChangeHandler(event))}
                        // onBlur={() => updateUserData(item)}
                        maxLength={100}
                        readOnly={showEditName !== i}
                        autoFocus
                      />
                      : <div>
                          {
                            editMode.active && editMode.id !== i ?
                            <p style={{marginLeft: '15px', color: 'grey'}}>{item.user_name}</p> :
                            <p style={{marginLeft: '15px'}}>{item.user_name}</p>
                          }

                        </div>
                    }
                  </TableCell>
                  <TableCell onClick={() => editUserCell(item, i, 'phone')}>
                    {!addUser && editMode.id === i ?
                        <ReactInputMask
                            id="phone"
                            className={classes.cellInput}
                            // mask="+7 (999) 999-99-99"
                            value={item.user_mobile}
                            // placeholder="+7 (921) 234-45-67"
                            // onChange={(e: any) => setPhone(e.target.value)}
                            // onBlur={() => updateUserData(item)}
                            readOnly={showEditPhone !== i}
                            autoFocus
                        /> :

                      <div>
                    {
                      editMode.active && editMode.id !== i ?
                        <p style={{marginLeft: 15, color: 'grey'}}>{item.user_mobile}</p> :
                        <p style={{marginLeft: 15}}>{item.user_mobile}</p>
                    }
                      </div>
                    }
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className={classes.selectAdmin}
                        value={item.user_role_uid}
                        onChange={(e: any) => updateUserRole(item, e.target.value)}
                        MenuProps={MenuProps}
                        disabled={item.us}
                      >
                        {selectList.map((item, i) => (<MenuItem key={i} value={item.id}>{item.name}</MenuItem>))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <div  style={{display: 'flex',
                        justifyContent: 'center'
                        }}>
                      <Checkbox checked={item.manager} disabled color="default" />
                    </div>

                  </TableCell>
                  <TableCell onClick={() => editUserCell(item, i, 'email')}>

                    {!addUser && editMode.id === i
                        ?
                        <input
                          type="text"
                          className={classes.cellInput}
                          value={showEditEmail !== i ? item.user_email : email}
                          onChange={(e => emailChangeHandler(e.target.value))}
                          // onBlur={() => updateEmail(item)}
                          readOnly={showEditEmail !== i}
                          autoFocus
                        /> :

                        <div>
                          {
                            editMode.active && editMode.id !== i ?
                              <p style={{marginLeft: 15, color: 'grey'}}>{item.user_email}</p> :
                              <p style={{marginLeft: 15}}>{item.user_email}</p>
                          }
                        </div>
                    }
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() =>
                      item.user_role_uid === '73ac1ea1-ee77-454e-b5f0-6c9c0dca239a'
                        ? confirmDelete(item)
                        : null
                    }
                  >
                    {item.user_role_uid === '73ac1ea1-ee77-454e-b5f0-6c9c0dca239a'
                        ? <span className={classes.removeBtn}>
                            <TrashIcon />
                          </span>
                        : null}
                  </TableCell>
                </TableRow>
              ))}
              {addUser && (
                <TableRow className={classes.addRow}>
                  <TableCell onClick={() => editUserCell({}, usersList.length, 'name')} className={classNames(name?.length === 0 ? classes.error : null)}>
                    <input
                      type="text"
                      className={classes.cellInput}
                      onChange={(event => nameChangeHandler(event))}
                      // onBlur={() => saveUser()}
                    />
                  </TableCell>
                  <TableCell onClick={() => editUserCell({}, usersList.length, 'phone')} className={classNames(phone?.length === 0 || phoneErrorState ? classes.error : null)}>
                    <ReactInputMask
                      id="phone"
                      className={classes.cellInput}
                      // mask="+7 (999) 999-99-99"
                      value={newPhone}
                      // placeholder="+7 (921) 234-45-67"
                      onChange={(e: any) => changePhoneNumberHandler(e.target.value)}
                      // onBlur={() => saveUser()}
                    />

                  </TableCell>
                  <TableCell onClick={() => editUserCell({}, usersList.length, 'role')} style={{padding: '0 !important'}} className={classNames(!role?.length || roleErrorState ? classes.error : null)}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className={`${classes.selectAdmin} selectAbsolute`}
                        // IconComponent={() => (<ArrowForwardIos style={{ color: '#D3D1CB', transform: 'rotate(90deg)' }} />)}
                        value={role}
                        onChange={(e: any) => setRole(e.target.value)}
                        MenuProps={MenuProps}
                      >
                        {selectList.map((item, i) => (<MenuItem key={i} value={item.id}>{item.name}</MenuItem>))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <div  style={{display: 'flex',
                        justifyContent: 'center'
                        }}>
                      <Checkbox checked={false} disabled color="default" />
                    </div>

                  </TableCell>
                  <TableCell onClick={() => editUserCell({}, usersList.length, 'email')} className={classNames( !emailErrorState ? null : classes.error)}>
                    <input
                      type="text"
                      className={classes.cellInput}
                      onChange={(e => emailChangeHandler(e.target.value))}
                      // onBlur={() => saveUser()}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <span className={classes.removeBtn} onClick={() => hideAddUser()}>
                      <TrashIcon />
                    </span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div className={classes.footer}>
          <span className={classes.addBtn} onClick={() => openAddNewUser()}>
            <Plus /> Добавить
          </span>
          <Button variant="contained" className={ editMode.active ? [classes.shakingParent, classes.shaking] : classes.shakingParent} style={{marginLeft: '25px', background: '#1B5E20', color: 'white'}} onClick={updateUserModeHandler}>Применить</Button>
          <Button variant="contained" className={ editMode.active ? [classes.shakingParent, classes.shaking] : classes.shakingParent} style={{marginLeft: '25px', background: '#b5a40d', color: 'white'}} onClick={clearFields}>Сбросить</Button>
        </div>
      </div>
    </>
  )
}
