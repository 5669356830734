import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// const baseURL = 'http://85.175.216.246:8081';

interface RefreshTariffsReq {
  planUid: string | null;
  dateStart: string | null;
  dateEnd: string | null;
  datePayment: string | null;
  planIsConfirmed: true;
  organisationUid: string;
  paymentAmount: number;
  email: string;
};

export interface TariffsType {
  uid: string;
  planName: string;
  planCost: number;
  planDescription1Title: string;
  planDescription1Text: string;
  planDescription2Title: string;
  planDescription2Text: string;
  planDescription3Title: string;
  planDescription3Text: string;
  planIsActive: boolean;
  planCanBetter: boolean;
  planPeriodDays: number;
  date_end?: string;
  plans_organizations_payments_uid: string;
};
interface UpdateByPurchaseReq {
  planIsConfirmed: boolean;
};

export const getAllActiveTariffs = async () => {
  const urlAdres = await url()
  return await API.get(`${urlAdres.data.tariffService}/tariffs/allActiveTariffs`);
};
export const findTariff = async (id: string) => {
  const urlAdres = await url()
  return await API.get(`${urlAdres.data.tariffService}/tariffs/find/${id}`);
};
export const refreshTariffs = async (req: RefreshTariffsReq) => {
  const urlAdres = await url()
  return await API.put(`${urlAdres.data.tariffService}/tariffs/refresh_tariff`, req);
};
export const updateTariff = async (id: string, req: TariffsType) => {
  const urlAdres = await url()
  return await API.put(`${urlAdres.data.tariffService}/tariffs/update/${id}`, req);
};
export const updateByPurchaseTariffs = async (id: string, req: UpdateByPurchaseReq) => {
  const urlAdres = await url()
  return await API.put(`${urlAdres.data.tariffService}/tariffs/update_by_purchase/${id}`, req);
};
export const showGreeting = async (id: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.tariffService}/tariffs/show_greeting`, { organization: id });
};
export const updateByPurchaseOrg = async (idTariff: string, { org, planIsConfirmed }: { org: string, planIsConfirmed: boolean }) => {
  const urlAdres = await url()
  return await API.put(`${urlAdres.data.tariffService}/tariffs/update_by_purchase_org/${idTariff}`, { org, planIsConfirmed });
};
