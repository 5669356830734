import React, { useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import {
  makeStyles,
  createStyles,
  Dialog,
  Popover,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl, Menu, Snackbar, Divider
} from '@material-ui/core';
import {MoreVerticalIcon, IdeaIcon} from '../UI/Icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import classNames from 'classnames';
import { uploadExcelWbData, wbExcelPrimaryFbs } from '../../api/wb-data';
import CustomSnackbar from "../UI/CustomSnackbar";
import IconButton from "@material-ui/core/IconButton";
import * as _ from 'lodash';

const useStyles = makeStyles(() =>
  createStyles({

    compareModal: {
      display: 'flex',
      flexDirection: 'column',
    },
    compareModalContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    compareModalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '22px 38px',
      borderBottom: '1px solid #D3D1CB',
    },
    compareModalTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
    },
    compareModalClose: {
      // position: 'absolute',
      // top: 32,
      // right: 32,
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',

      '& svg': {
        width: 24,
        height: 24,
      }
    },
    compareModalContent: {
      padding: '32px 35px 16px 32px',
      display: 'flex',
      height: '100%',
      flexGrow: 1,
      overflowY: 'auto',
    },
    compareModalFooter: {
      // position: 'fixed',
      // bottom: 0,
      // left: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      width: 'calc(100% - 64px)',
      padding: '10px 32px',
      borderTop: '1px solid #D3D1CB'
    },
    compareModalSaveBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      textTransform: 'capitalize',
      padding: '12px 16px',
      marginRight: 16,
      borderRadius: 8,
      '&:hover': {
        background: '#827F75 !important',
      },
    },
    compareModalCancelBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'capitalize',
      color: '#37352F',
      padding: '12px 16px',
      border: '1px solid #37352F',
      borderRadius: 8,
    },
    compareModalEqualityBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: 700,
      width: '100%',
    },
    compareModalBoldText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 16,
    },
    compareEqualityItem: {
      display: 'flex',
      justifyContent: 'space-between',
      // maxWidth: 588,
      width: '100%',
      marginBottom: 6,
    },
    compareEqualityName: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '6px 12px',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
    },
    compareEqualityToBlock: {
      display: 'flex',
      alignItems: 'center',
      width: 320,
      marginLeft: 10
    },
    compareEqualitySymbol: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#827F75',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      padding: '6px 12px',
      marginRight: 32,
      marginLeft: -5,
    },
    compareEqualityDisabled: {
      background: '#eee',
    },
    compareEqualityValue: {
      position: 'relative',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#F2F2F0',
      border: '1px dashed #D3D1CB',
      borderRadius: 8,
      // padding: '6px 12px',
      minWidth: 180,
      height: 28,
    },
    compareCloseIcon: {
      position: 'absolute',
      top: '50%', left: '102%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      lineHeight: 0,

      '& svg': {
        width: 16,
        height: 16,
        fill: '#37352F',
      }
    },
    compareImportBlock: {
      maxWidth: 552,
      width: '100%',
      marginLeft: 170,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    compareImportList: {
      // display: 'flex',
      // flexWrap: 'wrap',
      // minHeight: '21vh',
      flexGrow: 1,
      border: '1px dashed #ccc',
      padding: 4,
      borderRadius: 8,
      marginBottom: 8,
    },
    compareEmpty: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#efefef',
      border: '2px dashed #ccc',
    },
    compareImportItem: {
      display: 'inline-flex',
      background: '#E9E9E7',
      borderRadius: 8,
      padding: '6px 12px',
      marginRight: 8,
      marginBottom: 8,
      cursor: 'pointer',
      height: 20,
    },
    compareImportText: {
      display: 'inline-flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      userSelect: 'none',
      '& svg': {
        marginRight: 8,
      },
    },
    compareModalInfoBlock: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px 16px 16px 24px',
      maxWidth: 512,
      width: '100%',
      background: 'rgba(23, 115, 103, 0.1)',
      borderRadius: 8
    },
    compareModalInfoIcon: {
      marginRight: 10,
    },
    compareModalInfoContent: {},
    compareModalInfoTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 4,
    },
    compareModalInfoText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
    },
    isDraggable: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'start',
      height: 30,
      border: 'none',
      maxWidth: 'calc(300px - 24px) !important',
      minWidth: 'auto',
      overflow: 'hidden',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      whiteSpace: 'nowrap',
      color: '#37352F',
      background: '#E9E9E7',
      borderRadius: 8,
      padding: '0 12px',
      cursor: 'pointer',

      '& svg': {
        minWidth: 16,
        marginRight: 8,
      },
    },
    toolbarAddBtn: {
      width: 205,
      display: 'flex',
      textAlign: 'start',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'inherit',
      color: 'black',
      background: '',
      borderRadius: 8,
      border: '2px solid #DADADA',
      padding: '2px 12px',
      cursor: 'pointer',
      justifyContent: 'space-between',
    },
    settingsMenu: {
      '& .MuiMenu-paper': {
        background: '#EBEBEB',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 8,
        top: '146px !important',

        transform: 'translateY(60px) !important',
        maxHeight: '70vh',
      },
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    settingsReportItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 15,
      color: '#37352F',
      padding: '7px 44px 7px 16px',
      cursor: 'pointer',
    },
    '& .MuiSnackbar-anchorOriginBottomCenter': {
      top: '50%',
      left: '50%',
      bottom: '50%',
      right: 'auto',
    },
    '& .MuiSnackbarContent-message': {
      color: "black"
    },
    '& .customSnackbar': {
      background: 'white'
    },
  })
)

interface Props {
  open: boolean;
  currentTypeUid: string | null | undefined;
  fileData: string | null | undefined;
  primaryDataTypeName: string;
  closeModal: () => void;
  saveEvent: (newSwap: any, file: any, fileData?: any) => void;
  validateTrue: any;
  equalityList: any;
  importList: any;
  base64: string | undefined;
  type?: string
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const LoadDataValueMatchingModal = (props: Props) => {
  const classes = useStyles();

  const [draggableType, setDraggableType] = React.useState<string>('');
  const [draggableImport, setDraggableImport] = React.useState<any>();
  const [draggableEquality, setDraggableEquality] = React.useState<any>([]);
  const [validateTrue, setValidateTrue] = React.useState<any>([]);
  const [listGoodsAll, setListSelectAll] = React.useState<any>([])
  const [equalityList, setEqualityList] = React.useState<any>([]);
  const [importList, setImportList] = React.useState<any>([]);

  const [openReport, setOpenReport] = React.useState<boolean>(false);
  const [currentKeyComparisons, setCurrentKeyComparisons] = React.useState('')
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);


  const [equalityListNew, setEqualityListNew] = React.useState<any>([]);
  const [importListNew, setImportListNew] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [importCompare, setImportCompare] = React.useState([])

  const open = Boolean(anchorEl);
  const showMessage = (msg: string) => {
    if (!msg?.length) return;
    setSnackbarMessage(msg);
    setShowSnackbar(true);
  };

  useEffect(() => {
    const newEqual = props.equalityList.map(el => (
      {...el, name: el.name.replaceAll('<span>', '').replaceAll('</span>', '')}
    ))
    
    const selectEqualFalse = newEqual.filter(el => el.id === 'id_wb' || el.id === 'supplier_article' || el.id === 'barcode')

    const rename =  selectEqualFalse.map(el => {
      if(el.id === 'supplier_article') {
        return {...el , name: 'Артикул поставщика'}
      }
      if(el.id === 'barcode') {
        return {...el , name: 'шк wb'}
      }
      if(el.id === 'id_wb') {
        return {...el , name: 'Id wb'}
      }
      else {
        return el
      }
    })
    setListSelectAll(rename)
    const selectEqualTrue = newEqual.filter(el => el.id !== 'id_wb' && el.id !== 'supplier_article' && el.id !== 'barcode')
    let replacement = {default: false, disabled: false, equality: "",equalityId: 0, id: "main",name: ""}


    //todo: убрать, если возникнут проблемы при сопоставлении
    let validateTrueArray = props?.validateTrue?.map(prev => {
      return {
        default: true, disabled: false, equality: "",equalityId: 0, id: prev.to ,name: prev.from
      }
    })

    if(props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081') {
      setEqualityList(selectEqualTrue.length > 1 ? props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081' ? [...selectEqualTrue, ...validateTrueArray] : newEqual : [...selectEqualTrue, ...validateTrueArray, replacement]);
      setEqualityListNew(selectEqualTrue.length > 1 ? props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081' ? [...selectEqualTrue, ...validateTrueArray] : newEqual : [...selectEqualTrue, ...validateTrueArray, replacement]);
    }

    if(props.currentTypeUid !== '5fe9a4b9-423d-4bad-bbc9-e15660568081') {
      setEqualityList(newEqual)
      setEqualityListNew(newEqual)
    }

    setValidateTrue(props.validateTrue);

    setImportList(props.importList);

    setImportCompare(props.importList)
    setImportListNew(props.importList);
    // filterGoodsAllHandler()
  }, [props.equalityList, props.importList]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const onDragOverEvent = (e: any, item: any) => {
    e.preventDefault();
  };


  const dropInEquality = (hoveredEq: any) => {

    if (hoveredEq.disabled) return;
    if (draggableType === 'equality') return;

    let importListCopy = JSON.parse(JSON.stringify(importList));

    setEqualityList(equalityList.map((item: { id: number; equality: string; equalityId: number; }) => {
      if (hoveredEq.id === item.id) {
        if (item.equalityId > -1 && item.equality.length > 0) {
          importListCopy.push({ equalityId: item.equalityId, equality: item.equality });
          setImportList(importListCopy);
        }
        item.equalityId = draggableImport.equalityId;
        item.equality = draggableImport.equality;
      }
      return item;
    }));
    setImportList(importListCopy.filter((item: any) => item.equalityId !== draggableImport.equalityId));
  };

  const dropInImport = (e: any) => {
    if (!draggableEquality.equality || draggableType === 'import') {
      setDraggableEquality([]);
      return;
    }
    let equalityListCopy = JSON.parse(JSON.stringify(equalityList));
    let importListCopy = JSON.parse(JSON.stringify(importList));


    importListCopy.push({
      equalityId: draggableEquality.equalityId,
      equality: draggableEquality.equality
    });
    equalityListCopy.forEach((item: any) => {
      if (draggableEquality.equalityId === item.equalityId) {
        item.equalityId = 0;
        item.equality = '';
      }
    });
    importListCopy.sort((a, b) => a.equalityId - b.equalityId)
    setImportList(importListCopy);
    setEqualityList(equalityListCopy);
    setDraggableEquality([]);
  };

  const handleDragElement = (e: any, item: any, type: string) => {
    if (type === 'equality') {
      if (!item.equality) e.preventDefault();

      setDraggableEquality(item);
      setDraggableType(type);
    }
    if (type === 'import') {
      setDraggableImport(item)
      setDraggableType(type);
    }
  };


  const save = async () => {
    const error = equalityList.filter(el => el.equality === '' && el.disabled === false && el.id !== 'main')

    if(currentKeyComparisons === '' && props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081') {
      showMessage('Выберите ключ сопоставления')
    }
    else if(error.length > 0) {
      showMessage('Сопоставьте все значения')
    }
    else {
    // validateTrue
    const filter = equalityList.filter(el => el.equality !== '')
    const result = filter.map((item: any) => {
      return {
        from: item.disabled ? "true" : item.equality,
        to: item.id,
        default: item.disabled
      };
    });
    if(props.type === '1') {
      props.saveEvent([...result, ...validateTrue], props.fileData);
    }
    else props.saveEvent([...result, ...validateTrue], props.fileData ?? props.base64);
    
    handleClose();
  }
  };

  const handleClose = () => {
    setCurrentKeyComparisons('')
    setEqualityList([]);
    setImportList([]);
    props.closeModal();
  };

  const disableItem = (item: any, index: number) => {
    const equalityListCopy = JSON.parse(JSON.stringify(equalityList));
    equalityListCopy[index].disabled = !equalityListCopy[index].disabled;
    setEqualityList(equalityListCopy);
  };


  const replace = (item) => {
    return item.replaceAll('\n', ' ').replaceAll('- ', '').replaceAll(',', '').replaceAll('(', '').replaceAll(')', '').toLowerCase()
  }



  const clickCompare = () => {
    if(currentKeyComparisons === '' && props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081') {
      showMessage('Выберите ключ сопоставления')
    }
    else {
    let equalityListCopy = JSON.parse(JSON.stringify(equalityListNew));
    let importListCopy = JSON.parse(JSON.stringify(importListNew));
    let result: any = []
    let result1: any = []
    const newList = equalityListCopy.map(el => {
      importListCopy.map(item => {
        if(replace(item.equality) === replace(el.name)) {
          return result1.push({...el, equality: item.equality, equalityId: item.equalityId}) && result.push({...el, equality: item.equality, equalityId: item.equalityId})
        }
        else {
          return result.push(el)
        }
      })
    })

    const uniqData = result1.map(el => el.name)
    const uniqueArr: any = [...new Set(result)];

    let endResult: any = []
    uniqueArr.map((el, index) => {
      if(uniqData.includes(el.name) && el.equality === '') {
        return el;
      }
      else {
        return endResult.push(el)
      }
    })

    const filterNewList = endResult.filter(el => el.equality !== '' && replace(el.equality) === replace(el.name))
    const listName = filterNewList.map(el => replace(el.equality))


    const newImport = importListCopy.filter(el => {
      return !listName.includes(replace(el.equality))
    })

    setImportList(newImport)
    setImportCompare(newImport)
    const newEndRes = _.uniqBy(endResult, 'name')

    const next = newEndRes.map(el => {
      if(replace(el.name) !== replace(el.equality)) {
        return {...el, equality: ''}
      }
      else {
        return el
      }
    })

    setEqualityList(next)
    }
  }


  function changeCurrentKeyComparisons(value) {

    if(value.name !== currentKeyComparisons) {
    let importListCopyCompare = JSON.parse(JSON.stringify(importCompare));
    let importListCopy = JSON.parse(JSON.stringify(importList));
    const result = _.uniqBy([...importListCopyCompare, ...importListCopy], 'equality')

    setImportList(result)

    const newEqual = props.equalityList.map(el => (
      {...el, name: el.name.replaceAll('<span>', '').replaceAll('</span>', '')}
    ))

    const selectEqualFalse = newEqual.filter(el => el.id === 'id_wb' || el.id === 'supplier_article' || el.id === 'barcode')
    const rename =  selectEqualFalse.map(el => {
      if(el.id === 'supplier_article') {
        return {...el , name: 'Артикул поставщика'}
      }
      if(el.id === 'barcode') {
        return {...el , name: 'шк wb'}
      }
      if(el.id === 'id_wb') {
        return {...el , name: 'Id wb'}
      }
      else {
        return el
      }
    })
    setListSelectAll(rename)

    setOpenReport(false)
    if(currentKeyComparisons === '') {
      const equal = [...equalityList, value]
      const newEqual = [...equalityListNew, value]
      setEqualityListNew(newEqual)
      setEqualityList(equal)
    }

    if(currentKeyComparisons !== '' && value.name !== currentKeyComparisons) {
      equalityListNew.pop()
      equalityList.pop()
      const equal = [...equalityList, value]
      const newEqual = [...equalityListNew, value]
      setEqualityListNew(newEqual)
      setEqualityList(equal)
    }
    setCurrentKeyComparisons(value.name)
  }
  }


useEffect(() => {
  // if(props.currentTypeUid === '0a5956f9-a9f6-49c0-8080-bb1fda1251a7') {
  //   clickCompare()
  // }
  clickCompare()
}, [props]);

  return (
    <Dialog
      className={classes.compareModal}
      open={props.open}
      fullScreen
    >

    <CustomSnackbar
      open={showSnackbar}
      message={snackbarMessage}
      handleClose={() => setShowSnackbar(false)}
    />
      <div className={classes.compareModalContainer}>
        <div className={classes.compareModalHeader}>
          <h2 className={classes.compareModalTitle}>Сопоставление значений</h2>
          <button className={classes.compareModalClose} onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={classes.compareModalContent}>
          <div className={classes.compareModalEqualityBlock}>
            <div>
            <p className={classes.compareModalBoldText}>Название в сервисе</p>

            {props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081'?
            currentKeyComparisons === '' ?
              <div style={{marginBottom: 15, color: '#FF3F34', fontWeight: 600, fontSize: 14}}>Выберите ключ сопоставления</div>
              :
              <div style={{marginBottom: 15, color: 'black', fontWeight: 600, fontSize: 14}}>Выберите ключ сопоставления</div>
              // : <div style={{marginTop: 47}}></div>
              : null
            }
              {
                props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081' &&
                <div style={{marginBottom: 200}}>
                  {equalityList.map((item: any, index: number) => (
                    index === equalityList.length - 1 &&
                    <div className={classes.compareEqualityItem}>
                      <div>
                        <Button
                          id="basic-button"
                          aria-haspopup="true"
                          onClick={() => setOpenReport(prev => !prev)}
                          className={classes.toolbarAddBtn}
                          style={{border: equalityList[equalityList.length - 1].equality && currentKeyComparisons ? '2px solid #7DF179' : null}}
                        >
                          {currentKeyComparisons !== '' ? currentKeyComparisons : `Выберите ключ`}
                          {openReport === true ?  <ExpandLessIcon color="#000000"/> : <ExpandMoreIcon color="#000000"/>}
                        </Button>

                        <Menu
                          id="settings-menu"
                          className={classes.settingsMenu}
                          style={{left: 14}}
                          open={openReport}
                          onBlur={() => setOpenReport(false)}
                        >
                          {listGoodsAll.map(el => (
                            <div
                              className={classes.settingsReportItem}
                              onClick={(event => changeCurrentKeyComparisons(el))}
                            >
                              {el.name}
                            </div>
                          ))}
                        </Menu>
                      </div>

                      <div className={classNames(classes.compareEqualityToBlock)}>

                  <span
                    className={
                      classNames(
                        classes.compareEqualitySymbol,
                        item.disabled ? classes.compareEqualityDisabled : null
                      )
                    }
                  >=</span>
                        <span
                          draggable={currentKeyComparisons === '' && props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081'? false : true}
                          onDragOver={(e: any) => onDragOverEvent(e, item)}
                          onDragStart={(e) => handleDragElement(e, item, 'equality')}
                          onDrop={(e) => dropInEquality(item)}
                          id='22'
                          className={
                            classNames(
                              classes.compareEqualityValue,
                              item.equality.length ? classes.isDraggable : ''
                            )
                          }
                        >
                    {item.equality.length ? (<MoreVerticalIcon />) : null}
                          {item.equality.substr(0, 26).replaceAll('\n', ' ').replaceAll('- ', '')}
                          {item.equality.length >= 26 ? '...' : null}
                  </span>
                      </div>
                    </div>
                  ))}
                </div>
              }


            {props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081' ?
            <div>
            {equalityList.map((item: any, index: number) => {
              return (
                currentKeyComparisons !== '' || equalityList.length <= 2 ?
                <div
                    className={classes.compareEqualityItem}
                    key={index}
                  >
                {index !== equalityList.length - 1 && item.name &&
                <span
                    className={
                      classNames(
                          classes.compareEqualityName,
                          item.disabled ? classes.compareEqualityDisabled : null
                      )
                    }
                >
                  {item.name}
                </span>
                }
                {index !== equalityList.length - 1 && item.name &&
                    <div className={classNames(classes.compareEqualityToBlock)}>
                      <span
                          className={
                            classNames(
                                classes.compareEqualitySymbol,
                                item.disabled ? classes.compareEqualityDisabled : null
                            )
                          }
                      >=</span>
                          <span
                              draggable={currentKeyComparisons === '' && props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081'? false : true}
                              onDragOver={(e: any) => onDragOverEvent(e, item)}
                              onDragStart={(e) => handleDragElement(e, item, 'equality')}
                              onDrop={(e) => dropInEquality(item)}
                              id='22'
                              className={
                                classNames(
                                    classes.compareEqualityValue,
                                    item.equality.length ? classes.isDraggable : ''
                                )
                              }
                          >
                            {item.equality.length ? (<MoreVerticalIcon />) : null}
                                {item.equality.substr(0, 26).replaceAll('\n', ' ').replaceAll('- ', '')}
                                {item.equality.length >= 26 ? '...' : null}
                                {
                                  item.default
                                      ? <span
                                          className={classes.compareCloseIcon}
                                          onClick={() => disableItem(item, index)}
                                      >
                                  <CloseIcon />
                                </span>
                                      : null
                                }
                          </span>
                    </div>
                }
                  </div>
                :
              <div
                  className={classes.compareEqualityItem}
                  key={index}
              >

              <span
                  className={
                    classNames(
                        classes.compareEqualityName,
                        item.disabled ? classes.compareEqualityDisabled : null
                    )
                  }
              >
                {item.name}
              </span>


                  <div className={classNames(classes.compareEqualityToBlock)}>
                    <span
                        className={
                          classNames(
                              classes.compareEqualitySymbol,
                              item.disabled ? classes.compareEqualityDisabled : null
                          )
                        }
                    >=</span>
                        <span
                            draggable={currentKeyComparisons === '' && props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081'? false : true}
                            onDragOver={(e: any) => onDragOverEvent(e, item)}
                            onDragStart={(e) => handleDragElement(e, item, 'equality')}
                            onDrop={(e) => dropInEquality(item)}
                            id='22'
                            className={
                              classNames(
                                  classes.compareEqualityValue,
                                  item.equality.length ? classes.isDraggable : ''
                              )
                            }
                        >
                      {item.equality.length ? (<MoreVerticalIcon />) : null}
                          {item.equality.substr(0, 26).replaceAll('\n', ' ').replaceAll('- ', '')}
                          {item.equality.length >= 26 ? '...' : null}
                          {
                            item.default
                                ? <span
                                    className={classes.compareCloseIcon}
                                    onClick={() => disableItem(item, index)}
                                >
                            <CloseIcon />
                          </span>
                                : null
                          }
                    </span>
                  </div>

                </div>
              )
            })}
            </div>

            :
            <div>
              {equalityList.map((item: any, index: number) => {
              return (
                  <div
                    className={classes.compareEqualityItem}
                    key={index}
                  >

                <span
                    className={
                      classNames(
                          classes.compareEqualityName,
                          item.disabled ? classes.compareEqualityDisabled : null
                      )
                    }
                >
                  {item.name}
                </span>


                    <div className={classNames(classes.compareEqualityToBlock)}>
                      <span
                          className={
                            classNames(
                                classes.compareEqualitySymbol,
                                item.disabled ? classes.compareEqualityDisabled : null
                            )
                          }
                      >=</span>
                          <span
                              draggable={currentKeyComparisons === '' && props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081'? false : true}
                              onDragOver={(e: any) => onDragOverEvent(e, item)}
                              onDragStart={(e) => handleDragElement(e, item, 'equality')}
                              onDrop={(e) => dropInEquality(item)}
                              id='22'
                              className={
                                classNames(
                                    classes.compareEqualityValue,
                                    item.equality.length ? classes.isDraggable : ''
                                )
                              }
                          >
                        {item.equality.length ? (<MoreVerticalIcon />) : null}
                            {item.equality.substr(0, 26).replaceAll('\n', ' ').replaceAll('- ', '')}
                            {item.equality.length >= 26 ? '...' : null}
                            {
                              item.default || item.id === 'rest_fbo_SPB'
                                  ? <span
                                      className={classes.compareCloseIcon}
                                      onClick={() => disableItem(item, index)}
                                  >
                              <CloseIcon />
                            </span>
                                  : null
                            }
                      </span>
                    </div>

                  </div>
              )
            })}
            </div>}

            </div>
            {props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081' ?
              <div style={{display: 'flex',justifyContent: 'flex-end', marginBottom: 20}}>
              <Button className={classes.compareModalSaveBtn}  style={{fontSize: 17}} onClick={clickCompare}>
                  Сопоставить
              </Button>
            </div>
            :
            <div style={{display: 'flex',justifyContent: 'flex-end', marginBottom: 20}}>
              <Button className={classes.compareModalSaveBtn} style={{fontSize: 17}} onClick={clickCompare}>
                  Сопоставить
              </Button>
            </div>
            }
          </div>
          <div
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => dropInImport(e)}
            onDragLeave={(e) => e.preventDefault()}
            className={classes.compareImportBlock}
            id='15'
          >
              <p className={classes.compareModalBoldText}>Название в файле импорта</p>

              <div
                className={
                  classNames(
                    classes.compareImportList,
                    !importList.length ? classes.compareEmpty : null
                  )
                }
              >
                {!importList.length ? <span>Список пуст</span> : null}
                {importList.map((item: any, index: number) => (
                  <div
                    className={classes.compareImportItem}
                    draggable={currentKeyComparisons === '' && props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081'? false : true}
                    onDragStart={(e) => handleDragElement(e, item, 'import')}
                    key={index}
                  >
                    <span className={classes.compareImportText}>
                      <MoreVerticalIcon /> <span>{item.equality.replaceAll('\n', ' ').replaceAll('- ', '')}</span>
                    </span>
                  </div>
                ))}
              </div>
              <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography>I use Popover.</Typography>
              </Popover>
            <div className={classes.compareModalInfoBlock}>
              <span className={classes.compareModalInfoIcon}>
                <IdeaIcon />
              </span>
              <div className={classes.compareModalInfoContent}>
                <h3 className={classes.compareModalInfoTitle}>Сопоставление значений</h3>
                <p className={classes.compareModalInfoText}>
                  Перетащите значение файла импорта напротив значения
                  в сервисе. При следующей загрузке сопоставление
                  не потребуется.
                </p>
              </div>
            </div>
          </div>
        </div>


        <div className={classes.compareModalFooter}>
          {
            props.currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081' ?
              <Button
                // disabled={ currentKeyComparisons.length === 0}
                className={classes.compareModalSaveBtn}
                onClick={save}
              >
                Сохранить
              </Button> :
              <Button className={classes.compareModalSaveBtn} onClick={save}>
                Сохранить
              </Button>
          }
          <Button className={classes.compareModalCancelBtn} onClick={handleClose}>
            Отмена
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default LoadDataValueMatchingModal
