
import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
  fill?: string;
}

const MoreIcon = (props: Props) => {
  return (
    <svg width={props.width || '16px'} height={props.height || '16px'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99967 8.66634C10.3679 8.66634 10.6663 8.36786 10.6663 7.99967C10.6663 7.63148 10.3679 7.33301 9.99967 7.33301C9.63148 7.33301 9.33301 7.63148 9.33301 7.99967C9.33301 8.36786 9.63148 8.66634 9.99967 8.66634Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.99967 3.99984C10.3679 3.99984 10.6663 3.70136 10.6663 3.33317C10.6663 2.96498 10.3679 2.6665 9.99967 2.6665C9.63148 2.6665 9.33301 2.96498 9.33301 3.33317C9.33301 3.70136 9.63148 3.99984 9.99967 3.99984Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.99967 13.3333C10.3679 13.3333 10.6663 13.0349 10.6663 12.6667C10.6663 12.2985 10.3679 12 9.99967 12C9.63148 12 9.33301 12.2985 9.33301 12.6667C9.33301 13.0349 9.63148 13.3333 9.99967 13.3333Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default MoreIcon;
