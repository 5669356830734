import React, {useCallback, useEffect} from 'react'
import { makeStyles, createStyles } from '@material-ui/core';

import Main from '../../components/layout/Main'
import { NavLink } from 'react-router-dom'
import { CofeCircle, PhoneCircle, EmailCircle } from '../../components/UI/Icons'
import { useQuery } from 'react-query';
import { getInfo } from '../../api/faq';
import { SupportFAQModal } from '../../components/Support/FAQModal';
import routes from "../../components/UI/Breadcrumbs/routesList";

const useStyles = makeStyles(() =>
  createStyles({
    support: {
      padding: 32,
    },
    supportText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
      maxWidth: 468,
    },
    supportLink: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      textDecoration: 'underline',
      cursor: 'pointer',
      marginBottom: 10,
    },
    supportTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
      marginBottom: 16,
      marginTop: 36,
    },
    supportList: {
      listStyle: 'none',
      padding: 0,
    },
    supportItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
    },
    supportItemText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      marginLeft: 16,
      textDecoration: 'none',
    },
  }),
);

const Support = () => {
  const classes = useStyles();

  const contacts = useQuery('contacts', async () => {
    const phones = await getInfo('c7523369-5f0a-41ad-90bd-c9889a694806');
    const emails = await getInfo('2d222849-ac3b-4999-b862-7e01b003c01d');
    const times = await getInfo('93037cf8-a154-45f1-abb4-db9bcafea15c');
    const result = {
      phones: phones.data.data,
      emails: emails.data.data,
      times: times.data.data
    }

    return result;
  });
  const [open, setOpen] = React.useState(false);

  const token = document.cookie.split('token="')
  const filterToken = token[1]?.split('\"')
  const logResult = useCallback(() => {
    return window.location.pathname
  }, [window.location.pathname])

  useEffect(() => {
    if(!filterToken && window.location.pathname !== '/login' &&
      window.location.pathname !== '/admin/jur-license_agreement' &&
      window.location.pathname !== '/admin/jur-personal_data_processing_policy' &&
      window.location.pathname !== '/admin/jur-privacy_policy') {
      window.location.replace('/login')
    }

  }, [logResult])

  return (
    <>
      <SupportFAQModal open={open} closeEvent={() => setOpen(false)} />

      <div className={classes.support}>
        <p className={classes.supportText}>Вопросы, замечания и предложения по работе с сервисом вы можете задать нашим сотрудникам. Для оперативности решения вашего вопроса перед обращением к сотрудникам рекомендуем посмотреть раздел</p>
        <div style={{display: 'flex',flexDirection: 'column'}}>
          {/* <span className={classes.supportLink}
          onClick={() => setOpen(true)}
          >
            Часто задаваемые вопросы (FAQ)
          </span> */}
          <a className={classes.supportLink}
          href='/admin/jur-license_agreement' target="_blank"
          >
            Оферта (лицензионное соглашение)
          </a>
          <a className={classes.supportLink}
          href= '/admin/jur-privacy_policy' target="_blank"
          >
            Политика конфиденциальности
          </a>
          <a className={classes.supportLink}
          href= '/admin/jur-personal_data_processing_policy' target="_blank"
          >
            Политика обработки персональных данных
          </a>
        </div>
        <h4 className={classes.supportTitle}>Контакты</h4>
        <ul className={classes.supportList}>
          <li className={classes.supportItem}>
            <CofeCircle />
            <span className={classes.supportItemText}>9:00 - 18:00 (мск)</span>
          </li>
          <li className={classes.supportItem}>
            <PhoneCircle />
            <a className={classes.supportItemText} target="blank" style={{textDecoration: 'underline'}} href="https://api.whatsapp.com/send/?phone=79648965197&text&type=phone_number&app_absent=0">написать в мессенджер</a>
          </li>
          <li className={classes.supportItem}>
            <EmailCircle />
            <a className={classes.supportItemText} href="mailto:price.market.io@yandex.ru">price.market.io@yandex.ru</a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Support
