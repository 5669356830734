import { FormControlLabel, Checkbox, createStyles, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useCallback } from 'react';
import { setData } from '../../../api/reports';
import CurrencyFormatter from '../../UI/CurrencyFormatter';
import cookie from 'cookie';
import { useSearchParams } from 'react-router-dom';
import '../../../index.css'
import Hightlight from '../../../common/Hightlight';
import { PropertyKeys } from 'ag-grid-community';


const useStyles = makeStyles(() =>
  createStyles({
    cellCheckbox: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: 0,
      margin: 0,

      '& svg': {
        fill: '#37352F',
      },
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
    cellText: {
      display: 'inline-flex',
      width: '100%',
      height: '100%',
      padding: 0,
      margin: 0,
    },
    cellInput: {
      width: '100%',
      background: 'transparent',
      border: 'none',
      outline: 'none',
    },
  })
);

interface Props {
  uidRow: string;
  editCellPos?: { x: number, y: number };
  x: number;
  y: number;
  dataItem?: any;
  type?: string;
  editable?: boolean;
  data: any;
  rowIndex?: number;
  colName: string;
  setNewData?: (data: any, rowIndex: number, colName: string) => void;
  resetEditCellPos: () => void;
  setNewVolume?: (val: any) => void;
  updateCellData?: (val: any) => void;
  typeName: any;
  search:string;
  setDoubles?: any
  test: any
  changeCheckboxHandler: (item: any) => void
};

const getAlign = (colType: string) => {
  switch (colType) {
    case 'int': return 'flex-end';
    case 'bool': return 'center';
    case 'str': return 'flex-start';
  };
};



const BooleanCell = (props: Props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.dataItem.check);

  const [searchParams, setSearchParams] = useSearchParams();
  console.log('2', props.test, props.data)
  React.useEffect(() => {
    if (props.data) setValue(props.data === 'true' || props.data === 'True');
  }, [props.data]);
  // props.changeCheckboxHandler(props.dataItem);

  const saveData = async (val: string | boolean) => {
    setValue(!value);
    console.log('SAVE', props.data, props.dataItem)
    props.changeCheckboxHandler(props.dataItem)
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await setData({
      primary: searchParams.get('primaryDataUploadUid') || '',
      org: uid,
      column: [props.colName],
      filter: [props.uidRow],
      val: typeof val === 'boolean' ? String(val ? 'true' : 'false') : val ||  ''
    });
  };

  const getDubles = async (item) => {
    setValue(!value);
    props.changeCheckboxHandler(item)
    props.setDoubles(props.dataItem.uid)
  };
  console.log(props.dataItem.check, props.data)
  return (
    <span
      className={classes.cellText}
      style={{justifyContent: props.colName === 'rest_wb_fbo_stocks'?getAlign('str') : getAlign('bool')}}
    >
      {props.dataItem.check === false ?
      <FormControlLabel
        className={classes.cellCheckbox}
        control={
          <Checkbox
            color="default"
          />
        }
        checked={props.dataItem.check}
        onChange={
          () => {
            getDubles(props.dataItem)
          }
        }
        label={undefined}
      />
    :
    props.colName === 'rest_wb_fbo_stocks' ?
    <div >{value ? 'Да' : 'Нет'}</div>
    :
    <FormControlLabel
        className={classes.cellCheckbox}
        control={
          <Checkbox
            color="default"
            // disabled={props.editable}
          />
        }
        checked={props.dataItem.check}
        onChange={
          () => {
            saveData()
          }
        }
        label={undefined}
      />

        // <p>ssssss</p>
      }
    </span>
  );
};

const NumberCell = (props: Props) => {
  const light = useCallback((str) => {
    return <Hightlight filter={props.search} str={str} />
  }, [props.search])
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const [value, setValue] = React.useState<any>();
  const [loaded, setLoaded] = React.useState(false);
  const [focusEv, setFocusEv] = React.useState(false);

  React.useEffect(() => {
    const val = String(value);
    if (val.length > 1 && val[0] === '0')
      setValue(val.slice(1));
  }, [value]);

  React.useEffect(() => {
    if (props.colName !== 'length'
      && props.colName !== 'weight'
      && props.colName !== 'height'
      && props.colName !== 'width')
      setValue(Number(props.data || 0));
    if (props.data !== value && !loaded)
      setValue(Number(props.data || 0));

    setLoaded(true);
  }, [props.data]);

  const saveData = async () => {
    props.resetEditCellPos();
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await setData({
      primary: searchParams.get('primaryDataUploadUid') || '',
      org: uid,
      column: [props.colName],
      filter: [props.uidRow],
      val: String(value ? value^0 : 0)
    });

    if (props.updateCellData) {
      props.updateCellData({[props.colName]: value ? value^0 : 0});
      if (typeof result?.data?.data?.volume === 'number')
        props.updateCellData({ [props.colName]: value ? value^0 : 0, volume: String(result.data.data.volume) })
    }

    // if (props.setNewVolume) props.setNewVolume(String(result.data.data.volume));
  };

  return (
    (props.x === props?.editCellPos?.x && props.y === props?.editCellPos?.y && props.typeName === 'Параметры бренд/категория/товар' )
      ? (
          <input
            autoFocus
            type="number"
            className={classes.cellInput}
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            onBlur={() => saveData()}
          />
        )
      : (
          <span
            className={classes.cellText && 'vid'}
            style={{justifyContent: getAlign('int')}}
          >
            {searchParams.get('id') === '7e8d9639-2419-47cd-aca1-0c8829528622'
              && !value
              ? null
              : <CurrencyFormatter light={light} currency={value || 0} />
            }
          </span>
        )
  );
};

const DateCell = (props: Props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (props.data)
      setValue(props.data);
  }, [props.data]);

  const saveData = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await setData({
      primary: searchParams.get('primaryDataUploadUid') || '',
      org: uid,
      column: [props.colName],
      filter: [props.uidRow],
      val: String(value)
    });
    props.resetEditCellPos();
  };

  return (
    (props.x === props?.editCellPos?.x && props.y === props?.editCellPos?.y && props.typeName === 'Параметры бренд/категория/товар')
      ? (
          <input
            autoFocus
            type="date"
            className={classes.cellInput}
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            onBlur={() => saveData()}
          />
          // <span
          //   className={classes.cellText && 'vid'}
          //   style={{justifyContent: getAlign('date')}}
          // >
          //   {moment(value).format('L')}
          // </span>
        )
      : (
          <span
            className={classes.cellText && 'vid'}
            style={{justifyContent: getAlign('date')}}
          >
            {moment(value).format('L')}
          </span>
        )
  )
};

const DefaultCell = (props: Props) => {
  const light = useCallback((str) => {
    return <Hightlight filter={props.search} str={str} />
  }, [props.search])
  const classes = useStyles();
  const [value, setValue] = React.useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (props.data !== value)
      setValue(props.data);
  }, [props.data]);

  const saveData = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await setData({
      primary: searchParams.get('primaryDataUploadUid') || '',
      org: uid,
      column: [props.colName],
      filter: [props.uidRow],
      val: value ? String(value) : ''
    });
    props.resetEditCellPos();
  };


  return (
    (props.x === props?.editCellPos?.x && props.y === props?.editCellPos?.y && props.typeName === 'Параметры бренд/категория/товар')
      ? (
          <input
            autoFocus
            type="text"
            className={classes.cellInput}
            value={light(String(value))}
            onChange={(e: any) => setValue(e.target.value)}
            onBlur={() => saveData()}
          />
        )
      : (
        props.data ?
          props.data.split(' ')[0].length === 0 && props.typeName === 'Параметры бренд/категория/товар' ?
          <span>
            &nbsp;{light(props.data)}
          </span>
          :
          <span>
            {light(props.data)}
          </span>
        :
        null
        )
  )
};

// const CheckboxCell = (props: Props) => {
//   const light = useCallback((str) => {
//     return <Hightlight filter={props.search} str={str} />
//   }, [props.search])
//   const classes = useStyles();
//
//   const [value, setValue] = React.useState<any>();
//   const [searchParams, setSearchParams] = useSearchParams();
//
//   React.useEffect(() => {
//     if (props.data !== value)
//       setValue(props.data);
//   }, [props.data]);
//
//   // const saveData = async () => {
//   //   const uid = await cookie.parse(document.cookie)['org_uid'];
//   //   const result = await setData({
//   //     primary: searchParams.get('primaryDataUploadUid') || '',
//   //     org: uid,
//   //     column: [props.colName],
//   //     filter: [props.uidRow],
//   //     val: value ? String(value) : ''
//   //   });
//   //   props.resetEditCellPos();
//   // };
//
//   return (
//     <div style={{display: 'flex', justifyContent: 'center'}}>
//       <FormControlLabel
//         className={classes.cellCheckbox && 'vid'}
//         control={
//           <Checkbox
//             color="default"
//             disabled={props.editable}
//           />
//         }
//         checked={props.data === 'true'}
//         // onChange={
//         //   () => {
//         //     saveData(
//         //       'item.uid',
//         //       props.data === 'true' ? false : true,
//         //       'col.column_name',
//         //       false
//         //     )
//         //   }
//         // }
//         label={''}
//       />
//     </div>
//   )
// };


const LoadDataTableCellByType = (props: Props) => {
  if (props.type === 'bool')
    return <BooleanCell {...props} />
  if (props.type === 'int')
    return <NumberCell {...props} />
  if (props.type === 'date')
    return <DateCell {...props} />
  // if (props.type === 'Boolean')
  //   return <CheckboxCell {...props} />

  return <DefaultCell {...props} />
};

export default LoadDataTableCellByType;
