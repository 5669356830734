import { makeStyles, createStyles, Menu, MenuItem, Select, TextField, Badge, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import cookie from 'cookie';
import { Plus } from '../UI/Icons';
import Trash from '../UI/Icons/Trash';
import { getFilter, setFilter } from '../../api/reports';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(() =>
  createStyles({
    toolbarFilterBtn: {
      position: 'relative',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '14px',
      color: '#37352F',
      textTransform: 'capitalize',
      borderBottom: '1px solid #000',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:hover': {
        borderBottom: '1px solid #827F75',
        color: '#827F75',
      },
    },
    toolbarFilterChip: {
      position: 'absolute',
      top: -10, right: -10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '12px',
      width: 14,
      height: 14,
      color: 'white',
      background: '#FF6C4B',
      borderRadius: '50%',
    },
    filterMenu: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 16,
        top: '126px !important',
        overflow: 'unset',
      },
      '& .MuiMenu-list': {
        minWidth: 500,
        maxWidth: 500,
        width: '100vh',
        padding: 0,
      },
    },
    filterContet: {
      padding: 16,
      boxSizing: 'border-box',
    },
    filterHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,

      '& svg': {
        width: 20,
        height: 20,
      }
    },
    filterTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    filterList: {},
    filterAddBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      textTransform: 'none',
      border: 'none',
      outline: 'none',
      background: 'none',
      padding: 0,
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        marginRight: 4,
        stroke: '#37352F',
      },
    },
    rowBtn: {
      marginLeft: 5,
      '& svg': {
        stroke: '#FF6C4B ',
      },
    },
    rowList: {},
    row: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginBottom: 24,
    },
    rowLabel: {},
    rowSelectWhere: {
      height: 30,
      minWidth: 214,
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      borderRadius: 8,
      marginLeft: 16,

      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '& .MuiSelect-select': {
        paddingLeft: 10,
      },
    },
    rowEqualSymb: {
      minWidth: 40,
      paddingLeft: 10,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      borderRadius: 8,
      margin: '0 10px',

      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      }
    },
    rowSelectEqual: {
      position: 'relative',
      minWidth: 108,
      height: 30,
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      borderRadius: 8,
      marginRight: 16,

      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '& .MuiSelect-select': {
        paddingLeft: 10,
      },
      '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        opacity: 0,
      },
      '& input': {
        paddingLeft: '4px !important',
      },
      '& .MuiAutocomplete-clearIndicator': {
        display: 'none',
      },
    },
    filterCancelBtn: {
      marginLeft: 8,
    },
    filterFooter: {
      display: 'flex',
      alignItems: 'center',
    },
    autocompleteWrap: {
      position: 'relative',

      '& .MuiAutocomplete-popper': {
        right: 0,
        left: 'auto',
        minWidth: 300,
      },
      '& .MuiAutocomplete-option': {
        padding: 0,
      },
    },
    autocomplete: {},
    autocompleteListItem: {
      position: 'relative',
      width: '100%',
      padding: '6px 16px !important',

      '&.disabled': {
        opacity: 0.5,
      }
    },
  })
)

interface Props {
  cols: any;
  filterList?: any;
  primaryDataUploadUid: string;
  sendFilters: (filter: any) => void;
  cancelFilter?: () => void;
  setFilterLoaded?: (loaded: boolean) => void;
  setCurrentFilter?: () => void;
  setPage: any;
};

export const FilterMenu = (props: Props) => {
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilter, setShowFilter] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [oldValue, setOldValue] = React.useState();
  const [hasAlready, setHasAlready] = React.useState<any>({});
  const [symbols, setSymbols] = React.useState(['=', '>', '<', '!=']);
  const [lenOfFilters, setLenOfFilters] = React.useState([])


  const [rows, setRows] = React.useState<any>([
    { where: '', equal: null, symbol: '=', type: '', equalList: [] }
  ]);

  useEffect(() => {
    console.log('я LOADDATAFILTER', props.filterList)
    if (loaded) return;
    if (props.filterList.length) {
      let list: any = [];

      props.filterList.forEach(async (item: any) => {
        const uid = await cookie.parse(document.cookie)['org_uid'];
        const result = await getFilter({
          primary: props.primaryDataUploadUid,
          org: uid,
          column: item.filter,
        });

        list.push({
          where: item.filter,
          equal: item.value_filter,
          equalList: result.data.data.result,
          symbol: symbols[Number(item.type)],
          type: item.column_type
        });
      });
      let copyFil: any = JSON.parse(JSON.stringify(props.filterList));
      const fil = copyFil.reduce((acc, n) => (acc[n.value_filter] = true, acc), {})
      setHasAlready(fil)
      setLenOfFilters(props.filterList)
      setTimeout(() => {
        updateHasAlready(list);
        setRows(list);
        sendGetDataFilter(list);
        setLoaded(true);
        if (props.setFilterLoaded) props.setFilterLoaded(true);
      }, 1000);
    } else {
      setTimeout(() => {
        if (props.setFilterLoaded) props.setFilterLoaded(true);
      }, 1000);
    }
  }, [loaded, props.filterList]);

  const sendGetDataFilter = (list: any) => {
    let filterResult: any = [];
    let copyRow: any = JSON.parse(JSON.stringify(list));

    copyRow.forEach((item: any) => {
      const idx = filterResult.findIndex((item2: any) => item2.column === item.where);
      const checkType = filterResult.find((item2: any) => {
        return item2.type === symbols.findIndex((el) => el === item.symbol);
      });
      if (idx === -1 || !checkType) {
        filterResult.push({
          column: item.where,
          value: [item.equal],
          type: symbols.findIndex((el) => el === item.symbol)
        });
      } else {
        filterResult[idx].column = item.where;
        filterResult[idx].type = (symbols.findIndex((el) => el === item.symbol));
        filterResult[idx].value.push(item.equal);
      }
    });
    props.setCurrentFilter(filterResult)
    props.sendFilters(filterResult);

    setTimeout(() => props.setPage(1), 700)

  };

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowFilter(true);
  };
  const handleCloseFilter = () => {
    setShowFilter(false);
  };
  const addRow = () => {
    let copyRow: any = JSON.parse(JSON.stringify(rows));
    copyRow.push({ where: '', equal: null, symbol: '=', equalList: [] });
    setRows(copyRow)
  };
  const deleteRow = async (index: number) => {
    let copyRow: any = JSON.parse(JSON.stringify(rows));

    sendDelRow(rows, index);

    copyRow.splice(index, 1);

    sendGetDataFilter(copyRow);
    updateHasAlready(copyRow);
    setRows(copyRow);
  };
  const sendDelRow = async (copyRow: any, index: number) => {
    if (copyRow[index].where.length) {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const res = await setFilter({
        filter: copyRow[index].where,
        value_filter: oldValue || String(copyRow[index].equal),
        organization_uid: uid,
        primary_data: searchParams.get("id") || '',
        del: true,
        type: symbols.findIndex((el) => el === copyRow[index].symbol)
      });
      if(oldValue !== null || copyRow[index].equal !== null) {
        setLenOfFilters(res.data.data)
      }

      setOldValue(null);
    }
  }
  const editWhere = async (e: any, index: number) => {
    let copyRow: any = JSON.parse(JSON.stringify(rows));
    const newValue = e.target.value;
    const colIdx = props.cols.findIndex(el => el.column_name === newValue);
    const newType = props.cols[colIdx].column_type;

    // if (copyRow[index].type === 'int')
      // if (!copyRow[index].equal?.length) return;

    await sendDelRow(rows, index);

    if (newType !== 'int') {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      const result = await getFilter({
        primary: props.primaryDataUploadUid,
        org: uid,
        column: newValue,
      });
      copyRow[index].equalList = result.data.data.result;
    }

    copyRow[index].where = newValue;
    copyRow[index].symbol = '=';
    copyRow[index].type = newType;
    copyRow[index].equal = null;

    if (newType !== 'int')
      copyRow[index].equal = null;
    else
      copyRow[index].equal = '';

    setRows(copyRow);
    updateHasAlready(copyRow);
    if (props.setFilterLoaded) props.setFilterLoaded(true);
    setOldValue(null);
  };
  const editSymbol = async (e: any, index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyRow: any = JSON.parse(JSON.stringify(rows));
    let copyHasAlready = JSON.parse(JSON.stringify(hasAlready));
    const newValue = e.target.value;

    copyRow[index].symbol = newValue;
    setHasAlready(copyHasAlready);
    setRows(copyRow);
    copyHasAlready[newValue] = true;

    if (typeof copyRow[index].equal !== 'string' && typeof copyRow[index].equal !== 'number') return;
    if (typeof copyRow[index].equal === 'number' && !copyRow[index].equal?.length) return;

    if (copyRow[index].type === 'int')
      if (!copyRow[index].equal?.length) return;

    await sendDelRow(rows, index);


    const res = await setFilter({
      filter: copyRow[index].where,
      type: symbols.findIndex((el) => el === copyRow[index].symbol),
      value_filter: copyRow[index].equal,
      organization_uid: uid,
      primary_data: searchParams.get("id") || '',
      del: false
    });
    setLenOfFilters(res.data.data)
    sendGetDataFilter(copyRow);
  };
  const editEqual = async (newValue: string, index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyRow: any = JSON.parse(JSON.stringify(rows));

    copyRow[index].equal = newValue;

    if (copyRow[index].type === 'int') {
      if (!newValue?.length) return;
      if (hasAlready[newValue]) return;
    }

    await sendDelRow(rows, index);

    const res = await setFilter({
      filter: copyRow[index].where,
      value_filter: String(newValue),
      organization_uid: uid,
      primary_data: searchParams.get("id") || '',
      del: false,
      type: symbols.findIndex((el) => el === copyRow[index].symbol)
    });
    setLenOfFilters(res.data.data)
    updateHasAlready(copyRow);
    setRows(copyRow);
    sendGetDataFilter(copyRow);
  };
  const setNewEditEqualValue = (e: any, index: number) => {
    let copyRow: any = JSON.parse(JSON.stringify(rows));
    const newValue = e.target.value;

    if (!oldValue) setOldValue(rows[index].equal);

    copyRow[index].equal = newValue;
    setRows(copyRow);
  };

  const cancelFilter = async () => {
    setLenOfFilters([])
    await setRows([
      { where: '', equal: null, symbol: '=', type: '', equalList: [] }
    ]);
    props.setCurrentFilter([])
    props.sendFilters([]);
    if (props.cancelFilter) props.cancelFilter();
  };

  const updateHasAlready = (list: any) => {
    let copyRow: any = JSON.parse(JSON.stringify(list));
    let copyHasAlready = JSON.parse(JSON.stringify({}));
    copyRow.forEach((item) => {
      if (item.equal)
        copyHasAlready[item.equal] = true;
    });
    // setHasAlready(copyHasAlready);
  };

  const StyledBadge = withStyles((theme) => ({
    badge: {
      color: '#fff',
      background: '#FF6C4B',
      right: -4,
      top: -10,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge);

  return (
    <>
      <span
        className={classes.toolbarFilterBtn}
        // onClick={() => setOpenFilters(true)}
        aria-controls={showFilter ? 'filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={showFilter ? 'true' : undefined}
        onClick={handleOpenFilter}
      >
        Фильтр
        <StyledBadge badgeContent={lenOfFilters.length}></StyledBadge>
      </span>
      <Menu
        id="filter-menu"
        className={classes.filterMenu}
        anchorEl={anchorEl}
        open={showFilter}
        onClose={handleCloseFilter}
      >
        <div className={classes.filterContet}>
          <div className={classes.filterHeader}>
            <h2 className={classes.filterTitle}>Фильтр</h2>
            <span onClick={handleCloseFilter}>
              <CloseIcon />
            </span>
          </div>
          <div className={classes.filterList}>
            <div className={classes.rowList}>
              {rows.map((item: any, index: number) => (
                <div className={classes.row} key={index}>
                  <span className={classes.rowLabel}>Где</span>
                  <Select
                    className={classes.rowSelectWhere}
                    value={item.where}
                    onChange={(e:any) => editWhere(e, index)}
                  >
                    {props.cols?.map((col: any, i: number) =>
                      <MenuItem value={col.column_name} key={i}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: col.column_caption
                          }}
                        />
                      </MenuItem>)}
                  </Select>
                  {/* <div className={classes.rowEqualSymb}>=</div> */}
                  <Select
                    className={classes.rowEqualSymb}
                    value={item.symbol}
                    onChange={(e: any) => editSymbol(e, index)}
                  >
                    {symbols.map((el: any, i: number) =>
                      <MenuItem
                        value={el}
                        key={i}
                        disabled={(item.type !== 'int') && (i === 1 || i === 2)}
                      >
                        {el}
                      </MenuItem>)}
                  </Select>
                  {(item.type !== 'int') ? (
                    <div className={classes.autocompleteWrap}>
                      <Autocomplete
                        disablePortal
                        noOptionsText="Список пуст"
                        id="combo-box-demo"
                        options={item.equalList.sort((a, b) => a > b ? 1 : -1)}
                        value={
                          item.equal === 'true'
                          ? 'Да'
                          : item.equal === 'false'
                            ? 'Нет'
                            : item.equal === ''
                              ? 'ПУСТОЕ'
                              : item.equal
                        }
                        onChange={(e: any, newValue: string) => editEqual(e.target.value || newValue, index)}
                        className={classes.autocomplete}
                        renderOption={(props: string, category) => (
                          <li
                            {...props}
                            className={
                              classNames(
                                classes.autocompleteListItem,
                                hasAlready[props] ? 'disabled' : null
                              )
                            }
                          >
                            {
                              props === 'true'
                                ? 'Да'
                                : props === 'false'
                                  ? 'Нет'
                                  : item.type === 'date'
                                    ? props ? moment(props).format('L') : null
                                    : props ? props : 'ПУСТОЕ'
                            }
                          </li>
                        )}
                        renderInput={(params) =>
                          <TextField {...params} className={classes.rowSelectEqual} label="" />
                        }
                      />
                    </div>
                  ) : (
                    <input
                      type="number"
                      placeholder="Только цифры"
                      className={classes.rowSelectEqual}
                      value={item.equal}
                      onChange={(e) => setNewEditEqualValue(e, index)}
                      onBlur={(e: any) => editEqual(e.target.value, index)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          editEqual(event.target.value, index);
                        }
                      }}
                    />
                  )}
                  <span className={classes.rowBtn} onClick={() => deleteRow(index)}>
                    <Trash />
                  </span>
                </div>
              ))}
            </div>
            <div className={classes.filterFooter}>
              <button className={classes.filterAddBtn} onClick={addRow}>
                <Plus /> Добавить фильтр
              </button>
              <button className={classNames(classes.filterAddBtn, classes.filterCancelBtn)} onClick={cancelFilter}>
                Сбросить
              </button>
            </div>
          </div>
        </div>
      </Menu>
    </>
  )
}
