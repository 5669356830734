import React, { useState } from "react";
import { makeStyles, createStyles, TextField } from '@material-ui/core';
import './index.css';
import moment from 'moment';
import CustomModal from "../../components/UI/CustomModal";
import { setMarks } from "../../api/reports";
import cookie from 'cookie';

const useStyles = makeStyles(() => 
  createStyles({
    wrapper: {
      padding: 32,
    },
    fields: {
      width: 360,
      height: 'auto'
    },
    mainFields: {
      background: '#F1FFF3',
      position: 'sticky',
      left: 0,
      zIndex: 8
    },
    mainFields1: {
      position: 'sticky',
      zIndex: 8,
      left: 0,
      padding:'10px 10px 21px 10px'
    },
    mainFields2: {
      position: 'sticky',
      zIndex: 8,
      width: 80,
      right: 0,
      background: '#F1FFF3',
    },
    gal: {
      display: 'flex',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      alignItems: 'center',
      fontSize: '20px'
    }
  }),
);

interface Props {
   table: any,
   setUpdate: (toggle: boolean) => void
 };

const TableAnalitycs = (props: Props) => {
   
   const changeFormatWeeks = (num: number) => {
      if(num === 1) {
         return 'ПН'
      }
      if(num === 2) {
         return 'ВТ'
      }
      if(num === 3) {
         return 'СР'
      }
      if(num === 4) {
         return 'ЧТ'
      }
      if(num === 5) {
         return 'ПТ'
      }
      if(num === 6) {
         return 'СБ'
      }
      if(num === 7) {
         return 'ВС'
      }
   }


   const totalResult = (key) => {
   const totalAmount = props.table.reduce(
      function (sum, currentAccount) {
        return sum + currentAccount[key]
      },
      0 
    )
    return totalAmount
   }
    
   /////////////////Для модалки
   const colors = [
      '#EB4034',
      '#E5EB34',
      '#DEDED7',
      '#43E846',
      '#43E8C4',
      '#1C6DC9',
      '#6643E6',
      '#BB43E6',
      '#E339C7',
      '#E3395B'
   ]

   const [toggle, setToggle] = useState<boolean>(false)
   const [color, setColor] = useState<string>('')
   const [text, setText] = useState<string>('')
   const [date, setDate] = useState<string>('')
   const [mark, setMark] = useState<number>(0)

   const clickMarker = (date: string, mark: number) => {
      setToggle(true)
      setDate(date)
      setMark(mark)
   }

   const saveMark = async() => {
      const org_uid = cookie.parse(document.cookie)['org_uid'];
      const res = await setMarks({
         color: color,
         date: date,
         del_: false,
         mark: mark,
         organization_uid: org_uid,
         text: text
      })
      if(res.data.success) {
         setColor('')
         setText('')
         setToggle(false)
         props.setUpdate(true)
      }
   }

    const classes = useStyles();
    return ( 
        <div>
          <CustomModal
               title="Изменение дополнительной метки"
               show={toggle}
               closeEvent={() => setToggle(false)}
               successEvent={saveMark}
               successText="Сохранить"
               cancelText="Отменить"
            >
           <br />
           <div style={{maxWidth: '250px', display: 'flex',flexWrap: 'wrap'}}>
            {colors.map((el: string, index: number) => (
               <div style={{background: el, width: 40, height: 40, margin: 5, cursor: 'pointer'}} onClick={(e) => setColor(colors[index])}>
                  {color === el &&
                  <div className={classes.gal}> &#10003;</div>
                  }
               </div>
            ))}
           
           </div>
           <br />
           <TextField
               id="standard-multiline-flexible"
               label="Название метки"
               multiline
               style={{margin: '30px 0'}}
               maxRows={4}
               value={text}
               onChange={(e) => setText(e.target.value)}
            />
      </CustomModal>
         {props.table &&
            <div className="table" style={{overflowX: 'auto', overflowY: 'hidden'}} id="table1">
             <tr>
                <th className={classes.mainFields1} style={{border: 'none', paddingBottom: 10}}>
                  <div style={{width: 360}}></div>
                </th>
                <td colSpan={props.table.length}>
                    <div style={{fontSize: 20,textAlign: 'center'}}>Дата</div>
                    <div style={{fontSize: 20,textAlign: 'center'}}>День недели</div>
                </td>
             </tr>
             
             <tr>
                <td className={classes.mainFields1} style={{border: 'none',
                display: 'flex',
                alignItems: 'flex-end',
                paddingBottom: '49px',
                background: 'white',
                fontSize: 20,
                textAlign: 'center'}}>
                  <div className={classes.fields}>ПАРАМЕТРЫ</div>
               </td>
                {props.table.map((el: any) => (
                  <td style={{fontSize: 20,textAlign: 'center', verticalAlign: 'middle'}}>
                     {moment(el.date_).format('L').slice(0, -5)}
                  </td>
               ))}
                <th className={classes.mainFields2} style={{padding: '10px 10px 90px 10px'}} rowSpan={2}>ИТОГО</th>
             </tr>


             <tr>
             <td className={classes.mainFields1} style={{width: 360, border: 'none', background: 'white'}}></td>
               {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : '', fontSize: 16,textAlign: 'center', verticalAlign: 'middle'}}>
                     {changeFormatWeeks(el.day_week)}
                  </td>
               ))}
             </tr>

            
             <tr>
                <td className={classes.mainFields}>
                  <div className={classes.fields}>остатки (шт)</div>
               </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.rest_wb_fbo}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('rest_wb_fbo')}
                  </div>
                </td>
             </tr>

             <tr>
                <td className={classes.mainFields}>
                  <div className={classes.fields}>спрос (заказы) (шт)</div>
               </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.ordered}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('ordered')}
                  </div>
                </td>
             </tr>

             <tr>
                 <td className={classes.mainFields}>
                 <div className={classes.fields}>средня цена заказа (₽)</div>
                 </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.order_amount}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('order_amount')}
                  </div>
                </td>
             </tr>

             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>кол-во изменений заказа к прошлому разу</div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.kol_ordel_last}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('kol_ordel_last')}
                  </div>
                </td>
             </tr>

             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>% изменения заказов к прошлому разу</div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.procent_ordel_last}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('procent_ordel_last')}
                  </div>
                </td>
             </tr>

             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>% изменения средней цены заказа к прошлому разу</div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.procent_price_last}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('procent_price_last')}
                  </div>
                </td>
             </tr>

             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>сумма изменений средней цены заказа к прошлому разу</div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.kol_price_last}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('kol_price_last')}
                  </div>
               </td>
             </tr>


             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>сумма изменения за день по средней цене заказа от прошлого дня</div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.sum_last_order_amound}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('sum_last_order_amound')}
                  </div>
                </td>
             </tr>


             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>доп метка 1</div>
                </td>
                {props.table.map((el: any) => (
                  <td onClick={() => clickMarker(el.date_, 1)}
                   style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : el.mark_1_color}}>
                     {el.mark_1}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('mark_1')}
                  </div>
                </td>
             </tr>


             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>доп метка 2</div>
                </td >
                {props.table.map((el: any) => (
                  <td onClick={() => clickMarker(el.date_, 2)} 
                  style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : el.mark_2_color}}>
                     {el.mark_2}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('mark_2')}
                  </div>
                </td>
             </tr>

             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>доп метка 3</div>
                </td>
                {props.table.map((el: any) => (
                  <td onClick={() => clickMarker(el.date_, 3)}
                  style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : el.mark_3_color}}>
                     {el.mark_3}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('mark_3')}
                  </div>
                </td>
             </tr>


             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>поступление товара</div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.receipts}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('receipts')}
                  </div>
                </td>
             </tr>

             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>сумма изменений за день по средней цене заказа от прошлого дня</div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.sum_last_order_amound}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('sum_last_order_amound')}
                  </div>
                </td>
             </tr>

             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}> % изменения количества заказов от даты начала фиксации результата</div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.procent_ordered}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('procent_ordered')}
                  </div>
                </td>
             </tr>


             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>% изменения средней цены заказа от даты начала фиксации результата</div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.procent_price}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('procent_price')}
                  </div>
                </td>
             </tr>


             <tr>
                <td className={classes.mainFields}>
                <div className={classes.fields}>сумма изменения на текущий день начиная от даты начала фиксации результата</div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.sum_order_amound_procent}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('sum_order_amound_procent')}
                  </div>
                </td>
             </tr>

             <tr>
                <td className={classes.mainFields}>
                  <div className={classes.fields}>
                     общая сумма изменения цены заказов накопительным итогом начиная от даты начала фиксации результата
                  </div>
                </td>
                {props.table.map((el: any) => (
                  <td style={{background: el.day_week === 6 || el.day_week === 7 ? '#EEEEEE' : ''}}>
                     {el.all_sum_order}
                  </td>
               ))}
               <td className={classes.mainFields2}>
                  <div>
                     {totalResult('all_sum_order')}
                  </div>
                </td>
             </tr>
             
            </div>
         }
        </div>
     );
}
 
export default TableAnalitycs;