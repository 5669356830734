import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// const baseURL = 'http://85.175.216.246:8086';

interface OrganisationReq {
  uid?: string;
  name: string;
  inn?: string;
  short_name?: string;
  type?: string;
  del: boolean;
}
interface GetOrgAllUsrReq {
  filter: Array<any>;
  // filter: [{
  //   "user_mobile": [
  //     "784521516",
  //     "79384262305"
  //   ]
  // }]
}
interface OrgStorage {
  uid?: string;
  organization_uid: string;
  name_storage?: string;
  adress_storage?: string;
  task: "check" | "create" | "change" | "delete";
}
interface UpdateOrgStorageTypeReq {
  org: string;
  action: 'update' | 'show';
  type?: number | 0 | 1 | 3;
}

export const getOrganizations = async () => {
  const urlAdres = await url()
  return await API.get(`${urlAdres.data.organizations}/organizations/getorg`);
};
export const setOrganizations = async (req: OrganisationReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/setorg`, req);
};
export const getOrganizationsUsers = async (org: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/getUsersOgr`, { org });
};
export const getFilter = async (column: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/getFilter`, { column });
};
export const userHist = async (id: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/user_hist`, { org: id });
};
export const organizationsHistory = async ({ id, path }: { id: string, path: string }) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/history`, { org: id, link: path });
};
export const organizationsStorage = async (req: OrgStorage) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/storage`, req);
};
export const updateOrgStorageType = async (req: UpdateOrgStorageTypeReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/updateOrgStorageType`, req);
};
export const dateTrunc = async (org: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/dateTrunc`, {org});
};
export const getAllFilter = async (org: string, primary_data: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/getAllFilter`, { org, primary_data });
};

interface DateilDateReq {
  org: string;
  date_1: string;
  date_2: string;
}
export const dateilDate = async (req: DateilDateReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/dateilDate`, req);
};
/* Admin */
export const getOrganizationsAllUsers = async (req: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/getAllOrg`, req);
};
export const setOrganizationsAdminOrg = async (org: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.organizations}/organizations/setAdminOrg`, { org });
};
/* Admin */
