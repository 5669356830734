import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
  fill?: string;
}

const UserProfile = (props: Props) => {
  return (
    <svg width={props.width || '40px'} height={props.height || '40px'} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill={props.fill || '#E9E9E7'} />
    <path d="M28 29V27C28 25.9391 27.5786 24.9217 26.8284 24.1716C26.0783 23.4214 25.0609 23 24 23H16C14.9391 23 13.9217 23.4214 13.1716 24.1716C12.4214 24.9217 12 25.9391 12 27V29" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20 19C22.2091 19 24 17.2091 24 15C24 12.7909 22.2091 11 20 11C17.7909 11 16 12.7909 16 15C16 17.2091 17.7909 19 20 19Z" stroke={props.color || '#D3D1CB'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default UserProfile;
