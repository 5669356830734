  export const getCellWidth = (el: any) => {
    switch (el.column_name) {
      case 'base_price': return 70;
      case 'collection': return 95;
      case 'category': return 270;
      case 'current_discount': return 70;
      case 'serial_number_card_one_article': return 145;
      case 'date_occurrence_illiquid': return 130;
      case 'id_wb': return 100;
      case 'illiquid': return 80;
      case 'manufacturers_article': return 180;
      case 'number_days_site': return 80;
      case 'product_color_code': return 180;
      case 'quantity_goods_set': return 105;
      case 'rest_wb_fbo': return 66;
      case 'scale_wb': return 110;
      case 'turnover': return 135;
      case 'supplier_article': return 300;
      case 'week_year': return 67.5;
      case 'day': return 135;
      case 'receipts': return 150;
      case 'bought_out': return 150;
      case 'enumeration': return 210;
      case 'ordered': return 110;
      case 'order_amount': return 180;
      case 'barcode': return 135;
      case 'storage': return 135;
      case 'remaining_wb_fbs': return 67.5;
      case 'name': return 405;
      case 'details': return 67.5;
      case 'article': return 360;
      case 'unrecorded_stock_balances': return 107.5;
      case 'good_way': return 67.5;
      case 'quantity': return 75;
      case 'take': return 67.5;
      case 'data_end': return 135;
      case 'fbs': return 67.5;
      case 'fbo_fbs': return 65;
      case 'fbs_commission_reduction': return 160;
      case 'fbo_commission_reduction': return 160;
      case 'logistics_penalty': return 100;
      case 'fine_fbs': return 140;
      case 'fine_fbo': return 140;
      case 'reduced_logistics': return 140;
      case 'nomenclature': return 120;
      case 'days_site': return 90;
      case 'remains': return 90;
      case 'planned_share_price': return 90;
      case 'retail_price': return 110;
      case 'downloadable_price': return 130;
      case 'discount_website': return 90;
      case 'downloadable_discount': return 135;
      case 'code_discount': return 105;
      case 'subject': return 270;
      case 'number': return 135;
      case 'number_deliveries': return 90;
      case 'item_code': return 135;
      case 'name_': return 405;
      case 'country': return 67.5;
      case 'rid': return 135;
      case 'size': return 67.5;
      case 'type_document': return 90;
      case 'wb_sold_the_goods': return 100;
      case 'final_agreed_discount': return 120;
      case 'regular_customer_discount': return 140;
      case 'kvv_size': return 100;
      case 'kvv_size_without_nds': return 140;
      case 'final_kvv': return 90;
      case 'comission_before_deductiong': return 202.5;
      case 'reimbursement': return 135;
      case 'wb_reward': return 135;
      case 'nds_wb_reward': return 140;
      case 'office_number': return 135;
      case 'delivery_office_name': return 270;
      case 'partner_inn': return 135;
      case 'reason_for_payment': return 135;
      case 'buyer_order_date': return 135;
      case 'sale_date': return 135;
      case 'barcode_wk': return 135;
      case 'retail_price_with_agreed_discount': return 175;
      case 'to_transferred': return 165;
      case 'delivery_number': return 95;
      case 'return_quantity': return 95;
      case 'delivery_services': return 140;
      case 'box_type': return 202.5;
      case 'agreed_product_discount': return 140;
      case 'promo_code': return 90;
      case 'number_customs_declaration': return 100;
      case 'justification_fine': return 405;
      case 'week_1_buyer_order_date': return 75;
      case 'week_1_sale_date': return 75;
      case 'week_2_buyer_order_date': return 75;
      case 'week_2_sale_date': return 75;
      case 'week_3_buyer_order_date': return 75;
      case 'week_3_sale_date': return 75;
      case 'week_4_buyer_order_date': return 75;
      case 'week_4_sale_date': return 75;
      case 'week_5_buyer_order_date': return 75;
      case 'week_5_sale_date': return 75;
      case 'week_6_buyer_order_date': return 75;
      case 'week_6_sale_date': return 75;
      case 'week_7_buyer_order_date': return 75;
      case 'week_7_sale_date': return 75;
      case 'week_8_buyer_order_date': return 75;
      case 'week_8_sale_date': return 75;
      case 'week_9_buyer_order_date': return 75;
      case 'week_9_sale_date': return 75;
      case 'week_10_buyer_order_date': return 75;
      case 'week_10_sale_date': return 75;
      case 'week_11_buyer_order_date': return 75;
      case 'week_11_sale_date': return 75;
      case 'avg_price': return 155;
      case 'delivery_time': return 145;
      case 'manufacturer_article': return 180;
      case 'max_price': return 110;
      case 'min_price': return 110;
      case 'min_stock': return 110;
      case 'product': return 405;
      case 'height': return 70;
      case 'length': return 70;
      case 'volume': return 95;
      case 'weight': return 90;
      case 'width': return 90;
      case 'price': return 67.5;
      case 'stocks_staus': return 67.5;
      case 'status': return 67.5;
      case 'ordered_0_1': return 145;
      case 'ordered_1': return 140;
      case 'ordered_1_2': return 140;
      case 'ordered_1_4': return 145;
      case 'ordered_1_8': return 145;
      case 'ordered_1_11': return 145;
      case 'ordered_5_8': return 160;
      case 'ordered_9_11': return 160;
      case 'actual_revenue_losses': return 90;
      case 'potential_revenue_loss': return 120;
      case 'lack_of_goods': return 55;
      case 'required_stocks': return 100;
      case 'quantity_': return 80;
      case 'number_change_points': return 190;
      case 'number_change_percent': return 135;
      case 'current_price_discount': return 150;
      case 'penalty_promotion': return 150;
      case 'penalty': return 150;
      case 'remains_sale': return 100;
      case 'remaining_fbs_fbo': return 105;
      case 'stocks_1': return 90;
      case 'stocks_2': return 90;
      case 'stocks_3': return 90;
      case 'stocks_4': return 90;
      case 'stocks_5': return 90;
      case 'stocks_6': return 90;
      case 'stocks_7': return 90;
      case 'stocks_8': return 90;
      case 'stocks_9': return 90;
      case 'stocks_10': return 90;
      case 'stocks_11': return 90;
      case 'stocks_12': return 90;
      case 'name_shares': return 160;
      case 'increased_spp': return 110;
      case 'standard_spp': return 110;
      default: return 270;
    }
  };
