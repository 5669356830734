import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import LoadDataTableHeader from './LoadDataTableHeader';
import LoadDataTableAccordion from './LoadDataTableAccordion';
import LoadDataTableBody from './LoadDataTableBody';
import { Pagination } from '@material-ui/lab';
import '../../../pages/ManagePrices/Pagination.css'

const useStyles = makeStyles(() =>
  createStyles({
    tableWrap: {
      overflowY: 'auto',
      width: 'fit-content',
      maxWidth: '100%',
      maxHeight: 'calc(100vh - 260.6px)',

      '& .MuiPaper-root': {
        backgroundColor: 'transparent !important',
      },
    },
    tableContainer: {
      boxShadow: 'none',
      borderTop: '1px solid #E9E9E7',
      borderBottom: '1px solid #E9E9E7',
      border: 'none !important',
      width: 'fit-content',

      '& .MuiTableContainer-root': {
        overflowX: 'hidden !important',
      },
    },
  })
);

interface Props {
  cols: any;
  data: any;
  param: string;
  groups: Array<string>;
  tableWidth: number;
  setNewData: (data: any, rowIndex: number, colName: string) => void;
  results: number;
  total: any;
  page: any;
  setPage: any;
  search: string
}

const useScrollAware = () => {
  const [scrollTop, setScrollTop] = React.useState(0);
  const ref: any = React.useRef();

  const onScroll = (e) =>
    requestAnimationFrame(() => {
      setScrollTop(e.target.scrollTop);
    });

  React.useEffect(() => {
    const scrollContainer: any = ref?.current;
    setScrollTop(scrollContainer?.scrollTop);
    scrollContainer?.addEventListener("scroll", onScroll);
    return () => scrollContainer?.removeEventListener("scroll", onScroll);
  }, []);

  return [scrollTop, ref];
};

const LoadDataTable = (props: Props) => {
  const classes = useStyles();

  // const [scrollTop, ref] = useScrollAware();
  const itemCount = props?.data?.length;
  const childHeight = 29;
  const heightTh = 58.5;
  const totalHeight = (props?.data?.length * childHeight) + heightTh;
  const renderAhread = 20;
  const height = childHeight * 18;
  const ref: any = React.useRef();


  return (
    <div ref={ref} className={classes.tableWrap}>
      <LoadDataTableHeader
        cols={props.cols}
        param={props.param}
        tableWidth={props.tableWidth}
      />
      {(!props.groups)
        ?
        <>
        <LoadDataTableAccordion
            cols={props.cols || []}
            data={props.data || []}
            search={props.search}
            param={props.param}
            groups={props.groups}
            tableWidth={props.tableWidth}
            setNewData={props.setNewData}
            total={props.total}
            page={props.page}
            setPage={props.setPage}
            results={props.results}
          />
        </> 
        : 
          props.results >= 500
          ?
          <div>
            {props.data.length > 0 ? null : <div style={{fontSize: 19}}>Данных на данной странице не найдено</div> }
              <LoadDataTableBody
              cols={props.cols || []}
              data={props.data || []}
              search={props.search}
              param={props.param}
              tableWidth={props.tableWidth}
              setNewData={props.setNewData}
            />
            <div style={{position: 'fixed', bottom: 0,right: 30, marginBottom: 10}}>
              <Pagination count={props.total} page={props.page} onChange={(_, num) => props.setPage(num)} shape="rounded"/>
            </div>
            
          </div>
          
          :
          <>
          {props.data.length > 0 ? null : <div style={{fontSize: 19}}>Данных на данной странице не найдено</div> }
            <LoadDataTableBody
            search={props.search}
            cols={props.cols || []}
            data={props.data || []}
            param={props.param}
            tableWidth={props.tableWidth}
            setNewData={props.setNewData}
            />
          </>
          
        }
    </div>
  )
}

export default React.memo(LoadDataTable);
