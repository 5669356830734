import React, {useEffect, useState} from 'react'
import {Button, ButtonGroup, Checkbox, createStyles, FormControlLabel, makeStyles} from "@material-ui/core";
import classNames from "classnames";
import {Check} from "@material-ui/icons";
import CustomModal from "../../UI/CustomModal";
import cookie from "cookie";
import {getData, setFilter} from "../../../api/reports";
import {useSearchParams} from "react-router-dom";
import { loadNewExcel } from '../../../api/wb-data';
import { calcSale, calcStocks } from '../../../api/calc';
import fileDownload from '../../../common/file-download';
import { getOrganizations } from '../../../api/organizations';
import * as XLSX from 'xlsx';
import moment from 'moment';

const useStyles = makeStyles(() =>
	createStyles({
		cellCheckbox: {
			fontFamily: '"Work Sans", sans-serif',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: 14,
			lineHeight: '16px',
			color: '#37352F',
			padding: 0,
			margin: '5px 0px',

			'& svg': {
				fill: '#37352F',
			},
			'& .MuiIconButton-root': {
				padding: 0,
			},
		},
		checkboxContainer: {
			display: 'flex',
			flexDirection: 'column',
			margin: '25px 0px'
		},
		container: {
			margin: '15px 0px'
		}
	})
)
type ExcelDialogType = {
	isOpenExcel: boolean
	closeModalExportToExcel: () => void
	filterList: any
	type: string
	dataFetch?: any
	colsFetch?: any
	loader?: any
	setSnackbarMessage?: any
	setShowSnackbar?: any
}


export const ExcelDialog = ({isOpenExcel, loader, setSnackbarMessage, setShowSnackbar, colsFetch, closeModalExportToExcel, filterList, type, dataFetch}: ExcelDialogType): JSX.Element => {
	const [toggleDisabled, setToggleDisabled] = useState(false)
	const [toggleCheckbox, setToggleCheckbox] = React.useState(false)
	const [filterType, setFilterType] = useState('')
	const [checkboxStateFirst, setCheckboxStateFirst] = React.useState({
		allTables: false,
		selectedWithFilters: false,
		selectedWithoutFilters: false,
})
	const [checkboxStateSecond, setCheckboxStateSecond] = React.useState({
		allTables: false,
		selectedRows: false,
	})
/*потом убрать этот мусор*/
	const [searchParams, setSearchParams] = useSearchParams();
	const [oldValue, setOldValue] = React.useState();
	const [symbols, setSymbols] = React.useState(['=', '>', '<', '!=']);
	const [lenOfFilters, setLenOfFilters] = React.useState([])

	const sendDelRow = async (copyRow: any, index: number) => {
		if (copyRow[index].where.length) {
			const uid = await cookie.parse(document.cookie)['org_uid'];
			const res = await setFilter({
				filter: copyRow[index].where,
				value_filter: oldValue || String(copyRow[index].equal),
				organization_uid: uid,
				primary_data: searchParams.get("id") || '',
				del: true,
				type: symbols.findIndex((el) => el === copyRow[index].symbol)
			});

			if(oldValue !== null || copyRow[index].equal !== null) {
				setLenOfFilters(res.data.data)
			}

			setOldValue(null);
		}
	}
	/*потом убрать этот мусор*/

	useEffect(() => {
		setFilterType('')
	}, []);

	const close = () => {
		setFilterType('')
		closeModalExportToExcel()
	}

	const saveParamsForCalculation = async() => {
		const cols = colsFetch.map((item: any) => {
			return item.column_name
		  })
		const date = moment().format('DD.MM.YYYY')
		let name = ''
		if(filterType === 'everything') name = 'Полная'
		if(filterType === 'selected_w/_filter') name = 'Без фильтра'
		if(filterType === 'selected_w/o_filter') name = 'Выб. столбцы'
		const resulut = await getOrganizations()
		const nameOrg = resulut.data.data[0].org_name

		let types = ''
		if(type === 'brand_category_product') types = 'Параметры БКТ'
		if(type === 'current_sales_week') types = 'Еженедельная динамика продаж'
		if(type === 'stocks') types = 'Текущие акции'

		if(type !== 'calc_stocks' && type !== 'calc_sale') {
			let data = {org: dataFetch.org,
				type: dataFetch.type,
				search: "",
				primary: dataFetch.primary,
				column: cols,
				filter:  [{}]}
			if(filterType === 'selected_w/_filter') {
				 data = {
					org: dataFetch.org,
					type: dataFetch.type,
					search: "",
					primary: dataFetch.primary,
					column: dataFetch.column,
					filter:  [{}]
				}
			}
			if(filterType === 'selected_w/o_filter') {
				data = {
				   org: dataFetch.org,
				   type: dataFetch.type,
				   search: "",
				   primary: dataFetch.primary,
				   column: dataFetch.column,
				   filter:  dataFetch.filter
			   }
		   }

		try {
			loader(true)
			const result = await getData( data, 0, 10000000);
			const res = await loadNewExcel({
				report_type: type,
				filter_type: filterType,
				excel_takeout: result.data.data.result,
				org: dataFetch.org,
				columns:  filterType === 'everything' ? cols : dataFetch.column
			})
			const workbook = XLSX.read(res.data.data, { type: 'base64' });
			let worksheets: any = {};

			for (const sheetName of workbook.SheetNames) {
			worksheets[sheetName] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
			};
			fileDownload(res.data.data, `PM, ${types}, ${name}, ${nameOrg ? nameOrg.replaceAll(/['"«»/\\?.,]/g, '').replaceAll(/[<]/g, '(М)').replaceAll(/[>]/g, '(Б)') : 'Без названия'}, (${worksheets.Лист1.length}шт) ${date}.xlsx`);
			setShowSnackbar(true);
      		setSnackbarMessage('Отчет загружен');
			console.log(res)
		} catch (res) {
			setShowSnackbar(true);
			setSnackbarMessage(res.data.description[0].message);
		} finally {
			loader(false)
		}

		}
		else if(type === 'calc_stocks') {
			let data = {org: dataFetch.org,
				search: "",
				primary: dataFetch.primary,
				colum: cols,
				filter:  [],
				stocks_type: dataFetch.stocks_type,
				type: 0,
				weeks_count: dataFetch.weeks_count,
				calculate_type: dataFetch.calculate_type
			}

				if(filterType === 'selected_w/_filter') {
					data = {
					   org: dataFetch.org,
					   search: "",
					   primary: dataFetch.primary,
					   colum: dataFetch.colum,
					   filter:  [],
					   stocks_type: dataFetch.stocks_type,
					   type: 0,
					   weeks_count: dataFetch.weeks_count,
					   calculate_type: dataFetch.calculate_type
				   }
			   }
			   if(filterType === 'selected_w/o_filter') {
				   data = {
					  org: dataFetch.org,
					  search: "",
					  primary: dataFetch.primary,
					  colum: dataFetch.colum,
					  filter:  dataFetch.filter,
					  stocks_type: dataFetch.stocks_type,
					  type: 0,
					  weeks_count: dataFetch.weeks_count,
					  calculate_type: dataFetch.calculate_type
				  }
			  }
			  try {
				loader(true)
				const result = await calcStocks(data, 0, 10000000)
				const res = await loadNewExcel({
				  report_type: type,
				  filter_type: filterType,
				  excel_takeout: result.data.data.data,
				  org: dataFetch.org,
				  columns:  filterType === 'everything' ? cols : dataFetch.colum
				})
				const workbook = XLSX.read(res.data.data, { type: 'base64' });
				let worksheets: any = {};

				for (const sheetName of workbook.SheetNames) {
				worksheets[sheetName] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
				};
				fileDownload(res.data.data, `PM, Расчёт по спросу и остаткам, ${name}, ${nameOrg ? nameOrg.replaceAll(/['"«»/\\?.,]/g, '').replaceAll(/[<]/g, '(М)').replaceAll(/[>]/g, '(Б)') : 'Без названия'}, (${worksheets.Лист1.length}шт) ${date}.xlsx`);
				console.log(res)
				setShowSnackbar(true);
      			setSnackbarMessage('Отчет загружен');
			  } catch (res) {
				setShowSnackbar(true);
				setSnackbarMessage(res.data.description[0].message);
			  } finally {
				loader(false)
			  }

		}
		else if(type === 'calc_sale') {
			let data = {
				org: dataFetch.org,
				colum: cols,
				filter:  [],
				max_sale: dataFetch.max_sale,
				max_sale_value: dataFetch.max_sale_value,
				rubles: dataFetch.rubles,
				rubles_procent: dataFetch.rubles_procent,
				search: '',
				type: 0,
			}

				if(filterType === 'selected_w/_filter') {
					data = {
						org: dataFetch.org,
						colum: dataFetch.colum,
						filter:  [],
						max_sale: dataFetch.max_sale,
						max_sale_value: dataFetch.max_sale_value,
						rubles: dataFetch.rubles,
						rubles_procent: dataFetch.rubles_procent,
						search: '',
						type: 0,
				   }
			   }
			   if(filterType === 'selected_w/o_filter') {
				   data = {
						org: dataFetch.org,
						colum: dataFetch.colum,
						filter:  dataFetch.filter,
						max_sale: dataFetch.max_sale,
						max_sale_value: dataFetch.max_sale_value,
						rubles: dataFetch.rubles,
						rubles_procent: dataFetch.rubles_procent,
						search: '',
						type: 0,
				  }
			  }
			  try {
				loader(true)
				const result = await calcSale(data, 0, 10000000)
				const res = await loadNewExcel({
				  report_type: type,
				  filter_type: filterType,
				  excel_takeout: result.data.data.data,
				  org: dataFetch.org,
				  columns:  filterType === 'everything' ? cols : dataFetch.colum
				})
				console.log(res)
				const workbook = XLSX.read(res.data.data, { type: 'base64' });
				let worksheets: any = {};

				for (const sheetName of workbook.SheetNames) {
				worksheets[sheetName] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
				};
				fileDownload(res.data.data, `PM, Расчёт по акциям, ${name}, ${nameOrg ? nameOrg.replaceAll(/['"«»/\\?.,]/g, '').replaceAll(/[<]/g, '(М)').replaceAll(/[>]/g, '(Б)') : 'Без названия'}, (${worksheets.Лист1.length}шт) ${date}.xlsx`);
				setShowSnackbar(true);
      			setSnackbarMessage('Отчет загружен');
			} catch (res) {
				setShowSnackbar(true);
				setSnackbarMessage(res.data.description[0].message);
			  } finally {
				loader(false)
			  }

		}
		close()
	}

	const changeCheckboxState = (type: string) => {
		setFilterType(type)
	}

	const classes = useStyles();
	return (
		<>
			<CustomModal
				title="Формирование в Excel"
				show={isOpenExcel}
				successText="Сохранить"
				cancelText="Отмена"
				disabledSuccessBtn={filterType ? false : true}
				successEvent={() => saveParamsForCalculation()}
				closeEvent={() => close()}
			>

				<div className={classes.container}>
					<span>Выберите условие</span>
					{filterList.length > 0 ?
						<div className={classes.checkboxContainer}>
							<FormControlLabel
								className={classes.cellCheckbox}
								control={
									<Checkbox
										checked={filterType === 'everything' ? true : false}
										color="default"
										// disabled={props.editable}
										onChange={() => {changeCheckboxState('everything')}}
									/>
								}
								label={'Таблицу полностью'}
							/>
							<FormControlLabel
								className={classes.cellCheckbox}
								control={
									<Checkbox
										color="default"
										checked={filterType === 'selected_w/_filter' ? true : false}
										onChange={() => {changeCheckboxState('selected_w/_filter')}}
									/>
								}
								label={'Выбранные столбцы без фильтров'}
							/>
							<FormControlLabel
								className={classes.cellCheckbox}
								control={
									<Checkbox
										color="default"
										// disabled={props.editable}
										checked={filterType === 'selected_w/o_filter' ? true : false}
										onChange={() => {changeCheckboxState('selected_w/o_filter')}}
									/>
								}
								label={'Выбранные столбцы с учетом фильтров'}
							/>
						</div>
						:
						<div className={classes.checkboxContainer}>
							<FormControlLabel
								className={classes.cellCheckbox}
								control={
									<Checkbox
										checked={filterType === 'everything' ? true : false}
										color="default"
										// disabled={props.editable}
										onChange={() => {changeCheckboxState('everything')}}
									/>
								}
								label={'Таблицу полностью'}
							/>
							<FormControlLabel
								className={classes.cellCheckbox}
								control={
									<Checkbox
										color="default"
										checked={filterType === 'selected_w/_filter' ? true : false}
										onChange={() => {changeCheckboxState('selected_w/_filter')}}
									/>
								}
								label={'Выбранные столбцы'}
							/>
						</div>
					}
				</div>
			</CustomModal>
		</>
	)
}
