import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import classNames from 'classnames';
import cookie from 'cookie';
import { useQuery } from 'react-query';

import { ChevronsLeft, User, WB, Dollar, Message, Server } from '../../components/UI/Icons';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { Plus } from '../../components/UI/Icons'
import { findTariff, showGreeting, TariffsType } from '../../api/tariffs';
import { getUsers } from '../../api/auth';
import CustomSnackbar from './CustomSnackbar';
import launch_Flatline from '../../assets/icons/Campaign launch_Flatline 2.png';
import CustomModal from './CustomModal';
import Spinner from './Spinner';
import './SnackBars.css'
import { useNewWb } from '../../pages/Admin/store';
import { getNewParam, setNewParam } from '../../api/admin';
import { getOrganizations } from '../../api/organizations';

const useStyles: any = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#e9e9e7',
      height: '100vh',
      width: '100%',
      maxWidth: '260px',
      minWidth: '260px',
      boxSizing: 'border-box',
      transition: 'all .2s ease-out',
    },
    logo: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      textDecoration: 'none',
    },
    rootActive: {
      width: '43px',
      minWidth: '43px',
      maxWidth: '43px',
    },
    sidebarHeader: {
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    justifyContentCenter: {
      justifyContent: 'center',
    },
    hideBtn: {
      cursor: 'pointer',
      transition: 'all .2s ease-out',

      '& svg': {
        stroke: '#37352F',
        transition: 'all .2s ease-out',
      },
      '&:hover svg': {
        // stroke: '#827F75',
      }
    },
    hideBtnActive: {
      display: 'flex',
      justifyContent: 'center',
      width: 43,
      transform: 'rotate(180deg)',
    },
    sidebarRate: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 16,
      paddingBottom: 17,
    },
    sidebarTariff: {
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
    },
    sidebarDemoBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      justifyContent: 'center',
      fontWeight: 800,
      fontSize: 14,
      lineHeight: 16,
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      borderRadius: 8,
      padding: '12px 12px',
      minWidth: '90px',
      // width: 53,
      height: 24,
      textTransform: 'capitalize',
      marginLeft: 4,
    },
    sidebarDemoBtnRed: {
      color: '#FF3317',
      background: '#FFB5AF',
      border: '1px solid #FF3317',
    },
    sidebarDemoBtnGreen: {
      color: '#000000',
      background: '#7DDB6D',
      border: '1px solid #5E863B',
    },
    sidebarNav: {
      position: 'relative',
      zIndex: 9,
      flexGrow: 1,
    },
    sidebarItem: {
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none !important',
      cursor: 'pointer',
      color: '#37352F',

      '& span': {
        paddingLeft: 10,
      },
      '& svg': {
        stroke: '#37352F',
      },
      '&.active svg': {
        stroke: '#fff !important',
      },
      '&.active': {
        color: '#ffffff',
        backgroundColor: '#37352f',
        cursor: 'default',
      },
    },
    sidebarFooter: {
      display: 'flex',
      justifyContent: 'center',
      padding: 16,
    },
    loadReport: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #37352F',
      boxSizing: 'border-box',
      borderRadius: 8,
      padding: '8px 10px',
      paddingRight: 16,
      cursor: 'pointer',
      transition: 'all .2s ease-out',
      textDecoration: 'none',

      '& svg': {
        stroke: '#37352F',
        transition: 'all .2s ease-out',
      },
      '&:hover': {
        color: '#D3D1CB',
        border: '1px solid #D3D1CB',
      },
      '&:hover svg': {
        stroke: '#D3D1CB',
      },
    },
    hide: {
      display: 'none',
    },
    successPayImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    successPayText: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: '#37352F',
    },
    actionBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    modalUpgradeBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'none',
      padding: '12px 16px',
      color: '#ffffff',
      background: '#187367',
      borderRadius: 8,
      '&:hover': {
        color: '#fff',
        background: '#209a8a',
      },
    },
    // switch: {
    //   display: 'inline-block',
    //   position: 'relative',
    //   width: 40,
    //   height: 20,
    //   backgroundColor: 'red'
    // },
    // switchInput: {
    //   width: 0,
    //   height: 0,
    //   opacity: 0,
    // },
    // switchSlider: {
    //   position: 'absolute',
    //   cursor: 'pointer',
    //   top: 0,
    //   left: 0,
    //   right: 0,
    //   bottom: 0,
    //   backgroundColor: 'green',


    //   '&::before': {
    //     content: null,
    //     position: 'absolute',
    //     cursor: 'pointer',
    //     left: '4px',
    //     bottom: '4px',
    //     height: 24,
    //     width: 24,
    //     backgroundColor: 'red',
    //   },
    // }
  })
);

export default function Sidebar() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [isAdm, setIsAdm] = useState(false);
  const [pathName, setPathName] = useState<any>({
    'Администрирование': { path: '/admin', icon: 'Server', isAdm },
    'Профиль': { path: '/profile', icon: 'User' },
    'Загрузка первичных данных': { path: '/load-data', icon: 'WB' },
    'Управление ценами': { path: '/manage-prices', icon: 'Dollar' },
    'Техническая поддержка': { path: '/support', icon: 'Message' },
    // 'Аналитика': { path: '/analitycs', icon: 'TrendingUpIcon' }
  });

  const [menu, setMenu] = useState<Array<string>>([]);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [buyedTariff, setBuyedTariff] = React.useState(false);
  const [path, setPath] = React.useState('');

  useEffect(() => {
    setPath(window.location.pathname);
    setSidebarStatus();
  }, [window.location.pathname]);

  const currentTariff = useQuery<TariffsType>('currentTariff', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const guid = await cookie.parse(document.cookie)['document.cookie'];

    // if (guid.length) {
    //   setIsAdm(true);
    // }

    const result = await findTariff(uid);

    return result.data.data;
  }, {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    retryOnMount: true
  });


  const setSidebarStatus = async () => {
    setShow(!Boolean(Number(await cookie.parse(document.cookie).sidebar)));
  };
  const showSidebar = () => {
    setShow(!show);
    document.cookie = `sidebar=${Number(show)};Path=/;`;
  };

  const greetingTariff = useQuery<Array<any>>('greatingTariff', async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await showGreeting(uid);
    if (result.data.data)
      setBuyedTariff(true);
    return result.data.data;
  });

  useQuery(['userPages', path], async (query: any) => {
    if (!query.queryKey[1].length) return;
    try {
      if (query.queryKey[1] !== '/login'
        && query.queryKey[1] !== '/verification'
        && query.queryKey[1] !== '/registration'
        && query.queryKey[1] !== '/reg-verification') {

        const result: any = await getUsers(query.queryKey[1]);
        const isAdmim = result?.data?.data?.[0]?.name_.filter(el => el.indexOf('Админ') > -1);

        if (isAdmim.length > 0) setIsAdm(true)
        if (isAdmim.length === 0) setIsAdm(false)

        const res = result.data.data[0].name_

        // res.push('Аналитика')

        if (result.data.data[0].name_)
          setMenu(res);
        return result.data.data;
      }
    } catch (error: any) {
      setSnackbarMessage(error?.data?.description?.[0]?.reason || 'Доступ к странице запрещен');
      setShowSnackbar(true);
      navigate('/profile', { replace: true });
    }
  });


  const dataStore = useNewWb()
  const [newPar, setNewPar] = useState<any>(null)
  const [orgUid, setOrgUid] = useState<string>('')


  const getUid = async () => {
    try {
      const result = await getOrganizations();
      const uid = result.data.data[0].org_uid
      setOrgUid(uid)
    } catch (error) {
      console.error(error)
    }
  }

  const getNewParams = async (uid) => {
    try {
      if (uid) {
        const res = await getNewParam({
          organization_uid: uid
        })

        localStorage.setItem('profileWb', res.data.parameter_new.new_);
        setNewPar(res.data.parameter_new.new_)
        dataStore.getToggleNew(res.data.parameter_new.new_ === 1 ? true : false)
        setToggleSwitch(res.data.parameter_new.new_ === 1 ? true : false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const setNewParams = async (newPar, uid) => {
    try {
      if (newPar !== null && uid) {
        await setNewParam({
          organization_uid: uid,
          parameter_new: newPar
        })
      }
    } catch (error) {
      console.error(error)
    }
  }


  const [toggleSwitch, setToggleSwitch] = useState<any>(null)


  const handleSwitch = (e) => {
    localStorage.setItem('profileWb', e.target.checked);
    const check = e.target.checked
    setNewPar(check ? 1 : 0)
    setToggleSwitch(check)

  }

  useEffect(() => {
    getUid()
    getNewParams(orgUid)
  }, [orgUid]);


  useEffect(() => {
    setNewParams(newPar, orgUid)
    dataStore.getToggleNew(toggleSwitch)
  }, [toggleSwitch, orgUid]);


  return (
    <div className={classNames(classes.root, show ? classes.rootActive : '')}>
      <div className={classNames(classes.sidebarHeader, show ? classes.justifyContentCenter : '')}>
        <NavLink
          to={isAdm ? '/admin' : '/profile'}
          className={
            classNames(classes.logo, show ? classes.hide : '')
          }
        >
          PriceMarket
        </NavLink>
        <span className={classNames(classes.hideBtn, show ? classes.hideBtnActive : '')} onClick={() => showSidebar()}>
          <ChevronsLeft />
        </span>
      </div>
      <div>
        {currentTariff.status === 'success' ?
          <div>
            <CustomModal
              title="Тариф оплачен"
              show={buyedTariff}
              closeEvent={() => setBuyedTariff(false)}
              successEvent={() => { }}
              successText="Сохранить"
              cancelText="Отмена"
              showSuccessBtn={false}
              showCancelBtn={false}
            >
              <div className={classes.successPayImage}>
                <img src={launch_Flatline} alt="" />
              </div>

              <p className={classes.successPayText}>
                Ваша оплата принята, тариф «{currentTariff?.data?.planName}» активирован. <br />
                Желаем приятной работы.
              </p>
            </CustomModal>

            {currentTariff?.data?.planName && (
              <div className={classNames(classes.sidebarRate, show ? classes.hide : '')}>
                <span className={classes.sidebarTariff}>Тариф</span>
                <NavLink to="/profile" className={classNames(classes.sidebarDemoBtn,
                  currentTariff?.data?.planName?.toLowerCase() === "базовый" ? classes.sidebarDemoBtnGreen : classes.sidebarDemoBtnRed)}>
                  {currentTariff?.data?.planName}
                </NavLink>
              </div>
            )}
            {!isAdm &&
              <div className={classNames(classes.sidebarRate, show ? classes.hide : '')}>
                <div className={classes.sidebarTariff} style={{ marginRight: 10 }}>Профиль WB</div>
                <label className='switch'>
                  <input type="checkbox" className='switchInput' checked={toggleSwitch} onChange={(e) => handleSwitch(e)} />
                  <span className='switchSlider'></span>
                </label>
                <div style={{ marginLeft: 10, color: toggleSwitch ? '#00D256' : '' }} className={classes.sidebarTariff}>NEW</div>
              </div>
            }
            <div className={classNames(classes.sidebarNav, show ? classes.hide : '')}>
              {menu.length > 0 && menu.map((item: any, index: number) => (
                <NavLink to={pathName[item].path || ''} className={classes.sidebarItem} key={index}>
                  {pathName[item].icon === 'Server' && <Server />}
                  {pathName[item].icon === 'User' && <User />}
                  {pathName[item].icon === 'WB' && <WB />}
                  {pathName[item].icon === 'Dollar' && <Dollar />}
                  {pathName[item].icon === 'Message' && <Message />}
                  {pathName[item].icon === 'TrendingUpIcon' && <TrendingUpIcon />}
                  <span>{item}</span>
                </NavLink>
              ))}
            </div>
            <div className={classNames(classes.sidebarFooter, show ? classes.hide : '')}>
              {/* <NavLink to="/load-data" className={classes.loadReport}>
              <Plus /> Загрузить отчет
            </NavLink> */}
            </div>
          </div>
          :
          <Spinner open={true} />
        }
      </div>



      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />


    </div>
  )
}
