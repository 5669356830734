import React, {useState, useEffect, useRef} from 'react'
import CloseIcon from '@material-ui/icons/Close';
import {
  makeStyles,
  createStyles,
  Dialog,
  Button,
} from '@material-ui/core';
import * as _ from 'lodash';
import DoublesDataTableHeader from './DoublesDataTableHeader';
import LoadDataTableBody from '../LoadData/LoadDataTable/LoadDataTableBody';
import LoadDataTableAccordion from '../LoadData/LoadDataTable/LoadDataTableAccordion';
import cookie from 'cookie';
import { downloadDuplicatesBKT, registerDuplicatesBKT } from '../../api/calc';
import Spinner from '../UI/Spinner';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      flexDirection: 'column',
    },
    modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '22px 38px',
      borderBottom: '1px solid #D3D1CB',
    },
    modalTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      margin: 0,
    },
    modalTitle2: {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '19px',
        display: 'flex',
        alignItems: 'center',
        color: '#37352F',
        marginTop: 20,
    },
    modalClose: {
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',

      '& svg': {
        width: 24,
        height: 24,
      }
    },
    modalContent: {
      padding: '32px 35px 16px 32px',
      display: 'flex',
      height: '100%',
      flexGrow: 1,
      overflowY: 'auto',
    },
    modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 'calc(100% - 64px)',
      padding: '10px 32px',
      borderTop: '1px solid #D3D1CB'
    },
    modalSaveBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      textTransform: 'capitalize',
      padding: '12px 16px',
      marginRight: 16,
      borderRadius: 8,
      '&:hover': {
        background: '#827F75 !important',
      },
    },
    modalCancelBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'capitalize',
      color: '#37352F',
      padding: '12px 16px',
      border: '1px solid #37352F',
      borderRadius: 8,
    },
    settingsMenu: {
      '& .MuiMenu-paper': {
        background: '#EBEBEB',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 8,
        top: '146px !important',

        transform: 'translateY(60px) !important',
        maxHeight: '70vh',
      },
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '14px 16px',
      width: 200,
      border: '1px solid grey',
      outline: 'none',
      borderRadius: 8,

    },
    settingsReportItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 15,
      color: '#37352F',
      padding: '7px 44px 7px 16px',
      cursor: 'pointer',
    },
    '& .MuiSnackbar-anchorOriginBottomCenter': {
      top: '50%',
      left: '50%',
      bottom: '50%',
      right: 'auto',
    },
    '& .MuiSnackbarContent-message': {
      color: "black"
    },
    '& .customSnackbar': {
      background: 'white'
    },
    tableWrap: {
        overflowY: 'auto',
        width: 'fit-content',
        maxWidth: '100%',
        maxHeight: 'calc(100vh - 260.6px)',

        '& .MuiPaper-root': {
          backgroundColor: 'transparent !important',
        },
    },
  })
)

interface Props {
  open: boolean;
  closeEvent: () => void;
  cols: any;
}


const DoublesModal = (props: Props) => {
  const classes = useStyles();
  const [doubles, setDouble] = useState<any>([])
  const [loader, setLoader] = useState(false)
  const [dataDoubles, setDataDoubles] = useState([])
  const [filteredElements, setFilteredElements] = React.useState(dataDoubles)
  const [selectedElements, setSelectedElements] = React.useState(0)
  //Это на акардион
  // const [groupParams, setGroupParams] = useState<any>();
  const navigate = useNavigate()

  const save = async () => {
    setLoader(true)
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const filter = doubles.filter(el => el.check)
    const res = filter.map(el => el.uid)

    await downloadDuplicatesBKT(uid, res)
    .then(()=> {
      navigate('/load-data')
      handleClose()})
    .catch((err) => console.log(err))
    .finally(() =>  setLoader(false))
  };


  const setDoubles = (data) => {
    const datas = doubles.map(el => {
      if(el.uid === data) {
        return {...el, check: !el.check}
      }
      else return el
    })
    let count = datas.filter(item => item.check)
    setSelectedElements(count.length)
    setDouble(datas)
  }

  const getCheck = async() => {
    setLoader(true)
    const uid = await cookie.parse(document.cookie)['org_uid'];
    await registerDuplicatesBKT(uid)
    .then(res => {
      const datas = res.data.data.map(el => {
        return {...el, check: false}
      })
      const sorts = datas.sort((a, b) => a.id_wb > b.id_wb ? 1 : -1)
      setDataDoubles(sorts)
      setFilteredElements(sorts)
      setDouble(sorts)

      //Это на акардион

      // let resultTable = datas.map((el, rowIndex) => {
      //   return {
      //     ...el,
      //     rowIndex
      //   }
      // });
      // let unique: any;
      // unique = resultTable
      //   .map((item: any) => item.brand)
      // unique = unique.filter((item: string, pos: number) => unique.indexOf(item) === pos);
      // setGroupParams(unique);
    })
    .catch((err) => console.log(err))
    .finally(() => setLoader(false))
  }

  useEffect(() => {
    getCheck()
    console.log('сколько раз я загружаюсь')
  }, []);

  const handleClose = () => {
    props.closeEvent();
    localStorage.setItem('checkModalDouble', 'false')
  };
  //
  // useEffect(() => {
  //   const debounce = setTimeout(() => filterData(search), 300)
  //   return () => clearTimeout(debounce)
  // }, [search, 500])

  function seacrhWithEnter(e) {
    if(e.key === 'Enter') {
      filterData(search1.current)
    }
  }

  function filterData(value) {
    // setLoader(true)
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setFilteredElements(dataDoubles);
    else {
      const filteredData = dataDoubles.filter(item => {
        return Object.keys(item).some(key => item[key] !== null ? item[key].toString().toLowerCase().includes(lowercasedValue) : false)
      })
      setFilteredElements(filteredData);
      console.log('dataDoubles', lowercasedValue, filteredData)
      // setLoader(false)
    }
  }

  const search1 = useRef('')
  function ControlledInput({ onUpdate }) {
    const [value1, setState1] = useState();
    const handleChange = e => {
      setState1(e.target.value);
      onUpdate(e.target.value);
    };
    return <input value={search1.current} placeholder={'Поиск'} className={classes.field} onChange={handleChange} onKeyDown={(event) => seacrhWithEnter(event)} onBlur={() => filterData(value1)}/>;
  }

  function changeCheckboxHandler(item: any) {
    console.log(item)
    let array = filteredElements.map(el => {
      if(item.uid === el.uid) {
        return {...el, check: !el.check}
      }
      return el
    })
    setFilteredElements(array)
  }

  console.log('filteredElements', filteredElements)
  return (
    <Dialog
      className={classes.modal}
      open={props.open}
      fullScreen
    >
    <Spinner open={loader} />
    {/* <CustomSnackbar
      open={showSnackbar}
      message={snackbarMessage}
      handleClose={() => setShowSnackbar(false)}
    /> */}
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
            <div style={{display: 'flex', alignItems: "baseline"}}>
                <h2 style={{marginRight: 5}} className={classes.modalTitle}>Реестр дублей Параметров БКТ</h2>
                <div style={{marginRight: 60}} className={classes.modalTitle2}>
                    Внимательно сравните выбранные позиции с актуальными.
                    Избегая дублей выберете верные позиции — они будут возвращены в Параметры БКТ, после чего реестр будет очищен
                </div>
              <ControlledInput onUpdate={(value) => search1.current = value} />
            </div>
          {/*<input*/}
          {/*  id="name"*/}
          {/*  className={classes.field}*/}
          {/*  type="name"*/}
          {/*  value={search}*/}
          {/*  onChange={(e) => setSearch(e.target.value)}*/}
          {/*  autoFocus*/}
          {/*  onBlur={() => filterData(search)}*/}
          {/*/>*/}

          <button className={classes.modalClose} onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={classes.modalContent}>
            <div className={classes.tableWrap}>
            <DoublesDataTableHeader
                cols={props.cols}
                tableWidth={0}
            />
            <LoadDataTableBody
              cols={props.cols || []}
              data={filteredElements || []}
              setDoubles={setDoubles}
              changeCheckboxHandler={changeCheckboxHandler}
            />
            {/* <LoadDataTableAccordion
              cols={props.cols || []}
              data={dataDoubles || []}
              groups={groupParams}
              setDoubles={setDoubles}
            /> */}
            </div>
        </div>


        <div className={classes.modalFooter}>
          <div className={classes.modalTitle}>Всего дублей: {dataDoubles.length}</div>
          <div className={classes.modalTitle}>Всего отображается: {filteredElements.length}</div>
          <div className={classes.modalTitle}>Всего выбранных: {selectedElements}</div>
          <div>
              <Button className={classes.modalSaveBtn} onClick={save}>
                  Сохранить
              </Button>
              <Button className={classes.modalCancelBtn} onClick={handleClose}>
                  Отмена
              </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default DoublesModal;
