import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
  fill?: string;
}

const IdeaIcon = (props: Props) => {
  return (
    // <svg width={props.width || '40px'} height={props.height || '40px'} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <circle cx="20" cy="20" r="20" fill={props.fill || '#E9E9E7'}/>
    //   <path d="M12 23C12 23 13 22 16 22C19 22 21 24 24 24C27 24 28 23 28 23V11C28 11 27 12 24 12C21 12 19 10 16 10C13 10 12 11 12 11V23Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M12 30V23" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>
    <svg width={props.width || '24px'} height={props.height || '24px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.375 22.3299H11.4541H12.2H12.9592H13.025H13.6705H14.6C15.5274 22.3299 16.29 21.5674 16.29 20.6399V15.8399C16.29 15.3603 16.4266 15.0349 16.6788 14.6797C16.8602 14.4244 17.1001 14.1753 17.3803 13.8845C17.507 13.7529 17.642 13.6128 17.7836 13.4596L17.7839 13.4593C18.6798 12.486 19.65 11.1047 19.65 8.63994C19.65 4.53062 16.3093 1.18994 12.2 1.18994C8.09068 1.18994 4.75 4.53062 4.75 8.63994C4.75 11.1447 5.7103 12.6445 6.61149 13.6791C6.79724 13.8928 6.98066 14.0895 7.14888 14.2698C7.18161 14.3049 7.21377 14.3394 7.24526 14.3733C7.44098 14.5838 7.60905 14.7689 7.74147 14.9419C8.00502 15.2863 8.11 15.5298 8.11 15.8399V20.6399C8.11 21.5674 8.87255 22.3299 9.8 22.3299H11.375ZM16.6963 12.4754L16.6959 12.4758C16.5934 12.5873 16.4866 12.6989 16.3782 12.8122C16.0759 13.1279 15.7611 13.4568 15.4915 13.8348C15.1134 14.3649 14.83 15.0277 14.83 15.8399V16.7499H9.57V15.8399C9.57 15.1159 9.25399 14.5285 8.88822 14.0526C8.66259 13.759 8.39961 13.4753 8.1393 13.1945C7.99242 13.036 7.84639 12.8785 7.7084 12.7206C7.31324 12.2675 6.94189 11.7777 6.66725 11.1402C6.39301 10.5037 6.21 9.70778 6.21 8.63994C6.21 5.32614 8.8862 2.64994 12.2 2.64994C15.5138 2.64994 18.19 5.32614 18.19 8.63994C18.19 9.68189 18.0086 10.4266 17.7376 11.012C17.4657 11.5993 17.0971 12.0413 16.6963 12.4754ZM10.2158 8.37568L10.203 8.37667L10.1903 8.37897C10.1626 8.38401 10.137 8.39067 10.1164 8.39658C9.84873 8.44975 9.63542 8.64768 9.55991 8.90774L9.55972 8.90842C9.48357 9.17353 9.56167 9.45665 9.7611 9.6446L11.47 11.3535V13.9799V14.2299H11.72H12.68H12.93V13.9799V11.3535L14.6418 9.64172C14.9307 9.35284 14.9307 8.88705 14.6418 8.59817C14.3529 8.30928 13.8871 8.30928 13.5982 8.59816L12.2 9.99639L10.8046 8.60103C10.6518 8.43891 10.4337 8.35892 10.2158 8.37568ZM14.83 18.2499V20.6399C14.83 20.7681 14.7282 20.8699 14.6 20.8699H10.8727C10.7934 20.8568 10.7116 20.8568 10.6323 20.8699H9.8C9.67182 20.8699 9.57 20.7681 9.57 20.6399V18.2499H14.83Z" fill="#37352F" stroke="#37352F" strokeWidth="0.5"/>
      <rect x="6" y="18" width="12" height="3" fill="white"/>
    </svg>
  )
}

export default IdeaIcon;
