import { makeStyles, createStyles, Button, Checkbox, FormControlLabel, Select, MenuItem, FormControl, Menu } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import classNames from 'classnames';
import cookie from 'cookie';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { dateilDate, dateTrunc, organizationsStorage } from '../../../api/organizations';
import { wbExcelPrimaryCheck, uploadExcelWbData, excelPrimarySaleWeekDetailing, SaleWeekDetailing, uploadExcelWbLeftovers } from '../../../api/wb-data';
import DataNew from '../../../context/context';
import CustomModal from '../../UI/CustomModal';
import CustomSnackbar from '../../UI/CustomSnackbar';
import { Upload } from '../../UI/Icons';
import Spinner from '../../UI/Spinner';
import LoadDataValueMatchingModal from '../ValueMatchingModal';
import SnackbarAll from '../../UI/SnackbarAll';
import { primaryDataSearchSale } from '../../../api/primary_data_search';

const useStyles = makeStyles(() =>
  createStyles({
    cardUploadBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      textTransform: 'capitalize',

      '&:hover': {
        background: '#827F75',
      },
    },
    settingsMenu: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 8,
        top: '58px !important',
        transform: 'translateY(60px) !important',
        maxHeight: '70vh',
      },
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    settingsMenu1: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 8,
        top: '120px !important',
        transform: 'translateY(60px) !important',
        maxHeight: '70vh',
      },
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    stockModalContent: {
      paddingTop: 32,
      paddingBottom: 16,
    },
    fieldBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      // marginTop: 32,
      marginBottom: 16,

      '& span': {
        color: '#FF6C4B',
      },
    },
    miniField: {
      maxWidth: 300,
    },
    fieldLabel: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    iconField: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      width: '100%',
      border: '1px solid #D3D1CB',
      borderRadius: 8,
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    checkIcon: {
      transition: 'all .1s ease-in-out',
      opacity: 0,
      transform: 'translateY(10px)'
    },
    checkIconActive: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    settingsReportItem: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: 10,
      cursor: 'pointer',
    },
    settingsReportItem1: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 17,
      lineHeight: '16px',
      color: '#37352F',
      padding: 10,
      cursor: 'pointer',
    },
    field: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      padding: '14px 16px',
      width: '100%',
      border: '1px solid transparent',
      outline: 'none',
      borderRadius: 8,
    },
    stockModalCheckbox: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 20,
      color: '#37352F',
      marginBottom: 16,

      '& svg': {
        fill: '#37352F',
      },
    },
    popover: {
      pointerEvents: 'none',

      '& .MuiPopover-paper': {
        fontFamily: '"Work Sans", sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: '#FFFFFF',
        borderRadius: 8,
        background: '#37352F',
        boxShadow: '-2px 3.5px 13px -3px rgba(113,114,171,0.3)',
        padding: 16,
      },
    },
    settingsReportContent: {
      padding: 8,
      minWidth: 185,
    },
    details: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#FFFFFF',
      minWidth: 112,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& .MuiSvgIcon-root': {
        width: 16, height: 16
      },
    },
    toolbarAddBtn: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: 'none',
      color: '#FFFFFF',
      background: '#37352F',
      borderRadius: 8,
      padding: '8px 16px 8px 10px',
      cursor: 'pointer',

      '& svg': {
        stroke: '#ffffff',
        marginRight: 4,
      },

      '&:hover': {
        background: '#827F75',
      },
    },
    loadDataModalInfoText: {},
    fbsModal: {
      paddingTop: 32,
      paddingBottom: 16,
    },
    rowLabel: {
      display: 'inline-block',
      width: '100%',
      marginBottom: 8,
    },
    rowSelect: {
      height: 30,
      width: '100%',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      borderRadius: 8,
      marginLeft: 0,

      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '& .MuiSelect-select': {
        paddingLeft: 10,
      },
    },
    detailDateList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    detailDateItem: {
      margin: 0,
      '& .MuiFormControlLabel-root': {
        margin: '0 !important',
      },
    },
    selectItem: {
      wordBreak: 'break-all',
      whiteSpace: 'break-spaces !important',
    }
  })
);

interface Props {
  primaryDataUploadUid?: string | undefined;
  hideIcon?: boolean;
  typeUid?: string | null | undefined;
  updateList?: () => void;
  newPrimaryDataUploadUid: (val) => void;
  storageType: number;
  loading: (val) => void;
  sensor?: boolean
};

export const ToolbarUpload = (props: Props) => {
  moment.locale('ru')
  const { funcOrange } = useContext(DataNew)
  const setOrange = funcOrange
  const classes = useStyles();
  const fileInput = React.useRef<any>(null);
  const [snackbar, setSnackbar] = React.useState(false)

  const [target, setTarget] = React.useState(false)
  const [anchorReport, setAnchorReport] = React.useState<null | HTMLElement>(null);
  const openReport = Boolean(anchorReport);

  const [searchParams, setSearchParams] = useSearchParams();
  const [showError, setShowError] = React.useState(false);
  const [showCompareModal, setShowCompareModal] = React.useState(false);
  const [showStockModal, setShowStockModal] = React.useState(false);
  const [showFBSModal, setShowFBSModal] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [primaryDataTypeName, setPrimaryDataTypeName] = React.useState('');

  const [selectedNameStock, setSelectedNameStock] = React.useState<any>();
  const [nameStock, setNameStock] = React.useState('');
  const [dateStock, setDateStock] = React.useState('');
  const [fbsTrade, setFbsTrade] = React.useState(false);
  const [fbsCommission, setFbsCommission] = React.useState('');
  const [fbsFine, setFbsFine] = React.useState('');
  const [fboCommission, setFboCommission] = React.useState('');
  const [fboFine, setFboFine] = React.useState('');
  const [logisticFine, setLogisticFine] = React.useState('');
  const [logisticDown, setLogisticDown] = React.useState('');
  const [currentTypeUid, setCurrentTypeUid] = React.useState<string | null | undefined>();

  const [base64, setBase64] = React.useState<string | null>();
  const [nameFile, setNameFile] = React.useState<string | null>();
  const [dateCreation, setDateCreation] = React.useState<string | null>();

  const [dateUpdate, setDateUpdate] = React.useState<string | null>();
  const [sizeFile, setSizeFile] = React.useState<number | null>();

  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [fileData, setFileData] = React.useState<string | null | undefined>(null);

  let [equalityList, setEqualityList] = React.useState([
    { id: 0, name: 'Бренд', equalityId: 0, equality: '' },
    { id: 1, name: 'Категория', equalityId: 0, equality: '' },
    { id: 2, name: 'Артикул поставщика', equalityId: 0, equality: '' },
    { id: 3, name: 'Артикул производителя', equalityId: 0, equality: '' },
  ]);
  const [importList, setImportList] = React.useState<any>([
    { equalityId: 0, equality: 'Значение 1' },
    { equalityId: 1, equality: 'Значение 2' },
    { equalityId: 2, equality: 'Значение 3' },
  ]);
  const [validateTrue, setValidateTrue] = React.useState([]);
  const [showDetailModal, setShowDetailModal] = React.useState(false);
  const [swapData, setSwapData] = React.useState<null | Array<any>>(null);

  const [selectedDetailDates, setSelectedDetailsDates] = React.useState<any>();
  const [detailDates, setDetailsDates] = React.useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        // transform: `translateY(${ITEM_HEIGHT / (selectedNameStock + 1)}px)`
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };


  useEffect(() => {
    setCurrentTypeUid(searchParams.get("id") || props.typeUid);
  }, [searchParams]);

  const dateTruncList = useQuery(['dateTruncList'], async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const dates = await dateTrunc(uid);
    return dates.data.data;
  });

  const orgList = useQuery(['orgList'], async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await organizationsStorage({
      organization_uid: uid,
      task: "check"
    });

    return result.data.data;
  });

  const handleClose = () => {
    setShowError(false);
  };
  const openCompareModal = () => {
    setShowCompareModal(true);
    handleClose();
  };
  const onFileChange = async (e: any) => {
    // await setLoader(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    setNameFile(file.name.replace(/[^a-zа-яё0-9\s.]/gi, ''));
    setDateCreation(`${moment(file.lastModifiedDate).format('L')} ${moment().format('LTS')}`);
    setDateUpdate(`${moment(file.lastModifiedDate).format('L')} ${moment().format('LTS')}`);
    setSizeFile(file.size);
    reader.onload = async () => {

      let result: any = reader.result;
      if (result) {
        try {
          let fileData = result.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', '');
          await setFileData(fileData)
          await setBase64(fileData);
          if (currentTypeUid === '960d6482-dc35-4834-871a-1ee8a26f1d02') {
            setShowStockModal(true);
            return;
            
          }
          // if (currentTypeUid === '94e066eb-3b01-4916-993c-73f06978145b') {
          //   setShowError(true);
          //   return;
            
          // }
          if (currentTypeUid === '974b957b-6df3-4811-9eaf-54c12898227f') {
            setShowFBSModal(true);
            return;
          }
          await uploadNewSwap(null, fileData, file);

        } catch (error) {
          console.error(error);
        } finally {
          // setLoader(false); убрано для установки непрерывного лоадера до полной закгрузки файла
          e.target.files = null
          e.target.value = null
        }
      }
    }

    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
    if (file) reader.readAsDataURL(file)
    else setLoader(false)
  };

  const checkFboFbsFields = () => {
    if (fbsTrade) {
      if (!String(fbsCommission)?.length || !String(fbsFine)?.length) {
        showMessage('Заполните все поля');
        setLoader(false);
        return false;
      }
    }
    if (!fbsTrade) {
      if (!String(fboCommission)?.length || !String(fboFine)?.length) {
        showMessage('Заполните все поля');
        setLoader(false);
        return false;
      }
    }

    return true;
  };

  const sendStockFile = async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result: any = await primaryDataSearchSale(uid);
    const names = result.data.data.map(el => el.name_shares.toUpperCase())
    if(names.includes(nameStock.toUpperCase())) {
      showMessage('Акция с таким названием уже существует');
    }
    else {
      setLoader(true);
      let dateEnd: Moment | null = null;

      if (!checkFboFbsFields()) return;

      if (dateStock?.length) {
        let currDate = moment(moment().format('YYYY-MM-DD'));
        if (dateEnd) {
          dateEnd = moment(dateStock);
          if (dateEnd.diff(currDate, 'days') < 0) {
            showMessage('Дата окончания акциии не может быть раньше текущей даты');
            setLoader(false);
            return;
          }
        }
      }
      // if (!nameStock?.length || !dateStock?.length || !logisticFine?.length || !logisticDown?.length) {
      if (!nameStock?.length || !dateStock?.length) {
        showMessage('Заполните все поля');
        setLoader(false);
        return;
      }

      await excelPrimaryCheck()
      .then(res => {
        setLoader(false);
        if (res.data.validate)
        uploadNewSwap(res.data.data.validateTrue);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoader(false))
    }
  };

  const clearFields = () => {
    setShowStockModal(false);
    setNameStock('');
    setDateStock('');
    setFbsTrade(false);
    setFbsCommission('');
    setFbsFine('');
    setFboCommission('');
    setFboFine('');
    setLogisticFine('');
    setLogisticDown('');
    setBase64(null);
    setNameFile(null);
    setDateCreation(null);
    setDateUpdate(null);
    setSizeFile(null);
    setShowFBSModal(false);
    setSelectedNameStock(null);
    setSwapData(null);
    setSelectedDetailsDates(null);
  };

  const uploadFile = (text: string) => {
    if(text === '2') setTarget(true)
    if(text === '1') setTarget(false)
    fileInput?.current?.click();
  };

  const closeCompareModal = () => {
    setEqualityList([]);
    setImportList([]);
    setShowCompareModal(false);
    clearFields();
  };

  const newSetNameStock = (value: string) => {
    if (props.storageType !== 0)
      if (value.includes('склад продавца'))
        setFbsTrade(true);
    setNameStock(value);
  };
  const uploadNewSwap = async (newSwap?: any, file?: string, fileDataS?: any) => {
    const dataOfFile = file ?? fileData
    setLoader(true);
    disableMinStockAndMinPrice();
    const uid = await cookie.parse(document.cookie)['org_uid'];
    // const check: any = await excelPrimaryCheck(newSwap, dataOfFile as string, fileDataS);

    // if (!newSwap && !swapData && check?.data?.data?.validateFalse) return;
    if (currentTypeUid === '7e8d9639-2419-47cd-aca1-0c8829528622') {
      const check: any = await excelPrimaryCheck(newSwap, dataOfFile as string, fileDataS);
      if (!newSwap && !swapData && check?.data?.data?.validateFalse) return;
      const detailin = await saleWeekDetailing({
        typeUid: props.typeUid || currentTypeUid || '',
        orgUid: uid,
        //
        content: dataOfFile || '',
        swap: newSwap || swapData || check.data.data.validateTrue
      }, dataOfFile, fileDataS);
      // if (!detailin || !detailin?.data?.success)
      // clearFields();
      return;
    }
    try {
      if (currentTypeUid === '960d6482-dc35-4834-871a-1ee8a26f1d02') {
          setLoader(true);
          const check: any = await excelPrimaryCheck(newSwap, dataOfFile as string, fileDataS);
          if (!newSwap && !swapData && check?.data?.data?.validateFalse) return;
          const uploaded = await uploadExcelWbData({
            typeUid: props.typeUid || currentTypeUid,
            orgUid: uid,
            swap: newSwap || swapData || check.data.data.validateTrue,
            //base64 ||
            content: dataOfFile || '',
            nameFile: nameFile || fileDataS?.name?.replace(/[^a-zа-яё0-9\s.]/gi, '') || '',
            dateCreation: dateCreation || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}`|| '',
            dateUpdate: dateUpdate || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
            size: sizeFile || fileDataS?.size || 0,
            list: !fbsTrade ? {
              name_shares: nameStock?.length ? nameStock : null,
              data_end: dateStock?.length ? dateStock : null,
              fbo_commission_reduction: fboCommission?.length ? fboCommission : null,
              fine_fbo: fboFine?.length ? fboFine : null,
              // logistics_penalty: logisticFine?.length ? logisticFine : null,
              // reduced_logistics: logisticDown?.length ? logisticDown : null,
              logistics_penalty: 0,
              reduced_logistics: 0,
              fbs: fbsTrade ? "true" : "false",
              rest_wb_fbo: takeAccountProducts,
            } : {
              name_shares: nameStock?.length ? nameStock : null,
              data_end: dateStock?.length ? dateStock : null,
              fbs_commission_reduction: fbsCommission?.length ? fbsCommission : null,
              fine_fbs: fbsFine?.length ? fbsFine : null,
              // logistics_penalty: logisticFine?.length ? logisticFine : null,
              // reduced_logistics: logisticDown?.length ? logisticDown : null,
              logistics_penalty: 0,
              reduced_logistics: 0,
              fbs: fbsTrade ? "true" : "false",
              rest_wb_fbo: takeAccountProducts,
            }
          });
          if (!uploaded.data.success) {

            showMessage(uploaded.data.description[0].reason);
            clearFields();
            setLoader(false);
            return
          }
          if (uploaded.data.success) {
            showMessage('Отчет загружен');
            clearFields();
            if (searchParams.get("id"))
              props.newPrimaryDataUploadUid(uploaded.data?.data?.primaryDataUploadHistoryUid);
            else if (props.updateList) props.updateList();
          }

          setLoader(false);
          return;
      }

      if (currentTypeUid === '974b957b-6df3-4811-9eaf-54c12898227f') {
        setLoader(true);
        const check: any = await excelPrimaryCheck(newSwap, dataOfFile as string, fileDataS);
        if (!newSwap && !swapData && check?.data?.data?.validateFalse) return;
        if (!selectedNameStock.name_storage) {
          showMessage('Выберите склад');
        }
        const uploaded = await uploadExcelWbData({
          typeUid: props.typeUid || currentTypeUid,
          orgUid: uid,
          swap: newSwap || swapData || check.data.data.validateTrue,
          //base64 ||
          content: dataOfFile || '',
          nameFile: nameFile || fileDataS?.name?.replace(/[^a-zа-яё0-9\s.]/gi, '') || '',
          dateCreation: dateCreation || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
          dateUpdate: dateUpdate || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
          size: sizeFile || fileDataS?.size || 0,
          list: {
            name_storage: selectedNameStock.name_storage
          }
        });

        if (!uploaded.data.success) {
          showMessage(uploaded.data.description[0].reason);
          clearFields();
          setLoader(false);
          return
        }
        if (uploaded.data.success) {
          showMessage('Отчет загружен');
          if (searchParams.get("id"))
            props.newPrimaryDataUploadUid(uploaded.data?.data?.primaryDataUploadHistoryUid);
          else
            if (props.updateList) props.updateList();
        }

        clearFields();
        setLoader(false);
        return;
      }


      if (currentTypeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081') {
        setLoader(true);
        const check: any = await excelPrimaryCheck(newSwap, dataOfFile as string, fileDataS);

        if (!newSwap && !swapData && check?.data?.data?.validateFalse) return;
        const uploaded = await uploadExcelWbData({
          typeUid: props.typeUid || currentTypeUid,
          orgUid: uid,
          swap: newSwap || swapData || check.data.data.validateTrue,
          //base64 ||
          content: dataOfFile || '',
          nameFile: nameFile || fileDataS?.name?.replace(/[^a-zа-яё0-9\s.]/gi, '') || '',
          dateCreation: dateCreation || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
          dateUpdate: dateUpdate || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
          size: sizeFile || fileDataS?.size || 0,
        });

        if (!uploaded.data.success) {
          showMessage(uploaded.data.description[0].reason);
          clearFields();
          setLoader(false);
          return
        }
        if (uploaded.data.success) {
          showMessage(uploaded.data.description[0].reason);
          if (searchParams.get("id"))
            props.newPrimaryDataUploadUid(uploaded.data?.data?.primaryDataUploadHistoryUid);
          else
            if (props.updateList) props.updateList();
        }

        clearFields();
        setLoader(false);
        return;
      }

      if(!target) {
      setLoader(true)
      const check: any = await excelPrimaryCheck(newSwap, dataOfFile as string, fileDataS);
      if (!newSwap && !swapData && check?.data?.data?.validateFalse) {
        return
      };
     
      const uploaded = await uploadExcelWbData({
        typeUid: props.typeUid || currentTypeUid,
        orgUid: uid,
        swap: newSwap || swapData || check.data.data.validateTrue,
        //base64 ||
        content: dataOfFile || '',
        nameFile: nameFile || fileDataS?.name?.replace(/[^a-zа-яё0-9\s.]/gi, '') || '',
        dateCreation: dateCreation || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
        dateUpdate: dateUpdate || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
        size: sizeFile || fileDataS?.size || 0,
      })
      .then((res) => {
        document.cookie = `orange = ${''}`;
        localStorage.setItem('weeks', '');
        localStorage.setItem('weeksDop', 'false');
        // setOrange([])
        if (!res.data.success) {
          ////////////////////
          showMessage(res.data.description[0].reason);
          clearFields();
          setLoader(false);
          return
        }
        if (res.data.success) {
          showMessage(res.status === 201 ?res.data.description[0].message : 'Отчет загружен');
          clearFields();
          if (searchParams.get("id"))
            props.newPrimaryDataUploadUid(res.data?.data?.primaryDataUploadHistoryUid);
          else if(res.status === 201)
          setTimeout(() => {
        setShowSnackbar(false)
        setSnackbar(true)
      }, 3000)

          else
            if (props.updateList) props.updateList();
        }

      })
      .catch((err) => {
        if(err.data.description[0].message.length) {
          console.log('тут ошибка')
        }
      })
      .finally(()=> {
        setLoader(false);
      })
      }
      if(target) {
        setLoader(true)
        const check1: any = await excelPrimaryCheck(newSwap, dataOfFile as string, fileDataS, '749fb88d-40db-4c39-a483-1051bfc5bb51');
        if(newSwap) {
          const uploaded = await uploadExcelWbLeftovers({
            typeUid: '749fb88d-40db-4c39-a483-1051bfc5bb51',
            orgUid: uid,
            swap: newSwap || swapData || check1.data.data.validateTrue,
            //base64 ||
            content: dataOfFile || '',
            nameFile: nameFile || fileDataS?.name?.replace(/[^a-zа-яё0-9\s.]/gi, '') || '',
            dateCreation: dateCreation || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
            dateUpdate: dateUpdate || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
            size: sizeFile || fileDataS?.size || 0,
          })
          .then((res) => {
            document.cookie = `orange = ${''}`;
            localStorage.setItem('weeks', '');
            localStorage.setItem('weeksDop', 'false');
            // setOrange([])
            if (!res.data.success) {

              ////////////////////
              showMessage(res.data.description[0].reason);
              clearFields();
              setLoader(false);
              return
            }
            if (res.data.success) {
              showMessage('Отчет загружен');
              clearFields();
              if (searchParams.get("id"))
                props.newPrimaryDataUploadUid(res.data?.data?.primaryDataUploadHistoryUid);
              else
                if (props.updateList) props.updateList();
            }

          })
          .catch((err) => {
            if(err.data.description[0].message.length) {
              showMessage('Произошла ошибка, что-то пошло не так');
            }
          })
          .finally(()=> {
            setLoader(false);
          })
        }
      }
    } catch (error) {
      if(error.data.description[0].message && currentTypeUid === '960d6482-dc35-4834-871a-1ee8a26f1d02') {
        showMessage(error.data.description[0].message);
      }
      setLoader(false);
      clearFields();
    }
    finally {
      setLoader(false);
    }
  };

  const handleCloseReport = () => {
    setAnchorReport(null);
  };

  const handleClickReportBtn = async (event: React.MouseEvent<HTMLButtonElement>, toggle: boolean) => {
    setAnchorReport(event.currentTarget);
  };


  const disableMinStockAndMinPrice = () => {
    if (currentTypeUid === '0a5956f9-a9f6-49c0-8080-bb1fda1251a7')
      document.cookie = `save=0;max-age=172800;`;
    if (currentTypeUid === '0a5956f9-a9f6-49c0-8080-bb1fda1251a7'
      || currentTypeUid === '960d6482-dc35-4834-871a-1ee8a26f1d02') {
      document.cookie = `min_stock=false;max-age=172800;path=/manage-prices;`;
      document.cookie = `min_price=false;max-age=172800;path=/manage-prices;`;
    }
  };

  const excelPrimaryCheck = async (newSwap?: any, file?: string, fileDataS?: any, type?: string) => {

    const dataOfFile = file ?? fileData
    const uid = await cookie.parse(document.cookie)['org_uid'];
    // setLoader(true);
    try {
      const check: any = await wbExcelPrimaryCheck({
        typeUid: type ? type : props.typeUid || currentTypeUid || '',
        //base64 ||
        content: dataOfFile || '',
        orgUid: uid || '',
        nameFile: nameFile || fileDataS.name.replace(/[^a-zа-яё0-9\s.]/gi, '') || '',
        dateCreation: dateCreation || `${moment(fileDataS?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
        dateUpdate: dateUpdate || `${moment(fileDataS.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
        size: sizeFile || fileDataS.size || 0
      });

      if (!check.data.success) {
        showMessage(check.data.description[0].reason);
        // setLoader(false)
        clearFields();
        return;
      }
      if(!check.data.data.validateTrue) setValidateTrue([])
      if (check.data.data.validateTrue) setValidateTrue(check.data.data.validateTrue);
      if (!check.data.validate && !newSwap) {
        const dbColumn = check.data.data.validateFalse.dbColumn;

        const excelColumn = check.data.data.validateFalse.excel;
        setEqualityList(dbColumn.map((item: any, index: number) => {
          return {
            id: item.id,
            name: item.name,
            equalityId: 0,
            equality: '',
            default: item.default ? item.default : false,
            disabled: false
          };
        }));
        setImportList(excelColumn.map((item: any, index: number) => {
          return { equalityId: index, equality: item };
        }));
        setFileData(file)
        setShowError(true);
      }
      else {
        setBase64(file);
        setSwapData(check?.data?.data?.validateTrue?.length ? check.data.data.validateTrue : newSwap);
      }
      return check;
    } catch (error) {
      showMessage(error.message);
      closeCompareModal();
    } finally {
      // setLoader(false);
    }
  };

  const sendDetailWeeks = async (newSwap?: any, file?: any, fileData?: any) => {
    setLoader(true);
    try {
      const uid = await cookie.parse(document.cookie)['org_uid'];

      if (selectedDetailDates) {
        await dateilDate({
          org: uid,
          date_1: selectedDetailDates?.from,
          date_2: selectedDetailDates?.to,
        });
      }

      const uploaded = await uploadExcelWbData({
        typeUid: props.typeUid || currentTypeUid,
        orgUid: uid,
        swap: newSwap || swapData,
        //base64 ||
        content: base64 || file || '',
        nameFile: nameFile || fileData.name.replace(/[^a-zа-яё0-9\s.]/gi, '') || '',
        dateCreation: dateCreation || `${moment(fileData?.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
        dateUpdate: dateUpdate || `${moment(fileData.lastModifiedDate).format('L')} ${moment().format('LTS')}` || '',
        size: sizeFile || fileData.size || 0,
      });
      if (!uploaded.data.success) {
        showMessage(uploaded.data.description[0].reason);
        clearFields();
        setLoader(false);
        return
      }
      if (uploaded.data.success) {
        showMessage('Отчет загружен');
        if (searchParams.get("id"))
          props.newPrimaryDataUploadUid(uploaded.data?.data?.primaryDataUploadHistoryUid);
        else
          if (props.updateList) props.updateList();
      }
      clearFields();
    } catch (error) {
      if (error?.data?.description?.[0]?.reason) {
        showMessage(error.data.description[0].reason);
      }
    } finally {
      setLoader(false);
      setShowDetailModal(false);
    }
  };

  const saleWeekDetailing = async (req: SaleWeekDetailing, file?: any, fileData?: any) => {
    setLoader(true);
    setSwapData(req.swap);
    const result = await excelPrimarySaleWeekDetailing(req);
    if (!result.data.success) {
      showMessage(result.data.description?.at(0)?.reason)
      setLoader(false);
      // sendDetailWeeks(req.swap, file, fileData); //TODO: Proverit' etu h**tu
      return;
    }
    let dateTrunc = result.data.data;


    const noPeriod = dateTrunc.some(el => {
      if (dateTruncList.data?.[10].date_1 > el.from
        && dateTruncList.data?.[10].date_2 > el.to)
        return true;
    });
    if (dateTrunc?.length > 1)
      dateTrunc.push({ from: "2001-01-01", to: "2001-01-01" });
    let newData = dateTrunc.map(el => {
      return {
        ...el,
        sec: new Date(el.to).getTime(),
        checked: true,
      }
    })
    const sortData = newData.sort((a, b) => b.sec - a.sec)

    setDetailsDates(sortData);
    if (dateTrunc?.length === 1) {
      setDetailsDates(dateTrunc);
      const uid = await cookie.parse(document.cookie)['org_uid'];

      await dateilDate({
        org: uid,
        date_1: dateTrunc[0]?.from,
        date_2: dateTrunc[0]?.to,
      });
      sendDetailWeeks(req.swap, file, fileData);
      return;
    }
    setShowDetailModal(true);
    setLoader(false);
    return result.data.data;
  };

  const uploadReport = () => {

  };

  const handleChangeDate = (e: any) => {
    setSelectedDetailsDates(e.target.value);
  };

  const editSelectedStock = (e: any) => {
    setSelectedNameStock(e.target.value);
  };

  const showMessage = (msg: string) => {
    if (!msg?.length) return;
    setSnackbarMessage(msg);
    setShowSnackbar(true);
  };

  const closePeriodModal = () => {
    clearFields();
    setShowDetailModal(false);
  };

  const [takeAccountProducts, setTakeAccountProducts] = React.useState(false)

  return (
    <>
      <Spinner open={loader} />
      <div style={{display: 'flex'}}>
      <SnackbarAll
        open={snackbar}
        closeEvent={() => setSnackbar(false)}
      />
      <CustomSnackbar
        open={showSnackbar}
        message={snackbarMessage}
        handleClose={() => setShowSnackbar(false)}
      />
      </div>
      <CustomModal
        show={showFBSModal}
        title="Загрузка FBS"
        closeEvent={() => setShowFBSModal(false)}
        successEvent={() => uploadNewSwap()}
        successText="Загрузить"
        cancelText="Отмена"
        disabledSuccessBtn={!(!!selectedNameStock)}
      >
        <div className={classes.fbsModal}>
          <span className={classes.rowLabel}>Выберите склад</span>
          <FormControl fullWidth>
            <Select
              className={classes.rowSelect}
              value={selectedNameStock}
              onChange={(e: any) => editSelectedStock(e)}
              MenuProps={MenuProps}
            >
              {
                orgList?.data?.map((org: any, i: number) =>
                  <MenuItem className={classes.selectItem} value={org} key={i}>
                    {org.name_storage}
                  </MenuItem>)
              }
            </Select>
          </FormControl>
        </div>
      </CustomModal>
      <CustomModal
        show={showDetailModal && currentTypeUid === '7e8d9639-2419-47cd-aca1-0c8829528622'}
        title="Запрос периода"
        closeEvent={() => closePeriodModal()}
        successEvent={() => sendDetailWeeks()}
        successText="Загрузить"
        cancelText="Отмена"
        disabledSuccessBtn={!selectedDetailDates}
      >
        <div className={classes.fbsModal}>
          <Select
            className={classes.rowSelect}
            value={selectedDetailDates}
            onChange={(e: any) => handleChangeDate(e)}
          >
            {detailDates?.map((el: any, i: number) =>
              <MenuItem value={el} key={i}>
                {el.from !== '2001-01-01' && el.to !== '2001-01-01'
                  ? `${moment(el.from).format('L')} - ${moment(el.to).format('L')}`
                  : 'Нет периода'
                }
              </MenuItem>)
            }
          </Select>
        </div>
      </CustomModal>
      <CustomModal
        show={showStockModal}
        title="Загрузка текущей акции"
        closeEvent={() => setShowStockModal(false)}
        successEvent={() => sendStockFile()}
        successText="Загрузить"
        cancelText="Отмена"
      >
        <div className={classes.stockModalContent}>
          <div className={classes.fieldBlock}>
            <label htmlFor="name" className={classes.fieldLabel}>Название акции <span>*</span></label>
            <div className={classes.iconField}>
              <input
                id="name"
                className={classes.field}
                value={nameStock}
                onChange={(e) => newSetNameStock(e.target.value)}
              />
              <Check className={classNames(classes.checkIcon, nameStock?.length > 0 ? classes.checkIconActive : '')} />
            </div>
          </div>
          <div className={classNames(classes.fieldBlock, classes.miniField)}>
            <label htmlFor="name" className={classes.fieldLabel}>Дата окончания <span>*</span></label>
            <div className={classes.iconField} style={{ paddingRight: 0 }}>
              <input
                id="name"
                className={classes.field}
                type="date"
                value={dateStock}
                onClick={(e: any) => e.target.showPicker()}
                onChange={(e) => setDateStock(e.target.value)}
              />
              {/* <Check className={classNames(classes.checkIcon, dateStock?.length > 0 ? classes.checkIconActive : '')} /> */}
            </div>
          </div>
          {/* {props.storageType !== 0
            ? <FormControlLabel
                className={classes.stockModalCheckbox}
                control={
                  <Checkbox defaultChecked color="default" checked={fbsTrade} />
                }
                label="торговля по системе wb fbs"
                checked={fbsTrade}
                onChange={(e: any) => setFbsTrade(e.target.checked)}
              />
            : null} */}
          {fbsTrade ? (
            <div className={classNames(classes.fieldBlock, classes.miniField)}>
              <label htmlFor="name" className={classes.fieldLabel}>Снижение комиссии <b>wb fbs</b> при участии в акции, % <span>*</span></label>
              <div className={classes.iconField}>
                <input
                  min={0}
                  type="number"
                  placeholder="Только цифры"
                  className={classes.field}
                  value={fbsCommission}
                  onChange={(e) => Number(e.target.value) > -1 ? setFbsCommission(e.target.value) : null}
                />
                <Check className={classNames(classes.checkIcon, fbsCommission?.length > 0 ? classes.checkIconActive : '')} />
              </div>
            </div>
          ) : (
            <div className={classNames(classes.fieldBlock, classes.miniField)}>
              <label htmlFor="name" className={classes.fieldLabel}>Снижение комиссии <b>wb</b> при участии в акции, % <span>*</span></label>
              <div className={classes.iconField}>
                <input
                  min={0}
                  type="number"
                  placeholder="Только цифры"
                  className={classes.field}
                  value={fboCommission}
                  onChange={(e) => Number(e.target.value) > -1 ? setFboCommission(e.target.value) : null}
                />
                <Check className={classNames(classes.checkIcon, fboCommission?.length > 0 ? classes.checkIconActive : '')} />
              </div>
            </div>
          )}
          {fbsTrade ? (
            <div className={classNames(classes.fieldBlock, classes.miniField)}>
              <label htmlFor="name" className={classes.fieldLabel}>Штраф (доп комиссия <b>wb</b>) при отказе от акции, % <span>*</span></label>
              <div className={classes.iconField}>
                <input
                  min={0}
                  type="number"
                  placeholder="Только цифры"
                  className={classes.field}
                  value={fbsFine}
                  onChange={(e) => Number(e.target.value) > -1 ? setFbsFine(e.target.value) : null}
                />
                <Check className={classNames(classes.checkIcon, fbsFine?.length > 0 ? classes.checkIconActive : '')} />
              </div>
            </div>
          ) : (
            <div className={classNames(classes.fieldBlock, classes.miniField)}>
              <label htmlFor="name" className={classes.fieldLabel}>Штраф (доп комиссия <b>wb</b>) при отказе от акции, % <span>*</span></label>
              <div className={classes.iconField}>
                <input
                  min={0}
                  type="number"
                  placeholder="Только цифры"
                  className={classes.field}
                  value={fboFine}
                  onChange={(e) => Number(e.target.value) > -1 ? setFboFine(e.target.value) : null}
                />
                <Check className={classNames(classes.checkIcon, fboFine?.length > 0 ? classes.checkIconActive : '')} />
              </div>
            </div>
          )}
          {/* <div className={classNames(classes.fieldBlock, classes.miniField)}>
            <label htmlFor="name" className={classes.fieldLabel}>Штраф на логистику при отказе, ₽ <span>*</span></label>
            <div className={classes.iconField}>
              <input
                min={0}
                type="number"
                placeholder="Только цифры"
                className={classes.field}
                value={logisticFine}
                onChange={(e) => Number(e.target.value) > -1 ? setLogisticFine(e.target.value) : null}
              />
              <Check className={classNames(classes.checkIcon, logisticFine?.length > 0 ? classes.checkIconActive : '')} />
            </div>
          </div> */}
          {/* <div className={classNames(classes.fieldBlock, classes.miniField)}>
            <label htmlFor="name" className={classes.fieldLabel}>Снижение логистики при участии в акции, % <span>*</span></label>
            <div className={classes.iconField}>
              <input
                min={0}
                type="number"
                placeholder="Только цифры"
                className={classes.field}
                value={logisticDown}
                onChange={(e) => Number(e.target.value) > -1 ? setLogisticDown(e.target.value) : null}
              />
              <Check className={classNames(classes.checkIcon, logisticDown?.length > 0 ? classes.checkIconActive : '')} />
            </div>
          </div> */}

          <FormControlLabel
            className={classes.cellCheckbox && 'vid'}
            control={
              <Checkbox
                color="default"
                disabled={props.editable}
              />
            }
            checked={takeAccountProducts}
            onChange={(event) => setTakeAccountProducts(event.target.checked)}
            label={'Учитывать товары из данной акции в общем количестве и проценте товаров, участвующих в акции'}
          />
        </div>
      </CustomModal>
      <LoadDataValueMatchingModal
        open={showCompareModal}
        fileData={fileData}
        base64={base64 || ''}
        primaryDataTypeName={primaryDataTypeName}
        currentTypeUid={currentTypeUid}
        validateTrue={validateTrue}
        equalityList={equalityList}
        importList={importList}
        saveEvent={(newSwap: any, file: any) => uploadNewSwap(newSwap, file)}
        closeModal={() => closeCompareModal()}
      />
      <CustomModal
        show={showError}
        successEvent={() => openCompareModal()}
        closeEvent={() => handleClose()}
        successText="Сопоставить значения"
        cancelText="Отмена"
      >
        <p className={classes.loadDataModalInfoText}>
          Не удалось загрузить файл. Одно или несколько полей не совпадают. <br />
          Вы можете сопоставить значения и продолжить работу.
        </p>
      </CustomModal>
      <input
        ref={fileInput}
        type="file"
        name="file"
        id="file"
        style={{ display: 'none', }}
        accept=".xlsx"
        onChange={(e: any) => onFileChange(e)}
      />


      {props.typeUid !== '0a5956f9-a9f6-49c0-8080-bb1fda1251a7' &&
      <Button
        className={classes.cardUploadBtn}
        startIcon={props.hideIcon ? null : <Upload />}
        onClick={() => uploadFile('1')}
        style={{ padding: props.hideIcon ? '12px 16px' : '8px 10px', bottom: props.typeUid === '5fe9a4b9-423d-4bad-bbc9-e15660568081' ? 35 : 0 }}
      >
        {props.typeUid === '0a5956f9-a9f6-49c0-8080-bb1fda1251a7' ? 'Загрузить весь товар' : 'Загрузить'}
      </Button>
      }

      {props.typeUid === '0a5956f9-a9f6-49c0-8080-bb1fda1251a7' && !props.sensor &&
      // <Button
      //   className={classes.cardUploadBtn}
      //   startIcon={props.hideIcon ? null : <Upload />}
      //   onClick={() => uploadFile('2')}
      //   style={{ padding: props.hideIcon ? '12px 16px' : '8px 10px', top: 13 }}
      // >
      //   Загрузить остатки
      // </Button>
      <div style={{position: 'relative'}}>
        <Button
          id="basic-button"
          aria-controls={openReport ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openReport ? 'true' : undefined}
          onClick={handleClickReportBtn}
          className={classes.toolbarAddBtn}
        >
          <Upload color="#fff" /> Загрузить
        </Button>
        <Menu
        id="settings-menu"
        className={classes.settingsMenu1}
        anchorEl={anchorReport}
        open={openReport}
        style={{marginTop: 17}}
        onClose={handleCloseReport}
        >
          <div className={classes.settingsReportContent}>
            <div className={classes.settingsReportItem1} onClick={() => uploadFile('1')} >
                Загрузить весь товар
            </div>
            <div className={classes.settingsReportItem1} onClick={() => uploadFile('2')} >
                Загрузить все остатки
            </div>
          </div>
        </Menu>
      </div>
      }

      {props.sensor && props.typeUid === '0a5956f9-a9f6-49c0-8080-bb1fda1251a7' &&
      <div style={{position: 'relative'}}>
        <Button
          id="basic-button"
          aria-controls={openReport ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openReport ? 'true' : undefined}
          onClick={handleClickReportBtn}
          className={classes.toolbarAddBtn}
        >
          <Upload color="#fff" /> Загрузки
        </Button>
        <Menu
        id="settings-menu"
        className={classes.settingsMenu}
        anchorEl={anchorReport}
        open={openReport}
        style={{marginTop: 17}}
        onClose={handleCloseReport}
        >
          <div className={classes.settingsReportContent}>
            <div className={classes.settingsReportItem} onClick={() => uploadFile('1')} >
                Загрузить весь товар
            </div>
            <div className={classes.settingsReportItem} onClick={() => uploadFile('2')} >
                Загрузить все остатки
            </div>
          </div>
        </Menu>
      </div>
      }


    </>
  )
}
