import React from 'react'

export const DiagramIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#E9E9E7"/>
      <path d="M31 26L21.5 16.5L16.5 21.5L9 14" stroke="#D3D1CB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25 26H31V20" stroke="#D3D1CB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
