import { makeStyles, createStyles, Menu, Button, MenuItem, Select, TextField, Box, Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import cookie from 'cookie';
import { Plus } from '../../UI/Icons';
import Trash from '../../UI/Icons/Trash';
import { getCategory, getFilterSale } from '../../../api/calc';
import { setFilter } from '../../../api/reports';
import classNames from 'classnames';
import { Autocomplete } from '@material-ui/lab';
import { useQuery } from 'react-query';
import { getAllFilter } from '../../../api/organizations';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '.MuiAutocomplete-popper': {
        right: 0,
        left: 'auto',
        minWidth: 300,
      }
    },
    toolbarFilterBtn: {
      position: 'relative',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '14px',
      color: '#37352F',
      textTransform: 'capitalize',
      borderBottom: '1px solid #000',
      paddingBottom: 1,
      cursor: 'pointer',

      '&:hover': {
        borderBottom: '1px solid #827F75',
        color: '#827F75',
      },
    },
    toolbarFilterChip: {
      position: 'absolute',
      top: -10, right: -10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '12px',
      width: 14,
      height: 14,
      color: 'white',
      background: '#FF6C4B',
      borderRadius: '50%',
    },
    filterMenu: {
      '& .MuiMenu-paper': {
        background: '#FFFFFF',
        border: '1px solid #E9E9E7 !important',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 20px rgba(55, 53, 47, 0.11)',
        borderRadius: 16,
        top: '126px !important',
        overflow: 'unset',
      },
      '& .MuiMenu-list': {
        minWidth: 500,
        maxWidth: 500,
        width: '100vh',
        padding: 0,
      },
    },
    filterContet: {
      padding: 16,
      boxSizing: 'border-box',
    },
    filterHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,

      '& svg': {
        width: 20,
        height: 20,
      }
    },
    filterTitle: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: '#37352F',
      margin: 0,
    },
    filterList: {},
    filterAddBtn: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      textTransform: 'none',
      border: 'none',
      outline: 'none',
      background: 'none',
      padding: 0,
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        marginRight: 4,
        stroke: '#37352F',
      },
    },
    rowBtn: {
      '& svg': {
        stroke: '#FF6C4B ',
      },
    },
    rowList: {},
    row: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#37352F',
      marginBottom: 24,
    },
    rowLabel: {},
    rowSelectWhere: {
      height: 30,
      minWidth: 214,
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      borderRadius: 8,
      marginLeft: 16,

      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '& .MuiSelect-select': {
        paddingLeft: 10,
      },
    },
    rowEqualSymb: {
      minWidth: 40,
      paddingLeft: 10,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      borderRadius: 8,
      margin: '0 10px',

      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      }
    },
    rowSelectEqual: {
      position: 'relative',
      minWidth: 108,
      height: 30,
      border: '1px solid #D3D1CB',
      boxSizing: 'border-box',
      borderRadius: 8,
      marginRight: 5,

      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '& .MuiSelect-select': {
        paddingLeft: 10,
      },
      '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        opacity: 0,
      },
      '& input': {
        paddingLeft: '4px !important',
      },
      '& .MuiAutocomplete-clearIndicator': {
        display: 'none',
      },
    },
    autocompleteWrap: {
      position: 'relative',
    },
    filterCancelBtn: {
      marginLeft: 8,
    },
    filterFooter: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

interface Props {
  cols?: any;
  sendFilters: (filter: any) => void;
  setFiltersLoaded?: (val: boolean) => void;
  cancelFilter?: () => void;
};

export const LogisticsCostFilterMenu = (props: Props) => {
  const classes = useStyles();

  const [showFilter, setShowFilter] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [hasAlready, setHasAlready] = React.useState<any>({});
  const [symbols, setSymbols] = React.useState(['=']);
  const [oldValue, setOldValue] = React.useState();
  const [selectedCategories, setSelectedCategories] = React.useState<any>();
  const [search, setSearch] = React.useState('');
  const [categories, setCategories] = React.useState([]);

  const [rows, setRows] = React.useState<any>([
    { where: '', equal: '', symbol: '=', equalList: [] }
  ]);

  useQuery(['getAllFilter'], async () => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    const result = await getAllFilter(uid, '2689fead-ddea-4d86-8ead-db8d7c1d9d8b');

    if (result?.data?.data) {
      const rowsResult = result.data.data.map((el: any) => {
        return { where: el.filter, equal: el.value_filter, symbol: symbols[el.type], equalList: [] }
      });
      setRows(rowsResult);
      if (props.sendFilters) props.sendFilters(rowsResult);
    }
    if (props.setFiltersLoaded) props.setFiltersLoaded(true);
  });

  useQuery(['categories', search], async (search: any) => {
    if (search.queryKey[1].length > 2) {
      const result: any = await getCategory({ filter: search.queryKey[1] });
      setCategories(result.data.data.subjects || []);
    }

    return [];
  });

  const findSymbol = (symb: string) => {
    return symbols.findIndex((el) => el === symb)
  };

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowFilter(true);
  };
  const handleCloseFilter = () => {
    setShowFilter(false);
  };
  const addRow = () => {
    let copyRow: any = JSON.parse(JSON.stringify(rows));
    copyRow.push({ where: '', equal: '', equalList: [] });
    setRows(copyRow)
  };
  const deleteRow = async (index: number) => {
    if (props.setFiltersLoaded) props.setFiltersLoaded(false);
    let copyRow: any = JSON.parse(JSON.stringify(rows));
    await sendDelRow(copyRow, index);
    copyRow.splice(index, 1);
    setRows(copyRow);
    props.sendFilters(copyRow);
    if (props.setFiltersLoaded) props.setFiltersLoaded(true);
  };
  const sendDelRow = async (rows: any, index: number) => {
    console.log('rows: ', rows);
    console.log(rows[index].where && rows[index].equal);

    if (rows[index].where && rows[index].equal) {
      const uid = await cookie.parse(document.cookie)['org_uid'];
      await setFilter({
        filter: rows[index].where,
        value_filter: oldValue || String(rows[index].equal),
        type: findSymbol(rows[index].symbol),
        organization_uid: uid,
        primary_data: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
        del: true
      });
      setOldValue(null);
    }
  };
  const editWhere = async (e: any, index: number) => {
    let copyRow: any = JSON.parse(JSON.stringify(rows));
    const newValue = e.target.value;
    const uid = await cookie.parse(document.cookie)['org_uid'];

    await sendDelRow(rows, index);

    copyRow[index].where = newValue;
    copyRow[index].symbol = '=';
    copyRow[index].equal = null;
    copyRow[index].equal = '';

    setRows(copyRow);
  };
  const editSymbol = async (e: any, index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyRow: any = JSON.parse(JSON.stringify(rows));
    let copyHasAlready = JSON.parse(JSON.stringify(hasAlready));
    const newValue = e.target.value;
    copyRow[index].symbol = newValue;

    copyHasAlready[newValue] = true;
    setHasAlready(copyHasAlready);
    setRows(copyRow);

    if (typeof copyRow[index].equal !== 'string' && typeof copyRow[index].equal !== 'number') return;
    if (typeof copyRow[index].equal === 'number' && !copyRow[index].equal?.length) return;
    if (copyRow[index].type === 'int')
      if (!copyRow[index].equal?.length) return;
    await sendDelRow(rows, index);

    await setFilter({
      filter: copyRow[index].where,
      type: findSymbol(copyRow[index].symbol),
      value_filter: copyRow[index].equal,
      organization_uid: uid,
      primary_data: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      del: false
    });
    props.sendFilters(copyRow);
  };
  const editEqual = async (newValue: string, index: number) => {
    const uid = await cookie.parse(document.cookie)['org_uid'];
    let copyRow: any = JSON.parse(JSON.stringify(rows));
    let copyHasAlready = JSON.parse(JSON.stringify(hasAlready));
    delete copyHasAlready[copyRow[index].equal]
    copyRow[index].equal = newValue;

    if (copyRow[index].type === 'int')
      if (!copyRow[index].equal?.length) return;

    if (!copyRow[index].where || !newValue.length) return;
    await sendDelRow(rows, index);

    await setFilter({
      filter: copyRow[index].where,
      type: findSymbol(copyRow[index].symbol),
      value_filter: String(newValue),
      organization_uid: uid,
      primary_data: '2689fead-ddea-4d86-8ead-db8d7c1d9d8b',
      del: false
    });

    copyHasAlready[newValue] = true;
    setSelectedCategories(newValue);
    setHasAlready(copyHasAlready);
    setRows(copyRow);
    props.sendFilters(copyRow);
  };
  const equalText = (newValue: string, index: number) => {
    let copyRow: any = JSON.parse(JSON.stringify(rows));

    if (!oldValue) setOldValue(rows[index].equal);

    copyRow[index].equal = newValue;
    setSearch(newValue);
    setRows(copyRow);
  };
  const setNewEditEqualValue = (e: any, index: number) => {
    let copyRow: any = JSON.parse(JSON.stringify(rows));
    const newValue = e.target.value;
    copyRow[index].equal = newValue;
    setRows(copyRow);
  };

  const cancelFilter = () => {
    setRows([
      { where: '', equal: '', equalList: [] }
    ]);
    if (props.cancelFilter) props.cancelFilter();
  };

  return (
    <>
      <span
        className={classes.toolbarFilterBtn}
        // onClick={() => setOpenFilters(true)}
        aria-controls={showFilter ? 'filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={showFilter ? 'true' : undefined}
        onClick={handleOpenFilter}
      >
        Фильтр
        {rows.length && rows[0].where ? <span className={classes.toolbarFilterChip}>
          {rows.length}
        </span> : null}
      </span>
      <Menu
        id="filter-menu"
        className={classes.filterMenu}
        anchorEl={anchorEl}
        open={showFilter}
        onClose={handleCloseFilter}
      >
        <div className={classes.filterContet}>
          <div className={classes.filterHeader}>
            <h2 className={classes.filterTitle}>Фильтры</h2>
            <span onClick={handleCloseFilter}>
              <CloseIcon />
            </span>
          </div>
          <div className={classes.filterList}>
            <div className={classes.rowList}>
              {rows.map((item: any, index: number) => (
                <div className={classes.row} key={index}>
                  <span className={classes.rowLabel}>Где</span>
                  <Select className={classes.rowSelectWhere} value={item.where} onChange={(e:any) => editWhere(e, index)}>
                    {props.cols?.map((col: any, i: number) => <MenuItem value={col.column_name} key={i}>
                      {col.column_caption}
                    </MenuItem>)}
                  </Select>
                  {/* <div className={classes.rowEqualSymb}>=</div> */}
                  <Select
                    className={classes.rowEqualSymb}
                    value={item.symbol}
                    onChange={(e: any) => editSymbol(e, index)}
                  >
                    {symbols.map((el: any, i: number) =>
                      <MenuItem
                        value={el}
                        key={i}
                        disabled={item.type !== 'int' && (i === 1 || i === 2)}
                      >
                        {el}
                      </MenuItem>
                    )}
                  </Select>
                  {item.type !== 'int' ? (
                    <div className={classes.autocompleteWrap}>
                      <Autocomplete
                        noOptionsText="Начните вводить название"
                        options={categories}
                        autoHighlight
                        value={item.equal}
                        onChange={(event: any, newValue: any) => {
                          editEqual(event?.target?.value || newValue, index);
                        }}
                        inputValue={item.equal}
                        className={classes.rowSelectEqual}
                        renderOption={(props, category) => (
                          <li {...props}>
                            {props}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={classes.rowSelectEqual}
                            value={item.equal}
                            onChange={(event: any) => equalText(event?.target?.value, index)}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <input
                      type="number"
                      placeholder="Только цифры"
                      className={classes.rowSelectEqual}
                      value={item.equal}
                      onChange={(e) => setNewEditEqualValue(e, index)}
                      onBlur={(e: any) => editEqual(e.target.value, index)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          editEqual(event.target.value, index);
                        }
                      }}
                    />
                  )}
                  <span className={classes.rowBtn} onClick={() => deleteRow(index)}>
                    <Trash />
                  </span>
                </div>
              ))}
            </div>
            <div className={classes.filterFooter}>
              <button className={classes.filterAddBtn} onClick={addRow}>
                <Plus /> Добавить фильтр
              </button>
              <button className={classNames(classes.filterAddBtn, classes.filterCancelBtn)} onClick={cancelFilter}>
                Сбросить
              </button>
            </div>
          </div>
        </div>
      </Menu>
    </>
  )
}
