import React from 'react'
import { makeStyles, createStyles, Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'

import faqPicture from '../../assets/icons/faqPicture.svg'
import Empty from '../../components/layout/empty'
import { ArrowForwardIos, ExpandMore } from '@material-ui/icons'
import { useQuery } from 'react-query'
import { getFAQ } from '../../api/faq'

const useStyles = makeStyles(() => 
  createStyles({
    content: {
      maxWidth: 600 + 64,
      width: '100%',
      margin: '0 auto',
      padding: 32,
    },
    logo: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '38px',
      color: '#37352F',
      margin: 0,
      marginBottom: 8,
    },
    title: {
      fontFamily: '"Work Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '23px',
      color: '#37352F',
      margin: 0,
      marginBottom: 32,
    },
    picture: {
      marginBottom: 36,
    },
    accordion: {
      border: '1px solid #37352F',
      borderRadius: '8px !important',
      boxShadow: 'none',
      marginBottom: '16px !important',

      '&::before': {
        opacity: 0,
      },
      '& .MuiAccordionSummary-content': {
        // margin: '0 !important',
        marginTop: 18,
        marginBottom: 18,
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        marginBottom: 10,
      },
      '&.Mui-expanded': {
        marginTop: 0,
      },
    },
    accordionSummary: {
      minHeight: 'fit-content',
    },
    accordionTitle: {
      marginLeft: 8,
    },
    accordionText: {
      marginLeft: 32,
    },
    accordionDetails: {
      paddingTop: 0,
    },
  })
)

const SupportFAQ = () => {
  const classes = useStyles();

  const faqList = useQuery('faqList', async () => {
    const result = await getFAQ();
    return result?.data?.data;
  }).data;

  return (
    <Empty>
      <div className={classes.content}>
        <h2 className={classes.logo}>PriceMarket</h2>
        <h3 className={classes.title}>Часто задаваемые вопросы (FAQ)</h3>
        <img src={faqPicture} className={classes.picture} alt="" />

        {faqList && faqList.map((item: any) => (
          <Accordion className={classes.accordion}>
            <AccordionSummary
              // expandIcon={<ExpandMore />}
              className={classes.accordionSummary}
            >
              <ArrowForwardIos />
              <Typography className={classes.accordionTitle}>{item.questText}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography className={classes.accordionText}>
                {item.questAnswer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </Empty>
  )
}

export default SupportFAQ
