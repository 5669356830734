import React from 'react'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
  fill?: string;
}

const AdminRole = (props: Props) => {
  return (
    <div style={{background: '#E9E9E7',
     width: 40,
    height: 40, 
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    }}>
      <GroupOutlinedIcon style={{color: '#D8D7D2', fontSize: 30}}/>
    </div>
  )
}

export default AdminRole;
