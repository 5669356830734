import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// const baseURL = 'http://85.175.216.246:8089';

interface GetReportReq {
  id_org: string;
  primary_data: string;
}
interface GetDataReq {
  primary: string;
  type?: number;
  org: string;
  column: Array<string>;
  search?: any;
  filter?: Array<{ column: string; value: Array<string>; }>
}
interface DelStockReq {
  org: string;
};
interface OldRepReq {
  org: string;
  rest: boolean;
  primary: string;
};
interface SetDataReq {
  primary: string;
  org: string;
  column: Array<string>;
  filter: Array<string>;
  val: string;
};
interface GetBelReq {
  primary: string;
  history: string;
  org: string;
};
interface GetNoDataReq {
  org: string;
};
interface GetFilterReq {
  primary: string;
  org: string;
  column: string;
};
interface SetValueReq {
  org: string;
  column: string;
  value: string;
  column_search: string;
  value_search: Array<string>;
};
interface GetAvgValueReq {
  org: string;
  column: string;
  value_column: Array<string>;
};
interface SetColumReq {
  colum: string;
  organization_uid: string;
  calc: string;
  del: boolean;
};
interface SetFilterReq {
  filter: string;
  value_filter: string;
  organization_uid: string;
  primary_data: string;
  del: boolean;
  type?: number;
};
interface SetColumLocationReq {
  organization_uid: string;
  calc: string;
  location: Array<string>;
};
interface ClearFilterReq {
  organization_uid: string;
  primary_data: string;
  type: number;
};
interface DeleteColumnReq {
  primary: string;
  org: string;
}
interface SetAllValueReq {
  org: string;
  avg_price: string;
  delivery_time: string;
  max_price: string;
  min_price: string;
  min_stock?: string;
  column_search: string;
  value_search: Array<string>;
}

interface Analist {
  brand?: Array<string> | null,
  category?: Array<string> | null,
  date_fix?: string | null,
  date_start?: string | null,
  date_stop?: string | null,
  organization_uid: string,
  print: boolean,
  supplier_article?: Array<string> | null
}

interface FixPoint {
    date: string,
    del_: boolean,
    name: string,
    organization_uid: string
}

interface Marks {
  color: string,
  date: string,
  del_: boolean,
  mark: number,
  organization_uid: string,
  text: string
}

export const getReport = async (req: GetReportReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/getRep`, req);
};
export const getData = async (req: GetDataReq, page: any = 0 ,def: any = 500) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/getData?page=${page}&perPage=${def}`, req);
};
export const delStock = async (req: DelStockReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/delStock`, req);
};
export const oldRep = async (req: OldRepReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/oldRep`, req);
};
export const setData = async (req: SetDataReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/setData`, req);
};
export const getBell = async (req: GetBelReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/getBell`, req);
};
export const getDetailData = async (req: DelStockReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/getDetailData`, req);
};
export const getNoData = async (req: GetNoDataReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/getNoData`, req);
};
export const getFilter = async (req: GetFilterReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/getFilter?page=0&perPage=189000`, req);
};
export const setVolume = async (req: SetValueReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/setVolume`, req);
};
export const setAlltVolume = async (req: SetAllValueReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/setAlltVolume`, req);
};
export const getAvgValue = async (req: GetAvgValueReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/getAvgValue`, req);
};
export const setColum = async (req: SetColumReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/setColum`, req);
};
export const setFilter = async (req: SetFilterReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/setFilter`, req);
};
export const getRegions = async (org: string) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/regions`, { org });
};
export const getRegionsHistory = async () => {
  const urlAdres = await url()
  return await API.get(`${urlAdres.data.reports}/reports/regions_history`);
};
export const setColumLocation = async (req: SetColumLocationReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/setColumLocation`, req);
};
export const clearFilter = async (req: ClearFilterReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/clearFilter`, req);
};
export const delBell = async ({ org, uid }: { org: string, uid: string }) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/delBell`, { org, uid });
};
export const deleteColum = async (req: DeleteColumnReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.reports}/reports/deleteColum`, req);
};

export const countNotifications = async (req: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/getCountBell`, req);
};

export const getAnalist = async (req: Analist) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/getAnalist`, req);
};

export const setImport = async (req: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/setImport`, req);
};

export const getBrandFilter = async (req: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/getFilterBrand`, req);
};

export const getArticleFilter = async (req: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/getFilterArticle`, req);
};

export const getCategoryFilter = async (req: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/getFilterCategory`, req);
};

export const getFixPoint = async (uid: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/getFixingPoint`, uid);
};

export const setFixPoint = async (req: FixPoint) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/setFixingPoint`, req);
};


export const setMarks = async (req: Marks) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/setMark`, req);
};


export const newNotifications = async (req: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/getNewBell`, req);
};

export const new1Notifications = async (req: any) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.notification}/organizations-2/setNewBell`, req);
};

export const getUserUid = async () => {
  const urlAdres = await url()
  return await API.get(`${urlAdres.data.notification}/organizations-2/getUserUID`);
};

