import API from '../utils';
import axios from "axios"

const url: any = async() => {
  return await axios.get('/configApi.json')
}

// const baseURL = 'http://85.175.216.246:8082';

interface TariffAccessEditorReq {
  whatHappening: string,
  uid: string,
  access_name: string,
  accessDescription: string
};
interface TariffAccessSettingsReq {
  whatHappening: string;
  planUid: string;
  accessUid: string;
}

export const getTariffsSettings = async () => {
  const urlAdres = await url()
  return await API.get(`${urlAdres.data.tariffsSettings}/tariffs_settings/access_table`);
};
export const tariffAccessEditor = async (req: TariffAccessEditorReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.tariffsSettings}/tariffs_settings/access_editor`, req);
};
export const tariffAccessSettings = async (req: TariffAccessSettingsReq) => {
  const urlAdres = await url()
  return await API.post(`${urlAdres.data.tariffsSettings}/tariffs_settings/tariff_access_setting`, req);
};
export const orgPlansAccess = async (id: string) => {
  const urlAdres = await url()
  await API.get(`${urlAdres.data.tariffsSettings}/tariffs_settings/org_plans_access/${id}`);
};
