
import React from 'react'

interface Props {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
  fill?: string;
}

const MoreVerticalIcon = (props: Props) => {
  return (
    // <svg width={props.width || '16px'} height={props.height || '16px'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M5.99967 8.66634C6.36786 8.66634 6.66634 8.36786 6.66634 7.99967C6.66634 7.63148 6.36786 7.33301 5.99967 7.33301C5.63148 7.33301 5.33301 7.63148 5.33301 7.99967C5.33301 8.36786 5.63148 8.66634 5.99967 8.66634Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M5.99967 3.99984C6.36786 3.99984 6.66634 3.70136 6.66634 3.33317C6.66634 2.96498 6.36786 2.6665 5.99967 2.6665C5.63148 2.6665 5.33301 2.96498 5.33301 3.33317C5.33301 3.70136 5.63148 3.99984 5.99967 3.99984Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M5.99967 13.3333C6.36786 13.3333 6.66634 13.0349 6.66634 12.6667C6.66634 12.2985 6.36786 12 5.99967 12C5.63148 12 5.33301 12.2985 5.33301 12.6667C5.33301 13.0349 5.63148 13.3333 5.99967 13.3333Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M9.99967 8.66634C10.3679 8.66634 10.6663 8.36786 10.6663 7.99967C10.6663 7.63148 10.3679 7.33301 9.99967 7.33301C9.63148 7.33301 9.33301 7.63148 9.33301 7.99967C9.33301 8.36786 9.63148 8.66634 9.99967 8.66634Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M9.99967 3.99984C10.3679 3.99984 10.6663 3.70136 10.6663 3.33317C10.6663 2.96498 10.3679 2.6665 9.99967 2.6665C9.63148 2.6665 9.33301 2.96498 9.33301 3.33317C9.33301 3.70136 9.63148 3.99984 9.99967 3.99984Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    //   <path d="M9.99967 13.3333C10.3679 13.3333 10.6663 13.0349 10.6663 12.6667C10.6663 12.2985 10.3679 12 9.99967 12C9.63148 12 9.33301 12.2985 9.33301 12.6667C9.33301 13.0349 9.63148 13.3333 9.99967 13.3333Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>
    <svg width={props.width || '16px'} height={props.height || '16px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 20C9.55228 20 10 19.5523 10 19C10 18.4477 9.55228 18 9 18C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 6C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4C14.4477 4 14 4.44772 14 5C14 5.55228 14.4477 6 15 6Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 20C15.5523 20 16 19.5523 16 19C16 18.4477 15.5523 18 15 18C14.4477 18 14 18.4477 14 19C14 19.5523 14.4477 20 15 20Z" stroke={props.color || '#37352F'} strokeWidth={props.strokeWidth || 2} strokeLinecap="round" strokeLinejoin="round"/>
      <rect x="9" y="3" width="6" height="4" fill={props.color || '#37352F'}/>
      <rect x="9" y="10" width="6" height="4" fill={props.color || '#37352F'}/>
      <rect x="9" y="17" width="6" height="4" fill={props.color || '#37352F'}/>
    </svg>
    
  )
}

export default MoreVerticalIcon;
